import React, { useState } from 'react';
import './TopHeader.css';
import AgenasLogo from '../../../UI/Logo/AgenasLogo/AgenasLogo';
import LogoRepubblicaItaliana from '../../../img/logos/logo-repubblica-it.svg';
import authConfig from '../../../../config/authConfig';
import SessioneUtente from '../../../../sessionManager/SessioneUtente';
import LogOut from '../../../img/logos/logout.svg';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Icon } from 'design-react-kit';
import { areeTerritoriali } from '../../../Utils/Utils';
import { areeTerritorialiEnum, roleEnum } from '../../../Utils/Dataset';

function TopHeader() {
    const paginationRegioni = 7;

    let sessione = SessioneUtente.getInstance();
    let username = sessione ? sessione.nomeUtente + " " + sessione.cognomeUtente : "Anonimo";
    let role = sessione ? " " + sessione.ruoliUtente : "Anonimo";
    let idAreaTerritoriale = sessione ? sessione.idAreaTerritoriale : "-";

    const [isOpenRegioni, setIsOpenRegioni] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [page, setPage] = useState(1);

    const toggle = (el) => {
        if (el.target.id !== "switchRegionale" && el.target.id !== "indietro" && el.target.id !== "nextPage" && el.target.id !== "prevPage" && el.target.id !== "changePage" && el.target.id !== "") {
            setIsOpen(!isOpen);
            setPage(1);
            setIsOpenRegioni(false);
        }
        else setIsOpen(true)
    }

    const nextPage = () => {
        if (page < 3) setPage(page + 1)
    }

    const prevPage = () => {
        if (page > 1) setPage(page - 1)
    }

    const impersonificazione = (idRegione) => {
        sessionStorage.setItem("regioneImpersonificata", idRegione);

        if (sessione.ruoloRealeUtente === roleEnum.AMMINISTRATORE) {
            sessione.ruoliUtente = "PT-AUT";
            sessione.idAreaTerritoriale = idRegione;
        }
    }

    const ritornaVisualizzazioneAmministratore = () => {
        sessionStorage.removeItem("regioneImpersonificata");
        setIsOpenRegioni(false);
        setPage(1);

        if (sessione.ruoloRealeUtente === roleEnum.AMMINISTRATORE) {
            sessione.ruoliUtente = "PT-AMM";
            sessione.idAreaTerritoriale = "";
        }
    }

    const dropDownItemsRegioniSelection = (
        <>
            <DropdownItem id="indietro" key="indietro" onClick={() => setIsOpenRegioni(false)}><Icon icon="it-arrow-left" style={{ width: "20px", height: "20px" }} className={"icon-white"} />Indietro</DropdownItem>
            {areeTerritoriali.map((el, i) => (
                i >= (page - 1) * paginationRegioni && i < page * paginationRegioni &&
                <DropdownItem id={el} tag={Link} to="/home-regionale" onClick={() => impersonificazione(el)}><img id={el} src={areeTerritorialiEnum[el].logo} style={{ width: "16px", height: "16px" }} />{areeTerritorialiEnum[el].nome}</DropdownItem>
            ))}
            <DropdownItem id={"changePage"} style={{ cursor: "default" }}>
                <Icon id={"nextPage"} icon="it-chevron-right" style={{ cursor: "pointer", float: "right" }} className={"icon-white"} onClick={() => nextPage()} />
                <Icon id={"prevPage"} icon="it-chevron-left" style={{ cursor: "pointer", float: "right" }} className={"icon-white"} onClick={() => prevPage()} />
            </DropdownItem>
            <DropdownItem divider />
        </>
    )

    const dropDownItemsSettingsAdmin = (
        <>
            <DropdownItem id="switchRegionale" key="switchRegionale" onClick={() => setIsOpenRegioni(true)}>Autore regionale</DropdownItem>
            <DropdownItem divider />
        </>
    )

    const dropDownItemsSettingsAdminImpersonificato = (
        <>
            {!isOpenRegioni ?
                <>
                    <DropdownItem id="switchAdmin" key="switchAdmin" tag={Link} to="/" onClick={() => ritornaVisualizzazioneAmministratore()}>Amministratore</DropdownItem>
                    <DropdownItem id="switchRegionale" key="switchRegionale" onClick={() => setIsOpenRegioni(true)}>Autore regionale</DropdownItem>
                    <DropdownItem divider />
                </>
                : dropDownItemsRegioniSelection}
        </>
    )

    const getDropDownItems = () => {
        if (sessione.ruoloRealeUtente === roleEnum.AMMINISTRATORE && sessionStorage.getItem("regioneImpersonificata")) return dropDownItemsSettingsAdminImpersonificato;
        else if (sessione.ruoloRealeUtente === roleEnum.AMMINISTRATORE) {
            if (isOpenRegioni) return dropDownItemsRegioniSelection;
            else return dropDownItemsSettingsAdmin;
        }
        else return <></>
    }

    const dropDownMenu = (
        <Dropdown show isOpen={isOpen} toggle={toggle} tag="span">
            <DropdownToggle id="toggleDd" caret className={"btn-primary-outline"}>
                <Icon id="toggleDd" icon="it-more-actions" className={"icon-white"} />
            </DropdownToggle>
            <DropdownMenu className={"ddmenu"}>
                {getDropDownItems()}
                <DropdownItem><a href={authConfig['url-logout']}><img id={"log-out"} src={LogOut} style={{ width: "16px", height: "16px" }} /> Log-out</a></DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )

    const getMenuUtente = () => {
        if (sessione.ruoloRealeUtente === roleEnum.AMMINISTRATORE) return dropDownMenu;
        else return <a className={"pl-3"} href={authConfig['url-logout']}><img id={"log-out"} src={LogOut} style={{ width: "24px", height: "24px" }} />Log-out</a>;
    }

    return (
        <div className="it-header-slim-wrapper Top-header" id="userHeader">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="it-header-slim-wrapper-content">
                            <img
                                className="icon icon-l"
                                src={LogoRepubblicaItaliana}
                                alt="ministero della salute"
                            />
                            <p>Ministero della salute</p>
                            <AgenasLogo></AgenasLogo>
                            {sessione ? <div className="it-right-zone d-none d-md-block logout">
                                <span style={{ color: "#fff" }}>Utente: {username} </span>
                                {getMenuUtente()}
                            </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopHeader;