import React, { useContext, useState } from 'react';
import { FormGroup, Input, Toggle, Button, Icon } from 'design-react-kit';
import { Label } from 'reactstrap';
import './Category.css';
import InfoModal from '../InfoModal/InfoModal';
import { KO_CATEGORIA_ALTRO_CONTENUTO, KO_FONTE, operazioniContenuto, roleEnum, tipologiaEnum } from '../../Utils/Dataset';
import { Context, showError, showSuccess } from '../../../hook/globalState/Store';
import { Messages } from '../../Utils/Messages';
import CustomModal from '../../CustomModal/CustomModal';
import useModal from '../../../hook/useModal';
import ContenutiRepository from '../../../Repo/ContenutiRepository';
import useObjectsArray from '../../../hook/useObjectsArray';

export default function Category(props) {

    const [show, , handleClose, handleShow] = useModal(false);
    const [currentSelection, setCurrentSelection] = useState();
    const [currentId, setCurrentId] = useState();


    return (
        <>
            <div id="category">
                <Toggle
                    checked={props.toggleViewCategorie}
                    label={props.labelName}
                    onChange={() => props.setToggleViewCategorie(!props.toggleViewCategorie)}
                />
                {props.infoMessage && props.ruoloUtente === roleEnum.AMMINISTRATORE && <InfoModal message={props.infoMessage} />}
                {props.toggleViewCategorie &&
                    <fieldset id="fieldsetCategory">
                        <div className="row">
                            {props.toggleViewCategorie && props.options.length <= 0 &&
                                <div className="msgNoCategories">
                                    <i>Non sono presenti categorie.</i>
                                </div>
                            }
                            {props.options?.map((el, i) => (
                                <div className="col-6" key={el.etichetta + i}>
                                    <FormGroup
                                        check
                                        tag="div"
                                        className="elements"
                                    >
                                        <Input
                                            defaultChecked={props.categorie.find((element) => element === el.etichetta)}
                                            id={`${props.id}${i}`}
                                            type="checkbox"
                                            onClick={(e) => {
                                                if (e.target.checked) {
                                                    props.addCategoria(el.etichetta)
                                                } else
                                                    props.removeCategoria(props.categorie.findIndex((element) => element.etichetta === el.etichetta))
                                            }}
                                        />
                                        <Label
                                            check
                                            for={`${props.id}${i}`}
                                            tag="label"
                                        >
                                            {el.etichetta}
                                            {props.ruoloUtente === roleEnum.AMMINISTRATORE &&
                                                <button className={"delete_tag"} onClick={() => getModal(handleShow, el.etichetta, setCurrentSelection, el.id, setCurrentId)}>
                                                    <Icon className="left" color="black" icon="it-close" id="delete" />
                                                </button>
                                            }
                                        </Label>


                                    </FormGroup>
                                </div>
                            ))}
                        </div><CustomModal
                            show={show}
                            onHide={handleClose}
                            onConfirm={() => callBackAndClose(props.categorie, true, handleClose, currentSelection, currentId, props.deleteCategoriaHandler)}
                            title={operazioniContenuto.ELIMINAZIONE + " categoria in corso"}
                            text={Messages.DELETE_CATEGORY}
                        />
                    </fieldset>
                }


                {props.dynamicOptions && props.toggleViewCategorie && props.ruoloUtente === roleEnum.AMMINISTRATORE &&
                    <div className={"customInput"}>
                        <input id="checkboxInput" type="text"
                            value={props.checkboxButtonValue}
                            onChange={props.changeCheckboxButton}
                        />
                        <Button id={"saveButton"} color={"secondary"} tag="button" disabled={!props.checkboxButtonValue ? true : false} onClick={(event) => {
                            if (props.checkboxButtonValue) {
                                event.preventDefault()
                                props.addCategoriaHandler(props.checkboxButtonValue)
                                props.changeCheckboxButton({ target: { value: "" } }) //oggetto artificiale per resettare il valore
                            }
                        }}>
                            {"Aggiungi"}
                        </Button>
                    </div>
                }

                {!props.toggleViewCategorie &&
                    <i>Le categorie sono disabilitate, fare click sull'interruttore per attivarle.</i>
                }
            </div>
        </>
    )
}

function getModal(handleShow, etichetta, setCurrentSelection, id, setCurrentId) {
    setCurrentSelection(etichetta)
    setCurrentId(id)
    handleShow();
}

async function callBackAndClose(categorie, checkCategorie, handleClose, currentSelection, currentId, deleteCategoriaHandler) {

    if (checkCategorie) {
        ContenutiRepository().getContenutiOrdinati({ tipologia: tipologiaEnum.FAKE_NEWS })
            .then(result => {
                let success = true;
                result.forEach(el => {
                    if (el.categorie && el.categorie.length > 0) {
                        if (el.categorie.includes(currentSelection)) {
                            success = false;
                            return;
                        }
                    }
                });
                if (success) {
                    if (categorie.includes(currentSelection)) {
                        showError(KO_FONTE)
                    } else {
                        if (currentId) {
                            deleteCategoriaHandler(currentId)
                        }
                    }
                } else {
                    showError(KO_CATEGORIA_ALTRO_CONTENUTO)
                }

            })
        handleClose();
    }

}