import React from 'react';
import CustomInput from '../../UI/CustomInput/CustomInput';
import './Template_Link.css'

export default function Template_Link(props) {

    return (
        <div className="template-link">
            <div className="inputBoxWithShadow">
                <CustomInput id="input3" labelName="Link*" change={(e) => props.content.linkChangedHandler(0, "link", e.target.value)} name={props.content.link[0]?.link} valid={props.content.validateLink} />
            </div>
        </div>
    )
}