import React, { useState } from 'react';
import './TitleTemplate.css';
import { Icon } from 'design-react-kit';
import { tipologiaEnum } from '../../Utils/Dataset';

export default function TitleTemplate(props) {

    // Gestione custom width dell'input
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext("2d");

    // Tooltips
    const tooltipModifica = "Modifica Nome";
    const tooltipInterrompiModifica = "Interrompi Modifica";

    return (
        <div className="titleTemplate">
            {props.tipologia && <h6>Tipologia: {props.tipologia} </h6>}
            {((props.tipologia?.toUpperCase() === tipologiaEnum.NEWS || props.tipologia?.toUpperCase() === tipologiaEnum.FAKE_NEWS) && props.logo) && <img alt={"Logo Regione"} className="img-fluid" src={props.logo} />}
            <div>
                <span> Nome: </span>
                <input
                    style={{ width: ctx.measureText(props.name).width * 2 + 25 + "px", maxWidth: "60%", minWidth: "10%" }}
                    id={"inputName"}
                    value={props.name ? props.name : ""}
                    disabled={props.nameNotEditable}
                    onChange={props.onChangeName}
                />
                {!props.disabledEditIcon ? props.isEditEnable ? <button id={"buttonPencil"} onClick={() => props.setNameNotEditable(!props.nameNotEditable)}>
                    {props.nameNotEditable ?
                        <a data-toggle="tooltip" data-selector="true" title={tooltipModifica}>
                            <Icon color="black" icon="it-pencil" id="pencil" />
                        </a> :
                        <a data-toggle="tooltip" data-selector="true" title={tooltipInterrompiModifica}>
                            <Icon id={"confirmUpdate"} icon="it-check" color="success" />
                        </a>
                    }
                </button> : null : null}
            </div>
            {props.ultimaModifica && props.utenteUltimaModifica &&
                <>
                    <div id="lastEdit">
                        <span>Ultimo aggiornamento</span>: {props.ultimaModifica}, <span>da</span> {props.utenteUltimaModifica}
                    </div>
                </>
            }
            {props.tipologia && <i id="nb">In questa sezione puoi modificare il contenuto della pagina. Ricordati di selezionare "Pubblica" per permettere la pubblicazione della modifica sul portale.</i>}
        </div >
    )
}