import React, { useContext, useEffect, useState } from 'react';
import { Icon } from 'design-react-kit';
import useInput from '../../../hook/useInput';
import Card from '../../UI/Card/Card';
import Publisher from '../../UI/Publisher/Publisher';
import Scheduled from '../../UI/Scheduled/Scheduled';
import moment from 'moment';
import validate, { validateQuillNoTitle } from '../../UI/FieldValidation/FieldValidation';
import useModal from '../../../hook/useModal';
import { Redirect } from 'react-router-dom';
import { WAFE_PREVIEW_URL } from '../../../config/config';
import {
    assetsEnum,
    blankQuill,
    KO_RELEASE,
    OK_RELEASE,
    statoContenuto,
    operazioniContenuto,
} from "../../Utils/Dataset";
import useObjectsArray from "../../../hook/useObjectsArray";
import CustomModal from "../../CustomModal/CustomModal";
import { Messages } from "../../Utils/Messages";
import { Context, hideLoading, reloadMenu, scrollToTop, showError, showLoading, showSuccess } from "../../../hook/globalState/Store";
import { alertLevels } from "../../UI/InPageAlert/InPageAlert";
import CustomInput from "../../UI/CustomInput/CustomInput";
import TextEditor from "../../UI/TextEditor/TextEditor";
import './EditPaginaErrore.css'
import { hasErrorOutcome } from "../../Interceptor/Interceptor";
import FileLoader from "../../UI/FileLoader/FileLoader";
import { BUCKET_URL } from "../../../config/config";
import FileRepository from "../../../Repo/FileRepository";
import { forcedScrollToTop, uploadImage } from "../../Utils/Utils";
import useFileHandler from "../../../hook/useFileHandler";
import TitleTemplate from "../../UI/TitleTemplate/TitleTemplate";
import ContenutiRepository from "../../../Repo/ContenutiRepository";
import { getMessaggioOperazione } from "../../../Repo/Utils";
import SessioneUtente from '../../../sessionManager/SessioneUtente';
import PreviewModal from '../../PreviewModal/PreviewModal';

export default function EditPaginaErrore(props) {
    const [state, dispatch] = useContext(Context);

    // Sessione
    let session = SessioneUtente.getInstance();

    const pageId = props.match.params.id_pagina_errore;
    const [contentFetched, setContentFetched] = useState(false);
    const [paginaErrore, setPaginaErrore] = useState(false);
    const [bucketBrowse, setBucketBrowse] = useState([])
    const [show, , handleClose, handleShow] = useModal(false);
    const [dataModifica, setDataModifica] = useInput("");
    const [utenteInserimento, setUtenteInserimento] = useInput("");
    const [redirect, setRedirect] = useState(false);
    const [action, setAction] = useState("");
    const [nameNotEditable, setNameNotEditable] = useState(true)


    //FIELDS
    const [name, setName, onChangeName, validateName] = useInput("", validate)
    const [originalName, setOriginalName] = useState("")

    const [title, setTitle, titleChangedHandler, validateTitle] = useInput("", validate);
    const [, , , , onChangeCurrentFile] = useFileHandler()
    const [immagine, setImmagine, onChangeImmagine, validateImmagine] = useInput("", validate);
    const [testoAlternativo, setTestoAlternativo, testoAlternativoChangeHandler] = useInput("", validate);
    const [testo, setTesto, , , updateTestoHandler, validateTesto] = useObjectsArray(null, validateQuillNoTitle, blankQuill);
    const [subtitle, setSubtitle, subtitleChangedHandler, validateSubtitle] = useInput("", validate);

    //Cards
    const [dataPubblicazioneProgrammata, setDataPubblicazioneProgrammata, dataPubblicazioneProgrammataChangeHandler, validateDataPubblicazioneProgrammata] = useInput("", validate);
    const [oraPubblicazioneProgrammata, setOraPubblicazioneProgrammata, oraPubblicazioneProgrammataChangeHandler, validateOraPubblicazioneProgrammata] = useInput("", validate);
    const [dataPubblicazione,] = useState("");
    const [togglePubblicazioneProgrammata, setTogglePubblicazioneProgrammata] = useState(false);
    const validatePubblicazioneProgrammata = !togglePubblicazioneProgrammata || (oraPubblicazioneProgrammata?.length && dataPubblicazioneProgrammata?.length)
    const [validazione, setValidazione] = useState(false);



    function hasError() {
        return !validateTitle || validateTesto.includes(false) || !validateName || (togglePubblicazioneProgrammata && !validatePubblicazioneProgrammata)
    }

    useEffect(() => {
        if (contentFetched) {
            setValidazione(paginaErrore.validazione)
            setName(paginaErrore.nome ? paginaErrore.nome : "")
            setOriginalName(paginaErrore.nome ? paginaErrore.nome : "")
            setTitle(paginaErrore.titolo ? paginaErrore.titolo : "");
            setSubtitle(paginaErrore.sottotitolo ? paginaErrore.sottotitolo : "");
            setTesto(paginaErrore.testo ? paginaErrore.testo : [{ ...blankQuill }]);
            setImmagine(paginaErrore.immagine ? paginaErrore.immagine?.path : null)
            setTestoAlternativo(paginaErrore.immagine?.testoAlternativo ? paginaErrore.immagine?.testoAlternativo : null)
            setTogglePubblicazioneProgrammata(paginaErrore.dataPubblicazioneProgrammata)
            setDataPubblicazioneProgrammata(paginaErrore.dataPubblicazioneProgrammata ? moment.utc(paginaErrore.dataPubblicazioneProgrammata).local().format("YYYY-MM-DD") : "")
            setOraPubblicazioneProgrammata(paginaErrore.dataPubblicazioneProgrammata ? moment.utc(paginaErrore.dataPubblicazioneProgrammata).local().format("HH:mm") : "")
            setDataModifica(paginaErrore.dataModifica)
            setUtenteInserimento(paginaErrore.utenteModifica)
        }
        else {
            onLoadHandler(pageId, setPaginaErrore, setContentFetched, dispatch)
        }
    }, [contentFetched]);


    //Load browse
    useEffect(() => {
        FileRepository().getFile(assetsEnum.IMMAGINI)
            .then(data => {
                setBucketBrowse(data.objects ? data.objects : []);
            });
    }, [immagine])


    //Reset alert when entering page
    useEffect(() => { dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.NONE, alertMsg: null } }) }, [])

    const btnPrimary = [
        {
            id: "1", color: !hasError() ? "secondary" : "primary", outline: !hasError(), title: "Salva modifiche", disabled: hasError(), disabledTooltip: Messages.INFO_DISABLED_SALVA_MODIFICHE,
            onClickHandler: () => {
                ContenutiRepository().aggiornaContenuto(InizializzaPaginaErrore(paginaErrore, name, title, immagine, testoAlternativo, testo, dataPubblicazioneProgrammata, oraPubblicazioneProgrammata, validazione, subtitle, session))
                    .then(result => {
                        if (result) {
                            if (!hasErrorOutcome(result)) {
                                setPaginaErrore(result.contenuto)
                                showSuccess()
                                checkNomeModificato(result.contenuto, originalName, setOriginalName, dispatch)
                            } else showError(getMessaggioOperazione(result?.esito?.messaggi[0], InizializzaPaginaErrore(paginaErrore, name, title, immagine, testoAlternativo, testo, dataPubblicazioneProgrammata, oraPubblicazioneProgrammata, validazione, subtitle, session)))
                        }
                    })
                forcedScrollToTop(state, dispatch, scrollToTop) // Gestione momentanea
            }
        },
        {
            id: "2",
            color: "secondary",
            title: "Visualizza anteprima",
            onClickHandler: () => {
                getModal(setAction, operazioniContenuto.ANTEPRIMA, handleShow)
            }
        }
    ]

    const btnSecondary = [
        {
            id: "3", color: "secondary", outline: false, title: operazioniContenuto.RIPRISTINA,
            onClickHandler: () => { }
        },
        {
            id: "4", color: !hasError() ? "secondary" : "primary", outline: hasError(), title: "Pubblica", disabled: hasError(),
            onClickHandler: () => {
                getModal(setAction, operazioniContenuto.PUBBLICAZIONE, handleShow)
            }
        },
        {
            id: "5", color: paginaErrore.stato === statoContenuto.PUBBLICATO ? "secondary" : "primary", disabled: paginaErrore.stato !== statoContenuto.PUBBLICATO, title: "Sospendi pubblicazione",
            onClickHandler: () => {
                getModal(setAction, operazioniContenuto.SOSPENSIONE_PUBBLICAZIONE, handleShow)
            }
        }
    ]

    function getModalMessage(action, validazione) {
        switch (action) {
            case operazioniContenuto.INDIETRO:
                return Messages.ERR_TMP_PAGINA_ERRORE
            case operazioniContenuto.ELIMINAZIONE:
                return Messages.ERR_02_01_0A_0A
            case operazioniContenuto.PUBBLICAZIONE:
                if (validazione)
                    return Messages.INFO_PUBBLICAZIONE_CON_VALIDAZIONE
                else return Messages.INFO_DEFAULT
            case operazioniContenuto.SOSPENSIONE_PUBBLICAZIONE:
                return Messages.INFO_SOSPENSIONE_PUBBLICAZIONE
            default:
                return Messages.INFO_DEFAULT
        }
    }

    return (
        redirect ? <Redirect to="/" /> :
            pageId ?
                <div className="container-fluid" id="templateApp">
                    <div id='intestazionePaginaStatica'>
                        <div className="row tipologiaRow">
                            <div className="col-6 p-0">
                                <h6>PAGINE DI ERRORE</h6>
                            </div>
                            <div className="col-6 back">
                                <a onClick={() => getModal(setAction, operazioniContenuto.INDIETRO, handleShow)}>
                                    <Icon className="left"
                                        color="black"
                                        icon="it-arrow-left"
                                        id="arrow" />
                                        Indietro
                                </a>
                            </div>
                        </div>
                        {action === operazioniContenuto.ANTEPRIMA ?
                            <PreviewModal
                                show={show}
                                onHide={handleClose}
                                onConfirm={() => callBackAndClose(InizializzaPaginaErrore(paginaErrore, name, title, immagine, testoAlternativo, testo, dataPubblicazioneProgrammata, oraPubblicazioneProgrammata, validazione, subtitle, session), handleClose, action, setRedirect, dispatch, state)}
                                title={"Anteprima"}
                                text={getModalMessage(action, validazione)}
                                body={<iframe title={"Preview"} src={WAFE_PREVIEW_URL + "/errore/paginanontrovata"} width={"100%"} height={"100%"} />}
                            />
                            :
                            <CustomModal
                                show={show}
                                onHide={handleClose}
                                onConfirm={() => callBackAndClose(InizializzaPaginaErrore(paginaErrore, name, title, immagine, testoAlternativo, testo, dataPubblicazioneProgrammata, oraPubblicazioneProgrammata, validazione, subtitle, session), handleClose, action, setRedirect, dispatch, state)}
                                title={action === operazioniContenuto.INDIETRO ? "Ritorno alla home page" : action} in corso
                                text={getModalMessage(action, validazione)}
                            />}
                        <div className={'row'}>
                            <div className={'col-9 p-0'}>
                                <div className="titleTemplate">
                                    {pageId &&
                                        <TitleTemplate
                                            disable={false} tipologia={null} name={name}
                                            icona={true} ultimaModifica={moment(dataModifica).format("DD-MM-YYYY")}
                                            utenteUltimaModifica={utenteInserimento}
                                            onChangeName={onChangeName} nameNotEditable={nameNotEditable} setNameNotEditable={setNameNotEditable}
                                        />
                                    }
                                </div>
                                <i id="nb">In questa sezione puoi modificare la pagina di errore. Ricordati di selezionare "Pubblica" per permettere la pubblicazione della modifica sul Portale.</i>
                            </div>
                        </div>
                    </div>
                    <div className="row" id={'templatePaginaStatica'}>
                        {contentFetched &&
                            <>
                                <div className="col-9">
                                    <div className="input-box inputBoxWithShadow">
                                        <CustomInput id="input1"
                                            labelName="Titolo*"
                                            change={titleChangedHandler}
                                            name={title}
                                            valid={validateTitle}
                                        />
                                        <CustomInput id="input2"
                                            labelName="Sottotitolo"
                                            change={subtitleChangedHandler}
                                            name={subtitle}
                                            valid={true}
                                        />
                                    </div>
                                    <FileLoader labelName="IMMAGINE*" idLoad="upload1"
                                        change={(e) => onChangeCurrentFile(e, (fileBin, fileMetadata) => uploadImage(fileBin, setImmagine))}
                                        name={immagine} valid={true}
                                        alternativeText={testoAlternativo} changeAlternativeText={testoAlternativoChangeHandler}
                                        setFile={setImmagine} type={assetsEnum.IMMAGINI}
                                        loadedAssets={bucketBrowse} preview previewUri={immagine ? BUCKET_URL + immagine : null}
                                    />
                                    <TextEditor array={testo}
                                        hideTitle
                                        updateValue={updateTestoHandler}
                                        valid={validateTesto}
                                    />
                                </div>
                                <div className="col-3">
                                    <Card title={operazioniContenuto.PUBBLICAZIONE}>
                                        <Publisher stato={paginaErrore.stato} togglePublisher="Validazione necessaria"
                                            arrayPrimary={btnPrimary} arraySecondary={btnSecondary}
                                            clickValidazione={() => setValidazione(!validazione)}
                                            validazione={validazione} date={dataPubblicazione} />
                                    </Card>
                                    <Card title={operazioniContenuto.PUBBLICAZIONE_PROGRAMMATA} toggle={true}
                                        toggleValue={togglePubblicazioneProgrammata}
                                        toggleChange={() => setTogglePubblicazioneProgrammata(!togglePubblicazioneProgrammata)}>
                                        <Scheduled valueTime={oraPubblicazioneProgrammata} valueDate={dataPubblicazioneProgrammata} changeTime={oraPubblicazioneProgrammataChangeHandler}
                                            changeDate={dataPubblicazioneProgrammataChangeHandler}
                                            stateToggle={togglePubblicazioneProgrammata}
                                            errorMsg={"Valorizzare entrambi i campi"} validDate={validateDataPubblicazioneProgrammata}
                                            validTime={validateOraPubblicazioneProgrammata} valid={validatePubblicazioneProgrammata} />
                                    </Card>
                                </div>
                            </>
                        }
                    </div>
                </div> :
                <div className="errore mt-5 ml-5"><span
                    className="error">Non è stato possibile caricare il contenuto richiesto</span>
                </div>
    )
}

async function callBackAndClose(statoPaginaStatica, handleClose, action, setRedirect, dispatch, state) {

    let response

    if (action === operazioniContenuto.PUBBLICAZIONE) {
        ContenutiRepository().aggiornaContenuto(statoPaginaStatica)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    ContenutiRepository().pubblicaContenuto({
                        ...statoPaginaStatica,
                        dataModifica: result.contenuto?.dataModifica
                    })
                        .then(result => {
                            if (!hasErrorOutcome(result)) {
                                setRedirect(true)
                                dispatch({ type: 'RELOAD_EDIT_CONTENT', payload: { reloadEditContent: true } });
                                reloadMenu()
                                showSuccess(OK_RELEASE)
                            } else showError(getMessaggioOperazione(result?.esito?.messaggi[0], statoPaginaStatica))
                        }).catch((err) => showError(KO_RELEASE))
                    handleClose()
                    forcedScrollToTop(state, dispatch, scrollToTop)
                } else showError(getMessaggioOperazione(result?.esito?.messaggi[0], statoPaginaStatica))
            })
    } else if (action === operazioniContenuto.INDIETRO) {
        handleClose();
        setRedirect(true);
    } else if (action === operazioniContenuto.SOSPENSIONE_PUBBLICAZIONE) {
        ContenutiRepository().sospendiContenuto(statoPaginaStatica.id)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    dispatch({ type: 'RELOAD_EDIT_CONTENT', payload: { reloadEditContent: true } });
                    showSuccess()
                }
                else showError(getMessaggioOperazione(result?.esito?.messaggi[0], statoPaginaStatica))
            })
        forcedScrollToTop(state, dispatch, scrollToTop) // Gestione momentanea
        handleClose();
    }

    if (response === Object(response) && action !== operazioniContenuto.SOSPENSIONE_PUBBLICAZIONE)
        setRedirect(true);

}

function onLoadHandler(contentId, setContent, setContentFetched) {
    setContentFetched(false)
    showLoading()

    ContenutiRepository().getContenutoById(contentId)
        .then(result => {
            setContent(result);
            setContentFetched(true);
        }).finally(() => hideLoading())

}

function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}

function InizializzaPaginaErrore(paginaErrore, nome, titolo, immagine, testoAlternativo, testo, datePubblicazioneProgrammata, timePubblicazioneProgrammata, validazione, subtitle, session) {

    const lingua = "ITA";

    if (datePubblicazioneProgrammata && timePubblicazioneProgrammata) {
        var dataPubblicazioneProgrammata = moment(datePubblicazioneProgrammata + " " + timePubblicazioneProgrammata).utc().format();
    }

    return Object.freeze({
        ...paginaErrore,
        validazione,
        nome,
        titolo,
        sottotitolo: subtitle,
        testo,
        immagine: { path: immagine, testoAlternativo: testoAlternativo },
        dataPubblicazioneProgrammata,
        lingua,
        areaTerritoriale: session.idAreaTerritoriale
    });

}

function checkNomeModificato(data, originalName, setOriginalName, dispatch) {
    if (data?.nome !== originalName) {
        reloadMenu()
        setOriginalName(data?.nome)
    }
}

