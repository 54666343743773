import React, { useEffect, useState } from 'react';
import Sprite from "../../../img/sprite.svg";
import { useNotizieInPubblicazione } from '../../HomePage/HomePageHooks';
import { hideLoading, showLoading } from '../../../../hook/globalState/Store';
import NewsPublisher from '../../../UI/NewsPublisher/NewsPublisher';
import { BUCKET_URL } from '../../../../config/config';
import './NewsPubblicazione.css'
import moment from 'moment';
import NewsRepository from '../../../../Repo/NewsRepository';
import useObjectsArray from '../../../../hook/useObjectsArray';
import { operazioniContenuto, roleEnum } from '../../../Utils/Dataset';
import SessioneUtente from '../../../../sessionManager/SessioneUtente';

export default function NewsPubblicazione() {

    let sessione = SessioneUtente.getInstance()

    // Gestione news in pubblicazione senza limiti temporali
    const [contentFetched, setContentFetched] = useState(false);
    const [notizieInPubblicazione, setNotizieInPubblicazione] = useObjectsArray();

    // Gestione paginazione
    const [page, setPage] = useState(0)
    const [elementsInView, setElementsInView] = useState([])
    const prevPage = () => {
        if (page > 0)
            setPage(page - 1)
    }
    const nextPage = () => {
        if (notizieInPubblicazione.length / pagination > page + 1)
            setPage(page + 1)
    }
    const pagination = 2;

    // Gestione caricamento iniziale
    useEffect(() => {
        if (contentFetched) {
            hideLoading()
        }
        else {
            onLoadHandler(setNotizieInPubblicazione, setContentFetched, sessione)
            showLoading()
        }
    }, [contentFetched])

    // Gestione paginazione
    useEffect(() => {
        if (contentFetched) {
            setElementsInView(pagination ? notizieInPubblicazione?.slice(0, pagination) : notizieInPubblicazione)
            setPage(0)
        }
    }, [notizieInPubblicazione])

    useEffect(() => {
        if (pagination) {
            setElementsInView(notizieInPubblicazione?.slice(page * pagination, (page * pagination) + pagination))
        }
    }, [page, contentFetched])

    return (
        <div id="pageNewsPubblicazione">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {contentFetched && notizieInPubblicazione?.length !== 0 ?
                            <span>News in pubblicazione</span> :
                            null
                        }
                    </div>
                </div>
                <div id="mapNews">
                    {contentFetched && elementsInView?.map((el, i) =>
                        <NewsPublisher
                            image={BUCKET_URL + el.immagineCopertina.path}
                            cardTitle={operazioniContenuto.PUBBLICAZIONE_PROGRAMMATA}
                            name={"group" + i}
                            id={"radio" + i}
                            date={moment.utc(el.dataPubblicazioneProgrammata).local().format("DD-MM-YYYY")}
                            time={moment.utc(el.dataPubblicazioneProgrammata).local().format("HH:mm")}
                            key={el.id} nomeContenuto={el.nome}
                        />
                    )}
                </div>
                {(contentFetched && notizieInPubblicazione?.length === 0) ?
                    <>
                        <div className={"newsType"}>
                            <span>Non sono presenti news in pubblicazione</span>
                        </div>
                    </> :
                    null
                }
                {pagination && notizieInPubblicazione?.length > pagination && (
                    <div id={"pagination"} className={"mb-4"}>
                        <span>Pagina {page + 1}</span>
                        <span onClick={prevPage}>
                            <svg className="icon">
                                <use xlinkHref={`${Sprite}#it-chevron-left`} />
                            </svg>
                        </span>
                        <span onClick={nextPage}>
                            <svg className="icon">
                                <use xlinkHref={`${Sprite}#it-chevron-right`} />
                            </svg>
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}

function onLoadHandler(setNotizieInPubblicazione, setContentFetched, sessione) {

    let dateTo = moment().utc().add(365, 'days').format()

    NewsRepository().getNewsInPubblicazione(dateTo, { areaTerritoriale: sessione.ruoliUtente === roleEnum.EDITORE && "" })
        .then(
            result => {
                setNotizieInPubblicazione(result)
            }
        )
        .catch(err => err)
        .finally(setContentFetched(true))
}