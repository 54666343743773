//import axios from 'axios';
import authConfig from "../config/authConfig";

export default class TokenService {

    static urlToken = authConfig["url-request-authToken"]
    static refreshToken = authConfig["url-refresh-authToken"]

    static requestNewToken(idSession) {
        return fetch(this.urlToken, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ sessionId: idSession }),
        })
    }

    static requestSessioneUtente(codeVerifier, authorizationCode) {
        return fetch(this.urlToken, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ codeVerifier: codeVerifier, authorizationCode: authorizationCode }),
        })
    }

    static refreshToken(sessioneUtente) {
        return fetch(this.refreshToken, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ sessioneUtente: sessioneUtente }),
        })
    }
}