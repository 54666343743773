const Reducer = (state, action) => {
    switch (action.type) {
        case 'RELOAD_MENU':
            return {
                ...state,
                reloadMenu: action.payload.reloadMenu
            };
        case 'SHOW_ALERT':
            return {
                ...state,
                alertLevel: action.payload.alertLevel,
                alertMsg: action.payload.alertMsg
            };
        case 'SCROLL_TOP':
            return {
                ...state,
                scrollTop: action.payload.scrollTop
            }
        case 'REDIRECT':
            return {
                ...state,
                redirect: action.payload.redirect,
                redirectUrl: action.payload.redirectUrl
            }
        case 'RELOAD_EDIT_CONTENT':
            return {
                ...state,
                reloadEditContent: action.payload.reloadEditContent
            }
        case 'RELOAD_PATH':
            return {
                ...state,
                reloadPath: action.payload.reloadPath
            }
        case 'LOADING':
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case 'RELOAD_EDITMENU':
            return {
                ...state,
                reloadEditMenu: action.payload.reloadEditMenu
            }
        case 'RELOAD_HOMEPAGE':
            return {
                ...state,
                reloadHomepage: action.payload.reloadHomepage
            }
        case 'FIELDS_ERROR':
            return {
                ...state,
                fieldsError: action.payload.fieldsError
            }
        case 'MENU':
            return {
                ...state,
                isMenuCollapsed: action.payload.isMenuCollapsed
            }
        case 'RELOAD_TABLE':
            return {
                ...state,
                reloadTable: action.payload.reloadTable
            }
        case 'USER_AUTHENTICATED':
            return {
                ...state,
                userAuthenticated: action.payload.userAuthenticated
            }
        case 'FORCE_LOGOUT':
            return {
                ...state,
                forceLogout: action.payload.forceLogout
            }
        case 'RELOAD_CESTINO':
            return {
                ...state,
                reloadCestino: action.payload.reloadCestino
            }
        default:
            return state;
    }
};

export default Reducer;
