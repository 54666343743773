import React from 'react';
import Logo from '../../UI/Logo/Logo';
import './UserFooter.css';
import PostFooter from './PostFooter';

function UserFooter() {

    return (
        <div className="UserFooter" id="UserFooter">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12" style={{ text: "white" }}>
                        <div className="it-header-center-content-wrapper">
                            <Logo />
                        </div>
                    </div>
                </div>
                <PostFooter />
            </div>
        </div>
    )
}

export default UserFooter;
