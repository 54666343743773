import React, { useState, useEffect, useContext, Fragment } from 'react';
import MenuSection from "../EditMenuMotoreDiRicerca/MenuSection/MenuSection";
import TableRicerca from "../../UI/TableRicerca/TableRicerca"
import { Icon } from 'design-react-kit';
import { Redirect } from 'react-router-dom';
import Card from '../../UI/Card/Card';
import Publisher from '../../UI/Publisher/Publisher';
import useModal from '../../../hook/useModal';
import { Messages } from '../../Utils/Messages';
import useObjectsArray from "../../../hook/useObjectsArray";
import './MotoreDiRicerca.css';
import {
    KO_RELEASE,
    KO_OPERATION,
    socialEnum,
    OK_RELEASE,
    operazioniContenuto,
    areeTerritorialiEnum,
    roleEnum,
    typeOrder
} from '../../Utils/Dataset';
import { showLoading, hideLoading, showSuccess } from '../../../hook/globalState/Store';
import { Context, showError } from '../../../hook/globalState/Store';
import { hasErrorOutcome, hasErrorOutcomeAPMS } from "../../Interceptor/Interceptor";
import MotoreRicercaRepository from "../../../Repo/MotoreRicercaRepository";
import UtentiRepository from "../../../Repo/UtentiRepository";
import { mapGetSiti, mapGetCategorieFiltriFromBE, mapGetCategorieFiltriForBE, mapGetSitiForBE } from "../../../Repo/Utils";
import { headerMsg, headerMsgAddSito, headerMsgNessunSitoPresente, headerMsgRegionale } from './MotoreDiRicercaUtils';
import CustomModal from "../../CustomModal/CustomModal";
import moment from "moment";
import MenuRepository from "../../../Repo/MenuRepository";
import { alertLevels } from "../../UI/InPageAlert/InPageAlert";
import SessioneUtente from '../../../sessionManager/SessioneUtente';
import { isUtenteRegionale } from '../../Utils/Utils';
import { validateSitiMotoreDiRicerca } from '../../UI/FieldValidation/FieldValidation';


export default function MotoreDiRicerca(props) {

    const [state, dispatch] = useContext(Context);
    const session = SessioneUtente.getInstance();
    const [title, setTitle] = useState(null);

    // Gestione finestre modali
    const [action, setAction] = useState("");
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [redirect, setRedirect] = useState(false);

    // Gestione caricamento dati
    const [contentFetched, setContentFetched] = useState(false);
    const [dataModifica, setDataModifica] = useState(null);
    const [lingua, setLingua] = useState(null);
    const [utenteModifica, setUtenteModifica] = useState(null);
    const [voci, setVoci, removeVoce, addVoce, updateVoce] = useObjectsArray([], null, null); // Contiene le voci della sezione
    const [siti, setSiti, removeSito, addSito, updateSito] = useObjectsArray([], null, null); // Contiene i siti
    // Contiene Header
    const [headers] = useState([
        { key: "sito", name: "Indirizzo sito", sortDirection: typeOrder.NONE },
        { key: "titolo", name: "Titolo", sortDirection: typeOrder.NONE },
        { key: "utenteModifica", name: "Inserito da", sortDirection: typeOrder.NONE },
        { key: "modifica", name: "Modifica" }]);
    const [newSiti, setNewSiti, removeNewSito, addNewSito, updateNewSito, validateNewSiti] = useObjectsArray([], validateSitiMotoreDiRicerca, null); // Contiene i siti da aggiungere
    const [editSiti, setEditSiti] = useState([]); // Contiene i siti da aggiungere
    const [utentiList, setUtentiList] = useState([])

    const [sitiRemove, setSitiRemove, removeSitiRemove, addSitiRemove, updateSitiRemove] = useObjectsArray([], null, null)

    let [count, setCount] = useState(0)

    // Gestione reload EditMenu
    useEffect(() => {
        if (state.reloadEditMenu) {
            setContentFetched(false)
            dispatch({ type: 'RELOAD_EDITMENU', payload: { reloadEditMenu: false } });
        }

    }, [state.reloadEditMenu])

    useEffect(() => {
        if (state.reloadTable) {
            //setContentFetched(false)
            dispatch({ type: 'RELOAD_TABLE', payload: { reloadTable: false } });
        }
    }, [state.reloadTable])

    useEffect(() => {
        if (!contentFetched) {
            showLoading()
            onLoadHandler(setContentFetched, setLingua, setVoci, setSiti, setDataModifica, setUtenteModifica, setUtentiList)
            setTitle("MOTORE DI RICERCA")
        }
        else {
            hideLoading()
        }
    }, [contentFetched])


    // Gestione pulsanti
    const btnPrimary = [
        {
            id: "salva", color: "secondary", outline: true, title: "Salva modifiche", disabled: hasError(),
            onClickHandler: () => {
                getModal(setAction, "Salva", handleShow)
                //     newMenuSection(voci, updateVoce)
                //     let filtri = mapGetCategorieFiltriForBE(voci)
                //     MotoreRicercaRepository().aggiornaCategorie(filtri)
                //         .then(result => {
                //             if (result && !hasErrorOutcome(result)) {
                //                 dispatch({type: 'RELOAD_EDITMENU', payload: {reloadEditMenu: true}});
                //                 let newSitiBe = mapGetSitiForBE(newSiti, editSiti, sitiRemove)
                //                 MotoreRicercaRepository().putSiti(newSitiBe)
                //                     .then(result => {
                //                         if ((result.status && result.status == 200) || !result.status) {
                //                             setContentFetched(false)
                //                             setNewSiti([])
                //                             setCount(0)
                //                             showSuccess()
                //                         } else {
                //                             showError(KO_RELEASE)
                //                         }
                //                         newMenuSection(voci, updateVoce)
                //                     }).catch((err) => {
                //                     showError(KO_RELEASE)
                //                 })
                //             }
                //         }).catch((err) => {
                //             showError(KO_OPERATION)
                //         }
                //     )
            }
        },
        {
            id: operazioniContenuto.RIPRISTINA.toLowerCase(), color: "secondary", outline: false, disabled: false, title: operazioniContenuto.RIPRISTINA,
            onClickHandler: () => {
                MotoreRicercaRepository().getSiti() // mcok chiamate per sezione
                    .then(result => {
                        setContentFetched(false)
                        setSiti(result)
                        setSitiRemove([])
                        dispatch({ type: 'RELOAD_TABLE', payload: { reloadTable: true } });
                    });
                /* Da collegare al servizio */
            }
        }
    ]

    const menuContents = {
        contentFetched, voci, setVoci, updateVoce, title: props.title
    }

    const editComponent = <MenuSection menuContents={menuContents} />

    function hasError() {
        let valid = true;
        valid = !validateNewSiti.includes(false)
        return !valid
    }

    function addRow() {
        setCount(count + 1);
        let row = {
            "idSito": null,
            "sito": "",
            "titolo": "",
            "utenteModifica": "",
            "stato": true,
            "modifica": "check",
        }
        addNewSito(row)
    }
    function removeRow(row, id) {
        if (id === "newSiti") {
            setCount(count - 1);
            removeNewSito(newSiti.indexOf(row))
        } else if (id === "siti") {
            if (!sitiRemove.includes(row.idSito)) {
                row["modifica"] = false
                sitiRemove.push(row.idSito)
                setSitiRemove([...sitiRemove])
            }
            else {
                removeSitiRemove(sitiRemove.indexOf(row.idSito))
            }

        }
        dispatch({ type: 'RELOAD_TABLE', payload: { reloadTable: true } });
    }

    function change(row) {
        if (!row["modifica"])
            row["modifica"] = true
        else
            row["modifica"] = false

        dispatch({ type: 'RELOAD_TABLE', payload: { reloadTable: true } });
    }
    function setValore(e, key, row, id) {
        let list = []
        if (id === "siti")
            list = siti;
        else if (id === "newSiti")
            list = newSiti

        row[key] = e.target.value
        list[list.indexOf(row)] = row

        if (id === "siti") {
            let sitoModificato = {}
            sitoModificato["idSito"] = row.idSito
            sitoModificato["sito"] = row.sito
            sitoModificato["titolo"] = row.titolo
            sitoModificato["utenteModifica"] = row.utenteModifica
            sitoModificato["stato"] = row.stato
            let i
            for (i = 0; i < editSiti.length; i++) {
                if (editSiti[i].idSito == row.idSito)
                    break
                else
                    i++
            }
            editSiti.splice(i, 1, sitoModificato)
            setEditSiti([...editSiti])
        }
        dispatch({ type: 'RELOAD_TABLE', payload: { reloadTable: true } });
    }

    function tableCell(key, row, id) {
        //let rows= row.clone(mapGetSiti(row))
        let cell;
        // if(!row["modifica"])
        //     row["modifica"]= false
        let findUtenteFromNomeCognome = utentiList?.find(el => (el.nome + " " + el.cognome) === row.utenteModifica);
        let rowToFilter = { ...row, ruoloUtenteModifica: findUtenteFromNomeCognome?.codicegruppo, regioneUtenteModifica: findUtenteFromNomeCognome?.listaAttributi[0]?.valore }

        if (key === "modifica") {
            if (!row["modifica"])
                cell = (<td>
                    {((!isUtenteRegionale(rowToFilter.ruoloUtenteModifica) && (!isUtenteRegionale(session.ruoliUtente))) || rowToFilter?.regioneUtenteModifica === (session.idAreaTerritoriale)) &&
                        <>
                            {!sitiRemove.includes(row.idSito) ?
                                <Icon
                                    id={"updateIcon"}
                                    className="right"
                                    icon="it-pencil"
                                    onClick={() => {
                                        change(row, key)
                                    }}
                                /> : <Fragment />}
                            <Icon
                                color="black" icon="it-delete" id="deleteAttachment"
                                onClick={() => {
                                    removeRow(row, id)
                                }}
                            />
                        </>
                    }
                </td>)
            else {
                cell = (<td>
                    <Icon
                        color="black" icon="it-delete" id="deleteAttachment"
                        onClick={() => {
                            removeRow(row, id)
                        }}
                    />
                </td>)

            }
        }
        else if (key === "utenteModifica" || ((key === "sito" || key === "titolo") && !row["modifica"])) {
            cell = (<td className={key}><span className={sitiRemove.includes(row.idSito) ? "removed" : null}>{row[key]}</span></td>)
        }
        else if ((key === "sito" || key === "titolo") && row["modifica"]) {
            cell = (<>
                <td>
                    <span className={sitiRemove.includes(row.idSito) ? "removed" : null}>
                        <input
                            type="textarea"
                            rows="2"
                            value={row[key]}
                            onChange={(e) => setValore(e, key, row, id)} />
                    </span>
                </td>
            </>)
        }
        return cell
    }

    return (
        redirect ? <Redirect to="/" /> :
            <>
                <div id="motoreDiRicerca">
                    <div className="row">
                        <div id={"headerText"} className="col-9">
                            <h5>{title?.toUpperCase()}</h5>
                        </div>
                        <div id={"goBack"} className={'col-3'}>
                            <a id={"buttonBack"} onClick={() => getModal(setAction, operazioniContenuto.INDIETRO, handleShow)}> <Icon className="right" color="black" icon="it-arrow-left" id="arrow" />Indietro</a>
                        </div>

                        <div className="col-9">
                            <div id={"infoTextMenu"}>
                                {dataModifica !== null ?
                                    <div id="lastEdit">
                                        <span>Ultimo aggiornamento</span>: {dataModifica.replace(/(\d{4})-(\d{2})-(\d{2})/, "$3-$2-$1")}, <span>da </span> {utenteModifica}
                                        <br></br>
                                    </div> :
                                    null
                                }
                                <i>{isUtenteRegionale(session.ruoliUtente) ? headerMsgRegionale : headerMsg}</i>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div id={"headerText"} className="col-9">
                            <h6>SITI CERTIFICATI</h6>
                            <div className="row">
                                <div id={"headerText"} className="pl-1">
                                    <div id={"infoText"} style={{ display: siti.length > 0 ? "none" : "" }}>
                                        <i>{headerMsgNessunSitoPresente}</i>
                                    </div>
                                    <TableRicerca
                                        id={"siti"}
                                        elements={siti}
                                        renderCell={tableCell}
                                        sorting={true}
                                        headers={headers}
                                        addItemButtonFunction={addRow}
                                        pagination={10}
                                    />
                                </div>
                            </div>
                            {count > 0 ?
                                <> <div className="row">
                                    <div id={"headerText"} className="pl-1">
                                        <div id={"infoText"}>
                                            <i>{headerMsgAddSito}</i>
                                        </div>
                                    </div>
                                </div>
                                    <div className="row">
                                        <div id={"headerText"} className="pl-1">
                                            <TableRicerca
                                                id={"newSiti"}
                                                elements={newSiti}
                                                headers={headers}
                                                renderCell={tableCell}
                                                pagination={count + 1}
                                                renderPagination={() => { return <></> }}
                                            />
                                            {validateNewSiti.includes(false) && <i className="invalid-link">
                                                Inserire dei siti validi che inizino con "http://" o "https://"
                                                e terminano con un suffisso di dominio (esempio ".com")</i>}
                                        </div>
                                    </div> </> : null
                            }
                            {!isUtenteRegionale(session.ruoliUtente) ? // Gestione filtri deve essere visualizzata solo per la Redazione centrale
                                <>
                                    <div className="row" style={{ marginTop: "1rem" }}>
                                        <div id={"headerText"} className="pl-1">
                                            <h6>GESTIONE FILTRI</h6>
                                        </div>
                                    </div>
                                    {editComponent}
                                </> : <></>
                            }
                        </div>
                        <div className="col-3">
                            <CustomModal
                                show={show}
                                onHide={handleClose}
                                onConfirm={() => callBackAndClose(handleClose, setRedirect, action, voci, updateVoce, dispatch, newSiti, editSiti, sitiRemove, setContentFetched, setNewSiti, setCount)}
                                title={action === operazioniContenuto.INDIETRO ? "Ritorno alla pagina principale" : action + " in corso"}
                                text={getModalMessage(action)}
                                body={action === operazioniContenuto.ANTEPRIMA ? <iframe title={"Preview"} src={"https://preview.wafept.pdt.18-196-167-173.nip.io/"} width={"100%"} height={"100%"} /> : null}
                            />
                            <Card title={operazioniContenuto.PUBBLICAZIONE}>
                                <Publisher arrayPrimary={btnPrimary} arraySecondary={[]} />
                            </Card>
                        </div>
                    </div>
                </div>
            </>
    )
}


function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}

function callBackAndClose(handleClose, setRedirect, action, voci, updateVoce, dispatch, newSiti, editSiti, sitiRemove, setContentFetched, setNewSiti, setCount) {
    if (action === operazioniContenuto.INDIETRO) {
        handleClose();
        setRedirect(true);
    }
    if (action === "Salva") {
        newMenuSection(voci, updateVoce)
        let filtri = mapGetCategorieFiltriForBE(voci)
        MotoreRicercaRepository().aggiornaCategorie(filtri)
            .then(result => {
                if (result && !hasErrorOutcome(result)) {
                    dispatch({ type: 'RELOAD_EDITMENU', payload: { reloadEditMenu: true } });
                    let newSitiBe = mapGetSitiForBE(newSiti, editSiti, sitiRemove)
                    MotoreRicercaRepository().putSiti(newSitiBe)
                        .then(result => {
                            if ((result.status && result.status == 200) || !result.status) {
                                setContentFetched(false)
                                setNewSiti([])
                                setCount(0)
                                showSuccess()
                            }
                            newMenuSection(voci, updateVoce)
                        })
                }
            })
        handleClose();

    }

}

function getModalMessage(action) {
    switch (action) {
        case operazioniContenuto.INDIETRO:
            return Messages.ERR_02_02_0L_0A
        case "Salva":
            return "Salvataggio delle modifiche per il motore di ricerca. Sei sicuro di voler procedere?"
        default:
            return Messages.INFO_DEFAULT
    }
}

function onLoadHandler(setContentFetched, setLingua, setVoci, setSiti, setDataModifica, setUtenteModifica, setUtentiList) {

    Promise.all([MotoreRicercaRepository().getCategorie(), MotoreRicercaRepository().getSiti(), UtentiRepository().getUsers({ applicazione: "PDT", listaAttributi: [], codiceGruppo: "" })])
        .then(([resultCategorie, resultSiti, utenti]) => {

            if (resultCategorie && resultCategorie.length > 0) {
                setVoci(mapGetCategorieFiltriFromBE(resultCategorie))
                setDataModifica(resultCategorie[0].dataUltimoSalvataggio)
                setUtenteModifica(resultCategorie[0].utenteModifica)
            }
            else showError(KO_OPERATION)

            if ((resultSiti.status && resultSiti.status === 200) || !resultSiti.status) setSiti(mapGetSiti(resultSiti))
            else showError(KO_OPERATION)

            if ((utenti && !hasErrorOutcomeAPMS(utenti))) setUtentiList(utenti)
            else showError(KO_OPERATION)

        })
        .catch(err => hideLoading())
        .finally(() => {
            setContentFetched(true);
            hideLoading()
        })
}

function newMenuSection(voci, updateVoce) {
    voci.map((el, i) => {
        updateVoce(i, "posizione", i)
        if (el.sottovoci !== null) {
            el.sottovoci.map((el2, i2) => {
                updateVoce(i, "sottovoci", i2, i2, "posizione")
            })
        }
    })
    return Object.freeze({ voci })
}
