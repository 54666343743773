import React from "react";
import './AttachmentsTable.css'
import { Icon } from "design-react-kit";
import CustomInput from "../CustomInput/CustomInput";
import { getFileNameFromPath } from "../../Utils/Utils";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BUCKET_URL } from "../../../config/config";

export default function AttachmentTable(props) {

    const updateAttachmentFileCopied = (indexToUpdateFileCopied) => {
        let indexToDeleteFileCopied = props.attachments?.findIndex(el => el.fileCopied === true);

        if (indexToDeleteFileCopied !== -1 && indexToDeleteFileCopied !== indexToUpdateFileCopied) props.updateAttachment(indexToDeleteFileCopied, "fileCopied", false)

        props.updateAttachment(indexToUpdateFileCopied, "fileCopied", true)
    }

    return (
        <div id="attachmentTableContainer">
            {props.label && <label id={"attachmentTableLabel"} className={"fieldLabel"}>{props.label}</label>}
            <table className={"col-12"} id={"attachmentTable"}>
                {props.attachments.map((el, i) => (
                    <tbody key={i}>
                        <tr id={"attachmentTableRow"}>
                            <td>
                                <label id={"attachmentTableLabel"} className={"fieldLabelSmaller"}>{"Descrizione"}</label>
                            </td>
                            <td>
                                <label id={"attachmentTableLabel"} className={"fieldLabelSmaller"}>{"Allegato"}</label>
                            </td>
                            <td></td>
                        </tr>
                        <tr id={"attachmentTableRow"}>
                            <td>
                                <CustomInput readonly={props.readonly} name={el.descrizione} hideLabel labelName="Descrizione" id={"inputTable"} change={(e) => {
                                    props.updateAttachment(i, "descrizione", e.target.value)
                                }}
                                    invalidMsg={(el.descrizione !== null && el.descrizione?.length !== 0 && el.path !== null) ?? false ? null : "Valorizzare il campo Descrizione ed inserire un allegato"}
                                    valid={(el.descrizione !== null && el.descrizione?.length !== 0 && el.path !== null) ?? false}
                                />
                            </td>
                            <td>
                                {el.path ? (
                                    <span id={"filename"}>
                                        {!props.readonly ? <Icon className="left" color="black" icon="it-clip" id="arrowRight" /> : null}
                                        {getFileNameFromPath(el.path)}
                                    </span>
                                ) : props.attachmentLoader(i)}
                            </td>
                            <td>
                                {!props.readonly ? <Icon color="black" icon="it-delete" id="deleteAttachment" onClick={() => props.removeAttachment(i)} /> : null}
                            </td>
                            <td>
                                {el.path && <div title={"Copia link del file."}><CopyToClipboard text={BUCKET_URL + el.path} onCopy={() => updateAttachmentFileCopied(i)}><Icon icon="it-copy" id="copyAttachment" /></CopyToClipboard></div>}
                            </td>
                            <td>
                                {el.fileCopied && <i style={{ fontSize: "0.8rem" }}>Copiato.</i>}
                            </td>
                        </tr>
                    </tbody>
                ))}
            </table>
            {props.addElementRender}
        </div>
    )
}
