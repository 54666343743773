import React from "react";
import './OverlayModal.css'

export default function OverlayModal(){
    return (
        <div className="overlayModal modal fade show modal-backdrop"
             backdrop={"static"}
             style={{display:"block"}}
             id="loadMe"
             tabIndex="-1"
             role="dialog"
             aria-labelledby="loadMeLabel">
            <div className="modal-dialog modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-body text-center">
                        <div className="text-center">
                            <div className="spinner-border" role="status" />
                            <p style={{color: "white"}}>Operazione in corso</p>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    )
}
