import React, { useState, useEffect } from 'react';
import './PageItem.css';
import { Icon, Toggle } from 'design-react-kit';
import TextEditor from '../TextEditor/TextEditor';
import ReactQuill from 'react-quill';
import Select from '../Select/Select';
import useInput from '../../../hook/useInput';

export default function PageItem(props) {

    // Gestione custom width dell'input
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext("2d");

    // Tooltips
    const tooltipModifica = "Modifica";
    const tooltipInterrompiModifica = "Interrompi Modifica";
    const tooltipElimina = "Elimina";

    // State
    const [customIconUpdate, setCustomIconUpdate] = useState()
    const [updateLabel, setUpdateLabel] = useState(false)
    const [contentFetched, setContentFetched] = useState(false)
    const [selectValue, setSelectValue, selectValueChangeHandler] = useInput("");

    useEffect(() => {
        if (!contentFetched) {
            onLoadHandler(setCustomIconUpdate, setContentFetched)
        }
    }, [contentFetched])

    return (
        <>
            <div id="pageItem">
                <div className="topLabel">
                    <label>{"Pagina " + (props.index + 1)}</label>
                </div>
                <hr />
                <div className="labelInput">
                    <input
                        style={{ width: ctx.measureText(props.label).width * 2 + 25 + "px", maxWidth: "60%", minWidth: "20%" }}
                        value={props.label}
                        onChange={(e) => props.updateData(props.index, "label", e.target.value)}
                        disabled={!updateLabel}
                        placeholder={"Inserisci un'etichetta"}
                    />
                    <a data-toggle="tooltip" data-selector="true" title={!updateLabel ? tooltipModifica : tooltipInterrompiModifica}>
                        <Icon
                            color={customIconUpdate?.color}
                            icon={customIconUpdate?.icon}
                            id={customIconUpdate?.id}
                            onClick={() => {
                                setUpdateLabel(!updateLabel)
                                changeIcon(updateLabel, setCustomIconUpdate)
                            }}
                        />
                    </a>
                    <a data-toggle="tooltip" data-selector="true" title={tooltipElimina}>
                        <Icon
                            icon="it-delete"
                            id="deleteForm"
                            style={{ marginLeft: "10px" }}
                            onClick={() => props.removeItem(props.index)}
                        />
                    </a>
                    <Toggle
                        label={props.visibile ? "Visibile" : "Non visibile"}
                        checked={props.visibile}
                        onChange={() => props.updateData(props.index, "visibile", !props.visibile)}
                    />
                </div>
                <div className="linkCollegato">
                    <Select
                        title={"Link collegato"} placeholder={"Selezionare un link collegato..."}
                        array={props.optionsLinks} value={props.linkCollegato}
                        change={(e) => props.updateData(props.index, "linkCollegato", e.target.value)}
                        fieldValue={"linkCollegato"} fieldName={"linkCollegato"}
                    />
                </div>
                <div className="titoloItem">
                    <label>Titolo</label>
                    <input
                        id={props.label + props.index}
                        value={props.titolo}
                        onChange={(e) => props.updateData(props.index, "titolo", e.target.value)}
                        className={"form-control"}

                    />
                </div>
                <div className="descrizioneItem">
                    <label>Descrizione</label>
                    <ReactQuill
                        label={"Descrizione"}
                        value={props.testo}
                        onChange={(e, delta, source, editor) => {
                            if (source === 'user') {
                                props.updateData(props.index, "testo", e)
                            }
                        }}

                    />
                </div>
            </div>
        </>
    )
}

function onLoadHandler(setCustomIconUpdate, setContentFetched) {
    setCustomIconUpdate({
        color: "black",
        icon: "it-pencil",
        id: "pencil"
    })
    setContentFetched(true)
}

function changeIcon(updateLabel, setCustomIconUpdate) {
    if (!updateLabel) {
        setCustomIconUpdate({
            color: "success",
            icon: "it-check",
            id: "check"
        })
    }
    else {
        setCustomIconUpdate({
            color: "black",
            icon: "it-pencil",
            id: "pencil"
        })
    }
}