import React, { useState, useLayoutEffect } from 'react';
import { Icon } from 'design-react-kit';
import './DragDropItem.css';

export default function DragDropItem(props) {

    const tooltipDelete = "Nascondi";
    const tooltipMostra = "Mostra";
    const tooltipModifica = "Modifica";
    const tooltipInterrompiModifica = "Interrompi modifica";

    const [hideElement, setHideElement] = useState(false)
    const [customIcon, setCustomIcon] = useState({ icon: "it-password-invisible", color: "black" });


    useLayoutEffect(() => {
        if (props.visibile !== undefined) {
            setHideElement(!props.visibile)
            if (props.visibile === true) {
                setCustomIcon({ icon: "it-password-invisible", color: "black" })
            }
            else {
                setCustomIcon({ icon: "it-password-visible", color: "black" })
            }
        }
    }, [props.visibile])

    return (
        <div id="dragDropItem">
            <div className={'row'}>
                <div className={'col-7 align-middle'}>
                    <Icon id={hideElement ? "primaryBurgerIcon-disable" : "primaryBurgerIcon"} className="left" icon="it-burger" />
                    <input
                        className={hideElement ? "input-disable" : "input"}
                        id={props.id}
                        defaultValue={props.sezione ? props.sezione : ""}
                        onChange={(e) => props.updateItem(props.index, "etichetta", e.target.value)}
                        disabled={!props.active} // - Attiva/disattiva contemporaneamente textarea&input
                    />
                </div>

                {props.notEditable || props.versioneRegionale ? <></> :
                    <>
                        <div className={'col-2 align-middle'}>
                            <a data-toggle="tooltip" data-selector="true" title={props.active ? tooltipInterrompiModifica : tooltipModifica}>
                                <Icon
                                    id={hideElement ? "updateIcon-disable" : "updateIcon"}
                                    className="right"
                                    color={props.active && "success"}
                                    icon={props.active ? "it-check" : "it-pencil"}
                                    onClick={() => {
                                        if (props.setActive) {
                                            if (props.active)
                                                props.setActive(null)
                                            else props.setActive(props.id)
                                        }
                                    }}
                                />
                            </a>
                            <a data-toggle="tooltip" data-selector="true" title={hideElement ? tooltipMostra : tooltipDelete}>
                                <Icon
                                    id={"deleteIcon"}
                                    className="right"
                                    color={customIcon.color}
                                    icon={customIcon.icon}
                                    onClick={(e) => {
                                        change(setHideElement, hideElement)
                                        changeIcon(hideElement, setCustomIcon)
                                        props.updateItem(props.index, "visibile", hideElement)
                                        if (props.setActive) props.setActive(null)
                                    }}
                                />
                            </a>
                        </div>
                        {props.description &&
                            <div className={'col-6 align-middle'} id={'dragDropItemDescription'}>
                                <textarea
                                    disabled={!props.active}
                                    rows={props.active ? 5 : 1}
                                    //onFocus={() => props.setActive(props.id)}
                                    onBlur={() => props.setActive(null)}
                                    onChange={(e) => props.updateItem(props.index, "testo", e.target.value)}
                                    value={props.testo ? props.testo : ""}
                                />
                            </div>}
                    </>}
            </div>
        </div>
    )
}

function change(setState, state) {
    setState(!state)
}

function changeIcon(hideElement, setCustomIcon) {
    if (hideElement) {
        setCustomIcon({ icon: "it-password-invisible", color: "black" })
    }
    else
        setCustomIcon({ icon: "it-password-visible", color: "black" })
}
