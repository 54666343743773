import React from 'react';
import LogoPortale from '../../img/logos/logo-portale.png';
import './Logo.css';

function Logo() {
    return (
        <div className="it-brand-wrapper" id="logo">
            <a href="#">
                <img className="img-fluid" src={LogoPortale} />
                <div className="it-brand-text pt-3">
                    <h4>Portale della Trasparenza dei Servizi per la Salute</h4>
                </div>
            </a>
        </div>
    )
}

export default Logo;