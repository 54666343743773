import React from 'react';
import { Toggle } from 'design-react-kit';
import 'react-quill/dist/quill.snow.css';
import './Template_01.css';
import CustomInput from '../../UI/CustomInput/CustomInput';
import FileLoader from '../../UI/FileLoader/FileLoader';
import ButtonLink from '../../UI/ButtonLink/ButtonLink';
import SocialSharing from '../../UI/SocialSharing/SocialSharing';
import TextEditor from '../../UI/TextEditor/TextEditor';
import Index from '../../UI/Index/Index';
import { assetsEnum, uploadLimits } from "../../Utils/Dataset";
import { uploadImage } from '../../Utils/Utils';
import { BUCKET_URL } from "../../../config/config";
import CustomAppWrapper from '../../UI/CustomAppWrapper/CustomAppWrapper';
import { Messages } from '../../Utils/Messages';
import AttachmentTable from '../../UI/AttachmentsTable/AttachmentsTable';
import { addNewAttachmentRow, attachmentLoader } from '../TemplateUtils';


export default function Template_01(props) {

    const uploadFileCopertina = (file) => uploadImage(file, props.content.setFileCopertina, uploadLimits.IMG_COPERTINA)
    const uploadFileImgBox = (file) => uploadImage(file, props.content.setFileImgBox)

    return (
        <div id={"Template1"}>
            <div className="inputBoxWithShadow">
                <CustomInput id="input1" labelName="Titolo*" change={props.content.titleChangedHandler} name={props.content.title} valid={props.content.validateTitle} />
                <CustomInput id="input2" labelName="Sottotitolo" change={props.content.subTitleChangedHandler} name={props.content.subTitle} valid={props.content.validateSubTitle} />
                <CustomInput id="input3" labelName="DESCRIZIONE (non visibile sul Portale)" change={props.content.descriptionChangedHandler} name={props.content.description} valid={props.content.validateDescription} textarea />
                <CustomInput id="input4" labelName="Url*" change={props.content.pathChangedHandler} name={props.content.path} valid={props.content.validatePath} tipologia={props.content.tipologia} setPath={props.content.setPath} invalidMsg={props.content.invalidMsg ?? props.content.invalidMsg} setInvalidMsg={props.content.setInvalidMsg} />
            </div>
            <FileLoader labelName="IMMAGINE DI COPERTINA*" tip={Messages.SIZE_CONSIGLIATA_IMMAGINE_COPERTINA} idLoad="upload1" change={(e) => props.content.onChangeCurrentAttachment(e, (fileBin, fileMetadata) => uploadFileCopertina(fileBin))} name={props.content.fileCopertina} valid={props.content.validateCopertina} alternativeText={props.content.alternativeTextImgCopertina} changeAlternativeText={props.content.alternativeTextImgCopertinaChangedHandler} setFile={props.content.setFileCopertina} type={assetsEnum.IMMAGINI}
                loadedAssets={props.content.loadedAssets} preview previewUri={props.content.fileCopertina ? BUCKET_URL + props.content.fileCopertina : null}
            />
            <FileLoader labelName="immagine box di lancio*" tip={Messages.SIZE_CONSIGLIATA_IMMAGINE_LANCIO} idLoad="imgBoxLancio" change={(e) => props.content.onChangeCurrentAttachment(e, (fileBin, fileMetadata) => uploadFileImgBox(fileBin))} name={props.content.fileImgBox} valid={props.content.validateImgBox} alternativeText={props.content.alternativeTextImgBox} changeAlternativeText={props.content.alternativeTextImgBoxChangedHandler}
                loadedAssets={props.content.loadedAssets} preview previewUri={props.content.fileImgBox ? BUCKET_URL + props.content.fileImgBox : null} setFile={props.content.setFileImgBox} type={assetsEnum.IMMAGINI}
            />

            <div className={'inputBoxWithShadow'}>
                <ButtonLink links={props.content.pulsantiBlu} click={props.content.addLinkHandler} delete={props.content.removeLinkHandler} updateValue={props.content.updatePulsantiBlu} name="barra pulsanti blu" initialize="Non sono presenti pulsanti blu." valid={props.content.validatePulsantiBlu} messaggioValidazione={Messages.VALIDAZIONE_INDIRIZZO_INTERNO_ESTERNO} />
            </div>

            <label className="text-type">testo*</label>
            <TextEditor isErasable={false} array={props.content.testo} click={props.content.addTestoHandler} delete={props.content.removeTestoHandler} updateValue={props.content.updateTestoHandler} valid={props.content.validateTesto} indexOfPage={props.content.index} />
            <div id={"pageIndex"} className={'inputBoxWithShadow'}>
                <Toggle label={"Indice della pagina"} checked={props.content.index} onChange={props.content.setIndex} />
                <div className={'d-flex'}>
                    {props.content.index && <Index array={props.content.testo} />}
                </div>
            </div>

            <div className="inputBoxWithShadow">
                <CustomAppWrapper name="APPLICAZIONE CUSTOM" options={props.content.arrayCustomAppApprofondimenti} option={props.content.option} change={props.content.optionChangeHandler} array={props.content.customApps} click={props.content.addCustomApp} delete={props.content.removeCustomApp} restore={props.content.restoreDescrizione} disable={props.content.disableDescrizione}
                    isToggleLinkVisibile={false} toggleNameCta={"CTA a fondo testo"} toggleNameLink={"Link a inizio pagina"} changeToggleCta={props.content.changeCta} changeToggleLink={props.content.changeLinkToggle} changeDescrizioneCustomApp={props.content.changeDescrizioneCustomApp} changeLinkDaCollegare={props.content.changeLinkDaCollegare} valid={props.content.validateApplicazioneCustom} infoMessage={Messages.INFO_APPLICAZIONE_CUSTOM} />
            </div>

            <div className={"inputBoxWithShadow"}>
                <AttachmentTable label={"Allegati"}
                    attachments={props.content.attachments}
                    attachmentLoader={(index) => attachmentLoader(index, props.content.onChangeCurrentAttachment, props.content.updateAttachment)}
                    removeAttachment={props.content.removeAttachment}
                    addElementRender={addNewAttachmentRow(props.content.addAttachment)}
                    updateAttachment={props.content.updateAttachment}
                />
            </div>

            <div className={'inputBoxWithShadow'}>
                <ButtonLink links={props.content.contenutiCorrelati} click={props.content.addContenutiCorrelatiHandler} delete={props.content.removeContenutiCorrelatiHandler} updateValue={props.content.updateContenutiCorrelati} name="contenuti correlati" initialize="Non sono presenti contenuti correlati." valid={props.content.validateContenutiCorrelati} messaggioValidazione={Messages.VALIDAZIONE_INDIRIZZO_INTERNO} />
                <ButtonLink links={props.content.sitiCertificati} click={props.content.addSitiCertificatiHandler} delete={props.content.removeSitiCertificatiHandler} updateValue={props.content.updateSitiCertificati} name="altri siti certificati" initialize="Non sono presenti altri siti certificati." valid={props.content.validateSitiCertificati} messaggioValidazione={Messages.VALIDAZIONE_INDIRIZZO_INTERNO_ESTERNO} />
            </div>

            <div className={'inputBoxWithShadow'}>
                <SocialSharing labelName={"CONDIVISIONE SUI SOCIAL"} valueUrl={props.content.path} socials={props.content.socials} updateSocial={props.content.updateSocial} titolo={props.content.title} descrizione={props.content.description} immagine={props.content.fileCopertina} onChangeCurrentAttachment={props.content.onChangeCurrentAttachment} loadedAssets={props.content.loadedAssets} />
            </div>
        </div>
    )
}
