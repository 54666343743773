import React from 'react';
import { Redirect } from 'react-router-dom';
import SessioneUtente from '../../../sessionManager/SessioneUtente';
import { roleEnum } from '../../Utils/Dataset';

export default function AuthenticatedHome(props) {

    const userRoles = SessioneUtente.getInstance().ruoliUtente?.split(";");

    if (userRoles) {
        if (authRegionale(SessioneUtente.getInstance().ruoliUtente)) {
            return (
                <Redirect to={"/home-regionale"} />
            )
        } else {
            return <Redirect to={"/home"} />
        }
    } else {
        return (
            <Redirect to={"/error-auth"} />
        )
    }
}

function authRegionale(role) {
    if (role === roleEnum.AUTORE || role === roleEnum.COLLABORATORE) {
        return true;
    } else {
        return false;
    }
}