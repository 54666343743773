import React from "react";
import './TrashBin.css'
import Checkbox from "../Checkbox/Checkbox";
import { templatesEnum } from "../../Utils/Dataset";
import { Icon } from "design-react-kit";

export default function TrashBinCard(props) {
    return (
        <>
            <div id="trashBinCardContainer" className={"galleryCardContainer"}>
                {!props.seleziona &&
                    <Checkbox id={"trashCard" + props.index} options={[{ "checked": props.checked }]}
                        change={() => props.change(props.index)} />
                }
                <div id={"textDiv"}>
                    <p id={"template"}>{templatesEnum.getNomeFromTipologia(props.content.tipologia)}</p>
                    <p id={"title"}>{props.content.nome}</p>
                </div>
                <span className={"galleryCardDescription"}>{props.content.sottotitolo}</span>
                <p id={"timeToDelete"}>{props.content.giorniRimanenti}</p>
            </div>
        </>
    )
}