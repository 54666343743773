const authConfig = {
    //ATTENZIONE!!! NON COMMENTARE NULLA DI QUESTO FILE, MODIFICARE IL FILE DI .ENV SPECIFICO E RIESEGUIRE LA BUILD
    //I FILE SONO NELLA ROOT DEL PROGETTO, SI POSSONO CREARE DEI PROBLEMI NEI DEPLOY DIMENTICANDO UN COMMENTO !!!

    "attivazione-auth": (process.env.REACT_APP_ATTIVAZIONE_AUTH === "true"),
    "url-redirect-auth": process.env.REACT_APP_APP_URL_LOGIN,
    "url-request-authToken": process.env.REACT_APP_REQUEST_AUTH_TOKENS,
    "url-refresh-authToken": process.env.REACT_APP_REFRESH_TOKENS,
    /* "url-request-samlToken": process.env.REACT_APP_REQUEST_SAML_TOKENS, */
    "url-logout": process.env.REACT_APP_APP_URL_LOGOUT,
    "url-redirect-home": process.env.REACT_APP_APP_URL_HOME,
    "SessionTimeOut": 180, //minutes,
    "SessionExpirationTime": 10 //hours
}

export default authConfig