import React from 'react';
import { Button, Chip, ChipLabel, Icon } from 'design-react-kit';
import './Tag.css';
import InfoModal from '../InfoModal/InfoModal';

export default function Tag(props) {

    const infoText = "Per inserire un nuovo Tag digitare il nome del Tag nella casella di testo e premere il pulsante 'Aggiungi'."

    return (
        <fieldset id={props.id}>
            <div>
                <label className={"fieldLabel"}>TAG</label>
                {props.infoMessage && <InfoModal message={props.infoMessage} />}
            </div>
            <div className="Tag">
                {props.dynamicOptions &&
                    <div className={"customInput"}>
                        <input
                            readOnly={props.readonly}
                            id="tagInput"
                            type="text"
                            value={props.value}
                            onChange={props.change}
                            className={props.error ? 'is-danger' : ''}

                        />
                        <Button id={"saveButton"} color={"secondary"} tag="button" disabled={!props.value ? true : false} onClick={(event) => {
                            if (props.value) {
                                event.preventDefault()
                                props.onAdd(props.value)
                                props.change({ target: { value: "" } })
                            }
                        }}>
                            {"Aggiungi"}
                        </Button>
                        {props.error && <p>{props.errorMsg}</p>}
                    </div>
                }
                {props.options ? props.options?.map((el, index) => (
                    <Chip key={index} color="" disabled={false} large={false} simple={false} tag="div">
                        <ChipLabel tag="span">
                            {el}
                        </ChipLabel>
                        <Button close color="secondary" icon={false} tag="button" onClick={() => props.onDelete(index)}>
                            <Icon color="" icon="it-close" padding={false} size="" />
                        </Button>
                    </Chip>
                )) : null}
            </div>
        </fieldset>
    )
}
