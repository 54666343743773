import { React, useCallback, useState } from 'react';

export default function useCustomApp(initialValue, validateCustomApp) {

    const blankElement = { descrizione: '', option: '', disabled: true, fondoTesto: false, inizioPagina: false, link: '' }

    const [customApps, setCustomApps] = useState(initialValue);
    const addCustomApp = e => setCustomApps([...customApps, { ...blankElement, id: customApps.length + 1 }]);

    let validateResult = validateCustomApp ? validateCustomApp(customApps) : null;

    const removeCustomApp = (id, e) => {
        let array = [...customApps];
        const index = array.findIndex((el) => el.id === id)
        if (index !== -1)
            array.splice(index, 1);
        setCustomApps(array);
    }

    const changeCta = ((id) => {
        let array = [...customApps];
        const index = array.findIndex((el) => el.id === id)
        if (index !== -1)
            array[index].fondoTesto = !array[index].fondoTesto;
        setCustomApps(array);
    })

    const changeLink = ((id) => {
        let array = [...customApps];
        const index = array.findIndex((el) => el.id === id)
        if (index !== -1)
            array[index].inizioPagina = !array[index].inizioPagina;
        setCustomApps(array);
    })

    const changeLinkDaCollegare = ((id, e) => {
        let array = [...customApps];
        const index = array.findIndex((el) => el.id === id)
        if (index !== -1)
            array[index].link = e.target.value;
        setCustomApps(array);
    })

    const disableDescrizione = (id) => {
        let array = [...customApps];
        const index = array.findIndex((el) => el.id === id)
        if (index !== -1)
            array[index].disabled = false;

        setCustomApps(array);
    }

    const restoreDescrizione = (id) => {
        let array = [...customApps];
        const index = array.findIndex((el) => el.id === id)
        if (index !== -1)
            array[index].descrizione = " ";

        setCustomApps(array);
    }

    const changeDescrizione = (id, e) => {
        let array = [...customApps];
        const index = array.findIndex((el) => el.id === id)
        if (index !== -1)
            array[index].descrizione = e.target.value;

        setCustomApps(array);
    }

    return [customApps, setCustomApps, addCustomApp, removeCustomApp, changeDescrizione, restoreDescrizione, disableDescrizione, changeCta, changeLink, changeLinkDaCollegare, validateResult];
}