import React, { useState, useEffect, useCallback, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Icon } from "design-react-kit";
import CustomModal from "../../CustomModal/CustomModal";
import useModal from "../../../hook/useModal";
import Card from "../../UI/Card/Card";
import Publisher from "../../UI/Publisher/Publisher";
import { Messages } from "../../Utils/Messages";
import './EditHomepage.css';
import SimpleFileLoader from "../../SimpleFileLoader/SimpleFileLoader";
import {
    getFileExtension,
    getFileNameFromPath,
    getMessageUploadError,
    reorder
} from "../../Utils/Utils";
import ReorderList from "./ReorderList";
import useFileHandler from "../../../hook/useFileHandler";
import { assetsEnum, extensionsFile, OK_RELEASE, statoContenuto, operazioniContenuto, linguaEnum, tipologiaEnum, maxElementi } from "../../Utils/Dataset";
import { hideLoading, showError, showLoading, showSuccess, Context } from "../../../hook/globalState/Store";
import FileRepository from "../../../Repo/FileRepository";
import { useGetHomePage, } from "./EditHomePageHooks";
import useObjectsArray from "../../../hook/useObjectsArray";
import HomepageRepository from "../../../Repo/HomepageRepository";
import { hasErrorOutcome } from "../../Interceptor/Interceptor";
import { useContenutiOrdinati } from "../../UI/Menu/MenuHooks";
import { BUCKET_URL, WAFE_PREVIEW_URL } from "../../../config/config";
import ModalFile from "../../ModalFile/ModalFile";
import moment from 'moment';
import { DraggableRenderer } from "../../DragDrop/DragDropUtils";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { getMessaggioOperazione } from "../../../Repo/Utils";
import PreviewModal from "../../PreviewModal/PreviewModal";


export default function EditHomepage(props) {

    const [redirect, setRedirect] = useState(false);
    const history = useHistory();
    const [state, dispatch] = useContext(Context);
    const [action, setAction] = useState("");
    const [show, , handleClose, handleShow] = useModal(false);
    const [dataModifica, setDataModifica] = useState(null);
    const [utenteModifica, setUtenteModifica] = useState(null);

    const headerMsg = "In questa pagina puoi gestire la disposizione dei contenuti in home page. Ricordati di selezionare pubblica per permettere la pubblicazione della modifica sul portale."

    //CONSTS
    const N_APPROFONDIMENTI = 8
    const PAGINAZIONE = 5
    const N_COMEFAREPER = 4
    const N_NEWS = 4


    //GESTIONE DATI
    const [homepage, homePageLoaded, setHomepage, setHomepageLoaded] = useGetHomePage() //Contenuto homepage
    const [background, setBackground] = useFileHandler()
    const [immagineCopertina, setImmagineCopertina] = useState()

    //TUTTI I CONTENUTI
    const [approfondimenti, loadedApprofondimenti, setApprofondimenti, setApprofondimentiLoaded] = useContenutiOrdinati({ tipologia: tipologiaEnum.APPROFONDIMENTO }, { numeroElementi: maxElementi, inVersionePubblica: true, ascending: "false", sort: "dataInserimento" })
    const [comeFarePer, loadedComeFarePer, setComeFarePer, setComeFarePerLoaded] = useContenutiOrdinati({ tipologia: tipologiaEnum.COME_FARE_PER }, { numeroElementi: maxElementi, inVersionePubblica: true, ascending: "false", sort: "dataInserimento" })
    const [news, loadedNews, setNews, setNewsLoaded] = useContenutiOrdinati({ tipologia: tipologiaEnum.NEWS }, { numeroElementi: maxElementi, inVersionePubblica: true, ascending: "false", sort: "dataInserimento" })

    //CONTENUTI IN HOME PAGE
    const [savedApprofondimenti, setSavedApprofondimenti] = useObjectsArray([])
    const [savedComeFarePer, setSavedComeFarePer] = useObjectsArray([])
    const [savedNews, setSavedNews] = useObjectsArray([])

    const hasError = () => {
        return !background
    }

    //HANDLERS///////////////////////////////////////////////////////////////////////////////////////
    useEffect(showLoading, [])

    useEffect(() => {
        if (homePageLoaded) {
            setImmagineCopertina(homepage.immagineCopertina)
            setBackground(homepage.immagineCopertina?.path)
            setDataModifica(homepage.dataModifica)
            setUtenteModifica(homepage.utenteModifica)

            setSavedApprofondimenti(homepage.approfondimentiPrincipali ? homepage.approfondimentiPrincipali : [])
            setSavedComeFarePer(homepage.comeFarePer ? homepage.comeFarePer : [])
            setSavedNews(homepage.news ? homepage.news : [])
        }

    }, [homePageLoaded])

    const mapToContenutoHomepageDTO = (contenuto, index) => {
        return {
            idContenuto: contenuto.id,
            pathContenuto: contenuto.path,
            immagineContenuto: contenuto.immagineCopertina,
            titoloContenuto: contenuto.titolo,
            testoContenuto: [...(contenuto?.testo) ?? [], ...(contenuto?.testoInferiore) ?? []],
            posizione: index,
            nomeContenuto: contenuto.nome,
            lingua: contenuto.lingua
        }
    }

    const mapToHomepageDTO = () => {
        return {
            ...homepage,
            immagineCopertina,
            approfondimentiPrincipali: savedApprofondimenti,
            comeFarePer: savedComeFarePer,
            news: savedNews
        }
    }

    const handleSave = () => HomepageRepository().aggiornaHomepage(mapToHomepageDTO())
        .then(data => {
            if (!hasErrorOutcome(data)) {
                showSuccess()
            }
        })
        .finally(() => dispatch({ type: 'RELOAD_HOMEPAGE', payload: { reloadHomepage: true } }))



    ///////////////////////////////////////////////////////////////////////////////////////

    /* GESTIONE BACKGROUND */
    const uploadBackground = (file) => {
        showLoading()
        if (file.size >= 2097152) {
            showError(Messages.ERR_IMAGE_DIMENSION)
            setBackground(null)
            setImmagineCopertina(
                {
                    "testoAlternativo": null,
                    "path": null,
                    "descrizione": null
                }
            )
        }
        else {
            FileRepository().uploadFile(assetsEnum.IMMAGINI, file).then(data => {
                setBackground(data?.object?.filename)
                setImmagineCopertina(
                    {
                        "testoAlternativo": file.name,
                        "path": data?.object?.filename,
                        "descrizione": file.name
                    }
                )
            })
        }
        hideLoading()
    }

    const updateBackground = e => {
        if (extensionsFile.IMMAGINI.includes(getFileExtension(e.target.files[0]))) {
            uploadBackground(e.target.files[0])
        } else showError(getMessageUploadError("immagine", extensionsFile.IMMAGINI))
    }


    //Sfoglia
    const [showBrowse, handleShowBrowse, handleCloseBrowse] = useModal(false);
    const [bucketBrowse, setBucketBrowse] = useState([])
    const PAGINAZIONE_IMMAGINI = 5

    useEffect(() => {
        FileRepository().getFile(assetsEnum.IMMAGINI)
            .then(data => {
                setBucketBrowse(data.objects ? data.objects : []);
            });
    }, [immagineCopertina])


    const backgroundLoader = (
        <div id="fileLoader">
            <label className="label-text" id="etichetta">{props.labelName}</label>
            <div className="row align-items-center">
                <div>
                    <input style={{ minWidth: "10%" }} className="mr-1" type="text" readOnly value={getFileNameFromPath(background) !== null && getFileNameFromPath(background)} />
                </div>
                <div>
                    <div id={"buttonLoad"}>
                        <input
                            type="file"
                            name={"backgroundLoad"}
                            id={"backgroundLoad"}
                            className="upload"
                            onChange={updateBackground}
                        />
                        <button type="button" id="btnCarica" className="ml-3 btn btn-secondary" htmlFor="backgroundLoad"
                            onClick={() => document.getElementById('backgroundLoad').click()}>Carica
                        </button>
                        <ModalFile
                            show={showBrowse}
                            onHide={handleCloseBrowse}
                            title={"Immagini presenti nella collezione"}
                            array={bucketBrowse}
                            pagination={PAGINAZIONE_IMMAGINI}
                            onConfirm={(fileName) => {
                                setBackground(fileName)
                                handleCloseBrowse()
                                setImmagineCopertina(
                                    {
                                        "testoAlternativo": getFileNameFromPath(fileName),
                                        "path": fileName,
                                        "descrizione": getFileNameFromPath(fileName)
                                    }
                                )
                            }}
                        />
                    </div>
                    <div id={"buttonSfoglia"}>
                        <Button id="btnSfoglia" color={"secondary"} tag="button" onClick={handleShowBrowse}>Sfoglia</Button>
                    </div>
                </div>
            </div>
        </div>
    )

    const backgroundStyle = {
        "backgroundImage": background ? `url(${encodeURI(BUCKET_URL + background)}` : '#',
        "backgroundSize": "contain",
        "backgroundPosition": "center right",
        "backgroundRepeat": "no-repeat"
    }

    ///////////////////////////////////////////////////////////////////////////////////

    const btnPrimary = [
        { id: "1", color: "secondary", outline: true, title: "Salva modifiche", disabled: hasError(), onClickHandler: () => handleSave(), disabledTooltip: Messages.INFO_DISABLED_SALVA_MODIFICHE },
        {
            id: "reloadPage", color: "secondary", title: operazioniContenuto.ANNULLA_MODIFICHE, disabled: false,
            onClickHandler: () => { getModal(setAction, operazioniContenuto.ANNULLA_MODIFICHE, handleShow) }
        },
        { id: "3", color: "secondary", outline: false, title: "Visualizza anteprima", disabled: false, onClickHandler: () => getModal(setAction, operazioniContenuto.ANTEPRIMA, handleShow) }
    ]
    const btnSecondary = [
        { id: "4", color: "secondary", outline: false, title: operazioniContenuto.PUBBLICAZIONE, disabled: hasError(), onClickHandler: () => getModal(setAction, operazioniContenuto.PUBBLICAZIONE, handleShow) }
    ]

    //GESTIONE GENERICA CONTENUTI //////////////////////////

    const filtraNonPubblicatiEMappaHomepageDTO = (contenuti) => {
        return contenuti
            .filter(el => el.stato === statoContenuto.PUBBLICATO && el.lingua === linguaEnum.ITALIANO)
            .map((el, index) => mapToContenutoHomepageDTO(el, index))
    }
    const filtraContenutiAggiunti = (contenuti, savedContenuti) => {
        return contenuti.filter(el => savedContenuti.map(innel => innel.idContenuto).indexOf(el.idContenuto) === -1)
    }
    const reorderContenuti = (result, savedContenuti, setSavedContenuti) => {
        if (!result.destination) {
            return;
        }

        const newArrayItems = reorder(
            savedContenuti,
            result.source.index,
            result.destination.index
        );

        setSavedContenuti(newArrayItems)
    }

    const updateContenuti = (index, nContenuti, contenuti, setContenuti, setSavedContenuti, savedContenuti) => {

        let newSavedContenuti
        let newContenuti = contenuti

        if (savedContenuti.length < nContenuti) {
            newSavedContenuti = [...savedContenuti, contenuti[index]]
            setSavedContenuti(newSavedContenuti)
        }
        else {
            let rimosso = savedContenuti[nContenuti - 1]
            newSavedContenuti = [...(savedContenuti.slice(0, nContenuti - 1)), contenuti[index]]
            setSavedContenuti(newSavedContenuti)
            contenuti.splice(index, 1)
            newContenuti = [rimosso, ...contenuti]
            setContenuti(newContenuti)
        }
        setContenuti([...filtraContenutiAggiunti(newContenuti, newSavedContenuti)]) //aggiorna la lista dei contenuti che si possono aggiungere
    }

    //Al caricamento della pagina, filtra tutti gli approfondimenti non pubblicati e mappa i restanti in ContenutoHomePageDTO
    //Poi filtra quelli già aggiunti alla lista della homepage
    useEffect(() => {
        if (loadedApprofondimenti && (savedApprofondimenti === homepage?.approfondimentiPrincipali))
            setApprofondimenti(filtraContenutiAggiunti(filtraNonPubblicatiEMappaHomepageDTO(approfondimenti), savedApprofondimenti))
        else setApprofondimentiLoaded(false)
    }, [loadedApprofondimenti])


    useEffect(() => {
        if (loadedComeFarePer && (savedComeFarePer === homepage?.comeFarePer))
            setComeFarePer(filtraContenutiAggiunti(filtraNonPubblicatiEMappaHomepageDTO(comeFarePer), savedComeFarePer))
        else setComeFarePerLoaded(false)
    }, [loadedComeFarePer])


    useEffect(() => {
        if (loadedNews && (savedNews === homepage?.news)) {
            setNews(filtraContenutiAggiunti(filtraNonPubblicatiEMappaHomepageDTO(news), savedNews))
            hideLoading()
        }
        else setNewsLoaded(false)
    }, [loadedNews])

    useEffect(() => {
        if (state.reloadHomepage) {
            setApprofondimentiLoaded(false)
            setComeFarePerLoaded(false)
            setNewsLoaded(false)
            setHomepageLoaded(false)
            if (!loadedApprofondimenti && !loadedComeFarePer && !loadedNews) {
                dispatch({ type: 'RELOAD_HOMEPAGE', payload: { reloadHomepage: false } });
            }
        }
    }, [state.reloadHomepage])


    const updateComeFarePer = (index) => updateContenuti(index, N_COMEFAREPER, comeFarePer, setComeFarePer, setSavedComeFarePer, savedComeFarePer)
    const updateApprofondimenti = (index) => updateContenuti(index, N_APPROFONDIMENTI, approfondimenti, setApprofondimenti, setSavedApprofondimenti, savedApprofondimenti)
    const updateNews = (index) => updateContenuti(index, N_NEWS, news, setNews, setSavedNews, savedNews)

    const reorderApprofondimentiHandler = useCallback((result) => reorderContenuti(result, savedApprofondimenti, setSavedApprofondimenti), [savedApprofondimenti]);
    const reorderComeFarePerHandler = useCallback((result) => reorderContenuti(result, savedComeFarePer, setSavedComeFarePer), [savedComeFarePer]);
    const reorderNewsHandler = useCallback((result) => reorderContenuti(result, savedNews, setSavedNews), [savedNews]);

    const removeContenuto = (index, contenuti, savedContenuti, setContenuti, setSavedContenuti) => {
        contenuti.push(savedContenuti[index])
        savedContenuti.splice(index, 1)
        setSavedContenuti([...savedContenuti])
        setContenuti([...contenuti])
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////

    const sezioneApprofondimenti = (
        <div className={"row contentRow col-12 d-flex"}>
            <div className={"col-8"}>
                <h5>APPROFONDIMENTI</h5>
                <DragDropContext onDragEnd={reorderApprofondimentiHandler}>
                    <Droppable direction={"horizontal"} droppableId={"approfondimentiDroppable1"}>
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <div className={"row"} id={"approfondimentiBig"}>
                                    {savedApprofondimenti.slice(0, 3).map((el, index) =>
                                        <DraggableRenderer
                                            draggableId={"reorder" + index + "draggable"}
                                            index={index}
                                            key={"reorder-" + index + "-draggable"}
                                        >
                                            {approfondimentoBigCard(el.nomeContenuto, () => removeContenuto(index, approfondimenti, savedApprofondimenti, setApprofondimenti, setSavedApprofondimenti))}

                                        </DraggableRenderer>
                                    )}
                                </div>
                            </div>
                        )}
                    </Droppable>
                    <Droppable direction={"horizontal"} droppableId={"approfondimentiDroppable2"}>
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {savedApprofondimenti.slice(3, 8).length > 0 &&
                                    <div className={"row"} id={"approfondimentiSmall"}>
                                        {savedApprofondimenti.slice(3, 8).map((el, index) =>
                                            <DraggableRenderer
                                                draggableId={"reorder" + (index + 3) + "draggable"}
                                                index={(index + 3)}
                                            >
                                                {approfondimentoSmallCard(el.nomeContenuto, () => removeContenuto(index + 3, approfondimenti, savedApprofondimenti, setApprofondimenti, setSavedApprofondimenti))}
                                            </DraggableRenderer>
                                        )}
                                    </div>
                                }
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <div className={"col-4"}>
                <label className="title-add">Approfondimenti da aggiungere</label>
                {loadedApprofondimenti &&
                    <ReorderList
                        disabled
                        showPlusButton
                        pagination={PAGINAZIONE}
                        filter
                        elements={approfondimenti}
                        id={"approfondimenti"}
                        unshiftElement={(index) => updateApprofondimenti(index)}
                    />
                }
            </div>
        </div>
    )

    const sezioneComeFarePer = (
        <div className={"row contentRow col-12 d-flex"} id={"comeFarePerContainer"}>
            <div className={"col-8"}>
                <h5>COME FARE PER</h5>
                <DragDropContext
                    onDragEnd={reorderComeFarePerHandler}>
                    <Droppable
                        direction={"horizontal"}
                        droppableId={"comeFarePerDroppable"}>
                        {(provided, snapshot) => (
                            <div
                                className={'d-flex'}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {savedComeFarePer.slice(0, N_COMEFAREPER).map((el, index) =>
                                    <DraggableRenderer
                                        draggableId={"reorder" + index + "draggable"}
                                        index={index}
                                        key={"draggable-" + index}
                                    >
                                        {comeFarePerElement(el.nomeContenuto, () => removeContenuto(index, comeFarePer, savedComeFarePer, setComeFarePer, setSavedComeFarePer))}
                                    </DraggableRenderer>
                                )}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <div className={"col-4"}>
                <label className="title">Come fare per da aggiungere</label>
                {loadedComeFarePer &&
                    <ReorderList
                        disabled
                        showPlusButton
                        pagination={PAGINAZIONE}
                        filter
                        elements={comeFarePer}
                        id={"comeFarePer"}
                        unshiftElement={(index) => updateComeFarePer(index)}
                    />
                }
            </div>
        </div>

    )

    const sezioneNews = (
        <div className={"row contentRow col-12 d-flex"}>
            <div className={"col-8"} id={"newsContainer"}>
                <h5>NEWS</h5>
                <DragDropContext
                    onDragEnd={reorderNewsHandler}>
                    <Droppable
                        direction={"horizontal"}
                        droppableId={"newsDroppable"}>
                        {(provided, snapshot) => (
                            <div
                                className={'d-flex'}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {savedNews.slice(0, N_NEWS).map((el, index) =>
                                    <DraggableRenderer
                                        draggableId={"reorder" + index + "draggable"}
                                        index={index}
                                    >
                                        {newsCard(el.nomeContenuto, () => removeContenuto(index, news, savedNews, setNews, setSavedNews))}
                                    </DraggableRenderer>
                                )}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <div className={"col-4 pl-3"}>
                <label className="title">News da aggiungere</label>
                <div>
                    {loadedNews &&
                        <ReorderList
                            filter
                            disabled
                            showPlusButton
                            pagination={PAGINAZIONE}
                            elements={news}
                            id={"news"}
                            unshiftElement={(index) => updateNews(index)}
                        />
                    }
                </div>
            </div>
        </div>
    )

    return (
        redirect ? <Redirect to="/" /> :
            <>
                <div id="editHomepage">
                    <div className="row">
                        <div className={'col-6'}>
                            <h6>{props.title.toUpperCase()}</h6>
                        </div>
                        <div id={"goBack"} className="col-6">
                            <a id={"buttonBack"} onClick={() => getModal(setAction, operazioniContenuto.INDIETRO, handleShow)}>
                                <Icon className="right" color="black" icon="it-arrow-left" id="arrow" />
                                Indietro
                            </a>
                        </div>
                    </div>
                    {action === operazioniContenuto.ANTEPRIMA ?
                        <PreviewModal
                            show={show}
                            onHide={handleClose}
                            title={"Anteprima"}
                            body={<iframe title={"Preview"} src={WAFE_PREVIEW_URL} width={"100%"} height={"100%"} />}
                        /> : <CustomModal
                            show={show}
                            onHide={handleClose}
                            onConfirm={() => callBackAndClose(handleClose, setRedirect, action, history, mapToHomepageDTO())}
                            title={action === operazioniContenuto.INDIETRO ? "Ritorno alla pagina principale" : action + " in corso"}
                            text={getModalMessage(action)}
                        />}
                    <div className="row mt-5">
                        <div id={"headerText"} className="col-9">
                            <div id={"infoText"}>
                                {dataModifica !== null ?
                                    <div id="lastEdit">
                                        <span>Ultimo aggiornamento</span>: {moment(dataModifica).format("DD-MM-YYYY")}, <span>da </span> {utenteModifica}
                                        <br></br>
                                    </div> :
                                    null
                                }
                                <i>{headerMsg}</i>
                            </div>
                        </div>
                        <div className="col-3 cards">
                            <Card title="Gestione homepage">
                                <Publisher arrayPrimary={btnPrimary} arraySecondary={btnSecondary} />
                            </Card>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-12">
                            <div id={"contentContainer"}>
                                <div id={"cambiaSfondo"} className={"row"} style={backgroundStyle}>
                                    <SimpleFileLoader
                                        loaderLayout={backgroundLoader}
                                    />
                                </div>
                                {!background && <div className={'customInput mr-5 mt-2 text-right'}>
                                    <p>E' necessario selezionare un'immagine di sfondo</p>
                                </div>}
                                {sezioneApprofondimenti}
                                {sezioneComeFarePer}
                                {sezioneNews}
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}

function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}

function callBackAndClose(handleClose, setRedirect, action, history, content) {
    if (action === operazioniContenuto.INDIETRO) {
        handleClose();
        setRedirect(true);
    } else if (action === operazioniContenuto.PUBBLICAZIONE) {
        HomepageRepository().pubblicaHomepage(content)
            .then(data => {
                if (!hasErrorOutcome(data)) {
                    showSuccess(OK_RELEASE)
                }
            })
        handleClose()
    }

    if (action === operazioniContenuto.ANNULLA_MODIFICHE) {
        history.go(0)
        handleClose();
    }
}

function getModalMessage(action) {
    switch (action) {
        case operazioniContenuto.INDIETRO:
            return Messages.ERR_02_00_0A_0A
        case operazioniContenuto.ANNULLA_MODIFICHE:
            return Messages.INFO_ANNULLA_MODIFICHE
        default:
            return Messages.INFO_DEFAULT
    }
}

const approfondimentoBigCard = (title, removeSelf) => (
    <div className={"approfondimentoBigCard"}>
        <Icon color="black" icon="it-close-circle" id="arrowRight" className={'float-right'} onClick={removeSelf} />
        <p>{title}</p>
    </div>
)

const approfondimentoSmallCard = (title, removeSelf) => (
    <div className={"approfondimentoSmallCard"}>
        <Icon color="black" icon="it-close-circle" id="arrowRight" className={'float-right'} onClick={removeSelf} />
        <p>{title}</p>
    </div>
)

const comeFarePerElement = (title, removeSelf) => (
    <div className={"comeFarePerElement"}>
        <Icon color="black" icon="it-close-circle" id="arrowRight" className={'float-right'} onClick={removeSelf} />
        <p>{title}</p>
    </div>
)

const newsCard = (text, removeSelf) => (
    <div className={"newsCard"}>
        <Icon color="black" icon="it-close-circle" id="arrowRight" className={'float-right'} onClick={removeSelf} />
        <p>{text}</p>
    </div>
)
