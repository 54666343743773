import React, { useState, useEffect, useContext } from 'react';
import useModal from '../../../../hook/useModal';
import './AggiungiStrutturaCovid.css'
import Card from '../../../UI/Card/Card';
import Publisher from '../../../UI/Publisher/Publisher';
import Scheduled from '../../../UI/Scheduled/Scheduled';
import useInput from '../../../../hook/useInput';
import validate, { validateCAP, validateEmail, validateTelephone, validateUrl } from '../../../UI/FieldValidation/FieldValidation';
import LinkItem from '../../../UI/LinkItem/LinkItem'
import CustomInput from '../../../UI/CustomInput/CustomInput';
import useObjectsArray from '../../../../hook/useObjectsArray';
import {
    blankAssociazione,
    operazioniAssociazione,
    KO_OPERATION, OK_OPERATION, roleEnum
} from '../../../Utils/Dataset';
import { Button, Icon } from 'design-react-kit';
import { Link, Redirect } from 'react-router-dom';
import StruttureRepository from '../../../../Repo/StruttureRepository';
import SessioneUtente from '../../../../sessionManager/SessioneUtente';
import StruttureRegionali from '../StruttureRegionali/StruttureRegionali';
import StrutturaCovidForm from './StrutturaCovidForm';
import { Context, hideLoading, showError, showSuccess } from '../../../../hook/globalState/Store';
import CustomModal from '../../../CustomModal/CustomModal';
import { Messages } from '../../../Utils/Messages';
import { hasErrorOutcome } from '../../../Interceptor/Interceptor';
import { alertLevels } from '../../../UI/InPageAlert/InPageAlert';
import useToggle from '../../../../hook/useToggle';
import { rejectNaN, validateTelephoneNumber } from '../../../Utils/Utils';

export default function AggiungiStrutturaCovid(props) {

    //Gestione finestre modali
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [action, setAction] = useState("");
    const [state, dispatch] = useContext(Context);
    const [redirect, setRedirect] = useState(false);

    // Headers
    const headerText = "Modifica i contenuti"
    const headerMsg = "Qui puoi inserire i siti covid-19 di riferimento. Se non inserisci alcun contenuto, questo non sarà visibile dal cittadino nelle sezioni specifiche."


    // Gestione caricamento/gestione dati strutturaCovid
    const idStrutturaCovid = props?.match?.params?.id_struttura_covid;
    let session = SessioneUtente.getInstance();
    let role = session.ruoliUtente;
    const idAreaTerritoriale = session.idAreaTerritoriale;
    const [contentFetched, setContentFetched] = useState(false)
    const [strutturaCovid, setStrutturaCovid] = useState();

    // Sezione con campi dell'strutturaCovid da compilare
    const [nominativo, setNominativo, nominativoChangedHandler, validateNominativo] = useInput("", validate);
    const [indirizzoWeb, setIndirizzoWeb, indirizzoWebChangedHandler, validateIndirizzoWeb] = useInput("", validateUrl);
    const [visibile, toggle, setVisibile] = useToggle(true)

    useEffect(() => {
        if (contentFetched && strutturaCovid) {
            setNominativo(strutturaCovid.descrizione ? strutturaCovid.descrizione : "")
            setIndirizzoWeb(strutturaCovid?.sito ? strutturaCovid.sito : "")
            setVisibile(strutturaCovid.visibile)

        } else {
            onLoadHandler(setStrutturaCovid, idStrutturaCovid, blankAssociazione, setContentFetched)
        }
    }, [contentFetched])

    var associazioneContent = null;

    if (strutturaCovid != null) {
        associazioneContent = {

            id: idStrutturaCovid,
            nominativo, nominativoChangedHandler, validateNominativo,
            indirizzoWeb, indirizzoWebChangedHandler, validateIndirizzoWeb,
            visibile, toggle,
            rejectNaN, validateTelephoneNumber

        }
    }

    function getModalMessage(action) {
        switch (action) {
            case operazioniAssociazione.ANNULLA:
                return Messages.ANNULLA_STRUTTURA_COVID
            case operazioniAssociazione.CONFERMA:
                return Messages.CONFERMA_STRUTTURA_COVID
            case operazioniAssociazione.ELIMINA:
                return Messages.ELIMINA_STRUTTURA_COVID
            default:
                return Messages.INFO_DEFAULT
        }
    }

    function hasError() {

        let valid = false;

        if (contentFetched) {
            valid = validateNominativo && validateIndirizzoWeb;
        }

        return valid;
    }

    // Gestione pulsanti
    const btnPrimary = [
        {
            id: "salva", color: "secondary", outline: true, title: "Salva Modifiche", disabled: !hasError(),
            onClickHandler: () => {
                if (idStrutturaCovid) {
                    getModal(setAction, operazioniAssociazione.CONFERMA_MODIFICA, handleShow)
                } else {
                    getModal(setAction, operazioniAssociazione.CONFERMA_CREAZIONE, handleShow)
                }
            }
        }
    ]
    const btnSecondary = [
        {
            id: "annulla", color: "secondary", outline: false, title: "Annulla Modifiche", disabled: false,
            onClickHandler: () => {
                getModal(setAction, operazioniAssociazione.ANNULLA, handleShow)
            }
        },
        {
            id: "elimina", color: "outline-primary", title: "Elimina",
            onClickHandler: () => {
                getModal(setAction, operazioniAssociazione.ELIMINA, handleShow)
            }
        }
    ]

    return (
        redirect ? <Redirect to="/siti-covid" /> :
            <div id="addStruttureCovid">
                <div className="row">
                    <div className="col-9">
                        <h5>{headerText}</h5>
                    </div>
                    <div className={'col-3 text-right '}>
                        <Link id="back" to="/siti-covid">
                            <Icon className="left" color="black" icon="it-arrow-left" id="arrow" />Indietro
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-9">
                        <i>{headerMsg}</i>
                        <div id={"idAssociazioni"}>
                            <h6>Sito covid-19</h6>
                        </div>
                        {strutturaCovid ?
                            <StrutturaCovidForm content={associazioneContent} /> : null}
                    </div>
                    <div className="col-3 cards">
                        <Card title={"Modifica"}>
                            <Publisher
                                arrayPrimary={btnPrimary}
                                arraySecondary={btnSecondary}
                            />
                        </Card>
                    </div>
                </div>
                <CustomModal
                    show={show}
                    onHide={handleClose}
                    onConfirm={() => callBackAndClose(inizializzaStrutturaCovid(idStrutturaCovid, nominativo, indirizzoWeb, visibile), idAreaTerritoriale, handleClose, action, setRedirect, dispatch, state, setContentFetched)}
                    title={action + " in corso"}
                    text={getModalMessage(action)}
                    // body={"ciao"}
                    removeButtons={false}
                />
            </div >
    )
}

function callBackAndClose(statoStrutturaCovid, idAreaTerritoriale, handleClose, action, setRedirect, dispatch, state, setContentFetched) {


    if (action === operazioniAssociazione.CONFERMA_CREAZIONE) {
        StruttureRepository().creaStrutturaCovid(idAreaTerritoriale, statoStrutturaCovid)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    setRedirect(true)
                    dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.SUCCESS, alertMsg: OK_OPERATION } });
                    showSuccess()
                } else showError(KO_OPERATION)
            }).catch((err) => showError(KO_OPERATION))
            .finally(hideLoading)
        handleClose();
    }

    else if (action === operazioniAssociazione.CONFERMA_MODIFICA) {
        StruttureRepository().modificaStrutturaCovid(idAreaTerritoriale, statoStrutturaCovid.id, statoStrutturaCovid)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    setRedirect(true)
                    dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.SUCCESS, alertMsg: OK_OPERATION } });
                    showSuccess()
                } else showError(KO_OPERATION)
            }).catch((err) => showError(KO_OPERATION))
            .finally(hideLoading)
        handleClose();
    }

    else if (action === operazioniAssociazione.ELIMINA) {
        StruttureRepository().eliminaStrutturaCovid(idAreaTerritoriale, statoStrutturaCovid.id)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    setRedirect(true)
                    showSuccess()
                    dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.SUCCESS, alertMsg: OK_OPERATION } });
                } else showError(KO_OPERATION)
            }).catch((err) => showError(KO_OPERATION))
            .finally(hideLoading)
        handleClose();
    }


    else if (action === operazioniAssociazione.ANNULLA) {
        setContentFetched(false)
        handleClose();
    }

}

function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}

function onLoadHandler(setStrutturaCovid, idStrutturaCovid, blankAssociazione, setContentFetched) {

    const session = SessioneUtente.getInstance();
    const idAreaTerritoriale = session.idAreaTerritoriale;

    if (idStrutturaCovid != null) {
        StruttureRepository().getStrutturaCovid(idAreaTerritoriale, idStrutturaCovid)
            .then(result => {
                setStrutturaCovid(result)
                setContentFetched(true);
            }).catch(err => err)
    } else {
        setStrutturaCovid(blankAssociazione)
        setContentFetched(true);
    }



}

function inizializzaStrutturaCovid(idStrutturaCovid, nominativo, indirizzoWeb, visibile) {

    return Object.freeze({
        id: idStrutturaCovid ? idStrutturaCovid : 0,
        descrizione: nominativo,
        sito: indirizzoWeb,
        visibile: visibile,
        idAreaTerritoriale: SessioneUtente.getInstance().idAreaTerritoriale
    });
}