import { Button, Icon } from 'design-react-kit';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { hideLoading, showLoading } from '../../../hook/globalState/Store';
import useInput from '../../../hook/useInput';
import useModal from '../../../hook/useModal';
import useToggle from '../../../hook/useToggle';
import FeedbackRepository from '../../../Repo/FeedbackRepository';
import CustomModal from '../../CustomModal/CustomModal';
import CircleContainer from '../../UI/CircleContainer/CircleContainer';
import Table from '../../UI/Table/Table';
import { operazioniContenuto, statoFeedback } from '../../Utils/Dataset';
import { Messages } from '../../Utils/Messages';
import ContatoreFeedback from './ContatoreFeedback/ContatoreFeedback';
import FiltraFeedback from './FiltraFeedback/FiltraFeedback';
import "./GestioneFeedback.css";
import { isObjectNullOrUndefined } from "../../../components/Utils/Utils";
import useObjectsArray from '../../../hook/useObjectsArray';
import moment from 'moment';
import InfoModal from '../../UI/InfoModal/InfoModal';

export default function GestioneFeedback() {

    // State
    const [contentFetched, setContentFetched] = useState(false);
    const [allFeedback, setAllFeedback, , , ,] = useObjectsArray([], null, null);
    const [numeroFeedbackRicevuti, setNumeroFeedbackInseriti] = useState("-");
    const [numeroFeedbackArchiviati, setNumeroFeedbackArchiviati] = useState("-");
    const [sort, setSort] = useState("data");
    const [ascending, setAscending] = useState(false);
    const [numeroPagina, setNumeroPagina] = useState(1);
    const [numeroElementi, setNumeroElementi] = useState(25);
    const [elementiTotali, setElementiTotali] = useState();
    const [pagineTotali, setPagineTotali] = useState();
    const [idFeedback, setIdFeedback] = useState("");

    // Gestione finestre modali
    const [action, setAction] = useState("");
    const [show, , handleClose, handleShow] = useModal(false);
    const [redirect, setRedirect] = useState(false);

    // Consts
    const titleFiltroFeedback = "FILTRA LE SEGNALAZIONI E I SUGGERIMENTI RICEVUTI PER:";
    const headerTitle = "GESTIONE FEEDBACK"
    const colsFiltro = [
        { label: "Data", key: "data" },
        { label: "Tipologia", key: "tipologia" },
        { label: "Testo", key: "testo" },
        { label: "Stato", key: "stato" },
        { label: "", key: "ACTIONS" }
    ]
    const userMsg = (
        <i>{Messages.INFO_USER_MSG_FEEDBACK}</i>
    )
    const elements = [
        {
            id: "2",
            data: "2019-02-03",
            tipologia: "Tipologia",
            testo: "Testo inventato ripetuto Testo inventato ripetuto Testo inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetuto",
            stato: "Aperto"
        },
        {
            id: "6",
            data: "2019-02-03T22:19:03Z",
            tipologia: "Tipologia",
            testo: "Testo inventato ripetuto Testo inventato ripetuto Testo inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetutoTesto inventato ripetuto",
            stato: "Aperto"
        }
    ]
    const arrayTipologieFiltro = [
        {
            id: "CONTENUTI",
            label: "Contenuti"
        },
        {
            id: "NEWS",
            label: "News e Fake news"
        },
        {
            id: "RICERCA",
            label: "Motore di ricerca"
        },
        {
            id: "RICERCA_LEA",
            label: "Ricerca LEA"
        }
    ]


    // Filtro feedback
    const [checkFiltroFeedbackRicevuti, updateCheckFiltroFeedbackRicevuti, setCheckFiltroFeedbackRicevuti] = useToggle(true);
    const [checkFiltroFeedbackArchiviati, updateCheckFiltroFeedbackArchiviati, setCheckFiltroFeedbackArchiviati] = useToggle(true);
    const [tipologiaFiltroFeedback, setTipologiaFiltroFeedback, tipologiaFiltroFeedbackChangeHandler] = useInput("");
    const [dataDaFiltroFeedback, setDataDaFiltroFeedback, dataDaFiltroFeedbackChangeHandler] = useInput("");
    const [dataAFiltroFeedback, setDataAFiltroFeedback, dataAFiltroFeedbackChangeHandler] = useInput("");
    const [arrayTipologieFiltroFeedback, setArrayTipologieFiltroFeedback] = useInput(arrayTipologieFiltro);

    const onClickRicerca = () => {
        setContentFetched(false);
        setNumeroPagina(1);
    }

    const onClickAnnullaFiltri = () => {
        setCheckFiltroFeedbackRicevuti(true);
        setCheckFiltroFeedbackArchiviati(true);
        setNumeroPagina(1);
        setTipologiaFiltroFeedback("");
        setDataDaFiltroFeedback("");
        setDataAFiltroFeedback("");
        setContentFetched(false);
    }

    const archiviaFeedback = (idFeedback) => {
        showLoading();
        FeedbackRepository().archiviaFeedback(idFeedback)
            .finally(() => {
                hideLoading();
                setContentFetched(false);
            })
    }

    const eliminaFeedback = (idFeedback) => {
        showLoading();
        FeedbackRepository().deleteFeedback(idFeedback)
            .finally(() => {
                hideLoading();
                setContentFetched(false);
            })
    }


    // Tabella
    const standardCell = (el, row) => <span dangerouslySetInnerHTML={{ __html: `<span>${row[el.key] ? row[el.key] : ""}</span>` }} />

    const renderRow = (row, rowIndex, cols) => {
        if (!(row && cols)) return <></>
        else return (
            <>
                <tr id={`tr${row.id}`}>
                    {cols.map(col => renderTableFiltro(col, row, rowIndex))}
                </tr>
            </>
        )
    }

    const renderTableFiltro = (col, row, rowIndex) => {
        let cell = standardCell(col, row);

        if (col.key === "ACTIONS") {
            cell = (
                <span className="buttons-table">
                    <div className="mb-1 mt-2">
                        <Button color={"secondary"} disabled={row.stato === statoFeedback.ARCHIVIATO} className="block nowrap-cell btn-filtro" onClick={() => getModal(setAction, operazioniContenuto.ARCHIVIAZIONE_FEEDBACK, handleShow, row.id)}>{"Archivia"}</Button>
                    </div>
                    <div className="mb-2">
                        <Button color={"secondary"} className="block nowrap-cell btn-filtro" onClick={() => getModal(setAction, operazioniContenuto.ELIMINAZIONE, handleShow, row.id)}> {"Elimina"}</Button>
                    </div>
                </span>
            )
        }
        else if (col.key === "data") {
            let dataLocale = moment.utc(row.data).local().format("DD/MM/YYYY");
            cell = (
                <span>{dataLocale}</span>
            )
        }

        return <td key={`td$feedback${rowIndex}-${col.key}`} className={col.key !== "testo" ? "nowrap-cell" : ""}>{cell}</td>
    }

    const renderNoFeedback = () => {
        return (
            <span>
                <strong><i>Nessun feedback da visualizzare.</i></strong>
            </span>
        )
    }


    // Page header
    const pageHeader = (
        <>
            <div className="row mb-1">
                <div className="col-6">
                    <h5>{headerTitle}</h5>
                </div>
                <div id={"goBackFeedback"} className="col-6">
                    <a id={"buttonBack"} onClick={() => getModal(setAction, operazioniContenuto.INDIETRO, handleShow)}> <Icon className="right" color="black" icon="it-arrow-left" id="arrow" />Indietro</a>
                </div>
            </div>
            <div className="row">
                <div className="col-9">
                    {userMsg}
                </div>
            </div>
        </>
    )


    useEffect(() => {
        if (!contentFetched) {

            var queryParams = {
                ricevuti: checkFiltroFeedbackRicevuti,
                archiviati: checkFiltroFeedbackArchiviati,
            }

            if (!isObjectNullOrUndefined(tipologiaFiltroFeedback)) queryParams = { ...queryParams, tipologia: tipologiaFiltroFeedback }
            if (!isObjectNullOrUndefined(dataDaFiltroFeedback)) {
                let dataDaUtc = moment(dataDaFiltroFeedback).utc().format();
                queryParams = { ...queryParams, dataDa: dataDaUtc }
            }
            if (!isObjectNullOrUndefined(dataAFiltroFeedback)) {
                let dataAUtc = moment(dataAFiltroFeedback).endOf('day').utc().format();
                queryParams = { ...queryParams, dataA: dataAUtc }
            }
            if (!isObjectNullOrUndefined(sort)) queryParams = { ...queryParams, sort: sort }
            if (!isObjectNullOrUndefined(ascending)) queryParams = { ...queryParams, ascending: ascending }
            if (!isObjectNullOrUndefined(numeroPagina)) queryParams = { ...queryParams, numeroPagina: numeroPagina }
            if (!isObjectNullOrUndefined(numeroElementi)) queryParams = { ...queryParams, numeroElementi: numeroElementi }

            showLoading();
            onLoadHandler(setContentFetched, queryParams, setAllFeedback, setNumeroFeedbackInseriti, setNumeroFeedbackArchiviati, setElementiTotali, setPagineTotali);
        }
    }, [contentFetched])

    useEffect(() => {
        setContentFetched(false)
    }, [numeroPagina, numeroElementi, sort, ascending])

    return (
        redirect ? <Redirect to="/" /> :
            <div className="gestione-feedback">
                <CustomModal
                    show={show}
                    onHide={handleClose}
                    onConfirm={() => callBackAndClose(handleClose, setRedirect, action)}
                    title={action === operazioniContenuto.INDIETRO ? "Ritorno alla pagina principale" : action + " in corso"}
                    text={getModalMessage(action)}
                />
                <div>
                    {pageHeader}
                </div>
                <div className="mb-5">
                    <ContatoreFeedback numeroFeedbackRicevuti={numeroFeedbackRicevuti} numeroFeedbackArchiviati={numeroFeedbackArchiviati} />
                </div>
                <div className="mb-5">
                    <FiltraFeedback
                        checkFiltroFeedbackRicevuti={checkFiltroFeedbackRicevuti} setCheckFiltroFeedbackRicevuti={updateCheckFiltroFeedbackRicevuti}
                        checkFiltroFeedbackArchiviati={checkFiltroFeedbackArchiviati} setCheckFiltroFeedbackArchiviati={updateCheckFiltroFeedbackArchiviati}
                        title={titleFiltroFeedback} tipologiaFiltroFeedback={tipologiaFiltroFeedback}
                        tipologiaFiltroFeedbackChangeHandler={tipologiaFiltroFeedbackChangeHandler} arrayTipologieFiltroFeedback={arrayTipologieFiltroFeedback}
                        dataDaFiltroFeedback={dataDaFiltroFeedback} dataDaFiltroFeedbackChangeHandler={dataDaFiltroFeedbackChangeHandler}
                        dataAFiltroFeedback={dataAFiltroFeedback} dataAFiltroFeedbackChangeHandler={dataAFiltroFeedbackChangeHandler}
                        onClickRicerca={onClickRicerca} onClickAnnullaFiltri={onClickAnnullaFiltri}
                    />
                </div>
                <div>
                    {allFeedback?.length > 0 ?
                        <Table
                            id={"tableFiltro"}
                            pagination={numeroElementi}
                            // disablePagination={editIndex !== null}
                            elements={allFeedback}
                            cols={colsFiltro}
                            renderRow={renderRow}
                            setPaginaCorrente={setNumeroPagina}
                            paginaCorrente={numeroPagina}
                            totalElements={elementiTotali}
                            nBackendPages={pagineTotali}
                        /> : renderNoFeedback()}
                </div>
            </div>
    )

    function getModal(setAction, azione, handleShow, id) {
        setAction(azione);
        setIdFeedback(id)
        handleShow();
    }

    function callBackAndClose(handleClose, setRedirect, action) {
        if (action === operazioniContenuto.INDIETRO) {
            handleClose();
            setRedirect(true);
        }
        else if (action === operazioniContenuto.ELIMINAZIONE) {
            handleClose();
            eliminaFeedback(idFeedback);
        }
        else if (action === operazioniContenuto.ARCHIVIAZIONE_FEEDBACK) {
            handleClose();
            archiviaFeedback(idFeedback);
        }
    }

    function getModalMessage(action) {
        switch (action) {
            case operazioniContenuto.INDIETRO:
                return Messages.ERR_INDIETRO_FEEDBACK;
            case operazioniContenuto.ELIMINAZIONE:
                return Messages.INFO_ELIMINAZIONE_FEEDBACK
            case operazioniContenuto.ARCHIVIAZIONE_FEEDBACK:
                return Messages.INFO_ARCHIVIAZIONE_FEEDBACK
            default:
                return Messages.INFO_DEFAULT
        }
    }
}

function onLoadHandler(setContentFetched, queryParams, setAllFeedback, setNumeroFeedbackInseriti, setNumeroFeedbackArchiviati, setElementiTotali, setPagineTotali) {
    Promise.all([FeedbackRepository().getAllFeedback(queryParams), FeedbackRepository().getDashboardFeedback()])
        .then(([res1, res2]) => {

            if (res1?.elementi?.length > 0) {
                setElementiTotali(res1?.elementiTotali)
                setPagineTotali(res1?.pagineTotali)
                setAllFeedback(res1?.elementi)
            } else {
                setElementiTotali(0)
                setPagineTotali(0)
                setAllFeedback([])
            }

            if (res2?.numeroFeedbackArchiviati >= 0 || res2?.numeroFeedbackRicevuti >= 0) {
                setNumeroFeedbackInseriti(res2.numeroFeedbackRicevuti)
                setNumeroFeedbackArchiviati(res2.numeroFeedbackArchiviati)
            }

        })
        .finally(() => {
            hideLoading()
            setContentFetched(true)
        })
}