import { Button, Input } from "design-react-kit";
import React from "react";
import Checkbox from "../../../UI/Checkbox/Checkbox";
import Select from "../../../UI/Select/Select";
import "./FiltraFeedback.css";

export default function FiltraFeedback(props) {

    const feedbackRicevuti = [
        {
            checked: props.checkFiltroFeedbackRicevuti,
            label: "Feedback ricevuti"
        }
    ]

    const feedbackArchiviati = [
        {
            checked: props.checkFiltroFeedbackArchiviati,
            label: "Feedback archiviati"
        }
    ]

    return (
        <div className="filtra-feedback">
            <div>
                <span className="title-filtro">{props.title}</span>
            </div>
            <div className="row mt-2 checkbox-filtro">
                <div className="col-auto">
                    <Checkbox options={feedbackRicevuti} id="feedbackRicevuti" change={props.setCheckFiltroFeedbackRicevuti} />
                </div>
                <div className="col-auto">
                    <Checkbox options={feedbackArchiviati} id="feedbackArchiviati" change={props.setCheckFiltroFeedbackArchiviati} />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-3">
                    <div className="row">
                        <div className="col-12">
                            <label>{"Tipologia feedback"}</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Select idOption={"selectId"} placeholder="Selezionare la tipologia..." array={props.arrayTipologieFiltroFeedback} value={props.tipologiaFiltroFeedback} change={props.tipologiaFiltroFeedbackChangeHandler} fieldValue={"id"} fieldName={"label"} />
                        </div>
                    </div>
                </div>
                <div className="col-9">
                    <div className="row">
                        <div className="col-3">
                            <label>{"Data da"}</label>
                        </div>
                        <div className="col-3">
                            <label>{"Data a"}</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <input id="dataDa" type="date" value={props.dataDaFiltroFeedback} onChange={props.dataDaFiltroFeedbackChangeHandler} placeholder="Data da" />
                        </div>
                        <div className="col-3">
                            <input id="dataA" type="date" value={props.dataAFiltroFeedback} onChange={props.dataAFiltroFeedbackChangeHandler} placeholder="Data a" />
                        </div>
                        <div className="col-auto ml-2">
                            <Button variant="secondary" disabled={false} onClick={() => props.onClickRicerca()}>
                                Ricerca
                            </Button>
                        </div>
                        <div className="col-auto ml-2">
                            <Button variant="secondary" disabled={false} onClick={() => props.onClickAnnullaFiltri()}>
                                Annulla filtri
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}