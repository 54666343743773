export const Messages = {

    // Error messages
    "ERR_02_00_0A_0A": "Sei sicuro di voler uscire dal form di modifica della home page del Portale e tornare alla pagina principale? I dati non salvati non potranno essere recuperati",
    "ERR_02_00_0B_0A": "Sei sicuro di voler uscire dal form di modifica del Main menu del Portale e tornare alla pagina principale? I dati non salvati non potranno essere recuperati",
    "ERR_02_00_0C_0A": "Sei sicuro di voler uscire dal form di modifica del Header menu del Portale e tornare alla pagina principale? I dati non salvati non potranno essere recuperati",
    "ERR_02_00_0D_0A": "Sei sicuro di voler uscire dal form di modifica del Footer menu del Portale e tornare alla pagina principale? I dati non salvati non potranno essere recuperati",
    "ERR_TMP_BOTTOM_MENU": "Sei sicuro di voler uscire dal form di modifica del Bottom menu del Portale e tornare alla pagina principale? I dati non salvati non potranno essere recuperati",
    "ERR_02_02_0L_0A": "Sei sicuro di voler uscire dalla modifica delle opzioni del motore di ricerca e tornare alla pagina principale? I dati salvati non potranno essere recuperati.",
    "ERR_FAILED_FETCH": "Errore nella connessione al server",

    "ERR_02_00_0E_0A": "Sei sicuro di voler eliminare definitivamente i contenuti selezionati?",
    "ERR_02_01_0A_0A": "Sei sicuro di voler eliminare il contenuto? Questo non sarà più disponibile",
    "ERR_02_02_0E_0A": "Sei sicuro di voler uscire dal form di modifica dell'applicazione custom per le Prestazioni garantite dal SSN?",
    "ERR_INDIETRO_LEA": "Eventuali modifiche non salvate verranno perse. Sei sicuro di voler uscire dalla pagina attuale?",
    "ERR_TMP_COLLEGAMENTI_SOCIAL": "Sei sicuro di voler uscire dal form di modifica dei Collegamenti Social e tornare alla pagina principale? I dati non salvati non potranno essere recuperati",
    "ERR_TMP_PAGINA_ERRORE": "Sei sicuro di voler uscire dal form di modifica della pagina di errore e tornare alla pagina principale? I dati non salvati non potranno essere recuperati",
    "ERR_TMP_PAGINA_STATICA": "Sei sicuro di voler uscire dal form di modifica della pagina statica e tornare alla pagina principale? I dati non salvati non potranno essere recuperati",
    "ERR_TMP_EDIT_CONTENUTO": "Sei sicuro di voler uscire dal form di modifica del contenuto e tornare alla pagina principale? I dati non salvati non potranno essere recuperati",
    "ERR_TMP_GESTIONE_RUOLI": "Sei sicuro di voler uscire dal form della gestione utenti e tornare alla pagina principale? I dati non salvati non potranno essere recuperati",
    "ERR_CONTENUTO_ELIMINATO": "Il contenuto è in stato 'ELIMINATO'. Occorre ripristinarlo dal Cestino per poter procedere con il salvataggio.",
    "ERR_ELIMINAZIONE_UTENTE": "Sei sicuro di voler eliminare i dati? Questi non saranno più disponibili",
    "ERR_ELIMINAZIONE_CONTENUTO_ITA_CON_ALTRE_LINGUE": "Attenzione: Eliminando questo contenuto verranno eliminati anche le sue versioni in altre lingue. Procedere con l'eliminazione?",
    "ERR_SOSPENSIONE_CONTENUTO_ITA_CON_ALTRE_LINGUE": "Attenzione: Sono presenti contenuti in altra lingua associati a quello corrente, per sospendere tali contenuti sarà necessario sospenderli manualmente",
    "ERR_SOSPENSIONE_CONTENUTO_PADRE": "Attenzione: Sospendendo questo contenuto verranno sospesi anche tutti i dettagli ad esso collegati. Procedere con la sospensione?",
    "ERR_ELIMINA_ASSOCIAZIONE": "Sei sicuro di voler eliminare l'associazione?",
    "ERR_SVUOTAMENTO_CESTINO": "Errore durante lo svuotamento del cestino",
    "ERR_RIPRISTINO_CESTINO": "Errore durante il ripristino del cestino",
    "ERR_TMP_EDIT_LINK_AZIENDALI": "Sei sicuro di voler uscire dal form di modifica dei link aziendali e tornare alla pagina principale? I dati non salvati non potranno essere recuperati",
    "ERR_FILE_DIMENSION": "La dimensione dell’allegato supera il limite consentito (max 10Mb)",
    "ERR_IMAGE_DIMENSION": "La dimensione del file supera il limite consentito (max 2Mb)",
    "ERR_IMGCOPERTINA_DIMENSION": "La dimensione del file supera il limite consentito (max 10mb)",
    "ERR_ICON_DIMENSION": "La dimensione del file supera il limite consentito (max 2Mb)",
    "ERR_ELIMINAZIONE_CTA_NON_CONSENTITA": "Eliminazione non consentita. Questo contenuto risulta raggiungibile da altri contenuti.",
    "ERR_SOSPENSIONE_CTA_NON_CONSENTITA": "Sospensione non consentita. Questo contenuto risulta raggiungibile da altri contenuti.",
    "ERR_ELIMINAZIONE_CC_NON_CONSENTITA": "Eliminazione non consentita. Questo contenuto risulta raggiungibile da altri contenuti.",
    "ERR_SOSPENSIONE_CC_NON_CONSENTITA": "Sospensione non consentita. Questo contenuto risulta raggiungibile da altri contenuti.",
    "ERR_ELIMINAZIONE_CONTENUTO_PADRE": "Eliminazione non consentita. Questo contenuto risulta raggiungibile da altri contenuti.",
    "ERR_INDIETRO_FEEDBACK": "Sei sicuro di voler uscire dalla pagina di gestione dei feedback?",
    "ERR_CAMBIO_PROVENIENZA": "Attenzione: per modificare la provenienza di questo contenuto occorre eliminare le sue versioni in altre lingue",
    "ERR_CAMBIO_FONTE": "Attenzione: per modificare la fonte di questo contenuto occorre eliminare le sue versioni in altre lingue",

    // Info messages
    "INFO_GALLERIA": "Le immagini inserite nella galleria verranno visualizzate in un carosello posizionato tra TESTO SUPERIORE e TESTO INFERIORE.",
    "INFO_CONTENUTO_PADRE": "Digitare almeno tre caratteri appartenenti al titolo o al nome del contenuto quindi selezionare dall'elenco che apparirà il contenuto al quale questo Dettaglio sarà associato. Una volta salvato e pubblicato il Dettaglio corrente, il contenuto padre selezionato sarà visualizzato nel percorso di navigazione del Dettaglio.",
    "INFO_TESTO_COLLASSABILE": "Per il contenuto inserito in questo paragrafo verrà visualizzato soltanto il Titolo Paragrafo. Cliccando sul Titolo Paragrafo l'utente del Portale potrà visualizzare il Testo associato.",
    "INFO_ANNULLA_MODIFICHE": "Annullando le modifiche tornerai alla disposizione presente nell'ultimo salvataggio. Sei sicuro di voler procedere?",
    "INFO_PUBBLICAZIONE": "Pubblicazione di un nuovo contenuto. Sei sicuro di voler procedere?",
    "INFO_PUBBLICAZIONE_CON_VALIDAZIONE": "Il contenuto deve essere validato. Sei sicuro di voler procedere?",
    "INFO_SOSPENSIONE_PUBBLICAZIONE": "Attenzione! Stai procedendo alla sospensione di un contenuto. Ricordati di riorganizzare la visualizzazione della home page",
    "INFO_APPLICAZIONE_CUSTOM": "Rappresentano collegamenti all'interno del contenuto. Possono essere visualizzati in testa al contenuto, a fondo testo o in entrambe le posizioni.",
    "INFO_FONTE": "Per inserire una nuova 'Fonte' digitare il nome della fonte nella casella di testo e premere il pulsante 'Aggiungi'.",
    "INFO_CATEGORIA": "Per inserire una nuova 'Categoria' digitare il nome della categoria nella casella di testo e premere il pulsante 'Aggiungi'.",
    "INFO_TAG": "Per inserire un nuovo 'Tag' digitare il nome del tag nella casella di testo e premere il pulsante 'Aggiungi'.",
    "INFO_IDLINK": "Selezionare il link da collegare",
    "INFO_DELETE_TESTO": "Vuoi rimuovere il campo testo selezionato?",
    "INFO_DEFAULT": "Sei sicuro di voler procedere?",
    "INFO_LINK_DA_COLLEGARE_APP_CUSTOM": "Seleziona per visualizzare entrambi i link sul portale. La mancata selezione comporta la visualizzazione automatica solo del primo valorizzato per ciascuna struttura.",
    "DELETE_FONTE": "Vuoi rimuovere la fonte selezionata? L'operazione non è reversibile.",
    "DELETE_CATEGORY": "Vuoi rimuovere la categoria selezionata? L'operazione non è reversibile.",
    "INFO_DISABLED_SALVA_MODIFICHE": "Salvataggio disabilitato. Verificare che tutti i campi obbligatori siano valorizzati.",
    "INFO_SALVA_CONTATTI_REGIONALI": "Salvataggio dei contatti regionali. Sei sicuro di voler procedere?",
    "INFO_ELIMINAZIONE_EXTRALEA": "Eliminazione dell'extra LEA selezionato. Sei sicuro di voler procedere?",
    "INFO_SALVA_EXTRALEA": "Salvataggio della tabella. Sei sicuro di voler procedere?",
    "INFO_ANNULLA_EXTRALEA": "Annullando le modifiche, ciò che non hai salvato andrà perso. Sei sicuro di voler procedere?",
    "INFO_RESET_MFA": "Dopo il reset dell'MFA l'utente selezionato non potrà più usare la configurazione corrente e dovrà effettuare nuovamente la procedura di configurazione dell'MFA per accedere al backoffice. Sei sicuro di voler procedere?",
    "INFO_CAMBIO_PASSWORD": "Stai per modificare la password corrente. Una volta che il sistema avrà modificato la tua password sarà necessario effettuare un nuovo login utilizzando la nuova password. Sei sicuro di voler procedere?",
    "INFO_ELIMINAZIONE_FEEDBACK": "Sei sicuro di voler eliminare il feedback selezionato?",
    "INFO_ARCHIVIAZIONE_FEEDBACK": "Sei sicuro di voler archiviare il feedback selezionato?",
    "INFO_USER_MSG_FEEDBACK": "In questa sezione puoi gestire i suggerimenti, le segnalazioni, e le osservazioni al Portale inviate dai cittadini tramite il modulo 'Aiutaci a migliorare il Portale'. Dopo aver ricercato e visualizzato il feedback, puoi catalogarlo in 'Archiviato'.",

    "ANNULLA_ASSOCIAZIONE": "Sei sicuro di voler annullare le modifiche? I dati non salvati andranno persi",
    "CONFERMA_ASSOCIAZIONE": "Stai per creare un'associazione, vuoi procedere?",
    "ELIMINA_ASSOCIAZIONE": "Stai per eliminare un'associazione, vuoi procedere?",

    "ANNULLA_STRUTTURA_COVID": "Sei sicuro di voler annullare le modifiche? I dati non salvati andranno persi",
    "CONFERMA_STRUTTURA_COVID": "Stai per creare un sito covid-19, vuoi procedere?",
    "ELIMINA_STRUTTURA_COVID": "Stai per eliminare un sito covid-19, vuoi procedere?",

    "ERRORE_CTA": "Eliminazione e Sospensione non consentite. Questo contenuto risulta raggiungibile da altri contenuti",

    // tips
    SIZE_CONSIGLIATA_IMMAGINE_COPERTINA: "Dimensione ottimale dell'immagine: (1172x300)px",
    SIZE_CONSIGLIATA_IMMAGINE_LANCIO: "Dimensione ottimale dell'immagine: (380x260)px",
    SIZE_CONSIGLIATA_ICONA_COMEFAREPER: "larghezza consigliata: 48px, NECESSARIO sfondo trasparente",
    SIZE_CONSIGLIATA_ICONA_SOCIAL: "larghezza consigliata: 24px, NECESSARIO sfondo trasparente",

    //validazione
    VALIDAZIONE_INDIRIZZO_INTERNO_ESTERNO: "E' necessario valorizzare entrambi i campi. L'indirizzo deve iniziare con http:// o https:// se l'indirizzo è esterno oppure iniziare con / se l'indirizzo è interno.",
    VALIDAZIONE_INDIRIZZO_INTERNO: "E' necessario valorizzare entrambi i campi. L'indirizzo deve iniziare con il carattere / "
}
