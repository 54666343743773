import SessioneUtente from "./SessioneUtente";
import TokenService from "./TokenService";
import authConfig from "../config/authConfig";
import { auth_debug_log_enable, globals } from "../config/config";

export default class AuthenticationManager {

    verificaAccessoUtente = async () => {
        //verifica sessione valida
        auth_debug_log_enable && console.log('[DEBUG] Verifica sessione...')
        if (!SessioneUtente.existsSession() || SessioneUtente.isSessionExpired()) {
            auth_debug_log_enable && console.log('[DEBUG] Sessione nulla o scaduta. Redirezione alla pagina di login.')
            return false;
        } else {
            auth_debug_log_enable && console.log('[DEBUG] Sessione valida, aggiornando SessionLatestUpdate')
            SessioneUtente.getInstance().SessionLatestUpdate = Date.now();

            let nuovoTokenNecessario = false;

            auth_debug_log_enable && console.log("[DEBUG] Verifica token presente...");
            auth_debug_log_enable && console.log(SessioneUtente.getInstance())
            auth_debug_log_enable && console.log(SessioneUtente.getInstance().idToken)
            let currentIdToken = SessioneUtente.getInstance().idToken;
            if (currentIdToken) {
                auth_debug_log_enable && console.log('[DEBUG] Token presente. Verifica validità token corrente...')
                let dataControl = new Date(SessioneUtente.getInstance().dataScadenzaToken) - 10 * 1000

                if (dataControl > Date.now()) {
                    auth_debug_log_enable && console.log('[DEBUG] Token non scaduto. Nessuna ulteriore operazione richiesta.')
                    SessioneUtente.getInstance().sessionLatestUpdate = Date.now()
                    //return this.getHeaders() //il getHeaders lo facciamo al ritorno da questa funzione
                } else {
                    auth_debug_log_enable && console.log('[DEBUG] Nuovo token necessario...')
                    // if expired request for a new one
                    nuovoTokenNecessario = true;
                    //return this.generaIdToken();
                }
            } else {
                auth_debug_log_enable && console.log('[DEBUG] Token non presente. Necessario generare un nuovo token...')
                //return this.generaIdToken();
                nuovoTokenNecessario = true;
            }

            if (nuovoTokenNecessario) {
                auth_debug_log_enable && console.log('[DEBUG] Richiesta aggiornamento token...');
                // const data = await TokenService.requestNewToken(SessioneUtente.getInstance().idSessione);
                const data = await TokenService.refreshToken(SessioneUtente.getInstance());
                auth_debug_log_enable && console.log('[DEBUG] Token aggiornato recuperato con successo.');
                auth_debug_log_enable && console.info(data);
                SessioneUtente.getInstance().idToken = data.idToken;
                SessioneUtente.getInstance().idSessione = data.idSessione;
                // SessioneUtente.getInstance().dataScadenzaToken = data.tokenExpirationTime;
                SessioneUtente.getInstance().sessionLatestUpdate = Date.now();
                SessioneUtente.getInstance().testaRefreshToken = data.testaRefreshToken;
                auth_debug_log_enable && console.log('Aggiornamento sessione completata');
                return data.idToken ? true : false;
            }
            else {
                return true;
            }
        }
    }

    getHeaders = () => {
        /* eslint-disable-next-line */
        let session = SessioneUtente.getInstance();

        const headers = {
            //l'header Content-Type è impostato nei repository perché qui vengono gestiti solo gli header di autenticazione 
            "AZContext": "sessionid=" + session.idSessione + ";applicationid=" + globals["app-id-applicazione"] + ";operationid=" + this.makeid(16),
            "Authorization": "Bearer " + session.idToken,
        }

        return headers;
    }

    makeid(length) {
        let result = "";
        let characters = "abcdef0123456789";
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }



    initRequestHeaders = async (requestUrl, mockedHeaders) => {
        auth_debug_log_enable && console.log('[AuthenticationManager] Intercepting request')
        auth_debug_log_enable && console.log('[AuthenticationManager] Request to:', requestUrl)

        if (authConfig['attivazione-auth']) {
            let isAutenticato = await this.verificaAccessoUtente();
            if (isAutenticato) {
                return this.getHeaders();
            }
            else {
                window.location.href = authConfig['url-redirect-auth']
            }

        }
        else {
            return mockedHeaders;
        }
    }


}