import React from 'react';
import CustomApp from './CustomApp/CustomApp';
import { Icon } from 'design-react-kit';
import './CustomAppWrapper.css';
import InfoModal from '../InfoModal/InfoModal';
import CustomCTA from './CustomCTA/CustomCTA';

export default function CustomAppWrapper(props) {

    return (
        <div id="CustomAppWrapper">
            <div>
                <div className="row">
                    <label className="title">{props.name}</label>
                    {props.infoMessage &&
                        <InfoModal message={props.infoMessage} />
                    }
                </div>
                <div className="row mt-3">
                    <button onClick={props.click}>
                        <Icon className="left" color="black" icon="it-plus-circle" id="arrowRight" />
                    Aggiungi nuovo
                    </button>
                </div>
                <div>
                    {props.options && props.options.length && props.array ? props.array.map((el, i) =>
                        <div key={el.id + i}>
                            {props.renderAppCTA ?
                                <CustomCTA index={i} options={props.options} option={props.option} change={props.change} default={props.options[0].nome} delete={() => props.delete(el.id)} restore={props.restore} id={el.id} descrizione={el.descrizione} link={el.link}
                                    disabled={el.disabled} disable={props.disable} toggleNameCta={props.toggleNameCta} toggleNameLink={props.toggleNameLink} toggleValueCta={el.fondoTesto} toggleValueLink={el.inizioPagina}
                                    changeToggleCta={props.changeToggleCta} changeToggleLink={props.changeToggleLink} changeDescrizioneCustomApp={props.changeDescrizioneCustomApp} isValid={props.valid[i]} changeLinkDaCollegare={props.changeLinkDaCollegare} isToggleLinkVisibile={props.isToggleLinkVisibile} /> :
                                <CustomApp index={i} options={props.options} option={props.option} change={props.change} default={props.options[0].nome} delete={() => props.delete(el.id)} restore={props.restore} id={el.id} descrizione={el.descrizione} link={el.link}
                                    disabled={el.disabled} disable={props.disable} toggleNameCta={props.toggleNameCta} toggleNameLink={props.toggleNameLink} toggleValueCta={el.fondoTesto} toggleValueLink={el.inizioPagina}
                                    changeToggleCta={props.changeToggleCta} changeToggleLink={props.changeToggleLink} changeDescrizioneCustomApp={props.changeDescrizioneCustomApp} isValid={props.valid[i]} changeLinkDaCollegare={props.changeLinkDaCollegare} isToggleLinkVisibile={props.isToggleLinkVisibile} />}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}
