import { SERVICE_URL, REPHEADERS } from "../config/config";
import AuthenticationManager from '../sessionManager/AuthenticationManager';
import SessioneUtente from "../sessionManager/SessioneUtente";

export default function HomepageRepository() {

    const homepageServiceUrl = SERVICE_URL + "/homepage";

    const authManager = new AuthenticationManager();
    const session = SessioneUtente.getInstance();

    /* centralizza le logiche di inzializzazione degli Headers */
    async function getHeaders(requestUrl) {
        let defaultHeaders = { ...REPHEADERS };
        defaultHeaders["AZUser"] = defaultHeaders["AZUser"] + ";areaterritoriale=" + session.idAreaTerritoriale
        return await authManager.initRequestHeaders(requestUrl, defaultHeaders);
    }

    async function getHomepage() {
        const headers = await getHeaders(homepageServiceUrl);
        headers["Content-Type"] = "application/json";
        return fetch(homepageServiceUrl, {
            headers: headers,
            method: "GET"
        })
    }

    async function aggiornaHomepage(contenuto) {
        const headers = await getHeaders(homepageServiceUrl);
        headers["Content-Type"] = "application/json";
        return fetch(homepageServiceUrl, {
            headers: headers,
            method: "PUT",
            body: JSON.stringify(contenuto)
        }).catch(err => err)
    }

    async function pubblicaHomepage(contenuto) {
        const headers = await getHeaders(homepageServiceUrl);
        headers["Content-Type"] = "application/json";
        return fetch(homepageServiceUrl, {
            headers: headers,
            method: "PUT",
            body: JSON.stringify(contenuto)
        }).then(data => {
            const publishUrl = homepageServiceUrl + "/pubblica";
            return fetch(publishUrl, {
                headers: headers,
                method: "POST"
            })
        }).catch(err => err)

    }

    return Object.freeze({
        getHomepage,
        aggiornaHomepage,
        pubblicaHomepage
    });
}
