import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Icon } from "design-react-kit";
import './TableRicerca.css'
import ButtonLink from "../ButtonLink/ButtonLink";
import CustomLink from "../ButtonLink/CustomLink/CustomLink";
import { Context } from "../../../hook/globalState/Store";
import { sortAscending } from "../../Utils/Utils";
import { typeOrder } from "../../Utils/Dataset";

export default function TableRicerca(props) {

    const [state, dispatch] = useContext(Context);

    const DEFAULT_PAGINATION_SIZE = 5
    const [PAGINATION_SIZE, SetPAGINATION_SIZE] = useState(props.pagination ? parseInt(props.pagination) : DEFAULT_PAGINATION_SIZE)
    const [page, setPage] = useState(0)
    const [goTo, setGoTo] = useState()

    //Math variables
    const [elements, setElements] = useState(props.elements ? props.elements : [])
    const [uncompletePage, setUncompletePage] = useState(0)
    const [nPages, setNPages] = useState()
    const [updateDisabled, setUpdateDisabled] = useState(true);
    const tooltipModifica = "modifica";


    useEffect(() => {
        SetPAGINATION_SIZE(props.pagination)
    }, [props.pagination]
    )

    useEffect(() => {
        setElements(props.elements ? props.elements : [])
        setUncompletePage(props.elements.length % PAGINATION_SIZE ? 1 : 0)
        setNPages(parseInt(props.elements.length / PAGINATION_SIZE) + uncompletePage)
    }, [props.elements, props.pagination])

    useEffect(() => {
        setPage(0)
    }, [props.filterValue])

    const [elementsInView, setElementsInView] = useState(props.elements ? props.elements : [])
    const [headersInView, setHeadersInView] = useState(props.headers ? props.headers : [])

    //Default render functions
    const defaultRenderHeaders = () => <tr>
        {props.elements && elementsInView && elementsInView.length > 0 ? headersInView.map(el =>
            <th className={"py-2"}>
                {el.name}
            </th>
        ) : <Fragment></Fragment>}
    </tr>

    const sortElements = (sortColumn, sortDirection) => {
        const comparer = (a, b) => {
            if (sortDirection === typeOrder.ASC || sortDirection === typeOrder.DESC) {
                if (a[sortColumn]?.toUpperCase() === b[sortColumn]?.toUpperCase()) {
                    return 0
                }
                if (a[sortColumn]?.toUpperCase() > b[sortColumn]?.toUpperCase()) {
                    return sortDirection === typeOrder.ASC ? 1 : -1
                } else {
                    return sortDirection === typeOrder.ASC ? -1 : 1
                }
            } else {
                return 0
            }
        }

        const elementsInView = sortDirection === typeOrder.NONE
            ? props.elements
            : [...props.elements].sort((a, b) => comparer(a, b));
        // divide le pagine
        const elementsInViewPaginated = elementsInView.slice(page * PAGINATION_SIZE, page * PAGINATION_SIZE + PAGINATION_SIZE);
        setElementsInView(elementsInViewPaginated);

        const headersInView = props.headers ? props.headers.map(el => {
            if (el.key === sortColumn) {
                el.sortDirection = sortDirection;
            } else {
                if (el.sortDirection) {
                    el.sortDirection = typeOrder.NONE;
                }
            }
            return el
        }) : null;
        setHeadersInView(headersInView);
    }

    //Default render sorting headers functions
    const defaultRenderHeadersSorting = () => {
        return (<tr>
            {headersInView ? headersInView.map(el => {
                if (el.sortDirection) {
                    return (<th>
                        {el.name}
                        {el.sortDirection === typeOrder.NONE ?
                            <span className={"vertical-align"} onClick={() => {
                                sortElements(el.key, typeOrder.ASC)
                            }}>
                                <Icon className="right" color="white" icon="it-arrow-up-triangle"
                                    id="arrowUpTableRicerca" />
                                <Icon className="right both-icon" color="white" icon="it-arrow-down-triangle"
                                    id="arrowDownTableRicerca" />
                            </span>
                            : null}
                        {el.sortDirection === typeOrder.ASC ?
                            <span className={"vertical-align"} onClick={() => {
                                sortElements(el.key, typeOrder.DESC)
                            }}>
                                <Icon className="right" color="white" icon="it-arrow-up-triangle"
                                    id="arrowUpTableRicerca" />
                            </span>
                            : null}
                        {el.sortDirection === typeOrder.DESC ?
                            <span className={"vertical-align"} onClick={() => {
                                sortElements(el.key, typeOrder.ASC)
                            }}>
                                <Icon className="right" color="white" icon="it-arrow-down-triangle"
                                    id="arrowDownTableRicerca" />
                            </span>
                            : null}
                    </th>)
                } else {
                    return (
                        <th>
                            {el.name}
                        </th>
                    )
                }
            }
            )
                : <></>}
        </tr>)
    }

    const defaultRenderRow = (row) => {
        let columns = props.headers.map(header => header.key);
        return row ? <tr>
            {columns.map(el =>
                renderCell(el, row, props.id)
            )}
        </tr> : <></>
    }

    const defaultRenderCell = (el) => <td>
        {props.disableHtml ? <span>{el}</span>
            : <span dangerouslySetInnerHTML={{ __html: `<span>${el}</span>` }} />}
    </td>

    const defaultRenderPagination = () => {

        let start = page * PAGINATION_SIZE + 1
        let end = page * PAGINATION_SIZE + PAGINATION_SIZE <= props.elements?.length ? page * PAGINATION_SIZE + PAGINATION_SIZE : props.elements?.length
        let uncompletePage = props.elements.length % PAGINATION_SIZE ? 1 : 0
        let nPage = parseInt(props.elements.length / PAGINATION_SIZE) + uncompletePage

        return props.elements && props.elements.length > 0 ? <>
            {addItemButton()}
            <div className={'pagination noselect'}>

                <div className={'v-center d-flex'}>
                    <label>
                        <span className={'font-weight-bold'}> Pagina {page + 1} </span> {start} - {end} di {props.elements?.length ?? 0} items
                    </label>
                    <Icon color="black" icon="it-chevron-left" onClick={prevPage} />
                    <div className="page-item d-flex">

                        {(page == 0 || page == 1) && nPage < 3 ? null :
                            <a onClick={() => setPage(page === nPage - 1 ? page - 2 : page < nPage - 2 ? page : page - 1)} className="page-link" aria-current={page < nPage - 2 ? 'page' : null}>{page === nPage - 1 ? page - 1 : page < nPage - 2 ? page + 1 : page}</a>}
                        {page == 0 && nPage == 1 ? null :
                            <a onClick={() => setPage(page === nPage - 1 ? page - 1 : page < nPage - 2 ? page + 1 : page)} className="page-link" aria-current={page === nPage - 2 ? 'page' : null}>{page === nPage - 1 ? page : page < nPage - 2 ? page + 2 : page + 1}</a>}
                        {
                            <a onClick={() => setPage(page === nPage - 1 ? page : page < nPage - 2 ? page + 2 : page + 1)} className="page-link" aria-current={page === nPage - 1 ? 'page' : null}>{page === nPage - 1 ? page + 1 : page < nPage - 2 ? page + 3 : page + 2}</a>}
                    </div>


                    <Icon color="black" icon="it-chevron-right" onClick={nextPage} />

                    <form className={'d-inline'} onSubmit={(event) => {
                        goToPage()
                        event.preventDefault()
                        setGoTo('')
                    }}>
                        <input valid type={"number"} max={nPage} min={1} value={goTo} onSubmit={goToPage} onChange={(e) => setGoTo(e.target.value)} placeholder={'Vai a...'} />
                        <input type="submit" className={'d-none'} />
                    </form>
                </div>
            </div>
        </> : addItemButton()
    }


    const defaultRenderFooter = () => <></>

    const defaultAddItemButton = () => {
        return (<div id="buttonLink">
            <div className="row pt-2">
                <button
                    id={"add-button"}
                    onClick={() => props.addItemButtonFunction()}
                >
                    <Icon className="left" color="black" icon="it-plus-circle" id="arrowRight" />
                    Aggiungi nuovo
                </button>
            </div>
        </div>)
    }

    const nextPage = () => {
        let uncompletePage = props.elements.length % PAGINATION_SIZE ? 1 : 0
        let nPage = parseInt(props.elements.length / PAGINATION_SIZE) + uncompletePage
        if (page + 1 < nPage)
            setPage(page + 1)
    }

    const prevPage = () => {
        if (page > 0) setPage(page - 1)
    }

    const goToPage = () => {
        let uncompletePage = props.elements.length % PAGINATION_SIZE ? 1 : 0
        let nPage = parseInt(props.elements.length / PAGINATION_SIZE) + uncompletePage
        try {
            if (parseInt(goTo - 1) >= 0 && goTo - 1 <= nPage)
                setPage(parseInt(goTo - 1))
        } catch (e) { }
    }

    //Render functions
    let renderHeaders;
    if (props.renderHeaders) {
        renderHeaders = props.renderHeaders
    } else if (props.sorting && props.headers) {
        renderHeaders = defaultRenderHeadersSorting
    } else {
        renderHeaders = defaultRenderHeaders
    }
    const renderRow = props.renderRow ? props.renderRow : defaultRenderRow
    const renderCell = props.renderCell ? props.renderCell : defaultRenderCell
    const renderFooter = props.renderFooter ? props.renderFooter : defaultRenderFooter
    const renderPagination = props.renderPagination ? props.renderPagination : defaultRenderPagination
    const addItemButton = props.addItemButtonFunction ? defaultAddItemButton : () => { return <></> }

    //Aggiorna paginazione
    useEffect(() => {
        if (headersInView && headersInView.length > 0) {
            let header = headersInView.find(el => el.sortDirection != null && el.sortDirection !== typeOrder.NONE)
            if (header) {
                // ordina e divide le pagine
                sortElements(header.key, header.sortDirection)
            } else {
                // divide le pagine
                setElementsInView(props.elements.slice(page * PAGINATION_SIZE, page * PAGINATION_SIZE + PAGINATION_SIZE))
            }
        } else {
            // divide le pagine
            setElementsInView(props.elements.slice(page * PAGINATION_SIZE, page * PAGINATION_SIZE + PAGINATION_SIZE))
        }

    }, [props.elements, page])

    useEffect(() => {
        setHeadersInView(props.headers)
    }, [props.headers]);

    const renderBody = () => {
        return elementsInView.map(el => {
            return renderRow(el)
        });
    }

    const defaultRenderTable = () =>
        <>
            <table className={'customTable'}>
                <tbody id={props.id ? props.id : 'defaultTableId'}>
                    {renderHeaders()}
                    {renderBody()}
                    {renderFooter()}
                </tbody>
            </table>
            {renderPagination()}
        </>



    return props.renderTable ? props.renderTable() : defaultRenderTable()

}

