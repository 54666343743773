import LogoAgenas from '../../img/logos/logo-agenas.png';
import React, { useEffect, useContext, useState } from 'react';
import { Context } from '../../../hook/globalState/Store'
import './Menu.css';
import { Link } from 'react-router-dom';
import LinkCollapse from '../LinkCollapse/LinkCollapse';
import LogoHomePage from '../../img/logos/homepage.svg';
import LogoMenu from '../../img/logos/menu.svg';
import LogoInsert from '../../img/logos/insert.svg';
import LogoSection from '../../img/logos/section.svg';
import LogoApplication from '../../img/logos/application.svg';
import LogoSearch from '../../img/logos/search.svg';
import LogoSocial from '../../img/logos/social.svg';
import LogoTool from '../../img/logos/tool.svg';
import LogoError from '../../img/logos/error.svg';
import LogoTrash from '../../img/logos/trash.svg';
import {
    useContenutiOrdinati, useTuttiContenuti,
} from './MenuHooks';
import useObjectsArray from '../../../hook/useObjectsArray';
import ContenutiRepository from '../../../Repo/ContenutiRepository';
import { linguaEnum, maxElementi, roleEnum, statoContenuto, tipologiaEnum, tipologiaNonCreabileEnum } from '../../Utils/Dataset';
import SessioneUtente from '../../../sessionManager/SessioneUtente';
import { isContentReadOnly, sortAscending } from '../../Utils/Utils';
import { Icon } from 'design-react-kit';


export default function Menu(props) {

    const [state, dispatch] = useContext(Context);

    let session = SessioneUtente.getInstance();
    let role = session.ruoliUtente;

    const [tuttiContenuti, tuttiContenutiLoadCompleted, setTuttiContenuti, setTuttiContenutiLoaded] = useTuttiContenuti();

    const [pagineErrore, , , setComePagineErroreLoaded] = useContenutiOrdinati({ tipologia: tipologiaNonCreabileEnum.ERRORE, numeroElementi: maxElementi }, { ascending: "false", sort: "dataInserimento" })
    const [pagineArgomentoGenerico, pagineArgomentoGenericoLoaded, setPagineArgomentoGenerico, setPagineArgomentoGenericoLoaded] = useContenutiOrdinati({ tipologia: tipologiaNonCreabileEnum.ARGOMENTO_GENERICO_BOTTOM })


    // Gestione Portale & Contatti
    const [contentFetched, setContentFetched] = useState(false)
    const [contattiMail, setContattiMail, updateContattiMail] = useObjectsArray()
    const [portaleProgetto, setPortaleProgetto, updatePortaleProgetto] = useObjectsArray()
    const [portaleGruppoDiLavoro, setPortaleGruppoDiLavoro, updatePortaleGruppoDiLavoro] = useObjectsArray()
    const [portaleChiSiamo, setPortaleChiSiamo, updatePortaleChiSiamo] = useObjectsArray()

    const mapPaginaErroreToListElement = pagina => ({ ...pagina, href: '/edit/errore/' + pagina.id, icon: 'it-arrow-right-triangle', title: pagina.nome })
    const mapPaginaArgomentoGenericoToListElement = pagina =>
    (({
        ...pagina, href: pagina?.id && '/edit/footer/' + pagina?.id, icon: 'it-arrow-right-triangle',
        title: pagina?.title ? pagina?.title : pagina?.nome,
    }))

    useEffect(() => {
        if (state.reloadMenu) {
            setTuttiContenutiLoaded(false)
            setComePagineErroreLoaded(false)
            setPagineArgomentoGenericoLoaded(false)


            dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: false } });
        }
    }, [state.reloadMenu])

    useEffect(() => {
        if (portaleProgetto && portaleChiSiamo && portaleGruppoDiLavoro && contattiMail && pagineArgomentoGenericoLoaded) {
            setPagineArgomentoGenerico([...pagineArgomentoGenerico, portaleProgetto, portaleGruppoDiLavoro, portaleChiSiamo, contattiMail])
        }

    }, [pagineArgomentoGenericoLoaded, portaleProgetto, portaleGruppoDiLavoro, portaleChiSiamo, contattiMail])

    useEffect(() => {
        if (!contentFetched) {
            uploadContents(setPortaleProgetto, setPortaleChiSiamo, setPortaleGruppoDiLavoro, setContattiMail, setContentFetched)
        }
    }, [contentFetched])

    const linksTree = BuildLinksTree(tuttiContenuti);

    return props.collapsed ? <></> : (
        <div id="menu">

            <div id="agenaslogo">
                <img alt="" className="img-fluid" src={LogoAgenas} />
            </div>
            <div className="link-list-wrapper">
                <ul className="link-list">
                    <li>
                        {role !== roleEnum.EDITORE ? <Link to="/edit/homepage" ><span><img className="menu-state-icon" src={LogoHomePage} />Home Page</span></Link> : null}
                    </li>
                    <li>
                        {role !== roleEnum.EDITORE ? <LinkCollapse logo={LogoMenu} collapseId={"collapseThree"} defaultCollapsed={false} title={"MENU"} links={linksTree.menu} nested={false} showVediTutti={true} maxElementeInView={6} /> : null}
                    </li>
                    <li>
                        <Link to="/new/contenuto" ><span><img className="menu-state-icon" src={LogoInsert} />Inserimento nuovo contenuto</span></Link>
                    </li>
                    <li>
                        <LinkCollapse logo={LogoSection} visible={false} collapseId={"collapseThree"} defaultCollapsed={false} title={"AGGIORNAMENTO SEZIONI"} links={linksTree.aggiornamentoSezioni} nested={true} showVediTutti={true} maxElementeInView={6} />
                    </li>
                    <li>
                        <LinkCollapse logo={LogoApplication} collapseId={"collapseThree"} defaultCollapsed={false} title={"APPLICAZIONI CUSTOM"} links={linksTree.applicazioniCustom} nested={true} showVediTutti={true} maxElementeInView={6} />
                    </li>
                    <li>
                        <Link to="/motorediricerca"><span><img className="menu-state-icon" src={LogoSearch} />Motore di ricerca</span></Link>
                    </li>
                    <li>
                        <Link to="/collegamenti-social"><span><img className="menu-state-icon" src={LogoSocial} />Collegamenti ai social</span></Link>
                    </li>
                    {/* TUTTI I CONTENUTI GENERICI SONO STATI TRASFERITI IN AGGIORNAMENTO SEZIONI
                    <li>
                        <LinkCollapse logo={LogoTool} collapseId={"collapseThreeFooter"} defaultCollapsed={true} title={"IMPOSTAZIONI FOOTER"}
                            links={pagineArgomentoGenerico && portaleProgetto && portaleGruppoDiLavoro && portaleChiSiamo && contattiMail && pagineArgomentoGenerico?.map(el => (
                                mapPaginaArgomentoGenericoToListElement(el)
                            ))?.sort((el1, el2) => sortAscending(el1, el2, "title"))}
                            nested={false} showVediTutti={true} maxElementsInView={10}
                        />
                    </li> */}
                    <li>
                        <LinkCollapse logo={LogoError} collapseId={"collapseThree"} defaultCollapsed={true} title={"PAGINE DI ERRORE"} links={pagineErrore?.map(el => mapPaginaErroreToListElement(el))} showVediTutti={true} maxElementeInView={6100} />
                    </li>
                    {role !== roleEnum.EDITORE ? <li>
                        <Link to="/gestione-ruoli"><span><img alt={"Redattori"} src={LogoTool} className="menu-state-icon" />Redattori</span></Link>
                    </li> : null}
                    <li>
                        <Link to="/gestione-feedback"><span><Icon icon="it-hearing" className="white" />Gestione feedback</span></Link>
                    </li>
                    <li>
                        <Link to="/cestino"><span><img alt={"Cestino"} className="menu-state-icon" src={LogoTrash} />Cestino</span></Link>
                    </li>
                </ul>
            </div >
        </div >
    )
}

function BuildLinksTree(tuttiContenuti) {

    let approfondimenti = tuttiContenuti.filter(x => x.tipologia === tipologiaEnum.APPROFONDIMENTO)
    let applicazioneStrutture = tuttiContenuti.filter(x => x.tipologia === tipologiaEnum.APPLICAZIONE_STRUTTURE)
    let dettaglioApprofondimenti = tuttiContenuti.filter(x => x.tipologia === tipologiaEnum.DETTAGLIO_APPROFONDIMENTO)
    let comeFarePer = tuttiContenuti.filter(x => x.tipologia === tipologiaEnum.COME_FARE_PER)
    let vivereInSalute = tuttiContenuti.filter(x => x.tipologia === tipologiaEnum.VIVERE_IN_SALUTE)
    let prestazioneGarantitaRicerca = tuttiContenuti.filter(x => x.tipologia === tipologiaEnum.PRESTAZIONE_GARANTITA_SSN && x.template !== "TEMPLATE_LINK")
    let news = SessioneUtente.getInstance().ruoliUtente === roleEnum.EDITORE ? tuttiContenuti.filter(x => x.tipologia === tipologiaEnum.NEWS && x.areaTerritoriale === "") : tuttiContenuti.filter(x => x.tipologia === tipologiaEnum.NEWS)
    let fakeNews = tuttiContenuti.filter(x => x.tipologia === tipologiaEnum.FAKE_NEWS)
    let covid = tuttiContenuti.filter(x => x.tipologia === tipologiaEnum.COVID)
    let applicazioneStruttureCovid = tuttiContenuti.filter(x => x.tipologia === tipologiaEnum.APPLICAZIONE_STRUTTURE_COVID)
    let faq = tuttiContenuti.filter(x => x.tipologia === tipologiaEnum.FAQ)
    let feedback = tuttiContenuti.filter(x => x.tipologia === tipologiaEnum.FEEDBACK)
    let contenutiGenerici = tuttiContenuti.filter(x => x.tipologia === tipologiaEnum.ARGOMENTO_GENERICO_BOTTOM)
    let contenutiGenericiPortale = tuttiContenuti.filter(x => x.tipologia === tipologiaEnum.ARGOMENTO_GENERICO_PORTALE)
    let contenutiGenericiContatti = tuttiContenuti.filter(x => x.tipologia === tipologiaEnum.ARGOMENTO_GENERICO_CONTATTI)

    return {
        applicazioniCustom: [
            {
                title: "Prestazioni garantite dal SSN",
                href: "/applicazioni-custom/prestazioni-garantite-ssn/IT",
                icon: "it-arrow-right-triangle",
                direct: true
            },
            {
                title: "Come fare per",
                href: "#",
                icon: "it-arrow-right-triangle",
                links: [
                    {
                        contenuti: applicazioneStrutture,
                        actions: [
                            {
                                url: "edit/contenutoredirect/",
                                name: "Modifica"
                            },
                            {
                                url: "/",
                                name: "Elimina",
                                getDisabledStatus: function getDisabledStatus(content) {
                                    return {
                                        isDisabled: tuttiContenuti.filter(x => x.applicazioniCustom?.find(y => y.link === content.path))?.length > 0,
                                        disabledMessage: "Eliminazione non consentita"
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: "Covid-19",
                href: "#",
                icon: "it-arrow-right-triangle",
                links: [
                    {
                        contenuti: applicazioneStruttureCovid,
                        actions: [
                            {
                                url: "edit/contenutoredirect/",
                                name: "Modifica"
                            },
                            {
                                url: "/",
                                name: "Elimina",
                                getDisabledStatus: function getDisabledStatus(content) {
                                    return {
                                        isDisabled: tuttiContenuti.filter(x => x.applicazioniCustom?.find(y => y.link === content.path))?.length > 0,
                                        disabledMessage: "Eliminazione non consentita"
                                    }
                                }
                            }
                        ]
                    }
                ]
            }
        ],
        menu: [
            {
                title: "Main menu",
                href: "/edit/menu/main",
                icon: "it-arrow-right-triangle"
            },
            {
                title: "Header Menu",
                href: "/edit/menu/header",
                icon: "it-arrow-right-triangle"
            },
            {
                title: "Footer Menu",
                href: "/edit/menu/footer",
                icon: "it-arrow-right-triangle"
            },
            {
                title: "Bottom Bar Menu",
                href: "/edit/menu/bottom",
                icon: "it-arrow-right-triangle"
            }
        ],
        aggiornamentoSezioni: [
            {
                title: "Approfondimenti",
                href: "#",
                icon: "it-arrow-right-triangle",
                links: [
                    {
                        contenuti: approfondimenti,
                        actions: [
                            {
                                url: "edit/contenutoredirect/",
                                name: "Modifica"
                            },
                            {
                                url: "/",
                                name: "Elimina",
                                getDisabledStatus: function getDisabledStatus(content) {
                                    let isDisabled = false;
                                    let disabledMessage = "";
                                    if (tuttiContenuti.filter(x => x.idPadre === content.id)?.length > 0) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita (padre di dettagli)";
                                    }
                                    return {
                                        isDisabled: isDisabled,
                                        disabledMessage: disabledMessage
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: "Dettaglio Approfondimenti",
                href: "#",
                icon: "it-arrow-right-triangle",
                links: [
                    {
                        contenuti: dettaglioApprofondimenti,
                        actions: [
                            {
                                url: "edit/contenutoredirect/",
                                name: "Modifica"
                            },
                            {
                                url: "/",
                                name: "Elimina",
                                getDisabledStatus: function getDisabledStatus(content) {
                                    let isDisabled = false;
                                    let disabledMessage = "";
                                    if (tuttiContenuti.filter(x => x.idPadre === content.id)?.length > 0) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita (padre di dettagli)";
                                    }
                                    return {
                                        isDisabled: isDisabled,
                                        disabledMessage: disabledMessage
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: "Come fare per",
                href: "#",
                icon: "it-arrow-right-triangle",
                links: [
                    {
                        contenuti: comeFarePer,
                        actions: [
                            {
                                url: "edit/contenutoredirect/",
                                name: "Modifica"
                            },
                            {
                                url: "/",
                                name: "Elimina",
                                getDisabledStatus: function getDisabledStatus(content) {
                                    let isDisabled = false;
                                    let disabledMessage = "";
                                    if (tuttiContenuti.filter(x => x.idPadre === content.id)?.length > 0) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita (padre di dettagli)";
                                    }
                                    return {
                                        isDisabled: isDisabled,
                                        disabledMessage: disabledMessage
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: "Vivere in salute",
                href: "#",
                icon: "it-arrow-right-triangle",
                links: [
                    {
                        contenuti: vivereInSalute,
                        actions: [
                            {
                                url: "edit/contenutoredirect/",
                                name: "Modifica"
                            },
                            {
                                url: "/",
                                name: "Elimina",
                                getDisabledStatus: function getDisabledStatus(content) {
                                    let isDisabled = false;
                                    let disabledMessage = "";
                                    if (tuttiContenuti.filter(x => x.idPadre === content.id)?.length > 0) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita (padre di dettagli)";
                                    }
                                    return {
                                        isDisabled: isDisabled,
                                        disabledMessage: disabledMessage
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: "Prestazioni garantite dal SSN",
                href: "#",
                icon: "it-arrow-right-triangle",
                links: [
                    {
                        contenuti: prestazioneGarantitaRicerca,
                        actions: [
                            {
                                url: "edit/contenutoredirect/",
                                name: "Modifica"
                            },
                            {
                                url: "/",
                                name: "Elimina",
                                getDisabledStatus: function getDisabledStatus(content) {
                                    let isDisabled = false;
                                    let disabledMessage = "";
                                    if (tuttiContenuti.filter(x => x.idPadre === content.id)?.length > 0) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita (padre di dettagli)";
                                    }
                                    return {
                                        isDisabled: isDisabled,
                                        disabledMessage: disabledMessage
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: "News",
                href: "#",
                icon: "it-arrow-right-triangle",
                links: [
                    {
                        contenuti: news,
                        actions: [
                            {
                                url: "edit/contenutoredirect/",
                                name: "Modifica"
                            },
                            {
                                url: "/",
                                name: "Elimina",
                                getDisabledStatus: function getDisabledStatus(content) {
                                    let isDisabled = false;
                                    let disabledMessage = "";
                                    if (tuttiContenuti.filter(x => x.idPadre === content.id)?.length > 0) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita (padre di dettagli)";
                                    }
                                    if (isContentReadOnly(SessioneUtente.getInstance().ruoliUtente, SessioneUtente.getInstance().codiceFiscale, content.areaTerritoriale, content.idUtenteInserimento)) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita";
                                    }
                                    return {
                                        isDisabled: isDisabled,
                                        disabledMessage: disabledMessage
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: "Fake news",
                href: "#",
                icon: "it-arrow-right-triangle",
                links: [
                    {
                        contenuti: fakeNews,
                        actions: [
                            {
                                url: "edit/contenutoredirect/",
                                name: "Modifica"
                            },
                            {
                                url: "/",
                                name: "Elimina",
                                getDisabledStatus: function getDisabledStatus(content) {
                                    let isDisabled = false;
                                    let disabledMessage = "";
                                    if (tuttiContenuti.filter(x => x.idPadre === content.id)?.length > 0) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita (padre di dettagli)";
                                    }
                                    if (isContentReadOnly(SessioneUtente.getInstance().ruoliUtente, SessioneUtente.getInstance().codiceFiscale, content.areaTerritoriale, content.idUtenteInserimento)) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita";
                                    }
                                    return {
                                        isDisabled: isDisabled,
                                        disabledMessage: disabledMessage
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: "Covid-19",
                href: "#",
                icon: "it-arrow-right-triangle",
                links: [
                    {
                        contenuti: covid,
                        actions: [
                            {
                                url: "edit/contenutoredirect/",
                                name: "Modifica"
                            },
                            {
                                url: "/",
                                name: "Elimina",
                                getDisabledStatus: function getDisabledStatus(content) {
                                    let isDisabled = false;
                                    let disabledMessage = "";
                                    if (tuttiContenuti.filter(x => x.idPadre === content.id)?.length > 0) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita (padre di dettagli)";
                                    }
                                    if (isContentReadOnly(SessioneUtente.getInstance().ruoliUtente, SessioneUtente.getInstance().codiceFiscale, content.areaTerritoriale, content.idUtenteInserimento)) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita";
                                    }
                                    return {
                                        isDisabled: isDisabled,
                                        disabledMessage: disabledMessage
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: "FAQ",
                href: "#",
                icon: "it-arrow-right-triangle",
                links: [
                    {
                        contenuti: faq,
                        actions: [
                            {
                                url: "edit/contenutoredirect/",
                                name: "Modifica"
                            },
                            {
                                url: "/",
                                name: "Elimina",
                                getDisabledStatus: function getDisabledStatus(content) {
                                    let isDisabled = false;
                                    let disabledMessage = "";
                                    if (tuttiContenuti.filter(x => x.idPadre === content.id)?.length > 0) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita (padre di dettagli)";
                                    }
                                    if (isContentReadOnly(SessioneUtente.getInstance().ruoliUtente, SessioneUtente.getInstance().codiceFiscale, content.areaTerritoriale, content.idUtenteInserimento)) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita";
                                    }
                                    return {
                                        isDisabled: isDisabled,
                                        disabledMessage: disabledMessage
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: "Feedback",
                href: "#",
                icon: "it-arrow-right-triangle",
                links: [
                    {
                        contenuti: feedback,
                        actions: [
                            {
                                url: "edit/contenutoredirect/",
                                name: "Modifica"
                            },
                            {
                                url: "/",
                                name: "Elimina",
                                getDisabledStatus: function getDisabledStatus(content) {
                                    let isDisabled = false;
                                    let disabledMessage = "";
                                    if (tuttiContenuti.filter(x => x.idPadre === content.id)?.length > 0) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita (padre di dettagli)";
                                    }
                                    if (isContentReadOnly(SessioneUtente.getInstance().ruoliUtente, SessioneUtente.getInstance().codiceFiscale, content.areaTerritoriale, content.idUtenteInserimento)) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita";
                                    }
                                    return {
                                        isDisabled: isDisabled,
                                        disabledMessage: disabledMessage
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: "Contenuti generici",
                href: "#",
                icon: "it-arrow-right-triangle",
                links: [
                    {
                        contenuti: contenutiGenerici,
                        actions: [
                            {
                                url: "edit/contenutoredirect/",
                                name: "Modifica"
                            },
                            {
                                url: "/",
                                name: "Elimina",
                                getDisabledStatus: function getDisabledStatus(content) {
                                    let isDisabled = false;
                                    let disabledMessage = "";
                                    if (tuttiContenuti.filter(x => x.idPadre === content.id)?.length > 0) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita (padre di dettagli)";
                                    }
                                    return {
                                        isDisabled: isDisabled,
                                        disabledMessage: disabledMessage
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: "Contenuti generici (portale)",
                href: "#",
                icon: "it-arrow-right-triangle",
                links: [
                    {
                        contenuti: contenutiGenericiPortale,
                        actions: [
                            {
                                url: "edit/contenutoredirect/",
                                name: "Modifica"
                            },
                            {
                                url: "/",
                                name: "Elimina",
                                getDisabledStatus: function getDisabledStatus(content) {
                                    let isDisabled = false;
                                    let disabledMessage = "";
                                    if (tuttiContenuti.filter(x => x.idPadre === content.id)?.length > 0) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita (padre di dettagli)";
                                    }
                                    return {
                                        isDisabled: isDisabled,
                                        disabledMessage: disabledMessage
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: "Contenuti generici (contatti)",
                href: "#",
                icon: "it-arrow-right-triangle",
                links: [
                    {
                        contenuti: contenutiGenericiContatti,
                        actions: [
                            {
                                url: "edit/contenutoredirect/",
                                name: "Modifica"
                            },
                            {
                                url: "/",
                                name: "Elimina",
                                getDisabledStatus: function getDisabledStatus(content) {
                                    let isDisabled = false;
                                    let disabledMessage = "";
                                    if (tuttiContenuti.filter(x => x.idPadre === content.id)?.length > 0) {
                                        isDisabled = true;
                                        disabledMessage = "Eliminazione non consentita (padre di dettagli)";
                                    }
                                    return {
                                        isDisabled: isDisabled,
                                        disabledMessage: disabledMessage
                                    }
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    };
}

function uploadContents(setPortaleProgetto, setPortaleChiSiamo, setPortaleGruppoDiLavoro, setContattiMail, setContentFetched) {
    ContenutiRepository().getSezione({ tipologia: "ARGOMENTO_GENERICO_PORTALE" })
        .then(result => result?.map((el, i) => (
            el.path === "/portale/progetto" ?
                setPortaleProgetto({
                    ...el,
                    icon: 'it-arrow-right-triangle',
                    title: "Progetto - il portale"
                }) :
                el.path === "/portale/gruppo-lavoro" ?
                    setPortaleGruppoDiLavoro({
                        ...el,
                        icon: 'it-arrow-right-triangle',
                        title: "Gruppo di lavoro - il portale"
                    }) :
                    setPortaleChiSiamo({
                        ...el,
                        icon: 'it-arrow-right-triangle',
                        title: "Chi siamo - il portale"
                    })
        )))

    ContenutiRepository().getSezione({ tipologia: "ARGOMENTO_GENERICO_CONTATTI" })
        .then(result => result?.map((el, i) => (
            setContattiMail({
                ...el,
                icon: 'it-arrow-right-triangle',
                title: "Contatti - posta elettronica"
            })
        )))

    setContentFetched(true)
}