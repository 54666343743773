import React from "react";
import { Icon } from "design-react-kit";

export default function GalleryCard(props) {

    return (
        <>
            <div className={"galleryCardContainer"}>
                {!props.readonly ? <Icon color="black" icon="it-close-circle" id="arrowRight" onClick={() => props.removeSelf(props.index)} /> : null}
                <img
                    className={"galleryCard"}
                    id={props.id}
                    src={props.content.path}
                    alt={props.content.alt}
                />
                <p className={"galleryCardDescription"}>{props.content.testoAlternativo}</p>
                <label className="label-gallery">Descrizione</label>
                <input readOnly={props.readonly} className="input-gallery" type="text" value={props.value} onChange={(e) => props.change(props.index, "descrizione", e.target.value)} />
                <label className="label-gallery">Link</label>
                <input readOnly={props.readonly} className="input-gallery" type="text" value={props.link} onChange={(e) => props.change(props.index, "link", e.target.value)} />
                {!props.validateLink && <div className={"invalid-link"}><i>Inserire un sito valido che inizi con http/https</i></div>}
            </div>
        </>
    )
}