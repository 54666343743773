import React from 'react';
import SessioneUtente from '../../../../sessionManager/SessioneUtente';
import Logo from '../../../UI/Logo/Logo';
import { Icon } from 'design-react-kit';
import "./CenterHeader.css";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { PWD_EXP_CRITICAL_DAYS, PWD_EXP_MAX_DAYS, PWD_EXP_WARNING_DAYS } from '../../../../config/config';

function CenterHeader(props) {

    const checkScadenza = (value) => {



        let dataOdierna = new Date();
        let dataUltimoCambioPassword = new Date(value);
        let dataScadenza = new Date(dataUltimoCambioPassword);

        dataScadenza.setDate(dataUltimoCambioPassword.getDate() + parseInt(PWD_EXP_MAX_DAYS))

        let Difference_In_Time = dataScadenza.getTime() - dataOdierna.getTime()

        let diffDays = Math.floor(Difference_In_Time / (1000 * 3600 * 24));

        if (diffDays <= PWD_EXP_WARNING_DAYS && diffDays > PWD_EXP_CRITICAL_DAYS) {
            return <div className="danger-container">
                <label className="warning-label">
                    <Icon className="warning" icon="it-warning-circle" id="warning" />
                    {"La password scade fra " + diffDays + " giorni. "}<Link to="/cambio-password" ><span>{"Clicca qui"}</span></Link></label>
            </div>

        } else if (diffDays <= PWD_EXP_CRITICAL_DAYS) {
            let giorni;
            let etichettaScadenza;
            if (diffDays === 1) {
                giorni = " giorno. ";
                etichettaScadenza = "La password scade fra " + diffDays;
            } else if (diffDays === 0) {
                giorni = " oggi. ";
                etichettaScadenza = "La password scade ";
            } else if (diffDays < 0) {
                giorni = " ";
                etichettaScadenza = "La password è scaduta";
            } else {
                giorni = " giorni. ";
                etichettaScadenza = "La password scade fra " + diffDays;
            }
            return <div className="danger-container">
                <label className="danger-label">
                    <Icon className="danger" color="danger" icon="it-warning-circle" id="danger" />
                    {etichettaScadenza + giorni}<Link to="/cambio-password" ><span>{"Clicca qui"}</span></Link></label>
            </div>
        }
    }

    return (
        <div className="it-nav-wrapper" id="userCenterHeader">
            <div className="it-header-center-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-9">
                            <div className="it-header-center-content-wrapper">
                                <Logo />
                            </div>
                        </div>
                        <div className="col-3">
                            {props.mostraScadenzaPassword ? checkScadenza(SessioneUtente.getInstance().scadenzaPassword) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CenterHeader;