import React, { useState, useEffect, useContext } from 'react';
import MenuSection from './MenuSection/MenuSection';
import { Icon } from 'design-react-kit';
import { Redirect, useHistory } from 'react-router-dom';
import Card from '../../UI/Card/Card';
import Publisher from '../../UI/Publisher/Publisher';
import useModal from '../../../hook/useModal';
import CustomModal from '../../CustomModal/CustomModal';
import { Messages } from '../../Utils/Messages';
import useObjectsArray from "../../../hook/useObjectsArray";
import './EditMenu.css';
import MenuRepository from '../../../Repo/MenuRepository';
import { headerMsg } from './MenuUtils';
import moment from 'moment';
import { OK_RELEASE, KO_RELEASE, KO_OPERATION, operazioniContenuto } from '../../Utils/Dataset';
import { showLoading, hideLoading, showSuccess, scrollToTop } from '../../../hook/globalState/Store';
import { alertLevels } from '../../UI/InPageAlert/InPageAlert';
import { Context, showError } from '../../../hook/globalState/Store';
import { hasErrorOutcome } from "../../Interceptor/Interceptor";
import './EditMenu.css';
import { forcedScrollToTop } from '../../Utils/Utils';
import { getMessaggioOperazione } from '../../../Repo/Utils';
import { WAFE_PREVIEW_URL } from '../../../config/config';
import PreviewModal from '../../PreviewModal/PreviewModal';


export default function EditMenu(props) {

    const [state, dispatch] = useContext(Context);
    const [title, setTitle] = useState(null);
    const [section, setSection] = useState(null);

    // Gestione finestre modali
    const [action, setAction] = useState("");
    const [show, , handleClose, handleShow] = useModal(false);
    const [redirect, setRedirect] = useState(false);
    const history = useHistory();

    // Gestione caricamento dati
    const [contentFetched, setContentFetched] = useState(false);
    const [voci, setVoci, removeVoce, addVoce, updateVoce] = useObjectsArray([], null, null); // Contiene le voci della sezione
    const [menuData, setMenuData] = useState({
        dataModifica: null,
        utenteModifica: null,
        lingua: null
    });

    // Gestione pulsanti
    const btnPrimary = [
        {
            id: "salva", color: "secondary", outline: true, title: "Salva modifiche", disabled: false,
            onClickHandler: () => {
                MenuRepository().aggiornaMenu(props.title.toUpperCase(), newMenuSection(menuData.lingua, menuData.dataModifica, voci, updateVoce))
                    .then(result => {
                        if (result) {
                            if (!hasErrorOutcome(result)) {
                                dispatch({ type: 'RELOAD_EDITMENU', payload: { reloadEditMenu: true } });
                                showSuccess()
                            } else showError(getMessaggioOperazione(result?.esito?.messaggi[0], newMenuSection(menuData.lingua, menuData.dataModifica, voci, updateVoce)))
                        }
                    }).catch((err) => showError(KO_OPERATION))
                forcedScrollToTop(state, dispatch, scrollToTop) // Gestione momentanea
            }
        },
        {
            id: "reloadPage", color: "secondary", title: operazioniContenuto.ANNULLA_MODIFICHE, disabled: false,
            onClickHandler: () => {
                getModal(setAction, operazioniContenuto.ANNULLA_MODIFICHE, handleShow)
            }
        },
        {
            id: "3", color: "secondary", title: "Visualizza anteprima", disabled: false,
            onClickHandler: () => {
                getModal(setAction, operazioniContenuto.ANTEPRIMA, handleShow)
            }
        }

    ]
    const btnSecondary = [
        {
            id: "pubblica", color: "secondary", outline: false, title: "Pubblica", disabled: false,
            onClickHandler: () => {
                getModal(setAction, operazioniContenuto.PUBBLICAZIONE, handleShow)
            }
        }
    ]

    if (section !== props.title) { //necessario per gestire il reload del menu nel caso di navigazione tra due editmenu (e.g., da /edit/menu/main a /edit/menu/footer)
        setSection(props.title);
        setContentFetched(false)
    }

    // Gestione reload EditMenu
    useEffect(() => {
        if (state.reloadEditMenu) {
            setContentFetched(false)
            dispatch({ type: 'RELOAD_EDITMENU', payload: { reloadEditMenu: false } });
        }
    }, [state.reloadEditMenu])

    useEffect(() => {
        if (!contentFetched) {
            showLoading()
            onLoadHandler(props.title, setContentFetched, setMenuData, setVoci)
            switch (props.title) {
                case "main":
                    setTitle("Main menu")
                    break;
                case "header":
                    setTitle("Header menu")
                    break;
                case "footer":
                    setTitle("Footer menu")
                    break;
                case "bottom":
                    setTitle("Bottom bar menu")
                    break;
                default:
                    setTitle("Main menu")
                    break;
            }
        }
        else {
            hideLoading()
        }
    }, [contentFetched])

    // Sezione
    const menuContents = {
        contentFetched,
        section: props.title, // Identifica Main, Header o Footer
        voci,
        setVoci,
        updateVoce
    }
    const editComponent = <MenuSection menuContents={menuContents} />

    return (
        redirect ? <Redirect to="/" /> :
            <>
                <div id="editMenu">
                    <div id={"headerSection"}>
                        <div className="row">
                            <div id={"headerTextMenu"} className="col-6">
                                <h5>{title?.toUpperCase()}</h5>
                            </div>
                            <div id={"goBackMenu"} className="col-6">
                                <a id={"buttonBack"} onClick={() => getModal(setAction, operazioniContenuto.INDIETRO, handleShow)}> <Icon className="right" color="black" icon="it-arrow-left" id="arrow" />Indietro</a>
                            </div>
                        </div>
                        {action === operazioniContenuto.ANTEPRIMA ?
                            <PreviewModal
                                show={show}
                                onHide={handleClose}
                                title={"Anteprima"}
                                body={<iframe title={"Preview"} src={WAFE_PREVIEW_URL} width={"100%"} height={"100%"} />}
                            /> :
                            <CustomModal
                                show={show}
                                onHide={handleClose}
                                onConfirm={() => callBackAndClose(handleClose, setRedirect, action, props.title, dispatch, menuData.lingua, menuData.dataModifica, voci, updateVoce, history, state)}
                                title={action === operazioniContenuto.INDIETRO ? "Ritorno alla pagina principale" : action + " in corso"}
                                text={getModalMessage(action, props.title)}
                                body={action === operazioniContenuto.ANTEPRIMA ? <iframe title={"Preview"} src={"https://preview.wafept.pdt.18-196-167-173.nip.io/"} width={"100%"} height={"100%"} /> : null}
                            />}
                        <div className="row col-9">
                            <div id={"infoTextMenu"}>
                                {menuData.dataModifica !== null ?
                                    <div id="lastEdit">
                                        <span>Ultimo aggiornamento</span>: {moment(menuData.dataModifica).format("DD-MM-YYYY")}, <span>da </span> {menuData.utenteModifica}
                                        <br></br>
                                    </div> :
                                    null
                                }
                                <i>{headerMsg}</i>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div>
                            {editComponent}
                        </div>
                        <div className="col-3 cards">
                            <Card title={operazioniContenuto.PUBBLICAZIONE}>
                                <Publisher arrayPrimary={btnPrimary} arraySecondary={btnSecondary} />
                            </Card>
                        </div>
                    </div>
                </div>
            </>
    )
}

function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}

function callBackAndClose(handleClose, setRedirect, action, title, dispatch, lingua, dataModifica, voci, updateVoce, history, state) {
    if (action === operazioniContenuto.INDIETRO) {
        handleClose();
        setRedirect(true);
    }

    if (action === operazioniContenuto.PUBBLICAZIONE) {
        MenuRepository().pubblicaMenu(title.toUpperCase(), newMenuSection(lingua, dataModifica, voci, updateVoce))
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    setRedirect(true)
                    dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
                    dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.SUCCESS, alertMsg: OK_RELEASE } });
                } else showError(getMessaggioOperazione(result?.esito?.messaggi[0], newMenuSection(lingua, dataModifica, voci, updateVoce)))
            }).catch((err) => showError(KO_RELEASE))
        handleClose();
        forcedScrollToTop(state, dispatch, scrollToTop)
    }

    if (action === operazioniContenuto.ANNULLA_MODIFICHE) {
        history.go(0)
        handleClose();
    }
}

function getModalMessage(action, title) {
    switch (action) {
        case operazioniContenuto.INDIETRO:
            if (title === "main") return Messages.ERR_02_00_0B_0A
            else if (title === "header") return Messages.ERR_02_00_0C_0A
            else if (title === "footer") return Messages.ERR_02_00_0D_0A
            else if (title === "bottom") return Messages.ERR_TMP_BOTTOM_MENU
            break
        case operazioniContenuto.ANNULLA_MODIFICHE:
            return Messages.INFO_ANNULLA_MODIFICHE
        case operazioniContenuto.PUBBLICAZIONE:
            return Messages.INFO_PUBBLICAZIONE
        default:
            return Messages.INFO_DEFAULT
    }
}

function onLoadHandler(section, setContentFetched, setMenuData, setVoci) {

    MenuRepository().getMenu(section.toUpperCase())
        .then(result => {
            setVoci(result.voci);
            setMenuData({
                dataModifica: result.dataModifica,
                utenteModifica: result.utenteModifica,
                lingua: result.lingua
            })
            setContentFetched(true);
        })
}

function newMenuSection(lingua, dataModifica, voci, updateVoce) {

    voci.map((el, i) => updateVoce(i, "posizione", i))


    return Object.freeze({
        lingua: lingua,
        voci,
        dataModifica: dataModifica
    })
}
