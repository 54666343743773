 import React from 'react';
import './Scheduled.css';

export default function Scheduled(props) {
    return (
        <div id="scheduled">
            <div className="row">
                <div className="col-12">
                    <input id="dateInput" type="date" className={props.valid === false || props.validDate === false && props.stateToggle ? "is-danger" : null} value={props.valueDate} onChange={props.changeDate} disabled={!props.stateToggle} />
                    <input id="timeInput" max="18:00" className={props.valid === false || props.validTime === false && props.stateToggle ? "is-danger" : null} min="9:00" type="time" value={props.valueTime} onChange={props.changeTime} disabled={!props.stateToggle} />

                    {props.valid
                        ? (!props.validDate || !props.validTime) && props.stateToggle && (<p id={"scheduledError"}>{"Inserire sia la data che l'ora"}</p>)
                        : props.errorMsg && <p id={"scheduledError"}>{props.errorMsg}</p>
                    }

                </div>
            </div>
        </div>
    )
}
