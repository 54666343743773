import React, { useState, useRef } from 'react';
import { CustomOverlay } from '../../UI/CustomOverlay/CustomOverlay';
import './CollpasedMenuIcon.css';
import { Link } from 'react-router-dom';
import { Icon } from 'design-react-kit';

export function CollapsedMenuIconWithSubmenu(props) {

    const [show, setShow] = useState(false);
    const iconReference = useRef(null);

    return (
        <>
            <button type="button" className="icon-button with-submenu" title={props.title} ref={iconReference} onClick={() => setShow(!show)}>
                <img alt={""} className="menu-state-icon" src={props.icon} />
            </button>
            <CustomOverlay show={show} target={iconReference} setShow={setShow} menu={props.menu} nested={props.nested} popoverClassName="pdt-popover-menu" />
        </>
    );
}

export function CollapsedMenuIcon(props) {

    return (
        <button type="button" className="icon-button" title={props.title}>
            <Link className="list-item large medium right-icon" to={props.url}>
                {props.iconBI ? <Icon icon={props.iconBI} style={{ fill: "white" }} /> : <img alt={""} className="menu-state-icon" src={props.icon} />}
            </Link>
        </button>
    );
}