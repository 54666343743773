import React from "react";
import './Gallery.css'
import InfoModal from "../InfoModal/InfoModal";

export default function Gallery(props) {

    return (
        <div id="galleryContainer">
            {props.label &&
                <>
                    <label id={"galleryLabel"} className={"fieldLabel"}>{props.label}</label>
                    {props.infoMessage && <InfoModal message={props.infoMessage} />}
                </>
            }
            <div id={props.id}>
                {props.children.map((el, i) =>
                    <span key={el.key}>
                        {el}
                    </span>
                )}
            </div>
            {props.addElementRender}
        </div>
    )

}

