import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Icon } from 'design-react-kit';
import './LanguagePicker.css';
import Sprite from '../../img/sprite.svg';
import ContenutiRepository from '../../../Repo/ContenutiRepository';
import { linguaEnum, WAIT_FOR_OPERATION, statoContenuto, KO_OPERATION, OK_RELEASE, KO_RELEASE, tipologiaEnum, provenienzaEnum, roleEnum } from "../../Utils/Dataset";
import { Context, scrollToTop, showSuccess, showError, showLoading, hideLoading } from "../../../hook/globalState/Store";
import { alertLevels } from "../../UI/InPageAlert/InPageAlert";
import { Link, Redirect } from 'react-router-dom';
import DeFlag from '../../img/logos/de-flag.png';
import FrFlag from '../../img/logos/fr-flag.png';
import { hasErrorOutcome } from '../../Interceptor/Interceptor';
import SessioneUtente from '../../../sessionManager/SessioneUtente';


export default function LanguagePicker(props) {

    const sessione = SessioneUtente.getInstance();

    const [state, dispatch] = useContext(Context);
    const [redirect, setRedirect] = useState(false);
    const [data, setData] = useState({});
    const [contentFetched, setContentFetched] = useState(null);

    const [contentEn, setContentEn] = useState(null);
    const [contentDe, setContentDe] = useState(null);
    const [contentFr, setContentFr] = useState(null);

    const [isWaiting, setIsWaiting] = useState(false)

    useEffect(() => {
        if (props.altreLingue && props.altreLingue.length > 0) {
            setContentFetched(false);
        }

    }, [props.altreLingue]);

    useEffect(() => {
        if (!contentFetched) {
            onLoadHandler(props.altreLingue, setContentFetched, setContentEn, setContentDe, setContentFr)
        }
    }, [contentFetched]);

    const nextHandler = useCallback(async (contenuto) => {
        // don't send again while we are sending
        if (isWaiting) return
        // update state
        setIsWaiting(true)

        dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.INFO, alertMsg: WAIT_FOR_OPERATION } });
        scrollToTop()

        // send the actual request
        ContenutiRepository().creaContenuto(contenuto)
            .then(result => {
                setData(result.contenuto);
                setRedirect(true);
                dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
                showSuccess()
                // router.push('/edit/contenuto/' + result.id);
            })
            .catch(err => {
                //dispatch({type: 'SHOW_ALERT', payload: {alertLevel: alertLevels.ERROR, alertMsg: "Errore durante la creazione del contenuto"}});
            })
        // once the request is sent, update state again
        setIsWaiting(false)
    }, [isWaiting]) // update the callback if the state changes

    const en = props.altreLingue.filter(x => x.lingua === linguaEnum.INGLESE);
    const de = props.altreLingue.filter(x => x.lingua === linguaEnum.TEDESCO);
    const it = props.altreLingue.filter(x => x.lingua === linguaEnum.ITALIANO);
    const fr = props.altreLingue.filter(x => x.lingua === linguaEnum.FRANCESE);
    const modifyText = props.solaVisualizzazione ? "Visualizza contenuto " : "Modifica contenuto "

    const isContenutoEliminato = (contenuto) => {
        if (contenuto === statoContenuto.ELIMINATO) return true;
        else return false;
    }
    const isTipologiaLea = (tipologia) => {
        if (tipologia === tipologiaEnum.APPLICAZIONE_LEA) return true;
        else return false;
    }
    const isUtenteCollaboratore = () => {
        if (sessione.ruoliUtente === roleEnum.COLLABORATORE) return true;
        else return false;
    }

    const getLinkAppCustom = (lingua) => {
        return "/applicazioni-custom/redirect/" + lingua;
    }

    const getLinkContenuto = (idContenuto) => {
        return "/edit/contenutoredirect/" + idContenuto;
    }

    const contenutoEn = (<div className="row">
        <span>EN</span>
        <svg className="icon d-none d-lg-block">
            <use href={Sprite + "#uk-flag"} />
        </svg>
        {en && en.length > 0 ?
            isContenutoEliminato(contentEn) && isUtenteCollaboratore() ?
                <span className={"disabled-collaboratore"}>Cestino <Icon className="left disabled-icon" icon={"it-arrow-right-circle"} /></span> :
                <Link to={!isContenutoEliminato(contentEn) ? (isTipologiaLea(props.tipologia) ? getLinkAppCustom(linguaEnum.INGLESE) : getLinkContenuto(en[0].id)) : "/cestino"} >
                    {!isContenutoEliminato(contentEn) ? modifyText : "Cestino "}<Icon className="left" color="blue" icon={"it-arrow-right-circle"} />
                </Link> :
            !props.solaVisualizzazione &&
            <a onClick={() => nextHandler(inizializzaContenuto(props.idAgnosticoLingua, linguaEnum.INGLESE, props.tipologia, props.template, props.nome, props.interno, props.fonte))}>
                Inserisci contenuto <Icon className="left" color="blue" icon={"it-arrow-right-circle"} />
            </a>}
    </div>)
    const contenutoDe = (<div className="row">
        <span>DE</span>
        <img className="flag" src={DeFlag} />
        {de && de.length > 0 ?
            isContenutoEliminato(contentDe) && isUtenteCollaboratore() ?
                <span className={"disabled-collaboratore"}>Cestino <Icon className="left disabled-icon" icon={"it-arrow-right-circle"} /></span> :
                <Link to={!isContenutoEliminato(contentDe) ? (isTipologiaLea(props.tipologia) ? getLinkAppCustom(linguaEnum.TEDESCO) : getLinkContenuto(de[0].id)) : "/cestino"} >
                    {!isContenutoEliminato(contentDe) ? modifyText : "Cestino "}<Icon className="left" color="blue" icon={"it-arrow-right-circle"} />
                </Link> :
            !props.solaVisualizzazione &&
            <a onClick={() => nextHandler(inizializzaContenuto(props.idAgnosticoLingua, linguaEnum.TEDESCO, props.tipologia, props.template, props.nome, props.interno, props.fonte))}>
                Inserisci contenuto <Icon className="left" color="blue" icon={"it-arrow-right-circle"} />
            </a>}
    </div>)
    const contenutoFr = (<div className="row">
        <span>FR</span>
        <img className="flag" src={FrFlag} />
        {fr && fr.length > 0 ?
            isContenutoEliminato(contentFr) && isUtenteCollaboratore() ?
                <span className={"disabled-collaboratore"}>Cestino <Icon className="left disabled-icon" icon={"it-arrow-right-circle"} /></span> :
                <Link to={!isContenutoEliminato(contentFr) ? (isTipologiaLea(props.tipologia) ? getLinkAppCustom(linguaEnum.FRANCESE) : getLinkContenuto(fr[0].id)) : "/cestino"} >
                    {!isContenutoEliminato(contentFr) ? modifyText : "Cestino "}<Icon className="left" color="blue" icon={"it-arrow-right-circle"} />
                </Link> :
            !props.solaVisualizzazione &&
            <a onClick={() => nextHandler(inizializzaContenuto(props.idAgnosticoLingua, linguaEnum.FRANCESE, props.tipologia, props.template, props.nome, props.interno, props.fonte))}>
                Inserisci contenuto <Icon className="left" color="blue" icon={"it-arrow-right-circle"} />
            </a>}
    </div>)

    return (
        <>
            {redirect && data?.id ? <Redirect to={{ pathname: (props.tipologia === tipologiaEnum.APPLICAZIONE_LEA) ? ('/applicazioni-custom/redirect/' + data.lingua) : ('/edit/contenutoredirect/' + data.id), customProps: { id: data.id, title: data.nome, interno: data.interno } }} /> : null}
            <div id="languagePicker">
                {props.flagLingua !== linguaEnum.ITALIANO ?
                    <div id="content">
                        <div className="row">
                            <span>IT</span>
                            <svg className="icon d-none d-lg-block">
                                <use href={Sprite + "#it-flag"} />
                            </svg>
                            {it && it.length > 0 ? <Link to={(props.tipologia === tipologiaEnum.APPLICAZIONE_LEA) ? ('/applicazioni-custom/redirect/' + linguaEnum.ITALIANO) : "/edit/contenutoredirect/" + it[0].id} >Versione in italiano <Icon className="left" color="blue" icon={"it-arrow-right-circle"} /></Link> : null}
                        </div>
                    </div>
                    :
                    <>
                        <label>Altre lingue disponibili:</label>
                        <div id="content" className="pb-1">
                            {props.solaVisualizzazione ?
                                contentEn && contenutoEn : contenutoEn}
                            {props.solaVisualizzazione ?
                                contentDe && contenutoDe : contenutoDe}
                            {props.solaVisualizzazione ?
                                contentFr && contenutoFr : contenutoFr}
                            {props.solaVisualizzazione && props.altreLingue.length === 0 && <i style={{ fontSize: "0.7rem" }}>Questo contenuto non è presente in altre lingue.</i>}
                        </div>
                    </>}
            </div>
        </>
    )
}

function onLoadHandler(altreLingue, setContentFetched, setContentEn, setContentDe, setContentFr) {


    showLoading()
    if (altreLingue) {
        altreLingue.map((el) => {
            ContenutiRepository().getContenutoById(el.id)
                .then((result) => {
                    if (result) {
                        if (!hasErrorOutcome(result)) {
                            if (result.lingua === linguaEnum.INGLESE) {
                                setContentEn(result.stato);
                            } else if (result.lingua === linguaEnum.TEDESCO) {
                                setContentDe(result.stato);
                            } else if (result.lingua === linguaEnum.FRANCESE) {
                                setContentFr(result.stato);
                            }
                            setContentFetched(true);
                            hideLoading();
                        } else showError(KO_OPERATION)
                    }
                }).catch(err => {
                    showError(KO_OPERATION)
                })
        })
    }
}

function inizializzaContenuto(idAgnosticoLingua, lingua, tipologia, template, nome, interno, fonte) {

    var localizzazioneNome;
    let sessione = SessioneUtente.getInstance();

    if (lingua === linguaEnum.INGLESE) {
        localizzazioneNome = nome + " (en)";
    } else if (lingua === linguaEnum.TEDESCO) {
        localizzazioneNome = nome + " (de)";
    } else if (lingua === linguaEnum.FRANCESE) {
        localizzazioneNome = nome + " (fr)";
    }

    return Object.freeze({
        idAgnosticoLingua,
        template,
        tipologia,
        nome: localizzazioneNome,
        lingua,
        areaTerritoriale: sessione.idAreaTerritoriale,
        interno: interno,
        fonte: fonte
    })

}