import React, { useState, useEffect, useContext } from 'react';
import useInput from '../../../hook/useInput';
import useToggle from '../../../hook/useToggle';
import TitleTemplate from '../../UI/TitleTemplate/TitleTemplate';
import './PrestazioniGarantite.css';
import Card from '../../UI/Card/Card';
import Publisher from '../../UI/Publisher/Publisher';
import TextEditor from '../../UI/TextEditor/TextEditor';
import useObjectsArray from '../../../hook/useObjectsArray';
import { Redirect } from 'react-router-dom';
import {
    blankQuill, KO_OPERATION, OK_RELEASE, KO_RELEASE, operazioniContenuto, leaEnum, tipologiaEnum, roleEnum, contentNuovaLingua
} from '../../Utils/Dataset';
import { validateQuillNoTitle } from '../../UI/FieldValidation/FieldValidation';
import useModal from '../../../hook/useModal';
import { Icon } from 'design-react-kit';
import CustomModal from '../../CustomModal/CustomModal';
import { Context, showLoading, hideLoading, showSuccess, showError, scrollToTop } from "../../../hook/globalState/Store";
import { Messages } from "../../Utils/Messages";
import DatabaseRicerca from '../../UI/DatabaseRicerca/DatabaseRicerca';
import ContenutiRepository from '../../../Repo/ContenutiRepository';
import { hasErrorOutcome } from '../../Interceptor/Interceptor';
import moment from 'moment';
import { alertLevels } from '../../UI/InPageAlert/InPageAlert';
import { forcedScrollToTop, isUtenteRegionale } from '../../Utils/Utils';
import SessioneUtente from '../../../sessionManager/SessioneUtente';
import LanguagePicker from '../../UI/LanguagePicker/LanguagePicker';

export default function PrestazioniGarantite(props) {

    const headerMsg = "In questa sezione puoi gestire il contenuto dell'applicazione custom. Ricordati di selezionare 'Pubblica' per permetere la pubblicazione della modifica sul Portale";

    // Sessione
    let session = SessioneUtente.getInstance();
    let isRegionale = isUtenteRegionale(session.ruoliUtente);

    //state
    const [redirect, setRedirect] = useState(false);
    const [state, dispatch] = useContext(Context);
    const [action, setAction] = useState("");
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [contentFetched, setContentFetched] = useState(false);
    const [nameNotEditable, setNameNotEditable] = useToggle(true);
    const [lea, setLea, updateLea] = useObjectsArray(null) // Gestione LEA
    const [stato, setStato] = useState();

    // Gestione ultima modifica
    const [ultimaModifica, setUltimaModifica] = useState();
    const [utenteUltimaModifica, setUtenteUltimaModifica] = useState();

    //Contenuto altre lingue
    const [contenutoAltraLingua, setContenutoAltraLingua] = useObjectsArray([], null, contentNuovaLingua)
    const [lingua, setLingua] = useState(null);

    // Gestione dati
    const [idLea, setIdLea] = useState();
    const [template, setTemplate] = useState();
    const [tipologia, setTipologia] = useState();
    const [idAgnosticoLingua, setIdAgnosticoLingua] = useState(null);
    const [path, setPath] = useState();
    const [name, setName, nameChangedHandler] = useInput("");
    const [database, setDatabase, , , updateDatabase] = useObjectsArray(null); // Gestione database
    const [testo, setTesto, removeTestoHandler, addTestoHandler, updateTestoHandler, validateTesto] = useObjectsArray(null, validateQuillNoTitle, blankQuill);
    const [titolo, setTitolo, titoloChangedHandler] = useInput("");

    const hasError = () => {
        return validateTesto?.includes(false)
    }

    //bottoni
    const btnPrimary = [
        {
            //le classi sono invertite per aderenza al mockup
            id: "1", color: "secondary", outline: true, title: "Salva modifiche", disabled: hasError(), disabledTooltip: Messages.INFO_DISABLED_SALVA_MODIFICHE,
            onClickHandler: () => {
                ContenutiRepository().aggiornaContenuto(InizializzaLea(idLea, name, template, tipologia, path, titolo, database, testo, lea?.dataModifica, utenteUltimaModifica, session))
                    .then(result => {
                        if (result) {
                            if (!hasErrorOutcome(result)) {
                                setContentFetched(false)
                                showSuccess()
                            } else showError(KO_OPERATION)
                        }
                    })
                    .catch(err => {
                        showError(KO_OPERATION)
                    })
                forcedScrollToTop(state, dispatch, scrollToTop) // Gestione momentanea
            }
        },
        {
            id: "2",
            color: "secondary",
            title: "Visualizza anteprima",
        }
    ]

    const btnSecondary = [
        {
            id: "3", color: "secondary", outline: false, title: operazioniContenuto.RIPRISTINA
        },
        {
            id: "4", color: "secondary", outline: true, title: "Pubblica",
            onClickHandler: () => {
                getModal(setAction, operazioniContenuto.PUBBLICAZIONE, handleShow)
            }
        }
    ]

    useEffect(() => {
        if (contentFetched) {
            setName(lea?.nome ? lea?.nome : "");
            setTesto(lea?.testo ? lea?.testo : [blankQuill]);
            setIdLea(lea?.id ? lea?.id : "")
            setTitolo(lea?.titolo ? lea?.titolo : "")
            setUtenteUltimaModifica(lea?.utenteModifica ? lea?.utenteModifica : "")
            setTemplate(lea?.template ? lea?.template : "")
            setTipologia(lea?.tipologia ? lea?.tipologia : "")
            setPath(lea?.path ? lea?.path : "")
            setUltimaModifica(lea?.dataModifica ? moment(lea?.dataModifica).format("DD-MM-YYYY") : "");
            setStato(lea?.stato ? lea?.stato : "")
            setIdAgnosticoLingua(lea?.idAgnosticoLingua ? lea?.idAgnosticoLingua : "");
            setContenutoAltraLingua(lea?.contenutiAltraLingua ? lea?.contenutiAltraLingua : []);
            setLingua(lea?.lingua ? lea?.lingua : "");
        } else {
            showLoading()
            onLoadHandler(setContentFetched, setDatabase, setLea, props.match.params.lingua)
        }
    }, [contentFetched, lea]);

    return (
        contentFetched ?
            redirect ? <Redirect to="/" /> :
                <div id="prestazioniGarantite" className="container-fluid">
                    <div className="row">
                        <div className="col-6">
                            <h5>{props.title}</h5>
                        </div>
                        <div id={"goBack"} className="col-6">
                            <a id={"buttonBack"} onClick={() => getModal(setAction, operazioniContenuto.INDIETRO, handleShow)}> <Icon className="right" color="black" icon="it-arrow-left" id="arrow" />Indietro</a>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-9'}>
                            {lea ? <TitleTemplate
                                name={name} icona={true} onChangeName={nameChangedHandler}
                                nameNotEditable={nameNotEditable} setNameNotEditable={setNameNotEditable}
                                ultimaModifica={ultimaModifica} utenteUltimaModifica={utenteUltimaModifica}
                            /> : null}
                            <div id={"infoText"}>
                                {lea ? <i>{headerMsg}</i> : null}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-9">
                            <div className="contentText">
                                {lea && !isRegionale ? <TextEditor add={false} array={testo} delete={removeTestoHandler} updateValue={updateTestoHandler} valid={validateTesto} disabled={(session.ruoliUtente === roleEnum.AUTORE || session.ruoliUtente === roleEnum.COLLABORATORE) ? true : false} /> : <></>}
                            </div>

                            {/* Database per ora mockato */}
                            <div>
                                <DatabaseRicerca
                                    loadDb={true}
                                    label={"DATABASE RICERCA"}
                                    database={database ? database : []}
                                    setDatabase={setDatabase}
                                    updateDatabase={updateDatabase}
                                    firstPageDb={lea?.categorie}
                                    pathname={"/applicazioni-custom/prestazioni-garantite-ssn/" + lingua}
                                    fieldKey={"idCategoria"}
                                    versioneRegionale={(session.ruoliUtente === roleEnum.AUTORE || session.ruoliUtente === roleEnum.COLLABORATORE) ? true : false}
                                />
                            </div>

                        </div>
                        <div className="col-3" id={"publisherContainer"}>
                            <CustomModal
                                show={show}
                                onHide={handleClose}
                                onConfirm={() => callBackAndClose(handleClose, action, setRedirect, dispatch, InizializzaLea(idLea, name, template, tipologia, path, titolo, database, testo, lea?.dataModifica, utenteUltimaModifica, session), state)}
                                title={action === operazioniContenuto.INDIETRO ? "Ritorno alla home page" : action} in corso
                                text={getModalMessage(action)}
                            />
                            {!isRegionale ?
                                <>
                                    <Card title={operazioniContenuto.PUBBLICAZIONE}>
                                        <Publisher
                                            arrayPrimary={btnPrimary} arraySecondary={btnSecondary}
                                            stato={stato}
                                        />
                                    </Card>
                                    <Card title={"Lingua: " + lingua} flag={true} lingua={lingua}>
                                        <LanguagePicker tipologia={tipologia} nome={name} template={template} idAgnosticoLingua={idAgnosticoLingua} altreLingue={contenutoAltraLingua} flagLingua={lingua} />
                                    </Card>
                                </> : null}
                        </div>
                    </div>
                </div > : null
    )
}

function onLoadHandler(setContentFetched, setDatabase, setLea, lingua) {

    ContenutiRepository().getSezione({ tipologia: "APPLICAZIONE_LEA", lingua: lingua, dontUseAreaTerritorialeFilter: true })
        .then(result => {
            let index = 0;
            if (result && result.length) {

                // Controllo sulla path per distinguere "Applicazione LEA" da "Applicazione LEA extra regionali"
                if (result[0].path?.endsWith("/applicazione/lea")) setLea(result[0])
                else {
                    setLea(result[1])
                    index = 1;
                }

                setDatabase([
                    {
                        etichetta: "Ausili",
                        visibile: true,
                        sottoCategorie: result[index]?.categorie?.filter(e => e.tipologia.toLowerCase() === leaEnum.AUSILI),
                        notEditable: true,
                        idCategoria: "AusiliLEA"
                    },
                    {
                        etichetta: "Presidi",
                        visibile: true,
                        sottoCategorie: result[index]?.categorie?.filter(e => e.tipologia.toLowerCase() === leaEnum.PRESIDI),
                        notEditable: true,
                        idCategoria: "PresidiLEA"
                    },
                    {
                        etichetta: "Prestazioni",
                        visibile: true,
                        sottoCategorie: result[index]?.categorie?.filter(e => e.tipologia.toLowerCase() === leaEnum.PRESTAZIONI),
                        notEditable: true,
                        idCategoria: "PrestazioniLEA"
                    },
                ])
            }
        })
        .then(() => {
            setContentFetched(true)
            hideLoading()
        })
}

function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}

async function callBackAndClose(handleClose, action, setRedirect, dispatch, inizializzaLea, state) {

    if (action === operazioniContenuto.INDIETRO) {
        handleClose();
        setRedirect(true)
    }

    if (action === operazioniContenuto.PUBBLICAZIONE) {
        ContenutiRepository().pubblicaContenuto(inizializzaLea)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    setRedirect(true)
                    dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
                    dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.SUCCESS, alertMsg: OK_RELEASE } });
                } else showError(KO_RELEASE)
            }).catch((err) => showError(KO_RELEASE))
        handleClose();
        forcedScrollToTop(state, dispatch, scrollToTop)
    }
}

function getModalMessage(action) {
    switch (action) {
        case operazioniContenuto.INDIETRO:
            return Messages.ERR_INDIETRO_LEA
        default:
            return Messages.INFO_DEFAULT
    }
}

function InizializzaLea(idLea, name, template, tipologia, path, titolo, database, testo, ultimaModifica, utenteUltimaModifica, session) {

    var lingua = "IT";

    return Object.freeze({
        id: idLea,
        nome: name,
        template,
        tipologia,
        lingua,
        path,
        titolo,
        categorie: database[0]?.sottoCategorie.concat(database[1]?.sottoCategorie, database[2].sottoCategorie),
        testo,
        dataModifica: ultimaModifica,
        utenteModifica: utenteUltimaModifica,
        areaTerritoriale: session.idAreaTerritoriale
    });

}
