import React from 'react';
import CustomInput from '../../UI/CustomInput/CustomInput';
import TextEditor from '../../UI/TextEditor/TextEditor';
import './Template_Applicazione_Custom_Covid.css';
import { Messages } from '../../Utils/Messages';
import Select from '../../UI/Select/Select';



export default function Template_Applicazione_Custom(props) {

    return (
        <div id="ApplicazioneStruttureCovid">
            <div className="inputBoxWithShadow">
                <CustomInput id="input1" labelName="Titolo*" change={props.content.titleChangedHandler} name={props.content.title} valid={props.content.validateTitle} />
                <CustomInput id="input3" labelName="DESCRIZIONE (non visibile sul Portale)" change={props.content.descriptionChangedHandler} name={props.content.description} valid={props.content.validateDescription} textarea />
                <CustomInput id="input4" labelName="Url*" change={() => { }} name={props.content.path} valid={props.content.validatePath} tipologia={props.content.tipologia} setPath={props.content.setPath} invalidMsg={props.content.invalidMsg ?? props.content.invalidMsg} setInvalidMsg={props.content.setInvalidMsg} />
                <Select idOption={"selectId"} title={"Link da collegare*"} placeholder="Seleziona il link da collegare..." array={props.content.arrayLinkCovid && props.content.arrayLinkCovid.length > 0 ? props.content.arrayLinkCovid.map(el => ({ ...el, value: el.id })) : []} value={props.content.idLink} change={props.content.idLinkChangedHandler} valid={props.content.validateIdLink} fieldValue={"id"} fieldName={"nome"} infoMessage={Messages.INFO_IDLINK} />
            </div>
            <label className="text-type">TESTO*</label>
            <TextEditor isErasable={false} array={props.content.testo} delete={props.content.removeTestoHandler} updateValue={props.content.updateTestoHandler} valid={props.content.validateTesto} indexOfPage={props.content.index} />
        </div>
    )
}