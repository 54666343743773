import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import '../CustomModal/CustomModal.css';

export default function PreviewModal(props) {

    // Se da problemi di visualizzazione => aggiungere position: fixed;

    return (
        <Modal dialogClassName={"custom-modal"} show={props.show} onHide={props.onHide} backdrop={true}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.body}
            </Modal.Body>
        </Modal>
    )

}