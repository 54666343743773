import React, { useEffect, useState } from 'react'
import Card from '../../../UI/Card/Card';
import CustomInput from '../../../UI/CustomInput/CustomInput';
import Publisher from '../../../UI/Publisher/Publisher';
import CustomModal from '../../../CustomModal/CustomModal';
import useModal from '../../../../hook/useModal';
import { Messages } from '../../../Utils/Messages';
import { hasErrorOutcome } from '../../../Interceptor/Interceptor';
import { showError, showLoading, hideLoading, showSuccess } from '../../../../hook/globalState/Store';
import { KO_RELEASE, blankQuill, operazioniContenuto, tipologiaEnum, maxElementi } from '../../../Utils/Dataset';
import './ContattiRegionali.css';
import useInput from '../../../../hook/useInput';
import { validateQuillNoTitle } from '../../../UI/FieldValidation/FieldValidation';
import { uploadImage } from '../../../Utils/Utils';
import useFileHandler from '../../../../hook/useFileHandler';
import { BUCKET_URL, WAFE_PREVIEW_URL } from '../../../../config/config';
import useObjectsArray from '../../../../hook/useObjectsArray';
import TextEditor from '../../../UI/TextEditor/TextEditor';
import ContenutiRepository from '../../../../Repo/ContenutiRepository';
import SessioneUtente from '../../../../sessionManager/SessioneUtente';
import { statoContenuto, KO_OPERATION } from '../../../Utils/Dataset';
import PreviewModal from '../../../PreviewModal/PreviewModal';
import { Icon } from 'design-react-kit';

export default function ContattiRegionali() {

    //Sessione
    let session = SessioneUtente.getInstance();

    const headerMsg = "Qui puoi impostare i contatti regionali. Ricordati di selezionare 'Pubblica' per pubblicare la modifica."

    //Gestione chiamate
    const [contentFetched, setContentFetched] = useState(false);
    const [contatti, setContatti] = useState();

    //Gestione finestre modali
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [action, setAction] = useState("");

    //State
    const [testo, setTesto, , , updateTestoHandler, validateTesto] = useObjectsArray(null, validateQuillNoTitle, blankQuill);
    const [mapBox, setMapBox, onChangeMapBox, validateMapBox] = useInput(null);
    const [nome, setNome] = useState(null);
    const [nameEditable, setNameEditable] = useState(false);
    const [titolo, setTitolo, titoloChange] = useInput("");

    const [currentAttachment, setCurrentAttachment, attachmentMetadata, setAttachmentMetadata, onChangeCurrentAttachment] = useFileHandler()

    //Per controllare l'abilitazione dei pulsanti
    function hasError() {
        //campi comuni a tutti i template
        let valid = validateTesto
        return !valid
    }

    // Gestione custom width dell'input
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext("2d");

    //Pulsanti
    const btnPrimary = [
        {
            id: "1", color: "secondary", outline: true, title: "Salva modifiche", disabled: hasError(),
            onClickHandler: () => {
                getModal(setAction, operazioniContenuto.SALVA_MODIFICHE, handleShow)
            }
        },
        {
            id: operazioniContenuto.RIPRISTINA.toLowerCase(), color: "secondary", outline: false, disabled: ((contatti?.stato === statoContenuto.BOZZA && contatti?.versione?.major > 0) ? false : true), title: operazioniContenuto.RIPRISTINA,
            onClickHandler: () => {
                ContenutiRepository().ripristinaContenuto(contatti?.id) //.then(data => checkNomeModificato(data, originalName, dispatch))
                    .then(result => {
                        if (!hasErrorOutcome(result)) {
                            setContentFetched(false)
                            showSuccess()
                        }
                    })
                    .catch(err => showError(KO_OPERATION))
            }
        }
    ]
    const btnSecondary = [
        {
            id: "4", color: !hasError() ? "secondary" : "primary", outline: hasError(), title: "Pubblica", disabled: hasError(),
            onClickHandler: () => {
                getModal(setAction, operazioniContenuto.PUBBLICAZIONE, handleShow)
            }
        }
    ]

    useEffect(() => {
        if (!contentFetched) {
            onLoadHandler(setContentFetched, setContatti, session)
        }
        else {
            setTesto(contatti?.testo ? contatti.testo : [{ titolo: null, testo: "" }])
            setMapBox(contatti?.mappa ? contatti?.mappa.path : null)
            setNome(contatti?.nome ? contatti?.nome : "")
            setTitolo(contatti?.titolo ? contatti?.titolo : "")
        }
    }, [contentFetched])

    return (
        <>
            <div id="contattiRegionali">
                {action === operazioniContenuto.ANTEPRIMA ?
                    <PreviewModal
                        show={show}
                        onHide={handleClose}
                        onConfirm={() => callBackAndClose(handleClose, action, InizializzaContenuto(contatti, mapBox, testo, nome, titolo), setContentFetched)}
                        title={"Anteprima"}
                        text={getModalMessage(action)}
                        body={<iframe title={"Preview"} src={WAFE_PREVIEW_URL + contatti?.path} width={"100%"} height={"100%"} />}
                    /> :
                    <CustomModal
                        show={show}
                        onHide={handleClose}
                        onConfirm={() => callBackAndClose(handleClose, action, InizializzaContenuto(contatti, mapBox, testo, nome, titolo), setContentFetched)}
                        title={action + " in corso"}
                        text={getModalMessage(action)}
                    />}
                <div className="row">
                    <div id={"headerText"} className="col-8">
                        <h5>Contatti regionali</h5>
                        <div className="nome-contenuto">
                            <span> Nome: </span>
                            <input
                                style={{ width: ctx.measureText(nome).width * 2 + 25 + "px", maxWidth: "60%", minWidth: "10%" }}
                                id={"inputName"}
                                value={nome}
                                disabled={!nameEditable}
                                onChange={(e) => setNome(e.target.value)}
                            />
                            {}
                            {!nameEditable ?
                                <button title={"Modifica nome"} onClick={() => setNameEditable(!nameEditable)}>
                                    <Icon color="black" icon="it-pencil" id="pencil" title={"Modifica nome"} />
                                </button> :
                                <button title={"Interrompi modifica"} onClick={() => setNameEditable(!nameEditable)}>
                                    <Icon id={"confirmUpdate"} icon="it-check" color="success" title={"Interrompi modifica"} />
                                </button>
                            }
                        </div>
                        <div id={"infoText"}>
                            <i>{headerMsg}</i>
                        </div>
                        <div className="title-field">
                            <CustomInput id={"title-input"} labelName={"TITOLO"} change={titoloChange} name={titolo} valid={true} />
                        </div>
                        {mapBox ?
                            <>
                                <div id={"previewMapDiv"}>
                                    <img src={BUCKET_URL + mapBox} id={"previewMap"} alt={"alt"} />
                                </div>
                            </> :
                            <div id={"mapNotLoad"}>
                                <i>Nessuna mappa caricata</i>
                            </div>
                        }
                        <div id={"inserimentoMappaContatti"}>
                            <input
                                type="file"
                                id={"mappa"}
                                className="upload"
                                onChange={(e) => onChangeCurrentAttachment(e, (fileBin, fileMetadata) => {
                                    uploadImage(fileBin, setMapBox)
                                })}
                            />
                            <label id="btnLoad" htmlFor={"mappa"}>
                                {!mapBox ? "Carica mappa" : "Modifica mappa"}
                            </label>
                        </div>

                        <TextEditor
                            array={testo}
                            hideTitle
                            updateValue={updateTestoHandler}
                            valid={validateTesto}
                        />

                    </div>
                    <div className="col-3 cards">
                        <Card title={operazioniContenuto.PUBBLICAZIONE}>
                            <Publisher
                                stato={contatti?.stato}
                                arrayPrimary={btnPrimary}
                                arraySecondary={btnSecondary}
                            />
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

function callBackAndClose(handleClose, action, contenuto, setContentFetched) {

    if (action === operazioniContenuto.PUBBLICAZIONE) {
        showLoading()
        ContenutiRepository().pubblicaContenuto(contenuto)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    setContentFetched(false)
                } else showError(KO_RELEASE)
            }).catch((err) => showError(KO_RELEASE))
            .finally(hideLoading)
        handleClose();
    }
    else if (action === operazioniContenuto.SALVA_MODIFICHE) {
        showLoading()
        ContenutiRepository().aggiornaContenuto(contenuto).then(result => {
            if (result) {
                if (!hasErrorOutcome(result)) {
                    setContentFetched(false)
                    showSuccess()
                } else showError(KO_RELEASE)
            }
        })
            .catch((err) => showError(KO_RELEASE))
            .finally(() => {
                hideLoading()
            })
        handleClose()
    }
}

function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}

function changeTesto(e, setTesto) {
    setTesto(e)
}

function getModalMessage(action) {
    switch (action) {
        case operazioniContenuto.INDIETRO:
            return Messages.ERR_TMP_EDIT_CONTENUTO
        case operazioniContenuto.PUBBLICAZIONE:
            return Messages.INFO_PUBBLICAZIONE
        case operazioniContenuto.SALVA_MODIFICHE:
            return Messages.INFO_SALVA_CONTATTI_REGIONALI
        default:
            return Messages.INFO_DEFAULT
    }
}

function onLoadHandler(setContentFetched, setContatti, session) {
    if (session.idAreaTerritoriale) {
        ContenutiRepository().getSezione({ tipologia: tipologiaEnum.CONTATTO_REGIONALE }, { numeroElementi: maxElementi })
            .then(result => {
                setContatti(result.filter(el => el.areaTerritoriale === session.idAreaTerritoriale)[0])
            })
            .catch(err => showError)
            .finally(() => setContentFetched(true))
    }
    else {
        console.warn("IdAreaTerritoriale non correttamente valorizzata");
    }
}

function InizializzaContenuto(contatti, mappa, testo, nome, titolo) {

    return Object.freeze({
        ...contatti,
        mappa: { path: mappa, testoAlternativo: mappa, descrizione: null, link: null },
        testo,
        nome,
        titolo
    })
}