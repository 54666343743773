import React, { useContext, useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { Button, Icon } from 'design-react-kit';
import './AbilitazioneMfa.css';
import CustomInput from '../../UI/CustomInput/CustomInput';
import { alertLevels } from "../../UI/InPageAlert/InPageAlert";
import validate, { validateQrCode } from '../../UI/FieldValidation/FieldValidation';
import useInput from '../../../hook/useInput';
import UtentiRepository from '../../../Repo/UtentiRepository';
import { APPLICATION_NAME, KO_RELEASE, OK_RELEASE } from '../../Utils/Dataset';
import SessioneUtente from '../../../sessionManager/SessioneUtente';
import { Context, hideLoading, showError, showLoading } from '../../../hook/globalState/Store';
import { getMessaggioOperazione } from '../../../Repo/Utils';
import { hasErrorOutcome } from '../../Interceptor/Interceptor';
import { Link, Redirect } from 'react-router-dom';
import authConfig from '../../../config/authConfig';
import TopHeader from '../../layout/FullHeader/TopHeader/TopHeader';
import CenterHeader from '../../layout/FullHeader/CenterHeader/CenterHeader';
import Flag from '../../layout/FullHeader/CenterHeader/Flag/Flag';
import { auth_debug_log_enable } from '../../../config/config';

export default function AbilitazioneMfa() {

    let infomMsg = "Accesso tramite MFA non configurato, seguire le istruzioni per effettuare la procedura di configurazione.";
    let istruzioni = "Accedi a Google Authenticator dal tuo smartphone e scansiona il QR-CODE presente in questa pagina. Una volta ottenuto il codice numerico in Google Authenticator inseriscilo all'interno del campo Codice e premi Invia.";
    let qrCodeMsg = "Scansiona il QR-CODE con il tuo dispositivo";
    let secureCodeMsg = "Oppure inserisci manualmente il codice di configurazione su googleAuthenticator: ";

    const [state, dispatch] = useContext(Context);

    const [codicePairing, setCodicePairing, codicePairingChangedHandler, validateCodice] = useInput("", validateQrCode);
    const [codeFetched, setCodeFetched] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [secureCode, setSecureCode] = useState(null);
    const [qrCodeString, setQrCodeString] = useState(null);
    const [qrCodeError, setQrCodeError] = useState(null);
    const [errore, setErrore] = useState(null);
    const [pairingCompleted, setPairingCompleted] = useState(false);

    let session = SessioneUtente.getInstance();

    function hasError() {
        let valid = null;
        valid = validateCodice;

        return !valid;
    }

    useEffect(() => {
        if (codeFetched) {
            if (secureCode) {
                setQrCodeString("otpauth://totp/Portale%20Trasparenza%20(" + session.codiceFiscale + ")?secret=" + secureCode);
                setQrCodeError(false)
            }
            else {
                setQrCodeError(true)
            }
        } else {
            onLoadHandler(session.codiceFiscale, APPLICATION_NAME, session.accessToken, setSecureCode, setCodeFetched, setErrore)
        }
    }, [codeFetched, secureCode]);

    const onClickHandler = () => {

        showLoading()
        UtentiRepository().verificaDispositivoMFA(SessioneUtente.getInstance().accessToken, codicePairing, APPLICATION_NAME, SessioneUtente.getInstance().codiceFiscale)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    setPairingCompleted(true);
                    /* setRedirect(true)
                    dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } }); */
                    dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.SUCCESS, alertMsg: OK_RELEASE } });
                } else showError(getMessaggioOperazione(result?.esito?.messaggi[0], result?.esito))
            }).catch((err) => showError(KO_RELEASE))
            .finally(hideLoading)
    }

    if (errore) {
        return <div className="abilitazioneMfa">
            <div className="container-fluid inputBoxWithShadow">
                <div className="row">
                    <div className="col">{errore}</div>
                </div>
            </div>
        </div>
    }
    else {
        return (
            <React.Fragment>
                <div className="pt-sticky-header">
                    <div className="pt-header-content">
                        <div className="it-header-wrapper it-header-sticky">
                            <TopHeader />
                            <CenterHeader mostraScadenzaPassword={false} />
                            <Flag></Flag>
                        </div>
                    </div>
                </div>
                {pairingCompleted ?
                    <div>
                        <div>Configurazione MFA completata con successo.</div>
                        <a href={authConfig['url-logout']}>Clicca qui accedere con la nuova configurazione e cominciare ad operare nel backoffice</a>
                    </div> :
                    <div className="abilitazioneMfa">
                        <div className="container-fluid inputBoxWithShadow">
                            <div className="row">
                                <div className="col-1">
                                    <Icon className="it-lock" color="black" icon="it-locked" id="lock" />
                                </div>
                                <div className="col-11">
                                    <p>{infomMsg}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-1">
                                    <Icon className="it-tool" color="black" icon="it-tool" id="tool" />
                                </div>
                                <div className="col-11">
                                    <p><b>{"Istruzioni: "}</b>{istruzioni}</p>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <label className="qr-label">{qrCodeMsg}</label>
                                </div>
                                <div className="col-12">
                                    {qrCodeError ? <div className="qr-label">Impossibile generare il qr-code. Contattere il supporto tecnico.</div> :
                                        codeFetched && secureCode && qrCodeString ? <QRCode className="qr-code" value={qrCodeString} size="300" /> : <div><strong>In attesa di generazione del QR Code...</strong></div>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <label className="qr-label">{secureCodeMsg}  <b>{secureCode}</b></label>
                            </div>
                        </div>
                        <div className="container-fluid inputBoxWithShadow">
                            {qrCodeString ? <div className="row">
                                <div className="col-6">
                                    <CustomInput id="input1" labelName="Codice" change={codicePairingChangedHandler} name={codicePairing} valid={validateCodice} disableValidColor={false} />
                                </div>
                                <div className="col-6">
                                    <Button id="inviaButton" color="secondary" disabled={hasError()} tag="button" onClick={() => onClickHandler()}>INVIA</Button>
                                </div>
                            </div> : null}
                        </div>
                    </div >}
            </React.Fragment>

        )
    }
}

function onLoadHandler(codiceFiscale, applicazione, accessToken, setSecureCode, setCodeFetched, setErrore) {

    if (accessToken) {
        showLoading()
        UtentiRepository().setMFAUtente(accessToken, applicazione, codiceFiscale)
            .then(result => {
                if (result) {
                    auth_debug_log_enable && console.log("setMFAUtente result: ", result)
                    setErrore("");
                    setSecureCode(result);
                }
                else {
                    setErrore("Impossibile recuperare informazioni su MFA utente");
                }
            })
            .catch((err) => {
                auth_debug_log_enable && console.log("[DEBUG] Errore setMFAUtente: ", err)
                setErrore("Impossibile recuperare informazioni su MFA utente");
            })
            .finally(() => {
                setCodeFetched(true);
                hideLoading()
            })
    } else {
        auth_debug_log_enable && console.log("[DEBUG] AbilitazioneMFA, Missing accessToken")
        setCodeFetched(true);
        setErrore("AccessToken mancante. Contattare l'amministratore.");
    }
}