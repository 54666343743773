import { useState } from 'react';

export default function useToggle(start) {

    const [currentValue, setCurrentValue] = useState(start);

    function toggle() {
        setCurrentValue(!currentValue);
    }

    return [currentValue, toggle, setCurrentValue];
}