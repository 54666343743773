import React from "react";
import './ULSSBannerStyles.css'
import CustomInput from "../CustomInput/CustomInput";
import { Icon } from "design-react-kit";
import { Link } from "react-router-dom";
import { BUCKET_URL } from "../../../config/config";

export default function ULSSBanner(props) {
    return (
        <div className={'col-12 d-flex'} id={'ULSSBannerContainer'}>
            <div className={'col-4'} id={'ULSSBannerLogoContainer'}>
                {props.el?.icona ? <img alt={"ULSS Logo"} src={BUCKET_URL + props.el.icona} id={'ULSSBannerLogo'} /> : null}
            </div>
            <div className={'col-8'}>
                <CustomInput name={props.el?.nome} labelName="Nome struttura" id={"nome-" + props.index} valid change={(e) => props.change(props.index, 'nome', e.target.value)} disabled />
                {/* <CustomInput name={props.el?.sito} labelName="Sito" id={"inputTable-" + props.index} valid change={(e) => props.change(props.index, 'sito', e.target.value)} disabled /> */}
                <div className={'d-flex'}>
                    <div className={'col-12 pt-2'} id={'ULSSBannerLinks'}>
                        {/* <span>
                            <Icon color="black" icon="it-clip" id="arrowRight" />
                            <Link className="ref-link" to={'/link-aziendali/' + props.el?.id}>Gestisci link aziendali</Link>
                        </span> */}
                        <span>
                            <Icon color="black" icon="it-clip" id="arrowRight" />
                            <Link className="ref-strutture" to={'/anagrafica-strutture/' + props.el?.id}> Anagrafica struttura</Link>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
