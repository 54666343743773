import React from 'react';
import './InformazioniGenerali.css';

export default function InformazioniGenerali(props) {

    const fonte = "(fonte Mds):";

    return (
        <div id="informazioniGenerali">
            <h5 className="title">Informazioni generali</h5>
            <div>
                <label className="information">Nome struttura <i>{fonte}</i></label>  <span>{props.asl?.nome}</span>
            </div>
            <div>
                <label className="information">Pubblico <i>{fonte}</i></label> <span>{props.asl?.pubblico == 1 ? "Si" : "No"}</span>
            </div>
            <div>
                <label className="information">Tipologia <i>{fonte}</i></label> <span>{props.asl?.tipologia}</span>
            </div>
            <div>
                <label className="information">Indirizzo <i>{fonte}</i></label> <span>{props.asl?.indirizzo}</span>
            </div>
            <div>
                <label className="information">Sito web: </label> <a>{props.asl?.sito}</a>
            </div>
        </div>
    )
}