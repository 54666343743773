import React, { useState } from 'react'
import { Icon } from "design-react-kit";

export default function Pager({ paginationSize, page, nElements, setPage, addItemButton, disablePagination, nBackendPages }) {

    const defPaginationSize = paginationSize ? paginationSize : 5
    const defAddItemButton = addItemButton ? addItemButton : () => <></>
    const nPages = nBackendPages ?? parseInt(nElements / paginationSize)
    const uncompletePage = nElements % paginationSize !== 0

    const [goTo, setGoTo] = useState()
    const goToPage = () => {
        try {
            if (parseInt(goTo - 1) >= 0 && goTo - 1 <= nPages)
                setPage(parseInt(goTo - 1))
        } catch (e) { }
    }

    const nextPage = () => {
        if (page + 1 < nPages)
            setPage(page + 1)
    }

    const prevPage = () => {
        if (page > 0) setPage(page - 1)
    }


    let start = page * defPaginationSize + 1
    let end = page * defPaginationSize + defPaginationSize <= nElements ? page * defPaginationSize + defPaginationSize : nElements
    let nPage = parseInt(nElements / defPaginationSize) + uncompletePage


    return nElements ? <>
        {defAddItemButton()}
        <div className={'pagination noselect'.concat(disablePagination ? ' disabled' : '')}>
            <div className={'v-center d-flex mb-3'}>
                <label>
                    <span className={'font-weight-bold mr-2'}> Pagina {page + 1} </span> {start} - {end} di {nElements} elementi
                </label>
                <Icon color="black" icon="it-chevron-left" onClick={prevPage} />

                <div className="page-item d-flex">

                    {(page === 0 || page === 1) && nPage < 3 ? null :
                        <a onClick={() => setPage(page === nPage - 1 ? page - 2 : page < nPage - 2 ? page : page - 1)}
                            className="page-link mr-1 ml-1"
                            aria-current={page < nPage - 2 ? 'page' : null}>{page === nPage - 1 ? page - 1 : page < nPage - 2 ? page + 1 : page}</a>}
                    {page === 0 && nPage === 1 ? null :
                        <a onClick={() => setPage(page === nPage - 1 ? page - 1 : page < nPage - 2 ? page + 1 : page)}
                            className="page-link mr-1 ml-1"
                            aria-current={page === nPage - 2 ? 'page' : null}>{page === nPage - 1 ? page : page < nPage - 2 ? page + 2 : page + 1}</a>}
                    {
                        <a onClick={() => setPage(page === nPage - 1 ? page : page < nPage - 2 ? page + 2 : page + 1)}
                            className="page-link mr-1 ml-1"
                            aria-current={page === nPage - 1 ? 'page' : null}>{page === nPage - 1 ? page + 1 : page < nPage - 2 ? page + 3 : page + 2}</a>}
                </div>


                <Icon color="black" icon="it-chevron-right" onClick={nextPage} />

                <form className={'d-inline'} onSubmit={(event) => {
                    goToPage()
                    event.preventDefault()
                    setGoTo('')
                }}>
                    <input type={"number"} max={nBackendPages ?? nPages + (uncompletePage ? 1 : 0)} min={1} value={goTo} onSubmit={goToPage}
                        onChange={(e) => setGoTo(e.target.value)} placeholder={'Vai a...'} />
                    <input type="submit" className={'d-none'} />
                </form>
            </div>
        </div>
    </> : defAddItemButton()
}
