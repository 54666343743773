import React, { useCallback, useEffect, useState, useContext } from "react";
import SessioneUtente from "./SessioneUtente";
import authConfig from "../config/authConfig";
import AuthenticationManager from "./AuthenticationManager";
import UtentiRepository from '../Repo/UtentiRepository';
import { auth_debug_log_enable, MFACHECK_ENABLED } from "../config/config";
import { logincallback, logout } from "./GestioneSessioneHelper";
import { Context, setUserAuthenticated } from "../../src/hook/globalState/Store";
import AbilitazioneMfa from "../components/pages/AbilitazioneMfa/AbilitazioneMfa";
import '../index.css';
import { encryptStringWithSHA256, hashToBase64url, makeCodeVerifier } from "../components/Utils/Utils";
import { v4 as uuid } from "uuid";


const mockSessionQueryString = '?sessionId=0dasd4gf42f&userid=DVNLRD54C15M059P'

export default function Authenticator(props) {

    const [state, dispatch] = useContext(Context);
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [authenticationError, setAuthenticationError] = useState(false);
    const [mfaNotConfigured, setMfaNotConfigured] = useState(true);
    const [mfaCheckCompleted, setMfaCheckCompleted] = useState(false);

    const authManager = new AuthenticationManager();

    useEffect(() => {
        if (state.forceLogout) {
            auth_debug_log_enable && console.log(" ---- FORCE LOGOUT ----");
            SessioneUtente.destroySession(); //resetta la sessione per forzare ad effettuare nuovamente l'accesso
            sessionStorage.removeItem("regioneImpersonificata");
            window.location.href = authConfig["url-logout"];
        }
    }, [state.forceLogout])

    useEffect(() => {
        if (redirectUrl) {
            auth_debug_log_enable && console.log(" ---- REDIRECT ----");
            window.location.href = redirectUrl;
        }
    }, [redirectUrl])

    useEffect(() => {
        auth_debug_log_enable && console.log("MFACHECK_ENABLED: '" + MFACHECK_ENABLED + "'")
        if (!redirectUrl && !state.userAuthenticated) {
            if (authConfig['attivazione-auth']) { //autenticazione ABILITATA

                if (window.location.pathname === '/login-callback') {
                    auth_debug_log_enable && console.log(" ---- LOGIN CALLBACK ----");
                    logincallback(window.location.search).then((res) => {
                        if (!res) {
                            setAuthenticationError(true);
                        } else {
                            if (MFACHECK_ENABLED) {
                                UtentiRepository().checkMFAUtente("PDT", SessioneUtente.getInstance().codiceFiscale)
                                    .then((result) => {
                                        auth_debug_log_enable && console.log("Authenticator, checkMFAUtente result: ", result)
                                        if (result) { //l'utente ha completato con successo la configurazione dell'MFA comprensiva di pairing con il dispositivo personale
                                            auth_debug_log_enable && console.log("checkMFAUtente: configurato");
                                            setMfaNotConfigured(false);
                                        }
                                        else { //l'utente non ha mai effettuato l'attivazione dell'MFA oppure non ha completato il pairing del dispositivo personale
                                            auth_debug_log_enable && console.log("checkMFAUtente: NON configurato");
                                            setMfaNotConfigured(true);
                                        }
                                        setAuthenticationError(false);
                                        setUserAuthenticated(true);
                                        setRedirectUrl(null);
                                        setMfaCheckCompleted(true);
                                    })
                            }
                            else {
                                setAuthenticationError(false);
                                setUserAuthenticated(true);
                                setRedirectUrl(null);
                                setMfaCheckCompleted(true);
                            }
                            auth_debug_log_enable && console.log("[DEBUG] login completato")
                        }
                    }).catch(err => {
                        console.log("[ERROR] login fallito ", err);
                        setAuthenticationError(true);
                    })
                }
                else if (window.location.pathname === '/logout-callback') {
                    auth_debug_log_enable && console.log("logout-callback: check for successful logout")
                    logout(window.location.search).then((successfulLogout) => {
                        if (successfulLogout) {
                            auth_debug_log_enable && console.log("successfulLogout: true")
                            setUserAuthenticated(false);
                            getRedirectUrl().then(
                                url => {
                                    setRedirectUrl(url);
                                }
                            );
                        }
                        else {
                            auth_debug_log_enable && console.log("successfulLogout: false")
                            setRedirectUrl(null)
                            setAuthenticationError(true)
                        }
                    });
                }
                else {
                    auth_debug_log_enable && console.warn("[DEBUG] necessario verificare l'accesso utente prima di procedere...");
                    authManager.verificaAccessoUtente().then((isAutenticato) => {
                        if (!isAutenticato) {
                            getRedirectUrl().then(
                                url => {
                                    setRedirectUrl(url);
                                }
                            );
                        }
                        else {
                            setUserAuthenticated(true);
                            setRedirectUrl(null);
                        }
                    })
                }
            }
            else {
                auth_debug_log_enable && console.log("[DEBUG] possibile procedere senza verificare l'accesso utente (authentication disabled)")
                //utilizziamo dati per autenticazione mockata
                logincallback(mockSessionQueryString).then(() => {
                    setUserAuthenticated(true);
                    setRedirectUrl(null);
                    setMfaCheckCompleted(true);
                })
            }
        }
    }, [state.userAuthenticated])

    auth_debug_log_enable && console.log("Autenticazione Richiesta: " + authConfig['attivazione-auth'] + " - " + window.location.href);

    auth_debug_log_enable && console.log("isUserAuthenticated: " + JSON.stringify(state.userAuthenticated));
    auth_debug_log_enable && console.log("redirectURL: " + JSON.stringify(redirectUrl));

    if (mfaCheckCompleted) {
        if (MFACHECK_ENABLED && mfaNotConfigured) {

            auth_debug_log_enable && console.log("RENDER: mfaNotConfigured, userAuthenticated: ", state.userAuthenticated)

            return (
                state.userAuthenticated ? SessioneUtente.getInstance().ruoliUtente ? <AbilitazioneMfa title="AbilitazioneMFA" /> : <RenderNonAutorizzato /> : <RenderVerificaInCorso />
            )
        } else {
            return (
                authenticationError ? <RenderAuthenticationError /> : state.userAuthenticated ? SessioneUtente.getInstance().ruoliUtente ? <>{props.children}</> : <RenderNonAutorizzato /> : <RenderVerificaInCorso />
            )
        }
    }
    else {
        return authenticationError ? <RenderAuthenticationError /> : <RenderVerificaInCorso text={"Verifica autenticazione in corso..."} />;
    }

}

function RenderAuthenticationError() {

    return <div className="authenticatedError">
        <div className="inputBoxWithShadow">{"Errore di autenticazione"}</div>
    </div>
}

function RenderNonAutorizzato(props) {

    return <div className="authenticatedError">
        <div className="inputBoxWithShadow">{"Utente non autorizzato"}</div>
    </div>

}

function RenderVerificaInCorso(props) {

    return <div className="authenticatedError">
        <div className="inputBoxWithShadow">{"Verifica autenticazione in corso..."}</div>
    </div>

}

async function getRedirectUrl() {
    let result = "";
    let state = uuid();
    localStorage.setItem("state", state);
    let code_verifier = makeCodeVerifier();
    let code_challenge = ""
    localStorage.setItem("code_verifier", code_verifier);
    return await encryptStringWithSHA256(code_verifier).then(res => {
        code_challenge = hashToBase64url(res);
        localStorage.setItem("code_challenge", code_challenge)
        return authConfig['url-redirect-auth'] + "?state=" + state + "&codeChallenge=" + code_challenge
    });

}


