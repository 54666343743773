import React, { useMemo, useState } from "react";

export default function useObjectsArray(initialState, validate, blankItemStructure, label) {

    //ATTENZIONE!
    //Se non si imposta un blankItem potrebbero verificarsi comportamenti inaspettati
    const blankItem = blankItemStructure ? { ...blankItemStructure } : {}

    const [value, setValue] = useState(initialState)

    const removeItem = index => {
        value.splice(index, 1)
        setValue([...value])
    }

    const addItem = (item) => {
        if (item)
            setValue([...value, item])
        else
            setValue([...value, blankItem])
    }

    const updateItem = (index, field, updatedValue, index2, field2) => {
        if (Number.isInteger(index2)) {
            if (value[index]) {
                value[index][field][index2][field2] = updatedValue
            }
        }
        else {
            value[index][field] = updatedValue
        }
        setValue([...value])
    }

    let validationResult = validate ? validate(value) : null;

    return useMemo(() => ([value, setValue, removeItem, addItem, updateItem, validationResult]), [value, setValue, removeItem, addItem, updateItem, validationResult])
}
