import React, { useState, useEffect } from "react";
import './StrutturaCovidBox.css'
import { Link } from "react-router-dom";

export default function AssociazioneBox(props) {

    return (
        <div className={'col-12 mt-4'} id={'strutturaCovidBoxContainer'}>
            <div className={'row mt-2 border-bottom'}>
                <p className={'strutturaCovidBoxHeader'}>NOMINATIVO</p><label className={'strutturaCovidBoxValue'}>{props.el?.descrizione}</label>
            </div>
            <div className={'row'}>
                <div className={'col-8 d-flex'}>
                    <p className={'strutturaCovidBoxHeader'}>INDIRIZZO WEB</p><label className={'strutturaCovidBoxValue'}>{props.el?.sito}</label>
                </div>
                <div className={'col-4 d-flex'}>
                    <Link to={"/edit/sito-covid/" + props.el?.id}><button className={'btn btn-secondary modifica-dati'}>Modifica dati</button></Link>
                </div>
            </div>
        </div>
    )
}
