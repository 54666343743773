import React from 'react';
import LinkCollapse from '../LinkCollapse/LinkCollapse';
import './MenuUtenteRegionale.css';
import { Link } from 'react-router-dom';
import LogoSection from '../../img/logos/section.svg';
import LogoApplication from '../../img/logos/application.svg';
import LogoSearch from '../../img/logos/search.svg';
import LogoTool from '../../img/logos/tool.svg';
import LogoTrash from '../../img/logos/trash.svg';
import { isRegione } from '../../Utils/Utils';
import { roleEnum } from '../../Utils/Dataset';

export default function MenuUtenteRegionale(props) {

    const linksTree = BuildLinksTree();

    return (
        props.collapsed ? <></> :
            <div id="menuUtenteRegionale">
                {props.regione ? <div id="logoRegione">
                    <img alt={"Logo " + props.regione} className="img-fluid" src={props.logo} />
                    {isRegione(props.idRegione) ? <label className={"labelRegione"}>Benvenuta, regione {props.regione}</label> : <label className={"labelRegione"}>Benvenuta, provincia autonoma di {props.regione}</label>}
                </div> : null}
                <div className="link-list-wrapper">
                    <ul className="link-list">
                        <li>
                            <LinkCollapse logo={LogoSection} collapseId={"collapseTwo"} title={"Contenuti"} links={linksTree.contenuti} nested={false} />
                        </li>
                        <li>
                            <LinkCollapse logo={LogoApplication} collapseId={"collapses"} title={"Applicazioni custom"} links={linksTree.applicazioniCustom} nested={false} />
                        </li>
                        <li>
                            <Link to="/motorediricerca"><span><img alt={""} className="menu-state-icon" src={LogoSearch} />Motore di ricerca</span></Link>
                        </li>
                        <li>
                            {props.ruolo === roleEnum.AUTORE && <LinkCollapse logo={LogoTool} collapseId={"collapseTwo"} title={"Impostazioni"} links={linksTree.impostazioni} nested={false} />}
                        </li>
                        <li>
                            {props.ruolo === roleEnum.AUTORE && <Link to="/cestino"><span><img alt={""} className="menu-state-icon" src={LogoTrash} />Cestino</span></Link>}
                        </li>
                    </ul>
                </div>
            </div>
    )
}

function BuildLinksTree() {
    return {
        contenuti: [
            {
                title: "Aziende sanitarie e strutture",
                href: "/strutture-regionali"
            },
            {
                title: "Associazioni disturbi alimentari",
                href: "/associazioni"
            },
            {
                title: "Tempi d'attesa - PRGLA",
                href: "/tempi-attesa"
            },
            {
                title: "Lea Regionali",
                href: "/lea-regionali"
            },
            {
                title: "Gestisci news",
                href: "/gestisci-news"
            },
            {
                title: "Siti covid-19",
                href: "/siti-covid"
            }
        ],
        applicazioniCustom: [
            {
                title: "Prestazioni garantite dal SSN",
                href: "/applicazioni-custom/prestazioni-garantite-ssn/IT"
            }
        ],
        impostazioni: [
            {
                title: "Redattori",
                href: "/redattori"
            },
            {
                title: "Contatti regionali",
                href: "/contatti-regionali"
            }
        ]
    }
}