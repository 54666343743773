import AuthenticationManager from "../sessionManager/AuthenticationManager";
import SessioneUtente from "../sessionManager/SessioneUtente";
import { REPHEADERS, utentiService2Url, utentiServiceUrl } from "../config/config";


export default function UtentiRepository() {

    const authManager = new AuthenticationManager();
    const session = SessioneUtente.getInstance();

    /* centralizza le logiche di inzializzazione degli Headers */
    async function getHeaders(requestUrl) {
        let defaultHeaders = { ...REPHEADERS };
        defaultHeaders["AZUser"] = defaultHeaders["AZUser"] + ";areaterritoriale=" + session.idAreaTerritoriale
        return await authManager.initRequestHeaders(requestUrl, defaultHeaders);
    }

    /* 
        Request body:
        codiceFiscale, gruppo, applicazione, struttura, nome, cognome, mail, utentelocale,
        listaGruppi: attributo composto da una lista di oggetti contenenti codicegruppo e una listaAttributi, che fa riferimento ad una lista di coppie codiceAttributo - valore
    */
    async function createUser(body) {
        const headers = await getHeaders(utentiServiceUrl);
        headers["Content-Type"] = "application/json";

        return fetch(utentiServiceUrl, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(body)
        })
    }

    /* 
        Request body:
        codiceFiscale, gruppo, applicazione, struttura, nome, cognome, mail, utentelocale,
        listaGruppi: attributo composto da una lista di oggetti contenenti codicegruppo e una listaAttributi, che fa riferimento ad una lista di coppie codiceAttributo - valore
    */
    async function editUser(body) {
        const headers = await getHeaders(utentiServiceUrl);
        headers["Content-Type"] = "application/json";

        return fetch(utentiServiceUrl, {
            headers: headers,
            method: "PUT",
            body: JSON.stringify(body)
        })
    }

    /* 
        Request body:
        codiceFiscale, applicazione, struttura,
        listaGruppi: attributo composto da una lista di oggetti contenenti codicegruppo e una listaAttributi, che fa riferimento ad una lista di coppie codiceAttributo - valore
    */
    async function deleteUser(body) {
        const headers = await getHeaders(utentiServiceUrl);
        headers["Content-Type"] = "application/json";

        return fetch(utentiServiceUrl, {
            headers: headers,
            method: 'DELETE',
            body: JSON.stringify(body)
        })
    }

    /* 
        Request body:
        applicazione, codiceFiscale, struttura, gruppo
    */
    async function getUser(body) {
        let requestUrl = utentiServiceUrl + "/ricerca"
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";

        return fetch(requestUrl, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(body)
        })
    }

    /* 
        QueryParams:
        applicazione, codiceFiscale
    */
    async function checkMFAUtente(applicazione, codiceFiscale) {

        const requestUrl = utentiServiceUrl + "/checkMFAUtente" + "?applicazione=" + applicazione + "&codiceFiscale=" + codiceFiscale;

        const headers = await getHeaders(utentiServiceUrl);
        headers["Content-Type"] = "application/json";

        return fetch(requestUrl, {
            headers: headers,
            method: 'GET'
        }).then(result => result)
            .catch(err => err)
    }

    /* 
        QueryParams:
        accessToken, applicazione, codiceFiscale
    */
    async function setMFAUtente(accessToken, applicazione, codiceFiscale) {

        const requestUrl = utentiServiceUrl + "/setMFAUtente" + "?accessToken=" + accessToken + "&applicazione=" + applicazione + "&codiceFiscale=" + codiceFiscale;
        //const requestUrl = utentiServiceUrl + "/setMFAUtente?accessToken=eyJraWQiOiJ4ZHg0S3FrdE1hUzJxVFhXSVNGQ0Q2MXBiamZlblJTaVwvM2RBQ2Z4OWtLaz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI0NjlhZGZjYy02OTY2LTQwNWUtODEzYS05NjcyMjA5MWFiNTEiLCJldmVudF9pZCI6ImJiYzI2MzViLTk1MzMtNGFjOC04N2Q2LWM3Yzg0MmFmZWQ5MSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4gb3BlbmlkIiwiYXV0aF90aW1lIjoxNjA1NjAwNjc3LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtY2VudHJhbC0xLmFtYXpvbmF3cy5jb21cL2V1LWNlbnRyYWwtMV9ieTBaeDA5MkgiLCJleHAiOjE2MDU2MDQyNzgsImlhdCI6MTYwNTYwMDY3OSwidmVyc2lvbiI6MiwianRpIjoiOTk0YzgzZDQtM2Q2MC00YmJmLWJjOWEtZGQzMjY5YTQ3YzNjIiwiY2xpZW50X2lkIjoiNjhpOXBuMXBuOTA4cmR0ZjQwZDI4anE1a3UiLCJ1c2VybmFtZSI6InRzY3RyYTgwYTAxZDYxMmUifQ.QjPcapqST7a4mB-1jDezu1PAqj1ueDw38xvRPNAx0h8CncilUK3CB1P_QT-mBMYzXg50pkJ_IOD6yk7LDImzTq3XmMxyzFCKWWiVlGpUWdn5GXTCKYJZZ4Hs4jf3GKVUxeSzMPfVd3RRv-Grn05cA2LGEt0Ob-C7C_p6WCGhAXaWvUvXoZKwobzdB6JE-X6Xmp1h8A2ARWSUKcuupcGjCK73re_hbuPp86PhX6BMF4L-YmVeQztQf7Gq6NJU99zPBjX2H4eI7vFC42c6WcGZYbRdJcSA2gR7S71F8lRsVuEFbbFPAp9BaitkUzpOC1_YxYIKSB7WUisUmVOV-xr5Sw&applicazione=PDT&codiceFiscale=tsctra80a01d612e";
        const headers = await getHeaders(utentiServiceUrl);
        headers["Content-Type"] = "application/json";

        return fetch(requestUrl, {
            headers: headers,
            method: 'POST'
        }).then(result => result.text())
            .catch(err => err)
    }

    /* 
        QueryParams:
        accessToken, userCode
    */
    async function verificaDispositivoMFA(accessToken, userCode, applicazione, codiceFiscale) {

        const requestUrl = utentiServiceUrl + "/verificaDispositivoMFA" + "?accessToken=" + accessToken + "&applicazione=" + applicazione + "&codiceFiscale=" + codiceFiscale + "&userCode=" + userCode;

        const headers = await getHeaders(utentiServiceUrl);
        headers["Content-Type"] = "application/json";

        return fetch(requestUrl, {
            headers: headers,
            method: 'POST'
        }).then(result => result)
            .catch(err => err)
    }

    /* 
        QueryParams:
        applicazione, codiceFiscale
    */
    async function rimozioneMFAUtente(codiceFiscale, applicazione) {

        const requestUrl = utentiServiceUrl + "/rimozioneMFAUtente" + "?applicazione=" + applicazione + "&codiceFiscale=" + codiceFiscale;

        const headers = await getHeaders(utentiServiceUrl);
        headers["Content-Type"] = "application/json";

        return fetch(requestUrl, {
            headers: headers,
            method: 'POST'
        }).then(result => result)
            .catch(err => err)
    }

    /* 
        Request body:
        applicazione,
        listaAttributi: attributo composto da una lista di coppie codiceAttributo - valore
    */
    async function getUsers(body) {
        let requestUrl = utentiServiceUrl + "/lista"
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";

        return fetch(requestUrl, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(body)
        }).catch(err => err)
    }

    /*
        Request body:
        accessToken, applicazione, codiceFiscale, previousPassword, proposedPassword
    */
    async function cambioPassword(body) {
        let requestUrl = utentiServiceUrl + "/cambiopasswordcognito"
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";

        return fetch(requestUrl, {
            headers: headers,
            method: 'PUT',
            body: JSON.stringify(body)
        })
    }


    async function getUtenti(queryParams) {
        let requestUrl = utentiService2Url + "/utenti?";
        requestUrl = requestUrl + new URLSearchParams(queryParams);
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";

        return fetch(requestUrl, {
            headers: headers,
            method: 'GET',
        }).catch(err => err)
    }

    return Object.freeze({
        createUser, editUser, deleteUser, getUser, getUsers, getUtenti, checkMFAUtente, setMFAUtente, verificaDispositivoMFA, rimozioneMFAUtente, cambioPassword
    });
}