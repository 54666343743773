import React from 'react';
import { Icon } from 'design-react-kit';
import useModal from '../../../hook/useModal';
import CustomModal from '../../CustomModal/CustomModal';
import './InfoModal.css';

export default function InfoModal(props) {

    // Gestione finestre modali
    const [show, , handleClose, handleShow] = useModal(false);

    const tooltipInfo = "Informazione";

    return (
        <span className={"infoModal"}>
            <CustomModal
                show={show}
                onHide={handleClose}
                onConfirm={() => handleClose()}
                title={"Informazione"}
                text={props.message}
                removeButtons={true}
            />
            <a data-toggle="tooltip" data-selector="true" title={tooltipInfo}>
                <Icon
                    className="it-info"
                    icon={"it-info-circle"}
                    onClick={() => handleShow()}
                //  size={"sm"}
                />
            </a>
        </span>
    )
}
