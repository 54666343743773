import React from 'react';
import './Flag.css';

function Flag() {
    return (
        <div className="Flag">
            <div className="container-fluid">
                <div className="row" style={{ height: "7px" }}>
                    <div
                        className="col-4 p-0"
                        style={{ backgroundColor: "rgb(0,146,70)" }}
                    ></div>
                    <div
                        className="col-4 p-0"
                        style={{ backgroundColor: "white" }}
                    ></div>
                    <div
                        className="col-4 p-0"
                        style={{ backgroundColor: "rgb(206,43,55)" }}
                    ></div>
                </div>
            </div>
        </div>
    )
}

export default Flag;