import React, { useState, useEffect, useContext } from 'react';
import Template_01 from './Template_01/Template_01';

import Template_02 from './Template_02/Template_02';
import Template_03a from './Template_03a/Template_03a';
import Template_03b from './Template_03b/Template_03b';
import Template_03c from './Template_03c/Template_03c';
import Template_Link from './Template_Link/Template_Link';
import { useApplicazioniCustom, useApplicazioniCustomApprofondimenti, useApplicazioniCustomCovid, useCatalogoLink, useCatalogoLinkCovid, useContenutiOrdinati } from '../UI/Menu/MenuHooks';
import useToggle from '../../hook/useToggle';
import { WAFE_PREVIEW_URL } from '../../config/config';

import { Icon } from 'design-react-kit';
import TitleTemplate from '../UI/TitleTemplate/TitleTemplate';
import useInput from '../../hook/useInput';
import useFileHandler from '../../hook/useFileHandler';
import useCustomApp from '../../hook/useCustomApp';
import Card from '../UI/Card/Card';
import Publisher from '../UI/Publisher/Publisher';
import Scheduled from '../UI/Scheduled/Scheduled';
import LanguagePicker from '../UI/LanguagePicker/LanguagePicker';
import ContenutiRepository from '../../Repo/ContenutiRepository';
import moment from 'moment';
import validate, {
    validateArray, validateCustomApp, validateCustomCTA,
    validateLinksGallery, validateQuill, validateQuillNoTitle, validateTrue,
    validateUrl, validateSitiCertificatiValues, validatePulsantiBluValues,
    validateContenutiCorrelatiValues
} from '../UI/FieldValidation/FieldValidation';
import { forcedScrollToTop, isUtenteRegionale, makeCodeVerifier, setInvalidFieldMsg } from '../Utils/Utils'
import useModal from '../../hook/useModal';
import { Redirect } from 'react-router-dom'
import Template_04 from "./Template_04/Template_04";
import Template_05a from "./Template_05/Template_05a";
import Template_05b from "./Template_05/Template_05b";
import {
    templatesEnum,
    statoContenuto,
    blankLink,
    blankQuill,
    blankSocial,
    blankTag,
    blankGallery,
    contentNuovaLingua,
    OK_RELEASE,
    KO_RELEASE,
    KO_OPERATION,
    assetsEnum,
    operazioniContenuto,
    cataloghiEnum, tipologiaEnum, maxElementi, linguaEnum, areeTerritorialiEnum, roleEnum, provenienzaEnum, templateNonCreabileEnum
} from "../Utils/Dataset";
import useRadio from "../../hook/useRadio";
import useObjectsArray from "../../hook/useObjectsArray";
import { isDateFuture, isContentReadOnly } from "../Utils/Utils";
import CustomModal from "../CustomModal/CustomModal";
import { Messages } from "../Utils/Messages";
import { Context, hideLoading, scrollToTop, showError, showLoading, showSuccess, showWarning } from "../../hook/globalState/Store";
import { alertLevels } from "../UI/InPageAlert/InPageAlert";
import { hasErrorOutcome } from "../Interceptor/Interceptor";
import FileRepository from "../../Repo/FileRepository";
import { getMessaggioOperazione } from "../../Repo/Utils";
import Invalid_Template from './Invalid_Template/Invalid_Template';
import Template_Applicazione_Custom from './TemplateApplicazioneCustom/Template_Applicazione_Custom';
import Template_Applicazione_Custom_Covid from './TemplateApplicazioneCustomCovid/Template_Applicazione_Custom_Covid';
import SessioneUtente from '../../sessionManager/SessioneUtente';
import PreviewModal from '../PreviewModal/PreviewModal';
import ReadOnlyTemplate_05a from './Template_05/ReadOnlyTemplate_05a';
import Template_03d from './Template_03d/Template_03d';
import Template_FEEDBACK from './Template_FEEDBACK/Template_FEEDBACK';
import Template_Argomento_Generico from './Template_Argomento_Generico/Template_Argomento_Generico';
import { isTipologiaNewsOrFakeNews } from './TemplateUtils';

export default function EditTemplate(props) {
    const [state, dispatch] = useContext(Context);

    // SessioneUtente
    const session = SessioneUtente.getInstance()
    let role = session.ruoliUtente;

    //CTA editabile
    const [isCTAEditable, setIsCTAEditable] = useState(true);

    //CONTENUTI CORRELATI 
    const [isContenutiCorrelatiEditable, setIsContenutiCorrelatiEditable] = useState(true);
    const [isContenutoPadreEditable, setIsContenutoPadreEditable] = useState(true);

    const [invalidMsg, setInvalidMsg] = useState("")

    const [currentContentId, setCurrentContentId] = useState(null)
    let isValidTipologia = true;

    //const contentId = props.location.customProps.data.id;
    const matchParamsContentId = props.match.params.id_contenuto;//router.pathname?.replace('/edit/contenuto/', ''); //props.location.customProps?.id;
    // const relativePathName = props.location.customProps?.title ? slugify(props.location.customProps.title) : "";
    // const titleName = props.location.customProps?.title;
    const [idAgnosticoLingua, setIdAgnosticoLingua] = useState(null);

    //Per Template_03
    const [customApps, setCustomApps, addCustomApp, removeCustomApp, changeDescrizioneCustomApp, restoreDescrizione, disableDescrizione, changeCta, changeLinkToggle, changeLinkDaCollegare, validateApplicazioneCustom] = useCustomApp([], validateCustomApp);
    const [customCTAs, setCustomCTAs, addCustomCTA, removeCustomCTA, changeDescrizioneCustomCTA, restoreDescrizioneCTA, disableDescrizioneCTA, changeCtaCustomCTA, changeLinkToggleCTA, changeLinkCTA, validateCTA] = useCustomApp([], validateCustomCTA)
    const [option, setOption, optionChangeHandler] = useInput("");

    //Per Template_02
    const [approfondimenti, approfondimentiLoadCompleted] = useContenutiOrdinati({ tipologia: tipologiaEnum.APPROFONDIMENTO }, { ascending: "false", sort: "dataInserimento" });
    //const [approfondimenti, approfondimentiLoadCompleted] = useApprofondimenti();
    const [nomeContenutoPadre, setNomeContenutoIdPadre] = useState('')
    const [contenutiPerIdPadre, setContenutiPerIdPadre] = useState(null)
    const [contenutiFigli, setContenutiFigli] = useState(null)


    const [linkDaCollegare, linkDaCollegareLoadCompleted] = useCatalogoLink();
    const [linkDaCollegareCovid, linkDaCollegareCovidLoadCompleted] = useCatalogoLinkCovid();
    const [linkAppCustom, linkAppCustomLoadCompleted] = useApplicazioniCustom();
    const [linkAppCustomApprofondimenti, linkAppCustomApprofondimentiLoadCompleted] = useApplicazioniCustomApprofondimenti();
    const [linkAppCustomCovid, linkAppCustomCovidLoadCompleted] = useApplicazioniCustomCovid();
    const [inputDate, setInputDate, inputDateChangedHandler, validateInputDate] = useInput("", validate);
    const [tempoLettura, setTempoLettura, tempoLetturaChangedHandler, validateTempoLettura] = useInput("", validate);
    const [description, setDescription, descriptionChangedHandler, validateDescription] = useInput("", () => true);

    function customDescriptionChangedHandler(e) {
        let currentDescritpionValue = description;
        if (socials) {
            for (var i = 0; i < socials.length; ++i) {
                if (!socials[i].descrizione || socials[i].descrizione === currentDescritpionValue) {
                    updateSocial(i, "descrizione", e.target.value)
                }
            }
        }
        descriptionChangedHandler(e);
    }

    const [sitiCertificati, setSitiCertificati, removeSitiCertificatiHandler, addSitiCertificatiHandler, updateSitiCertificati, validateSitiCertificati] = useObjectsArray([], validateSitiCertificatiValues, blankLink);

    // const [tag, setTag, tagChangedHandler, validateTag] = useInput("", validate);
    const [newTag, , tagChangedHandler] = useInput("");
    const [tags, setTags, removeTag, addTag] = useObjectsArray([], null, blankTag);
    const [manageTagInput, setManageTagInput] = useState({ showTagInput: false, name: "" })
    const [toggleDate, setToggleDate] = useToggle(false);
    const [toggleReading, setToggleReading] = useToggle(false);
    const [toggleTag, setToggleTag] = useToggle(false);
    const [approfondimentoPadre, setApprofondimentoPadre, approfondimentoPadreChangedHandler, validateApprofondimentoPadre] = useInput(null, validate);
    const [idLink, setIdLink, idLinkChangedHandler, validateIdLink] = useInput(null, validate);
    const [titoloLink, setTitoloLink, titoloLinkChangedHandler, validateTitoloLink] = useInput(null, validate);
    const [idLinkAlternativo, setIdLinkAlternativo, idLinkAlternativoChangedHandler, validateIdLinkAlternativo] = useInput(null, validate);
    const [titoloLinkAlternativo, setTitoloLinkAlternativo, titoloLinkAlternativoChangedHandler, validateTitoloLinkAlternativo] = useInput(null, validate);
    const [linkInMutuaEsclusione, setLinkInMutuaEsclusione] = useToggle(false);

    // FILE HANDLERS
    ////////////////////////////////////////////////////////////////////////////////////////////////////

    //Icona
    const [fileIcona, setFileIcona, onChangeFileIcona, validateIcona] = useInput("", validate);

    //Copertina
    const [fileCopertina, setFileCopertina, onChangeFileCopertina, validateCopertina] = useInput("", validate);
    const [alternativeTextImgCopertina, setAlternativeTextImgCopertina, alternativeTextImgCopertinaChangedHandler,] = useInput("", validate);

    //Allegati
    const [fileImgBox, setFileImgBox, onChangeFileImgBox, validateImgBox] = useInput("", validate);
    const [alternativeTextImgBox, setAlternativeTextImgBox, alternativeTextImgBoxChangedHandler,] = useInput("", validate);

    const [currentAttachment, setCurrentAttachment, attachmentMetadata, setAttachmentMetadata, onChangeCurrentAttachment] = useFileHandler()
    const [attachments, setAttachments, removeAttachment, addAttachment, updateAttachment, validateAttachmentTable] = useObjectsArray([], value => { return value.filter(el => el.descrizione === null || el.descrizione?.length === 0 || el.path === null || el.path?.length === 0).length === 0 }, { "path": null, "descrizione": null, "fileCopied": false })  //ritorna true se ci sono tutte le descrizioni

    //Assets
    const [loadedAssets, setLoadedAssets] = useState([])
    const [bucketIcons, setBucketIcons] = useState([])

    const reloadImages = () => {
        FileRepository().getFile(assetsEnum.IMMAGINI)
            .then(data => setLoadedAssets(data.objects ? data.objects : []))
    }

    const reloadIcons = () => {
        FileRepository().getFile(assetsEnum.ICONE)
            .then(data => setBucketIcons(data.objects ? data.objects : []))
    }

    useEffect(() => {
        if (!loadedAssets.length)
            reloadImages()
        else setTimeout(reloadImages, 5000) //se ho appena caricato/sto caricando un asset aspetto 5 secondi prima della get

        if (!bucketIcons.length)
            reloadIcons()
        else setTimeout(reloadIcons, 5000) //se ho appena caricato/sto caricando un asset aspetto 5 secondi prima della get
    }, [currentAttachment])
    ////////////////////////////////////////////////////////////////////////////////////////////////////


    //Socials
    const [socials, setSocials, , , updateSocial,] = useObjectsArray([], null, null);
    const [alternativeTextIcon, setAlternativeTextIcon, alternativeTextIconChangedHandler,] = useInput("", validate);

    const [content, setContent] = useState(null);
    const [contentFetched, setContentFetched] = useState(false);

    const [show, , handleClose, handleShow] = useModal(false);
    const [name, setName, nameChangedHandler,] = useInput(props.location.customProps?.title);
    const [originalName, setOriginalName] = useInput(name);

    const [nameNotEditable, setNameNotEditable] = useToggle(true);
    const [template, setTemplate] = useState("");

    const [path, setPath, pathChangedHandler, validatePath] = useInput("", validate);
    const [basePath, setBasePath] = useState()
    const [subPath, setSubPath] = useState("")

    useEffect(() => {
        if (path && !path.startsWith(basePath))
            setPath(basePath + '/' + subPath)

        let pathSplit = path ? path.split("/") : []
        setSubPath(pathSplit.length ? pathSplit[pathSplit.length - 1] : "")
    }, [path])

    const [title, setTitle, titleChangedHandler, validateTitle] = useInput("", validate);
    const [subTitle, setSubTitle, subTitleChangedHandler, validateSubTitle] = useInput(null, () => true);
    const [date, setDate, dateChangedHandler, validateDate] = useInput("", validate);
    const [time, setTime, timeChangedHandler, validateTime] = useInput("", validate);

    const [pulsantiBlu, setPulsantiBlu, removeLinkHandler, addLinkHandler, updatePulsantiBlu, validatePulsantiBlu] = useObjectsArray([], validatePulsantiBluValues, blankLink);

    const [contenutiCorrelati, setContenutiCorrelati, removeContenutiCorrelatiHandler, addContenutiCorrelatiHandler, updateContenutiCorrelati, validateContenutiCorrelati] = useObjectsArray([], validateContenutiCorrelatiValues, blankLink);


    const [validazione, setValidazione] = useState(false);
    const [stato, setStato] = useState("");
    const [dataModifica, setDataModifica] = useInput("");
    const [utenteInserimento, setUtenteInserimento] = useInput("");
    const [utenteModifica, setUtenteModifica] = useInput("");
    const [redirect, setRedirect] = useState(false);
    const [action, setAction] = useState("");
    const [dataPubblicazione, setDataPubblicazione] = useState("");
    const [dataSospensione, setDataSospensione] = useState("");
    const [tipologia, setTipologia] = useState("");
    const [togglePubblicazioneProgrammata, setTogglePubblicazioneProgrammata] = useState(false);
    const [index, setIndex] = useToggle(false);

    //Quills
    const [testo, setTesto, removeTestoHandler, addTestoHandler, updateTestoHandler, validateTesto] = useObjectsArray(null, index ? validateQuill : validateQuillNoTitle, blankQuill, "testoSuperiore");
    //Quill aggiuntivo News
    const [testoInferiore, setTestoInferiore, removeTestoInferioreHandler, addTestoInferioreHandler, updateTestoInferioreHandler, validateTestoInferiore] = useObjectsArray(null, validateTrue, blankQuill, "testoInferiore");
    //Quill riferementi esterni
    const [riferimentiEsterni, setRiferimentiEsterni, removeRiferimentiEsterniHandler, addRiferimentiEsterniHandler, updateRiferimentiEsterniHandler, validateRiferimentiEsterni] = useObjectsArray(null, validateTrue, blankQuill, "riferimentiEsterni");

    //Template 5
    // const [optionRadio, setOptionRadio] = useState(null);
    const [provenienza, setProvenienza, provenienzaChangeHandler] = useRadio("Interna");
    const [link, setLink, linkChangedHandler, validateLink] = useInput("", validateUrl);
    const [newRadioButton, , changeNewRadioButtonHandler,] = useInput("");

    const [toggleScadenzaProgrammata, setToggleScadenzaProgrammata] = useState(false);
    const [dateScadenzaProgrammata, setDateScadenzaProgrammata, dateScadenzaProgrammataChangedHandler, validateDateScadenza] = useInput("", validate);
    const [timeScadenzaProgrammata, setTimeScadenzaProgrammata, timeScadenzaProgrammataChangedHandler, validateTimeScadenza] = useInput("", validate);

    const validateDatePubblicazioneProgrammata = togglePubblicazioneProgrammata ? isDateFuture(date, time) : true
    const validateDateScadenzaProgrammata = toggleScadenzaProgrammata ? isDateFuture(dateScadenzaProgrammata, timeScadenzaProgrammata) : true


    const [fonte, setFonte, fonteChangeHandler] = useRadio("");
    const [fonti, setFonti] = useState();
    const [galleryPics, setGalleryPic, removeGalleryPic, addGalleryPic, updateGalleryPic, validateGallery] = useObjectsArray([], validateLinksGallery, blankGallery)

    const [moreDescription, setMoreDescription, moreDescriptionChangeHandler] = useInput("")
    const [moreAddress, setMoreAddress, moreAddressChangeHandler] = useInput("")
    const [persone, setPersone, removePersona, addPersona] = useObjectsArray([], () => { }, { "nome": "", "cognome": "" })
    const [manageUserInput, setManageUserInput] = useState({ showUserBadgeInput: false, name: "" })

    const [disableRipristina, setDisableRipristina] = useState(true);

    //Template 6
    const [newCheckboxButton, , changeNewCheckboxButtonHandler,] = useInput("");
    const [categorie, setCategorie, removeCategoria, addCategoria, updateCategoria] = useObjectsArray(null);
    const [toggleViewCategorie, setToggleViewCategorie] = useState(false)

    //Contenuto altre lingue
    const [contenutoAltraLingua, setContenutoAltraLingua] = useObjectsArray([], null, contentNuovaLingua)
    const [lingua, setLingua] = useState(null);

    function hasError() {
        //campi comuni a tutti i template
        let valid = null;
        if (provenienza === provenienzaEnum.ESTERNA) {
            valid = validateTitle && validateLink && !validateTesto.includes(false) && fonte && (togglePubblicazioneProgrammata ? date && time && validateDatePubblicazioneProgrammata : true) && (toggleScadenzaProgrammata ? dateScadenzaProgrammata && timeScadenzaProgrammata && validateDateScadenzaProgrammata : true)
        }
        else {
            valid = validateTitle && (validatePath && path.slice(-1) !== "/") && !validateTesto.includes(false) && (toggleDate ? inputDate : true) && (toggleReading ? tempoLettura : true) && (togglePubblicazioneProgrammata ? date && time && validateDatePubblicazioneProgrammata : true)

            if (content) {
                if (content.template === templateNonCreabileEnum.TEMPLATE_LINK)
                    valid = validateLink;
                else {
                    switch (content.tipologia) {
                        case tipologiaEnum.APPROFONDIMENTO:
                            valid = valid && validateCopertina && validateImgBox && !validatePulsantiBlu.includes(false) && !validateContenutiCorrelati.includes(false) && !validateApplicazioneCustom.includes(false) && !validateSitiCertificati.includes(false) && validateAttachmentTable //campi specifici del template
                            break;
                        case tipologiaEnum.DETTAGLIO_APPROFONDIMENTO:
                            valid = valid && validateCopertina && !validateContenutiCorrelati.includes(false) && !validateSitiCertificati.includes(false) && validateApprofondimentoPadre && validateAttachmentTable
                            break;
                        case tipologiaEnum.COME_FARE_PER:
                            valid = valid && validateIcona && validateDescription && !validateApplicazioneCustom.includes(false) && validateAttachmentTable
                            break;
                        case tipologiaEnum.PRESTAZIONE_GARANTITA_SSN:
                            valid = valid && !validateApplicazioneCustom.includes(false) && validateAttachmentTable
                            break;
                        case tipologiaEnum.NEWS:
                        case tipologiaEnum.FAKE_NEWS:
                            valid = valid && validateAttachmentTable && (toggleScadenzaProgrammata ? dateScadenzaProgrammata && timeScadenzaProgrammata && validateDateScadenzaProgrammata : true) && validateCopertina && !validateGallery.includes(false)
                            break;
                        case tipologiaEnum.VIVERE_IN_SALUTE:
                            valid = valid && !validateGallery.includes(false) && validateAttachmentTable;
                            break;
                        case tipologiaEnum.APPLICAZIONE_STRUTTURE:
                            valid = valid && validateIdLink && ((idLink === "DISTURBI_ALIMENTARI" || idLink === "PRGLA") ? true : validateTitoloLink) && (linkInMutuaEsclusione ? validateTitoloLinkAlternativo && validateIdLinkAlternativo : true);
                            break;
                        case tipologiaEnum.COVID:
                            valid = valid && validateIcona && validateDescription && !validateApplicazioneCustom.includes(false) && validateAttachmentTable
                            break;
                        case tipologiaEnum.APPLICAZIONE_STRUTTURE_COVID:
                            valid = valid;
                            break;
                        case tipologiaEnum.FAQ:
                            valid = valid && !validateApplicazioneCustom.includes(false) && validateAttachmentTable;
                            break;
                        case tipologiaEnum.FEEDBACK:
                            valid = validateTitle && !validateTesto.includes(false) && validateAttachmentTable;
                            break
                        case tipologiaEnum.ARGOMENTO_GENERICO_BOTTOM:
                        case tipologiaEnum.ARGOMENTO_GENERICO_CONTATTI:
                        case tipologiaEnum.ARGOMENTO_GENERICO_PORTALE:
                            valid = validateTitle && !validateTesto.includes(false) && validateAttachmentTable
                            break;
                        default:
                            valid = false;
                            break;
                    }
                }
            }
        }
        return !valid
    }

    function addKeys(content) {
        let genericArray = [];
        if (content) {
            genericArray = content.map((el, i) => {
                return {
                    ...el,
                    id: "link-" + i
                }
            })
        }
        return genericArray;
    }


    //Funzione che avvisa l'utente dell'impossibilità di inserire uno o più caratteri in un form numerico
    function rejectNaN(insert, fieldName) {
        if (isNaN(insert)) {
            dispatch({
                type: 'SHOW_ALERT',
                payload: { alertLevel: alertLevels.ERROR, alertMsg: "Nel campo '" + fieldName + "' non sono ammessi caratteri. Inserire un numero." }
            });
            return true
        }
    }

    if (currentContentId !== matchParamsContentId) { //necessario per gestire il reload del menu nel caso di navigazione tra due editmenu (e.g., da /edit/menu/main a /edit/menu/footer)
        setCurrentContentId(matchParamsContentId);
        setContentFetched(false);
    }

    //Gestione reload EditTemplate & Path
    useEffect(() => {
        if (state.reloadEditContent || state.reloadPath || state.fieldsError) {
            if (state.reloadEditContent) {
                setContentFetched(false)
                dispatch({ type: 'RELOAD_EDIT_CONTENT', payload: { reloadEditContent: false } });
            }
            if (state.reloadPath) {
                ContenutiRepository().getContenutoById(approfondimentoPadre)
                    .then(data => {
                        setBasePath(data.path)
                        setPath(data.path + "/" + subPath)
                    })
                dispatch({ type: 'RELOAD_PATH', payload: { reloadPath: false } });
            }
            if (state.fieldsError?.length > 0) {
                setInvalidFieldMsg(setInvalidMsg, state.fieldsError)
                dispatch({ type: 'FIELDS_ERROR', payload: { fieldsError: null } });
            }
        }
    }, [state.reloadEditContent, state.reloadPath, state.fieldsError])

    useEffect(() => {
        if (contentFetched || (contentFetched && contenutiPerIdPadre !== null && linkDaCollegareLoadCompleted && linkDaCollegareCovidLoadCompleted && linkAppCustomLoadCompleted && linkAppCustomApprofondimentiLoadCompleted && linkAppCustomCovidLoadCompleted)) {
            // const tipologiaPath = getPath(content.tipologia?.toLowerCase());
            setProvenienza(!content.interno ? (content.interno === false ? provenienzaEnum.ESTERNA : provenienzaEnum.INTERNA) : provenienzaEnum.INTERNA)

            //per Template 3
            setFileIcona(content.iconaHomepage ? content.iconaHomepage?.path : null);
            setAlternativeTextIcon(content.iconaHomepage ? content.iconaHomepage?.path : null)

            setTipologia(content.tipologia)
            setIdAgnosticoLingua(content.idAgnosticoLingua);
            setOption("");
            setLingua(content.lingua);

            //Toggles
            if (content.tempoLettura && !toggleReading) {
                setToggleReading()
                setTempoLettura(content.tempoLettura);
            }
            else if (!content.tempoLettura && toggleReading) {
                setToggleReading()
            }

            if (content.data && !toggleDate) {
                setToggleDate()
                setInputDate(content.data)
            }
            else if (!content.data && toggleDate) {
                setToggleDate()
            }

            setContenutoAltraLingua(content.contenutiAltraLingua ? content.contenutiAltraLingua : []);

            setDescription(content.descrizione ? content.descrizione : "");

            setSitiCertificati([]);
            setTags(content.tag ? content.tag : []);
            setSitiCertificati(content.sitiCertificati ? addKeys(content.sitiCertificati) : []);
            if (content.tag && !toggleTag) setToggleTag()
            setApprofondimentoPadre(content.idPadre);
            setIdLink(content.idLink);
            setTitoloLink(content.titoloLink ? content.titoloLink : "Link");
            setIdLinkAlternativo(content.idLinkAlternativo);
            setTitoloLinkAlternativo(content.titoloLinkAlternativo ? content.titoloLinkAlternativo : "Link");
            if (!content.linkInMutuaEsclusione && !linkInMutuaEsclusione && content.idLinkAlternativo) setLinkInMutuaEsclusione();
            setDisableRipristina(content.versione?.major > 0 && content.stato === statoContenuto.BOZZA ? false : true);

            setStato(content.stato ? content.stato : statoContenuto.BOZZA);
            setTemplate(content.template ? content.template : "");

            setName(content.nome ? content.nome : " ");
            setOriginalName(content.nome ? content.nome : " ")

            //PATH
            let path = (content?.path?.charAt(0) === "/") ? content.path : ""
            setPath(path); //setPath(name ? "/" + tipologiaPath + "/" + relativePathName : content.path);

            let pathSplit = path.split("/")
            let subPath = pathSplit.length ? pathSplit[pathSplit.length - 1] : ""

            setSubPath(subPath)
            setBasePath(path.replace('/' + subPath, ""))
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            setTitle(content.titolo ? content.titolo : "");
            setSubTitle(content.sottotitolo);
            setDataPubblicazione(content.dataPubblicazione ? moment(content.dataPubblicazione).format("DD-MM-YYYY") : "");
            setDataSospensione(content.dataSospensione ? moment.utc(content.dataSospensione).local().format("YYYY-MM-DD") : "");
            setDate(content.dataPubblicazioneProgrammata ? moment.utc(content.dataPubblicazioneProgrammata).local().format("YYYY-MM-DD") : "");
            setTime(content.dataPubblicazioneProgrammata ? moment.utc(content.dataPubblicazioneProgrammata).local().format("HH:mm") : "");

            //Immagine copertina e box
            setFileCopertina(content.immagineCopertina ? content.immagineCopertina?.path : null)
            setAlternativeTextImgCopertina(content.immagineCopertina ? content.immagineCopertina?.testoAlternativo : null);

            // setMetadataFileImgBox(content.immagineBoxLancio ? content.immagineBoxLancio?.path : null);
            setFileImgBox(content.immagineBoxLancio ? content.immagineBoxLancio?.path : null);
            setAlternativeTextImgBox(content.immagineBoxLancio ? content.immagineBoxLancio?.testoAlternativo : null);

            let mappedTestoArray = content.testo ?
                content.testo.map((currentTesto) => {
                    let mappedTesto = { ...currentTesto, uniqueId: makeUniqueId(6) };
                    return mappedTesto;
                }) : [{ ...blankQuill, uniqueId: makeUniqueId(6) }]

            let mappedTestoInferioreArray = content.testoInferiore ?
                content.testoInferiore.map((currentTesto) => {
                    let mappedTesto = { ...currentTesto, uniqueId: makeUniqueId(6) };
                    return mappedTesto;
                }) : []

            //setTesto(content.testo ? content.testo : [{ ...blankQuill }]);
            setTesto(mappedTestoArray);
            //setTestoInferiore(content.testoInferiore?.length ? content.testoInferiore : []);
            setTestoInferiore(mappedTestoInferioreArray);
            setRiferimentiEsterni(content.riferimentiEsterni?.length ? content.riferimentiEsterni : []);

            setPulsantiBlu(content.pulsantiBlu ? addKeys(content.pulsantiBlu) : []);
            setContenutiCorrelati(content.contenutiCorrelati ? addKeys(content.contenutiCorrelati) : []);
            setStato(content.stato ? content.stato : " ");
            setValidazione(content.validazione ? content.validazione : false);
            setDataModifica(content.dataModifica ? moment(content.dataModifica).format("DD-MM-YYYY") : "");
            setUtenteInserimento(content.utenteInserimento ? content.utenteInserimento : "");
            setUtenteModifica(content.utenteModifica ? content.utenteModifica : "");
            setTogglePubblicazioneProgrammata(content.dataPubblicazioneProgrammata ? true : false);

            if (content.tipologia === tipologiaEnum.DETTAGLIO_APPROFONDIMENTO) setCustomCTAs(content.applicazioniCustom ? addKeys(content.applicazioniCustom) : [])
            else setCustomApps(content.applicazioniCustom ? addKeys(content.applicazioniCustom) : []);


            //Template 5

            // TODO - allineare il campo link al servizio
            setLink(content.link ? content?.link?.link : "")

            setFonte(  // Reso generico, dato che è possibile inserire "portale" più volte e in maniera case sensitive
                fonti?.map(el => el.descrizione === content?.fonte).includes(true) ? content.fonte :
                    fonti?.map(el => el.descrizione.toLowerCase() === "portale").includes(true) ?
                        getFirstValueNotNullFromArray(fonti?.map(el => {
                            if (el.descrizione.toLowerCase() === "portale") {
                                return el.descrizione
                            }
                        })) :
                        null
            )
            setAttachments(content.allegati ? content.allegati : [])
            setPersone(content.persone ? content.persone : [])
            setMoreDescription(content.informazioni ? content.informazioni.descrizione : "")
            setMoreAddress(content.informazioni ? content.informazioni.indirizzo : "")
            setDateScadenzaProgrammata(content.dataScadenzaProgrammata ? moment.utc(content.dataScadenzaProgrammata).local().format("YYYY-MM-DD") : "")
            setTimeScadenzaProgrammata(content.dataScadenzaProgrammata ? moment.utc(content.dataScadenzaProgrammata).local().format("HH:mm") : "")
            setToggleScadenzaProgrammata(content.dataScadenzaProgrammata ? true : false)
            setGalleryPic(content.galleria ? content.galleria : [])

            //Template 5 - Fake News
            //Mock
            setCategorie(content.categorie ? content.categorie : [])
            setToggleViewCategorie(content.categorie ? true : false)

            //Social Network
            setSocials([
                content.facebook ? { ...content.facebook, immagine: content.facebook.immagine?.path } : { ...blankSocial },
                content.twitter ? { ...content.twitter, immagine: content.twitter.immagine?.path } : { ...blankSocial },
            ])

            //CARICA CONTENUTI PER AUTOCOMPLETE DETTAGLIO APPROFONDIMENTI
            ContenutiRepository()
                .getTuttiContenuti({ lingua: content.lingua }, { numeroElementi: maxElementi })
                .then(resp => {
                    let contenutiFigliFiltrati = resp.filter(x => x.tipologia === tipologiaEnum.DETTAGLIO_APPROFONDIMENTO)
                    let contenutiFiltrati = resp.filter(x => x.tipologia !== tipologiaEnum.DETTAGLIO_APPROFONDIMENTO); //resp.filter(el => tipologiaEnum.getValueOf(el.tipologia)).filter(el2 => el2.stato === statoContenuto.PUBBLICATO)
                    let nomeContenutoIdPadre = content.idPadre ?
                        contenutiFiltrati?.filter(el => el.id === content.idPadre)[0].nome :
                        "";
                    setNomeContenutoIdPadre(nomeContenutoIdPadre);
                    setContenutiPerIdPadre(contenutiFiltrati)
                    setContenutiFigli(contenutiFigliFiltrati)
                })

            //Indice della pagina
            if (content.indice === true && index === false) {
                setIndex();
            }
            else if (content.indice === false && index === true) {
                setIndex();
            }

            if (content.stato === statoContenuto.ELIMINATO) {
                dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.WARN, alertMsg: Messages.ERR_CONTENUTO_ELIMINATO } });
            }
        }
        else {
            onLoadHandler(currentContentId, setContent, setContentFetched, dispatch, setFonti, setIsCTAEditable, setIsContenutiCorrelatiEditable, setIsContenutoPadreEditable)
        }
    }, [contentFetched]);

    //Reset alert when entering page
    useEffect(() => { dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.NONE, alertMsg: null } }) }, [])

    // Set della fonte al cambio della provenienza
    useEffect(() => {
        if (provenienza === provenienzaEnum.INTERNA) {
            if (fonti?.filter(el => el.descrizione?.toLowerCase().includes((session.ruoliUtente === roleEnum.AMMINISTRATORE || session.ruoliUtente === roleEnum.EDITORE) ? "portale" : "regioni")).length > 0)
                setFonte(fonti?.filter(el => el.descrizione?.toLowerCase().includes((session.ruoliUtente === roleEnum.AMMINISTRATORE || session.ruoliUtente === roleEnum.EDITORE) ? "portale" : "regioni"))[0]?.descrizione)
            else setFonte(fonti && fonti[0]?.descrizione)
        }
        else if (provenienza === provenienzaEnum.ESTERNA) {
            if ((session.ruoliUtente === roleEnum.AUTORE || session.ruoliUtente === roleEnum.COLLABORATORE)) {
                if (fonti?.filter(el => el.descrizione?.toLowerCase().includes("regioni")).length > 0)
                    setFonte(fonti?.filter(el => el.descrizione?.toLowerCase().includes("regioni"))[0]?.descrizione)
                else setFonte(fonti && fonti[0]?.descrizione)
            }
            else if (fonte === fonti?.filter(el => el.descrizione?.toLowerCase().includes("portale"))[0]?.descrizione) {
                setFonte("")
            }
        }
    }, [provenienza])

    //Gestione link alternativi in applicazioni custom - come fare per
    useEffect(() => {
        if (idLink === "DISTURBI_ALIMENTARI" || idLink === "PRGLA") {
            setTitoloLink(null);
            setIdLinkAlternativo(null);
            setTitoloLinkAlternativo(null);
            if (linkInMutuaEsclusione) setLinkInMutuaEsclusione();
        }
    }, [idLink])

    const btnPrimary = [
        {
            //le classi sono invertite per aderenza al mockup
            id: "1", color: !hasError() ? "secondary" : "primary", outline: !hasError(), title: "Salva modifiche", disabled: hasError(), disabledTooltip: Messages.INFO_DISABLED_SALVA_MODIFICHE,
            onClickHandler: () => {
                if (content.template === "TEMPLATE_5" && provenienza === provenienzaEnum.ESTERNA && testo[0].testo?.replace(/<(?:.|\n)*?>/gm, '').replace(/(\r\n|\n|\r)/gm, "").replaceAll('&nbsp;', ' ')?.length > 250) {
                    showError("La lunghezza del testo non può superare i 250 caratteri, compreso gli spazi.")
                }
                else {
                    ContenutiRepository().aggiornaContenuto(InizializzaStatoTemplate(currentContentId, path, tipologia, title, subTitle, testo, riferimentiEsterni, testoInferiore, fileCopertina, fileImgBox, fileIcona, pulsantiBlu, contenutiCorrelati, sitiCertificati, date, time, togglePubblicazioneProgrammata, validazione, template, stato, description, name, fonte, attachments, persone, toggleReading, tempoLettura, tags, toggleDate, inputDate, socials, moreDescription, moreAddress, approfondimentoPadre, idLink, content.tipologia === tipologiaEnum.DETTAGLIO_APPROFONDIMENTO ? customCTAs : customApps, dateScadenzaProgrammata, timeScadenzaProgrammata, toggleScadenzaProgrammata, galleryPics, alternativeTextImgCopertina, alternativeTextImgBox, index, alternativeTextIcon, content?.dataModifica, categorie, toggleViewCategorie, lingua, session, provenienza, link, titoloLink, idLinkAlternativo, titoloLinkAlternativo, linkInMutuaEsclusione))
                        .then(result => {
                            if (result) {
                                if (!hasErrorOutcome(result)) {
                                    checkNomeModificato(result, originalName, setOriginalName, dispatch)
                                    dispatch({ type: 'RELOAD_EDIT_CONTENT', payload: { reloadEditContent: true } });
                                    dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
                                    showSuccess()
                                    setIdAgnosticoLingua(result.idAgnosticoLingua);
                                }
                            }
                        })
                        .catch(err => {
                            showError(KO_OPERATION)
                        })
                    forcedScrollToTop(state, dispatch, scrollToTop) // Gestione momentanea
                }
            }
        },
        {
            id: "2",
            color: stato === statoContenuto.ELIMINATO ? "primary" : "secondary",
            title: "Visualizza anteprima",
            disabled: stato === statoContenuto.ELIMINATO,
            onClickHandler: () => {
                getModal(setAction, operazioniContenuto.ANTEPRIMA, handleShow)
            }
        }
    ]

    const btnSecondary = role !== roleEnum.COLLABORATORE ? [
        role !== roleEnum.AUTORE && {
            id: "3", color: disableRipristina ? "primary" : "secondary", outline: false, disabled: disableRipristina, title: operazioniContenuto.RIPRISTINA,
            onClickHandler: () => {
                ContenutiRepository().ripristinaContenuto(currentContentId) //.then(data => checkNomeModificato(data, originalName, dispatch))
                    .then(result => {
                        if (!hasErrorOutcome(result)) {
                            dispatch({ type: 'RELOAD_EDIT_CONTENT', payload: { reloadEditContent: true } });
                            dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
                            showSuccess()
                        }
                    })
                    .catch(err => showError(KO_OPERATION))
                forcedScrollToTop(state, dispatch, scrollToTop) // Gestione momentanea
            }
        },
        role !== roleEnum.COLLABORATORE ? {
            id: "4", color: !hasError() ? "secondary" : "primary", outline: hasError(), title: "Pubblica", disabled: hasError(),
            onClickHandler: () => {
                if (content.template === "TEMPLATE_5" && provenienza === provenienzaEnum.ESTERNA && testo[0].testo?.replace(/<(?:.|\n)*?>/gm, '').replace(/(\r\n|\n|\r)/gm, "").replaceAll('&nbsp;', ' ')?.length > 250) {
                    showError("La lunghezza del testo non può superare i 250 caratteri, compreso gli spazi.")
                }
                else {
                    getModal(setAction, operazioniContenuto.PUBBLICAZIONE, handleShow)
                }
            }
        } : null,
        role !== roleEnum.COLLABORATORE /* && role !== roleEnum.AUTORE */ ? {
            id: "5", color: stato === statoContenuto.PUBBLICATO ? "secondary" : "primary", disabled: stato === statoContenuto.PUBBLICATO ? false : true, title: "Sospendi pubblicazione",
            onClickHandler: () => {
                getModal(setAction, operazioniContenuto.SOSPENSIONE_PUBBLICAZIONE, handleShow)
            }
        } : null,
        {
            id: "6", color: stato === statoContenuto.ELIMINATO ? "primary" : "outline-primary", title: "Elimina contenuto", disabled: stato === statoContenuto.ELIMINATO,
            onClickHandler: () => {
                getModal(setAction, operazioniContenuto.ELIMINAZIONE, handleShow)
            }
        }
    ] : []

    const customAddTestoHandler = () => {
        addTestoHandler({ ...blankQuill, uniqueId: makeUniqueId(6) });
    }

    const customAddTestoInferioreHandler = () => {
        addTestoInferioreHandler({ ...blankQuill, uniqueId: makeUniqueId(6) });
    }

    const resetLinkAlternativo = () => {
        setIdLinkAlternativo(null);
        setTitoloLinkAlternativo(null);
        if (linkInMutuaEsclusione) setLinkInMutuaEsclusione();
    }

    // Gestione provenienza
    const changeProvenienza = (value) => {
        if (contenutoAltraLingua.length > 0) showError(Messages.ERR_CAMBIO_PROVENIENZA)
        else setProvenienza(value);
    }

    //Gestione fonte
    const changeFonte = (value) => {
        if (contenutoAltraLingua.length > 0) showError(Messages.ERR_CAMBIO_FONTE)
        else setFonte(value);
    }

    var editTemplateComponent = null;
    if (content) {
        var currentContent = {

            session, role, //Sessione utente & ruolo utente

            id: matchParamsContentId,

            path, setPath, tipologia: content.tipologia, name, pathChangedHandler, validatePath, //Path

            title, titleChangedHandler, validateTitle, //Titolo

            subTitle, subTitleChangedHandler, validateSubTitle, //Sottotitolo

            fileIcona, setFileIcona, onChangeFileIcona, validateIcona, alternativeTextIcon, alternativeTextIconChangedHandler, //Icona homepage

            fileCopertina, setFileCopertina, onChangeFileCopertina, validateCopertina,
            alternativeTextImgCopertina, alternativeTextImgCopertinaChangedHandler, //Img copertina

            fileImgBox, setFileImgBox, onChangeFileImgBox, validateImgBox,
            alternativeTextImgBox, alternativeTextImgBoxChangedHandler, //Img box lancio

            pulsantiBlu, addLinkHandler, removeLinkHandler, updatePulsantiBlu, validatePulsantiBlu, //Pulsanti blu

            testo, setTesto, removeTestoHandler, addTestoHandler: customAddTestoHandler, updateTestoHandler, validateTesto, //Testo

            riferimentiEsterni, setRiferimentiEsterni, removeRiferimentiEsterniHandler, addRiferimentiEsterniHandler, updateRiferimentiEsterniHandler, validateRiferimentiEsterni, //Riferimenti Esterni

            testoInferiore, setTestoInferiore, removeTestoInferioreHandler, addTestoInferioreHandler: customAddTestoInferioreHandler,
            updateTestoInferioreHandler, validateTestoInferiore, //Testo inferiore

            option, setOption, optionChangeHandler, //select

            customApps, addCustomApp, removeCustomApp, changeDescrizioneCustomApp, restoreDescrizione,
            disableDescrizione, validateApplicazioneCustom, changeCta, changeLinkToggle, changeLinkDaCollegare, //Applicazioni custom

            customCTAs, setCustomCTAs, addCustomCTA, removeCustomCTA, changeDescrizioneCustomCTA, restoreDescrizioneCTA,
            disableDescrizioneCTA, changeCtaCustomCTA, changeLinkToggleCTA, changeLinkCTA, validateCTA,  //Custom CTA

            contenutiCorrelati, addContenutiCorrelatiHandler, removeContenutiCorrelatiHandler,
            updateContenutiCorrelati, validateContenutiCorrelati, //Contenuti correlati

            inputDate, inputDateChangedHandler, validateInputDate, toggleDate, setToggleDate, //Data
            tempoLettura, tempoLetturaChangedHandler, validateTempoLettura, toggleReading, setToggleReading, //Tempo lettura

            contenutiPerIdPadre: contenutiPerIdPadre,

            arrayLink: linkDaCollegare,
            arrayLinkCovid: linkDaCollegareCovid,

            arrayCustomApp: [...linkAppCustom],
            arrayCustomAppApprofondimenti: [...linkAppCustomApprofondimenti],
            arrayCustomAppCovid: [...linkAppCustomCovid],

            description, descriptionChangedHandler: customDescriptionChangedHandler, validateDescription, //Descrizione

            tags, setTags, addTag, removeTag, newTag, tagChangedHandler, manageTagInput, setManageTagInput, //Tags

            sitiCertificati, addSitiCertificatiHandler, removeSitiCertificatiHandler,
            updateSitiCertificati, validateSitiCertificati, //Siti certificati

            approfondimentoPadre, approfondimentoPadreChangedHandler, setApprofondimentoPadre, validateApprofondimentoPadre, //Approfondimento padre
            nomeContenutoPadre: nomeContenutoPadre,
            idLink, idLinkChangedHandler, validateIdLink, titoloLink, titoloLinkChangedHandler, validateTitoloLink,  //idLink
            idLinkAlternativo, idLinkAlternativoChangedHandler, validateIdLinkAlternativo, titoloLinkAlternativo, titoloLinkAlternativoChangedHandler, validateTitoloLinkAlternativo, linkInMutuaEsclusione, setLinkInMutuaEsclusione, resetLinkAlternativo, //idLinkAlternativo

            socials, updateSocial, //Socials

            provenienza, setProvenienza, provenienzaChangeHandler: changeProvenienza, //Provenienza

            link, setLink, linkChangedHandler, validateLink, //Link

            fonte, fonteChangeHandler: changeFonte, //Fonte

            categorie, setCategorie, removeCategoria, addCategoria, updateCategoria, toggleViewCategorie, setToggleViewCategorie, //Categoria

            galleryPics, removeGalleryPic, addGalleryPic, updateGalleryPic, validateGallery, //Galleria

            persone, removePersona, addPersona, manageUserInput, setManageUserInput, //Persone

            index, setIndex, //Indice della pagina

            currentAttachment, setCurrentAttachment, onChangeCurrentAttachment,
            attachments, setAttachments, removeAttachment, addAttachment, updateAttachment, attachmentMetadata, setAttachmentMetadata, //Allegati

            moreDescription, moreDescriptionChangeHandler, moreAddress, moreAddressChangeHandler, //Informazioni

            newRadioButton, changeNewRadioButtonHandler, //Radio

            newCheckboxButton, changeNewCheckboxButtonHandler, //Checkbox

            invalidMsg, setInvalidMsg, //Messaggio errore specifico

            loadedAssets, bucketIcons,

            lingua, contenutoAltraLingua, //Lingua del contenuto & contenuti in altre lingue

            rejectNaN
        };

        // Gestione Templates
        if (content.template === templateNonCreabileEnum.TEMPLATE_LINK) {
            editTemplateComponent = <Template_Link content={currentContent} />
        }
        else {
            switch (content.tipologia) {
                case tipologiaEnum.APPROFONDIMENTO:
                    editTemplateComponent = <Template_01 content={currentContent} />
                    break;
                case tipologiaEnum.DETTAGLIO_APPROFONDIMENTO:
                    editTemplateComponent = <Template_02 content={currentContent} />
                    break;
                case tipologiaEnum.COME_FARE_PER:
                    editTemplateComponent = <Template_03a content={currentContent} />
                    break;
                case tipologiaEnum.PRESTAZIONE_GARANTITA_SSN:
                    editTemplateComponent = <Template_03b content={currentContent} />
                    break;
                case tipologiaEnum.COVID:
                    editTemplateComponent = <Template_03c content={currentContent} />
                    break;
                case tipologiaEnum.FAQ:
                    editTemplateComponent = <Template_03d content={currentContent} />
                    break;
                case tipologiaEnum.VIVERE_IN_SALUTE:
                    editTemplateComponent = <Template_04 content={currentContent} />
                    break;
                case tipologiaEnum.NEWS:
                    editTemplateComponent = isContentReadOnly(SessioneUtente.getInstance().ruoliUtente, SessioneUtente.getInstance().codiceFiscale, content.areaTerritoriale, content.idUtenteInserimento) ? <ReadOnlyTemplate_05a content={currentContent} /> : <Template_05a content={currentContent} />
                    break;
                case tipologiaEnum.FAKE_NEWS:
                    editTemplateComponent = <Template_05b content={currentContent} />
                    break;
                case tipologiaEnum.APPLICAZIONE_STRUTTURE:
                    editTemplateComponent = <Template_Applicazione_Custom content={currentContent} />
                    break;
                case tipologiaEnum.APPLICAZIONE_STRUTTURE_COVID:
                    editTemplateComponent = <Template_Applicazione_Custom_Covid content={currentContent} />
                    break;
                case tipologiaEnum.FEEDBACK:
                    editTemplateComponent = <Template_FEEDBACK content={currentContent} />
                    break;
                case tipologiaEnum.ARGOMENTO_GENERICO_BOTTOM:
                case tipologiaEnum.ARGOMENTO_GENERICO_CONTATTI:
                case tipologiaEnum.ARGOMENTO_GENERICO_PORTALE:
                    editTemplateComponent = <Template_Argomento_Generico content={currentContent} />
                    break;
                default:
                    editTemplateComponent = <Invalid_Template />
                    isValidTipologia = false;
                    break;
            }
        }
    }

    function getModalMessage(action, validazione) {
        switch (action) {
            case operazioniContenuto.INDIETRO:
                return Messages.ERR_TMP_EDIT_CONTENUTO
            case operazioniContenuto.ELIMINAZIONE:
                if (!isContenutoPadreEditable)
                    return Messages.ERR_ELIMINAZIONE_CONTENUTO_PADRE
                else if (!isCTAEditable)
                    return Messages.ERR_ELIMINAZIONE_CTA_NON_CONSENTITA
                else if (!isContenutiCorrelatiEditable)
                    return Messages.ERR_ELIMINAZIONE_CC_NON_CONSENTITA
                else if (content.lingua === linguaEnum.ITALIANO && content.contenutiAltraLingua?.length > 0)
                    return Messages.ERR_ELIMINAZIONE_CONTENUTO_ITA_CON_ALTRE_LINGUE
                else
                    return Messages.ERR_02_01_0A_0A
            case operazioniContenuto.PUBBLICAZIONE:
                if (validazione)
                    return Messages.INFO_PUBBLICAZIONE_CON_VALIDAZIONE
                else return Messages.INFO_PUBBLICAZIONE
            case operazioniContenuto.SOSPENSIONE_PUBBLICAZIONE:
                if (!isCTAEditable)
                    return Messages.ERR_SOSPENSIONE_CTA_NON_CONSENTITA
                if (!isContenutiCorrelatiEditable)
                    return Messages.ERR_SOSPENSIONE_CC_NON_CONSENTITA
                let alertMessage = "";
                if (content.lingua === linguaEnum.ITALIANO && content.contenutiAltraLingua?.length > 0)
                    alertMessage += "<p>" + Messages.ERR_SOSPENSIONE_CONTENUTO_ITA_CON_ALTRE_LINGUE + "</p>"
                if (contenutiFigli?.filter((el) => el.idPadre === content.id)?.length > 0)
                    alertMessage += "<p>" + Messages.ERR_SOSPENSIONE_CONTENUTO_PADRE + "</p>"
                if (alertMessage === "")
                    return Messages.INFO_SOSPENSIONE_PUBBLICAZIONE
                else
                    return alertMessage
            default:
                return Messages.INFO_DEFAULT
        }
    }

    return (
        redirect ? <Redirect to="/" /> :
            currentContentId ?

                <div className="container-fluid" id="templateApp">
                    <div className="row tipologiaRow">
                        <div className="col-9 tipologia">
                            <TitleTemplate disabledEditIcon={isContentReadOnly(SessioneUtente.getInstance().ruoliUtente, SessioneUtente.getInstance().codiceFiscale, content?.areaTerritoriale, content?.idUtenteInserimento)}
                                disable={false} tipologia={templatesEnum.getNomeFromTipologia(tipologia)} name={name}
                                icona={true} ultimaModifica={dataModifica}
                                utenteUltimaModifica={utenteModifica} onChangeName={nameChangedHandler}
                                nameNotEditable={nameNotEditable} setNameNotEditable={setNameNotEditable} isEditEnable={isValidTipologia}
                                logo={areeTerritorialiEnum[content?.areaTerritoriale]?.logo}
                            />
                        </div>
                        <div className="col-3">
                            <div className="back">
                                <a onClick={() => getModal(setAction, operazioniContenuto.INDIETRO, handleShow)}> <Icon className="left"
                                    color="black"
                                    icon="it-arrow-left"
                                    id="arrow" />Indietro</a>
                            </div>
                        </div>
                    </div>
                    {action === operazioniContenuto.ANTEPRIMA ?
                        <PreviewModal
                            show={show}
                            onHide={handleClose}
                            onConfirm={() => callBackAndClose(InizializzaStatoTemplate(currentContentId, path, tipologia, title, subTitle, testo, riferimentiEsterni, testoInferiore, fileCopertina, fileImgBox, fileIcona, pulsantiBlu, contenutiCorrelati, sitiCertificati, date, time, togglePubblicazioneProgrammata, validazione, template, stato, description, name, fonte, attachments, persone, toggleReading, tempoLettura, tags, toggleDate, inputDate, socials, moreDescription, moreAddress, approfondimentoPadre, idLink, content.tipologia === tipologiaEnum.DETTAGLIO_APPROFONDIMENTO ? customCTAs : customApps, dateScadenzaProgrammata, timeScadenzaProgrammata, toggleScadenzaProgrammata, galleryPics, alternativeTextImgCopertina, alternativeTextImgBox, index, alternativeTextIcon, content?.dataModifica, categorie, toggleViewCategorie, lingua, session, provenienza, link, titoloLink, idLinkAlternativo, titoloLinkAlternativo, linkInMutuaEsclusione), handleClose, action, setRedirect, dispatch, state, setContentFetched)}
                            title={"Anteprima"}
                            body={<iframe title={"Preview"} src={WAFE_PREVIEW_URL + path} width={"100%"} height={"100%"} />}
                        /> :
                        <CustomModal
                            show={show}
                            onHide={handleClose}
                            onConfirm={() => callBackAndClose(InizializzaStatoTemplate(currentContentId, path, tipologia, title, subTitle, testo, riferimentiEsterni, testoInferiore, fileCopertina, fileImgBox, fileIcona, pulsantiBlu, contenutiCorrelati, sitiCertificati, date, time, togglePubblicazioneProgrammata, validazione, template, stato, description, name, fonte, attachments, persone, toggleReading, tempoLettura, tags, toggleDate, inputDate, socials, moreDescription, moreAddress, approfondimentoPadre, idLink, content.tipologia === tipologiaEnum.DETTAGLIO_APPROFONDIMENTO ? customCTAs : customApps, dateScadenzaProgrammata, timeScadenzaProgrammata, toggleScadenzaProgrammata, galleryPics, alternativeTextImgCopertina, alternativeTextImgBox, index, alternativeTextIcon, content?.dataModifica, categorie, toggleViewCategorie, lingua, session, provenienza, link, titoloLink, idLinkAlternativo, titoloLinkAlternativo, linkInMutuaEsclusione), handleClose, action, setRedirect, dispatch, state, setContentFetched)}
                            title={action === operazioniContenuto.INDIETRO ? "Ritorno alla home page" : action + " in corso"}
                            text={getModalMessage(action, validazione)}
                            buttonOk={(!isContenutoPadreEditable && action === operazioniContenuto.ELIMINAZIONE) || ((action === operazioniContenuto.SOSPENSIONE_PUBBLICAZIONE || action === operazioniContenuto.ELIMINAZIONE) && (!isCTAEditable || !isContenutiCorrelatiEditable))}
                            removeButtons={(!isContenutoPadreEditable && action === operazioniContenuto.ELIMINAZIONE) || ((action === operazioniContenuto.SOSPENSIONE_PUBBLICAZIONE || action === operazioniContenuto.ELIMINAZIONE) && (!isCTAEditable || !isContenutiCorrelatiEditable))}
                        />}
                    <div className="row">
                        {content && editTemplateComponent ?
                            <>
                                <div className="col-9">
                                    {editTemplateComponent}
                                </div>
                                <div className="col-3">
                                    {!isContentReadOnly(SessioneUtente.getInstance().ruoliUtente, SessioneUtente.getInstance().codiceFiscale, content.areaTerritoriale, content.idUtenteInserimento) ? isValidTipologia ? <>
                                        <Card title={operazioniContenuto.PUBBLICAZIONE}>
                                            <Publisher stato={stato === statoContenuto.SOSPESO ? stato + " in data: " + dataSospensione : stato} togglePublisher={content.template !== "TEMPLATE_5" && "Validazione necessaria"}
                                                arrayPrimary={btnPrimary} arraySecondary={btnSecondary}
                                                clickValidazione={() => setValidazione(!validazione)}
                                                validazione={content.template !== "TEMPLATE_5" && validazione} date={dataPubblicazione}
                                            />
                                        </Card>
                                        {role !== roleEnum.COLLABORATORE ? <><Card title={operazioniContenuto.PUBBLICAZIONE_PROGRAMMATA} toggle={true}
                                            toggleValue={togglePubblicazioneProgrammata}
                                            toggleChange={() => setTogglePubblicazioneProgrammata(!togglePubblicazioneProgrammata)}>
                                            <Scheduled valueTime={time} valueDate={date} changeTime={timeChangedHandler}
                                                changeDate={dateChangedHandler}
                                                stateToggle={togglePubblicazioneProgrammata}
                                                errorMsg={"Inserire una data futura"} validDate={validateDate}
                                                validTime={validateTime} valid={validateDatePubblicazioneProgrammata} />
                                        </Card>
                                            {content.template === "TEMPLATE_5" &&
                                                <Card title={operazioniContenuto.SCADENZA_PROGRAMMATA} toggle={true} toggleValue={toggleScadenzaProgrammata}
                                                    toggleChange={() => setToggleScadenzaProgrammata(!toggleScadenzaProgrammata)}>
                                                    <Scheduled valueTime={timeScadenzaProgrammata} valueDate={dateScadenzaProgrammata}
                                                        changeTime={timeScadenzaProgrammataChangedHandler}
                                                        changeDate={dateScadenzaProgrammataChangedHandler}
                                                        stateToggle={toggleScadenzaProgrammata}
                                                        valid={validateDateScadenzaProgrammata}
                                                        validDate={validateDateScadenza}
                                                        validTime={validateTimeScadenza}
                                                        errorMsg={"Inserire una data futura"} />
                                                </Card>}</> : null}
                                        {content.tipologia !== tipologiaEnum.FEEDBACK ?
                                            <Card title={"Lingua: " + lingua} flag={true} lingua={lingua}>
                                                <LanguagePicker tipologia={tipologia} nome={name} template={template} fonte={isTipologiaNewsOrFakeNews(content.tipologia) ? content.fonte : null} interno={isTipologiaNewsOrFakeNews(content.tipologia) ? content.interno : null} idAgnosticoLingua={idAgnosticoLingua} altreLingue={contenutoAltraLingua} flagLingua={lingua} />
                                            </Card> : null}
                                    </> : null : null}
                                    {isContentReadOnly(SessioneUtente.getInstance().ruoliUtente, SessioneUtente.getInstance().codiceFiscale, content.areaTerritoriale, content.idUtenteInserimento) && isValidTipologia &&
                                        <Card title={"Lingua: " + lingua} flag={true} lingua={lingua}>
                                            <LanguagePicker tipologia={tipologia} nome={name} template={template} idAgnosticoLingua={idAgnosticoLingua} altreLingue={contenutoAltraLingua} flagLingua={lingua} solaVisualizzazione />
                                        </Card>}
                                </div>
                            </> : null}
                    </div>
                </div> :
                <div className="errore"><span className="error">Non è stato possibile caricare il contenuto richiesto</span>
                </div>
    )
}

async function callBackAndClose(statoTempale, handleClose, action, setRedirect, dispatch, state, setContentFetched) {

    let response

    if (action === operazioniContenuto.PUBBLICAZIONE) {
        showLoading()
        ContenutiRepository().pubblicaContenuto(statoTempale)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    setRedirect(true)
                    dispatch({ type: 'RELOAD_EDIT_CONTENT', payload: { reloadEditContent: true } });
                    dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
                    dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.SUCCESS, alertMsg: OK_RELEASE } });
                }
            }).catch((err) => showError(KO_RELEASE))
            .finally(hideLoading)
        handleClose();
        forcedScrollToTop(state, dispatch, scrollToTop)
    }

    else if (action === operazioniContenuto.ELIMINAZIONE) {
        ContenutiRepository().eliminaContenuto(statoTempale.id)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    setRedirect(true)
                    dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
                    showSuccess()
                }
            })
        handleClose();
        forcedScrollToTop(state, dispatch, scrollToTop)
    }

    else if (action === operazioniContenuto.INDIETRO) {
        handleClose();
        setRedirect(true);
    }

    else if (action === operazioniContenuto.SOSPENSIONE_PUBBLICAZIONE) {
        response = ContenutiRepository().sospendiContenuto(statoTempale.id)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    dispatch({ type: 'RELOAD_EDIT_CONTENT', payload: { reloadEditContent: true } });
                    showSuccess();
                } else {
                    setContentFetched(false)
                }
            })
        forcedScrollToTop(state, dispatch, scrollToTop) // Gestione momentanea
        handleClose();
    }

    if (response === Object(response) && action !== operazioniContenuto.SOSPENSIONE_PUBBLICAZIONE)
        setRedirect(true);

}

function onLoadHandler(contentId, setContent, setContentFetched, dispatch, setFonti, setIsCTAEditable, setIsContenutiCorrelatiEditable, setIsContenutoPadreEditable) {

    showLoading()
    Promise.all([ContenutiRepository().getContenutoById(contentId),
    ContenutiRepository().getCataloghi(cataloghiEnum.FONTE),
    ContenutiRepository().getTuttiContenuti({ lingua: linguaEnum.ITALIANO, notInStato: "ELIMINATO" }, { inVersionePubblica: true, ascending: "false", sort: "dataInserimento", numeroElementi: maxElementi })])
        .then(([result1, result2, result4]) => {
            setContent(result1)
            setFonti(result2?.map(el => el))
            if (result4) {
                result4.map((el, i) => {
                    if (el.applicazioniCustom && el.applicazioniCustom.length > 0) {
                        el.applicazioniCustom.map((app, i) => {
                            if (app?.link === result1?.path) {
                                setIsCTAEditable(false)
                            }
                        })
                    }
                    if (el?.idPadre && el?.idPadre === result1?.id) {
                        setIsContenutoPadreEditable(false)

                    }
                    if (el?.contenutiCorrelati && el?.contenutiCorrelati?.length > 0) {
                        el.contenutiCorrelati.map((cc, i) => {

                            if (cc?.link === result1?.path) {
                                setIsContenutiCorrelatiEditable(false)
                            }
                        })
                    }
                })
            }
        })
        .finally(() => {
            setContentFetched(true);
            hideLoading()
        })

}

function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}

function checkNomeModificato(data, originalName, setOriginalName, dispatch) {
    if (data?.contenuto.nome !== originalName) {
        dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
        setOriginalName(data?.contenuto.nome)
    }
}

function getFirstValueNotNullFromArray(array) {

    let value;

    array.map((el, i) => {
        if (el) {
            value = el
        }
    })

    return value;
}

function InizializzaStatoTemplate(id, path, tipologia, titolo, sottotitolo, testo, riferimentiEsterni, testoInferiore, immagineCopertina, immagineBoxLancio, iconaHomepage, pulsantiBlu, contenutiCorrelati, sitiCertificati, date, time, togglePubblicazioneProgrammata, validazione, template, stato, descrizione, nome, fonte, allegati, persone, toggleReading, tempoLettura, tags, toggleDate, inputDate, socials, moreDescription, moreAddress, approfondimentoPadre, idLink, applicazioniCustom, dateScadenzaProgrammata, timeScadenzaProgrammata, toggleScadenzaProgrammata, galleryPics,
    alternativeTextImgCopertina, alternativeTextImgBox, indice, alternativeTextIcon, dataModifica, categorie, toggleViewCategorie, lingua, session, provenienza, link, titoloLink, idLinkAlternativo, titoloLinkAlternativo, linkInMutuaEsclusione) {

    // var lingua = "IT";

    if (date && time) {
        var dataPubblicazioneProgrammata = moment(date + " " + time).utc().format();
    }

    if (dateScadenzaProgrammata && timeScadenzaProgrammata) {
        var dataScadenzaProgrammata = moment(dateScadenzaProgrammata + " " + timeScadenzaProgrammata).utc().format();
    }


    //Se il testo inferiore è vuoto -> mando array vuoto
    if (testoInferiore.length === 1 && !testoInferiore[0].titolo && !testoInferiore[0].testo) {
        testoInferiore = []
    }


    return Object.freeze({
        id,
        path,
        tipologia,
        titolo,
        sottotitolo,
        testo: (template === templateNonCreabileEnum.TEMPLATE_LINK) ? null : testo,
        riferimentiEsterni,
        testoInferiore,
        template,
        lingua,
        validazione,
        immagineCopertina: { path: immagineCopertina, testoAlternativo: alternativeTextImgCopertina },
        immagineBoxLancio: { path: immagineBoxLancio, testoAlternativo: alternativeTextImgBox },
        pulsantiBlu,
        contenutiCorrelati,
        sitiCertificati,
        dataPubblicazioneProgrammata: togglePubblicazioneProgrammata ? dataPubblicazioneProgrammata : null,
        stato,
        descrizione,
        nome,
        fonte,
        allegati,
        persone,
        indice,
        tempoLettura: toggleReading ? tempoLettura : null,
        data: toggleDate ? inputDate : null,
        //tag: toggleTag ? tag : null --> Il backend si aspetta una lista
        tag: tags,
        facebook: {
            "link": path,
            "titolo": socials[0].titolo ? socials[0].titolo : titolo,
            "descrizione": socials[0].descrizione ? socials[0].descrizione : descrizione,
            "immagine": {
                path: socials[0].immagine ? socials[0].immagine : immagineCopertina,
                testoAlternativo: null
            }
        },
        twitter: {
            "link": path,
            "titolo": socials[1]?.titolo ? socials[1]?.titolo : titolo,
            "descrizione": socials[1]?.descrizione ? socials[1]?.descrizione : descrizione,
            "immagine": {
                path: socials[1]?.immagine ? socials[1]?.immagine : immagineCopertina,
                testoAlternativo: null
            }
        },
        informazioni: {
            "descrizione": moreDescription,
            "indirizzo": moreAddress
        },
        idPadre: approfondimentoPadre,
        idLink: idLink,
        titoloLink: titoloLink,
        idLinkAlternativo: idLinkAlternativo,
        titoloLinkAlternativo: linkInMutuaEsclusione ? titoloLinkAlternativo : null,
        linkInMutuaEsclusione: !linkInMutuaEsclusione,
        applicazioniCustom,
        iconaHomepage: { path: iconaHomepage, testoAlternativo: alternativeTextIcon },
        dataScadenzaProgrammata: toggleScadenzaProgrammata ? dataScadenzaProgrammata : null,
        galleria: galleryPics,
        dataModifica: dataModifica,
        categorie: toggleViewCategorie ? categorie : null,
        areaTerritoriale: session.idAreaTerritoriale,
        interno: provenienza === provenienzaEnum.INTERNA ? true : false,
        link: { descrizione: null, inNuovaFinestra: false, link: link }
    });

}

function makeUniqueId(length) {
    let result = "";
    let characters = "abcdef0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
