import React from 'react';
import CustomInput from '../../../UI/CustomInput/CustomInput';
import { Button } from 'design-react-kit';
import { Link } from 'react-router-dom';
import { Toggle, Icon } from 'design-react-kit';
import './AssociazioneForm.css';

export default function AssociazioneForm(props) {

    return (
        <div id="associazioneForm">
            <div>
                <label>Nuova Associazione</label>
                <hr></hr>
            </div>
            <div className="row">
                <div className="col-12">
                    <Toggle label={props.content.visibile ? "Visibile" : "Non visibile"} checked={props.content.visibile} onChange={props.content.toggle} />
                </div>
            </div>
            <div className="container">
                <CustomInput id="input1" labelName="Nominativo*" change={props.content.nominativoChangedHandler} name={props.content.nominativo} valid={props.content.validateNominativo} />
                <div className="row">
                    <div className="col-6">
                        <CustomInput id="input3" labelName="Comune*" change={props.content.comuneChangedHandler} name={props.content.comune} valid={props.content.validateComune} />
                    </div>
                    <div className="col-6">
                        <CustomInput id="input2" labelName="Provincia*" change={props.content.provinciaChangedHandler} name={props.content.provincia} valid={props.content.validateProvincia} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <CustomInput id="input5" labelName="Indirizzo" change={props.content.indirizzoChangedHandler} name={props.content.indirizzo} valid={true} />
                    </div>
                    <div className="col-6">
                        <CustomInput id="input6" labelName="Cap" change={props.content.capChangedHandler} name={props.content.cap} valid={props.content.cap ? props.content.validCap : true} type="number" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <CustomInput id="input7" labelName="Telefono (Struttura)*" change={props.content.telefonoStrutturaChangedHandler} name={props.content.telefonoStruttura} valid={props.content.validateTelefonoStruttura} reject={props.content.validateTelephoneNumber} />
                    </div>
                    <div className="col-4">
                        <CustomInput id="input8" labelName="Telefono (Referente)" change={props.content.telefonoReferenteChangedHandler} name={props.content.telefonoReferente} valid={true} reject={props.content.validateTelephoneNumber} />
                    </div>
                    <div className="col-4">
                        <CustomInput id="input9" labelName="Fax" change={props.content.faxChangedHandler} name={props.content.fax} valid={true} reject={props.content.validateTelephoneNumber} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <CustomInput id="input10" labelName="Mail (Struttura)*" change={props.content.mailStrutturaChangedHandler} name={props.content.mailStruttura} valid={props.content.validateMailStruttura} />
                    </div>
                    <div className="col-6">
                        <CustomInput id="input11" labelName="Mail (Referente)" change={props.content.mailReferenteChangedHandler} name={props.content.mailReferente} valid={props.content.mailReferente ? props.content.validateMailReferente : true} />
                    </div>
                </div>
                <CustomInput id="input12" infoMsg={"Deve iniziare con http:// o https://"} placeholder={"https://"} labelName="Indirizzo Web*" change={props.content.indirizzoWebChangedHandler} name={props.content.indirizzoWeb} valid={props.content.validateIndirizzoWeb} />
                <p className="info">*Campi obbligatori</p>
            </div>
        </div>
    )
}