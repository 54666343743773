import React from 'react';
import './Index.css';

export default function Index(props) {

    return (
        <div className="index">
            {props.array ? props.array.map((el, i) =>
                <div className="row" key={"index" + i}>
                    <div className="col-4">
                        <label className={"fieldLabel"} htmlFor={i}>{"PARAGRAFO"}</label>
                        <input id={"titolo-" + i} type="text" readOnly placeholder={"Paragrafo " + (i + 1)} />
                    </div>
                    <div className="co-6">
                        <label className={"fieldLabel"} htmlFor={i}>{"TITOLO DEL PARAGRAFO"}</label>
                        <input id={"paragrafo-" + i} type="text" readOnly placeholder={el.titolo} />
                    </div>
                </div>
            ) : null}
        </div>
    )
}