import React, { useContext } from 'react';
import './InPageAlert.css';
import { Context } from "../../../hook/globalState/Store";
import $ from 'jquery'
import 'bootstrap'

$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip();
});

const tooltipMessage = "Clicca per nascondere"

export default function InPageAlert(props) {

    const [, dispatch] = useContext(Context)
    const dismissModal = () => dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.NONE, alertMsg: null } });

    let overlayClassName = "";
    let alertClassName = "";
    switch (props.level) {
        case alertLevels.ERROR:
            alertClassName = "alert-danger";
            overlayClassName = "overlay";
            break;
        case alertLevels.WARN:
            alertClassName = "alert-warning";
            overlayClassName = "overlay";
            break;
        case alertLevels.INFO:
            alertClassName = "alert-info";
            overlayClassName = "overlay";
            break;
        case alertLevels.SUCCESS:
            alertClassName = "alert-success";
            overlayClassName = "overlay";
            break;
        default:
            alertClassName = "d-none";
            overlayClassName = "";
            break;
    }
    return (
        <>
            <div className={"row inPageAlertOverlay " + overlayClassName}>
                <div className={"col-12 alertSection alert " + alertClassName} role="alert" onClick={() => dismissModal()}>
                    {props.message}
                    <div className={"pt-hint"}>Clicca su questo banner per chiuderlo.</div>
                </div>

            </div>
            {props.children}
        </>
    )
}

export const alertLevels = {
    ERROR: 'error',
    WARN: 'warn',
    INFO: 'info',
    SUCCESS: 'success',
    NONE: 'none'
}
