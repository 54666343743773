import { useState, useEffect } from 'react';

export function useRepositoryFunction(repositoryFunction, filters, queryParams) {
    const [result, setResult] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState()

    useEffect(() => {
        if (!loaded) {
            repositoryFunction(filters, queryParams)
                .then(res => {
                    setResult(res);
                    setLoaded(true);
                    setError(null)
                })
                .catch(err => setError(err))
        }
    }, [loaded]);

    if (error == null) {
        return [result, loaded, setResult, setLoaded];
    } else throw error
}
