import React from 'react';
import './Card.css';
import { Toggle } from 'design-react-kit';
import LanguageFlag from "../LanguageFlag/LanguageFlag";

export default function Card(props) {

    var flag = <LanguageFlag lingua={props.lingua} />

    return (
        <div className="container" id="card">
            <div className="row">
                <div className="title">
                    <label className="text-title">{props.title}</label>
                    {props.flag ?
                        flag :
                        <>
                            {props.toggle && <Toggle onChange={props.toggleChange} checked={props.toggleValue} />}
                        </>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-12 content">
                    {props.children}
                </div>
            </div>
        </div>
    )
}
