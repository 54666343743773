import React from 'react';
import sideBarOpen from '../../img/logos/sidebar-open.svg';
import { CollapsedMenuIcon, CollapsedMenuIconWithSubmenu } from '../HideMenu/CollapsedMenuIcon';
import './HideMenuUtenteRegionale.css';
import LogoSection from '../../img/logos/section.svg';
import LogoApplication from '../../img/logos/application.svg';
import LogoSearch from '../../img/logos/search.svg';
import LogoTool from '../../img/logos/tool.svg';
import LogoTrash from '../../img/logos/trash.svg';
import { roleEnum } from '../../Utils/Dataset';

export default function HideMenuUtenteRegionale(props) {

    const linksTree = BuildLinksTree();

    return (
        <div id="hideMenuRegionale">
            <div className="menu-state" id="showMenu" >
                <button type="button" className="icon-button" title="Mostra Menu" onClick={props.click}><img className="menu-state-icon" src={sideBarOpen} /></button>
            </div>
            <div className="link-list-wrapper">
                <ul className="link-list">
                    <li>
                        <CollapsedMenuIconWithSubmenu menu={linksTree.contenuti} nested={false} icon={LogoSection} title="Contenuti" />
                    </li>
                    <li>
                        <CollapsedMenuIconWithSubmenu menu={linksTree.applicazioniCustom} nested={false} icon={LogoApplication} title="Applicazioni custom" />
                    </li>
                    <li>
                        <CollapsedMenuIcon url="#" icon={LogoSearch} title="Motore di ricerca" />
                    </li>
                    <li>
                        {props.ruolo === roleEnum.AUTORE && <CollapsedMenuIconWithSubmenu menu={linksTree.impostazioni} nested={false} icon={LogoTool} title="Impostazioni" />}
                    </li>
                    <li>
                        {props.ruolo === roleEnum.AUTORE && <CollapsedMenuIcon url="/cestino" icon={LogoTrash} title="Cestino" />}
                    </li>
                </ul>
            </div>
        </div>
    )
}

function BuildLinksTree() {
    return {
        contenuti: [
            {
                title: "Aziende sanitarie e strutture",
                href: "/strutture-regionali"
            },
            {
                title: "Associazioni disturbi alimentari",
                href: "/associazioni"
            },
            {
                title: "Tempi d'attesa - PRGLA",
                href: "/tempi-attesa"
            },
            {
                title: "Lea Regionali",
                href: "/lea-regionali"
            },
            {
                title: "Gestisci news",
                href: "/gestisci-news"
            },
            {
                title: "Siti covid-19",
                href: "/siti-covid"
            }
        ],
        applicazioniCustom: [
            {
                title: "Prestazioni garantite dal SSN",
                href: "#"
            }
        ],
        impostazioni: [
            {
                title: "Redattori",
                href: "/redattori"
            },
            {
                title: "Contatti regionali",
                href: "/contatti-regionali"
            }
        ]
    }
}