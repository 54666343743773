import React from 'react';
import CustomInput from '../../UI/CustomInput/CustomInput';
import ToggleItem from '../../UI/ToggleItem/ToggleItem';
import TextEditor from '../../UI/TextEditor/TextEditor';
import CustomAppWrapper from '../../UI/CustomAppWrapper/CustomAppWrapper';
import FileLoader from '../../UI/FileLoader/FileLoader';
import './Template_03a.css';
import SocialSharing from '../../UI/SocialSharing/SocialSharing';
import { Toggle } from 'design-react-kit';
import Index from '../../UI/Index/Index';
import { assetsEnum } from "../../Utils/Dataset";
import { uploadIcon } from '../../Utils/Utils';
import { BUCKET_URL } from "../../../config/config";
import { Messages } from '../../Utils/Messages';
import AttachmentTable from '../../UI/AttachmentsTable/AttachmentsTable';
import { addNewAttachmentRow, attachmentLoader } from '../TemplateUtils';

export default function Template_03(props) {

    // Template tipologia Come fare per

    const uploadIcona = (file) => uploadIcon(file, props.content.setFileIcona)

    return (
        <div id="Template3">
            <div className="inputBoxWithShadow">
                <CustomInput id="input0" labelName="Titolo*" change={props.content.titleChangedHandler} name={props.content.title} valid={props.content.validateTitle} />
                <CustomInput id="input1" labelName="Sottotitolo" change={props.content.subTitleChangedHandler} name={props.content.subTitle} valid={props.content.validateSubTitle} />
                <CustomInput id="input3" labelName="DESCRIZIONE (non visibile sul Portale)" change={props.content.descriptionChangedHandler} name={props.content.description} valid={props.content.validateDescription} textarea />
                <CustomInput id="input4" labelName="Url*" change={props.content.pathChangedHandler} name={props.content.path} valid={props.content.validatePath} tipologia={props.content.tipologia} setPath={props.content.setPath} invalidMsg={props.content.invalidMsg ?? props.content.invalidMsg} setInvalidMsg={props.content.setInvalidMsg} />
            </div>

            <div className="row inputBoxWithShadow d-flex">
                <ToggleItem inputType={"date"} toggleName={"DATA"} toggleValue={props.content.toggleDate} formValue={props.content.inputDate} changeToggle={props.content.setToggleDate} changeValue={props.content.inputDateChangedHandler} id={"toggleDate"} valid={props.content.validateInputDate} />
                <ToggleItem inputType={"text"} toggleName={"TEMPO DI LETTURA"} toggleValue={props.content.toggleReading} formValue={props.content.tempoLettura} changeToggle={props.content.setToggleReading} changeValue={props.content.tempoLetturaChangedHandler} id={"toggleText"} valid={props.content.validateTempoLettura} rejectNaN={props.content.rejectNaN} />
            </div>

            <FileLoader labelName="icona per homepage*" tip={Messages.SIZE_CONSIGLIATA_ICONA_COMEFAREPER} idLoad="upload1" change={(e) => props.content.onChangeCurrentAttachment(e, (fileBin, fileMetadata) => uploadIcona(fileBin))} name={props.content.fileIcona} valid={props.content.validateIcona} alternativeText={props.content.alternativeTextIcon} changeAlternativeText={props.content.alternativeTextIconChangedHandler} setFile={props.content.setFileIcona} type={assetsEnum.ICONE}
                loadedAssets={props.content.bucketIcons} preview previewUri={props.content.fileIcona ? BUCKET_URL + props.content.fileIcona : null}
            />

            <label className="text-type">testo*</label>
            <TextEditor isErasable={false} label={"testoSuperiore"} array={props.content.testo} click={props.content.addTestoHandler} delete={props.content.removeTestoHandler} updateValue={props.content.updateTestoHandler} valid={props.content.validateTesto} indexOfPage={props.content.index} />

            <label className="text-type">riferimenti esterni</label>
            <TextEditor isErasable={true} label={"testoSuperiore"} array={props.content.riferimentiEsterni} click={props.content.addRiferimentiEsterniHandler} delete={props.content.removeRiferimentiEsterniHandler} updateValue={props.content.updateRiferimentiEsterniHandler} valid={props.content.validateRiferimentiEsterni} />


            <label className="text-type">testo inferiore</label>
            <TextEditor isErasable={true} label={"testoInferiore"} array={props.content.testoInferiore} click={props.content.addTestoInferioreHandler} delete={props.content.removeTestoInferioreHandler} updateValue={props.content.updateTestoInferioreHandler} valid={props.content.validateTestoInferiore} indexOfPage={props.content.index} />

            <div className="inputBoxWithShadow" id={"pageIndex"}>
                <Toggle label={"Indice della pagina"} checked={props.content.index} onChange={props.content.setIndex} />
                {props.content.index && <Index array={props.content.testo.concat(props.content.testoInferiore)} />}
            </div>

            <div className="inputBoxWithShadow">
                <CustomAppWrapper name="APPLICAZIONE CUSTOM" options={props.content.arrayCustomApp} option={props.content.option} change={props.content.optionChangeHandler} array={props.content.customApps} click={props.content.addCustomApp} delete={props.content.removeCustomApp} restore={props.content.restoreDescrizione} disable={props.content.disableDescrizione}
                    isToggleLinkVisibile={true} toggleNameCta={"CTA a fondo testo"} toggleNameLink={"Link a inizio pagina"} changeToggleCta={props.content.changeCta} changeToggleLink={props.content.changeLinkToggle} changeDescrizioneCustomApp={props.content.changeDescrizioneCustomApp} changeLinkDaCollegare={props.content.changeLinkDaCollegare} valid={props.content.validateApplicazioneCustom} infoMessage={Messages.INFO_APPLICAZIONE_CUSTOM} />
            </div>

            <div className={"inputBoxWithShadow"}>
                <AttachmentTable label={"Allegati"}
                    attachments={props.content.attachments}
                    attachmentLoader={(index) => attachmentLoader(index, props.content.onChangeCurrentAttachment, props.content.updateAttachment)}
                    removeAttachment={props.content.removeAttachment}
                    addElementRender={addNewAttachmentRow(props.content.addAttachment)}
                    updateAttachment={props.content.updateAttachment}
                />
            </div>

            <div className="inputBoxWithShadow">
                <SocialSharing labelName={"CONDIVISIONE SUI SOCIAL"} valueUrl={props.content.path} socials={props.content.socials} updateSocial={props.content.updateSocial} titolo={props.content.title} descrizione={props.content.description} immagine={props.content.fileName} onChangeCurrentAttachment={props.content.onChangeCurrentAttachment} loadedAssets={props.content.loadedAssets} />
            </div>
        </div>
    )
}
