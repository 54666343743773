import React from 'react';
import { Icon } from 'design-react-kit';
import './UnAuthorized.css';

export default function UnAuthorized(props) {

    return (
        <div className="unAuthorized">
            <div className="inputBoxWithShadow">
                <Icon className="it-lock" color="black" icon="it-locked" id="lock" />
                {props.role ? <p>Accesso non autorizzato, la pagina richiesta non è raggiungibile per il ruolo di <b>{props.role}</b></p> :
                    <p>Utente non autorizzato. Contattare l’Amministrazione per ottenere l’autorizzazione.</p>}
            </div>
        </div>
    )
}