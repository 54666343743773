import React from 'react';
import Sprite from '../../../img/sprite.svg';
import './LabelDateTime.css';

export default function LabelDateTime(props) {
    return (
        <div>
            <div id="labelDateTime" >
                <div className="date">
                    <label>{props.date}</label>
                    <svg className="bg-blue icon"><use xlinkHref={`${Sprite}#it-calendar`} /></svg>
                </div>
                <div className="time">
                    <label>{props.time}</label>
                    <svg className="bg-blue icon"><use xlinkHref={`${Sprite}#it-clock`} /></svg>
                </div>
            </div>
        </div >
    )
}