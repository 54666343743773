import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './DragDropItems.css';
import DragDropItem from './DragDropItem/DragDropItem';

export default function DragDropItems(props) {

    const [active, setActive] = useState()

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = ((result) => {
        if (!result.destination) {
            return;
        }

        const newArrayItems = reorder(
            props.items,
            result.source.index,
            result.destination.index
        );

        props.setItems(newArrayItems)

    });

    const onDragEndSottovoci = ((result, index) => {
        if (!result.destination) {
            return;
        }

        props.items[index].sottoCategorie = reorder(
            props.items[index].sottoCategorie,
            result.source.index,
            result.destination.index
        )

        props.setItems([...props.items])

    });

    const sottovociRender = (parentIndex, el) => <>
        <DragDropContext onDragStart={() => setActive(null)} onDragEnd={(dropResult) => onDragEndSottovoci(dropResult, parentIndex)}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        <ul>
                            {el.sottoCategorie.map((el, index) =>
                                <div key={el[props.fieldKey].toString().concat("", index)}>
                                    <li>
                                        <Draggable key={el[props.fieldKey].toString().concat("", index)} draggableId={`${el[props.fieldKey]}${index}`} index={index} isDragDisabled={props.versioneRegionale}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    tabIndex={-1}
                                                >
                                                    <DragDropItem
                                                        id={parentIndex + "-" + index}
                                                        index={index}
                                                        sezione={el.etichetta}
                                                        visibile={el.visibile}
                                                        notEditable={el.notEditable}

                                                        active={active === parentIndex + "-" + index}
                                                        setActive={setActive}
                                                        disableRenameItem={props.disableRenameItem}

                                                        updateItem={(index, field, value) => props.updateItem(parentIndex, "sottoCategorie", value, index, field)}

                                                        description={props.description}
                                                        testo={props?.items[parentIndex]?.sottoCategorie[index]?.testo}
                                                        versioneRegionale={props.versioneRegionale}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    </li>
                                </div>
                            )}
                        </ul>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    </>

    return (
        props.items &&
        <div id="dragDropItems">
            <label>{props.label}</label>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={() => setActive(null)} >
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            <ul>
                                {props.items && props.items?.map((el, i) =>
                                    <div key={el[props.fieldKey].toString().concat("", i)}>

                                        <Draggable key={el[props.fieldKey].toString().concat("", i)} draggableId={`${el[props.fieldKey]}${i}`} index={i} isDragDisabled={props.versioneRegionale}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    tabIndex={-1}
                                                    key={el.idCategoria + i}
                                                >
                                                    <DragDropItem
                                                        id={i + "-"}
                                                        index={i}

                                                        sezione={el.etichetta}

                                                        visibile={el.visibile}
                                                        notEditable={el.notEditable}
                                                        updateItem={props.updateItem}

                                                        active={active === i + "-"}
                                                        setActive={setActive}
                                                        disableRenameItem={props.disableRenameItem}
                                                        description={props.description}
                                                        testo={el.testo}
                                                        versioneRegionale={props.versioneRegionale}
                                                    />
                                                    {!props.notLoadOthers && el.sottoCategorie && sottovociRender(i, el)}
                                                </div>
                                            )}
                                        </Draggable>
                                    </div>
                                )}
                            </ul>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    )
}


