import React from 'react';
import CustomInput from '../../../../UI/CustomInput/CustomInput';
import LinkItem from '../../../../UI/LinkItem/LinkItem';
import './ComeFarePerAS.css';

export default function ComeFarePerAS(props) {

    return (
        <div id="comeFarePerAS">
            <h5 className="headerTitle">Come fare per</h5>
            {/* <CustomInput className="input" id="input1" labelName="Prenotare una prestazione online" name={props.asl.prestazioneOnline} valid={true} toggleEnabled={true} />
            <CustomInput className="input" id="input2" labelName="Prenotare una prestazione al CUP" name={props.asl.prestazioneCUP} valid={true} toggleEnabled={true} />
            <div className="row">
                <div className="col-8">
                    <CustomInput className="input-mail" id="input3" labelName="Indirizzo mail" name={props.asl.email} valid={true} />
                </div>
                <div className="col-4">
                    <CustomInput className="input-tel" id="input4" labelName="Telefono" name={props.asl.telefono} valid={true} />
                </div>
            </div>
            <CustomInput className="input" id="input5" labelName="Ottenere esenzioni ed agevolazioni" name={props.asl.esenzioni} valid={true} toggleEnabled={true} />
            <CustomInput className="input" id="input6" labelName="Ritirare referti" name={props.asl.referti} valid={true} toggleEnabled={true} />
            <CustomInput className="input" id="input7" labelName="Ritirare cartelle cliniche" name={props.asl.cartelleCliniche} valid={true} toggleEnabled={true} />
            <CustomInput className="input" id="input8" labelName="Segnalazioni e reclami" name={props.asl.reclami} valid={true} toggleEnabled={true} /> */}
            {props.comeFareArray.map((el, i) => (
                <div key={el.id + i}>
                    <LinkItem
                        bottomLabel={props.catalogoLink?.find(link => link.id === el.id).nome}
                        visibile={el.applicabile}
                        changeVisibile={() => props.updateComeFare(i, "applicabile", !el.applicabile)}
                        link={el.link}
                        changeLink={(e) => props.updateComeFare(i, "link", e.target.value)}
                        customWidthInput={"90%"}
                        linkStrutture={true}
                        validateLink={props.validateComeFarePer[i]}
                    />
                </div>
            ))}
        </div>
    )
}