import React, { useEffect, useState } from 'react';
import useDocumentTitle from '../../../../hook/useDocumentTitle';
import './StruttureRegionali.css'
import CustomInput from "../../../UI/CustomInput/CustomInput";
import {
    operazioniContenuto,
    operazioniAssociazioni, KO_OPERATION, maxElementi, areeTerritorialiEnum
} from "../../../Utils/Dataset";
import useModal from "../../../../hook/useModal";
import useInput from "../../../../hook/useInput";
import validate, { validateUrl } from "../../../UI/FieldValidation/FieldValidation";
import Card from "../../../UI/Card/Card";
import Publisher from "../../../UI/Publisher/Publisher";
import ULSSBanner from "../../../UI/ULSSBanner/ULSSBanner";
import useObjectsArray from "../../../../hook/useObjectsArray";
import CustomModal from "../../../CustomModal/CustomModal";
import { Messages } from "../../../Utils/Messages";
import StruttureRepository from '../../../../Repo/StruttureRepository';
import { hideLoading, scrollToTop, showError, showLoading, showSuccess } from '../../../../hook/globalState/Store';
import { hasErrorOutcome } from '../../../Interceptor/Interceptor';
import SessioneUtente from '../../../../sessionManager/SessioneUtente';
import InfoModal from '../../../UI/InfoModal/InfoModal';


export default function StruttureRegionali(props) {

    useDocumentTitle(props.title);
    let session = SessioneUtente.getInstance();

    //Consts
    const headerMsg = "Qui puoi impostare le strutture presenti nella regione e inserire le associazioni regionali di riferimento per i Disturbi Alimentari. Se non inserisci alcun contenuto, questo non sarà visibile dal cittadino nelle sezioni specifiche."
    const PAGINAZIONE = 5;

    //Data
    const [contentFetched, setContentFetched] = useState(false);
    const [sitoRegionale, setSitoRegionale, changeSitoRegionale, sitoRegionaleValid] = useInput('', validateUrl)
    const [struttureRegionali, setStruttureRegionali, removeStrutturaRegionale, addStrutturaRegionale, updateStrutturaRegionale] = useObjectsArray([])
    const [struttureRegionaliInView, setStruttureRegionaliInView, removeStrutturaRegionaleInView, addStrutturaRegionaleInView, updateStrutturaRegionaleInView] = useObjectsArray([]);
    const [areaTerritoriale, setAreaTerritoriale] = useState();
    const [filterValue, setFilterValue] = useState("")
    const [filtered, setFiltered] = useState(null)

    //Pubblicazione programmata
    const [togglePubblicazioneProgrammata, setTogglePubblicazioneProgrammata] = useState(false);
    const [date, setDate, dateChangedHandler, validateDate] = useInput("", validate);
    const [time, setTime, timeChangedHandler, validateTime] = useInput("", validate);
    const validateDatePubblicazioneProgrammata = !togglePubblicazioneProgrammata || (time?.length && date?.length)

    //Gestione finestre modali
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [action, setAction] = useState("");
    const getModal = (azione) => {
        setAction(azione);
        handleShow();
    }

    const callBackAndClose = () => {
        if (action === operazioniContenuto.ANNULLA_MODIFICHE) {
            // history.go(0)
            setContentFetched(false)
            handleClose();
        }
    }

    const getModalTitle = (action) => {
        switch (action) {
            case operazioniAssociazioni.ELIMINA:
                return 'Elimina associazione'
            case operazioniContenuto.ANNULLA_MODIFICHE:
                return operazioniContenuto.ANNULLA_MODIFICHE
            default:
                return 'Elimina associazione'
        }
    }

    const getModalMessage = () => {
        switch (action) {
            case operazioniAssociazioni.ELIMINA:
                return Messages.ERR_ELIMINA_ASSOCIAZIONE
            case operazioniContenuto.ANNULLA_MODIFICHE:
                return Messages.INFO_ANNULLA_MODIFICHE
            default:
                return Messages.ERR_ELIMINA_ASSOCIAZIONE
        }
    }

    // Filtro
    const updateFilter = event => {
        setFilterValue(event.target.value)
        let tmpFiltered = struttureRegionali?.filter(el => el?.nome?.toLowerCase().trim().indexOf(event.target.value.toLowerCase().trim()) !== -1)
        setFiltered(tmpFiltered)
        setStruttureRegionaliInView(tmpFiltered)
    }

    const resetFilter = () => {
        updateFilter({ target: { value: "" } })
        setFiltered(null)
    }

    useEffect(() => {
        if (struttureRegionali?.length > 0) {
            setFiltered(null)
            setFilterValue("")
            setStruttureRegionaliInView([...struttureRegionali])
        }
    }, [struttureRegionali?.length])

    const Filter = (
        <div className={"filter-elements"}>
            <input type="text" placeholder={"Ricerca azienda o struttura per nome..."} style={{ "background": "transparent" }} className={`form-control`} value={filterValue} onChange={updateFilter} />
        </div>
    )

    //Pulsanti
    const btnPrimary = [
        {
            id: operazioniContenuto.SALVA_MODIFICHE.toLowerCase(), color: "secondary", outline: true, title: "Salva modifiche", disabled: sitoRegionaleValid ? false : true,
            onClickHandler: () => {
                StruttureRepository().editAreaTerritoriale({ icona: areaTerritoriale?.icona ? areaTerritoriale.icona : areeTerritorialiEnum[session.idAreaTerritoriale]?.logo, id: areaTerritoriale?.id ? areaTerritoriale.id : session.idAreaTerritoriale, nome: areaTerritoriale?.nome ? areaTerritoriale.nome : areeTerritorialiEnum[session.idAreaTerritoriale]?.nome, sito: sitoRegionale })
                    .then((result) => {
                        if (result) {
                            if (!hasErrorOutcome(result)) {
                                scrollToTop()
                                showSuccess()
                                setContentFetched(false)
                            } else showError(KO_OPERATION)
                        }
                    })
            }
        }
    ]
    const btnSecondary = [
        {
            id: operazioniContenuto.ANNULLA_MODIFICHE.toLowerCase(), color: "secondary", outline: false, disabled: false, title: operazioniContenuto.ANNULLA_MODIFICHE,
            onClickHandler: () => { getModal(operazioniContenuto.ANNULLA_MODIFICHE) }
        }
    ]

    useEffect(() => {
        if (!contentFetched) {
            showLoading()
            onLoadHandler(setAreaTerritoriale, setStruttureRegionali, setContentFetched)
        }
        else {
            setSitoRegionale(areaTerritoriale?.sito)
        }
    }, [contentFetched])

    return (
        <>
            <div id="struttureRegionaliContainer">
                <CustomModal
                    show={show}
                    onHide={handleClose}
                    onConfirm={() => callBackAndClose()}
                    title={getModalTitle(action)}
                    text={getModalMessage()}
                />
                <div className="row">
                    <div id={"headerText"} className="col-8">
                        <h5>Strutture Regionali</h5>
                        <div id={"infoText"}>
                            <i>{headerMsg}</i>
                        </div>
                        <div className={"d-block"} id={"actions"} />
                        <div className={'input-box col-12'}>
                            <div>
                                <CustomInput infoMsg={"Deve iniziare con http:// o https://"} placeholder="https://" labelName="Sito Regionale" id={"sitoRegionaleLink"} name={sitoRegionale} valid={sitoRegionaleValid} change={changeSitoRegionale} />
                                <h4>AZIENDE SANITARIE E STRUTTURE</h4>
                                {Filter}
                                {struttureRegionaliInView?.map((el, index) =>
                                    <div key={el.id}>
                                        <ULSSBanner
                                            el={el} index={index}
                                            change={updateStrutturaRegionaleInView}

                                        //removeSelf={removeStrutturaRegionaleInView} addSelf={addStrutturaRegionale}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-3 cards">
                        <Card title={operazioniContenuto.MODIFICA}>
                            <Publisher
                                arrayPrimary={btnPrimary}
                                arraySecondary={btnSecondary}
                            />
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

function onLoadHandler(setAreaTerritoriale, setStruttureRegionali, setContentFetched) {

    let session = SessioneUtente.getInstance();

    var filterStruttura = "idAreaTerritoriale::" + session.idAreaTerritoriale;

    let idAreaTerritoriale = session.idAreaTerritoriale;
    Promise.all([StruttureRepository().getAreaTerritoriale(idAreaTerritoriale), StruttureRepository().getStrutture({ numeroElementi: maxElementi, filterStruttura: filterStruttura })])
        .then(([result1, result2]) => {
            setAreaTerritoriale(result1)
            setStruttureRegionali(result2)
        }).catch(err => err)
        .finally(() => {
            setContentFetched(true)
            hideLoading()
        })

}