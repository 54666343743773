import SessioneUtente from "./SessioneUtente";
import TokenService from "./TokenService";
import authConfig from '../config/authConfig'
import { auth_debug_log_enable, globals } from '../config/config'
import { parseJwt } from "../components/Utils/JwtHelper";
import { roleEnum } from '../components/Utils/Dataset';
import { isUtenteImpersonificato } from "../components/Utils/Utils";

const idTokenAutoreRegionale = "eyJraWQiOiJWWHFTZitwaGkrZkhkWjI2T2s2bEdvZGRqVm8xNTB5XC90TXlMd3JoVjZqST0iLCJhbGciOiJSUzI1NiJ9.eyJhdF9oYXNoIjoiTF9xYjRaSWNwbXpiQXd5Mnp6a0h1dyIsInN1YiI6IjUwODY4ZjJiLTFkYTUtNDgwZi1hZjk3LTQ2YWMyYTc0ZTlkMCIsImN1c3RvbTpjb2RpY2Vfc3RydXR0dXJhIjoibG9jYWwiLCJhcG1zOnJ1b2xvX29yZyI6IiIsImFwbXM6UFQtQVRFUlIiOiIyMDAiLCJjdXN0b206Y29kaWNlX2Zpc2NhbGUiOiJyc3NnY3I2N2UwMWgyMzRzIiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfYnkwWngwOTJIIiwiY29nbml0bzp1c2VybmFtZSI6InJzc2djcjY3ZTAxaDIzNHMiLCJnaXZlbl9uYW1lIjoiR2lhbmNhcmxvIiwiYXVkIjoiNjhpOXBuMXBuOTA4cmR0ZjQwZDI4anE1a3UiLCJldmVudF9pZCI6ImQ0ZWQ3MDg0LWQ1OTEtNDBhNS1iM2I5LTNkYjYxMTRjNzhjMSIsInRva2VuX3VzZSI6ImlkIiwiYXBtczpzY2FkZW56YV9wd2QiOiJUaHUgTm92IDE5IDIwMjAgMDg6NDk6MjcgR01UKzAwMDAgKENvb3JkaW5hdGVkIFVuaXZlcnNhbCBUaW1lKSIsImF1dGhfdGltZSI6MTYwNTc3NTg4OCwiYXBtczpncnVwcGkiOiJQVC1DT0wiLCJleHAiOjE2MDU3ODc0MDAsImlhdCI6MTYwNTc4MzgwMCwiZmFtaWx5X25hbWUiOiJSb3NzaSIsImVtYWlsIjoibS5tYWNjaGlhQGtleTIuaXQifQ.EAif9_y0Jkn7t6SrURxa-cck-kax5LSjK2-kZBducMNoh-kriQQ3SIx65ragVM_SoGIsanmcUMLaeOp4s47F-3XqfJFsCXh-ce7Ja6P3opMHA3bdHZb2i-4arpDYieWaa-_BLAIGjGMQK2bsfDSZnbiWbddCr_XqWJ767e9MrJBb97rkQvc1_CQjrOJbJVacbkvNs0hHKK4SwPipmx0Z5JVeWEbikIORQa-aoW3VpIsPUf0wfD4r6bLKDhOjYcSd-tld51SF4rk2CRCFcpdo5wiuyFJfukNoH056MEwwjGW6BwUdfl2Wznplm2Vdp8HHhJ2uBcv3dKtvw-Oo--CGgw";
//const idTokenAmministratore = "eyJraWQiOiJWWHFTZitwaGkrZkhkWjI2T2s2bEdvZGRqVm8xNTB5XC90TXlMd3JoVjZqST0iLCJhbGciOiJSUzI1NiJ9.eyJhdF9oYXNoIjoiTF9xYjRaSWNwbXpiQXd5Mnp6a0h1dyIsInN1YiI6IjUwODY4ZjJiLTFkYTUtNDgwZi1hZjk3LTQ2YWMyYTc0ZTlkMCIsImN1c3RvbTpjb2RpY2Vfc3RydXR0dXJhIjoibG9jYWwiLCJhcG1zOnJ1b2xvX29yZyI6IiIsImFwbXM6UFQtQVRFUlIiOiIyMDAiLCJjdXN0b206Y29kaWNlX2Zpc2NhbGUiOiJyc3NnY3I2N2UwMWgyMzRzIiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfYnkwWngwOTJIIiwiY29nbml0bzp1c2VybmFtZSI6InJzc2djcjY3ZTAxaDIzNHMiLCJnaXZlbl9uYW1lIjoiR2lhbmNhcmxvIiwiYXVkIjoiNjhpOXBuMXBuOTA4cmR0ZjQwZDI4anE1a3UiLCJldmVudF9pZCI6ImQ0ZWQ3MDg0LWQ1OTEtNDBhNS1iM2I5LTNkYjYxMTRjNzhjMSIsInRva2VuX3VzZSI6ImlkIiwiYXBtczpzY2FkZW56YV9wd2QiOiJUaHUgTm92IDE5IDIwMjAgMDg6NDk6MjcgR01UKzAwMDAgKENvb3JkaW5hdGVkIFVuaXZlcnNhbCBUaW1lKSIsImF1dGhfdGltZSI6MTYwNTc3NTg4OCwiYXBtczpncnVwcGkiOiJQVC1DT0wiLCJleHAiOjE2MDU3ODc0MDAsImlhdCI6MTYwNTc4MzgwMCwiZmFtaWx5X25hbWUiOiJSb3NzaSIsImVtYWlsIjoibS5tYWNjaGlhQGtleTIuaXQifQ.EAif9_y0Jkn7t6SrURxa-cck-kax5LSjK2-kZBducMNoh-kriQQ3SIx65ragVM_SoGIsanmcUMLaeOp4s47F-3XqfJFsCXh-ce7Ja6P3opMHA3bdHZb2i-4arpDYieWaa-_BLAIGjGMQK2bsfDSZnbiWbddCr_XqWJ767e9MrJBb97rkQvc1_CQjrOJbJVacbkvNs0hHKK4SwPipmx0Z5JVeWEbikIORQa-aoW3VpIsPUf0wfD4r6bLKDhOjYcSd-tld51SF4rk2CRCFcpdo5wiuyFJfukNoH056MEwwjGW6BwUdfl2Wznplm2Vdp8HHhJ2uBcv3dKtvw-Oo--CGgw";
const idTokenAmministratore = "eyJraWQiOiJWWHFTZitwaGkrZkhkWjI2T2s2bEdvZGRqVm8xNTB5XC90TXlMd3JoVjZqST0iLCJhbGciOiJSUzI1NiJ9.eyJhdF9oYXNoIjoiYUZWbnVIWTlHd3A1OWw4aVRGcUJMdyIsInN1YiI6IjQ5OWY5YWJhLTg5M2UtNDA4Ny1hOWExLWUyMjNkODRiYWY4MyIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJjdXN0b206Y29kaWNlX3N0cnV0dHVyYSI6ImxvY2FsIiwiYXBtczpydW9sb19vcmciOiIiLCJjdXN0b206Y29kaWNlX2Zpc2NhbGUiOiJyZWRhemlvbmUiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtY2VudHJhbC0xLmFtYXpvbmF3cy5jb21cL2V1LWNlbnRyYWwtMV9ieTBaeDA5MkgiLCJwaG9uZV9udW1iZXJfdmVyaWZpZWQiOnRydWUsImNvZ25pdG86dXNlcm5hbWUiOiJyZWRhemlvbmUiLCJnaXZlbl9uYW1lIjoiUmVkYXppb25lIiwiYXVkIjoiNjhpOXBuMXBuOTA4cmR0ZjQwZDI4anE1a3UiLCJ0b2tlbl91c2UiOiJpZCIsImFwbXM6c2NhZGVuemFfcHdkIjoiVGh1IE5vdiAyNiAyMDIwIDA5OjUzOjQ2IEdNVCswMDAwIChDb29yZGluYXRlZCBVbml2ZXJzYWwgVGltZSkiLCJhdXRoX3RpbWUiOjE2MDY0OTc2NzIsImFwbXM6Z3J1cHBpIjoiUFQtQU1NIiwicGhvbmVfbnVtYmVyIjoiKzM5MzMzNDQ1NTY2NiIsImV4cCI6MTYwNjUwMTI3MywiaWF0IjoxNjA2NDk3NjczLCJmYW1pbHlfbmFtZSI6IkNlbnRyYWxlIiwiZW1haWwiOiJyZWRhemlvbmVAcGR0Lml0In0.NcYFioWthplu7roGSrbTg2krslgYDf-b9YXgZE7QgloTEH94G3xaQRyM3OtmjSDAwJWRPJciQaZjbLy0dUS947t2yCn4vunD4rY1Zm0Ykm1OdM03ASkaO7FMZPkawqEh7HQBKvnCQyxZ4n8Puc5wysmY7SnaoU4ofH5FL9v__3jof1LaZ6bMHecreYaRE8aY3YzgY2tw_bQlb4Q0XKv7ygA0Q7CdLEonBtZj07WV_frJhe5_Oc6KNQZnlHf-qIaNro0KUntXIxNUmDmXAv985g68r5GMRfSwSOv89ZKlCYohfid8VH27v06fn6exhQKAkbmGLSz7WPbsbnL1g0bj4g";

export async function logout(params) {
    SessioneUtente.destroySession();
    sessionStorage.removeItem("regioneImpersonificata");

    if (params) {
        let search = params.substring(1);
        let authParams = JSON.parse('{"' + decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') + '"}');

        if (authParams.error) {
            auth_debug_log_enable && console.log("Logout Error: " + JSON.stringify(authParams.error));
            return false;
        }
    }
    return true;
}

export async function logincallback(params) {

    if (authConfig['attivazione-auth'] === false) {
        auth_debug_log_enable && console.log("[DEBUG] autenticazione disabilitata: procedo con proprietà mock");
        let session = SessioneUtente.initEmptyInstance();
        let now = new Date();
        let scadenza = now.setTime(now.getTime() + (30 * 60 * 1000));
        //session.idToken = idTokenAmministratore;
        session.idToken = idTokenAutoreRegionale;
        session.dataScadenzaToken = scadenza;
        session.sessionLatestUpdate = now;
        session = unwrapTokenPropertiesMock(session);
        return true;
    }


    let search = params.substring(1);
    let authParams = JSON.parse('{"' + decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') + '"}');

    const state = authParams.state;
    const authorizationCode = authParams.code;

    auth_debug_log_enable && console.log("[DEBUG] state: ", state);
    auth_debug_log_enable && console.log("[DEBUG] authorizationCode: ", authorizationCode);

    if (authorizationCode && state) {
        const localStateValue = localStorage.getItem("state");
        auth_debug_log_enable && console.log("[DEBUG] localStateValue: ", localStateValue);

        if (state === localStateValue) {
            let codeVerifier = localStorage.getItem('code_verifier')
            let response = await TokenService.requestSessioneUtente(codeVerifier, authorizationCode);

            if (response && response.sessionId) {
                let session = SessioneUtente.initEmptyInstance();
                session.idSessione = response.sessionId;
                session.idToken = response.idToken;
                session.accessToken = response.accessToken;
                session.testaRefreshToken = response.testa_refresh_token
                session.sessionLatestUpdate = Date.now()
                session.SessionTimeOut = authConfig['SessionTimeOut'];
                session.SessionExpirationTime = authConfig['SessionExpirationTime'];
                session.idApplicazione = globals['app-id-applicazione'];
                session = unwrapTokenProperties(session);
                return true;
            } else {
                auth_debug_log_enable && console.log("[ERROR] errore nel reupero el token");
                return false;
            }
        } else {
            auth_debug_log_enable && console.log("[DEBUG] localStateValue e state risultano diversi");
            return false;
        }
    } else {
        auth_debug_log_enable && console.log("[DEBUG] state o authorizationCode non valorizzati: impossibile proseguire");
        return false;
    }
}

function unwrapTokenProperties(sessione) {
    if (sessione.idToken) {

        let unwarappedToken = parseJwt(sessione.idToken);

        sessione.dataScadenzaToken = new Date(unwarappedToken["exp"] * 1000);
        sessione.nomeUtente = unwarappedToken["given_name"];
        sessione.cognomeUtente = unwarappedToken["family_name"];
        sessione.idAreaTerritoriale = unwarappedToken["apms:PT-ATERR"] || ""; //se non arriva un valore per Area Territoriale forziamo a stringa vuota, un utente della redazione centrale rientra in questo caso
        sessione.ruoliUtente = unwarappedToken["apms:gruppi"];
        sessione.scadenzaPassword = unwarappedToken["apms:scadenza_pwd"];
        sessione.codiceFiscale = unwarappedToken["custom:codice_fiscale"];

        //Impersonificazione
        let regioneImpersonificata = sessionStorage.getItem('regioneImpersonificata');
        sessione.ruoloRealeUtente = sessione.ruoliUtente;
        if (isUtenteImpersonificato(sessione)) {
            sessione.ruoliUtente = "PT-AUT";
            sessione.idAreaTerritoriale = regioneImpersonificata;
        }
    }
    return sessione;
}

function unwrapTokenPropertiesMock(sessione) { //solo per scopi di test
    // let testUser = mockRuoli["autore-lazio"];
    let testUser = mockRuoli["amministratore"];
    sessione.nomeUtente = testUser.nome;
    sessione.cognomeUtente = testUser.cognome;
    sessione.idAreaTerritoriale = testUser.idAreaTerritoriale;
    sessione.ruoliUtente = testUser.ruolo;

    if (sessione.idToken) {
        let unwarappedToken = parseJwt(sessione.idToken);
        sessione.scadenzaPassword = unwarappedToken["apms:scadenza_pwd"];
        sessione.codiceFiscale = unwarappedToken["custom:codice_fiscale"];
        // sessione.scadenzaPassword = "Wed Sep 30 2020 12:02:38 GMT+0000 (Coordinated Universal Time)"; //MOCK DA TOGLIERE 
    }

    //Impersonificazione
    let regioneImpersonificata = sessionStorage.getItem('regioneImpersonificata');
    sessione.ruoloRealeUtente = sessione.ruoliUtente;
    if (isUtenteImpersonificato(sessione)) {
        sessione.ruoliUtente = "PT-AUT";
        sessione.idAreaTerritoriale = regioneImpersonificata;
    }
    return sessione;
}

const mockRuoli = {
    "autore-lazio": { nome: "Autore", cognome: "Lazio", ruolo: roleEnum.AUTORE, idAreaTerritoriale: "120" },
    "autore-veneto": { nome: "Autore", cognome: "Veneto", ruolo: roleEnum.AUTORE, idAreaTerritoriale: "050" },
    "autore-piemonte": { nome: "Autore", cognome: "Piemonte", ruolo: roleEnum.AUTORE, idAreaTerritoriale: "010" },
    "autore-sardegna": { nome: "Autore", cognome: "Sardegna", ruolo: roleEnum.AUTORE, idAreaTerritoriale: "200" },
    "collaboratore-abruzzo": { nome: "Collaboratore", cognome: "Abruzzo", ruolo: roleEnum.COLLABORATORE, idAreaTerritoriale: "130" },
    "amministratore": { nome: "Redazione", cognome: "Centrale", ruolo: roleEnum.AMMINISTRATORE, idAreaTerritoriale: "" },
    "editore": { nome: "Editore", cognome: "Centrale", ruolo: roleEnum.EDITORE, idAreaTerritoriale: "" },
    "autore-trento": { nome: "Autore", cognome: "Trento", ruolo: roleEnum.AUTORE, idAreaTerritoriale: "042" },
    "autore-bolzano": { nome: "Autore", cognome: "Bolzano", ruolo: roleEnum.AUTORE, idAreaTerritoriale: "041" },
    "autore-puglia": { nome: "Autore", cognome: "Puglia", ruolo: roleEnum.AUTORE, idAreaTerritoriale: "160" },
}