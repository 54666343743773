import { RICERCA_URL } from "../config/config";
import AuthenticationManager from "../sessionManager/AuthenticationManager";
import SessioneUtente from "../sessionManager/SessioneUtente";

export default function MotoreRicercaRepository() {

    const authManager = new AuthenticationManager();
    const session = SessioneUtente.getInstance();

    const RICERCA_HEADERS = { //usato solo per sviluppo
        "AZUser": "id=" + session.codiceFiscale + ";name=" + session.nomeUtente + ";surname=" + session.cognomeUtente + ";authenticationinfo=xxxx;roles=" + session.ruoliUtente,
        "AZContext": "sessionid=1234;applicationid=SPABOPT;operationid=xxxxx"
    };

    /* centralizza le logiche di inzializzazione degli Headers */
    async function getHeaders(requestUrl) {
        let defaultHeaders = { ...RICERCA_HEADERS };
        defaultHeaders["AZUser"] = defaultHeaders["AZUser"] + ";areaterritoriale=" + session.idAreaTerritoriale
        let headers = await authManager.initRequestHeaders(requestUrl, defaultHeaders);
        headers["Content-Type"] = "application/json";
        return headers;
    }

    async function getSiti() {
        const requestUrl = RICERCA_URL + "/sito";
        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).catch(err => err)
    }

    async function getCategorie() {
        const requestUrl = RICERCA_URL + "/categoria";
        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).catch(err => err)
    }

    async function putSiti(siti) {
        const requestUrl = RICERCA_URL + "/sito";
        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "PUT",
            body: JSON.stringify(siti)
        }).catch(err => err)
    }

    async function aggiornaCategorie(voci) {
        const requestUrl = RICERCA_URL + "/categoria";
        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "PUT",
            body: JSON.stringify(voci)
        }).catch(err => err)
    }

    return Object.freeze({
        getSiti,
        getCategorie,
        putSiti,
        aggiornaCategorie
    });
}