import React from 'react';
import { Toggle, Button } from 'design-react-kit';
import './Publisher.css';
import { statoContenuto, operazioniContenuto } from "../../Utils/Dataset";

export default function Publisher(props) {

    return (
        <div className="container" id="publisher">
            <div className="row">
                {props.stato ?
                    <>
                        {props.stato === statoContenuto.BOZZA || statoContenuto.VALIDAZIONE ?
                            <><label>Stato:</label><span>{props.stato}</span></> :
                            <><label>Stato:</label><span>{props.stato} il {props.date}</span></>
                        }
                    </> :
                    null
                }

            </div>
            <div className="container">
                <div className="row">
                    <div className="switch">
                        {props.togglePublisher ?
                            <>
                                <Toggle onChange={props.clickValidazione} checked={props.validazione} /><span>{props.togglePublisher}</span>
                            </> :
                            null
                        }
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    {props.arrayPrimary?.map((el, i) =>
                        el && <div key={el.id} className="btn-tooltip" title={el.disabled && el.disabledTooltip ? el.disabledTooltip : ""}> <Button id={el.id} color={el.color} outline={el.outline} disabled={el.disabled} tag="button" onClick={el.onClickHandler}>{el.title}</Button> </div>)}
                </div>
            </div>
            <div className="container">
                <div className="row">
                    {props.arraySecondary.map((el, i) =>
                        el ?
                            <Button style={el?.title === operazioniContenuto.RIPRISTINA ? { marginTop: "20px" } : null} key={el?.id} id={el?.id} color={el?.color} disabled={el?.disabled} tag="button" onClick={el?.onClickHandler}>{el?.title}</Button> : null)}
                </div>
            </div>
        </div>
    )
}
