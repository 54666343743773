import React, { useState, useLayoutEffect } from 'react';
import { Icon } from "design-react-kit";
import './SubItem.css';

export default function SubItem(props) {

    const tooltipDelete = "Nascondi";
    const tooltipMostra = "Mostra";

    const [hideElement, setHideElement] = useState(false)
    const [customIcon, setCustomIcon] = useState({ icon: "it-password-invisible", color: "black" });

    useLayoutEffect(() => {
        if (props.visibile !== undefined) {
            setHideElement(!props.visibile)
            if (props.visibile === true) {
                setCustomIcon({ icon: "it-password-invisible", color: "black" })
            }
            else {
                setCustomIcon({ icon: "it-password-visible", color: "black" })
            }
        }
    }, [props.etichetta, props.visibile])

    return (
        <>
            <div id="subItem">
                <Icon
                    id={hideElement || props.hideVoce ? "secondaryBurgerIcon-disable" : "secondaryBurgerIcon"}
                    className="left"
                    icon="it-burger"
                />
                <span
                    id={hideElement || props.hideVoce ? "etichetta-disable" : "etichetta"}
                >
                    {props.etichetta}
                </span>
                {!props.hideVoce &&
                    <a data-toggle="tooltip" title={!hideElement ? tooltipDelete : tooltipMostra}>
                        <Icon
                            id={"hideItem"}
                            size={"sm"}
                            icon={customIcon.icon}
                            color={customIcon.color}
                            onClick={(e) => {
                                change(setHideElement, hideElement)
                                changeIcon(hideElement, setCustomIcon)
                                props.changeVoce(props.index1, "sottovoci", hideElement, props.index2, "visibile")
                            }}
                        />
                    </a>
                }
            </div>
        </>
    )
}

function change(setState, state) {
    setState(!state)
}

function changeIcon(hideElement, setCustomIcon) {
    if (hideElement) {
        setCustomIcon({ icon: "it-password-visible", color: "black" })
    }
    else
        setCustomIcon({ icon: "it-password-invisible", color: "black" })
}