import React from 'react';
import { Collapse, Icon } from 'design-react-kit';
import useToggle from '../../../hook/useToggle';
import LinkList from '../../UI/LinkList/LinkList';
import './LinkCollapse.css';
import { Link } from 'react-router-dom';

export default function LinkCollapse(props) {

    let [collapsed, toggle] = useToggle(props.defaultCollapsed);

    return (
        <div className="col-12" id="linkCollapse">
            <div>
                <a className={"pt-collapse-button"} onClick={toggle}>
                    {props.visible ? <Icon className="left" color="black" icon={props.menuIcon} id="burger" /> : null}<span title={props.tooltip}><img alt={""} className="menu-state-icon" src={props.logo} />{props.title}</span>
                </a>
            </div>
            <Collapse isOpen={!collapsed}>
                {props.nested ? props.links?.map((el, i) =>
                    el.direct ?
                        <Link key={el.title + i} className="list-item direct-link" to={el.href}>{el.title}</Link> :
                        <LinkList array={el.links} title={el.title} key={el.title + i} showVediTutti={props.showVediTutti} maxElementsInView={props.maxElementsInView} />) :
                    <ul>
                        {props.links ? props.links.map((el, i) => <li key={props.collapseId + "-" + i}>
                            <Link className="list-item direct-link" to={el.href}>
                                {el.title}
                            </Link>
                        </li>) : null}
                    </ul>}
            </Collapse>
        </div>
    )
}