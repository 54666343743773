import React, { useState, useContext } from 'react';
import useToggle from '../../../hook/useToggle';
import { Icon, Collapse } from 'design-react-kit';
import './LinkList.css';
import ContenutiRepository from '../../../Repo/ContenutiRepository';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import useModal from '../../../hook/useModal';
import CustomModal from "../../CustomModal/CustomModal";
import { Messages } from "../../Utils/Messages";
import { Context, reloadCestino, showError, showSuccess } from "../../../hook/globalState/Store";
import { hasErrorOutcome } from "../../Interceptor/Interceptor";
import { KO_OPERATION, statoContenuto } from "../../Utils/Dataset";
import { Link } from 'react-router-dom';

export default function LinkList(props) {

    const [state, dispatch] = useContext(Context);

    let [show, toggle] = useToggle(false);
    const [visible, setVisible, handleClose, handleShow] = useModal(false);
    const [id, setId] = useState("");

    const deleteElement = () => {
        ContenutiRepository().eliminaContenuto(id)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
                    showSuccess()
                }
            })
            .finally(() => reloadCestino())
        handleClose();
    }

    // Elementi massimi visualizzati
    // const maxElementsInView = 6;

    return (
        <div className="col-12" id="linkList">
            <a className={"pt-collapse-button"} onClick={toggle}>
                {show ?
                    <Icon className="left" color="white" icon="it-arrow-down-triangle" /> :
                    <Icon className="left" color="white" icon="it-arrow-right-triangle" />}
                {props.title}
            </a>
            {props.array ? props.array?.map((el1, i) => {
                let links = el1?.contenuti?.length && checkList(el1) ? el1?.contenuti?.filter(x => x.stato !== statoContenuto.ELIMINATO)?.sort((a, b) => ordinaPerData(a, b)).slice(0, props.maxElementsInView) : [];
                return <div key={"collapse" + i}>
                    <Collapse isOpen={show}>
                        {links.map((el2, i) =>
                            // checkList(element)
                            <div key={el2.id}>
                                <a className={"sub-argument"}>
                                    {el2.nome}
                                </a>
                                <ul>
                                    {el1.actions ? el1.actions?.map((el, i) => {
                                        const disabledStatus = el.getDisabledStatus && el.getDisabledStatus(el2);
                                        return <li className={el1.actions.length > 1 ? "multiActions" : "singleAction"} key={el.name + i}>
                                            {disabledStatus?.isDisabled ?
                                                <span className={"list-item disabled"} title={disabledStatus.disabledMessage} >
                                                    {el.name}
                                                </span> :
                                                <Link className={"list-item"} to={"/" + el.url + el2.id} onClick={(e) => onClickHandler(el.name, el2.id, handleShow, setId, e)} >
                                                    {el.name}
                                                </Link>}
                                        </li>
                                    }
                                    ) : null}
                                </ul>
                            </div>
                        )}
                        {
                            props.showVediTutti && props.array && props.array.length && props.array[0].contenuti && props.array[0].contenuti.length && props.array[0].contenuti[0] && props.array[0].actions[0].url !== "edit/footer/" ? // Controllo momentaneo
                                <Link className="see-all" to={"/contenuti/" + props.array[0].contenuti[0]?.tipologia?.toLowerCase()}>
                                    Vedi tutti...
                            </Link> : null
                        }
                    </Collapse>
                </div>
            }
            ) : < div >
                </ div>
            }

            <CustomModal
                show={visible}
                title={"Eliminazione in corso"}
                text={props?.array[0]?.contenuti?.find(x => x.id === id)?.contenutiAltraLingua?.length > 0 ? Messages.ERR_ELIMINAZIONE_CONTENUTO_ITA_CON_ALTRE_LINGUE : Messages.ERR_02_01_0A_0A}
                onHide={handleClose}
                onConfirm={() => deleteElement(id, handleClose)}
            />
        </div >
    )
}

function hrefHandler(name, url, id) {
    if (name == "Modifica") {
        return url + id;
    }
    if (name == "Elimina") {
        return null;
    }
}

function checkList(el) {

    const filterList = el?.contenuti?.filter(x => x.stato !== statoContenuto.ELIMINATO);

    if (filterList && filterList.length > 0) {
        return true;
    } else {
        return false;
    }
}


function onClickHandler(name, id, handleShow, setId, e) {
    if (name == "Elimina") {
        e.preventDefault();
        handleShow();
        setId(id);
    }
}

function ordinaPerData(a, b) {
    if (a.dataInserimento < b.dataInserimento) {
        return 1;
    }
    if (a.dataInserimento > b.dataInserimento) {
        return -1;
    }
    return 0;
}
