import React from 'react';
import CircleContainer from '../../../UI/CircleContainer/CircleContainer';
import "./ContatoreFeedback.css";

export default function ContatoreFeedback(props) {

    const feedbackRicevuti = (
        <div className="feedback-inseriti">
            <b>Feedback ricevuti</b>
            <hr />
            <b>{props.numeroFeedbackRicevuti}</b>
        </div>
    )

    const feedbackArchiviati = (
        <div className="feedback-archiviati">
            <b>Feedback archiviati</b>
            <hr />
            <b>{props.numeroFeedbackArchiviati}</b>
        </div>
    )

    return (
        <div className="contatore-feedback">
            <div>
                <CircleContainer body={feedbackRicevuti} />
                <CircleContainer body={feedbackArchiviati} />
            </div>
        </div>
    )

}