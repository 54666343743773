import React from 'react';
import './FileLoader.css';
import { Button } from 'design-react-kit';
import { getFileNameFromPath } from '../../Utils/Utils';
import ModalFile from '../../ModalFile/ModalFile';
import useModal from '../../../hook/useModal';

export default function FileLoader(props) {

    const [show, , handleClose, handleShow] = useModal(false);


    //CONSTS
    const PAGINAZIONE = 5


    return (
        <div id="fileLoader">
            <label className="label-text" id="etichetta">{props.labelName}</label>
            {props.tip ? <div><span className="label-tip">{props.tip}</span></div> : null}
            <div className="row">
                <div className="col-5">
                    <div id={'imgBtns'}>
                        <form method="post" action="" encType="multipart/form-data">
                            {props.disableButton ?
                                <div id={"buttonSfoglia"}>
                                    <Button disabled={props.disableButton} className={"btnSfoglia"} color={"secondary"} tag="button" onClick={handleShow}>{props.name === null ? "Carica" : "Cambia"}</Button>
                                </div>
                                : <div id={"buttonLoad"}>
                                    <input type="file" name={props.idLoad} id={props.idLoad} className="upload"
                                        onChange={props.change}
                                    />
                                    <label id="btnLoad" htmlFor={props.idLoad}>
                                        {props.name === null ? "Carica" : "Cambia"}
                                    </label>
                                </div>}
                            <div id={"buttonSfoglia"}>
                                <Button disabled={props.disableButton} className={"btnSfoglia"} color={"secondary"} tag="button" onClick={handleShow}>Sfoglia</Button>
                            </div>
                            <ModalFile
                                show={show}
                                onHide={handleClose}
                                title={"Immagini presenti nella collezione"}
                                array={props.loadedAssets}
                                pagination={PAGINAZIONE}
                                onConfirm={(name) => setFileName(name, handleClose, props.setFile)}

                            />
                        </form>
                    </div>
                </div>
                <div className="col-7">
                    <div id={'imgLabel'}>
                        <input style={{ minWidth: "10%" }} className="mr-1" type="text" readOnly value={props.name ? getFileNameFromPath(props.name) : ""} />
                    </div>
                </div>
                {props.preview &&
                    <>
                        <div className="box-preview">
                            <label className="preview" htmlFor={"text-" + props.idLoad}>Anteprima</label>
                            <div id={"fileLoaderPreviewDiv"}>
                                <img src={props.previewUri ? props.previewUri : require('./../../img/banners/placeholder-image.png')} id={"fileLoaderPreview"} alt={props.idLoad} />
                            </div>
                        </div>
                    </>
                }
            </div>
            {!props.valid ? <p>Caricare un'immagine</p> : null}
            {!props.hideAlternativeText && <div className="testo-alternativo">
                <div className="row">
                    <div className="">
                        <label htmlFor={"text-" + props.idLoad}>Testo alternativo</label>
                    </div>
                    <div className="col-9">
                        <input readOnly={props.disableButton} id={"text-1" + props.idLoad} type="text" className="form-control" value={props.alternativeText ? props.alternativeText : ""} onChange={props.changeAlternativeText} />
                    </div>
                </div>
            </div>}

        </div>
    )
}

function setFileName(name, handleClose, setFile) {
    setFile(name)
    handleClose();
}
