import React from 'react';
import './AgenasLogo.css';
import LogoAgenas from '../../../img/logos/logo-agenas.png';

function AgenasLogo() {
    return (
        <div className="col d-none d-md-block" id="agenaslogo">
            <a href="#">
                <img className="img-fluid" src={LogoAgenas} />
            </a>
        </div>
    )
}

export default AgenasLogo;