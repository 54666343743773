import { FEEDBACK_URL, REPHEADERS } from "../config/config";
import AuthenticationManager from '../sessionManager/AuthenticationManager';
import SessioneUtente from "../sessionManager/SessioneUtente";

export default function FeedbackRepository() {

    const feedbackUrl = FEEDBACK_URL + "/feedback";

    const authManager = new AuthenticationManager();
    const session = SessioneUtente.getInstance();

    /* centralizza le logiche di inzializzazione degli Headers */
    async function getHeaders(requestUrl) {
        let defaultHeaders = { ...REPHEADERS };
        defaultHeaders["AZUser"] = defaultHeaders["AZUser"] + ";areaterritoriale=" + session.idAreaTerritoriale
        return await authManager.initRequestHeaders(requestUrl, defaultHeaders);
    }

    async function getAllFeedback(queryParams) {

        const requestUrl = feedbackUrl + "?" + new URLSearchParams(queryParams);

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result)
    }

    async function deleteFeedback(idFeedback) {
        const requestUrl = feedbackUrl + "/" + idFeedback;

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "DELETE"
        }).then(result => result)
    }

    async function getDashboardFeedback() {
        const requestUrl = feedbackUrl + "/dashboard";

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result)
    }

    async function archiviaFeedback(idFeedback) {
        const requestUrl = feedbackUrl + "/" + idFeedback + "/archivia";

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "POST"
        }).then(result => result)
    }

    /* 
        BODY: { "tipologia": "CONTENUTI", "testo": "string" }
    */
    async function creaFeedback(body) {
        const requestUrl = feedbackUrl;

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "POST",
            body: JSON.stringify(body)
        }).then(result => result)

    }

    return Object.freeze({
        getAllFeedback, deleteFeedback, getDashboardFeedback, archiviaFeedback, creaFeedback
    })

}