import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";


export const DragDropContextRenderer = props => (
    <DragDropContext onDragEnd={props.dragEndHandler}>
        <Droppable direction={props.direction} droppableId={props.droppableId}>
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    {props.children}
                </div>
            )}
        </Droppable>
    </DragDropContext>
)

export const DraggableRenderer = props => (
    <Draggable draggableId={props.draggableId} index={props.index} isDragDisabled={props.disabled}>
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                {props.children}
            </div>
        )}
    </Draggable>
)
