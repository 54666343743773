import { DAM_URL, REPHEADERS, FILEREPHEADERS } from "../config/config";
import AuthenticationManager from '../sessionManager/AuthenticationManager';
import SessioneUtente from "../sessionManager/SessioneUtente";

export default function FileRepository() {

    //Tipologia {immagini, icone, allegati}

    //const fileServiceUrl = SERVICE_URL;
    //const fileServiceUrl = "http://msdampt.pdt.18-196-167-173.nip.io/api/v1";

    const authManager = new AuthenticationManager();
    const session = SessioneUtente.getInstance();

    /* centralizza le logiche di inzializzazione degli Headers */
    async function getHeaders(requestUrl, mockHeaders) {
        let defaultHeaders = { ...mockHeaders };
        defaultHeaders["AZUser"] = defaultHeaders["AZUser"] + ";areaterritoriale=" + session.idAreaTerritoriale
        return await authManager.initRequestHeaders(requestUrl, defaultHeaders);
    }

    async function uploadFile(tipologia, file) {

        const formData = new FormData();
        formData.append('file', file);

        const requestUrl = DAM_URL + "/assets/" + tipologia;
        const headers = await getHeaders(requestUrl, FILEREPHEADERS); //FILEREPHEADERS,
        //no content-type required

        const optionsUpload = {
            headers: headers,
            method: "POST",
            body: formData
        };

        return fetch(requestUrl, optionsUpload)
            .then(data => data)
            .catch(err => err)
    }

    async function getFile(tipologia) {
        const requestUrl = DAM_URL + "/assets/" + tipologia;
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET"
        }).catch(err => err)
    }

    return Object.freeze({
        uploadFile,
        getFile
    });

}
