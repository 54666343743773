import AuthenticationManager from "../sessionManager/AuthenticationManager";
import { STRUTTUREREPHEADERS, STRUTTURE_URL } from "../config/config";
import SessioneUtente from "../sessionManager/SessioneUtente";

export default function StruttureRepository() {

    const struttureServiceUrl = STRUTTURE_URL + "/strutture"
    const cataloghiServiceUrl = struttureServiceUrl + "/cataloghi"
    const areeTerritorialiServiceUrl = struttureServiceUrl + "/areeterritoriali"

    const authManager = new AuthenticationManager();
    const session = SessioneUtente.getInstance();

    /* centralizza le logiche di inzializzazione degli Headers */
    async function getHeaders(requestUrl) {
        let defaultHeaders = { ...STRUTTUREREPHEADERS };
        defaultHeaders["AZUser"] = defaultHeaders["AZUser"] + ";areaterritoriale=" + session.idAreaTerritoriale
        let headers = await authManager.initRequestHeaders(requestUrl, defaultHeaders);
        headers["Content-Type"] = "application/json";
        return headers;
    }

    /*  
        Query params:
        filterStruttura - Filtri di ricerca da applicare alle strutture da recuperare
        filterLink - Filtri di ricerca da applicare ai link delle strutture da recuperare
        sort - Attributo di ordinamento delle strutture da recuperare (Default value: nome)
        ascending - Flag per la tipologia di ordinamento delle strutture da recuperare (Default value: true)
        numeroPagina - Numero di pagina dei risultati paginati (Default value: 1)
        numeroElementi - Numero di elementi per pagina dei risultati paginati (Default value: 10)
    */
    async function getStrutture(queryParams) {
        const requestUrl = struttureServiceUrl + (queryParams ? "?" + new URLSearchParams(queryParams) : "");

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result.elementi)
    }


    async function getStruttura(idStruttura) {
        const requestUrl = struttureServiceUrl + "/" + idStruttura;

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result)
    }


    /* Creazione/modifica di una struttura */
    async function editStruttura(struttura) {
        const requestUrl = struttureServiceUrl + "/" + struttura.id;

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "PUT",
            body: JSON.stringify(struttura)
        })
    }


    async function deleteStruttura(idStruttura) {
        const requestUrl = struttureServiceUrl + "/" + idStruttura;

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "DELETE"
        })
    }


    async function getCatalogoLink() {
        const requestUrl = cataloghiServiceUrl + "/link";

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result)
    }


    /* Query params: idAreaTerritoriale - ID dell'area territoriale del catalogo strutture da recuperare */
    async function getCatalogoStrutture(queryParams) {
        const requestUrl = cataloghiServiceUrl + "/strutture" + (queryParams ? "?" + new URLSearchParams(queryParams) : "");

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result)
    }


    /*
        Query params:
        filter - Filtri di ricerca delle aree territoriali da recuperare
        sort - Attributo di ordinamento delle aree territoriali da recuperare (Default value: nome)
        ascending - Flag per la tipologia di ordinamento delle aree territoriali da recuperare (Default value: true)
    */
    async function getAreeTerritoriali(queryParams) {
        const requestUrl = areeTerritorialiServiceUrl + (queryParams ? "?" + new URLSearchParams(queryParams) : "");

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result)
    }


    async function getAreaTerritoriale(idAreaTerritoriale) {
        const requestUrl = areeTerritorialiServiceUrl + "/" + idAreaTerritoriale;

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result)
    }


    /* Creazione/modifica di un'area territoriale */
    async function editAreaTerritoriale(areaTerritoriale) {
        const requestUrl = areeTerritorialiServiceUrl + "/" + areaTerritoriale.id

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "PUT",
            body: JSON.stringify(areaTerritoriale)
        }).catch(err => err)
    }

    /*  
        Query params:
        numeroPagina - Numero di pagina dei risultati paginati (Default value: 1)
        numeroElementi - Numero di elementi per pagina dei risultati paginati (Default value: 10)
    */
    async function getAllDisturbiAlimentari(idAreaTerritoriale) {
        const requestUrl = areeTerritorialiServiceUrl + "/" + idAreaTerritoriale + "/disturbialimentari?" + new URLSearchParams({ numeroPagina: 1, numeroElementi: 5000 });

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result)
    }

    async function creaDisturboAlimentare(payload, idAreaTerritoriale) {
        const requestUrl = areeTerritorialiServiceUrl + "/" + idAreaTerritoriale + "/disturbialimentari";

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "POST",
            body: JSON.stringify(payload),
        })
    }

    async function getDisturboAlimentare(idAreaTerritoriale, idDisturboAlimentare) {
        const requestUrl = areeTerritorialiServiceUrl + "/" + idAreaTerritoriale + "/disturbialimentari" + "/" + idDisturboAlimentare;

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result)
    }

    async function deleteDisturboAlimentare(idAreaTerritoriale, idDisturboAlimentare) {
        const requestUrl = areeTerritorialiServiceUrl + "/" + idAreaTerritoriale + "/disturbialimentari" + "/" + idDisturboAlimentare;

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: 'DELETE'
        }).catch(err => err)
    }

    async function editDisturboAlimentare(payload, idAreaTerritoriale, idDisturboAlimentare) {
        const requestUrl = areeTerritorialiServiceUrl + "/" + idAreaTerritoriale + "/disturbialimentari" + "/" + idDisturboAlimentare;

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: 'PUT',
            body: JSON.stringify(payload),
        }).catch(err => err)
    }

    async function getTempoAttesa() {
        const requestUrl = areeTerritorialiServiceUrl + "/" + session.idAreaTerritoriale + "/tempiattesa";

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result)
    }

    async function editTempoAttesa(payload) {
        const requestUrl = areeTerritorialiServiceUrl + "/" + session.idAreaTerritoriale + "/tempiattesa";

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "PUT",
            body: JSON.stringify(payload)
        }).then(result => result)
    }

    async function deleteTempoAttesa() {
        const requestUrl = areeTerritorialiServiceUrl + "/" + session.idAreaTerritoriale + "/tempiattesa";

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "DELETE"
        }).then(result => result)
    }

    async function getCatalogoLinkCovid() {
        const requestUrl = cataloghiServiceUrl + "/link/covid";

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result)
    }

    async function getAllStruttureCovid(idAreaTerritoriale) {
        const requestUrl = areeTerritorialiServiceUrl + "/" + idAreaTerritoriale + "/covid?" + new URLSearchParams({ numeroPagina: 1, numeroElementi: 5000 });

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result)
    }

    async function creaStrutturaCovid(idAreaTerritoriale, body) {
        const requestUrl = areeTerritorialiServiceUrl + "/" + idAreaTerritoriale + "/covid";

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "POST",
            body: JSON.stringify(body)
        }).then(result => result)
    }

    async function getStrutturaCovid(idAreaTerritoriale, idCovid) {
        const requestUrl = areeTerritorialiServiceUrl + "/" + idAreaTerritoriale + "/covid/" + idCovid;

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result)
    }

    async function modificaStrutturaCovid(idAreaTerritoriale, idCovid, body) {
        const requestUrl = areeTerritorialiServiceUrl + "/" + idAreaTerritoriale + "/covid/" + idCovid;

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "PUT",
            body: JSON.stringify(body)
        }).then(result => result)
    }

    async function eliminaStrutturaCovid(idAreaTerritoriale, idCovid) {
        const requestUrl = areeTerritorialiServiceUrl + "/" + idAreaTerritoriale + "/covid/" + idCovid;

        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "DELETE"
        }).then(result => result)
    }


    return Object.freeze({
        getStrutture, getStruttura, editStruttura, deleteStruttura,
        getCatalogoLink, getCatalogoStrutture,
        getAreeTerritoriali, getAreaTerritoriale, editAreaTerritoriale,
        getAllDisturbiAlimentari, creaDisturboAlimentare, getDisturboAlimentare,
        deleteDisturboAlimentare, editDisturboAlimentare,
        getTempoAttesa, editTempoAttesa, deleteTempoAttesa,
        getCatalogoLinkCovid, getAllStruttureCovid, creaStrutturaCovid, getStrutturaCovid, modificaStrutturaCovid, eliminaStrutturaCovid
    })
}