import React from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import UserLayout from './components/layout/UserLayout/UserLayout';
import InserimentoContenuto from './components/pages/InserimentoContenuto/InserimentoContenuto';
import HomePage from './components/pages/HomePage/HomePage';
import EditTemplate from './components/template/EditTemplate';
import EditMenu from './components/pages/EditMenu/EditMenu'
import Cestino from './components/pages/Cestino/Cestino'
import Store, { dismissAlert, scrollToTop } from "./hook/globalState/Store";
import Interceptor from "./components/Interceptor/Interceptor";
import EditHomepage from './components/pages/EditHomepage/EditHomepage';
import ErrorBoundary from "./components/pages/Error/ErrorBoundary";
import Error from "./components/pages/Error/Error";
import ContattiRegionali from './components/pages/UtentiRegionali/ContattiRegionali/ContattiRegionali';
import Redattori from "./components/pages/UtentiRegionali/Redattori/Redattori";
import StruttureRegionali from "./components/pages/UtentiRegionali/StruttureRegionali/StruttureRegionali";
import GestisciNews from "./components/pages/UtentiRegionali/GestisciNews/GestisciNews";
import AggiungiAssociazione from './components/pages/UtentiRegionali/AggiungiAssociazione/AggiungiAssociazione';
import AggiungiStrutturaCovid from './components/pages/UtentiRegionali/AggiungiStrutturaCovid/AggiungiStrutturaCovid';
import NewsPubblicazione from './components/pages/News/NewsPubblicazione/NewsPubblicazione';
import NewsScadenza from './components/pages/News/NewsScadenza/NewsScadenza';
import DatabaseRicercaCustomApp from './components/pages/ApplicazioneCustom/DatabaseRicercaCustomApp/DatabaseRicercaCustomApp';
import PrestazioniGarantite from './components/pages/ApplicazioneCustom/PrestazioniGarantite';
import ComeFarePer from './components/pages/ApplicazioneCustom/ComeFarePer/ComeFarePer';
import EditArgomentoGenerico from "./components/pages/EditArgomentoGenerico/EditArgomentoGenerico";
import EditPaginaErrore from "./components/pages/EditPaginaErrore/EditPaginaErrore";
import CollegamentiSocial from './components/pages/CollegamentiSocial/CollegamentiSocial';
import ContenutiTipologia from './components/pages/ContenutiTipologia/ContenutiTipologia';
import MotoreDiRicerca from "./components/pages/MotoreDiRicerca/MotoreDiRicerca";
import PageTable from './components/pages/ApplicazioneCustom/PageTable/PageTable';
import ProfiledRoute from './components/UI/ProfiledRoute/ProfiledRoute';
import AuthenticatedHome from './components/UI/ProfiledRoute/AuthenticatedHome';
import Authenticator from './sessionManager/Authenticator';
import { roleEnum } from './components/Utils/Dataset';
import GestioneRuoli from './components/pages/GestioneRuoli/GestioneRuoli';
import DisturbiAlimentari from './components/pages/UtentiRegionali/DisturbiAlimentari/DisturbiAlimentari';
import StruttureCovid from './components/pages/UtentiRegionali/StruttureCovid/StruttureCovid';
import TempiAttesa from './components/pages/UtentiRegionali/TempiAttesa/TempiAttesa';
import LeaRegionali from './components/pages/UtentiRegionali/LeaRegionali/LeaRegionali';
import NoRoleDetected from './components/pages/UnAuthorized/NoRoleDetected';
import ContenutiTipologiaRegione from './components/pages/UtentiRegionali/ContenutiTipologiaRegione/ContenutiTipologiaRegione';
import AbilitazioneMfa from './components/pages/AbilitazioneMfa/AbilitazioneMfa';
import AnagraficaStrutture from './components/pages/UtentiRegionali/AnagraficaStrutture/AnagraficaStrutture';
import CambioPassword from './components/pages/CambioPassword/CambioPassword';
import HomePageUtenteRegionale from './components/pages/UtentiRegionali/HomePageUtenteRegionale/HomePageUtenteRegionale';
import GestioneFeedback from './components/pages/GestioneFeedback/GestioneFeedback';

function App() {

    const location = useLocation();

    //ruoli
    const ammistratori = [roleEnum.AMMINISTRATORE];
    const regionali = [roleEnum.AUTORE, roleEnum.COLLABORATORE];
    const redazione = [roleEnum.AMMINISTRATORE, roleEnum.EDITORE];
    const allRoles = [...regionali, ...redazione];
    const autoriRegionali = [roleEnum.AUTORE];
    const autoriRegionaliAndRedazione = [...autoriRegionali, ...redazione];

    React.useEffect(() => {
        scrollToTop()
        dismissAlert()
    }, [location.pathname]);

    return (
        <Store>
            <ErrorBoundary>
                <Interceptor>
                    <div className="App">
                        <Authenticator>
                            <Switch>
                                <UserLayout>
                                    <Route exact path="/login-callback" render={(props) => <Redirect to={"/"} />} />
                                    <Route exact path="/logout-callback" render={(props) => <Redirect to={"/"} />} />
                                    <Route exact path="/error" render={(props) => <ProfiledRoute roles={allRoles}><Error title="Errore" /> </ProfiledRoute>} />
                                    <Route exact path="/home" render={(props) => <ProfiledRoute roles={redazione}><HomePage {...props} title="Home Backoffice" /></ProfiledRoute>} />
                                    <Route exact path="/error-auth" render={() => <NoRoleDetected />} />
                                    <Route exact path="/" render={() => <AuthenticatedHome />} />
                                    <Route exact path="/attivaMfa" render={() => <AbilitazioneMfa title="AbilitazioneMFA" />} />
                                    <Route exact path="/cambio-password" render={() => <CambioPassword title="Cambio password" />} />

                                    {/*Pagine per utenti regionali*/}
                                    <Route exact path="/home-regionale" render={(props) => <ProfiledRoute roles={regionali}><HomePageUtenteRegionale {...props} title="Home Backoffice" /></ProfiledRoute>} />
                                    <Route path="/contenuti-news" render={(props) => <ProfiledRoute roles={regionali}><ContenutiTipologiaRegione {...props} title="Contenuti News" /></ProfiledRoute>} />
                                    <Route path="/contatti-regionali" render={(props) => <ProfiledRoute roles={autoriRegionali}><ContattiRegionali {...props} title="Contatti Regionali" /></ProfiledRoute>} />
                                    <Route path="/redattori" render={(props) => <ProfiledRoute roles={autoriRegionali}><Redattori {...props} title="Redattori" /></ProfiledRoute>} />
                                    <Route path="/strutture-regionali" render={(props) => <ProfiledRoute roles={regionali}><StruttureRegionali {...props} title="Strutture Regionali" /></ProfiledRoute>} />
                                    {/* <Route path="/link-aziendali/:id" render={(props) => <ProfiledRoute roles={regionali}><LinkAziendali {...props} title="Link Aziendali" /></ProfiledRoute>} /> */}
                                    <Route path="/gestisci-news" render={(props) => <ProfiledRoute roles={regionali}><GestisciNews {...props} title="Gestisci News" /></ProfiledRoute>} />
                                    <Route path="/anagrafica-strutture/:id" render={(props) => <ProfiledRoute roles={regionali}><AnagraficaStrutture {...props} title="Anagrafica Strutture" /></ProfiledRoute>} />
                                    <Route path="/aggiungi-associazione" render={(props) => <ProfiledRoute roles={regionali}><AggiungiAssociazione {...props} title="Aggiungi associazione" /></ProfiledRoute>} />
                                    <Route path="/edit/associazione/:id_associazione" render={(props) => <ProfiledRoute roles={regionali}><AggiungiAssociazione {...props} title="Modifica associazione" /></ProfiledRoute>} />
                                    <Route path="/aggiungi-sito-covid" render={(props) => <ProfiledRoute roles={regionali}><AggiungiStrutturaCovid {...props} title="Aggiungi sito covid-19" /></ProfiledRoute>} />
                                    <Route path="/edit/sito-covid/:id_struttura_covid" render={(props) => <ProfiledRoute roles={regionali}><AggiungiStrutturaCovid {...props} title="Modifica sito covid-19" /></ProfiledRoute>} />
                                    <Route path="/associazioni" render={(props) => <ProfiledRoute roles={regionali}><DisturbiAlimentari {...props} title="Associazioni - Disturbi alimentari" /></ProfiledRoute>} />
                                    <Route path="/siti-covid" render={(props) => <ProfiledRoute roles={regionali}><StruttureCovid {...props} title="Siti Covid-19" /></ProfiledRoute>} />
                                    <Route path="/tempi-attesa" render={(props) => <ProfiledRoute roles={regionali}><TempiAttesa {...props} title="Tempi d'attesa - PRGLA" /></ProfiledRoute>} />
                                    <Route path="/lea-regionali" render={(props) => <ProfiledRoute roles={regionali}><LeaRegionali {...props} title="Lea regionali" /></ProfiledRoute>} />

                                    <Route path="/gestione-ruoli" render={(props) => <ProfiledRoute roles={ammistratori}><GestioneRuoli {...props} title={"Redattori"} /></ProfiledRoute>} />
                                    <Route path="/news/pubblicazione-programmata" render={(props) => <ProfiledRoute roles={redazione}><NewsPubblicazione {...props} title="News in pubblicazione" /></ProfiledRoute>} />
                                    <Route path="/news/scadenza-programmata" render={(props) => <ProfiledRoute roles={redazione}><NewsScadenza {...props} title="News in scadenza" /></ProfiledRoute>} />
                                    <Route path="/new/contenuto" render={(props) => <ProfiledRoute tipologia={props.match.params.tipologia} roles={redazione}><InserimentoContenuto {...props} title="Nuovo contenuto" /></ProfiledRoute>} /> {/* TODO: da verificare per l'inserimento di contenuti di un utente regionali */}
                                    {/* <Route path="/login" render={(props) => <Login {...props} title="Accesso" />} /> */}

                                    <Route path="/contenuti/:tipologia" render={(props) => <ProfiledRoute roles={allRoles}><ContenutiTipologia {...props} title="Contenuti tipologia" tipologia={props.match.params.tipologia} /></ProfiledRoute>} />
                                    {/* <Route path="/edit/footer/:id_argomento_generico" render={(props) => <ProfiledRoute roles={redazione}><EditArgomentoGenerico {...props} title="Modifica Argomento Generico" /></ProfiledRoute>} /> */}
                                    <Route path="/edit/contenutoredirect/:id_contenuto" render={(props) => { return <Redirect to={"/edit/contenuto/" + props.match.params.id_contenuto} /> }} />
                                    <Route path="/edit/contenuto/:id_contenuto" render={(props) => <ProfiledRoute roles={allRoles}><EditTemplate {...props} title="Edit Template" /></ProfiledRoute>} />
                                    <Route path="/applicazioni-custom/redirect/:lingua" render={(props) => { return <Redirect to={("/applicazioni-custom/prestazioni-garantite-ssn/" + props.match.params.lingua)} /> }} />
                                    <Route path="/edit/menu/:title" render={(props) => <ProfiledRoute roles={redazione}><EditMenu {...props} title={props.match.params.title} /></ProfiledRoute>} />
                                    <Route path="/edit/homepage" render={(props) => <ProfiledRoute roles={redazione}><EditHomepage {...props} title={"Home page"} /></ProfiledRoute>} />
                                    <Route path="/edit/errore/:id_pagina_errore" render={(props) => <ProfiledRoute roles={redazione}><EditPaginaErrore {...props} title={"Modifica pagina di errore"} /></ProfiledRoute>} />
                                    <Route path="/gestione-feedback" render={(props) => <ProfiledRoute roles={redazione}><GestioneFeedback {...props} title={"Gestione feedback"} /></ProfiledRoute>} />

                                    <Route path="/cestino" render={(props) => <ProfiledRoute roles={autoriRegionaliAndRedazione}><Cestino {...props} title="Cestino" /></ProfiledRoute>} />
                                    <Route path="/motorediricerca" render={(props) => <ProfiledRoute roles={allRoles}><MotoreDiRicerca {...props} title="MotoreDiRicerca" /></ProfiledRoute>} />

                                    <Route path="/collegamenti-social" render={(props) => <ProfiledRoute roles={redazione}><CollegamentiSocial {...props} title="Collegamenti ai social" /></ProfiledRoute>} />

                                    <Route exact path="/applicazioni-custom/prestazioni-garantite-ssn/:lingua" render={(props) => <ProfiledRoute roles={allRoles}><PrestazioniGarantite {...props} title="Applicazioni custom" /></ProfiledRoute>} />
                                    <Route exact path="/applicazioni-custom/prestazioni-garantite-ssn/:lingua/:tipoLea/:id" render={(props) => <ProfiledRoute roles={allRoles}><DatabaseRicercaCustomApp {...props} title={"Prestazioni garantite SSN"} tipoLea={props.match.params.tipoLea} id={props.match.params.id} /></ProfiledRoute>} />
                                    <Route exact path="/applicazioni-custom/prestazioni-garantite-ssn/:lingua/:tipoLea/:id/table" render={(props) => <ProfiledRoute roles={allRoles}><PageTable {...props} title="Applicazioni custom" tipoLea={props.match.params.tipoLea} idCategoria={props.match.params.id} /></ProfiledRoute>} />

                                    <Route exact path="/applicazioni-custom/come-fare-per" render={(props) => <ProfiledRoute roles={redazione}><ComeFarePer {...props} title="Applicazioni custom" /></ProfiledRoute>} />
                                </UserLayout>
                            </Switch>
                        </Authenticator>
                    </div>
                </Interceptor>
            </ErrorBoundary>
        </Store >
    );
}

export default App;
