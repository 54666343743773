import { useEffect, useState } from "react";
import { useRepositoryFunction } from "../../../hook/useRepository";
import ContenutiRepository from '../../../Repo/ContenutiRepository';
import LeaRepository from "../../../Repo/LeaRepository";
import StruttureRepository from '../../../Repo/StruttureRepository';
import { linguaEnum, maxElementi, tipologiaEnum } from "../../Utils/Dataset";

export function usePortale() {
    return useRepositoryFunction(ContenutiRepository().getPortale);
}

export function useContatti() {
    return useRepositoryFunction(ContenutiRepository().getContatti);
}

export function useCatalogoLink() {
    return useRepositoryFunction(StruttureRepository().getCatalogoLink);
}

export function useCatalogoLinkCovid() {
    return useRepositoryFunction(StruttureRepository().getCatalogoLinkCovid);
}

export function useTuttiContenuti() {
    return useRepositoryFunction(ContenutiRepository().getTuttiContenuti, { lingua: linguaEnum.ITALIANO, notInStato: "ELIMINATO" }, { numeroElementi: maxElementi, ascending: "false", sort: "dataInserimento" })
}

export function useContenutiOrdinati(filters, queryParams) {
    return useRepositoryFunction(ContenutiRepository().getContenutiOrdinati, filters, queryParams);
}

export function useAllNote() {
    return useRepositoryFunction(LeaRepository().getAllNote);
}

export function useAllBranche() {
    return useRepositoryFunction(LeaRepository().getAllBranche);
}

export function useApplicazioniCustom() {
    const [result, setResult] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState()
    useEffect(() => {
        if (!loaded) {
            Promise.all([
                ContenutiRepository().getContenutiOrdinati({ tipologia: tipologiaEnum.APPLICAZIONE_STRUTTURE }, { ascending: "false", sort: "dataInserimento", numeroElementi: maxElementi }),
                ContenutiRepository().getContenutiOrdinati({ tipologia: tipologiaEnum.APPLICAZIONE_LEA }, { ascending: "false", sort: "dataInserimento", numeroElementi: maxElementi })
            ])
                .then(([res1, res2]) => {
                    setResult([...res1, ...res2]);
                    setLoaded(true);
                    setError(null)
                })
                .catch(err => setError(err))
        }
    }, [loaded]);
    if (error == null) {
        return [result, loaded, setResult, setLoaded];
    } else throw error
}

export function useApplicazioniCustomApprofondimenti() {
    const [result, setResult] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState()
    useEffect(() => {
        if (!loaded) {
            Promise.all([
                ContenutiRepository().getContenutiOrdinati({ tipologia: tipologiaEnum.APPLICAZIONE_STRUTTURE }, { ascending: "false", sort: "dataInserimento", numeroElementi: maxElementi }),
                ContenutiRepository().getContenutiOrdinati({ tipologia: tipologiaEnum.APPLICAZIONE_LEA }, { ascending: "false", sort: "dataInserimento", numeroElementi: maxElementi }),
                ContenutiRepository().getContenutiOrdinati({ tipologia: tipologiaEnum.APPLICAZIONE_STRUTTURE_COVID }, { ascending: "false", sort: "dataInserimento", numeroElementi: maxElementi })
            ])
                .then(([res1, res2, res3]) => {
                    setResult([...res1, ...res2, ...res3]);
                    setLoaded(true);
                    setError(null)
                })
                .catch(err => setError(err))
        }
    }, [loaded]);
    if (error == null) {
        return [result, loaded, setResult, setLoaded];
    } else throw error
}

export function useApplicazioniCustomCovid() {
    const [result, setResult] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState()
    useEffect(() => {
        if (!loaded) {
            Promise.all([
                ContenutiRepository().getContenutiOrdinati({ tipologia: tipologiaEnum.APPLICAZIONE_STRUTTURE_COVID }, { ascending: "false", sort: "dataInserimento", numeroElementi: maxElementi })
            ])
                .then(([res1]) => {
                    setResult([...res1]);
                    setLoaded(true);
                    setError(null)
                })
                .catch(err => setError(err))
        }
    }, [loaded]);
    if (error == null) {
        return [result, loaded, setResult, setLoaded];
    } else throw error
}
