import React from 'react';
import './OrganizzazioneAS.css';
import CustomInput from '../../../../UI/CustomInput/CustomInput';
import { validateUrlNotRequired } from '../../../../UI/FieldValidation/FieldValidation';

export default function OrganizzazioneAS(props) {

    return (
        <div id="organizzazioneAS">
            <h5 className="headerTitle">Organizzazione</h5>
            <CustomInput id="input1" labelName="Direzione generale - Direzione amministrativa - Direzione sanitaria" change={(e) => props.updateOrganizzazione(0, "direzione", e.target.value)} name={props.organizzazione[0]?.direzione} valid={true} invalidMsg={!validateUrlNotRequired(props.organizzazione[0]?.direzione) && "Inserire un sito valido che inizi con http/https"} />
            <CustomInput id="input2" labelName="Nominativi Primari di Reparto" change={(e) => props.updateOrganizzazione(0, "primari", e.target.value)} name={props.organizzazione[0]?.primari} valid={true} invalidMsg={!validateUrlNotRequired(props.organizzazione[0]?.primari) && "Inserire un sito valido che inizi con http/https"} />
            {/* <CustomInput id="input3" labelName="Direzione sanitaria" name={props.asl.direzioneSanitaria} valid={true} /> */}
            {/* <div className="row">
                <div className="col-6">
                    <CustomInput id="input4" labelName="Nominativi Primari di Reparto" name={props.asl.nominativi} valid={true} />
                </div>
                <div className="col-6">
                    <CustomInput id="input5" name={props.asl.ruolo} valid={true} />
                </div>
            </div> */}
        </div>
    )
}