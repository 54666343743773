import React from 'react';
import { Toggle } from 'design-react-kit';
import './SocialInput.css';

export default function SocialInput(props) {

    return (
        <div id="socialInput">
            <div className="row">
                <div className="col-6">
                    <label className={"fieldLabel"} htmlFor={props.id}>{props.label}</label>
                </div>
                <div className="col-6">
                    <div className="switch">
                        <Toggle onChange={props.changeToggle} checked={props.toggleValue} label={props.toggleName ? "visibile" : "non visibile"} />
                    </div>
                </div>
                <input id={props.id} type="text" className="form-control" value={props.name ? props.name : ""} onChange={(e) => props.change(e)} />
                {props.invalidLink && <p className="invalid-link"><i>Inserire un link valido che inizi con http/https</i></p>}
            </div>
        </div>
    )
}