import { alertLevels } from "../components/UI/InPageAlert/InPageAlert";
import { KO_OPERATION } from "../components/Utils/Dataset";
import { operationId } from '../components/Utils/Dataset';

export function getEsitoOperazione(codRet) {
    switch (codRet) {
        case "001":
            return "Operazione eseguita"
        case "010":
            return "Operazione eseguita con avvisi"
        case "100":
            return "Operazione non eseguita"
        default:
            return "?"
    }
}

export function getSeveritaOperazione(severita) {
    switch (severita) {
        case "S001":
            return alertLevels.INFO
        case "S010":
            return alertLevels.WARN
        case "S100":
            return alertLevels.ERROR
        default:
            return alertLevels.ERROR
    }
}

export function getMessaggio504() {
    return "Impossibile completare l'operazione in questo momento. Riprovare più tardi. Se l'errore persiste contattare l'amministratore.";
}

export function getMessaggioOperazione(errore, esito) {
    switch (errore?.codMsg) {
        case "OK":
            return "Ok"
        //Messaggi di errore di MSCMS
        //TODO - Da rivedere gli esiti con più parametri
        case "PATERNITA_VIOLATA":
            if (esito?.operationId === operationId.ELIMINA_LOGICAMENTE_CONENUTO)
                return "Non è possibile eliminare questo contenuto perché ad esso sono legati dei dettagli (" + errore?.parametri?.filter(el => el.tipo === "valore")[1]?.valore + "). Eliminare i dettagli per poi procedere con l'operazione."
            if (esito?.operationId === operationId.PUBBLICA_CONTENUTO)
                return "Il CONTENUTO PADRE a cui si vuole legare questo dettaglio NON è stato ancora pubblicato. Pubblicare il contenuto padre per poi procedere con l'operazione."
            return "Paternità violata"
        case "RIFERIMENTO_CTA_VIOLATO":
            if (esito?.operationId === operationId.ELIMINA_LOGICAMENTE_CONENUTO)
                return "Questo contenuto viene utilizzato come link ad Applicazione Custom da un altro contenuto. Rimuovere tutti i link ad Applicazione Custom associati a questo contenuto per poter procedere con l'eliminazione."
            if (esito?.operationId === operationId.PUBBLICA_CONTENUTO)
                return "Il link ad Applicazione Custom non è stato ancora pubblicato. Pubblicare il link ad Applicazione Custom associato a questo contenuto per poter procedere con la pubblicazione."
            if (esito?.operationId === operationId.SOSPENDI_CONTENUTO)
                return "Questo contenuto viene utilizzato come link ad Applicazione Custom da un altro contenuto. Rimuovere tutti i link ad Applicazione Custom associati a questo contenuto per poter procedere con la sospensione."
            return "Riferimento CTA violato"
        case "RIFERIMENTO_HOMEPAGE_VIOLATO":
            if (esito?.operationId === operationId.SOSPENDI_CONTENUTO)
                return "Questo contenuto é presente nella homepage. Ricordati di riorganizzare la visualizzazione della home page."
            if (esito?.operationId === operationId.ELIMINA_LOGICAMENTE_CONENUTO)
                return "Questo contenuto non può essere eliminato perché presente nella homepage. È necessario rimuovere l'elemento dalla homepage prima di procedere con l'eliminazione."
        case "RIFERIMENTO_LINGUISTICO_VIOLATO":
            if (esito?.operationId === operationId.CREA_CONTENUTO)
                return "Non esiste un contenuto associato in altra lingua."
            if (esito?.operationId === operationId.ELIMINA_LOGICAMENTE_CONENUTO)
                return "È necessario eliminare le versioni del contenuto nelle altre lingue (" + errore?.parametri?.filter(el => el.tipo === "valore")?.map(el => el.valore) + ") prima di procedere con l'eliminazione."
            if (esito?.operationId === operationId.RIPRISTINA_CONTENUTO_ELIMINATO_LOGICAMENTE)
                return "Esistono già dei contenuti associati in altra lingua."
            return "Riferimento linguistico violato"
        case "RIFERIMENTO_VIOLATO":
            return "Il CONTENUTO PADRE non esiste o non è stato specificato, nel campo CONTENUTO PADRE associare il dettaglio al contenuto al quale si vuole fare riferimento."
        case "STATO_INCOMPATIBILE":
            return "Lo stato del contenuto è incompatibile con l'operazione eseguita."
        case "TEMPLATE_INCOMPATIBILE":
            return "Il template del contenuto è incompatibile con l'operazione eseguita."
        case "UNIVOCITA_VIOLATA":
            if (esito?.operationId === operationId.CREA_CATALOGO)
                return "Non possono esistere due cataloghi con lo stesso valore nel campo: " + errore?.parametri?.filter(el => el.tipo === "valore")?.map(el => el.valore)
            if (esito?.operationId === operationId.MODIFICA_CONTENUTO || esito?.operationId === operationId.CREA_CONTENUTO)
                return "Non possono esistere due contenuti con lo stesso valore nel campo: " + errore?.parametri?.filter(el => el.tipo === "valore")?.map(el => el.valore)
            return "Univocità violata"
        case "VERSIONE_INCOMPATIBILE":
            return "La versione del contenuto (" + errore?.parametri?.filter(el => el.tipo === "valore")?.map(el => el.valore) + ") è incompatibile con l'operazione selezionata."
        case "VERSIONE_OBSOLETA":
            if (esito?.operationId === operationId.MODIFICA_CONTENUTO)
                return "Il contenuto è stato modificato dopo l'apertura, probabilmente da un altro utente, ricaricare il contenuto per recuperare la versione più aggiornata e provare ad effettuare nuovamente le modifiche."
            if (esito?.operationId === operationId.MODIFICA_HOME_PAGE)
                return "L'Homepage è stata modificata dopo l'apertura, probabilmente da un altro utente, ricaricare il contenuto per recuperare la versione più aggiornata e provare ad effettuare nuovamente le modifiche."
            if (esito?.operationId === operationId.MODIFICA_MENU)
                return "Il Menu è stato modificato dopo l'apertura, probabilmente da un altro utente, ricaricare il contenuto per recuperare la versione più aggiornata e provare ad effettuare nuovamente le modifiche."
            return "Versione obsoleta"
        case "VINCOLO_VIOLATO":
            if (esito?.operationId === operationId.ELIMINA_CATALOGO)
                return "Il catalogo è associato ad altri contenuti: " + errore?.parametri?.filter(el => el.tipo === "valore")?.map(el => el.valore) + ". Rimuovere l'associazione e riprovare con l'eliminazione"
            if (esito?.operationId === operationId.MODIFICA_CONTENUTO)
                return "Non è possibile procedere con la modifica in quanto una " + errore?.parametri?.filter(el => el.tipo === "valore")?.map(el => el.valore) + " selezionata non è più presente nel catalogo. Ricaricare il contenuto e provare a rieffettuare le modifiche."
            if (esito?.operationId === operationId.PUBBLICA_CONTENUTO)
                return "Non è possibile procedere con la pubblicazione in quanto una fonte o una categoria selezionate non sono più presenti nel catalogo. Ricaricare il contenuto e provare a rieffettuare le modifiche."
            if (esito?.operationId === operationId.MODIFICA_HOME_PAGE)
                return "Uno o più contenuti presenti nella homepage non sono pubblicati: " + errore?.parametri?.filter(el => el.tipo === "valore")?.map(el => el.valore) + ". Si prega di eliminarli dalla visualizzazione della homepage, o di pubblicarli, per poi procedere con la modifica."
            if (esito?.operationId === operationId.PUBBLICA_HOME_PAGE)
                return "Uno o più contenuti presenti nella homepage non sono pubblicati: " + errore?.parametri?.filter(el => el.tipo === "valore")?.map(el => el.valore) + ". Si prega di eliminarli dalla visualizzazione della homepage, o di pubblicarli, per poi procedere con l'operazione."
            if (esito?.operationId === operationId.MODIFICA_MENU)
                return "Uno o più contenuti presenti nel menu non sono pubblicati: " + errore?.parametri?.filter(el => el.tipo === "valore")?.map(el => el.valore) + ". Si prega di pubblicarli, per poi procedere con la modifica."
            if (esito?.operationId === operationId.PUBBLICA_MENU)
                return "Uno o più contenuti presenti nel menu non sono pubblicati: " + errore?.parametri?.filter(el => el.tipo === "valore")?.map(el => el.valore) + ". Si prega di pubblicarli, per poi procedere con l'operazione."
            //Messaggio errore strutture
            if (esito?.operationId === operationId.MODIFICA_CREA_STRUTTURA)
                return "Impossibile creare o modificare la struttura, l'area territoriale non è stata riconosciuta"
            //Messaggi errore LEA
            if (esito?.operationId === operationId.MODIFICA_AUSILIO_MONOUSO)
                return "La quantità erogabile scelta non è più disponibile. Ricaricare il contenuto per recuperare la versione più aggiornata e provare a rieffettuare le modifiche."
            if (esito?.operationId === operationId.MODIFICA_PRESTAZIONE)
                return "La branca o la nota scelta non sono più disponibili. Ricaricare il contenuto per recuperare la versione più aggiornata e provare a rieffettuare le modifiche."
            return "Vincolo violato"
        case 'INIDICIZZAZIONE_IN_ESECUZIONE':
            return "Un processo di indicizzazione è già in esecuzione"
        case 'ERRORE_SINCRONIZZAZIONE_TROVASTRUTTURE':
            return "Impossibile completare l'operazione a causa di un problema di sincronizzazione con il TrovaStrutture. Riprovare più tardi o contattare l'amministratore."
        default:
            return KO_OPERATION
    }
}

export function getMessaggioOperazioneAPMS(err) {
    switch (err?.errore) {
        case "Nuova password uguale a vecchia password":
            return "La nuova password inserita è uguale a quella corrente. Si prega di inserire una password diversa da quella in uso."
        default:
            return err?.errore
    }
}

export function getMessaggioOperazioneMSRicerca(errorMessage) {
    switch (errorMessage) {
        case "KO_DATI_SITO_MANCANTI":
            return "Errore: dati di uno o più siti mancanti"
        case "KO_SITO_CENSITO":
        case "KO_SITO_DUPLICATO":
        case "KO_PROTOCOLLO_SITO_MANCANTE":
            return "Errore: uno o più siti duplicati"
        default:
            return KO_OPERATION
    }
}

export function getEsito(messaggio, data) {
    if (messaggio == "OK") {
        return data;
    }
    else {
        return getMessaggioOperazione(messaggio)
    }
}


export function mapGetSiti(data) {
    let res = [];
    if (data) {
        data.forEach(function (el) {
            let element = {}
            element["idSito"] = el.idSito
            element["sito"] = el.sito
            element["titolo"] = el.titolo
            element["utenteModifica"] = el.utenteModifica
            element["modifica"] = false
            res.push(element);
        })
    }
    return res
}

export function mapGetCategorieFiltriFromBE(result) {
    let res = [];
    if (result) {
        result.forEach(function (el) {
            let element = {}
            element["idCategoria"] = el.idCategoria
            element["etichetta"] = "Filtri-" + el.descrizione
            element["posizione"] = el.posizione
            element["visibile"] = el.attiva
            element["index"] = el.idCategoria
            let filtri = el.filtri
            let sottovoci = []
            filtri.forEach(function (el) {
                let filtro = {}
                filtro["index"] = el.id
                filtro["idContenuto"] = "Contenuto" + el.id
                filtro["etichetta"] = el.descrizione
                filtro["categoria"] = el.categoria
                filtro["posizione"] = el.posizione
                filtro["visibile"] = el.attivo
                filtro["tipologiaPerRicerca"] = el.tipologiaPerRicerca
                sottovoci.push(filtro)
            })
            element["sottovoci"] = sottovoci
            res.push(element);
        })
    }
    return res
}
export function mapGetCategorieFiltriForBE(categorie) {
    let res = [];
    if (categorie) {
        categorie.forEach(function (el) {
            let element = {}
            element["idCategoria"] = el.idCategoria
            element["descrizione"] = el.etichetta.replace('Filtri-', '')
            element["posizione"] = el.posizione
            element["attiva"] = el.visibile
            let sottovoci = el.sottovoci
            let filtri = []
            sottovoci.forEach(function (el) {
                let filtro = {}
                filtro["idFiltro"] = el.idContenuto.replace('Contenuto', '')
                filtro["descrizione"] = el.etichetta
                filtro["categoria"] = el.categoria
                filtro["posizione"] = el.posizione
                filtro["attivo"] = el.visibile
                filtro["tipologiaPerRicerca"] = el.tipologiaPerRicerca
                filtri.push(filtro)
            })
            element["filtri"] = filtri
            res.push(element);
        })
    }
    return res
}

export function mapGetSitiForBE(siti, sitiModifica, sitiElimina) {
    if (siti) {
        let element = {
            sitiInserimento: [],
            sitiModifica,
            sitiElimina
        }

        siti.forEach(function (el) {
            let sito = {}
            sito["sito"] = el.sito
            sito["titolo"] = el.titolo
            sito["stato"] = el.stato
            element.sitiInserimento.push(sito)
            // let sitiElimina=[]

        })

        return element

    }

}
