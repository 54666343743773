import React from 'react';
import './NewsPublisher.css';
import LabelDateTime from './LabelDateTime/LabelDateTime';
import { Button } from "design-react-kit";
import { Link } from "react-router-dom";

export default function NewsPublisher(props) {

    return (
        <div className="newsPublisher">
            <div className="row">
                <label className="label-name"><b>Nome news:</b> {props.nomeContenuto}</label>
            </div>
            <div className="row">
                {props.image &&
                    <div className={props.edit ? "col-5" : "col-5"}>
                        {props.image?.endsWith(null) ?
                            <></> :
                            <div className="card-image">
                                <img alt="" className="" src={props.image} />
                            </div>}
                    </div>}
                {props.cardTitle &&
                    <div className={props.edit ? "col-5" : "col-7"}>
                        <div className="card">
                            <div className="title">
                                <div className="form-check form-check-inline">
                                    <label>{props.cardTitle}</label>
                                </div>
                            </div>
                            <div className="input">
                                <div className="container">
                                    <LabelDateTime date={props.date} time={props.time} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {props.edit && props.editPath &&
                    <div id="editNews" className={'col-2 mt-4'}>
                        <Link to={{ pathname: props.editPath }}>
                            <Button id="btnAggiungiNews" color={"secondary"} tag="button">Modifica</Button>
                        </Link>
                    </div>
                }
            </div>
        </div>
    )
}
