import { Icon } from 'design-react-kit';
import React from 'react';
import SimpleFileLoader from '../SimpleFileLoader/SimpleFileLoader';
import { tipologiaEnum } from '../Utils/Dataset';
import { uploadAllegato } from '../Utils/Utils';

//Gestione Allegati
export const uploadFile = (file, index, updateAttachment) => uploadAllegato(file, index, updateAttachment)
export const attachmentLoader = (index, onChangeCurrentAttachment, updateAttachment) => (<SimpleFileLoader
    loaderLayout={(
        <label><Icon color="black" icon="it-clip" id="attach" /><span>Allega</span></label>
    )}
    change={(e) => onChangeCurrentAttachment(e, (file, fileMetadata) => uploadFile(file, index, updateAttachment))}
    idLoad={"uploadAttachment" + index}
/>)
export const addNewAttachmentRow = (addAttachment) =>
(<div className="row align-items-center" id="addRow">
    <span className="col-auto" onClick={(e) => { addAttachment() }} style={{ fontSize: "0.8rem", fontWeight: "bold", cursor: "pointer" }}><Icon className="left mr-2" color="black" icon="it-plus-circle" id="addNewRowIcon" />Aggiungi nuovo</span>
</div>)

export const isTipologiaNewsOrFakeNews = (tipologia) => {
    if (tipologia === tipologiaEnum.NEWS || tipologia === tipologiaEnum.FAKE_NEWS) return true;
    else return false;
}