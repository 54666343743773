import React from 'react';
import CustomInput from '../../../UI/CustomInput/CustomInput';
import { Toggle, Icon } from 'design-react-kit';
import './StrutturaCovidForm.css';

export default function StrutturaCovidForm(props) {

    return (
        <div id="strutturaCovidForm">
            <div>
                <label>Nuovo sito covid-19</label>
                <hr></hr>
            </div>
            <div className="row">
                <div className="col-12">
                    <Toggle label={props.content.visibile ? "Visibile" : "Non visibile"} checked={props.content.visibile} onChange={props.content.toggle} />
                </div>
            </div>
            <div className="container">
                <CustomInput id="input1" labelName="Nominativo*" change={props.content.nominativoChangedHandler} name={props.content.nominativo} valid={props.content.validateNominativo} />
                <CustomInput id="input12" infoMsg={"Deve iniziare con http:// o https://"} placeholder={"https://"} labelName="Indirizzo Web*" change={props.content.indirizzoWebChangedHandler} name={props.content.indirizzoWeb} valid={props.content.validateIndirizzoWeb} />
                <p className="info">*Campi obbligatori</p>
            </div>
        </div>
    )
}