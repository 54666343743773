import React, { useState, useEffect, useContext } from "react";
import { FormGroup, Label, Input, Button, Icon } from 'design-react-kit';
// import ContenutiRepository from '../../../Repo/ContenutiRepository';
import './Radio.css';
import InfoModal from "../InfoModal/InfoModal";
import { Context, showError, showSuccess } from "../../../hook/globalState/Store";
import { KO_FONTE, KO_FONTE_ALTRO_CONTENUTO, OK_OPERATION, operazioniContenuto, roleEnum, tipologiaEnum } from "../../Utils/Dataset";
import ContenutiRepository from '../../../Repo/ContenutiRepository';
import CustomModal from "../../CustomModal/CustomModal";
import useModal from "../../../hook/useModal";
import { Messages } from "../../Utils/Messages";

/**
 *  PROPS:
 *      - id: mandatory!
 *      - value: suggested to set a default value with useRadio()
 *      - inline: if set, displays the options on the same line
 *      - disabled: disable every option
 *      - title: title of the section
 *      - options: options to render {label, value}
 *      - dynamycOptions: renders an input to update the options list
 *
 *  E.G:
 *  const [source, setSource, changeSource] = useRadio("00")
 *  <Radio disabled inline id={"sourceRadio"} value={source} change={changeSource} options={[{"label":"OPT1", "value":"00"},{label:"OPT2", "value":"01"},{label:"OPT3", "value":"03"},{label:"OPT4", "value":"04"}]}  />
 */

export default function Radio(props) {

    if (props.id === undefined || props.id?.length === 0)
        throw new Error("Radio component must have id prop")

    const [show, , handleClose, handleShow] = useModal(false);
    const [state, dispatch] = useContext(Context);
    const [currentSelection, setCurrentSelection] = useState();
    const [currentId, setCurrentId] = useState();

    return (
        <fieldset id={props.id}>
            <div className="Radio">
                <div>
                    {props.title &&
                        (<>
                            <label className={"fieldLabel"}> {props.title} </label>
                            {props.infoMessage && (props.ruoloUtente === roleEnum.AMMINISTRATORE) && <InfoModal message={props.infoMessage} />}
                        </>)
                    }
                </div>
                {props.dynamicOptions && props.ruoloUtente === roleEnum.AMMINISTRATORE &&
                    <div className={"customInput"}>
                        <input id="radioInput" type="text"
                            value={props.radioButtonValue}
                            onChange={props.changeRadioButton}
                            className={!props.options.length ? 'is-danger' : ''}
                        />
                        <Button id={"saveButton"} color={"secondary"} tag="button" disabled={!props.radioButtonValue ? true : false} onClick={(event) => {
                            if (props.radioButtonValue) {
                                event.preventDefault()
                                props.addFonteHandler(props.radioButtonValue)
                                props.changeRadioButton({ target: { value: "" } }) //oggetto artificiale per resettare il valore
                            }
                        }}>
                            {"Aggiungi"}
                        </Button>
                    </div>
                }
                {props.error ? <p>{props.errorMsg}</p> : null}
                {props.options?.map((el, index) => (
                    (
                        <FormGroup
                            check
                            className="elements"
                            tag="div"
                            inline={true}
                            key={el.descrizione + index}
                        >
                            <Input
                                checked={props.value === el.descrizione}// || !props.value && index === 0}
                                id={`${props.id}${index}`}
                                name={props.id}
                                type="radio"
                                onChange={() => props.change(el.value)}
                                disabled={props.disabled}
                            />
                            <Label
                                for={`${props.id}${index}`}
                                tag="label"
                                widths={['xs', 'sm', 'md', 'lg', 'xl']}
                            >
                                {el.descrizione}
                            </Label>
                            {!(props.notDelete) && props.ruoloUtente === roleEnum.AMMINISTRATORE &&
                                <button className={"delete_tag"} onClick={(e) => getModal(el.descrizione, handleShow, setCurrentSelection, el.id, setCurrentId)}>
                                    <Icon className="left" color="black" icon="it-close" id="delete" />
                                </button>
                            }
                        </FormGroup>

                    )
                ))}
                <CustomModal
                    show={show}
                    onHide={handleClose}
                    onConfirm={() => callBackAndClose(currentSelection, currentId, true, props.value, handleClose, dispatch, props.deleteFonteHandler)}
                    title={operazioniContenuto.ELIMINAZIONE + " fonte in corso"}
                    text={Messages.DELETE_FONTE}
                />
            </div>
        </fieldset>
    )
}

function getModal(descrizione, handleShow, setCurrentSelection, id, setCurrentId) {
    setCurrentSelection(descrizione)
    setCurrentId(id)
    handleShow();
}

async function callBackAndClose(currentSelection, currentId, checkFonti, value, handleClose, dispatch, deleteFonteHandler) {

    if (checkFonti) {
        Promise.all([ContenutiRepository().getContenutiOrdinati({ tipologia: tipologiaEnum.NEWS }, { ascending: "false", sort: "dataInserimento" }),
        ContenutiRepository().getContenutiOrdinati({ tipologia: tipologiaEnum.FAKE_NEWS }, { ascending: "false", sort: "dataInserimento" })])
            .then(([result1, result2]) => {
                let mergedResult = [...result1, ...result2];
                let success = true;
                mergedResult.forEach(el => {
                    if (el?.fonte === currentSelection) {
                        success = false;
                        return;
                    }
                });
                if (success) {
                    if (value === currentSelection) {
                        showError(KO_FONTE)
                    } else {
                        if (currentId) {
                            deleteFonteHandler(currentId)
                        }
                    }
                } else {
                    showError(KO_FONTE_ALTRO_CONTENUTO)
                }

            })
        handleClose();
    }

}




