export const SERVICE_URL = process.env.REACT_APP_SERVICE_URL
export const RICERCA_URL = process.env.REACT_APP_RICERCA_URL
export const DAM_URL = process.env.REACT_APP_DAM_URL
export const BUCKET_URL = process.env.REACT_APP_BUCKET_URL
export const LEA_URL = process.env.REACT_APP_LEA_URL
export const STRUTTURE_URL = process.env.REACT_APP_STRUTTURE_URL
export const WAFE_PREVIEW_URL = process.env.REACT_APP_WAFE_PREVIEW
export const APMS_URL = process.env.REACT_APP_APMS_URL
export const APMS2_URL = process.env.REACT_APP_APMS2_URL
export const FEEDBACK_URL = process.env.REACT_APP_FEEDBACK_URL
export const MFACHECK_ENABLED = (process.env.REACT_APP_MFACHECK_ENABLED === "true")
export const PWD_EXP_WARNING_DAYS = process.env.REACT_APP_PWD_EXP_WARNING_DAYS
export const PWD_EXP_CRITICAL_DAYS = process.env.REACT_APP_PWD_EXP_CRITICAL_DAYS
export const PWD_EXP_MAX_DAYS = process.env.REACT_APP_PWD_EXP_MAX_DAYS

export const auth_debug_log_enable = false;

export const USERHEADERS = { //usato solo per sviluppo
    "AZUser": "id=redazione;name=Redazione;surname=Centrale;roles=PT-AMM",
    "AZContext": "sessionid=1234;applicationid=SPABOPT;operationid=xxxxx"
}
export const REPHEADERS = { //usato solo per sviluppo
    "AZUser": "id=BCKTNT20A01H501F;name=Utente;surname=Backoffice;authenticationinfo=xxxx;roles=PT-AMM",
    "AZContext": "sessionid=1234;applicationid=SPABOPT;operationid=xxxxx"
};
export const REPHEADERSLEA = { //usato solo per sviluppo
    "AZUser": "id=BCKTNT20A01H501F;name=Utente;surname=Backoffice;authenticationinfo=xxxx;roles=PT-AUT",
    "AZContext": "sessionid=1234;applicationid=SPABOPT;operationid=xxxxx"
};

export const FILEREPHEADERS = { //usato solo per sviluppo
    "AZUser": "id=BCKTNT20A01H501F;name=Utente;surname=Backoffice;authenticationinfo=xxxx;roles=PT-AMM",
    "AZContext": "sessionid=1234;applicationid=SPABOPT;operationid=xxxxx"
};

export const STRUTTUREREPHEADERS = { //usato solo per sviluppo
    "AZUser": "id=BCKTNT20A01H501F;name=Utente;surname=Backoffice;authenticationinfo=xxxx;roles=PT-AUT",
    "AZContext": "sessionid=1234;applicationid=SPABOPT;operationid=xxxxx"
}

export const globals = {
    "app-application-name": "PT.BackOffice",
    "app-id-applicazione": "SPABOPT"
}

export const utentiServiceUrl = APMS_URL + "/utente";
export const utentiService2Url = APMS_URL + "/api/v2"; // Da rivedere quando andremo ad allineare la v2 di APIAPMS
export const ricercaServiceUrlSito = RICERCA_URL + "/sito";
export const ricercaServiceUrlCategoria = RICERCA_URL + "/categoria";
