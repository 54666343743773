import { Toggle } from 'design-react-kit';
import React from 'react';
import InfoModal from '../InfoModal/InfoModal';
import './CustomInput.css';

export default function CustomInput(props) {

    const preventCutCopyPaste = (e) => {
        e.preventDefault();
    };

    return (
        <div className="customInput form-group">
            {
                props.toggleEnabled ?
                    <div className="row">
                        <div className="col-9">
                            <label className={"fieldLabel"} id={props.id + "-label"}>{props.labelName}</label>
                        </div>
                        <div className="col-3">
                            <Toggle className="customInput-toggle" label={props.visibile ? "Visibile" : "Non visibile"} checked={props.visibile} onChange={props.toggle} />
                        </div>
                    </div> :

                    props.infoMsg ?
                        <>
                            <label className={"fieldLabel"} id={props.id + "-label"}>{props.labelName} <InfoModal message={props.infoMsg} /></label>
                        </>
                        :
                        !props.hideLabel && <label className={"fieldLabel"} id={props.id + "-label"}>{props.labelName}</label>

            }
            {props.textarea ?
                <textarea
                    readOnly={props.readonly}
                    value={props.name}
                    id={props.id} type="text" placeholder={props.placeholder ? props.placeholder : ''}
                    className={"textArea"}
                    rows="3"
                    onChange={(e) => {
                        props.change(e)
                        if (props.invalidMsg && props.setInvalidMsg)
                            props.setInvalidMsg(null)
                    }}
                /> :
                <input
                    readOnly={props.readonly}
                    id={props.id}
                    type={props.type ? props.type : "text"}
                    placeholder={props.placeholder ? props.placeholder : ''}
                    className={`form-control ${!props.valid && props.disableValidColor || (props.valid && props.labelName === "Url" && props.name.slice(-1) === "/") || props.invalidMsg && props.disableValidColor ? `is-danger` : null}`}
                    value={props.name ? props.name : ""}
                    onChange={(e) => {
                        if ((!props.reject) || (props.reject && !props.reject(e.target.value))) {
                            props.change(e)
                            if (props.invalidMsg && props.setInvalidMsg)
                                props.setInvalidMsg(null)
                        }
                    }}
                    disabled={props.disabled}
                    onCut={props.type === "password" ? preventCutCopyPaste : null} onCopy={props.type === "password" ? preventCutCopyPaste : null} onPaste={props.type === "password" ? preventCutCopyPaste : null}
                />
            }
            {props.disableValidColor === null || props.disableValidColor === undefined || props.disableValidColor ? (!props.valid && !props.invalidMsg) && (props.name === "") ? <p>{"Il campo " + props.labelName.replace('*', '') + " è obbligatorio"}</p> : (!props.valid && !props.invalidMsg) || (props.valid && props.labelName === "Url" && !props.invalidMsg && props.name.slice(-1) === "/") ? <p>{"Il valore inserito nel campo " + props.labelName.replace('*', '') + " non è valido"}</p> : null : null}
            {props.invalidMsg ? <p>{props.invalidMsg}</p> : null}
        </div>
    )
}
