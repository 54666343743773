import InfoModal from "../InfoModal/InfoModal";
import { Messages } from "../../Utils/Messages";
import React, { useEffect, useState } from "react";
import Autosuggest from 'react-autosuggest';
import "./CustomAutocompleteInput.css";
import { Icon } from "design-react-kit";

/* https://github.com/moroshko/react-autosuggest */
export default function CustomAutocompleteInput(props) {
    const [suggestions, setSuggestions] = useState([])
    const [value, setValue] = useState('')
    useEffect(() => setValue(props.inputProps.value ? props.inputProps.value : ''), [props.inputProps.value])


    const inputProps = {
        ...props.inputProps,
        onChange: (event, { newValue }) => { setValue(newValue) },
        value: value,
    };

    const defaultRenderSuggestion = (suggestion) => <div>{suggestion.titolo}</div>
    const renderSuggestion = props.renderSuggestion ? props.renderSuggestion : defaultRenderSuggestion

    const defaultOnSuggestionsFetchRequested = ({ value }) => setSuggestions(props.getSuggestions(value))
    const onSuggestionsFetchRequested = props.onSuggestionsFetchRequested ? props.onSuggestionsFetchRequested : defaultOnSuggestionsFetchRequested

    const defaultOnSuggestionsClearRequested = () => setSuggestions([])
    const onSuggestionsClearRequested = props.onSuggestionsClearRequested ? props.onSuggestionsClearRequested : defaultOnSuggestionsClearRequested


    return <div className={"customInput customAutocompleteInput"}>
        <div className="labelDiv"><label>{props.title}</label>
            {props.infoMessage && <InfoModal message={props.infoMessage} />}
            <span title={"Reset contenuto padre"}>
                <Icon icon="it-close"
                    onClick={() => {
                        setValue("")
                        props.setApprofondimentoPadre("")
                    }}
                />
            </span>
        </div>
        <div id={props.inputProps.id} className="suggestDiv">
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={props.getSuggestionValue}
                onSuggestionSelected={props.onSuggestionSelected}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
        </div>
        {!props.valid ? <p>{"Il campo " + props.title + " non è valorizzato correttamente"}</p> : null}
    </div>
}
