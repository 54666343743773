import React, { useState, useEffect, useContext } from 'react';
import NewsPublisher from '../../UI/NewsPublisher/NewsPublisher';
import './HomePage.css';
import useDocumentTitle from '../../../hook/useDocumentTitle';
import { useNotizieInPubblicazione, useNotizieInScadenza } from "./HomePageHooks";
import { Context, hideLoading, showLoading } from "../../../hook/globalState/Store";
import moment from 'moment';
import { BUCKET_URL } from "../../../config/config";
import { Redirect, Link } from 'react-router-dom';
import { Button } from 'design-react-kit';
import NewsRepository from '../../../Repo/NewsRepository';
import { operazioniContenuto, roleEnum } from '../../Utils/Dataset';
import SessioneUtente from '../../../sessionManager/SessioneUtente';

export default function HomePage(props) {

    useDocumentTitle(props.title);
    let sessione = SessioneUtente.getInstance();

    const [state, dispatch] = useContext(Context)
    const [notizieInPubblicazione, setNotizieInPubblicazione] = useState();
    const [notizieInScadenza, setNotizieInScadenza] = useState();
    const [contentsFetched, setContentsFetched] = useState(false);

    useEffect(() => {
        if (contentsFetched)
            hideLoading()
        else {
            showLoading()
            onLoadHandler(setNotizieInPubblicazione, setNotizieInScadenza, setContentsFetched, sessione)
        }
    }, [contentsFetched])

    let userLabel = sessione ? sessione.nomeUtente + " " + sessione.cognomeUtente : "Agenas";

    return (
        <div id="homePanel">
            <div className="container">
                <h5>Benvenuta, {userLabel}</h5>
                <div className="row">
                    <div className="col-12 newsType">
                        <span>News in pubblicazione</span>
                        {!(contentsFetched && notizieInPubblicazione?.length !== 0) &&
                            <div id={"msgNoItems"}>
                                <i>Non sono presenti news in pubblicazione entro 15 giorni.</i>
                            </div>
                        }
                    </div>
                </div>
                <div className="">
                    {contentsFetched && notizieInPubblicazione?.map((el, i) =>
                        <NewsPublisher
                            image={BUCKET_URL + el?.immagineCopertina?.path}
                            cardTitle={operazioniContenuto.PUBBLICAZIONE_PROGRAMMATA} name={"group" + i} id={"radio" + i}
                            date={moment.utc(el.dataPubblicazioneProgrammata).local().format("DD-MM-YYYY")}
                            time={moment.utc(el.dataPubblicazioneProgrammata).local().format("HH:mm")}
                            key={el.id} nomeContenuto={el.nome}
                        />
                    )}
                </div>
                <div id={"viewAllPubblicazione"}>
                    {contentsFetched &&
                        <>
                            <Link
                                to={{ pathname: '/news/pubblicazione-programmata' }}
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "1.1rem"
                                }}
                            >
                                Vedi tutte...
                            </Link>
                        </>
                    }
                </div>
                <div className="row">
                    <div className="col-12 newsType">
                        <span>News in scadenza</span>
                        {!(contentsFetched && notizieInScadenza?.length !== 0) &&
                            <div id={"msgNoItems"}>
                                <i>Non sono presenti news in scadenza entro 15 giorni.</i>
                            </div>
                        }
                    </div>
                </div>
                <div className="">
                    {contentsFetched && notizieInScadenza?.map((el, i) =>
                        <NewsPublisher
                            image={BUCKET_URL + el?.immagineCopertina?.path}
                            visible={true} cardTitle={operazioniContenuto.SCADENZA_PROGRAMMATA}
                            name={"group" + i} id={"radio" + i}
                            date={moment.utc(el.dataScadenzaProgrammata).local().format("DD-MM-YYYY")}
                            time={moment.utc(el.dataScadenzaProgrammata).local().format("HH:mm")}
                            key={el.id} nomeContenuto={el.nome}
                        />)
                    }
                </div>
                <div id={"viewAllScadenza"}>
                    {contentsFetched &&
                        <Link
                            to={{ pathname: '/news/scadenza-programmata' }}
                            style={{
                                fontWeight: "bold",
                                fontSize: "1.1rem"
                            }}
                        >
                            Vedi tutte...
                        </Link>
                    }
                </div>
            </div>
        </div>
    )
}

function onLoadHandler(setNotizieInPubblicazione, setNotizieInScadenza, setContentsFetched, sessione) {

    let dateTo = moment().utc().add(15, 'days').format()

    Promise.all([NewsRepository().getNewsInPubblicazione(dateTo, { areaTerritoriale: sessione.ruoliUtente === roleEnum.EDITORE && "" }), NewsRepository().getNewsInScadenza(dateTo, { areaTerritoriale: sessione.ruoliUtente === roleEnum.EDITORE && "" })])
        .then(([result1, result2]) => {
            setNotizieInPubblicazione(result1)
            setNotizieInScadenza(result2)
        })
        .catch(err => err)
        .finally(setContentsFetched(true))
}