import React from 'react';
import TextEditor from '../../UI/TextEditor/TextEditor';
import './Template_04.css';
import CustomInput from '../../UI/CustomInput/CustomInput';
import SocialSharing from "../../UI/SocialSharing/SocialSharing";
import Gallery from '../../UI/Gallery/Gallery';
import GalleryCard from '../../UI/Gallery/GalleryCard';
import { showError } from '../../../hook/globalState/Store';
import { extensionsFile } from '../../Utils/Dataset';
import { getFileExtension, getMessageUploadError, uploadImageGallery } from '../../Utils/Utils';
import SimpleFileLoader from '../../SimpleFileLoader/SimpleFileLoader';
import { Icon } from 'design-react-kit';
import { BUCKET_URL } from '../../../config/config';
import { Messages } from '../../Utils/Messages';
import AttachmentTable from '../../UI/AttachmentsTable/AttachmentsTable';
import { addNewAttachmentRow, attachmentLoader } from '../TemplateUtils';


export default function Template_04(props) {

    //Gestione Galleria
    ////////////////////////////////////////////////////////////////////////////////////////
    const uploadGalleryElement = (file) => uploadImageGallery(file, props.content.addGalleryPic)

    const picLoader =
        <SimpleFileLoader
            loaderLayout={(
                <label id={"addNew"} className="row">
                    <Icon className="left" color="black" icon="it-plus-circle" id="arrowRight" />
                    Aggiungi nuovo
                </label>
            )}
            change={(e) => {
                if (extensionsFile.IMMAGINI.includes(getFileExtension(e.target.files[0]))) {
                    uploadGalleryElement(e.target.files[0])
                } else showError(getMessageUploadError("immagine", extensionsFile.IMMAGINI))
            }}
            idLoad="uploadGallery"
            name={props.content.currentGalleryPic}
        />
    ////////////////////////////////////////////////////////////////////////////////////////

    return (
        <>
            <div id="Template4">
                <div className="inputBoxWithShadow">
                    <CustomInput id="input1" labelName="Titolo*" change={props.content.titleChangedHandler} name={props.content.title} valid={props.content.validateTitle} />
                    <CustomInput id="input2" labelName="Sottotitolo" change={props.content.subTitleChangedHandler} name={props.content.subTitle} valid={props.content.validateSubTitle} />
                    <CustomInput id="input3" labelName="DESCRIZIONE (non visibile sul Portale)" change={props.content.descriptionChangedHandler} name={props.content.description} valid={props.content.validateDescription} textarea />
                    <CustomInput id="input4" labelName="Url*" change={props.content.pathChangedHandler} name={props.content.path} valid={props.content.validatePath} tipologia={props.content.tipologia} setPath={props.content.setPath} invalidMsg={props.content.invalidMsg ?? props.content.invalidMsg} setInvalidMsg={props.content.setInvalidMsg} />
                </div>
                <label className="text-type">testo*</label>
                <TextEditor array={props.content.testo} click={props.content.addTestoHandler} delete={props.content.removeTestoHandler} updateValue={props.content.updateTestoHandler} valid={props.content.validateTesto} indexOfPage={props.content.index} />
                {!props.content.validateTesto ? <p className="error">Inserire testo dell'articolo</p> : null}

                <div className={"inputBoxWithShadow"}>
                    <Gallery id={"gallery"} addElementRender={picLoader} label={"Link"} infoMessage={Messages.INFO_GALLERIA}>
                        {props.content.galleryPics.map((el, index) => <GalleryCard id={"gallery" + index} key={"galleryKey" + index} content={{ ...el, path: BUCKET_URL + el.path }} index={index} removeSelf={props.content.removeGalleryPic} value={el.descrizione} change={props.content.updateGalleryPic} link={el.link} validateLink={props.content.validateGallery[index]} />)}
                    </Gallery>
                </div>

                <div className={"inputBoxWithShadow"}>
                    <AttachmentTable label={"Allegati"}
                        attachments={props.content.attachments}
                        attachmentLoader={(index) => attachmentLoader(index, props.content.onChangeCurrentAttachment, props.content.updateAttachment)}
                        removeAttachment={props.content.removeAttachment}
                        addElementRender={addNewAttachmentRow(props.content.addAttachment)}
                        updateAttachment={props.content.updateAttachment}
                    />
                </div>

                <div className={'inputBoxWithShadow'}>
                    <SocialSharing labelName={"CONDIVISIONE SUI SOCIAL"} valueUrl={props.content.path} socials={props.content.socials} updateSocial={props.content.updateSocial} titolo={props.content.title} descrizione={props.content.description} immagine={props.content.fileName} onChangeCurrentAttachment={props.content.onChangeCurrentAttachment} loadedAssets={props.content.loadedAssets} />
                </div>
            </div>
        </>
    )
}
