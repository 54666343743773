import React, { useCallback } from 'react'
import MenuItem from '../../../UI/MenuItem/MenuItem'
import './MenuSection.css'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default function MenuSection(props) {

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = ((result) => {
        if (!result.destination) {
            return;
        }

        const newArrayItems = reorder(
            props.menuContents?.voci,
            result.source.index,
            result.destination.index
        );

        props.menuContents.setVoci(newArrayItems)

    });

    function staticElement() {
        if (props.menuContents?.section === "footer" && props.menuContents?.contentFetched) {
            return <>
                <MenuItem
                    id={props.menuContents?.voci.length - 1}
                    index={props.menuContents?.voci.length - 1}
                    etichetta={props.menuContents?.voci[props.menuContents?.voci.length - 1]?.etichetta}
                    nomeContenuto={props.menuContents?.voci[props.menuContents?.voci.length - 1]?.nomeContenuto}
                    sottovoci={props.menuContents?.voci[props.menuContents?.voci.length - 1]?.sottovoci}
                    visibile={props.menuContents?.voci[props.menuContents?.voci.length - 1]?.visibile}
                    changeVoce={props.menuContents?.updateVoce}
                />
            </>
        }
    }

    return (
        <>
            <div id="mainMenu">
                {props.menuContents?.voci ?
                    <>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {props.menuContents?.voci ? props.menuContents?.voci.map((el, i) => (
                                            el.pathContenuto !== "/bottombar" ?
                                                <>
                                                    <Draggable key={el.idContenuto + i} draggableId={"draggable" + i} index={i}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <MenuItem
                                                                    id={i}
                                                                    index={i}
                                                                    etichetta={el.etichetta}
                                                                    nomeContenuto={el.etichetta}
                                                                    visibile={el.visibile}
                                                                    sottovoci={el.sottovoci}
                                                                    changeVoce={props.menuContents?.updateVoce}
                                                                    motoreDiRicerca={true}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </> : null)) :
                                            null
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        {staticElement()}
                    </> :
                    null
                }
            </div>
        </>
    )
}