import React from 'react';
import { Toggle, Icon } from 'design-react-kit';
import './LinkItem.css';

export default function LinkItem(props) {

    return (
        <>
            <div id="linkItem">
                {props.topLabel &&
                    <div>
                        <label>{props.topLabel ?? props.topLabel}</label>
                        <hr></hr>
                    </div>
                }
                <div>
                    <div>
                        {props.bottomLabel &&
                            <label>{props.bottomLabel ?? props.bottomLabel}</label>
                        }
                        {props.changeVisibile &&
                            <Toggle label={props.linkStrutture ? props.visibile ? "Visibile" : "Non visibile" : "Visibile"} checked={props.visibile} onChange={props.changeVisibile} />
                        }
                    </div>
                    <div>
                        {props.changeLink &&
                            <input
                                value={props.link ? props.link : ""}
                                onChange={props.changeLink}
                                style={{ width: props.customWidthInput }}
                                className={"form-control"}
                                placeholder={props.placeholder ? props.placeholder : null}
                            />
                        }
                        {props.topLabel === "SERVIZI E PRESTAZIONI GARANTITE DAL SSN" &&
                            <Icon
                                icon={"it-delete"}
                                onClick={props.deleteItem}
                            />
                        }
                    </div>
                    {!props.validateLink && <i className="invalid-link">Inserire un sito valido che inizi con http/https</i>}
                </div>
            </div>
        </>
    )

}