import { SERVICE_URL, REPHEADERS } from "../config/config";
import AuthenticationManager from '../sessionManager/AuthenticationManager';
import SessioneUtente from "../sessionManager/SessioneUtente";

export default function ContenutiRepository() {

    const contenutiServiceUrl = SERVICE_URL + "/contenuti";
    const catalogoServiceUrl = SERVICE_URL + "/cataloghi";

    const authManager = new AuthenticationManager();
    const session = SessioneUtente.getInstance();

    /* centralizza le logiche di inzializzazione degli Headers */
    async function getHeaders(requestUrl) {
        let defaultHeaders = { ...REPHEADERS };
        defaultHeaders["AZUser"] = defaultHeaders["AZUser"] + ";areaterritoriale=" + session.idAreaTerritoriale
        return await authManager.initRequestHeaders(requestUrl, defaultHeaders);
    }

    // Momentaneo - Filtrato per stato!!ELIMINATO
    var queryFilter = (filters) => (filters.tipologia ? ('tipologia::' + filters.tipologia.toUpperCase() + ',') : "") + (filters.lingua ? ('lingua::' + filters.lingua + ',') : "") + (filters.areaTerritoriale ? ('areaTerritoriale::' + filters.areaTerritoriale + ',') : "") + ('stato!!ELIMINATO')


    async function getTuttiContenuti(filters, queryParams) {
        let requestUrl = contenutiServiceUrl + '?'
        if (filters) requestUrl = requestUrl + 'filter=' + queryFilter(filters)
        if (queryParams) requestUrl = requestUrl + '&' + new URLSearchParams(queryParams) // substr dell'ultimo carattere solo quando ci sono filters, perché ho una "," finale
        // if (queryParams) requestUrl = requestUrl + '?filter=' + new URLSearchParams(queryParams).toString().replace("=", "::");

        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";

        return fetch(requestUrl, {
            headers: headers,
            method: "GET",
        }).then(result => result.elementi)
    }


    async function getSezione(filters, queryParams, plain) {
        let requestUrl = contenutiServiceUrl + '?'
        if (filters) requestUrl = requestUrl + 'filter=' + queryFilter(filters)
        if (queryParams) requestUrl = requestUrl + '&' + new URLSearchParams(queryParams)
        // let requestUrl = contenutiServiceUrl + '?filter=' + new URLSearchParams(queryParams).toString().replace("=", "::");

        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";

        return fetch(requestUrl, {
            headers: headers,
            method: "GET",
        }).then(result => !plain ? result.elementi : result)
    }

    async function getSezioneAltraLingua(filters, queryParams, plain) {
        let requestUrl = contenutiServiceUrl + '?'
        if (filters) requestUrl = requestUrl + 'filter=' + queryFilter(filters)
        if (queryParams) requestUrl = requestUrl + '&' + new URLSearchParams(queryParams)
        // let requestUrl = contenutiServiceUrl + '?filter=' + new URLSearchParams(queryParams).toString().replace("=", "::");

        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";

        return fetch(requestUrl, {
            headers: headers,
            method: "GET",
        }).then(result => !plain ? result.elementi : result)
    }

    async function getContenutiOrdinati(filters, queryParams) {
        let requestUrl = contenutiServiceUrl + '?'
        if (filters) requestUrl = requestUrl + 'filter=' + queryFilter(filters)
        if (queryParams) requestUrl = requestUrl + '&' + new URLSearchParams(queryParams)
        // requestUrl = requestUrl + '&ascending=false&sort=dataInserimento'

        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET",
        }).then(result => result.elementi)
    }

    async function getPortale() {
        const requestUrl = contenutiServiceUrl + "?filter=tipologia::ARGOMENTO_GENERICO_PORTALE";
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET",
        })
    }

    async function getContatti() {
        const requestUrl = contenutiServiceUrl + "?filter=tipologia::ARGOMENTO_GENERICO_CONTATTI";
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET",
        })
    }

    async function getCollegamentiSocial() {
        const requestUrl = contenutiServiceUrl + "?filter=tipologia::COLLEGAMENTO_SOCIAL_NETWORK";
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET"
        })
    }

    async function creaContenuto(contenuto, props) {
        const headers = await getHeaders(contenutiServiceUrl);
        headers["Content-Type"] = "application/json";
        return fetch(contenutiServiceUrl, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(contenuto),
        })
    }

    async function aggiornaContenuto(contenuto) {
        const requestUrl = contenutiServiceUrl + "/" + contenuto.id;
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "PUT",
            body: JSON.stringify(contenuto),
        }).catch(err => err)
    }

    async function eliminaContenuto(id) {
        const requestUrl = contenutiServiceUrl + "/" + id + "/elimina";
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(id),
        }).catch(err => err)
    }

    async function pubblicaContenuto(contenuto) {
        const requestUrl = contenutiServiceUrl + "/" + contenuto.id;
        const headers = await getHeaders(requestUrl); //REPHEADERS,
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "PUT",
            body: JSON.stringify(contenuto),
        })
            .then(data => {
                const publishUrl = contenutiServiceUrl + "/" + data.contenuto.id + "/pubblica";
                return fetch(publishUrl, {
                    headers: headers,
                    method: "POST",
                    body: JSON.stringify(data.contenuto.id),
                })
            })
            .catch(err => err)
    }

    async function sospendiContenuto(id) {
        const requestUrl = contenutiServiceUrl + "/" + id + "/sospendi";
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(id),
        }).catch(err => err)
    }

    async function ripristinaContenuto(id) {
        const requestUrl = contenutiServiceUrl + "/" + id + "/ripristina";
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(id)
        }).catch(err => err)
    }

    async function getContenutoById(id) {
        const requestUrl = contenutiServiceUrl + "/" + id;
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET",
        }).catch(err => err)
    }

    // Per la getAll passare giorni=-1
    async function getInPubblicazione(giorni) {
        const requestUrl = contenutiServiceUrl + "?filter=inPubblicazioneProgrammata::" + giorni;
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET"
        })
    }

    // Per la getAll passare giorni=-1
    async function getInScadenza(giorni) {
        const requestUrl = contenutiServiceUrl + "?filter=inScadenzaProgrammata::" + giorni;
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET"
        })
    }

    async function getCataloghi(tipologia) {
        const requestUrl = catalogoServiceUrl + "?tipologia=" + tipologia.toUpperCase();
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET"
        }).catch(err => err)
    }

    async function creaCatalogo(catalogo) {
        const headers = await getHeaders(catalogoServiceUrl);
        headers["Content-Type"] = "application/json";
        return fetch(catalogoServiceUrl, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(catalogo)
        }).catch(err => err)
    }

    async function rimuoviCatalogo(idCatalogo) {
        const requestUrl = catalogoServiceUrl + "/" + idCatalogo;
        const headers = await getHeaders(catalogoServiceUrl);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "DELETE"
        }).catch(err => err)
    }

    return Object.freeze({
        getContenutiOrdinati,
        getSezione,
        getTuttiContenuti,
        getSezioneAltraLingua,
        pubblicaContenuto,
        sospendiContenuto,
        ripristinaContenuto,
        getContenutoById,
        getCollegamentiSocial,
        getPortale,
        getContatti,
        creaContenuto,
        aggiornaContenuto,
        eliminaContenuto,
        getInPubblicazione,
        getInScadenza,
        getCataloghi,
        creaCatalogo,
        rimuoviCatalogo
    });
}
