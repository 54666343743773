import React from 'react';
import { Toggle } from 'design-react-kit';
import './ToggleItem.css';

export default function ToggleItem(props) {

    let customCss = "";
    props.id === "toggleTag" ? customCss = "col-11" : customCss = "col-4 time"

    return (
        <div id="toggleItem">
            <div className="col-4">
                <div className="switch">
                    <div>
                        {props.disableLever ? <label className="toggles">{props.toggleName}</label> :
                            <Toggle onChange={props.changeToggle} checked={props.toggleValue} label={props.toggleName} />}
                    </div>
                </div>
            </div>
            <div className={customCss}>
                {props.id === "toggleText" ?
                    <>
                        <input
                            className={`form-control ${!props.valid && props.toggleValue ? `is-danger` : null}`}
                            id={props.id}
                            type={props.inputType}
                            value={props.formValue}
                            onChange={(e) => {
                                if (!props.rejectNaN(e.target.value, props.toggleName))
                                    props.changeValue(e)
                            }}
                            disabled={!props.toggleValue}

                        />
                        <span>minuti</span></> :
                    <input
                        id={props.id}
                        className={`form-control ${!props.valid && props.toggleValue ? `is-danger` : null}`}
                        type={props.inputType}
                        value={props.formValue}
                        onChange={props.changeValue}
                        disabled={!props.toggleValue}
                    />
                }
            </div>
            {props.toggleValue ?
                <>
                    {!props.valid ?
                        <p>{"Valorizzare il campo " + props.toggleName}</p> :
                        null
                    }
                </> :
                null
            }
        </div>
    )
}