import React, { useState, useEffect, useContext } from 'react';
import useInput from '../../../hook/useInput';
import useToggle from '../../../hook/useToggle';
import { Icon } from 'design-react-kit';
import CustomModal from '../../CustomModal/CustomModal';
import Card from '../../UI/Card/Card';
import Publisher from '../../UI/Publisher/Publisher';
import { Messages } from "../../Utils/Messages";
import { Redirect } from 'react-router-dom';
import useModal from '../../../hook/useModal';
import useFileHandler from '../../../hook/useFileHandler';
import { Context, hideLoading, scrollToTop, showError, showLoading, showSuccess } from "../../../hook/globalState/Store";
import SocialInput from '../../UI/SocialInput/SocialInput';
import LogoLoader from '../../UI/LogoLoader/LogoLoader';
import ContenutiRepository from '../../../Repo/ContenutiRepository';
import { BUCKET_URL, WAFE_PREVIEW_URL } from "../../../config/config";
import { forcedScrollToTop, uploadIcon } from '../../Utils/Utils';
import { socialEnum, KO_OPERATION, OK_RELEASE, KO_RELEASE, operazioniContenuto } from "../../Utils/Dataset";
import { alertLevels } from "../../UI/InPageAlert/InPageAlert";
import './CollegamentiSocial.css';
import { hasErrorOutcome } from '../../Interceptor/Interceptor';
import SessioneUtente from '../../../sessionManager/SessioneUtente';
import PreviewModal from '../../PreviewModal/PreviewModal';
import { validateUrl } from '../../UI/FieldValidation/FieldValidation';


export default function CollegamentiSocial(props) {

    const headerMsg = "In questa sezione puoi modificare i link ai social relativi al Portale, presenti nell'header della pagina, in alto a destra ('Seguici su'). Ricordati di selezionare 'Pubblica' per permettere la pubblicazione della modifica sul Portale.";

    // Sessione
    let session = SessioneUtente.getInstance();

    //content id
    const [contentIdFacebook, setContentIdFacebook] = useState(null);
    const [contentIdTwitter, setContentIdTwitter] = useState(null);
    const [contentIdYoutube, setContentIdYoutube] = useState(null);

    const tipologia = "COLLEGAMENTO_SOCIAL_NETWORK";
    const template = "TEMPLATE_COLLEGAMENTO_SOCIAL_NETWORK";

    // content state
    const [contentFetched, setContentFetched] = useState(false);
    const [content, setContent] = useState(null);

    //state
    const [redirect, setRedirect] = useState(false);
    const [state, dispatch] = useContext(Context);
    const [action, setAction] = useState("");
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [stato, setStato] = useState();

    //social state
    const [linkFacebook, setLinkFacebook, linkFacebookChangedHandler, validateLinkFacebook] = useInput("", validateUrl);
    const [visibileFb, toggleFb, setToggleFb] = useToggle(false);
    const [linkTwitter, setLinkTwitter, linkTwitterChangedHandler, validateLinkTwitter] = useInput("", validateUrl);
    const [visibileTw, toggleTw, setToggleTw] = useToggle(false);
    const [linkYoutube, setLinkYoutube, linkYoutubeChangedHandler, validateLinkYoutube] = useInput("", validateUrl);
    const [visibileYt, toggleYt, setToggleYt] = useToggle(false);

    const [iconaFb, setIconFb, onChangeIconaFb] = useInput(null);
    const [iconaTw, setIconTw, onChangeIconaTw] = useInput(null);
    const [iconaYt, setIconYt, onChangeIconaYt] = useInput(null);
    const [currentAttachment, setCurrentAttachment, attachmentMetadata, setAttachmentMetadata, onChangeCurrentAttachment] = useFileHandler()

    //dati mock
    const ultimaModifica = "24/01/2020";
    const utenteUltimaModifica = "Mario Rossi";
    const [dataModificaFb, setDataModificaFb] = useInput("");
    const [dataModificaTw, setDataModificaTw] = useInput("");
    const [dataModificaYt, setDataModificaYt] = useInput("");

    function hasError() {
        let valid = validateLinkFacebook && validateLinkTwitter && validateLinkYoutube;
        return !valid
    }

    //bottoni
    const btnPrimary = [
        {
            //le classi sono invertite per aderenza al mockup
            id: "1", color: "secondary", outline: true, title: "Salva modifiche", disabled: hasError(),
            onClickHandler: () => {
                Promise.all([ContenutiRepository().aggiornaContenuto(inizializzaCollegamentoSocial(contentIdFacebook, tipologia, template, socialEnum.FACEBOOK, linkFacebook, iconaFb, visibileFb, dataModificaFb, session)),
                ContenutiRepository().aggiornaContenuto(inizializzaCollegamentoSocial(contentIdTwitter, tipologia, template, socialEnum.TWITTER, linkTwitter, iconaTw, visibileTw, dataModificaTw, session)),
                ContenutiRepository().aggiornaContenuto(inizializzaCollegamentoSocial(contentIdYoutube, tipologia, template, socialEnum.YOUTUBE, linkYoutube, iconaYt, visibileYt, dataModificaYt, session))])
                    .then(([result1, result2, result3]) => {
                        if (result1 || result2 || result3) {
                            if (!hasErrorOutcome(result1) && !hasErrorOutcome(result2) && !hasErrorOutcome(result3)) {
                                // dispatch({ type: 'RELOAD_EDIT_CONTENT', payload: { reloadEditContent: true } });
                                showSuccess()
                                setContentFetched(false);
                            } else showError(KO_OPERATION)
                        }
                    })
                    .catch(err => {
                        showError(KO_OPERATION)
                    })
                forcedScrollToTop(state, dispatch, scrollToTop) // Gestione momentanea
            }
        },
        {
            id: "2", color: "secondary", title: "Visualizza anteprima",
            onClickHandler: () => {
                getModal(setAction, operazioniContenuto.ANTEPRIMA, handleShow)
            }
        }
    ]

    const btnSecondary = [
        {
            id: "3", color: "secondary", outline: false, title: operazioniContenuto.RIPRISTINA,
            onClickHandler: () => {
                Promise.all([ContenutiRepository().ripristinaContenuto(contentIdFacebook),
                ContenutiRepository().ripristinaContenuto(contentIdTwitter),
                ContenutiRepository().ripristinaContenuto(contentIdYoutube)])
                    .then(([result1, result2, result3]) => {
                        setContentFetched(false);
                    })
            }
        },
        {
            id: "4", color: "secondary", outline: true, title: "Pubblica", disabled: hasError(),
            onClickHandler: () => {
                getModal(setAction, operazioniContenuto.PUBBLICAZIONE, handleShow)
            }
        }
    ]

    useEffect(() => {
        if (contentFetched) {

            setStato(content.elementi[0]?.stato ? content.elementi[0]?.stato : ""); // Lo stato dei socials è uguale per tutti e tre

            content.elementi && content.elementi.forEach((el, i) => {
                if (el.nome === socialEnum.FACEBOOK) {
                    setContentIdFacebook(el.id);
                    setLinkFacebook(el?.collegamentoSocialNetwork?.link);
                    setIconFb(el?.collegamentoSocialNetwork?.icona?.path);
                    setToggleFb(el?.collegamentoSocialNetwork?.visibile);
                    setDataModificaFb(el.dataModifica);

                } else if (el.nome === socialEnum.TWITTER) {
                    setContentIdTwitter(el.id);
                    setLinkTwitter(el?.collegamentoSocialNetwork?.link);
                    setIconTw(el?.collegamentoSocialNetwork?.icona?.path);
                    setToggleTw(el?.collegamentoSocialNetwork?.visibile);
                    setDataModificaTw(el.dataModifica);

                } else {
                    setContentIdYoutube(el.id);
                    setLinkYoutube(el?.collegamentoSocialNetwork?.link);
                    setIconYt(el?.collegamentoSocialNetwork?.icona?.path);
                    setToggleYt(el?.collegamentoSocialNetwork?.visibile);
                    setDataModificaYt(el.dataModifica);
                }
            })

        }
        else {
            onLoadHandler(setContent, setContentFetched)
        }
    }, [contentFetched]);

    const uploadIconaFb = (file) => uploadIcon(file, setIconFb)
    const uploadIconaTw = (file) => uploadIcon(file, setIconTw)
    const uploadIconaYt = (file) => uploadIcon(file, setIconYt)

    return (

        redirect ? <Redirect to="/" /> :
            <div id="collegamentiSocial">
                <div className="row">
                    <div id={"headerText"} className="col-6 p-0">
                        <h6>{props.title}</h6>
                    </div>
                    <div className="col-6 back">
                        <a onClick={() => getModal(setAction, operazioniContenuto.INDIETRO, handleShow)}> <Icon className="left"
                            color="black"
                            icon="it-arrow-left"
                            id="arrow" />Indietro</a>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-9 p-0'}>
                        <i>Ultimo aggiornamento: {ultimaModifica}, da {utenteUltimaModifica} </i>
                        <div id={"infoText"}>
                            <i>{headerMsg}</i>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-9">
                        {/*Collegamenti Social*/}
                        <div className="social">
                            <div className="row">
                                <div className="col-4">
                                    <LogoLoader preview idLoad="fbIcon" name={iconaFb} change={(e) => onChangeCurrentAttachment(e, (fileBin, fileMetadata) => uploadIconaFb(fileBin))}
                                        previewUri={iconaFb ? BUCKET_URL + iconaFb : null} tip={Messages.SIZE_CONSIGLIATA_ICONA_SOCIAL} />
                                </div>
                                <div className="col-8">
                                    <SocialInput label="Link facebook" id="linkFacebook" name={linkFacebook} change={linkFacebookChangedHandler} toggleValue={visibileFb} changeToggle={toggleFb} toggleName={visibileFb} invalidLink={!validateLinkFacebook} />
                                </div>
                            </div>
                        </div>
                        <div className="social">
                            <div className="row">
                                <div className="col-4">
                                    <LogoLoader preview idLoad="twIcon" name={iconaTw} change={(e) => onChangeCurrentAttachment(e, (fileBin, fileMetadata) => uploadIconaTw(fileBin))}
                                        previewUri={iconaTw ? BUCKET_URL + iconaTw : null} tip={Messages.SIZE_CONSIGLIATA_ICONA_SOCIAL} />
                                </div>
                                <div className="col-8">
                                    <SocialInput label="Link Twitter" id="linkTwitter" name={linkTwitter} change={linkTwitterChangedHandler} toggleValue={visibileTw} changeToggle={toggleTw} toggleName={visibileTw} invalidLink={!validateLinkTwitter} />
                                </div>
                            </div>
                        </div>
                        <div className="social">
                            <div className="row">
                                <div className="col-4">
                                    <LogoLoader preview idLoad="ytIcon" name={iconaTw} change={(e) => onChangeCurrentAttachment(e, (fileBin, fileMetadata) => uploadIconaYt(fileBin))}
                                        previewUri={iconaYt ? BUCKET_URL + iconaYt : null} tip={Messages.SIZE_CONSIGLIATA_ICONA_SOCIAL} />
                                </div>
                                <div className="col-8">
                                    <SocialInput label="Link youtube" id="linkYoutube" name={linkYoutube} change={linkYoutubeChangedHandler} toggleValue={visibileYt} changeToggle={toggleYt} toggleName={visibileYt} invalidLink={!validateLinkYoutube} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 mt-5">
                        {action === operazioniContenuto.ANTEMPRIMA ?
                            <PreviewModal
                                show={show}
                                onHide={handleClose}
                                onConfirm={() => callBackAndClose(
                                    inizializzaCollegamentoSocial(contentIdFacebook, tipologia, template, socialEnum.FACEBOOK, linkFacebook, iconaFb, visibileFb, dataModificaFb, session),
                                    inizializzaCollegamentoSocial(contentIdTwitter, tipologia, template, socialEnum.TWITTER, linkTwitter, iconaTw, visibileTw, dataModificaTw, session),
                                    inizializzaCollegamentoSocial(contentIdYoutube, tipologia, template, socialEnum.YOUTUBE, linkYoutube, iconaYt, visibileYt, dataModificaYt, session), handleClose, action, setRedirect, dispatch)}
                                title={"Anteprima"}
                                text={getModalMessage(action)}
                                body={<iframe title={"Preview"} src={WAFE_PREVIEW_URL} width={"100%"} height={"100%"} />}
                            /> :
                            <CustomModal
                                show={show}
                                onHide={handleClose}
                                onConfirm={() => callBackAndClose(
                                    inizializzaCollegamentoSocial(contentIdFacebook, tipologia, template, socialEnum.FACEBOOK, linkFacebook, iconaFb, visibileFb, dataModificaFb, session),
                                    inizializzaCollegamentoSocial(contentIdTwitter, tipologia, template, socialEnum.TWITTER, linkTwitter, iconaTw, visibileTw, dataModificaTw, session),
                                    inizializzaCollegamentoSocial(contentIdYoutube, tipologia, template, socialEnum.YOUTUBE, linkYoutube, iconaYt, visibileYt, dataModificaYt, session), handleClose, action, setRedirect, dispatch)}
                                title={action === operazioniContenuto.INDIETRO ? "Ritorno alla home page" : action + " in corso"}
                                text={getModalMessage(action)}
                            />}
                        <Card title={operazioniContenuto.PUBBLICAZIONE}>
                            <Publisher
                                arrayPrimary={btnPrimary}
                                arraySecondary={btnSecondary}
                                stato={stato}
                            />
                        </Card>
                    </div>
                </div>
            </div>
    )
}

function onLoadHandler(setContent, setContentFetched) {

    showLoading()
    ContenutiRepository().getCollegamentiSocial()
        .then(result => {
            setContent(result);
            setContentFetched(true);
        }).finally(() => hideLoading())
}

function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}


async function callBackAndClose(statoFb, statoTw, statoYt, handleClose, action, setRedirect, dispatch) {

    let response;

    if (action === operazioniContenuto.PUBBLICAZIONE) {
        Promise.all([ContenutiRepository().pubblicaContenuto(statoFb),
        ContenutiRepository().pubblicaContenuto(statoTw),
        ContenutiRepository().pubblicaContenuto(statoYt)])
            .then(([result1, result2, result3]) => {
                if (!hasErrorOutcome(result1) && !hasErrorOutcome(result2) && !hasErrorOutcome(result3)) {
                    setRedirect(true)
                    dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
                    dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.SUCCESS, alertMsg: OK_RELEASE } });
                } else showError(KO_RELEASE)
            }).catch((err) => showError(KO_RELEASE))
        handleClose();
        scrollToTop()
    }

    if (action === operazioniContenuto.INDIETRO) {
        handleClose();
        setRedirect(true);
    }
}

function getModalMessage(action) {
    switch (action) {
        case operazioniContenuto.INDIETRO:
            return Messages.ERR_TMP_COLLEGAMENTI_SOCIAL
        default:
            return Messages.INFO_DEFAULT
    }
}


function inizializzaCollegamentoSocial(id, tipologia, template, nome, link, url, visibile, dataModifica, session) {

    var lingua = "IT";
    var descrizione = "_descrizione_";
    var testo = [{ titolo: "_titolo_", testo: "_testo_" }];
    var path;
    var titolo;
    if (nome === socialEnum.FACEBOOK) {
        path = "/seguicisu/facebook";
        titolo = socialEnum.FACEBOOK;
    } else if (nome === socialEnum.TWITTER) {
        path = "/seguicisu/twitter";
        titolo = socialEnum.TWITTER;
    } else {
        path = "/seguicisu/youtube";
        titolo = socialEnum.YOUTUBE;
    }
    //TODO: creare un oggetto che racchiude le property che arrivano per ogni collegamento social
    //sovrascrivere solo le property che stanno nella maschera

    return Object.freeze({
        id,
        titolo,
        path,
        tipologia,
        template,
        lingua,
        nome,
        testo,
        dataModifica: dataModifica,
        collegamentoSocialNetwork: {
            descrizione,
            link: link,
            icona: {
                path: url
            },
            visibile
        },
        areaTerritoriale: session.idAreaTerritoriale
    });
}
