import React from 'react';
import './CustomCTA.css';
import { Toggle, Icon } from 'design-react-kit';


export default function CustomCTA(props) {

    const tooltipDelete = "Rimuovi CTA"

    return (
        <div id="CustomCTA">
            <div className="row">
                <div className="col-10 url-field">
                    <label htmlFor={props.id}>DESCRIZIONE</label>
                    <input id={props.id} type="text" placeholder={"Inserire una descrizione..."} value={props.descrizione} onChange={(e) => props.changeDescrizioneCustomApp(props.id, e)} />
                </div>
                <div className="col-2" title={tooltipDelete}>
                    <button className={'float-right mt-2'} onClick={() => props.delete(props.id)} >
                        <Icon className="delete" color="black" icon="it-delete" id="delete" />
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-10 url-field">
                    <label htmlFor={props.id + props.index}>URL</label>
                    <input id={props.id + props.index} type="text" className={`form-control`} placeholder={"Inserire un link..."} value={props.link} onChange={(e) => { props.changeLinkDaCollegare(props.id, e) }} />
                </div>
                {!props.isValid ? <p>É necessario inserire la descrizione e il link al CTA associato</p> : null}
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="switch">
                        <div>
                            <Toggle onChange={() => props.changeToggleCta(props.id)} checked={props.toggleValueCta} label={props.toggleNameCta} />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    {props.isToggleLinkVisibile ?
                        <div className="switch">
                            <div>
                                <Toggle onChange={() => props.changeToggleLink(props.id)} checked={props.toggleValueLink} label={props.toggleNameLink} />
                            </div>
                        </div> : null}
                </div>
            </div>
        </div>
    )
}
