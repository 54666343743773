import { Icon } from 'design-react-kit';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { hideLoading, showLoading } from '../../../../hook/globalState/Store';
import useModal from '../../../../hook/useModal';
import useObjectsArray from '../../../../hook/useObjectsArray';
import StruttureRepository from '../../../../Repo/StruttureRepository';
import SessioneUtente from '../../../../sessionManager/SessioneUtente';
import AssociazioneBox from '../../../UI/AssociazioneBox/AssociazioneBox';
import { operazioniAssociazioni } from '../../../Utils/Dataset';
import './DisturbiAlimentari.css'

export default function DisturbiAlimentari() {

    // Session
    const session = SessioneUtente.getInstance();

    // Consts
    const headerMsg = "Qui puoi visualizzare le associazioni regionali di riferimento per i Disturbi Alimentari. Se non inserisci alcun contenuto, questo non sarà visibile dal cittadino nelle sezioni specifiche."

    // States
    const [associazioni, setAssociazioni, removeAssociazione, addAssociazione, updateAssociazione] = useObjectsArray([])
    const [removeAssociazioneIndex, setRemoveAssociazioneIndex] = useState()
    const [contentFetched, setContentFetched] = useState(false);

    // Gestione finestre modali
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [action, setAction] = useState("");

    const handleRemoveAssociazione = (index) => {
        setRemoveAssociazioneIndex(index)
        getModal(operazioniAssociazioni.ELIMINA)
    }
    const getModal = (azione) => {
        setAction(azione);
        handleShow();
    }

    useEffect(() => {
        if (!contentFetched) {
            showLoading()
            onLoadHandler(setContentFetched, setAssociazioni, session)
        }
    }, [contentFetched])

    return (
        <div className={"associazioni-disturbi-container"}>
            <div className="row">
                <div className="col-9">
                    <h5>Associazioni - Disturbi Alimentari</h5>
                    <i>{headerMsg}</i>
                </div>
                <div className={'col-3 text-right '}>
                    <Link id="back" to="/">
                        <Icon className="left" color="black" icon="it-arrow-left" id="arrow" />Indietro
                                </Link>
                </div>
            </div>
            <div className="row">
                <div className="col-9">
                    <div>
                        {associazioni && associazioni.length ? associazioni.map((el, index) => <div key={el.cap + "-" + index} className={"associazione-box"} ><AssociazioneBox el={el} removeSelf={() => handleRemoveAssociazione(index)} /></div>)
                            : <div className="mt-4"><span>Non sono presenti associazioni</span></div>}
                    </div>
                    <div className="mt-4"><Link className={"add-associazione"} to={'/aggiungi-associazione'}><Icon className="left" color="black" icon="it-plus-circle" id="arrowRight" /><span className={'ml-2 add-new-associazione'}>Aggiungi nuova</span></Link></div>
                </div>
            </div>
        </div>
    )
}

function onLoadHandler(setContentFetched, setAssociazioni, session) {
    StruttureRepository().getAllDisturbiAlimentari(session.idAreaTerritoriale)
        .then(result => setAssociazioni(result?.elementi))
        .catch(err => err)
        .finally(() => {
            setContentFetched(true)
            hideLoading()
        })
}