import React, { useContext } from 'react';
import './Select.css';
import { Context } from "../../../hook/globalState/Store";
import InfoModal from '../InfoModal/InfoModal';

export default function Select(props) {

    const [, dispatch] = useContext(Context);

    return (
        <div className="bootstrap-select-wrapper" id="select">
            <label className={"pb-1"}>{props.title && props.title}</label>
            <select
                onChange={props.title === "Approfondimento padre" ? (e) => {
                    props.change(e);
                    dispatch({ type: 'RELOAD_PATH', payload: { reloadPath: true } });
                } :
                    props.change ? (e) => {
                        if (props.click) props.change(e.target.value)
                        else props.change(e)
                    } : null}
                value={props.value ? props.value : ""}
                disabled={props.disabled}
            >
                <option value={""} disabled>{props.placeholder}</option>
                {props.array?.length ? props.array?.map((el, i) =>
                    <option
                        key={props.idOption + "-" + i}
                        value={el[props.fieldValue]}
                    >
                        {el[props.fieldName]}
                    </option>) :
                    null
                }
            </select>
            {props.infoMessage && <InfoModal message={props.infoMessage} />}
            {!props.valid && props.title === "Approfondimento padre" ? <p>{"Il campo " + props.title + " non è valorizzato"}</p> : null}
        </div>
    )
}
