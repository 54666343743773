import React from 'react';
import moment from "moment";
import { assetsEnum, extensionsFile, KO_OPERATION, roleEnum, uploadLimits } from './Dataset';
import { hideLoading, showError, showLoading, showSuccess } from "../../hook/globalState/Store";
import FileRepository from "../../Repo/FileRepository";
import { hasErrorOutcome } from "../Interceptor/Interceptor";
import { getMessaggioOperazione } from "../../Repo/Utils";
import { Messages } from './Messages';

export default function getPath(value) {

    switch (value) {
        case "approfondimento":
            value = "approfondimenti";
            break;
        default:
            return value;
    }

    return value;
}

export function isUtenteRegionale(role) {

    if (role?.toUpperCase() === roleEnum.AUTORE.toUpperCase() || role?.toUpperCase() === roleEnum.COLLABORATORE.toUpperCase()) {
        return true;
    }
    return false;
}

export function isRegione(idRegione) {
    if (idRegione === "041" || idRegione === "042") return false // Trento & Bolzano province autonome
    else return true
}

export function isDateFuture(date, time) {
    return moment(date + " " + time).valueOf() > moment.now()
}

export function getRemainingDays(dataCancellazione) {
    return moment(moment.now()).diff(moment(dataCancellazione), 'days')
}

export function getFileNameFromPath(filename, timestamp) {
    let regex = timestamp ? /(\/.+\/)(.+)/ : /(\/.+\/[0-9]+-)(.+)/
    let parsed
    try {
        parsed = filename.match(regex)[2]
    } catch (err) {
        parsed = filename
    }

    return parsed
}

export function getFileExtension(file) {
    var extension = file?.name?.split('.').pop().toLowerCase();
    return extension
}

export function getMessageUploadError(tipoFile, estensioniAccettate) {
    return "Formato del file non corretto. Inserire un file che abbia uno dei seguenti formati: " + estensioniAccettate
}

export const areeTerritoriali = ["130", "170", "041", "180", "150", "080", "060", "120", "070", "030", "110", "140", "010", "160", "200", "190", "090", "042", "100", "020", "050"];

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}

export const unshiftElementOfArray = (list, index) => {
    let el = list[index]
    list.splice(index, 1)
    list.unshift(el)
    return list
}

export function setInvalidFieldMsg(setInvalidMsg, fieldsError) {
    fieldsError.map((el, i) => {
        switch (el.valore) {
            case "path":
                setInvalidMsg("La path inserita non è univoca")
                break;
            default:
                break;
        }
    })
}

export function getPathFromName(name) {
    return name?.toLowerCase().split(' ').join('-')
}

export function getNameFromPath(path) {
    let name = path.split('-').join(' ')
    return name.toUpperCase()
}

export function uploadImage(file, setImage, limite) {
    if (file.size > (limite ? limite : uploadLimits.IMG)) {
        limite ? showError(Messages.ERR_IMGCOPERTINA_DIMENSION) : showError(Messages.ERR_IMAGE_DIMENSION)
    } else if (extensionsFile.IMMAGINI.includes(getFileExtension(file))) {
        showLoading()
        FileRepository().uploadFile(assetsEnum.IMMAGINI, file)
            .then(data => {
                if (setImage) setImage(data?.object?.filename)
            }).finally(() => hideLoading())
    } else showError(getMessageUploadError("immagine", extensionsFile.IMMAGINI))
}

export function uploadImageGallery(file, uploadGallery, limite) {
    if (file.size > (limite ? limite : uploadLimits.IMG)) {
        showError(Messages.ERR_IMAGE_DIMENSION)
    } else if (extensionsFile.IMMAGINI.includes(getFileExtension(file))) {
        showLoading()
        FileRepository().uploadFile(assetsEnum.IMMAGINI, file)
            .then(data => {
                if (uploadGallery) uploadGallery({ "testoAlternativo": getFileNameFromPath(data?.object?.filename), "path": data?.object?.filename })
            }).finally(() => hideLoading())
    } else showError(getMessageUploadError("immagine", extensionsFile.IMMAGINI))
}

export function uploadImageSocial(file, index, updateSocial, limite) {
    if (file.size > (limite ? limite : uploadLimits.IMG)) {
        showError(Messages.ERR_IMAGE_DIMENSION)
    } else if (extensionsFile.IMMAGINI.includes(getFileExtension(file))) {
        showLoading()
        FileRepository().uploadFile(assetsEnum.IMMAGINI, file)
            .then(data => {
                updateSocial(index, "immagine", data?.object?.filename)
            }).finally(() => hideLoading())
    } else showError(getMessageUploadError("immagine", extensionsFile.IMMAGINI))
}

export function uploadIcon(file, setIcon, limite) {
    if (file.size > (limite ? limite : uploadLimits.ICON)) {
        showError(Messages.ERR_ICON_DIMENSION)
    } else if (extensionsFile.ICONE.includes(getFileExtension(file))) {
        showLoading()
        FileRepository().uploadFile(assetsEnum.ICONE, file)
            .then(data => {
                if (setIcon) setIcon(data?.object?.filename)
            }).finally(() => hideLoading())
    } else showError(getMessageUploadError("icone", extensionsFile.ICONE))
}

export function uploadPdf(file, setDocument, limite) {
    let extension = extensionsFile.PDF;
    if (file.size > (limite ? limite : uploadLimits.DOCUMENT)) {
        showError(Messages.ERR_FILE_DIMENSION)
    } else if (extension.includes(getFileExtension(file))) {
        showLoading()
        FileRepository().uploadFile(assetsEnum.ALLEGATI, file)
            .then(data => {
                if (setDocument) setDocument(data?.object?.filename)
            })
            .finally(() => hideLoading())
    } else showError(getMessageUploadError("allegato", extension))
}

export function uploadAllegato(file, index, uploadAllegato, limite) {
    if (file.size > (limite ? limite : uploadLimits.DOCUMENT)) {
        showError(Messages.ERR_FILE_DIMENSION)
    } else if (extensionsFile.ALLEGATI.includes(getFileExtension(file))) {
        showLoading()
        FileRepository().uploadFile(assetsEnum.ALLEGATI, file)
            .then(data => {
                if (uploadAllegato) uploadAllegato(index, "path", data?.object?.filename)
            })
            .finally(() => hideLoading())
    } else showError(getMessageUploadError("allegato", extensionsFile.ALLEGATI))
}

export function getBasePathFromTipologia(tipologia, approfondimentoPadre, oldPath) {

    let pathNotEditable

    switch (tipologia) {
        case "APPROFONDIMENTO":
            pathNotEditable = "/approfondimenti/"
            break;
        case "DETTAGLIO_APPROFONDIMENTO":
            approfondimentoPadre ? pathNotEditable = "/approfondimenti/" + oldPath.slice(17, oldPath.length) + "/" : pathNotEditable = "/approfondimenti/dettagliapprofondimento/"
            break;
        case "COME_FARE_PER":
            pathNotEditable = "/comefareper/"
            break;
        case "VIVERE_IN_SALUTE":
            pathNotEditable = "/"
            break;
        case "NEWS":
            pathNotEditable = "/news/"
            break;
        case "PRESTAZIONE_GARANTITA_SSN":
            pathNotEditable = "/prestazionegarantitassn/"
            break;
        default:
            pathNotEditable = "/"
    }

    return pathNotEditable

}

export function checkEsitoAndShowMessageDefault(result) {
    if (!hasErrorOutcome(result))
        showSuccess()
    else
        showError(getMessaggioOperazione(result?.esito.messaggi[0]))
}

export function forcedScrollToTop(state, dispatch, scrollToTop) {
    // Gestione momentanea
    if (state.scrollTop) {
        window.scroll(0, 0)
        dispatch({ type: 'SCROLL_TOP', payload: { scrollTop: false } })
    }
    else scrollToTop()
}

export function rejectNaN(character) {
    if (isNaN(character)) {
        return true
    }
}

export function validateTelephoneNumber(character) {
    let regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
    if (character.match(regex) || character === "") return false
    else return true
}

export function isContentReadOnly(ruoloUtenteCorrente, codiceFiscaleUtenteCorrente, idAreaTerritorialeContenuto, idUtenteInserimentoContenuto) {
    if (ruoloUtenteCorrente === roleEnum.COLLABORATORE && codiceFiscaleUtenteCorrente?.toUpperCase() !== idUtenteInserimentoContenuto?.toUpperCase()) {
        return true;
    }
    if (areeTerritoriali.includes(idAreaTerritorialeContenuto) && !isUtenteRegionale(ruoloUtenteCorrente)) {
        return true;
    }
    return false;
}

export function sortAscending(el1, el2, property) {
    if (el1[property] < el2[property]) {
        return -1;
    }
    if (el1[property] > el2[property]) {
        return 1;
    }
    return 0;
}

export function makeUniqueId(length) {
    let result = "";
    let characters = "abcdef0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function makeCodeVerifier() {
    let length = Math.floor(Math.random() * (128 - 43)) + 43;
    let codeVerifier = "";
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        codeVerifier += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return codeVerifier;
}

export async function encryptStringWithSHA256(str) {
    const PROTOCOL = 'SHA-256'
    const textEncoder = new TextEncoder();
    const encodedData = textEncoder.encode(str);
    return window.crypto.subtle.digest(PROTOCOL, encodedData)
}

export function hashToBase64url(arrayBuffer) {
    const items = new Uint8Array(arrayBuffer)
    const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '')
    const decodedHash = btoa(stringifiedArrayHash)
    const base64URL = decodedHash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return base64URL
}

export function isObjectNullOrUndefined(object) {
    if (object?.length === 0 || object === "" || object === null || object === undefined) return true
    else return false
}

export function isUtenteImpersonificato(sessione) {
    const regioneImpersonificata = sessionStorage.getItem('regioneImpersonificata');

    if (regioneImpersonificata && sessione.ruoloRealeUtente === roleEnum.AMMINISTRATORE) return true
    else return false
}