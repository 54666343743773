import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../../../../hook/globalState/Store';
import useInput from '../../../../hook/useInput';
import useToggle from '../../../../hook/useToggle';
import useModal from '../../../../hook/useModal';
import TitleTemplate from '../../../UI/TitleTemplate/TitleTemplate';
import { Icon } from 'design-react-kit';
import './ComeFarePer.css';
import useObjectsArray from '../../../../hook/useObjectsArray';
import PageItem from '../../../UI/PageItem/PageItem';
import { blankPageItem, operazioniContenuto } from '../../../Utils/Dataset';
import CustomModal from '../../../CustomModal/CustomModal';
import Card from '../../../UI/Card/Card';
import Publisher from '../../../UI/Publisher/Publisher';
import { Messages } from '../../../Utils/Messages';

export default function ComeFarePer(props) {

    // Dati mock
    const ultimaModifica = "24/01/2020";
    const utenteUltimaModifica = "Mario Rossi";
    const headerMsg = "Qui puoi impostare nuove applicazioni custom della tipologia 'Come fare per', che quindi rimandano alla selezione delle Regioni, della ASL e l'indirizzamento finale al link inserito dal Referente Regionale, che ha la piena responsabilità del link stesso.";
    const mockData = [
        {
            label: "Prenotare una prestazione online",
            visibile: false,
            linkCollegato: "https://pdt.agenas.it/come-fare-per/prenotare-online",
            titolo: "Vai al sito della tua ASL per sapere come prenotare online",
            testo: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
            label: "Prenotare una prestazione al CUP",
            visibile: false,
            linkCollegato: "https://pdt.agenas.it/come-fare-per/prenotare-CUP",
            titolo: "Vai al sito della tua ASL per sapere come prenotare tramite CUP",
            testo: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
            label: "Ottenere esenzioni ed agevolazioni",
            visibile: false,
            linkCollegato: "https://pdt.agenas.it/come-fare-per/esenzioni",
            titolo: "Vai al sito della tua ASL per sapere che esenzioni ti spettano",
            testo: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        }
    ]
    const optionsLinks = [
        { linkCollegato: "https://pdt.agenas.it/come-fare-per/prenotare-online" },
        { linkCollegato: "https://pdt.agenas.it/come-fare-per/prenotare-CUP" },
        { linkCollegato: "https://pdt.agenas.it/come-fare-per/esenzioni" }
    ]

    // Gestione pulsanti
    const btnPrimary = [
        {
            id: "salva", color: "secondary", outline: true, title: "Salva modifiche", disabled: false,
            onClickHandler: () => {
                /* Da collegare al servizio */
            }
        },
        {
            id: "3", color: "secondary", title: "Visualizza anteprima", disabled: false,
            onClickHandler: () => {
                /* Da collegare al servizio */
            }
        }

    ]
    const btnSecondary = [
        {
            id: operazioniContenuto.RIPRISTINA.toLowerCase(), color: "secondary", outline: false, disabled: true, title: operazioniContenuto.RIPRISTINA,
            onClickHandler: () => {
                /* Da collegare al servizio */
            }
        },
        {
            id: "pubblica", color: "secondary", outline: false, title: "Pubblica", disabled: false,
            onClickHandler: () => {
                /* Da collegare al servizio */
            }
        }
    ]

    // State
    const [contentFetched, setContentFetched] = useState(false);
    const [name, , nameChangedHandler] = useInput("Come fare per");
    const [nameNotEditable, setNameNotEditable] = useToggle(true);
    const [, setRedirect] = useState(false);
    const [, dispatch] = useContext(Context);
    const [action, setAction] = useState("");
    const [show, , handleClose, handleShow] = useModal(false);
    const [data, setData, removeData, addData, updateData,] = useObjectsArray([], null, blankPageItem)

    useEffect(() => {
        if (!contentFetched) {
            onLoadHandler(setContentFetched, setData, mockData)
        }
    }, [contentFetched]);

    return (
        <>
            <div id="comeFarePer">
                <div className="row">
                    <div id={"headerText"} className="col-6">
                        <h5>{props.title}</h5>
                    </div>
                    <div id={"goBack"} className="col-6">
                        <a id={"buttonBack"} onClick={() => getModal(setAction, operazioniContenuto.INDIETRO, handleShow)}> <Icon className="right" color="black" icon="it-arrow-left" id="arrow" />Indietro</a>
                    </div>
                </div>
                <div className="row">
                    <div id={"headerText"} className="col-9">
                        <TitleTemplate
                            name={name} icona={true} onChangeName={nameChangedHandler}
                            nameNotEditable={nameNotEditable} setNameNotEditable={setNameNotEditable}
                            ultimaModifica={ultimaModifica} utenteUltimaModifica={utenteUltimaModifica}
                        />
                        <div id={"infoText"}>
                            <i>{headerMsg}</i>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-9">
                        {data?.map((el, index) => (
                            <PageItem
                                index={index} label={el.label} updateData={updateData}
                                visibile={el.visibile} removeItem={removeData}
                                addItem={addData} titolo={el.titolo} testo={el.testo}
                                optionsLinks={optionsLinks} linkCollegato={el.linkCollegato}
                            />
                        ))}
                        <div className="addPageItem">
                            <button onClick={() => addData()}>
                                <Icon className="left" color="black" icon="it-plus-circle" id="arrowRight" />
                                Aggiungi nuovo
                            </button>
                        </div>
                    </div>
                    <div className="col-3">
                        <CustomModal
                            show={show}
                            onHide={handleClose}
                            onConfirm={() => callBackAndClose(handleClose, action, setRedirect, dispatch)}
                            title={action === operazioniContenuto.INDIETRO ? "Ritorno alla home page" : action} in corso
                            text={getModalMessage(action)}
                        />
                        <Card title={operazioniContenuto.PUBBLICAZIONE}>
                            <Publisher
                                arrayPrimary={btnPrimary} arraySecondary={btnSecondary}
                            />
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}

function onLoadHandler(setContentFetched, setData, mockData) {
    setData(mockData)
    setContentFetched(true)
}

function callBackAndClose(handleClose, action, setRedirect, dispatch) {
    if (action === operazioniContenuto.INDIETRO) {
        handleClose();
        setRedirect(true);
    }
}

function getModalMessage(action) {
    switch (action) {
        case operazioniContenuto.INDIETRO:
            return Messages.ERR_02_00_0A_0A
        default:
            return Messages.INFO_DEFAULT
    }
}
