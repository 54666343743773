import React from 'react';
import './LogoLoader.css';

export default function LogoLoader(props) {

    return (

        <div id="logoLoader" className="row">
            <div className="col-4">
                {props.preview &&
                    <>
                        <div className="box-preview">
                            <div id={"fileLoaderPreviewDiv"}>
                                <img src={props.previewUri ? props.previewUri : require('./../../img/banners/placeholder-image.png')} id={"logoLoaderPreview"} />
                            </div>
                        </div>
                    </>
                }
            </div>
            <div className="col-8">
                <form method="post" action="" encType="multipart/form-data">
                    <div id={"buttonLoad"}>
                        <input type="file" name={props.idLoad} id={props.idLoad} className="upload"
                            onChange={props.change}
                        />
                        <label id="btnLoad" htmlFor={props.idLoad}>
                            {props.name === null ? "carica" : "cambia"}
                        </label>
                    </div>
                </form>
            </div>
            <div className="col-12">
                <em>{props.tip}</em>
            </div>
        </div>
    )
}