import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Icon } from 'design-react-kit';
import './TextEditor.css';
import useModal from '../../../hook/useModal';
import CustomModal from '../../CustomModal/CustomModal';
import { operazioniContenuto } from '../../Utils/Dataset';
import { modules, config, configNews, configReadOnly } from '../../Utils/Dataset';
import { Messages } from '../../Utils/Messages';
import JoditEditor from "../JoditEditor/JoditEditor";

export default function TextEditor(props) {

    // Gestione finestre modali
    const [action, setAction] = useState("");
    const [show, , handleClose, handleShow] = useModal(false);
    const [indice, setIndice] = useState();



    return (
        <div className="textEditor">
            <CustomModal
                show={show}
                onHide={handleClose}
                onConfirm={() => callBackAndClose(handleClose, action, indice, props.delete)}
                title={action === operazioniContenuto.INDIETRO ? "Ritorno alla pagina principale" : action + " in corso"}
                text={getModalMessage(action)}
            />
            {props.array ? props.array.map((el, i) => {
                return <div key={el.uniqueId} id={el.uniqueId}>
                    {!props.hideTitle &&
                        <div className="customInput form-group">
                            <label className={"fieldLabel"}>TITOLO PARAGRAFO</label>
                            <input readOnly={props.readonly} id={"input_" + el.uniqueId} type="text" className={`form-control ${!props.valid[i] && props.indexOfPage ? `is-danger` : null}`} value={el.titolo ? el.titolo : ""} onChange={(e) => props.updateValue(i, "titolo", e.target.value, null, null)} disabled={props.disabled} />
                        </div>
                    }
                    <div className="row">
                        <div>
                            <label className="quillLabel">Testo del contenuto*</label>
                        </div>
                        {props.isErasable || i > 0 ?
                            <button onClick={() => getModal(setAction, operazioniContenuto.ELIMINAZIONE, handleShow, setIndice, i)}>
                                <Icon className="delete" color="black" icon="it-delete" id="delete" />
                            </button> : null}
                    </div>
                    {props.disabled ? <div dangerouslySetInnerHTML={{ __html: el.testo }}></div> :
                        <div>
                            <TextEditorItem readonly={props.readonly} testo={el.testo} index={i} updateValue={(updatedContent) => props.updateValue(i, "testo", updatedContent, null, null)} />
                        </div>
                    }
                    {!props.valid[i] ? <p className="error-text">È necessario inserire il testo del contenuto {!props.hideTitle && props.indexOfPage && 'e il titolo paragrafo'}</p> : null}
                </div>
            }
            ) :
                null
            }
            {props.click ?
                <div className="row add-new">
                    {!props.readonly ? <div className="add">
                        <button onClick={() => props.click()}>
                            <Icon className="left" color="black" icon="it-plus-circle" id="arrowRight" />
                    Aggiungi nuovo
                </button>
                    </div> : null}
                </div>
                : null}
        </div>
    )
}

export function TextEditorItem(props) {

    const [textEditorContent, setTextEditorContent] = useState(props.testo);

    useEffect(() => {
        props.updateValue(textEditorContent);
    }, [textEditorContent]);

    if (props.readonly) {
        return (
            <JoditEditor value={textEditorContent} tabindex={props.index} config={configReadOnly}
                onChange={(newContent => { setTextEditorContent(newContent) })} />
        );
    } else {
        return (
            <JoditEditor value={textEditorContent} tabindex={props.index} config={config}
                onChange={(newContent => { setTextEditorContent(newContent) })} />
        );
    }
}

function getModal(setAction, azione, handleShow, setIndice, indice) {
    setAction(azione);
    setIndice(indice)
    handleShow();
}

function getModalMessage(action) {
    switch (action) {
        case operazioniContenuto.ELIMINAZIONE:
            return Messages.INFO_DELETE_TESTO
        default:
            return Messages.INFO_DEFAULT
    }
}

function callBackAndClose(handleClose, action, indice, elimina) {
    if (action === operazioniContenuto.ELIMINAZIONE) {
        handleClose();
        elimina(indice)
    }
}
