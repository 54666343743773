import React from 'react';
import AttachmentTable from '../../UI/AttachmentsTable/AttachmentsTable';
import CustomInput from '../../UI/CustomInput/CustomInput';
import TextEditor from '../../UI/TextEditor/TextEditor';
import { addNewAttachmentRow, attachmentLoader } from '../TemplateUtils';
import './Template_Argomento_Generico.css';

export default function Template_Argomento_Generico(props) {

    // Template tipologia ARGOMENTO_GENERICO, ARGOMENTO_GENERICO_PORTALE & ARGOMENTO_GENERICO_CONTATTI

    return (
        <div id={"templateArgomentoGenerico"}>
            <div className="inputBoxWithShadow">
                <CustomInput id="input1" labelName="Titolo*" change={props.content.titleChangedHandler} name={props.content.title} valid={props.content.validateTitle} />
            </div>
            <TextEditor array={props.content.testo} hideTitle updateValue={props.content.updateTestoHandler} valid={props.content.validateTesto} />
            <div className={"inputBoxWithShadow"}>
                <AttachmentTable label={"Allegati"}
                    attachments={props.content.attachments}
                    attachmentLoader={(index) => attachmentLoader(index, props.content.onChangeCurrentAttachment, props.content.updateAttachment)}
                    removeAttachment={props.content.removeAttachment}
                    addElementRender={addNewAttachmentRow(props.content.addAttachment)}
                    updateAttachment={props.content.updateAttachment}
                />
            </div>
        </div>
    )

}