import React from 'react';
import { Toggle } from 'design-react-kit';
import './ServiziAS.css';

export default function ServiziAS(props) {

    return (
        <div id="serviziAS">
            <h5 className="headerTitle">Servizi disponibili</h5>
            <div className="toggleElement">
                <div className="switch">
                    <div>
                        <Toggle onChange={(e) => props.updateServizi(0, "centroTrasfusionale", props.servizi[0]?.centroTrasfusionale === "1" ? "0" : "1")} checked={props.servizi[0]?.centroTrasfusionale === "1" ? true : false} label={props.labelCT} /> {props.servizi[0]?.centroTrasfusionale === "1" ? "Disponibile" : "Non disponibile"}
                    </div>
                </div>
            </div>
            <div className="toggleElement">
                <div className="switch">
                    <div>
                        <Toggle onChange={(e) => props.updateServizi(0, "dialisi", props.servizi[0]?.dialisi === "1" ? "0" : "1")} checked={props.servizi[0]?.dialisi === "1" ? true : false} label={props.labelSD} /> {props.servizi[0]?.dialisi === "1" ? "Disponibile" : "Non disponibile"}
                    </div>
                </div>
            </div>
            <div className="toggleElement">
                <div className="switch">
                    <div>
                        <Toggle onChange={(e) => props.updateServizi(0, "rianimazione", props.servizi[0]?.rianimazione === "1" ? "0" : "1")} checked={props.servizi[0]?.rianimazione === "1" ? true : false} label={props.labelSR} /> {props.servizi[0]?.rianimazione === "1" ? "Disponibile" : "Non disponibile"}
                    </div>
                </div>
            </div>
            <div className="toggleElement">
                <div className="switch">
                    <div>
                        <Toggle onChange={(e) => props.updateServizi(0, "terapiaIntensivaNeonatale", props.servizi[0]?.terapiaIntensivaNeonatale === "1" ? "0" : "1")} checked={props.servizi[0]?.terapiaIntensivaNeonatale == 1 ? true : false} label={props.labelTIN} /> {props.servizi[0]?.terapiaIntensivaNeonatale === "1" ? "Disponibile" : "Non disponibile"}
                    </div>
                </div>
            </div>
        </div>
    )
}