import React from 'react';
import AttachmentTable from '../../UI/AttachmentsTable/AttachmentsTable';
import CustomInput from '../../UI/CustomInput/CustomInput';
import TextEditor from '../../UI/TextEditor/TextEditor';
import { addNewAttachmentRow, attachmentLoader } from '../TemplateUtils';
import './Template_FEEDBACK.css';

export default function Template_FEEDBACK(props) {

    // Template tipologia FEEDBACK

    return (
        <div id="TemplateFEEDBACK">
            <div className="inputBoxWithShadow">
                <CustomInput id="input0" labelName="Titolo*" change={props.content.titleChangedHandler} name={props.content.title} valid={props.content.validateTitle} />
            </div>

            <label className="text-type">testo*</label>
            <TextEditor isErasable={false} label={"testoSuperiore"} array={props.content.testo} click={props.content.addTestoHandler} delete={props.content.removeTestoHandler} updateValue={props.content.updateTestoHandler} valid={props.content.validateTesto} indexOfPage={props.content.index} />

            <div className={"inputBoxWithShadow"}>
                <AttachmentTable label={"Allegati"}
                    attachments={props.content.attachments}
                    attachmentLoader={(index) => attachmentLoader(index, props.content.onChangeCurrentAttachment, props.content.updateAttachment)}
                    removeAttachment={props.content.removeAttachment}
                    addElementRender={addNewAttachmentRow(props.content.addAttachment)}
                    updateAttachment={props.content.updateAttachment}
                />
            </div>
        </div>
    )
}

