import React from 'react';
import { Icon } from 'design-react-kit';
import './UnAuthorized.css';

export default function NoRoleDetected(props) {

    return (
        <div className="unAuthorized">
            <div className="inputBoxWithShadow">
                <Icon className="it-lock" color="black" icon="it-locked" id="lock" />
                <p>Accesso non autorizzato, è necessario rieffettuare l'autenticazione</p>
                <p>Se il problema persiste contattare l'amministratore</p>
            </div>
        </div>
    )
}