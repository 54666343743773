import React, { useEffect, useState } from 'react';
import Publisher from '../../../UI/Publisher/Publisher';
import Card from '../../../UI/Card/Card';
import './AnagraficaStrutture.css';
import useModal from '../../../../hook/useModal';
import FileRepository from '../../../../Repo/FileRepository';
import useFileHandler from '../../../../hook/useFileHandler';
import { BUCKET_URL } from '../../../../config/config';
import InformazioniGenerali from './InformazioniGenerali/InformazioniGenerali';
import ContattiAS from './ContattiAS/ContattiAS';
import useToggle from '../../../../hook/useToggle';
import OrganizzazioneAS from './OrganizzazioneAS/OrganizzazioneAS';
import { assetsEnum, operazioniContenuto } from '../../../Utils/Dataset';
import useObjectsArray from '../../../../hook/useObjectsArray';
import { checkEsitoAndShowMessageDefault, uploadImage } from '../../../Utils/Utils';
import StruttureRepository from '../../../../Repo/StruttureRepository';
import useInput from '../../../../hook/useInput';
import FileLoader from '../../../UI/FileLoader/FileLoader';
import CustomModal from '../../../CustomModal/CustomModal';
import { Messages } from '../../../Utils/Messages';
import LinkItem from '../../../UI/LinkItem/LinkItem';
import ServiziAS from './ServiziAS/ServiziAS';
import CustomInput from '../../../UI/CustomInput/CustomInput';
import ComeFarePerAS from './ComeFarePerAS/ComeFarePerAS';
import { hideLoading, showLoading } from '../../../../hook/globalState/Store';
import { validateLinksComeFarePer, validateUrl, validateUrlNotRequired } from '../../../UI/FieldValidation/FieldValidation';

export default function AnagraficaStrutture(props) {

    // Messaggi Header
    const headerText = "Modifica la scheda anagrafica";
    const headerMsg = "Qui puoi impostare i contenuti di competenza della Struttura selezionata. Se non inserisci alcun contenuto, questo non sarà visibile dal cittadino nelle sezioni specifiche.";

    // Gestione caricamento dati
    const idStruttura = props.match.params.id
    const [struttura, setStruttura, , , updateStruttura] = useObjectsArray([])
    const [contentFetched, setContentFetched] = useState(false)
    const [linkAsl, setLinkAsl, changeLinkAsl, validateLinkAsl] = useInput(null, validateUrlNotRequired)

    //Gestione finestre modali
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [action, setAction] = useState("");
    const [redirect, setRedirect] = useState()
    const [comeFareArray, setComeFareArray, removeComeFare, addComeFare, updateComeFare, validateComeFarePer] = useObjectsArray([], validateLinksComeFarePer);
    const [contatti, setContatti, removeContatti, addContatti, updateContatti] = useObjectsArray([]);
    const [organizzazione, setOrganizzazione, removeOrganizzazione, addOrganizzazione, updateOrganizzazione] = useObjectsArray([]);
    const [servizi, setServizi, removeServizi, addServizi, updateServizi] = useObjectsArray([]);
    const [cup, setCup, removeCup, addCup, updateCup] = useObjectsArray([]);
    const [catalogoLink, setCatalogoLink, removeCatalogoLink, addCatalogoLink, updateCatalogoLink] = useObjectsArray([]);

    // Gestione logo
    const [logo, setLogo] = useState(null)
    const [loadedAssets, setLoadedAssets] = useState([])
    const [currentAttachment, setCurrentAttachment, attachmentMetadata, setAttachmentMetadata, onChangeCurrentAttachment] = useFileHandler()
    const reloadImages = () => {
        FileRepository().getFile(assetsEnum.IMMAGINI)
            .then(data => setLoadedAssets(data.objects ? data.objects : []))
    }

    const InizializzaStatoStruttura = () => ({
        ...struttura[0],
        link: comeFareArray,
        contatti: contatti[0],
        servizi: servizi[0],
        organizzazione: organizzazione[0],
        comeFarePer: cup[0],
        sito: linkAsl,
        icona: logo
    }
    )

    // mock tempi di attesa
    const tempiAttesa = "http://www.aulss1.veneto.it/service/tempi-di-attesa";

    // Gestione pulsanti
    const btnPrimary = [
        {
            id: "1", color: "secondary", outline: true, title: "Salva modifiche", disabled: hasError(),
            onClickHandler: () => StruttureRepository().editStruttura(InizializzaStatoStruttura()).then(checkEsitoAndShowMessageDefault)
        }
    ]
    const btnSecondary = [
        {
            id: operazioniContenuto.ANNULLA_MODIFICHE.toLowerCase(), color: "secondary", outline: false, disabled: false, title: operazioniContenuto.ANNULLA_MODIFICHE,
            onClickHandler: () => { getModal(setAction, operazioniContenuto.ANNULLA_MODIFICHE, handleShow) }
        }
    ]

    useEffect(() => {
        if (!loadedAssets.length)
            reloadImages()
        else setTimeout(reloadImages, 5000) //se ho appena caricato/sto caricando un asset aspetto 5 secondi prima della get
    }, [currentAttachment])

    useEffect(() => {
        if (!contentFetched) {
            showLoading()
            onLoadHandler()
        }
    }, [contentFetched])


    const onLoadHandler = () => {

        Promise.all([
            StruttureRepository().getStruttura(idStruttura),
            StruttureRepository().getCatalogoLink()
        ]).then(([struttura, links]) => {
            // struttura.link = links.map(el => {
            //     let linkStruttura = struttura.link.find(link => link.id === el.id) ?? {}

            //     return {
            //         // ...el,
            //         id: linkStruttura.id ?? null,
            //         link: linkStruttura.link ?? null,
            //         applicabile: linkStruttura.applicabile ?? false
            //     }
            // })

            // Alphabetically sort
            var catalogoSort = links?.sort(function (a, b) { return (a.nome.toUpperCase() < b.nome.toUpperCase()) ? -1 : (a.nome.toUpperCase() > b.nome.toUpperCase()) ? 1 : 0; });
            var comeFareArraySort = catalogoSort.map(el => struttura.link?.find((el2, i) => (el.id === el2.id))).filter(el => el !== undefined);

            setCatalogoLink(links)
            setStruttura([struttura])
            setContatti([struttura?.contatti])
            setCup([struttura?.comeFarePer])
            setOrganizzazione([struttura?.organizzazione])
            setServizi([struttura?.servizi])
            setLinkAsl(struttura.sito)
            setComeFareArray(comeFareArraySort)
            setLogo(struttura.icona)
        }).catch(err => err)
            .finally(() => {
                setContentFetched(true)
                hideLoading()
            })
    }

    function hasError() {
        let valid = true;
        valid = !validateComeFarePer.includes(false) && validateLinkAsl && validateUrlNotRequired(organizzazione[0]?.primari) && validateUrlNotRequired(organizzazione[0]?.direzione)
        return !valid
    }

    // Sezione ASL
    const caricaLogo = (
        <div className="input-box">
            <div className="nome-asl">
                <label style={{ fontWeight: 500 }}>AZIENDA SANITARIA O STRUTTURA</label>
            </div>
            <div className="row">
                {logo ?
                    <>
                        <div id={"previewLogo"} className={"col-4"}>
                            <img src={BUCKET_URL + logo} id={"previewLogoImg"} alt={""} />
                        </div>
                    </> :
                    <>
                        <div id={"previewLogo"} className={"col-4"}>
                            <span>Nessun logo caricato</span>
                        </div>
                    </>
                }
                <div className="col-8">
                    <input
                        id={"siteAsl"}
                        value={linkAsl ? linkAsl : ""}
                        onChange={changeLinkAsl}
                        placeholder={"Inserire il sito dell'azienda sanitaria/struttura..."}
                    />
                    {!validateUrlNotRequired(linkAsl) && <i className="invalid-link">Inserire un sito valido che inizi con http/https</i>}
                    <div className={'row'}>
                        <FileLoader
                            name={null}
                            valid
                            idLoad="upload1"
                            change={(e) => onChangeCurrentAttachment(e, (fileBin, fileMetadata) => uploadImage(fileBin, setLogo))}
                            setFile={setLogo} type={assetsEnum.IMMAGINI}
                            loadedAssets={loadedAssets}
                            hideAlternativeText
                        />
                    </div>
                </div>
            </div>
        </div>
    )

    //COMPONENTE FINALE
    return (
        <div id="anagraficaStrutture">
            <CustomModal
                show={show}
                onHide={handleClose}
                onConfirm={() => callBackAndClose(handleClose, action, setRedirect, setContentFetched)}
                title={action === operazioniContenuto.INDIETRO ? "Ritorno alla pagina principale" : operazioniContenuto.ANNULLA_MODIFICHE}
                text={getModalMessage(action)}
            />
            <div className="row">
                <div id="headerText" className="col-9">
                    <h5>{headerText}</h5>
                    <div id={"infoText"}>
                        <i>{headerMsg}</i>
                    </div>
                    <div id={"asl"}>
                        {caricaLogo}
                    </div>
                    <div id="informazioni">
                        <InformazioniGenerali asl={struttura[0]} />
                    </div>
                    <div id="comeFarePer">
                        <ComeFarePerAS comeFareArray={comeFareArray} updateComeFare={updateComeFare} catalogoLink={catalogoLink} validateComeFarePer={validateComeFarePer} />
                    </div>
                    <div id="contatti">
                        <ContattiAS contatti={contatti} updateContatti={updateContatti} cup={cup} updateCup={updateCup} />
                    </div>
                    <div id="serviziDisponibili">
                        <ServiziAS servizi={servizi} updateServizi={updateServizi}
                            labelCT="Centro trasfusionale"
                            labelSD="Servizio dialisi"
                            labelSR="Servizio rianimazioni"
                            labelTIN="Terapia intensiva neonati" />
                    </div>
                    {/* <div id="tempiAttesa">
                        <h5 className="headerTitle">Tempi d'attesa</h5>
                        <CustomInput id="input1" labelName="Tempi d'attesa per prestazioni" name={tempiAttesa} valid={true} toggleEnabled={true} />
                        <CustomInput id="input2" labelName="Tempi d'attesa medi" name={tempiAttesa} valid={true} />
                    </div> */}
                    <div id="organizzazione">
                        <OrganizzazioneAS organizzazione={organizzazione} updateOrganizzazione={updateOrganizzazione} />
                    </div>
                </div>
                <div className="col-3">
                    <Card title={operazioniContenuto.MODIFICA}>
                        <Publisher
                            arrayPrimary={btnPrimary}
                            arraySecondary={btnSecondary}
                        />
                    </Card>
                </div>
            </div>
        </div>
    )
}

function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}

function callBackAndClose(handleClose, action, setRedirect, setContentFetched) {
    if (action === operazioniContenuto.INDIETRO) {
        handleClose();
        setRedirect(true);
    }

    if (action === "Invio") {
        //Chiamata al servizio
        /*  .then(result => {
                if (!hasErrorOutcome(result)) {
                    dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.SUCCESS, alertMsg: OK_RELEASE } });
                    setRedirect(true)
                } else showError(KO_RELEASE)
            }).catch((err) => showError(KO_RELEASE)) */
        handleClose();
    }

    if (action === operazioniContenuto.ANNULLA_MODIFICHE) {
        setContentFetched(false)
        // history.go(0)
        handleClose()
    }
}

function getModalMessage(action) {
    switch (action) {
        case operazioniContenuto.INDIETRO:
            return Messages.ERR_TMP_EDIT_LINK_AZIENDALI
        case operazioniContenuto.ANNULLA_MODIFICHE:
            return Messages.INFO_ANNULLA_MODIFICHE
        default:
            return Messages.INFO_DEFAULT
    }
}