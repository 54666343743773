import logoAbruzzo from '../img/regioni/logo-abruzzo.svg';
import logoAosta from '../img/regioni/logo-valleaosta.png';
import logoBasilicata from '../img/regioni/logo-basilicata.svg';
import logoBolzano from '../img/regioni/logo-bolzano.png';
import logoCalabria from '../img/regioni/logo-calabria.svg';
import logoCampania from '../img/regioni/logo-campania.svg';
import logoER from '../img/regioni/logo-er.png';
import logoFVG from '../img/regioni/logo-fvg.png';
import logoLazio from '../img/regioni/logo-lazio.svg';
import logoLiguria from '../img/regioni/logo-liguria.svg';
import logoLombardia from '../img/regioni/logo-lombardia.svg';
import logoMarche from '../img/regioni/logo-marche.png';
import logoMolise from '../img/regioni/logo-molise.svg';
import logoPiemonte from '../img/regioni/logo-piemonte.svg';
import logoPuglia from '../img/regioni/logo-puglia.svg';
import logoSardegna from '../img/regioni/logo-sardegna.svg';
import logoSicilia from '../img/regioni/logo-sicilia.svg';
import logoToscana from '../img/regioni/logo-toscana.svg';
import logoTrento from '../img/regioni/logo-trentino.svg';
import logoUmbria from '../img/regioni/logo-umbria.svg';
import logoVeneto from '../img/regioni/logo-veneto.png';
import jodit, { Jodit } from 'jodit';

export function getTemplatesEnum() {
    let templatesEnum =
        [
            { value: "TEMPLATE_1", nome: "Approfondimento", tipologiaEnum: "Approfondimento" },
            { value: "TEMPLATE_2", nome: "Dettaglio approfondimento", tipologiaEnum: "Dettaglio_approfondimento" },
            { value: "TEMPLATE_3", nome: "Come fare per", tipologiaEnum: "Come_fare_per" },
            { value: "TEMPLATE_3", nome: "Prestazioni garantite (ricerca)", tipologiaEnum: "Prestazione_garantita_ssn" },
            { value: "TEMPLATE_4", nome: "Vivere in Salute", tipologiaEnum: "Vivere_in_salute" },
            { value: "TEMPLATE_5", nome: "News", tipologiaEnum: "News" },
            { value: "TEMPLATE_5", nome: "Fake news", tipologiaEnum: "Fake_news" },
            { value: "TEMPLATE_3", nome: "Covid-19", tipologiaEnum: "COVID" },
            { value: "TEMPLATE_3", nome: "FAQ", tipologiaEnum: "FAQ" },
            { value: "TEMPLATE_ARGOMENTO_GENERICO", nome: "Feedback", tipologiaEnum: "FEEDBACK" },
            { value: "TEMPLATE_APPLICAZIONE_STRUTTURE", nome: "Applicazione custom - Come fare per", tipologiaEnum: "APPLICAZIONE_STRUTTURE" },
            { value: "TEMPLATE_APPLICAZIONE_STRUTTURE", nome: "Applicazione custom - Covid-19", tipologiaEnum: "APPLICAZIONE_STRUTTURE_COVID" },
            { value: "TEMPLATE_ARGOMENTO_GENERICO", nome: "Contenuto generico", tipologiaEnum: "ARGOMENTO_GENERICO_BOTTOM" },
            { value: "TEMPLATE_ARGOMENTO_GENERICO", nome: "Contenuto generico (contatti)", tipologiaEnum: "ARGOMENTO_GENERICO_CONTATTI" },
            { value: "TEMPLATE_ARGOMENTO_GENERICO", nome: "Contenuto generico (portale)", tipologiaEnum: "ARGOMENTO_GENERICO_PORTALE" }
        ]

    const getNomeFromValue = value => {
        return templatesEnum.filter(el => el.value.toLowerCase() === value.toLowerCase())[0]?.nome
    }

    const getTipologiaEnumFromValue = value => {
        return templatesEnum.filter(el => el.value.toLowerCase() === value.toLowerCase())[0]?.tipologiaEnum
    }

    const getNomeFromTipologia = nome => {
        return templatesEnum.filter(el => el.tipologiaEnum?.toLowerCase() === nome?.toLowerCase())[0]?.nome
    }

    return { templatesEnum, getNomeFromValue, getTipologiaEnumFromValue, getNomeFromTipologia }
}

export const pagineStatiche = Object.freeze({
    portale: 'Il Portale',
    "contatti-posta-elettronica": 'Contatti - Posta elettronica',
    "privacy-cookies": 'Privacy - Cookies',
    "note-legali": 'Note legali',
    contatti: 'Contatti',
    accessibilita: 'Accessibilità',
    redazione: 'La redazione',

    getValueOf(key) {
        return pagineStatiche[key]
    }
})
export const templatesEnum = getTemplatesEnum()

export const tipologiaNonCreabileEnum = Object.freeze({
    ERRORE: "ERRORE",
    ARGOMENTO_GENERICO_BOTTOM: "ARGOMENTO_GENERICO_BOTTOM",

    getValueOf(tipologia) {
        return tipologiaNonCreabileEnum[tipologia]
    }
})

export const templateNonCreabileEnum = Object.freeze({
    TEMPLATE_LINK: "TEMPLATE_LINK",

    getValueOf(template) {
        return templateNonCreabileEnum[template]
    }
})

export const tipologiaEnum = Object.freeze({
    APPROFONDIMENTO: 'APPROFONDIMENTO',
    DETTAGLIO_APPROFONDIMENTO: 'DETTAGLIO_APPROFONDIMENTO',
    COME_FARE_PER: 'COME_FARE_PER',
    PRESTAZIONE_GARANTITA_SSN: 'PRESTAZIONE_GARANTITA_SSN',
    VIVERE_IN_SALUTE: 'VIVERE_IN_SALUTE',
    NEWS: 'NEWS',
    FAKE_NEWS: 'FAKE_NEWS',
    LEA: 'LEA',
    APPLICAZIONE_STRUTTURE: 'APPLICAZIONE_STRUTTURE',
    CONTATTO_REGIONALE: 'CONTATTO_REGIONALE',
    APPLICAZIONE_LEA: 'APPLICAZIONE_LEA',
    COVID: 'COVID',
    APPLICAZIONE_STRUTTURE_COVID: "APPLICAZIONE_STRUTTURE_COVID",
    FAQ: 'FAQ',
    FEEDBACK: 'FEEDBACK',
    ARGOMENTO_GENERICO_BOTTOM: 'ARGOMENTO_GENERICO_BOTTOM',
    ARGOMENTO_GENERICO_CONTATTI: 'ARGOMENTO_GENERICO_CONTATTI',
    ARGOMENTO_GENERICO_PORTALE: 'ARGOMENTO_GENERICO_PORTALE',

    getValueOf(tipologia) {
        return tipologiaEnum[tipologia]
    }
})

export const statoContenuto = Object.freeze({
    BOZZA: 'BOZZA',
    PUBBLICATO: 'PUBBLICATO',
    SOSPESO: 'SOSPESO',
    VALIDAZIONE: 'VALIDAZIONE',
    PUBBLICAZIONE_PROGRAMMATA: 'PUBBLICAZIONE PROGRAMMATA',
    ELIMINATO: 'ELIMINATO',

    getValueOf(stato) {
        return statoContenuto[stato]
    }
})

export const roleEnum = Object.freeze({
    AMMINISTRATORE: 'PT-AMM',
    EDITORE: 'PT-EDI',
    //VALIDATORE: 'PT-VAL',
    AUTORE: 'PT-AUT',
    COLLABORATORE: 'PT-COL',
    //REF_REGIONALE: 'PT-REF',

    getValueOf(role) {
        return roleEnum[role]
    },

    parse(role) {
        switch(role) {
            case 'PT-AMM':
                return 'AMMINISTRATORE';
                break;
            case 'PT-EDI':
                return 'EDITORE';
                break;
            // case 'PT-VAL':
            //     return roleEnum.VALIDATORE;
            //     break;
            case 'PT-AUT':
                return 'AUTORE';
                break;
            case 'PT-COL':
                return 'COLLABORATORE';
                break;
            // case 'PT-REF':
            //     return roleEnum.REF_REGIONALE;
            //     break;
            default:
                return null;
        }
    }

})

export const operazioniAssociazione = Object.freeze({

    ANNULLA: "Annulla",
    CONFERMA_CREAZIONE: "Conferma creazione",
    CONFERMA_MODIFICA: "Conferma modifica",
    ELIMINA: "Elimina",

    getValueOf(operazione) {
        return operazioniAssociazione[operazione]
    }
})

export const statoFeedback = Object.freeze({
    ARCHIVIATO: "ARCHIVIATO",
    RICEVUTO: "RICEVUTO",

    getValueOf(stato) {
        return statoFeedback[stato]
    }
})

export const operazioniContenuto = Object.freeze({
    SALVA_MODIFICHE: 'Salva',
    MODIFICA: 'Modifica',
    PUBBLICAZIONE: 'Pubblicazione',
    PUBBLICAZIONE_PROGRAMMATA: 'Pubblicazione programmata',
    ELIMINAZIONE: 'Eliminazione',
    SOSPENSIONE_PUBBLICAZIONE: 'Sospensione della pubblicazione',
    INDIETRO: 'Indietro',
    SCADENZA_PROGRAMMATA: 'Scadenza programmata',
    RIPRISTINA: 'Ripristina',
    ANNULLA_MODIFICHE: 'Annulla modifiche',
    ANTEPRIMA: 'Anteprima',
    ELIMINAZIONE_LEA_NAZIONALE: 'Eliminazione lea nazionale',
    ARCHIVIAZIONE_FEEDBACK: 'Archiviazione feedback',

    getValueOf(operazione) {
        return operazioniContenuto[operazione]
    }
})

export const operazioniUtente = Object.freeze({
    MODIFICA: 'Modifica',
    CREA: 'Crea',
    CAMBIO_PASSWORD: 'Cambio password',

    getValueOf(operazione) {
        return operazioniUtente[operazione]
    }
})

export const operazioniAssociazioni = Object.freeze({
    ELIMINA: 'elimina'
})

export const operazioniStruttureCovid = Object.freeze({
    ELIMINA: 'elimina'
})

export const assetsEnum = Object.freeze({
    IMMAGINI: 'immagini',
    ICONE: 'icone',
    ALLEGATI: 'allegati',

    getValueOf(tipo) {
        return assetsEnum[tipo]
    }
})

export const linguaEnum = Object.freeze({
    INGLESE: 'EN',
    TEDESCO: 'DE',
    ITALIANO: 'IT',
    FRANCESE: "FR",

    getValueOf(value) {
        return linguaEnum[value]
    }
})

export const socialEnum = Object.freeze({
    FACEBOOK: 'Facebook',
    TWITTER: 'Twitter',
    YOUTUBE: 'Youtube',

    getValueOf(value) {
        return socialEnum[value]
    }
})

export const leaEnum = Object.freeze({
    AUSILI_MONOUSO: 'ausilimonouso',
    AUSILIO: 'ausilio',
    PRESTAZIONI: 'prestazioni',
    PRESIDI: 'presidi',
    AUSILI: 'ausili',

    getValueOf(value) {
        return leaEnum[value]
    }
})

export const operationId = Object.freeze({
    ELIMINA_LOGICAMENTE_CONENUTO: 'eliminaLogicamenteContenuto',
    PUBBLICA_CONTENUTO: 'pubblicaContenuto',
    SOSPENDI_CONTENUTO: 'sospendiContenuto',
    CREA_CONTENUTO: 'creaContenuto',
    RIPRISTINA_CONTENUTO_ELIMINATO_LOGICAMENTE: 'ripristinaContenutoEliminatoLogicamente',
    CREA_CATALOGO: 'creaCatalogo',
    ELIMINA_CATALOGO: 'eliminaCatalogo',
    MODIFICA_CONTENUTO: 'modificaContenuto',
    MODIFICA_HOME_PAGE: 'modificaHomePage',
    PUBBLICA_HOME_PAGE: 'pubblicaHomePage',
    MODIFICA_MENU: 'modificaMenu',
    PUBBLICA_MENU: 'pubblicaMenu',
    MODIFICA_CREA_STRUTTURA: 'modificaCreaStruttura',
    MODIFICA_AUSILIO_MONOUSO: 'modificaAusilioMonouso',
    MODIFICA_PRESTAZIONE: 'modificaPrestazione',

    getValueOf(value) {
        return operationId[value]
    }
})

export const cataloghiEnum = Object.freeze({
    FONTE: 'FONTE',
    CATEGORIA: 'CATEGORIA',

    getValueOf(value) {
        return cataloghiEnum[value]
    }
})

export const provenienzaEnum = Object.freeze({
    INTERNA: "Interna",
    ESTERNA: "Esterna",

    getValueOf(value) {
        return provenienzaEnum[value]
    }
})


export const leaMapping = Object.freeze({
    1: leaEnum.AUSILI_MONOUSO,
    26: leaEnum.PRESIDI,
    29: leaEnum.PRESTAZIONI,
    30: leaEnum.AUSILIO,
    84: leaEnum.AUSILIO,
    110: leaEnum.AUSILIO,


    getValueOf(value) {
        return leaMapping[value]
    }
})

export const extensionsFile = Object.freeze({
    IMMAGINI: ['jpg', 'jpeg', 'png'],
    ICONE: ['gif', 'png', 'jpeg', 'jpg'],
    ALLEGATI: ['doc', 'pdf', 'docx'],
    PDF: ['pdf'],

    getValuesOf(tipo) {
        return extensionsFile[tipo]
    }
})

export const areeTerritorialiEnum = Object.freeze({

    "130": { nome: "Abruzzo", logo: logoAbruzzo },
    "020": { nome: "Valle D'Aosta", logo: logoAosta },
    "170": { nome: "Basilicata", logo: logoBasilicata },
    "041": { nome: "Bolzano", logo: logoBolzano },
    "180": { nome: "Calabria", logo: logoCalabria },
    "150": { nome: "Campania", logo: logoCampania },
    "080": { nome: "Emilia-Romagna", logo: logoER },
    "060": { nome: "Friuli Venezia Giulia", logo: logoFVG },
    "120": { nome: "Lazio", logo: logoLazio },
    "070": { nome: "Liguria", logo: logoLiguria },
    "030": { nome: "Lombardia", logo: logoLombardia },
    "110": { nome: "Marche", logo: logoMarche },
    "140": { nome: "Molise", logo: logoMolise },
    "010": { nome: "Piemonte", logo: logoPiemonte },
    "160": { nome: "Puglia", logo: logoPuglia },
    "200": { nome: "Sardegna", logo: logoSardegna },
    "190": { nome: "Sicilia", logo: logoSicilia },
    "090": { nome: "Toscana", logo: logoToscana },
    "042": { nome: "Trento", logo: logoTrento },
    "100": { nome: "Umbria", logo: logoUmbria },
    "050": { nome: "Veneto", logo: logoVeneto },

    getValueOf(value) {
        return areeTerritorialiEnum[value]
    }

})

export const fonteEnum = Object.freeze(
    [{ "label": "MINISTERO", "value": "Ministero" },
    { "label": "PORTALE", "value": "Portale" },
    { "label": "REGIONE", "value": "Regione" }]
)

// export const modules = {
//     toolbar: [
//         [{ 'header': [1, 2, false] }],
//         ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//         [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
//         [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
//         ['link'],
//         ['clean']
//     ],
// }

Jodit.defaultOptions.controls.paragraph.list = {
    p: 'Normal',
    h4: 'Heading 4'
};


export const config = {
    placeholder: 'Inserire del testo...',
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    addNewLine: true,
    style: {
        textAlign: 'left',
        height: "250px"
    },
    language: 'it',
    readonly: false,
    "buttons": "paragraph,bold,strikethrough,underline,italic,|,ul,ol,|,outdent,indent,|,align,|,link",
    "buttonsMD": "paragraph,bold,strikethrough,underline,italic,|,ul,ol,|,outdent,indent,|,align,|,link",
    "buttonsSM": "paragraph,bold,strikethrough,underline,italic,|,ul,ol,|,outdent,indent,|,align,|,link",
    "buttonsXS": "paragraph,bold,strikethrough,underline,italic,|,ul,ol,|,outdent,indent,|,align,|,link",
    "disablePlugins": "errormessages,hr,media,imageproperties,imageprocessor,resizecells,selectcells,tablekeyboardnavigation,xpath,tooltip,symbols,resizehandler,size",
    showCharsCounter: false
}

export const configReadOnly = {
    readonly: true,
    placeholder: 'Inserire del testo...',
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    addNewLine: false,
    style: {
        textAlign: 'left',
        height: "250px"
    },
    language: 'it',
    "buttons": "paragraph,bold,strikethrough,underline,italic,|,ul,ol,|,outdent,indent,|,align,|,link",
    "buttonsMD": "paragraph,bold,strikethrough,underline,italic,|,ul,ol,|,outdent,indent,|,align,|,link",
    "buttonsSM": "paragraph,bold,strikethrough,underline,italic,|,ul,ol,|,outdent,indent,|,align,|,link",
    "buttonsXS": "paragraph,bold,strikethrough,underline,italic,|,ul,ol,|,outdent,indent,|,align,|,link",
    "disablePlugins": "errormessages,hr,media,imageproperties,imageprocessor,resizecells,selectcells,tablekeyboardnavigation,xpath,tooltip,symbols,resizehandler,size"

}

export const APPLICATION_NAME = "PDT";
export const blankOption = { link: null }
export const blankBranca = { id: null, descrizione: null }
export const blankNota = { id: null, descrizione: null, allegato: null, avviso: true }
export const blankLink = { descrizione: null, link: null, inNuovaFinestra: null }
export const blankQuill = { titolo: null, testo: null }
export const blankSocial = { titolo: null, descrizione: null, immagine: null }
export const blankTag = {}
export const blankGallery = { path: null, testoAlternativo: null, descrizione: null, link: null }
export const blankLinkRegionale = { bottomLabel: "", visibile: false, link: "" }
export const contentNuovaLingua = {
    id: null,
    path: null,
    nome: null,
    lingua: null,
    titolo: null,
    stato: null
}
export const blankAssociazione = {
    visibile: true,
    nominativo: "",
    citta: "",
    indirizzo: "",
    cap: "",
    telefonoStruttura: "",
    telefonoReferente: "",
    fax: "",
    mailStruttura: "",
    mailReferente: "",
    indirizzoWeb: ""
}
export const blankElementLea = {
    "codice": "",
    "descrizione": "",
    "quantitaErogabile": "",
    "tipologia": "LEA"
}

export const blankElementLeaExtra = {
    "codice": null,
    "descrizione": null,
    "iconaAreaTerritoriale": null,
    "id": null,
    "idAreaTerritoriale": null, //TODO: demockare
    "nomeAreaTerritoriale": null,
    "note": null,
    "branche": null
}

export const blankLeaNazionaleAusilio = {
    "codice": "",
    "idCategoria": null,
    "descrizione": "",
    "leaAssociati": null,
    "leaExtra": [],
    "ministeriale": false,
    "uniqueId": null

}

export const blankLeaNazionaleAusilioMonouso = {
    "idCategoria": null,
    "codice": null,
    "descrizione": null,
    "quantitaErogabile": null,
    "note": [],
    "ministeriale": false,
    "uniqueId": null
}

export const blankLeaNazionalePresidi = {
    "codice": "",
    "idCategoria": null,
    "descrizione": "",
    "leaExtra": [],
    "ministeriale": false,
    "uniqueId": null
}

export const blankLeaNazionalePrestazioni = {
    "codice": null,
    "idCategoria": null,
    "descrizione": null,
    "note": [],
    "leaExtra": [],
    "branche": [],
    "ministeriale": false,
    "uniqueId": null,
    "new": true
}

export const uploadLimits = {
    IMG_COPERTINA: 10485760,
    IMG: 2097152,
    ICON: 2097152,
    DOCUMENT: 10485760
}

export const typeOrder = {
    ASC: "asc",
    DESC: "desc",
    NONE: "none"
}

export const maxElementi = 2147483647; // Per le getAll, numero massimo di elementi = 2^31 - 1
export const dimensionePaginaLea = 25; // Per le getAll, numero massimo di elementi = 2^31 - 1
export const blankPageItem = { label: "", visibile: false, linkCollegato: "", titolo: "", testo: "" }
export const OK_OPERATION = "Operazione eseguita con successo"
export const KO_OPERATION = "Si è verificato un errore durante l'operazione"
export const OK_RELEASE = "Pubblicazione avvenuta con successo"
export const KO_RELEASE = "Si è verificato un errore durante la pubblicazione"
export const WAIT_FOR_OPERATION = "Elaborazione in corso, attendere il completamento..."
export const KO_TEMPLATE = "La tipologia selezionata non è tra quelle previste"
export const KO_FONTE = "Non è possibile eliminare una fonte selezionata"
export const KO_FONTE_ALTRO_CONTENUTO = "Non è possibile eliminare una fonte attiva su un altro contenuto"
export const KO_CATEGORIA_ALTRO_CONTENUTO = "Non è possibile eliminare una categoria attiva su un altro contenuto"
