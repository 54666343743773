import React, { useContext, useEffect, useState } from 'react';
import './Main.css';
import Menu from '../../UI/Menu/Menu';
import InPageAlert, { alertLevels } from "../../UI/InPageAlert/InPageAlert";
import { Context, scrollToTop } from "../../../hook/globalState/Store";
import OverlayModal from "../Overlay/OverlayModal";
import sideBarClose from '../../img/logos/sidebar-close.svg';
import HideMenu from '../../UI/HideMenu/HideMenu';
import MenuUtenteRegionale from '../../UI/MenuUtenteRegionale/MenuUtenteRegionale';
import HideMenuUtenteRegionale from '../../UI/HideMenuUtenteRegionale/HideMenuUtenteRegionale';
import SessioneUtente from '../../../sessionManager/SessioneUtente';
import { isUtenteRegionale } from "../../Utils/Utils";
import { useLocation } from 'react-router-dom';
import { areeTerritorialiEnum } from '../../Utils/Dataset';

export default function Main(props) {

    const [state, dispatch] = useContext(Context);
    const [alertLevel, setAlertLevel] = useState(alertLevels.NONE);
    const [alertMessage, setAlertMessage] = useState("");
    const location = useLocation();

    const session = SessioneUtente.getInstance();

    //Manages SHOW_ALERT and throws SCROLL_TOP
    useEffect(() => {
        setAlertLevel(state.alertLevel)
        setAlertMessage(state.alertMsg)
        scrollToTop()
    }, [state.alertLevel, state.alertMsg])

    //Manages SCROLL_TOP
    useEffect(() => {
        if (state.scrollTop) {
            window.scroll(0, 0)
            dispatch({ type: 'SCROLL_TOP', payload: { scrollTop: false } })
        }
    }, [state.scrollTop, location.pathname])

    function switchMenu() {
        dispatch({ type: "MENU", payload: { isMenuCollapsed: !state.isMenuCollapsed } });
    }

    if (session) {
        return (
            <div id="corpoPagina">
                {state.isLoading && <OverlayModal />}
                {isUtenteRegionale(session.ruoliUtente) != null ? isUtenteRegionale(session.ruoliUtente) ?
                    <div id="sideMenu" className={state.isMenuCollapsed ? "collapsed" : ""}>
                        {/* Utente Regionale menu */}

                        <MenuUtenteRegionale collapsed={state.isMenuCollapsed} ruolo={session.ruoliUtente} idRegione={session.idAreaTerritoriale} logo={areeTerritorialiEnum[session.idAreaTerritoriale]?.logo} regione={areeTerritorialiEnum[session.idAreaTerritoriale]?.nome} />
                        {state.isMenuCollapsed ?
                            <HideMenuUtenteRegionale click={() => switchMenu()} ruolo={session.ruoliUtente} /> :
                            <div className="menu-state collapse-menu-action-icon" id="hideMenu" title="Nascondi Menu" onClick={() => switchMenu()}>
                                <span><img className="menu-state-icon" src={sideBarClose} /> Nascondi Menu</span>
                            </div>
                        }
                    </div> :
                    <div id="sideMenu" className={state.isMenuCollapsed ? "collapsed" : ""}>
                        {/* Utente Amministrativo menu */}

                        <Menu collapsed={state.isMenuCollapsed} />
                        {state.isMenuCollapsed ?
                            <HideMenu click={() => switchMenu()} /> :
                            <div className="menu-state collapse-menu-action-icon" id="hideMenu" title="Nascondi Menu" onClick={() => switchMenu()}>
                                <span><img className="menu-state-icon" src={sideBarClose} /> Nascondi Menu</span>
                            </div>
                        }
                    </div> : null}

                <div id="mainContent" className="container-fluid">
                    <InPageAlert level={alertLevel} message={alertMessage}>
                        <div className="row">
                            <div className="col-12">

                                {props.children}

                            </div>
                        </div>
                    </InPageAlert>
                </div>
                <div className="clearfix"></div>
            </div>
        )
    }
    else {
        return <></>;
    }
}
