import React, { useState, useContext, useEffect } from 'react';
import useInput from '../../../../hook/useInput';
import useToggle from '../../../../hook/useToggle';
import TitleTemplate from '../../../UI/TitleTemplate/TitleTemplate';
import { Context, hideLoading, showError, showLoading, showSuccess } from '../../../../hook/globalState/Store';
import useModal from '../../../../hook/useModal';
import { Icon, Button, Toggle, Chip, ChipLabel } from 'design-react-kit';
import './PageTable.css';
import CustomModal from '../../../CustomModal/CustomModal';
import { Messages } from '../../../Utils/Messages';
import { Redirect, useHistory } from 'react-router-dom';
import useObjectsArray from '../../../../hook/useObjectsArray';
import LeaRepository from '../../../../Repo/LeaRepository';
import Table from '../../../UI/Table/Table';
import SessioneUtente from '../../../../sessionManager/SessioneUtente';
import {
    operazioniContenuto,
    blankElementLea,
    tipologiaEnum,
    KO_OPERATION,
    blankElementLeaExtra,
    blankLeaNazionaleAusilio,
    blankLeaNazionaleAusilioMonouso,
    blankLeaNazionalePresidi,
    blankLeaNazionalePrestazioni,
    roleEnum, leaEnum, blankNota, blankBranca, blankPrestazioniLeaExtra, areeTerritorialiEnum, maxElementi, dimensionePaginaLea
} from '../../../Utils/Dataset';
import { checkEsitoAndShowMessageDefault, isUtenteRegionale } from "../../../Utils/Utils";
import { useAllBranche, useAllNote } from '../../../UI/Menu/MenuHooks';
import Select from '../../../UI/Select/Select';


export default function PageTable(props) {

    //sessione
    let session = SessioneUtente.getInstance();

    //btnDisable
    const [disabledAddButton, setDisabledAddButton] = useState(false);

    let isRegionale = isUtenteRegionale(session.ruoliUtente);

    const headerMsg = "In questa sezione puoi gestire la disposizione dei contenuti nella tabella selezionata.";

    // Gestione ultima modifica
    const [ultimaModifica, setUltimaModifica] = useState();
    const [utenteUltimaModifica, setUtenteUltimaModifica] = useState();

    //paginaCorrente
    const [paginaCorrente, setPaginaCorrente] = useState(1);
    //pagineTotali
    const [pagineTotali, setPagineTotali] = useState();
    const [elementiTotali, setElementiTotali] = useState();


    function updatePaginaCorrente(indicePagina) {
        setPaginaCorrente(indicePagina)
        setContentFetched(false)
    }


    // State
    const [name, setName, nameChangedHandler] = useInput("");
    const [nameNotEditable, setNameNotEditable] = useToggle(true);
    const [redirect, setRedirect] = useState(false);
    const [state, dispatch] = useContext(Context);
    const [action, setAction] = useState("");
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [params, setParams] = useState();
    const [branche, setBranche, removeBranca, addBranca] = useState("");
    const [contentFetched, setContentFetched] = useState(false);
    const [brancheFetched, setBrancheFetched] = useState(false);
    const [noteFetched, setNoteFetched] = useState(false);
    const history = useHistory();

    const [allBranche, loadedAllBranche, setAllBranche, allBrancheLoadedCompleted] = useAllBranche();
    const [brancheInput, setBrancheInput, brancheInputChangeHandler] = useInput("")
    // const [note, setNote] = useAllNote();
    const [nota, setNota, notaChangeHandler] = useInput("");
    const [noteAggiunte, setNoteAggiunte, removeNoteAggiunteHandler, addNoteAggiunteHandler, ,] = useObjectsArray([], () => { }, blankNota);
    const [brancheAggiunte, setBrancheAggiunte, removeBrancheAggiunteHandler, addBrancheAggiunteHandler, ,] = useObjectsArray([], () => { }, blankBranca);
    //TODO: rimuovere mock note
    const [allNote, loadedAllNote, setAllNote, allNoteLoadedCompleted] = useAllNote();



    const [categoria, setCategoria] = useObjectsArray();
    const [categoriaVisibile, setCategoriaVisibile] = useState(false)

    const [updateCategoria, setUpdateCategoria] = useState(false)

    const [elements, setElements, removeElement, addElement, updateElement] = useObjectsArray(null);

    //Gestione tabella
    const [editIndex, setEditIndex] = useState(null)
    const [editExtraLeaIndex, setEditExtraLeaIndex] = useState(null)

    const [brancaSelezionata, setBrancaSelezionata, brancaSelezionataChangeHandler] = useInput("")

    const iconaExtraLea = require('../../../img/logos/Icona_ExtraLEA.png')

    useEffect(() => {
        if (props.tipoLea === leaEnum.PRESTAZIONI && brancaSelezionata) {
            setPaginaCorrente(1);
            setContentFetched(false);
        }
    }, [brancaSelezionata])

    const onLoadHandler = () => {
        allBrancheLoadedCompleted(true)
        allNoteLoadedCompleted(true)
        showLoading()

        const queryParams = {
            idCategoria: props.idCategoria,
            numeroElementi: dimensionePaginaLea,
            numeroPagina: paginaCorrente,
            associati: props.tipoLea === leaEnum.AUSILIO ? true : false
        }

        if (props.tipoLea === leaEnum.PRESTAZIONI && brancaSelezionata) {
            queryParams.idBranca = brancaSelezionata
        }

        const loadLea =
            LeaRepository().getLea(queryParams, props.tipoLea) //aggiungere valore per paginazione (numero pagina)
                .then(result => {

                    let lea = result?.elementi;
                    let pagineTot = result?.pagineTotali;
                    let elementiTot = result?.elementiTotali;
                    /* INIZIO: se l'utente è regionale filtriamo mostrando solo gli ExtraLEA della sua areaTerritoriale */
                    //if (SessioneUtente.getInstance().idAreaTerritoriale !== "000") {
                    if (isRegionale) {
                        for (let i = 0; i < lea.length; ++i) {
                            if (lea[i].leaExtra && lea[i].leaExtra.length) {
                                lea[i].leaExtra = lea[i].leaExtra.filter(x => x.idAreaTerritoriale === SessioneUtente.getInstance().idAreaTerritoriale)
                            }
                        }
                    }
                    /* FINE: se l'utente è regionale filtriamo mostrando solo gli ExtraLEA della sua areaTerritoriale */

                    setPagineTotali(pagineTot)
                    setElementiTotali(elementiTot)
                    setElements(lea)
                    setBranche(lea?.map((el) => el.branche))

                    //TODO
                    //Se gli extra lea associati a LEA senza quantità possono avere il campo quantità erogabile, togliere l'if seguente//
                    // if (lea && lea.length && lea[0].quantitaErogabile === undefined) {
                    //     cols.splice(2, 1) //se non c'è la quantità la tolgo dalle colonne
                    //     setCols([...cols])
                    // }
                })

        const loadCategoria =
            LeaRepository().getCategoria(props.idCategoria)
                .then(result => {
                    setCategoria(result)
                    setCategoriaVisibile(result?.visibile ?? false)
                })

        Promise.all([loadLea, loadCategoria])
            .finally(() => {
                setContentFetched(true)
                hideLoading()
            })


    }

    useEffect(() => {
        if (!contentFetched)
            onLoadHandler()
        else {
            setName(categoria?.nome)
        }
    }, [contentFetched])

    //Gestione categoria visibile toggle

    const updateCategoriaVisibile = () => {
        setCategoriaVisibile(!categoriaVisibile)
        setUpdateCategoria(true)
    }

    useEffect(() => {
        if (updateCategoria) {
            showLoading()
            LeaRepository()
                .editVisibilitaCategoria(props.idCategoria, categoriaVisibile)
                .then(checkEsitoAndShowMessageDefault)
                .catch(() => setCategoriaVisibile(!categoriaVisibile)) //ripristino se non è andata a buon fine
                .finally(() => {
                    setUpdateCategoria(false)
                    hideLoading()
                })
        }

    }, [updateCategoria])

    const validateExtraLEA = row => row.quantitaErogabile?.length ?? false
    const validateDescrizioneExtraLEA = row => row.descrizione?.length > 0 ? true : false
    const validateCodiceLeaNazionale = row => row.codice?.length > 0 ? true : false

    const saveExtraLEAHandler = (leaExtra, tipoLea, extraLeaIndex, rowIndex) => {

        let leaNazionale = elements[rowIndex]

        let idAreaTerritoriale = SessioneUtente.getInstance().idAreaTerritoriale;

        showLoading()
        LeaRepository().editExtraLea(leaNazionale, idAreaTerritoriale, tipoLea)
            .then(checkEsitoAndShowMessageDefault)
            .finally(() => {
                hideLoading()
                setContentFetched(false)
            })
        setEditIndex(null)
        setEditExtraLeaIndex(null)

    }

    const deleteExtraLeaHandler = (leaExtra, tipoLea, removeRow, rowIndex) => {

        let leaNazionale = elements[rowIndex]

        showLoading()
        LeaRepository().deleteExtraLea(leaNazionale, SessioneUtente.getInstance().idAreaTerritoriale, leaExtra.id, tipoLea)
            .then(checkEsitoAndShowMessageDefault)
            .finally(() => {
                hideLoading()
                setContentFetched(false)
            })
    }

    const deleteLeaNazionale = (content, tipoLea) => {

        showLoading()
        LeaRepository().deleteLea(content, tipoLea)
            .then(checkEsitoAndShowMessageDefault)
            .finally(() => {
                hideLoading()
                setContentFetched(false)
            })
    }

    const saveLEANazionale = (id, tipoLea, noteAggiunte, brancheAggiunte, setBrancheFetched, setBrancheAggiunte, setNota, setBrancheInput) => {

        setNota("")
        setBrancheInput("")

        let toSave = elements.find(el => el.id === id)

        if (tipoLea === leaEnum.PRESTAZIONI) {
            toSave = {
                id: toSave.id ? toSave.id : null,
                codice: toSave.codice,
                idCategoria: toSave.idCategoria,
                descrizione: toSave.descrizione,
                note: noteAggiunte?.length > 0 ? noteAggiunte : [],
                branche: brancheAggiunte?.length > 0 ? brancheAggiunte : []
            }
        }
        showLoading()

        LeaRepository().saveLea(toSave, tipoLea)
            .then(checkEsitoAndShowMessageDefault)
            .finally(() => {
                hideLoading()
                setEditIndex(null)
                setEditExtraLeaIndex(null)
                setBrancheFetched(false)
                setNoteFetched(true)
                setNoteAggiunte([])
                setBrancheAggiunte([])
                setDisabledAddButton(false)
                setContentFetched(false)
            })
    }


    const saveLEAHandler = (id, tipoLea, noteAggiunte, brancheAggiunte, setBrancheFetched, setBrancheAggiunte, setNota, setBrancheInput) => {

        setNota("")
        setBrancheInput("")

        let toSave = elements.find(el => el.id === id)

        if (tipoLea === leaEnum.PRESTAZIONI) {

            toSave = {
                id: id,
                descrizione: toSave.descrizione,
                note: noteAggiunte?.length > 0 ? noteAggiunte : [],
                branche: brancheAggiunte?.length > 0 ? brancheAggiunte : []
            }
        }
        showLoading()

        LeaRepository().saveLea(toSave, tipoLea)
            .then((result) => {
                setEditIndex(null)
                setEditExtraLeaIndex(null)
                setBrancheFetched(false)
                setNoteFetched(true)
                setNoteAggiunte([])
                setBrancheAggiunte([])
                setContentFetched(false)
                checkEsitoAndShowMessageDefault(result)
            })
            .finally(hideLoading)
    }

    function isValidElement(id) {
        let toCheck = elements.find(el => el.id === id)

        if (toCheck.codice?.length > 0 && toCheck.descrizione?.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    function isMinisteriale(id) {
        let toCheck = elements.find(el => el.id === id)

        if (toCheck.ministeriale) {
            return true;
        } else {
            return false;
        }
    }


    //RENDER TABLE ////////////////////////////////////////////////////////////////////////////////////////////////
    const cellActions = (isEditing, col, row, rowIndex, tipoLea) => {
        let editButton = !isRegionale ? isEditing ?
            <>
                {isMinisteriale(row.id) ?
                    <>
                        <Button id={col.id} color={"secondary"} outline tag="button" onClick={() => saveLEAHandler(row.id, props.tipoLea, noteAggiunte, brancheAggiunte, setBrancheFetched, setBrancheAggiunte, setNota, setBrancheInput)}>{"Conferma"}</Button>
                        <Button id={row.id} color={"secondary"} outline tag="button" onClick={() => {
                            getModal(setAction, operazioniContenuto.ANNULLA_MODIFICHE, handleShow)
                        }}>
                            {"Annulla"}
                        </Button>
                    </> :
                    <>
                        <Button id={col.id} color={"secondary"} disabled={isValidElement(row.id)} outline tag="button" onClick={() => saveLEANazionale(row.id, props.tipoLea, noteAggiunte, brancheAggiunte, setBrancheFetched, setBrancheAggiunte, setNota, setBrancheInput)}>{"Conferma"}</Button>
                        <Button id={row.id} color={"secondary"} outline tag="button" onClick={() => {
                            getModal(setAction, operazioniContenuto.ANNULLA_MODIFICHE, handleShow)
                        }}>
                            {"Annulla"}
                        </Button>
                    </>
                }
            </>
            : isMinisteriale(row.id) ?
                <>
                    <Button id={col.id} color={"secondary"} outline tag="button" onClick={() => {
                        setBrancheAggiunte(row.branche)
                        setNoteAggiunte(row.note)
                        setEditIndex(rowIndex)
                        setEditExtraLeaIndex(null)
                    }} disabled={editIndex !== null}>{"Modifica"}</Button>
                </> :
                <>
                    <Button id={col.id} color={"secondary"} outline tag="button" onClick={() => {
                        setBrancheAggiunte(row.branche)
                        setNoteAggiunte(row.note)
                        setEditIndex(rowIndex)
                        setEditExtraLeaIndex(null)
                    }} disabled={editIndex !== null}>{"Modifica"}</Button>
                    <Button id={row.id} color={"danger"} disabled={isEditing} outline tag="button" onClick={() => getModal(setAction, operazioniContenuto.ELIMINAZIONE_LEA_NAZIONALE, handleShow,
                        { row: row, tipoLea: props.tipoLea })}>{"Elimina"}</Button>
                </>
            : null


        let addExtraLeaButton = isRegionale ?
            <>
                <Button id={col.id} color={"primary"} outline tag="button"
                    disabled={editIndex !== null}
                    onClick={() => addExtraLea(row, rowIndex, props.tipoLea)}
                >{"+ Extra Lea"}</Button>
            </> : null

        return <>{editButton}{addExtraLeaButton}</>

    }

    //Bottoni normali
    const cellActionsExtraLEA = (isEditing, col, row, rowIndex, extraLeaIndex, tipoLea) => {
        let stato = null

        let editButton = isRegionale ? isEditing ?
            <div>
                <Button id={col.id} color={"secondary"} outline tag="button"
                    onClick={() => getModal(setAction, operazioniContenuto.SALVA_MODIFICHE, handleShow, { row: row, tipoLea: props.tipoLea, extraLeaIndex: extraLeaIndex, rowIndex: rowIndex })}
                    disabled={props.tipoLea === leaEnum.PRESTAZIONI ? false : !validateExtraLEA(row)
                    }
                >
                    {stato = "Conferma"}
                </Button >
                <Button id={row.id} color={"secondary"} outline tag="button" onClick={() => {
                    getModal(setAction, operazioniContenuto.ANNULLA_MODIFICHE, handleShow)
                }}>
                    {stato = "Annulla"}
                </Button>
            </div>
            : <Button id={col.id} color={"secondary"} outline tag="button" onClick={() => {
                setEditIndex(rowIndex)
                setEditExtraLeaIndex(extraLeaIndex)
            }} disabled={editIndex !== null}>{stato = "Modifica"}
            </Button> : null
        let deleteButton = isRegionale ? <Button id={row.id} color={"danger"} outline tag="button" onClick={() => getModal(setAction, operazioniContenuto.ELIMINAZIONE, handleShow, { row: row, tipoLea: props.tipoLea, removeRow: removeRow, rowIndex: rowIndex })}>{"Elimina"}</Button> : null

        return <>{editButton}{stato === "Modifica" ? deleteButton : null}</>

    }



    //Cella non edit
    const standardCell = (el, row) => <span dangerouslySetInnerHTML={{ __html: `<span>${row[el.key] ? row[el.key] : ""}</span>` }} />
    const arrayCell = (el, row, field) => <span dangerouslySetInnerHTML={{ __html: `<span>${row[el.key] && row[el.key]?.length > 0 ? row[el.key].map((el2) => el2[field]) : ""}</span>` }} />

    //Cella in edit
    const cellEditing = (isExtra, el, row, rowIndex, extraLeaIndex, isDescrizione, isCodice) => {

        let text = row[el.key] ? row[el.key] : ''
        if (el.key === "codice")
            text = text.replace(/\./g, '') //tolgo i punti dal codice

        const changeHandler = (e) => {
            isExtra ? updateElement(rowIndex, 'leaExtra', e.target.value, extraLeaIndex, el.key) : updateElement(rowIndex, el.key, e.target.value)
        }

        let isDanger = '';
        if (isDescrizione) {
            isDanger = validateDescrizioneExtraLEA(row) ? '' : ' is-danger';
        } else {
            isDanger = validateExtraLEA(row) ? '' : ' is-danger'
        }

        if (isCodice) {
            isDanger = validateCodiceLeaNazionale(row) ? '' : 'is-danger';
        }

        return <>
            <textarea
                className={`form-control` + isDanger}
                placeholder={el.label}
                defaultValue={text} //"value" fa spostare il cursore alla fine ad ogni render
                type={"text"}
                onChange={(e) => changeHandler(e)}
            />
            {isDanger && <p className={'is-danger'}>{"Il campo " + el.label + " non è ben valorizzato"}</p>}
        </>
    }

    const brancheCellEditing = (el, row, rowIndex, allBranche, brancheInput, setBrancheInput, brancheInputChangeHandler, brancheAggiunte, addBrancheAggiunteHandler, removeBrancheAggiunteHandler, brancheFetched, setBrancheFetched, extraLeaIndex) => {

        if (row.new) {
            setBrancheAggiunte([])
            setNoteAggiunte([])
            row.new = false
        }

        if (!brancheFetched && row[el.key]?.length > 0) {
            row[el.key].map(el =>
                !brancheAggiunte.includes(el) ? addBrancheAggiunteHandler(el) : null
            )
            if (row[el.key].length === brancheAggiunte.length) {
                setBrancheFetched(true)
            }
        }

        function addBranche(el, row, brancheInput, allBranche, brancheAggiunte, addBrancheAggiunteHandler) {

            var newBranca = allBranche.filter(x => x.id == brancheInput)
            if (newBranca.length > 0) {
                if (!brancheAggiunte.includes(newBranca[0]))
                    addBrancheAggiunteHandler(newBranca[0])
            }
        }

        return (
            <div className="container">
                {brancheAggiunte?.length ? brancheAggiunte.map((el, i) => {
                    return (
                        <div key={el.id} className="brancheCards">
                            <label className="content">{el.descrizione}
                                <button className="close" onClick={() => removeBrancheAggiunteHandler(i)}>
                                    <Icon className="left" color="black" icon="it-close" id="delete" />
                                </button>
                            </label>
                        </div>
                    )
                }) : null}
                <div className="row d-flex pt-lea-aggiungi-branca">
                    <div className="col-12 testo-istruzioni">Per aggiungere una branca, seleziona la branca desiderata e clicca su Aggiungi</div>
                    <div className="col-8">
                        <Select idOption={"brancheId"} placeholder="branche..." array={allBranche} value={brancheInput} change={brancheInputChangeHandler} fieldValue={"id"} fieldName={"descrizione"} />
                    </div>
                    <div className="col-4">
                        <Button id="addBranche" className="btn btn-outline-secondary" onClick={() => addBranche(el, row, brancheInput, allBranche, brancheAggiunte, addBrancheAggiunteHandler)} disabled={brancheInput ? false : true}>Aggiungi</Button>
                    </div>
                </div>
            </div>
        )
    }

    const arrayCellEditing = (el, row, rowIndex, allNote, nota, setNota, notaChangeHandler, noteAggiunte, addNoteAggiunteHandler, removeNoteAggiunteHandler, noteFetched, setNoteFetched, extraLeaIndex) => {

        if (!noteFetched && row[el.key]?.length > 0) {
            row[el.key].map(el =>
                !noteAggiunte.includes(el) ? addNoteAggiunteHandler(el) : null
            )
            if (row[el.key].length === noteAggiunte.length) {
                setNoteFetched(true)
            }
        }

        function addNote(el, nota, allNote, noteAggiunte, addNoteAggiunteHandler, removeNoteAggiunteHandler) {

            var newNota = allNote.filter(x => x.id === nota)
            if (newNota.length > 0) {
                if (!noteAggiunte.includes(newNota[0]))
                    addNoteAggiunteHandler(newNota[0])
            }
        }

        return (
            <div className="container">
                {noteAggiunte?.length ? noteAggiunte.map((el, i) => {
                    return (
                        <div key={el.id} className="noteCards">
                            <label className="content">{el.id}
                                <button className="close" onClick={() => removeNoteAggiunteHandler(i)}>
                                    <Icon className="left" color="black" icon="it-close" id="delete" />
                                </button>
                            </label>
                        </div>
                    )
                }) : null}
                <div className="row d-flex pt-lea-aggiungi-nota">
                    <div className="col-12 testo-istruzioni">Per aggiungere una nota, seleziona la nota desiderata e clicca su Aggiungi</div>
                    <div className="col-7">
                        <Select idOption={"selectId"} placeholder="note..." array={allNote} value={nota} change={notaChangeHandler} fieldValue={"id"} fieldName={"id"} />
                    </div>
                    <div className="col-5">
                        <Button id="add" className="btn btn-outline-secondary" onClick={() => addNote(el, nota, allNote, noteAggiunte, addNoteAggiunteHandler, removeNoteAggiunteHandler)} disabled={nota ? false : true}>Aggiungi</Button>
                    </div>
                </div>
            </div>
        )
    }


    const removeRow = (rowIndex) => {
        let tmp = elements[rowIndex];
        tmp.extraLea = null;
        updateElement(rowIndex, 'leaExtra', null)

    }

    const addExtraLea = (clicked, rowIndex, tipoLea) => {
        let selected = elements.find(el => el.id === clicked.id)
        blankElementLeaExtra["idAreaTerritoriale"] = session.idAreaTerritoriale;
        //per mappare i campi branche e note di un extralea aspettare omologazione del servizio
        // blankElementLeaExtra["note"] = selected?.note ? selected?.note.map(el => el.id) : null;
        // blankElementLeaExtra["branche"] = selected?.branche ? selected?.branche.map(el => el.descrizione) : null;
        selected.leaExtra.push({ ...blankElementLeaExtra, idPadre: clicked.id, id: null, codice: selected.codice, descrizione: selected.descrizione })
        setElements([...elements])
        setEditIndex(rowIndex)
        setEditExtraLeaIndex(selected.leaExtra?.length - 1)
    }

    const addLeaNazionale = (rowIndex, tipoLea) => {
        switch (tipoLea) {
            case leaEnum.AUSILIO:
                blankLeaNazionaleAusilio["idCategoria"] = props.idCategoria
                setElements([{ ...blankLeaNazionaleAusilio }, ...elements])
                setEditIndex(rowIndex)
                setDisabledAddButton(true)
                break;
            case leaEnum.AUSILI_MONOUSO:
                blankLeaNazionaleAusilioMonouso["idCategoria"] = props.idCategoria
                setElements([{ ...blankLeaNazionaleAusilioMonouso }, ...elements])
                setEditIndex(rowIndex)
                setDisabledAddButton(true)
                break;
            case leaEnum.PRESIDI:
                blankLeaNazionalePresidi["idCategoria"] = props.idCategoria
                setElements([{ ...blankLeaNazionalePresidi }, ...elements])
                setEditIndex(rowIndex)
                setDisabledAddButton(true)
                break;
            case leaEnum.PRESTAZIONI:
                blankLeaNazionalePrestazioni["uniqueId"] = makeUniqueId(6)
                blankLeaNazionalePrestazioni["idCategoria"] = props.idCategoria
                setEditIndex(rowIndex)
                setDisabledAddButton(true)
                setElements([{ ...blankLeaNazionalePrestazioni }, ...elements])
                break;
            default:
                console.log("tipologia non valida");
        }

    }

    function makeUniqueId(length) {
        let result = "";
        let characters = "abcdef0123456789";
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const addRow = (rowIndex) => {
        let tmp = [...elements]
        tmp.splice(rowIndex + 1, 0, { ...blankElementLea, new: true });
        setElements(tmp)
    }

    const isAssociato = (row) => {
        return row?.tipologia === "LA"
    }

    const renderRow = (row, rowIndex, cols) => {
        let currentExtraLeaArray = row.leaExtra;
        if (!(row && cols)) return <></>
        else return <>
            <tr id={`tr${row.id ? row.id : row.uniqueId}${rowIndex}`} key={`tr${row.id ? row.id : row.uniqueId}${rowIndex}`} className={isAssociato(row) ? "associato" : ""}>
                {cols.map(col => renderLEA(col, row, rowIndex))}
            </tr>
            {currentExtraLeaArray && currentExtraLeaArray.map((extraLea, index) => (
                <tr key={`tr${props.id}${rowIndex}extraLea${index}`} className={'extraLEA'}>
                    {cols.map(col => renderExtraLEA(col, extraLea, rowIndex, index))}
                </tr>
            ))}
        </>
    }

    const renderLEA = (col, row, rowIndex) => {
        let cell = standardCell(col, row) //cella standard con label

        if (col.key === "branche") {
            cell = arrayCell(col, row, "descrizione")
        } else if (col.key === "note") {
            cell = arrayCell(col, row, "id")
        }

        if (rowIndex === editIndex && editExtraLeaIndex === null) { //editing attivo sulla riga
            if (col.key === "ACTIONS") {
                cell = cellActions(true, col, row, rowIndex, props.tipoLea) //bottoni per riga con editing in corso (true)
            } else if (!row.ministeriale && col.key === "codice") {
                cell = cellEditing(false, col, row, rowIndex, null, true, true)
            } else if (col.key === "descrizione") {
                cell = cellEditing(false, col, row, rowIndex, null, true, false)
            } else if (col.key === "quantitaErogabile") {
                cell = cellEditing(false, col, row, rowIndex, null, false, false) //text editor per quantità
            } else if (col.key === "note") {
                cell = arrayCellEditing(col, row, rowIndex, allNote, nota, setNota, notaChangeHandler, noteAggiunte, addNoteAggiunteHandler, removeNoteAggiunteHandler, noteFetched, setNoteFetched)
            } else if (col.key === "branche") {
                cell = brancheCellEditing(col, row, rowIndex, allBranche, brancheInput, setBrancheInput, brancheInputChangeHandler, brancheAggiunte, addBrancheAggiunteHandler, removeBrancheAggiunteHandler, brancheFetched, setBrancheFetched)
            } else cell = standardCell(col, row) //cella standard con label
        } else if (col.key === "ACTIONS")
            cell = cellActions(false, col, row, rowIndex, props.tipoLea) //bottoni per riga con editing non in corso (false)

        if (col.key === "codice" && row.leaExtra?.length)
            cell = <>
                {cell}
                <img className={'iconaExtraLea'} src={iconaExtraLea} />
            </>

        return <td className={col.key + (rowIndex === editIndex && editExtraLeaIndex === null ? ' activeRow' : '')} key={`td$lea${rowIndex}-${col.key}`}>{cell}</td>
    }

    const renderExtraLEA = (col, row, rowIndex, extraLeaIndex) => {
        let tmpRow = { ...row, codice: row.codice + " " }
        let cell = standardCell(col, tmpRow) //cella standard con label

        if (rowIndex === editIndex && editExtraLeaIndex === extraLeaIndex) { //editing attivo sulla riga
            if (col.key === "ACTIONS") {
                cell = cellActionsExtraLEA(true, col, tmpRow, rowIndex, extraLeaIndex, props.tipoLea) //bottoni per riga con editing in corso (true)
            } else if (col.key === "quantitaErogabile") {
                cell = cellEditing(true, col, tmpRow, rowIndex, extraLeaIndex, false, false) //text editor per quantità
            } else if (col.key === "descrizione") {
                cell = cellEditing(true, col, tmpRow, rowIndex, extraLeaIndex, true, false)
            } else cell = standardCell(col, tmpRow) //cella standard con label
        } else if (col.key === "ACTIONS")
            cell = cellActionsExtraLEA(false, col, tmpRow, rowIndex, extraLeaIndex, props.tipoLea) //bottoni per riga con editing non in corso (false)

        if (col.key === "codice") {
            cell = (
                <>
                    {cell}
                    <img src={areeTerritorialiEnum[row.idAreaTerritoriale]?.logo} title={areeTerritorialiEnum[row.idAreaTerritoriale]?.nome} style={{ width: "24px", height: "24px" }} alt={""} />
                </>
            )
        }

        return <td className={col.key + (rowIndex === editIndex && editExtraLeaIndex === extraLeaIndex ? ' activeRow' : '')} key={`td$lea${rowIndex}-${col.key}`}>{cell}</td>

    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [cols, setCols] = useState([
        { label: "Codice", key: "codice" },
        { label: "Descrizione", key: "descrizione" },
        props.tipoLea === leaEnum.AUSILI_MONOUSO ? { label: "Quantità erogabile", key: "quantitaErogabile" } : {},
        { label: "", key: "ACTIONS" }
    ])
    const [colsPrestazioni, setColsPrestazioni] = useState([
        { label: "Codice", key: "codice" },
        { label: "Descrizione", key: "descrizione" },
        { label: "Note", key: "note" },
        { label: "Branche", key: "branche" },
        { label: "", key: "ACTIONS" }
    ])

    return (
        redirect ? <Redirect to="/" /> :
            <div id="pageTable">
                <div className="row">
                    <div className="col-6">
                        <h5>{props.title}</h5>
                    </div>
                    <div id={"goBack"} className="col-6">
                        <a id={"buttonBack"} onClick={() => getModal(setAction, operazioniContenuto.INDIETRO, handleShow)}>
                            <Icon className="right" color="black" icon="it-arrow-left" id="arrow" />
                            Indietro
                        </a>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <TitleTemplate
                            name={name} icona={true} onChangeName={nameChangedHandler}
                            nameNotEditable={nameNotEditable} setNameNotEditable={setNameNotEditable}
                            ultimaModifica={ultimaModifica} utenteUltimaModifica={utenteUltimaModifica}
                        />
                        <div id={"infoText"}>
                            <i>{headerMsg}</i>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="etichettaCategoria">
                            <label>{categoria?.descrizione?.toUpperCase()}</label>
                        </div>
                        <div className="col-12">
                            {props.tipoLea === leaEnum.PRESTAZIONI ?
                                <Select placeholder="Selezionare una branca per filtrare..." array={allBranche} value={brancaSelezionata} change={brancaSelezionataChangeHandler} fieldValue={"id"} fieldName={"descrizione"} /> :
                                null
                            }
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="btnAddMinisteriale">
                            {!isRegionale ? <Button id="btnLeaNazionale" color={"primary"} outline tag="button"
                                onClick={() => addLeaNazionale(0, props.tipoLea)} disabled={disabledAddButton}
                            >{"Aggiungi LEA Nazionale"}</Button> : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 table">
                        {contentFetched && categoria && elements &&
                            <Table
                                id={"tableLea"}
                                pagination={dimensionePaginaLea}
                                disablePagination={editIndex !== null}
                                elements={elements}
                                cols={props.tipoLea === leaEnum.PRESTAZIONI ? colsPrestazioni : cols}
                                renderRow={renderRow}
                                setPaginaCorrente={updatePaginaCorrente}
                                paginaCorrente={paginaCorrente}
                                totalElements={elementiTotali}
                                nBackendPages={pagineTotali}
                            />
                        }
                    </div>
                </div>
                <CustomModal
                    show={show}
                    onHide={handleClose}
                    onConfirm={() => callBackAndClose(handleClose, action, setRedirect, dispatch, history)}
                    title={action === operazioniContenuto.INDIETRO ? "Indietro" : action} in corso
                    text={getModalMessage(action)}
                />
            </div>
    )

    function getModal(setAction, azione, handleShow, params) {
        setAction(azione);
        setParams(params)
        handleShow();
    }

    async function callBackAndClose(handleClose, action, setRedirect, dispatch, history) {

        if (action === operazioniContenuto.INDIETRO) {
            handleClose();
            history.goBack();
        } else if (action === operazioniContenuto.ELIMINAZIONE) {
            deleteExtraLeaHandler(params.row, params.tipoLea, params.removeRow, params.rowIndex)
            handleClose();
        } else if (action === operazioniContenuto.ELIMINAZIONE_LEA_NAZIONALE) {
            deleteLeaNazionale(params.row, params.tipoLea)
            handleClose();
        } else if (action === operazioniContenuto.SALVA_MODIFICHE) {
            saveExtraLEAHandler(params.row, params.tipoLea, params.extraLeaIndex, params.rowIndex)
            handleClose()
        } else if (action === operazioniContenuto.ANNULLA_MODIFICHE) {
            setContentFetched(false)
            setEditIndex(null)
            setEditExtraLeaIndex(null)
            setDisabledAddButton(false)
            handleClose()
        }

    }

    function getModalMessage(action) {
        switch (action) {
            case operazioniContenuto.INDIETRO:
                return Messages.ERR_INDIETRO_LEA
            case operazioniContenuto.ELIMINAZIONE:
                return Messages.INFO_ELIMINAZIONE_EXTRALEA
            case operazioniContenuto.SALVA_MODIFICHE:
                return Messages.INFO_SALVA_EXTRALEA
            case operazioniContenuto.ANNULLA_MODIFICHE:
                return Messages.INFO_ANNULLA_EXTRALEA
            default:
                return Messages.INFO_DEFAULT
        }
    }

}


