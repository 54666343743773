import React from 'react';
import TextEditor from '../../UI/TextEditor/TextEditor';
import './Template_02.css';
import CustomInput from '../../UI/CustomInput/CustomInput';
import FileLoader from '../../UI/FileLoader/FileLoader';
import ButtonLink from '../../UI/ButtonLink/ButtonLink';
import ToggleItem from '../../UI/ToggleItem/ToggleItem';
import SocialSharing from '../../UI/SocialSharing/SocialSharing';
import { Toggle } from 'design-react-kit';
import Index from '../../UI/Index/Index';
import Tag from '../../UI/Tag/Tag';
import { assetsEnum, uploadLimits } from "../../Utils/Dataset";
import { uploadImage } from '../../Utils/Utils';
import { BUCKET_URL } from "../../../config/config";
import { Messages } from '../../Utils/Messages';
import CustomAutocompleteInput from "../../UI/CustomAutocompleteInput/CustomAutocompleteInput";
import CustomAppWrapper from '../../UI/CustomAppWrapper/CustomAppWrapper';
import AttachmentTable from '../../UI/AttachmentsTable/AttachmentsTable';
import { addNewAttachmentRow, attachmentLoader } from '../TemplateUtils';

export default function Template_02(props) {

    const uploadFileCopertina = (file) => uploadImage(file, props.content.setFileCopertina, uploadLimits.IMG_COPERTINA)

    const candidatiPadri = props.content.contenutiPerIdPadre ?
        props.content.contenutiPerIdPadre.map(x => ({ ...x, value: x.id })) :
        [];

    //GESTIONE AUTOCOMPLETE ////////////////////////////////////////////////////////////////////////////////////////////
    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();

        if (inputValue.length < 3) {
            return [];
        }

        return candidatiPadri.filter(el => el.titolo?.toLowerCase().indexOf(inputValue) > -1 || el.nome?.toLowerCase().indexOf(inputValue) > -1);
    };
    const onSuggestionSelected = (event, { suggestion }) => props.content.setApprofondimentoPadre(suggestion.id)
    const inputProps = {
        id: 'approfondimentoPadreAutocompleteInputContainer',
        placeholder: 'Digitare il nome o il titolo del contenuto padre...',
        value: props.content.nomeContenutoPadre
    };

    const renderSuggestion = (suggestion, { query }) => {
        return (<span title={suggestion.titolo}>{suggestion.nome + " (" + suggestion.tipologia + ")"}</span>);
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <>
            <div id="Template2">
                <div className="inputBoxWithShadow">
                    <CustomInput id="input1" labelName="Titolo*" change={props.content.titleChangedHandler} name={props.content.title} valid={props.content.validateTitle} />
                    <CustomInput id="input2" labelName="Sottotitolo" change={props.content.subTitleChangedHandler} name={props.content.subTitle} valid={props.content.validateSubTitle} />
                    <CustomInput id="input3" labelName="DESCRIZIONE (non visibile sul Portale)" change={props.content.descriptionChangedHandler} name={props.content.description} valid={props.content.validateDescription} textarea />
                    <CustomInput id="input4" labelName="Url*" change={props.content.pathChangedHandler} name={props.content.path} valid={props.content.validatePath} tipologia={props.content.tipologia} setPath={props.content.setPath} approfondimentoPadre={props.content.approfondimentoPadre} invalidMsg={props.content.invalidMsg ?? props.content.invalidMsg} setInvalidMsg={props.content.setInvalidMsg} />

                    <CustomAutocompleteInput
                        title={"Contenuto padre*"}
                        getSuggestions={getSuggestions}
                        getSuggestionValue={(suggestion => suggestion.nome)}
                        inputProps={inputProps}
                        infoMessage={Messages.INFO_CONTENUTO_PADRE}
                        valid={props.content.validateApprofondimentoPadre}
                        onSuggestionSelected={onSuggestionSelected}
                        renderSuggestion={renderSuggestion}
                        setApprofondimentoPadre={props.content.setApprofondimentoPadre}
                    />
                </div>

                <FileLoader labelName="IMMAGINE DI COPERTINA*" tip={Messages.SIZE_CONSIGLIATA_IMMAGINE_COPERTINA} idLoad="upload1" change={(e) => props.content.onChangeCurrentAttachment(e, (fileBin, fileMetadata) => uploadFileCopertina(fileBin))} name={props.content.fileCopertina} valid={props.content.validateCopertina} alternativeText={props.content.alternativeTextImgCopertina} changeAlternativeText={props.content.alternativeTextImgCopertinaChangedHandler} setFile={props.content.setFileCopertina} type={assetsEnum.IMMAGINI}
                    loadedAssets={props.content.loadedAssets} preview previewUri={props.content.fileCopertina ? BUCKET_URL + props.content.fileCopertina : null}
                />

                <div className="row inputBoxWithShadow d-flex">
                    <ToggleItem inputType={"date"} toggleName={"Data"} toggleValue={props.content.toggleDate} formValue={props.content.inputDate} changeToggle={props.content.setToggleDate} changeValue={props.content.inputDateChangedHandler} id={"toggleDate"} valid={props.content.validateInputDate} />
                    <ToggleItem inputType={"text"} toggleName={"Tempo di lettura"} toggleValue={props.content.toggleReading} formValue={props.content.tempoLettura} changeToggle={props.content.setToggleReading} changeValue={props.content.tempoLetturaChangedHandler} id={"toggleText"} valid={props.content.validateTempoLettura} rejectNaN={props.content.rejectNaN} />
                </div>

                <div className={'inputBoxWithShadow'}>
                    <Tag dynamicOptions id={"sourceTag"} options={props.content.tags} onAdd={(chip) => props.content.addTag(chip)} onDelete={props.content.removeTag} value={props.content.newTag} change={props.content.tagChangedHandler} infoMessage={Messages.INFO_TAG} />
                </div>

                <label className="text-type">testo*</label>
                <TextEditor isErasable={false} array={props.content.testo} click={props.content.addTestoHandler} delete={props.content.removeTestoHandler} updateValue={props.content.updateTestoHandler} valid={props.content.validateTesto} indexOfPage={props.content.index} />

                <div id={"pageIndex"} className={'inputBoxWithShadow'}>
                    <Toggle label={"Indice della pagina"} checked={props.content.index} onChange={props.content.setIndex} />
                    <div className={'d-flex'}>
                        {props.content.index && <Index array={props.content.testo} />}
                    </div>
                </div>

                <div className="inputBoxWithShadow">
                    <CustomAppWrapper options={props.content.arrayCustomApp} renderAppCTA name="CTA" array={props.content.customCTAs} click={props.content.addCustomCTA} delete={props.content.removeCustomCTA} restore={props.content.restoreDescrizioneCTA} disable={props.content.disableDescrizioneCTA}
                        isToggleLinkVisibile={true} toggleNameCta={"CTA a fondo testo"} toggleNameLink={"Link a inizio pagina"} changeToggleCta={props.content.changeCtaCustomCTA} changeToggleLink={props.content.changeLinkToggleCTA} changeDescrizioneCustomApp={props.content.changeDescrizioneCustomCTA} changeLinkDaCollegare={props.content.changeLinkCTA} valid={props.content.validateCTA} infoMessage={Messages.INFO_APPLICAZIONE_CUSTOM} />
                </div>

                <div className={"inputBoxWithShadow"}>
                    <AttachmentTable label={"Allegati"}
                        attachments={props.content.attachments}
                        attachmentLoader={(index) => attachmentLoader(index, props.content.onChangeCurrentAttachment, props.content.updateAttachment)}
                        removeAttachment={props.content.removeAttachment}
                        addElementRender={addNewAttachmentRow(props.content.addAttachment)}
                        updateAttachment={props.content.updateAttachment}
                    />
                </div>

                <div className={'inputBoxWithShadow'}>
                    <ButtonLink links={props.content.contenutiCorrelati} click={props.content.addContenutiCorrelatiHandler} delete={props.content.removeContenutiCorrelatiHandler} updateValue={props.content.updateContenutiCorrelati} name="contenuti correlati" initialize="Non sono presenti contenuti correlati." valid={props.content.validateContenutiCorrelati} messaggioValidazione={Messages.VALIDAZIONE_INDIRIZZO_INTERNO} />
                    <ButtonLink links={props.content.sitiCertificati} click={props.content.addSitiCertificatiHandler} delete={props.content.removeSitiCertificatiHandler} updateValue={props.content.updateSitiCertificati} name="altri siti certificati" initialize="Non sono presenti altri siti certificati." valid={props.content.validateSitiCertificati} messaggioValidazione={Messages.VALIDAZIONE_INDIRIZZO_INTERNO_ESTERNO} />
                </div>

                <div className={'inputBoxWithShadow'}>
                    <SocialSharing labelName={"CONDIVISIONE SUI SOCIAL"} valueUrl={props.content.path} socials={props.content.socials} updateSocial={props.content.updateSocial} titolo={props.content.title} descrizione={props.content.description} immagine={props.content.fileCopertina} onChangeCurrentAttachment={props.content.onChangeCurrentAttachment} loadedAssets={props.content.loadedAssets} />
                </div>

            </div>
        </>
    )
}
