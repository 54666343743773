import React from 'react';
import LinkList from '../../UI/LinkList/LinkList';
import { Overlay, Popover } from 'react-bootstrap';
import './CustomOverlay.css';
import { Link } from 'react-router-dom';

export const CustomOverlay = React.forwardRef((props, ref) => {

    return (
        <Overlay target={props.target?.current} show={props.show} placement="right" rootClose={true} onHide={() => { props.setShow(false) }}>
            <Popover className={props.popoverClassName}>
                <Popover.Content>
                    {props.nested ?
                        props.menu.map((el, i) => <LinkList array={el.links} title={el.title} key={el.title + i} />) :
                        <ul>
                            {props.menu ? props.menu.map((el, i) => <li key={props.collapseId + "-" + i}>
                                <Link className="list-item direct-link" to={el.href} target={el.external ? '_blank' : "_self"}>
                                    {el.title}
                                </Link>
                            </li>) : null}
                        </ul>}
                </Popover.Content>
            </Popover>
        </Overlay>
    )
});