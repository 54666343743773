import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import './CustomModal.css';

export default function CustomModal(props) {

    // Se da problemi di visualizzazione => aggiungere position: fixed;

    return (
        <Modal dialogClassName={null} show={props.show} onHide={props.onHide} backdrop={true}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: props.text }}></div>
            </Modal.Body>
            <Modal.Footer>
                {!props.removeButtons &&
                    <>
                        <Button variant="secondary" onClick={props.onConfirm}>
                            Si, procedi
                        </Button>
                        <Button variant="secondary" onClick={props.onHide}>
                            Annulla
                        </Button>
                    </>
                }
                {props.buttonOk && <Button variant="secondary" onClick={props.onHide}>Ok</Button>}
            </Modal.Footer>
        </Modal>
    )

}