import React from 'react';
import SessioneUtente from '../../../sessionManager/SessioneUtente';
import { Route, Redirect } from 'react-router-dom';
import { roleEnum, tipologiaEnum } from '../../Utils/Dataset';
import UnAuthorized from '../../pages/UnAuthorized/UnAuthorized';


export default function ProfiledRoute(props) {

    //sessione
    let session = SessioneUtente.getInstance();

    let roles = session.ruoliUtente?.split(";");

    /*da togliere, solo per TEST in attesa dell'autenticazione funzionante*/
    // if (!session.ruoliUtente || session.ruoliUtente === "null" || session.ruoliUtente === "") {
    //     return (
    //         props.children
    //     )
    // }

    if (roles.length > 0) {
        if (roles.some((x) => props.roles.includes(x))) {
            return (
                props.children
            )
        } else {
            return <UnAuthorized role={session.ruoliUtente.replace(";", ", ")} />
        }
    }

}
