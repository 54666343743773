import React from 'react';
import CustomInput from '../../../../UI/CustomInput/CustomInput';
import './ContattiAS.css';

export default function ContattiAS(props) {

    return (
        <div id="contattiAS">
            <h5 className="headerTitle">Contatti</h5>
            <div className="row">
                <div className="col-3">
                    <CustomInput id="input1" labelName="Telefono Struttura" change={(e) => props.updateContatti(0, "telefono", e.target.value)} name={props.contatti[0]?.telefono} valid={true} />
                </div>
                <div className="col-3">
                    <CustomInput id="input2" labelName="Fax Struttura" change={(e) => props.updateContatti(0, "fax", e.target.value)} name={props.contatti[0]?.fax} valid={true} />
                </div>
                <div className="col-6">
                    <CustomInput id="input3" labelName="Indirizzo mail Struttura" change={(e) => props.updateContatti(0, "email", e.target.value)} name={props.contatti[0]?.email} valid={true} />
                </div>
            </div>
            <CustomInput id="input8" labelName="Indirizzo mail (PEC) Struttura" change={(e) => props.updateContatti(0, "pec", e.target.value)} name={props.contatti[0]?.pec} valid={true} />
            {/* <CustomInput id="input4" labelName="URP (Segnalazioni e reclami -URP)" name={props.asl.urp} valid={true} toggleEnabled={true} /> */}
            <div className="row">
                <div className="col-6">
                    <CustomInput id="input5" labelName="Indirizzo mail URP" change={(e) => props.updateContatti(0, "emailUrp", e.target.value)} name={props.contatti[0]?.emailUrp} valid={true} />
                </div>
                <div className="col-6">
                    <CustomInput id="input6" labelName="Telefono URP" change={(e) => props.updateContatti(0, "telefonoUrp", e.target.value)} name={props.contatti[0]?.telefonoUrp} valid={true} />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <CustomInput id="input5" labelName="Indirizzo mail CUP" change={(e) => props.updateCup(0, "email", e.target.value)} name={props.cup[0]?.email} valid={true} />
                </div>
                <div className="col-6">
                    <CustomInput id="input6" labelName="Telefono CUP" change={(e) => props.updateCup(0, "telefono", e.target.value)} name={props.cup[0]?.telefono} valid={true} />
                </div>
            </div>
            {/* <CustomInput id="input7" labelName="Carta dei sevizi (Amministrazione trasparente)" name={props.asl?.cartaServizi} valid={true} toggleEnabled={true} /> */}
        </div>
    )
}