import Sprite from "../../img/sprite.svg";
import React, { useEffect, useState } from "react";
import { DragDropContextRenderer, DraggableRenderer } from "../../DragDrop/DragDropUtils";

export default function ReorderList(props) {

    const [filterValue, setFilterValue] = useState("")
    const [filtered, setFiltered] = useState(null)

    const [page, setPage] = useState(0)
    const [elementsInView, setElementsInView] = useState([])

    //on props change
    useEffect(() => {
        setFiltered(null)
        setFilterValue("")
        setElementsInView(props.pagination ? [...props.elements].slice(0, props.pagination) : [...props.elements])
        setPage(0)
    }, [props.elements])

    //on page change
    useEffect(() => {
        if (props.pagination) {
            if (filtered)
                setElementsInView(filtered.slice(page * props.pagination, (page * props.pagination) + props.pagination))
            else setElementsInView([...props.elements].slice(page * props.pagination, (page * props.pagination) + props.pagination))
        }
    }, [page])


    const updateFilter = event => {
        setFilterValue(event.target.value)
        let tmpFiltered = props.elements?.filter(el => el.nomeContenuto.toLowerCase().trim().indexOf(event.target.value.toLowerCase().trim()) !== -1)
        setFiltered(tmpFiltered)
        setElementsInView(tmpFiltered.slice(page * props.pagination, (page * props.pagination) + props.pagination))
        setPage(0)
    }

    const prevPage = () => {
        if (page > 0)
            setPage(page - 1)
    }

    const nextPage = () => {
        if (filtered) {
            if (filtered.length / props.pagination > page + 1)
                setPage(page + 1)
        } else if (props.elements && props.elements.length / props.pagination > page + 1)
            setPage(page + 1)
    }

    const resetFilter = () => {
        updateFilter({ target: { value: "" } })
        setFiltered(null)
    }

    return (
        <DragDropContextRenderer
            dragEndHandler={props.dragEndHandler}
            droppableId={"reorderDroppable"}
        >
            {props.filter && (
                <span className={"d-flex"}>
                    <input type="text" placeholder={"Filtra elenco"} style={{ "background": "transparent" }} className={`form-control`} value={filterValue} onChange={updateFilter} />
                    <svg className="icon" id={"clearFilter"} onClick={() => resetFilter()}>
                        <use href={Sprite + "#it-close"} />
                    </svg>
                </span>
            )}
            <div className="element-box">
                <ul>
                    {elementsInView.map((el, index) => (
                        <DraggableRenderer
                            draggableId={"reorder" + index + "draggable"}
                            index={index}
                            disabled={(filterValue?.length ?? 0) > 0 || props.disabled}
                            key={"reorder-" + index}
                        >
                            <li className={"mt-2"}>
                                <span>{el.nomeContenuto}</span>
                                {props.showPlusButton && (
                                    <span onClick={() => {
                                        if (!props.pagination) props.unshiftElement(index)
                                        else props.unshiftElement(page * props.pagination + index)
                                    }}>
                                        <svg className="icon">
                                            <use xlinkHref={`${Sprite}#it-plus-circle`} />
                                        </svg>
                                    </span>
                                )}
                            </li>
                        </DraggableRenderer>
                    ))}
                    {!elementsInView.length && <span className={"mt-2"}>Nessun elemento da visualizzare</span>}
                </ul>
            </div>
            {props.pagination && props.elements?.length > props.pagination && (
                <div id={"pagination"} className={"mb-4"}>
                    <span>Pagina {page + 1}</span>
                    <span onClick={prevPage}>
                        <svg className="icon">
                            <use xlinkHref={`${Sprite}#it-chevron-left`} />
                        </svg>
                    </span>
                    <span onClick={nextPage}>
                        <svg className="icon">
                            <use xlinkHref={`${Sprite}#it-chevron-right`} />
                        </svg>
                    </span>
                </div>
            )}
        </DragDropContextRenderer>
    )
}
