import React, { useContext, useState } from 'react';
import './CambioPassword.css';
import useInput from '../../../hook/useInput';
import CustomInput from '../../UI/CustomInput/CustomInput';
import { Button } from 'design-react-kit';
import SessioneUtente from '../../../sessionManager/SessioneUtente';
import UtentiRepository from '../../../Repo/UtentiRepository';
import useModal from '../../../hook/useModal';
import { operazioniUtente } from '../../Utils/Dataset';
import { Messages } from '../../Utils/Messages';
import { Context, hideLoading, setForceLogout, showError, showLoading, showSuccess } from '../../../hook/globalState/Store';
import CustomModal from '../../CustomModal/CustomModal';
import { hasErrorOutcomeAPMS } from '../../Interceptor/Interceptor';
import { validatePassword } from '../../UI/FieldValidation/FieldValidation';

export default function CambioPassword(props) {

    //const [state, dispatch] = useContext(Context);

    const session = SessioneUtente.getInstance();

    //Const
    const headerMsg = "In questa pagina puoi cambiare la password di accesso al sistema"

    //Gestione finestre modali
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [action, setAction] = useState("");

    //State
    const [passwordCorrente, setPasswordCorrente, passwordCorrenteChange] = useInput("");
    const [nuovaPassword, setNuovaPassowrd, nuovaPasswordChange, validateNuovaPassword] = useInput("", validatePassword);
    const [confermaNuovaPassword, setConfermaNuovaPassword, confermaNuovaPasswordChange] = useInput("")

    const hasError = () => {
        return (
            !validateNuovaPassword ||
            passwordCorrente?.trim().length === 0 ||
            (confermaNuovaPassword !== nuovaPassword)
        )
    }

    const forceLogout = () => {
        setForceLogout();
    }

    return (
        <div className="cambio-password">
            <CustomModal
                show={show}
                onHide={handleClose}
                onConfirm={() => callBackAndClose(handleClose, action, inizializzaCambioPassword(passwordCorrente, nuovaPassword, session), forceLogout)}
                title={action + " in corso"}
                text={getModalMessage(action)}
                body={null}
            />
            <div className="row">
                <h4>{props.title}</h4>
            </div>
            <div className="row">
                <i>{headerMsg}</i>
            </div>
            <div>
                <strong>NOTA</strong>: Se procedi con la modifica della password, una volta completata l'operazione, sarà necessario effettuare un nuovo login utilizzando la nuova password.
            </div>
            <div>
                <div className="row">La password deve contenere almeno:</div>
                <ul>
                    <li>8 caratteri</li>
                    <li>Una lettera minuscola</li>
                    <li>Una lettera maiuscola</li>
                    <li>Un carattere speciale</li>
                    <li>Un numero</li>
                </ul>
            </div>
            <div className="row cambio-password-fields" >
                <div className="col-8">
                    <CustomInput id={"input1"} labelName={"Password corrente"} type="password" change={passwordCorrenteChange} name={passwordCorrente} valid={true} />
                    <CustomInput id={"input2"} labelName={"Nuova password"} type="password" change={nuovaPasswordChange} name={nuovaPassword} valid={validateNuovaPassword} invalidMsg={!validateNuovaPassword && "Inserire una password che rispetti i vincoli sopra indicati"} />
                    <CustomInput id={"input3"} labelName={"Conferma nuova password"} type="password" change={confermaNuovaPasswordChange} name={confermaNuovaPassword} valid={true} />
                    <Button className={"button-conferma"} disabled={hasError()} onClick={() => getModal(setAction, operazioniUtente.CAMBIO_PASSWORD, handleShow)}>{"Conferma"}</Button>
                </div>
            </div>
        </div>
    )
}

function inizializzaCambioPassword(passwordCorrente, nuovaPassword, sessioneUtente) {
    return Object.freeze({
        "accessToken": sessioneUtente.accessToken,
        "applicazione": "PDT",
        "codiceFiscale": sessioneUtente.codiceFiscale,
        "previousPassword": passwordCorrente,
        "proposedPassword": nuovaPassword
    })
}

function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}

function getModalMessage(action) {
    switch (action) {
        case operazioniUtente.CAMBIO_PASSWORD:
            return Messages.INFO_CAMBIO_PASSWORD
        default:
            return Messages.INFO_DEFAULT
    }
}

function callBackAndClose(handleClose, action, cambioPassword, forceLogout) {
    if (action === operazioniUtente.CAMBIO_PASSWORD) {
        handleClose();
        showLoading();
        UtentiRepository().cambioPassword(cambioPassword)
            .then(res => {
                if (res && !hasErrorOutcomeAPMS(res)) {
                    showSuccess();
                    forceLogout();
                }
            })
            .finally(() => hideLoading())
    }
}
