import React, { useState, useEffect, useContext } from 'react';
import useModal from '../../../../hook/useModal';
import './AggiungiAssociazione.css'
import Card from '../../../UI/Card/Card';
import Publisher from '../../../UI/Publisher/Publisher';
import Scheduled from '../../../UI/Scheduled/Scheduled';
import useInput from '../../../../hook/useInput';
import validate, { validateCAP, validateEmail, validateTelephone, validateUrl } from '../../../UI/FieldValidation/FieldValidation';
import LinkItem from '../../../UI/LinkItem/LinkItem'
import CustomInput from '../../../UI/CustomInput/CustomInput';
import useObjectsArray from '../../../../hook/useObjectsArray';
import {
    blankAssociazione,
    operazioniAssociazione,
    KO_OPERATION, OK_OPERATION, roleEnum
} from '../../../Utils/Dataset';
import { Button, Icon } from 'design-react-kit';
import { Link, Redirect } from 'react-router-dom';
import StruttureRepository from '../../../../Repo/StruttureRepository';
import SessioneUtente from '../../../../sessionManager/SessioneUtente';
import StruttureRegionali from '../StruttureRegionali/StruttureRegionali';
import AssociazioneForm from './AssociazioneForm';
import { Context, hideLoading, showError, showSuccess } from '../../../../hook/globalState/Store';
import CustomModal from '../../../CustomModal/CustomModal';
import { Messages } from '../../../Utils/Messages';
import { hasErrorOutcome } from '../../../Interceptor/Interceptor';
import { alertLevels } from '../../../UI/InPageAlert/InPageAlert';
import useToggle from '../../../../hook/useToggle';
import { rejectNaN, validateTelephoneNumber } from '../../../Utils/Utils';

export default function AggiungiAssociazione(props) {

    //Gestione finestre modali
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [action, setAction] = useState("");
    const [state, dispatch] = useContext(Context);
    const [redirect, setRedirect] = useState(false);

    // Headers
    const headerText = "Modifica i contenuti"
    const headerMsg = "Qui puoi inserire le associazioni regionali di riferimento per i Disturbi Alimentari. Se non inserisci alcun contenuto, questo non sarà visibile dal cittadino nelle sezioni specifiche."


    // Gestione caricamento/gestione dati associazione
    const idAssociazione = props?.match?.params?.id_associazione;
    let session = SessioneUtente.getInstance();
    let role = session.ruoliUtente;
    const idAreaTerritoriale = session.idAreaTerritoriale;
    const [contentFetched, setContentFetched] = useState(false)
    const [associazione, setAssociazione] = useState();

    // Sezione con campi dell'associazione da compilare
    const [nominativo, setNominativo, nominativoChangedHandler, validateNominativo] = useInput("", validate);
    const [comune, setComune, comuneChangedHandler, validateComune] = useInput("", validate);
    const [provincia, setProvincia, provinciaChangedHandler, validateProvincia] = useInput("", validate);
    const [indirizzo, setIndirizzo, indirizzoChangedHandler,] = useInput("");
    const [cap, setCap, capChangedHandler, validCap] = useInput("", validateCAP);
    const [telefonoStruttura, setTelefonoStruttura, telefonoStrutturaChangedHandler, validateTelefonoStruttura] = useInput("", validate);
    const [telefonoReferente, setTelefonoReferente, telefonoReferenteChangedHandler,] = useInput("");
    const [fax, setFax, faxChangedHandler,] = useInput("");
    const [mailStruttura, setMailStruttura, mailStrutturaChangedHandler, validateMailStruttura] = useInput("", validateEmail);
    const [mailReferente, setMailReferente, mailReferenteChangedHandler, validateMailReferente] = useInput("", validateEmail);
    const [indirizzoWeb, setIndirizzoWeb, indirizzoWebChangedHandler, validateIndirizzoWeb] = useInput("", validateUrl);
    const [visibile, toggle, setVisibile] = useToggle(true)

    useEffect(() => {
        if (contentFetched && associazione) {
            setComune(associazione.comune ? associazione.comune : "")
            setProvincia(associazione.provincia ? associazione.provincia : "")
            setNominativo(associazione.nome ? associazione.nome : "")
            setIndirizzo(associazione?.indirizzo ? associazione.indirizzo : "")
            setCap(associazione?.cap ? associazione.cap : "")
            setTelefonoStruttura(associazione?.telefonoStruttura ? associazione.telefonoStruttura : "")
            setTelefonoReferente(associazione?.telefonoReferente ? associazione.telefonoReferente : "")
            setFax(associazione?.fax ? associazione.fax : "")
            setMailStruttura(associazione?.mailStruttura ? associazione.mailStruttura : "")
            setMailReferente(associazione?.mailReferente ? associazione.mailReferente : "")
            setIndirizzoWeb(associazione?.sito ? associazione.sito : "")
            setVisibile(associazione.visibile)

        } else {
            onLoadHandler(setAssociazione, idAssociazione, blankAssociazione, setContentFetched)
        }
    }, [contentFetched])

    var associazioneContent = null;

    if (associazione != null) {
        associazioneContent = {

            id: idAssociazione,
            comune, comuneChangedHandler, validateComune,
            provincia, provinciaChangedHandler, validateProvincia,
            nominativo, nominativoChangedHandler, validateNominativo,
            indirizzo, indirizzoChangedHandler,
            cap, capChangedHandler, validCap,
            telefonoStruttura, telefonoReferenteChangedHandler, validateMailStruttura,
            telefonoReferente, telefonoStrutturaChangedHandler,
            fax, faxChangedHandler,
            mailStruttura, mailStrutturaChangedHandler, validateTelefonoStruttura,
            mailReferente, mailReferenteChangedHandler, validateMailReferente,
            indirizzoWeb, indirizzoWebChangedHandler, validateIndirizzoWeb,
            visibile, toggle,
            rejectNaN, validateTelephoneNumber

        }
    }

    function getModalMessage(action) {
        switch (action) {
            case operazioniAssociazione.ANNULLA:
                return Messages.ANNULLA_ASSOCIAZIONE
            case operazioniAssociazione.CONFERMA:
                return Messages.CONFERMA_ASSOCIAZIONE
            case operazioniAssociazione.ELIMINA:
                return Messages.ELIMINA_ASSOCIAZIONE
            default:
                return Messages.INFO_DEFAULT
        }
    }

    function hasError() {

        let valid = false;

        if (contentFetched) {
            valid = validateNominativo && validateComune && validateProvincia && validateTelefonoStruttura && validateMailStruttura && validateIndirizzoWeb && (cap.length > 0 ? validCap : true) && (mailReferente.length > 0 ? validateMailReferente : true);
        }

        return valid;
    }

    // Gestione pulsanti
    const btnPrimary = [
        {
            id: "salva", color: "secondary", outline: true, title: "Salva Modifiche", disabled: !hasError(),
            onClickHandler: () => {
                if (idAssociazione) {
                    getModal(setAction, operazioniAssociazione.CONFERMA_MODIFICA, handleShow)
                } else {
                    getModal(setAction, operazioniAssociazione.CONFERMA_CREAZIONE, handleShow)
                }
            }
        }
    ]
    const btnSecondary = [
        {
            id: "annulla", color: "secondary", outline: false, title: "Annulla Modifiche", disabled: false,
            onClickHandler: () => {
                getModal(setAction, operazioniAssociazione.ANNULLA, handleShow)
            }
        },
        {
            id: "elimina", color: "outline-primary", title: "Elimina",
            onClickHandler: () => {
                getModal(setAction, operazioniAssociazione.ELIMINA, handleShow)
            }
        }
    ]

    return (
        redirect ? <Redirect to="/associazioni" /> :
            <div id="addAssociazione">
                <div className="row">
                    <div className="col-9">
                        <h5>{headerText}</h5>
                    </div>
                    <div className={'col-3 text-right '}>
                        <Link id="back" to="/associazioni">
                            <Icon className="left" color="black" icon="it-arrow-left" id="arrow" />Indietro
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-9">
                        <i>{headerMsg}</i>
                        <div id={"idAssociazioni"}>
                            <h6>Associazioni - Disturbi alimentari</h6>
                        </div>
                        {associazione ?
                            <AssociazioneForm content={associazioneContent} /> : null}
                    </div>
                    <div className="col-3 cards">
                        <Card title={"Modifica"}>
                            <Publisher
                                arrayPrimary={btnPrimary}
                                arraySecondary={btnSecondary}
                            />
                        </Card>
                    </div>
                </div>
                <CustomModal
                    show={show}
                    onHide={handleClose}
                    onConfirm={() => callBackAndClose(inizializzaAssociazione(idAssociazione, nominativo, indirizzo, cap, telefonoStruttura, telefonoReferente, fax, mailStruttura, mailReferente, indirizzoWeb, visibile, comune, provincia), idAreaTerritoriale, handleClose, action, setRedirect, dispatch, state, setContentFetched)}
                    title={action + " in corso"}
                    text={getModalMessage(action)}
                    // body={"ciao"}
                    removeButtons={false}
                />
            </div >
    )
}

function callBackAndClose(statoAssociazione, idAreaTerritoriale, handleClose, action, setRedirect, dispatch, state, setContentFetched) {


    if (action === operazioniAssociazione.CONFERMA_CREAZIONE) {
        StruttureRepository().creaDisturboAlimentare(statoAssociazione, idAreaTerritoriale)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    setRedirect(true)
                    dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.SUCCESS, alertMsg: OK_OPERATION } });
                    showSuccess()
                } else showError(KO_OPERATION)
            }).catch((err) => showError(KO_OPERATION))
            .finally(hideLoading)
        handleClose();
    }

    else if (action === operazioniAssociazione.CONFERMA_MODIFICA) {
        StruttureRepository().editDisturboAlimentare(statoAssociazione, idAreaTerritoriale, statoAssociazione.id)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    setRedirect(true)
                    dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.SUCCESS, alertMsg: OK_OPERATION } });
                    showSuccess()
                } else showError(KO_OPERATION)
            }).catch((err) => showError(KO_OPERATION))
            .finally(hideLoading)
        handleClose();
    }

    else if (action === operazioniAssociazione.ELIMINA) {
        StruttureRepository().deleteDisturboAlimentare(idAreaTerritoriale, statoAssociazione.id)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    setRedirect(true)
                    showSuccess()
                    dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.SUCCESS, alertMsg: OK_OPERATION } });
                } else showError(KO_OPERATION)
            }).catch((err) => showError(KO_OPERATION))
            .finally(hideLoading)
        handleClose();
    }


    else if (action === operazioniAssociazione.ANNULLA) {
        setContentFetched(false)
        handleClose();
    }

}

function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}

function onLoadHandler(setAssociazione, idAssociazione, blankAssociazione, setContentFetched) {

    const session = SessioneUtente.getInstance();
    const idAreaTerritoriale = session.idAreaTerritoriale;



    if (idAssociazione != null) {
        StruttureRepository().getDisturboAlimentare(idAreaTerritoriale, idAssociazione)
            .then(result => {
                setAssociazione(result)
                setContentFetched(true);
            }).catch(err => err)
    } else {
        setAssociazione(blankAssociazione)
        setContentFetched(true);
    }



}

function inizializzaAssociazione(idAssociazione, nominativo, indirizzo, cap, telefonoStruttura, telefonoReferente, fax, mailStruttura, mailReferente, indirizzoWeb, visibile, comune, provincia) {


    return Object.freeze({
        //aggiungere comune e provincia
        id: idAssociazione ? idAssociazione : null,
        nome: nominativo,
        indirizzo: indirizzo,
        cap: cap,
        telefonoStruttura: telefonoStruttura,
        telefonoReferente: telefonoReferente,
        fax: fax,
        mailStruttura: mailStruttura,
        mailReferente: mailReferente,
        sito: indirizzoWeb,
        visibile: visibile,
        comune: comune,
        provincia: provincia
    });
}