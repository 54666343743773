import React, { useEffect, useState, useContext } from 'react';
import { areeTerritorialiEnum, getTemplatesEnum, linguaEnum, maxElementi, operazioniContenuto, roleEnum, statoContenuto, tipologiaEnum } from '../../Utils/Dataset';
import { isContentReadOnly } from "../../Utils/Utils";
import './ContenutiTipologia.css';
import ContenutiRepository from '../../../Repo/ContenutiRepository';
import { Button } from 'design-react-kit';
import { Link } from 'react-router-dom';
import { showLoading, hideLoading, Context, showSuccess, showError, scrollToTop } from '../../../hook/globalState/Store';
import CustomModal from '../../CustomModal/CustomModal';
import useModal from '../../../hook/useModal';
import { Messages } from '../../Utils/Messages';
import { hasErrorOutcome } from "../../Interceptor/Interceptor";
import { getMessaggioOperazione } from "../../../Repo/Utils";
import LanguageFlag from "../../UI/LanguageFlag/LanguageFlag";
import Pager from "../../UI/Table/Pager";
import SessioneUtente from '../../../sessionManager/SessioneUtente';

export default function ContenutiTipologia(props) {

    const [, dispatch] = useContext(Context);
    const headerMsg = "In questa sezione puoi visualizzare e gestire tutti i contenuti della tipologia " + "'" + getTemplatesEnum().getNomeFromTipologia(props.tipologia)?.toLowerCase() + "'" + "."

    // Gestione contenuti della tipologia
    const [contentFetched, setContentFetched] = useState();
    const [contenuti, setContenuti] = useState();
    const [contenutiEn, setContenutiEn] = useState();
    const [contenutiDe, setContenutiDe] = useState();
    const [contenutiFr, setContenutiFr] = useState();
    const [contenutiFiltered, setContenutiFiltered] = useState([])
    const [tipologia, setTipologia] = useState();

    const PAGINAZIONE = 10
    const [page, setPage] = useState(0)
    const [elementsInView, setElementsInView] = useState([])
    const [action, setAction] = useState("");

    //Contenuti padre
    const [isContenutoPadreEditable, setIsContenutoPadreEditable] = useState(true);

    //CTA editabile
    const [isCTAEditable, setIsCTAEditable] = useState(true);

    //CONTENUTI CORRELATI 
    const [isContenutiCorrelatiEditable, setIsContenutiCorrelatiEditable] = useState(true);

    const updatePage = (page) => {
        showLoading()
        loadContenutiITA(page + 1, PAGINAZIONE)
            .then(result => {
                setContenutiFiltered(result.elementi)
                setElementsInView(result.elementi)
                //setContenutiFiltered(result.elementi?.filter(el => el.stato !== statoContenuto.ELIMINATO))
                setPage(page)
            })
            .finally(() => {
                hideLoading()
                scrollToTop()
            })
    }

    // Gestione paginazione
    useEffect(() => {
        if (contentFetched) {
            setElementsInView(contenutiFiltered)
            setPage(0)
        }
    }, [contentFetched])


    // Gestione finestra modale per l'eliminazione
    const [visible, setVisible, handleClose, handleShow] = useModal(false);
    const [id, setId] = useState("");
    const deleteElement = () => {
        ContenutiRepository().eliminaContenuto(id)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
                    setContentFetched(false)
                    showSuccess()
                } else showError(getMessaggioOperazione(result?.esito?.messaggi[0]))
            })
        handleClose();
    }

    useEffect(() => {
        if (!contentFetched) {
            showLoading()
            onLoadHandler()
        }
        else {
            setTipologia(props.tipologia)
            hideLoading()
        }
    }, [contentFetched])


    if (tipologia !== props.tipologia) {
        setTipologia(props.tipologia)
        setContentFetched(false)
    }

    const contenutiAltraLingua = {
        "EN": contenutiEn,
        "DE": contenutiDe,
        "FR": contenutiFr
    }

    const loadContenutiITA = (numeroPagina, numeroElementi) => {
        return ContenutiRepository()
            .getSezioneAltraLingua({ tipologia: tipologia, lingua: "IT", areaTerritoriale: (SessioneUtente.getInstance()?.ruoliUtente === roleEnum.EDITORE && tipologia?.toUpperCase() === tipologiaEnum.NEWS) && "" }, { numeroPagina: numeroPagina, numeroElementi: numeroElementi, ascending: "true", sort: "nome" }, true)
    }

    const onLoadHandler = () => {
        Promise.all([loadContenutiITA(1, PAGINAZIONE),
        ContenutiRepository().getSezioneAltraLingua({ tipologia: tipologia, lingua: "EN" }, { numeroElementi: 1000 }),
        ContenutiRepository().getSezioneAltraLingua({ tipologia: tipologia, lingua: "DE" }, { numeroElementi: 1000 }),
        ContenutiRepository().getSezioneAltraLingua({ tipologia: tipologia, lingua: "FR" }, { numeroElementi: 1000 })
        ])
            .then(([result1, result2, result3, result4]) => {

                if (result1 || result2 || result3) {
                    setContenuti(result1);
                    setContenutiEn(result2);
                    setContenutiDe(result3);
                    setContenutiFr(result4)
                    setContenutiFiltered(result1.elementi)
                    //setContenutiFiltered(result1.elementi?.filter(el => el.stato !== statoContenuto.ELIMINATO))
                    setContentFetched(true)
                }
            })
    }

    const renderFlags = (el) => el.contenutiAltraLingua.map(altraLinguaEl => {
        const disabled = contenutiAltraLingua[altraLinguaEl.lingua]?.filter(x => x.id === altraLinguaEl.id)[0]?.stato === statoContenuto.ELIMINATO;
        return <div className={"languageFlag" + (disabled ? " disabled" : "")} key={el.nome + el.id}><LanguageFlag lingua={altraLinguaEl.lingua} /></div>
    })

    return (
        contentFetched &&
        <>
            <div id="contenutiTipologia">
                <h5>{getTemplatesEnum().getNomeFromTipologia(props.tipologia)}</h5>
                <i>{headerMsg}</i>
                <div id="mappingItems">
                    {contentFetched && elementsInView && elementsInView.map((el, i) => {
                        let nonModificabile = false;
                        let nonVisualizzabile = false;
                        let messaggioNonModificabile = "";
                        if (el.stato === statoContenuto.ELIMINATO) {
                            nonModificabile = true;
                            messaggioNonModificabile = "Il contenuto si trova nel cestino";
                        }
                        else if (el.tipologia === tipologiaEnum.NEWS && isContentReadOnly(SessioneUtente.getInstance().ruoliUtente, SessioneUtente.getInstance().codiceFiscale, el.areaTerritoriale, el.idUtenteInserimento)) {
                            nonModificabile = true;
                            messaggioNonModificabile = "Il contenuto è in sola lettura per l'utente corrente";
                        }
                        else if (el.template === "TEMPLATE_LINK") {
                            nonVisualizzabile = true;
                            nonModificabile = true;
                            messaggioNonModificabile = "";
                        }
                        return <div id="contenutoTipologia" className="row" key={el.id}>
                            <div className="logo-area">
                                {areeTerritorialiEnum[el.areaTerritoriale]?.logo ? <img alt={areeTerritorialiEnum[el.areaTerritoriale]?.nome} className="img-logo" src={areeTerritorialiEnum[el.areaTerritoriale]?.logo} title={areeTerritorialiEnum[el.areaTerritoriale]?.nome?.toString()} /> : <div className="img-space" />}
                            </div>
                            <div className="col-8">
                                <label><span title={el.titolo}>{el.nome}</span></label>
                            </div>
                            <div id="editContenuto" style={{ width: "120px" }}>
                                {nonVisualizzabile ?
                                    <Button disabled={true} style={{ width: "100%" }}>
                                        Visualizza
                                </Button> :
                                    <Link
                                        style={{
                                            cursor: el.stato === statoContenuto.ELIMINATO && "default",
                                            textDecoration: "none",
                                            color: "white"
                                        }}
                                        to={el.stato !== statoContenuto.ELIMINATO && "/edit/contenuto/" + el.id}
                                    >
                                        <Button disabled={el.stato === statoContenuto.ELIMINATO} style={{ width: "100%" }}>
                                            {nonModificabile ? "Visualizza" : "Modifica"}
                                        </Button>
                                    </Link>}
                            </div>
                            <div id="deleteContenuto">
                                <Button disabled={nonModificabile} title={messaggioNonModificabile} onClick={nonModificabile ? () => { } : (e) => { onClickHandler(el.id, handleShow, setId, e, props.tipologia, setIsCTAEditable, setIsContenutiCorrelatiEditable, setAction, operazioniContenuto.ELIMINAZIONE, setIsContenutoPadreEditable) }}>
                                    Elimina
                                    </Button>
                            </div>
                            <div className={"row v-center"}>
                                {renderFlags(el)}
                            </div>
                        </div>
                    })}

                    <CustomModal
                        show={visible}
                        title={!isCTAEditable || !isContenutiCorrelatiEditable || !isContenutoPadreEditable ? "Eliminazione non consentita" : "Eliminazione in corso"}
                        text={!isCTAEditable || !isContenutiCorrelatiEditable || !isContenutoPadreEditable ? Messages.ERR_ELIMINAZIONE_CTA_NON_CONSENTITA : Messages.ERR_02_01_0A_0A}
                        onHide={handleClose}
                        // onConfirm={() => deleteElement(id, handleClose)}
                        onConfirm={() => callBackAndClose(action, id, dispatch, setContentFetched, handleClose, isCTAEditable, isContenutiCorrelatiEditable)}
                        buttonOk={(action === operazioniContenuto.ELIMINAZIONE) && (!isCTAEditable || !isContenutiCorrelatiEditable || !isContenutoPadreEditable)}
                        removeButtons={(action === operazioniContenuto.ELIMINAZIONE) && (!isCTAEditable || !isContenutiCorrelatiEditable || !isContenutoPadreEditable)}
                    />

                    {contentFetched &&
                        <div className={'row'}>
                            <div className={'mt-5 section-pager'}>
                                <Pager
                                    paginationSize={PAGINAZIONE}
                                    nBackendPages={contenuti.pagineTotali}
                                    nElements={contenuti.elementiTotali}
                                    page={page}


                                    setPage={updatePage}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )

}

async function callBackAndClose(action, id, dispatch, setContentFetched, handleClose, isCTAEditable, isContenutiCorrelatiEditable) {

    if (isCTAEditable && isContenutiCorrelatiEditable) {
        if (action === operazioniContenuto.ELIMINAZIONE) {
            ContenutiRepository().eliminaContenuto(id)
                .then(result => {
                    if (!hasErrorOutcome(result)) {
                        dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
                        setContentFetched(false)
                        showSuccess()
                    }
                })
            handleClose();
        }
    } else {
        handleClose();
    }
}

function onClickHandler(id, handleShow, setId, e, tipologia, setIsCTAEditable, setIsContenutiCorrelatiEditable, setAction, azione, setIsContenutoPadreEditable) {

    setIsContenutiCorrelatiEditable(true)
    setIsCTAEditable(true)
    setIsContenutoPadreEditable(true)
    setAction(azione)

    let temp = true;

    Promise.all([ContenutiRepository().getContenutoById(id),
    ContenutiRepository().getTuttiContenuti({ lingua: linguaEnum.ITALIANO, notInStato: "ELIMINATO" }, { inVersionePubblica: true, ascending: "false", sort: "dataInserimento", numeroElementi: maxElementi })
    ]).then(([result1, result2]) => {
        if (result2) {
            for (var i = 0; i < result2.length; i++) {
                if (result2[i].applicazioniCustom && result2[i].applicazioniCustom.length > 0) {
                    for (var j = 0; j < result2[i].applicazioniCustom.length; j++) {
                        if (result2[i].applicazioniCustom[j].link === result1.path) {
                            setIsCTAEditable(false)
                            temp = false;
                            break;
                        }
                    }
                }
            }
            for (var i = 0; i < result2.length; i++) {
                if (result2[i].contenutiCorrelati && result2[i].contenutiCorrelati.length > 0) {
                    for (var j = 0; j < result2[i].contenutiCorrelati.length; j++) {
                        if (result2[i].contenutiCorrelati[j].link === result1.path) {
                            setIsContenutiCorrelatiEditable(false)
                            temp = false;
                            break;
                        }
                    }
                }
            }
            if (result2?.find(el => el?.idPadre === result1?.id)) setIsContenutoPadreEditable(false)
        }
        e.preventDefault();
        handleShow();
        setId(id);
    })



}
