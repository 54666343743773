import React, { useState, useEffect } from "react";
import './AssociazioneBoxStyles.css'
import { Icon } from "design-react-kit";
import { Link } from "react-router-dom";

export default function AssociazioneBox(props) {

    return <div className={'col-12 mt-4'} id={'associazioneBoxContainer'}>
        <div className={'row mt-2 border-bottom'}>
            <p className={'associazioneBoxHeader'}>NOMINATIVO</p><label className={'associazioneBoxValue'}>{props.el?.nome}</label>
        </div>
        <div className={'row'}>
            <div className={'col-5 d-flex'}>
                <p className={'associazioneBoxHeader'}>COMUNE</p><label className={'associazioneBoxValue'}>{props.el?.comune}</label>
            </div>
            <div className={'col-5 d-flex'}>
                <p className={'associazioneBoxHeader'}>PROVINCIA</p><label className={'associazioneBoxValue'}>{props.el?.provincia}</label>
            </div>
            <div className={'col-5 d-flex'}>
                <p className={'associazioneBoxHeader'}>INDIRIZZO</p><label className={'associazioneBoxValue'}>{props.el?.indirizzo}</label>
            </div>
            <div className={'col-2 d-flex'}>
                <p className={'associazioneBoxHeader'}>CAP</p><label className={'associazioneBoxValue'}>{props.el?.cap}</label>
            </div>
        </div>
        <div className={'row'}>
            <div className={'col-6 d-flex'}>
                <p className={'associazioneBoxHeader'}>TELEFONO (Struttura) </p><label className={'associazioneBoxValue'}>{props.el?.telefonoStruttura}</label>
            </div>
            <div className={'col-6 d-flex'}>
                <p className={'associazioneBoxHeader'}>FAX</p><label className={'associazioneBoxValue'}>{props.el?.fax}</label>
            </div>
        </div>
        <div className={'row'}>
            <div className={'col-12 d-flex'}>
                <p className={'associazioneBoxHeader'}>TELEFONO (Referente) </p><label className={'associazioneBoxValue'}>{props.el?.telefonoReferente}</label>
            </div>
        </div>
        <div className={'row'}>
            <div className={'col-12 d-flex'}>
                <p className={'associazioneBoxHeader'}>MAIL (Struttura) </p><label className={'associazioneBoxValue'}>{props.el?.mailStruttura}</label>
            </div>
        </div>
        <div className={'row'}>
            <div className={'col-12 d-flex'}>
                <p className={'associazioneBoxHeader'}>MAIL (Referente) </p><label className={'associazioneBoxValue'}>{props.el?.mailReferente}</label>
            </div>
        </div>
        <div className={'row'}>
            <div className={'col-8 d-flex'}>
                <p className={'associazioneBoxHeader'}>INDIRIZZO WEB</p><label className={'associazioneBoxValue'}>{props.el?.sito}</label>
            </div>
            <div className={'col-4 d-flex'}>
                <Link to={"/edit/associazione/" + props.el?.id}><button className={'btn btn-secondary modifica-dati'}>Modifica dati</button></Link>
            </div>
        </div>
    </div>
}

function onLoadHandler() {

}
