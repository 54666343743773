import { SERVICE_URL, REPHEADERS } from "../config/config";
import AuthenticationManager from '../sessionManager/AuthenticationManager';
import SessioneUtente from "../sessionManager/SessioneUtente";

export default function MenuRepository() {

    const menuServiceUrl = SERVICE_URL + "/menu";

    const authManager = new AuthenticationManager();
    const session = SessioneUtente.getInstance();

    /* centralizza le logiche di inzializzazione degli Headers */
    async function getHeaders(requestUrl) {
        let defaultHeaders = { ...REPHEADERS };
        defaultHeaders["AZUser"] = defaultHeaders["AZUser"] + ";areaterritoriale=" + session.idAreaTerritoriale
        let headers = await authManager.initRequestHeaders(requestUrl, defaultHeaders);
        headers["Content-Type"] = "application/json";
        return headers;
    }

    async function getMenu(tipologia) {
        const requestUrl = menuServiceUrl + "/" + tipologia;
        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        })
    }

    async function aggiornaMenu(tipologia, contenuto) {
        const requestUrl = menuServiceUrl + "/" + tipologia;
        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "PUT",
            body: JSON.stringify(contenuto)
        })
    }

    async function pubblicaMenu(tipologia, contenuto) {
        const requestUrl = menuServiceUrl + "/" + tipologia;
        const headers = await getHeaders(requestUrl);
        return fetch(requestUrl, {
            headers: headers,
            method: "PUT",
            body: JSON.stringify(contenuto)
        })
            .then(data => {
                return fetch(requestUrl + "/pubblica", {
                    headers: headers,
                    method: "POST"
                }).catch(err => err)
            })
            .catch(err => err)
    }

    return Object.freeze({
        getMenu,
        aggiornaMenu,
        pubblicaMenu
    });

}
