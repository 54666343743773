import React, { useContext, useEffect } from 'react';
import sideBarOpen from '../../img/logos/sidebar-open.svg';
import LogoHomePage from '../../img/logos/homepage.svg';
import LogoMenu from '../../img/logos/menu.svg';
import LogoInsert from '../../img/logos/insert.svg';
import LogoSection from '../../img/logos/section.svg';
import LogoApplication from '../../img/logos/application.svg';
import LogoSearch from '../../img/logos/search.svg';
import LogoSocial from '../../img/logos/social.svg';
import LogoTool from '../../img/logos/tool.svg';
import LogoError from '../../img/logos/error.svg';
import LogoTrash from '../../img/logos/trash.svg';
import { CollapsedMenuIconWithSubmenu, CollapsedMenuIcon } from './CollapsedMenuIcon';
import './HideMenu.css';
import { Context } from '../../../hook/globalState/Store';
import {
    useContenutiOrdinati
} from '../Menu/MenuHooks';
import "./HideMenu.css";
import { roleEnum, tipologiaEnum, tipologiaNonCreabileEnum } from '../../Utils/Dataset';
import SessioneUtente from '../../../sessionManager/SessioneUtente';

export default function HideMenu(props) {

    const [state, dispatch] = useContext(Context);

    let session = SessioneUtente.getInstance();
    let role = session.ruoliUtente;

    const [approfondimenti, approfondimentiLoadCompleted, setApprofondimenti, setApprofondimentiLoaded] = useContenutiOrdinati({ tipologia: tipologiaEnum.APPROFONDIMENTO }, { ascending: "false", sort: "dataInserimento" });
    const [dettaglioApprofondimenti, dettaglioApprofondimentiLoadCompleted, setDettaglioApprofondimenti, setDettaglioApprofondimentiLoaded] = useContenutiOrdinati({ tipologia: tipologiaEnum.DETTAGLIO_APPROFONDIMENTO }, { ascending: "false", sort: "dataInserimento" });
    const [comeFarePer, comeFarePerLoadCompleted, setComeFarePer, setComeFarePerLoaded] = useContenutiOrdinati({ tipologia: tipologiaEnum.COME_FARE_PER }, { ascending: "false", sort: "dataInserimento" });
    const [vivereInSalute, vivereInSaluteLoadCompleted, setVivereInSalute, setVivereInSaluteLoaded] = useContenutiOrdinati({ tipologia: tipologiaEnum.VIVERE_IN_SALUTE }, { ascending: "false", sort: "dataInserimento" });
    const [news, newsLoadCompleted, setNews, setNewsLoaded] = useContenutiOrdinati({ tipologia: tipologiaEnum.NEWS }, { ascending: "false", sort: "dataInserimento" });
    const [fakeNews, fakeNewsLoadCompleted, setFakeNews, setFakeNewsLoaded] = useContenutiOrdinati({ tipologia: tipologiaEnum.FAKE_NEWS }, { ascending: "false", sort: "dataInserimento" });
    const [prestazioneGarantitaRicerca, prestazioneGarantitaRicercaLoadCompleted, setPrestazioneGarantitaRicerca, setPrestazioneGarantitaRicercaLoaded] = useContenutiOrdinati({ tipologia: tipologiaEnum.PRESTAZIONE_GARANTITA_SSN }, { ascending: "false", sort: "dataInserimento" });
    // const [comeFarePerRicerca, comeFarePerRicercaLoadCompleted, setComeFarePerRicerca, setComeFarePerRicercaLoaded] = useComeFarePerRicerca();
    const [pagineErrore, , , setComePagineErroreLoaded] = useContenutiOrdinati({ tipologia: tipologiaNonCreabileEnum.ERRORE }, { ascending: "false", sort: "dataInserimento" })
    const [pagineArgomentoGenerico, , , setPagineArgomentoGenerico] = useContenutiOrdinati({ tipologia: tipologiaNonCreabileEnum.ARGOMENTO_GENERICO_BOTTOM }, { ascending: "false", sort: "dataInserimento" })

    const mapPaginaErroreToListElement = pagina => ({ ...pagina, href: '/edit/errore/' + pagina.id, icon: 'it-arrow-right-triangle', title: pagina.nome })
    const mapPaginaArgomentoGenericoToListElement = pagina => ({ ...pagina, href: '/edit/footer/' + pagina.id, icon: 'it-arrow-right-triangle', title: pagina.nome })

    useEffect(() => {
        if (state.reloadMenu) {
            setApprofondimentiLoaded(false)
            setDettaglioApprofondimentiLoaded(false)
            setComeFarePerLoaded(false)
            setVivereInSaluteLoaded(false)
            setNewsLoaded(false)
            setFakeNewsLoaded(false)
            setPrestazioneGarantitaRicercaLoaded(false)
            // setComeFarePerRicercaLoaded(false)
            setComePagineErroreLoaded(false)

            dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: false } });
        }
    }, [state.reloadMenu])

    const linksTree = BuildLinksTree();

    return (
        <div id="hideMenu">
            <div className="menu-state" id="showMenu" >
                <button type="button" className="icon-button" title="Mostra Menu" onClick={props.click}><img className="menu-state-icon" src={sideBarOpen} /></button>
            </div>
            <div className="link-list-wrapper">
                <ul className="link-list">
                    <li>
                        {role !== roleEnum.EDITORE && <CollapsedMenuIcon url="/edit/homepage" icon={LogoHomePage} title="Home Page" />}
                    </li>
                    <li>
                        {role !== roleEnum.EDITORE && <CollapsedMenuIconWithSubmenu menu={linksTree.menu} nested={false} icon={LogoMenu} title="Menu" />}
                    </li>
                    <li>
                        <CollapsedMenuIcon url="/new/contenuto" icon={LogoInsert} title="Inserisci nuovo contenuto" />
                    </li>
                    <li>
                        <CollapsedMenuIconWithSubmenu menu={linksTree.aggiornamentoSezioni} nested={false} icon={LogoSection} title="Aggiornamento sezioni" />
                    </li>
                    <li>
                        <CollapsedMenuIconWithSubmenu menu={linksTree.apllicazioniCustom} nested={false} icon={LogoApplication} title="Applicazioni custom" />
                    </li>
                    <li>
                        <CollapsedMenuIcon url="/edit/motorericerca" icon={LogoSearch} title="Motore di ricerca" />
                    </li>
                    <li>
                        <CollapsedMenuIcon url="/collegamenti-social" icon={LogoSocial} title="Collegamenti Social" />
                    </li>
                    <li>
                        <CollapsedMenuIconWithSubmenu menu={pagineErrore.map(el => mapPaginaErroreToListElement(el))} nested={false} icon={LogoError} title="Pagine di errore" />
                    </li>
                    <li>
                        {role !== roleEnum.EDITORE && <CollapsedMenuIcon url="/gestione-ruoli" icon={LogoTool} title="Redattori" />}
                    </li>
                    <li>
                        <CollapsedMenuIcon url="/gestione-feedback" iconBI={"it-hearing"} title="Gestione feedback" />
                    </li>
                    <li>
                        <CollapsedMenuIcon url="/cestino" icon={LogoTrash} title="Cestino" />
                    </li>
                </ul>
            </div>
        </div>
    )
}

function BuildLinksTree() {
    return {
        apllicazioniCustom: [
            {
                title: "Prestazioni garantite dal SSN",
                href: "/applicazioni-custom/prestazioni-garantite-ssn",
                icon: "it-arrow-right-triangle"
            },
            {
                title: "Come fare per",
                href: "/contenuti/applicazione_strutture",
                icon: "it-arrow-right-triangle"
            }
        ],
        menu: [
            {
                title: "Main menu",
                href: "/edit/menu/main",
                icon: "it-arrow-right-triangle"
            },
            {
                title: "Header Menu",
                href: "/edit/menu/header",
                icon: "it-arrow-right-triangle"
            },
            {
                title: "Footer Menu",
                href: "/edit/menu/footer",
                icon: "it-arrow-right-triangle"
            },
            {
                title: "Bottom Bar Menu",
                href: "/edit/menu/bottom",
                icon: "it-arrow-right-triangle"
            }
        ],
        aggiornamentoSezioni: [
            {
                title: "Approfondimenti",
                href: "/contenuti/approfondimento",
                icon: "it-arrow-right-triangle",
            },
            {
                title: "Dettaglio Approfondimenti",
                href: "/contenuti/dettaglio_approfondimento",
                icon: "it-arrow-right-triangle",
            },
            {
                title: "Come fare per",
                href: "/contenuti/come_fare_per",
                icon: "it-arrow-right-triangle",
            },
            {
                title: "Vivere in salute",
                href: "/contenuti/vivere_in_salute",
                icon: "it-arrow-right-triangle",
            },
            {
                title: "Prestazioni garantite dal SSN",
                href: "/contenuti/prestazione_garantita_ssn",
                icon: "it-arrow-right-triangle",
            },
            {
                title: "News",
                href: "/contenuti/news",
                icon: "it-arrow-right-triangle",
            },
            {
                title: "Fake news",
                href: "/contenuti/fake_news",
                icon: "it-arrow-right-triangle",
            },
            {
                title: "Covid-19",
                href: "/contenuti/covid",
                icon: "it-arrow-right-triangle"
            },
            {
                title: "FAQ",
                href: "/contenuti/faq",
                icon: "it-arrow-right-triangle"
            },
            {
                title: "Feedback",
                href: "/contenuti/feedback",
                icon: "it-arrow-right-triangle"
            },
            {
                title: "Contenuti generici",
                href: "/contenuti/argomento_generico_bottom",
                icon: "it-arrow-right-triangle"
            },
            {
                title: "Contenuti generici (contatti)",
                href: "/contenuti/argomento_generico_contatti",
                icon: "it-arrow-right-triangle"
            },
            {
                title: "Contenuti generici (portale)",
                href: "/contenuti/argomento_generico_portale",
                icon: "it-arrow-right-triangle"
            }
        ]
    };
}
