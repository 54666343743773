import React, { useEffect, useState, useContext } from 'react';
import { areeTerritorialiEnum, getTemplatesEnum, statoContenuto, tipologiaEnum } from '../../../Utils/Dataset';
import './ContenutiTipologiaRegione.css';
import ContenutiRepository from '../../../../Repo/ContenutiRepository';
import { Button } from 'design-react-kit';
import { Link } from 'react-router-dom';
import { showLoading, hideLoading, Context, showSuccess, showError, scrollToTop } from '../../../../hook/globalState/Store';
import CustomModal from '../../../CustomModal/CustomModal';
import useModal from '../../../../hook/useModal';
import { Messages } from '../../../Utils/Messages';
import { hasErrorOutcome } from "../../../Interceptor/Interceptor";
import { getMessaggioOperazione } from "../../../../Repo/Utils";
import LanguageFlag from "../../../UI/LanguageFlag/LanguageFlag";
import Pager from "../../../UI/Table/Pager";
import SessioneUtente from '../../../../sessionManager/SessioneUtente';
import { isContentReadOnly } from '../../../Utils/Utils';

export default function ContenutiTipologiaRegione() {

    const [, dispatch] = useContext(Context);
    var session = SessioneUtente.getInstance();
    const headerMsg = "In questa sezione puoi visualizzare e gestire tutti i contenuti della tipologia News."

    // Gestione contenuti della tipologia
    const [contentFetched, setContentFetched] = useState();
    const [contenuti, setContenuti] = useState();
    const [contenutiEn, setContenutiEn] = useState();
    const [contenutiDe, setContenutiDe] = useState();
    const [contenutiFr, setContenutiFr] = useState();
    const [contenutiFiltered, setContenutiFiltered] = useState([])
    const [tipologia, setTipologia] = useState();

    const PAGINAZIONE = 10
    const [page, setPage] = useState(0)
    const [elementsInView, setElementsInView] = useState([])

    const updatePage = (page) => {
        showLoading()
        loadContenutiITA(page + 1, PAGINAZIONE)
            .then(result => {
                setContenutiFiltered(result.elementi)
                setElementsInView(result.elementi)
                //setContenutiFiltered(result.elementi?.filter(el => el.stato !== statoContenuto.ELIMINATO))
                setPage(page)
            })
            .finally(() => {
                hideLoading()
                scrollToTop()
            })
    }

    // Gestione paginazione
    useEffect(() => {
        if (contentFetched) {
            setElementsInView(contenutiFiltered)
            setPage(0)
        }
    }, [contentFetched])


    // Gestione finestra modale per l'eliminazione
    const [visible, setVisible, handleClose, handleShow] = useModal(false);
    const [id, setId] = useState("");
    const deleteElement = () => {
        ContenutiRepository().eliminaContenuto(id)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
                    setContentFetched(false)
                    showSuccess()
                } else showError(getMessaggioOperazione(result?.esito?.messaggi[0]))
            })
        handleClose();
    }

    useEffect(() => {
        if (!contentFetched) {
            showLoading()
            onLoadHandler()
        }
        else {
            setTipologia(tipologiaEnum.NEWS)
            hideLoading()
        }
    }, [contentFetched])


    if (tipologia !== tipologiaEnum.NEWS) {
        setTipologia(tipologiaEnum.NEWS)
        setContentFetched(false)
    }

    const contenutiAltraLingua = {
        "EN": contenutiEn,
        "DE": contenutiDe,
        "FR": contenutiFr
    }

    const loadContenutiITA = (numeroPagina, numeroElementi) => {
        return ContenutiRepository()
            .getSezioneAltraLingua({ tipologia: tipologia, lingua: "IT", areaTerritoriale: session.idAreaTerritoriale }, { numeroPagina: numeroPagina, numeroElementi: numeroElementi, ascending: "true", sort: "nome" }, true)
    }

    const onLoadHandler = () => {
        Promise.all([loadContenutiITA(1, PAGINAZIONE),
        ContenutiRepository().getSezioneAltraLingua({ tipologia: tipologia, lingua: "EN", areaTerritoriale: session.idAreaTerritoriale }, { numeroElementi: 1000 }),
        ContenutiRepository().getSezioneAltraLingua({ tipologia: tipologia, lingua: "DE", areaTerritoriale: session.idAreaTerritoriale }, { numeroElementi: 1000 }),
        ContenutiRepository().getSezioneAltraLingua({ tipologia: tipologia, lingua: "FR", areaTerritoriale: session.idAreaTerritoriale }, { numeroElementi: 1000 })
        ])
            .then(([result1, result2, result3, result4]) => {

                if (result1 || result2 || result3) {
                    setContenuti(result1);
                    setContenutiEn(result2);
                    setContenutiDe(result3);
                    setContenutiFr(result4)
                    setContenutiFiltered(result1.elementi)
                    //setContenutiFiltered(result1.elementi?.filter(el => el.stato !== statoContenuto.ELIMINATO))
                    setContentFetched(true)
                }
            })
    }

    const renderFlags = (el) => el.contenutiAltraLingua.map(altraLinguaEl => {
        const disabled = contenutiAltraLingua[altraLinguaEl.lingua]?.filter(x => x.id === altraLinguaEl.id)[0]?.stato === statoContenuto.ELIMINATO;
        return <div className={"languageFlag" + (disabled ? " disabled" : "")} key={el.nome + el.id}><LanguageFlag lingua={altraLinguaEl.lingua} /></div>
    })

    return (
        contentFetched &&
        <>
            <div className="contenuti-tipologia-regione">
                <h5>{getTemplatesEnum().getNomeFromTipologia(tipologiaEnum.NEWS)}</h5>
                <i>{headerMsg}</i>
                <div id="mappingItems">
                    {contentFetched && elementsInView && elementsInView.map((el, i) => {
                        let nonModificabile = false;
                        let messaggioNonModificabile = "";
                        if (el.stato === statoContenuto.ELIMINATO) {
                            nonModificabile = true;
                            messaggioNonModificabile = "Il contenuto si trova nel cestino";

                        } else if (el.tipologia === tipologiaEnum.NEWS && isContentReadOnly(SessioneUtente.getInstance().ruoliUtente, SessioneUtente.getInstance().codiceFiscale.toUpperCase(), el.areaTerritoriale, el.idUtenteInserimento.toUpperCase())) {
                            nonModificabile = true;
                            messaggioNonModificabile = "Il contenuto è in sola lettura per l'utente corrente";
                        }
                        return <div id="contenutoTipologia" className="row" key={el.id}>
                            <div className="col-8">
                                <div>
                                    <label><span title={el.titolo}>{el.nome}</span></label>
                                </div>
                            </div>
                            <div id="editContenuto" style={{ width: "120px" }}>
                                <Link
                                    style={{
                                        cursor: el.stato === statoContenuto.ELIMINATO && "default",
                                        textDecoration: "none",
                                        color: "white"
                                    }}
                                    to={el.stato !== statoContenuto.ELIMINATO && "/edit/contenuto/" + el.id}
                                >
                                    <Button disabled={el.stato === statoContenuto.ELIMINATO} style={{ width: "100%" }}>
                                        {nonModificabile ? "Visualizza" : "Modifica"}
                                    </Button>
                                </Link>
                            </div>
                            <div id="deleteContenuto">
                                <Button disabled={nonModificabile} title={messaggioNonModificabile} onClick={nonModificabile ? () => { } : (e) => onClickHandler(el.id, handleShow, setId, e)}>
                                    Elimina
                                    </Button>
                            </div>
                            <div className={"row v-center"}>
                                {renderFlags(el)}
                            </div>
                        </div>
                    })}

                    <CustomModal
                        show={visible}
                        title={"Eliminazione in corso"}
                        text={Messages.ERR_02_01_0A_0A}
                        onHide={handleClose}
                        onConfirm={() => deleteElement(id, handleClose)}
                    />

                    {contentFetched &&
                        <div className={'row'}>
                            <div className={'mt-5 section-pager'}>
                                <Pager
                                    paginationSize={PAGINAZIONE}
                                    nBackendPages={contenuti.pagineTotali}
                                    nElements={contenuti.elementiTotali}
                                    page={page}


                                    setPage={updatePage}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

function onClickHandler(id, handleShow, setId, e) {
    e.preventDefault();
    handleShow();
    setId(id);
}