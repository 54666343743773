import moment from "moment";
import { SERVICE_URL, REPHEADERS } from "../config/config";
import AuthenticationManager from '../sessionManager/AuthenticationManager';
import SessioneUtente from "../sessionManager/SessioneUtente";

export default function NewsRepository() {

    const authManager = new AuthenticationManager();
    const session = SessioneUtente.getInstance();

    /* centralizza le logiche di inzializzazione degli Headers */
    async function getHeaders(requestUrl) {
        let defaultHeadersNews = { ...REPHEADERS };
        defaultHeadersNews["AZUser"] = defaultHeadersNews["AZUser"] + ";areaterritoriale=" + session.idAreaTerritoriale
        let headers = await authManager.initRequestHeaders(requestUrl, defaultHeadersNews);
        headers["Content-Type"] = "application/json";
        return headers;
    }

    async function getNewsInPubblicazione(date, filters) {
        let requestUrl = SERVICE_URL + "/contenuti?filter=tipologia::NEWS,lingua::IT,stato!!ELIMINATO";
        if (!date) {
            requestUrl = requestUrl + ",dataPubblicazioneProgrammata:>" + moment().toISOString();
        } else {
            requestUrl = requestUrl + ",dataPubblicazioneProgrammata:<" + date + ",dataPubblicazioneProgrammata:>" + moment().utc().startOf('day').format() + (filters ? (filters.areaTerritoriale ? ",areaTerritoriale::" + filters.areaTerritoriale : "") : "");
        }
        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result.elementi)
    }
    async function getNewsInScadenza(date, filters) {
        let requestUrl = SERVICE_URL + "/contenuti?filter=tipologia::NEWS,lingua::IT,stato!!ELIMINATO";
        if (!date) {
            requestUrl = requestUrl + ",dataScadenzaProgrammata:>" + moment().toISOString();
        } else {
            requestUrl = requestUrl + ",dataScadenzaProgrammata:<" + date + ",dataScadenzaProgrammata:>" + moment().utc().startOf('day').format() + (filters ? (filters.areaTerritoriale ? ",areaTerritoriale::" + filters.areaTerritoriale : "") : "");
        }
        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result.elementi)
    }

    async function getNewsPubblicateRecentemente(data) {
        const requestUrl = SERVICE_URL + "/contenuti?filter=tipologia::NEWS,lingua::IT,stato::PUBBLICATO" + ",dataPubblicazione:>" + data.date + ",areaTerritoriale::" + data.idAreaTerritoriale;
        return fetch(requestUrl, {
            headers: await getHeaders(requestUrl),
            method: "GET"
        }).then(result => result.elementi)
    }

    return Object.freeze({
        getNewsInPubblicazione,
        getNewsInScadenza,
        getNewsPubblicateRecentemente: getNewsPubblicateRecentemente
    });

}
