import React from 'react';
import { Icon, Toggle } from 'design-react-kit';
import './CustomLink.css';

export default function CustomLink(props) {
    return (
        <div id="customLink">
            <div className="row">
                <div className="col-5">
                    <div id="name">
                        <label htmlFor={props.idName}>nome</label>
                        <input id={props.idName} type="text" className="form-control" value={props.descrizione} onChange={(e) => props.updateValue(props.index, "descrizione", e.target.value)} />
                    </div>
                </div>
                <div className="col-5">
                    <div id="link">
                        <label htmlFor={props.idLink}>link</label>
                        <input id={props.idLink} type="text" className="form-control" value={props.link} onChange={(e) => props.updateValue(props.index, "link", e.target.value)} />
                    </div>
                </div>
                <div className="col-2">
                    <div id="delete">
                        <button onClick={props.click}>
                            <Icon className="left" color="black" icon="it-delete" id="delete" />
                        </button>
                    </div>
                </div>
                {!props.isValid ? <p>{props.messaggioValidazione}</p> : null}
            </div>
            <div className="row">
                <div className="col-9" id="inNewWindow">
                    <Toggle
                        defaultChecked={props.inNuovaFinestra}
                        onChange={(e) => props.updateValue(props.index, "inNuovaFinestra", !props.inNuovaFinestra)}
                        label={"Apri il collegamento in un'altra finestra"}
                    />
                </div>
            </div>
        </div>
    )
}