import React from "react";
import { FormGroup, Label, Input } from 'design-react-kit';

/**
 *  PROPS:
 *      - id
 *      - inline: if set, displays the options on the same line
 *      - disabled: disable every option
 *      - options: array of options [{"label":"", "checked":true/false}]
 *      - title: title of the section
 *
 *  E.G:
 *  const [options, setOpt, changeOpt] = useCheckbox([{"label":"OPT6"},{"label":"OPT7", "checked":true}]);
 *  <Checkbox inline id={"sourceRadio2"} change={changeOpt} options={options}/>
 */

export default function Checkbox(props) {

    return (
        <fieldset>
            <div>
                {props.title && (<label> {props.title} </label>)}
            </div>
            <div>
                {props.options?.map((el, index) => (
                    (
                        <FormGroup
                            check
                            tag="div"
                            inline={props.inline}
                            key={props.id + index}
                        >
                            <Input
                                checked={el.checked}
                                id={`${props.id}${index}`}
                                name={props.id}
                                type="checkbox"
                                onChange={() => { props.change(index) }}
                                disabled={props.disabled}
                            />
                            <Label
                                for={`${props.id}${index}`}
                                tag="label"
                                widths={['xs', 'sm', 'md', 'lg', 'xl']}
                            >
                                {el.label}
                            </Label>
                        </FormGroup>
                    )
                ))}
            </div>

        </fieldset>
    )
}