import React, { useEffect, useState, useContext } from 'react';
import useObjectsArray from '../../../hook/useObjectsArray'
import DatabaseRicercaItem from './DatabaseRicercaItem/DatabaseRicercaItem';
import { Link } from 'react-router-dom';
import './DatabaseRicerca.css';
import DragDropItems from "../DragDropItems/DragDropItems";
import { getPathFromName } from '../../Utils/Utils';
import { leaEnum, leaMapping } from '../../Utils/Dataset';


export default function DatabaseRicerca(props) {


    return (
        <>
            <div id={"databaseRicerca"}>
                {props.onlyDb ?
                    <>
                        <div id="labelDbRicerca">
                            <label>{props.label ? props.label : null}</label>
                        </div>
                        <div className="col-9">
                            {props.database &&
                                props.database.map((el, i) => (
                                    <div className="row" key={el.idCategoria + el.etichetta + i}>
                                        <div className="col-12">
                                            <DatabaseRicercaItem
                                                sezione={(el.codice ? el.codice + " " : "") + el.etichetta}
                                                sottovoci={el.sottoCategorie}
                                                href={"/" + el.id}
                                                // href={el.sottoCategorie && el.sottoCategorie.length > 0 ? "/" + el.id : "/" + props.id + "/table"}
                                                categoria={el}
                                                onClick={props.onClick}
                                                pathname={props.pathname}
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </> :
                    <>
                        <div className={'col-12'}>
                            <DragDropItems
                                items={props.database}
                                setItems={props.setDatabase}
                                updateItem={props.updateDatabase}
                                label={props.versioneRegionale ? "LEA" : "MODIFICA"}
                                description
                                disableRenameIte
                                fieldKey={props.fieldKey}
                                versioneRegionale={props.versioneRegionale}
                            />
                        </div>
                        <div id="labelDbRicerca">
                            <label>{props.label ? props.label : null}</label>
                        </div>
                        <div className="col-9">
                            {props.firstPageDb ? props.firstPageDb.map((el, i) => (
                                <div className="row" key={el.idCategoria + el.etichetta + i}>
                                    <div className="col-12">
                                        <DatabaseRicercaItem
                                            sezione={el.etichetta}
                                            sottovoci={el.sottoCategorie}
                                            href={"/" + leaMapping.getValueOf(el.idCategoria) + '/' + el.idCategoria}
                                            categoria={el}
                                            pathname={props.pathname}
                                        />
                                    </div>
                                </div>
                            )) :
                                props.database &&
                                props.database.map((el, i) => (
                                    <div className="row" key={el.idCategoria + el.etichetta + i}>
                                        <div className="col-12">
                                            <DatabaseRicercaItem
                                                sezione={el.etichetta}
                                                sottovoci={el.sottoCategorie}
                                                href={"/" + el.idCategoria}
                                                // href={el.sottoCategorie && el.sottoCategorie.length > 0 ? "/" + el.idCategoria : "/" + props.id + "/table"}
                                                categoria={el}
                                                pathname={props.pathname}
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                }
            </div>
        </>
    )
}
