import React from 'react';
import './SocialSharing.css';
import CustomForm from '../CustomForm/CustomForm';
import LogoFb from '../../img/logos/logo-fb.svg';
import LogoTwitter from '../../img/logos/logo-twitter.svg';
import { getFileNameFromPath, uploadImageSocial } from '../../Utils/Utils';
import { BUCKET_URL } from '../../../config/config';
import { Messages } from '../../Utils/Messages';

export default function SocialSharing(props) {

    const imgPreview = (index) => (
        <div className="box-preview">
            <label className="preview">Anteprima</label>
            <div id={"fileLoaderPreviewDiv"}>
                <img src={props.socials[index]?.immagine ? BUCKET_URL + props.socials[index]?.immagine : props.immagine ? BUCKET_URL + props.immagine : require('./../../img/banners/placeholder-image.png')} id={"fileLoaderPreview"} alt={props.idLoad} />
            </div>
        </div>
    )

    return (
        <>
            <div id="social">
                <label id="labelText">{props.labelName}</label>
                {props.socials?.map((el, i) => (
                    <div className="row" id={i === 0 ? "facebook" : "twitter"} key={props.labelName + i}>
                        <div className="col-1">
                            <img alt={""} src={i === 0 ? LogoFb : LogoTwitter} />
                        </div>
                        <div className="col-11">
                            <CustomForm
                                labelName="URL" name={props.valueUrl} disabled
                            />
                            <CustomForm
                                labelName="Metatag Title" name={props.socials[i].titolo ? props.socials[i].titolo : props.titolo}
                                change={e => props.updateSocial(i, "titolo", e.target.value)} disabled={props.readonly}
                            />
                            <CustomForm
                                labelName="Metatag Description" name={props.socials[i].descrizione}
                                change={e => props.updateSocial(i, "descrizione", e.target.value)} textarea disabled={props.readonly}
                            />
                            <CustomForm
                                labelName="Immagine" id={i === 0 ? "facebook" : "twitter"} readonly={props.readonly}
                                name={props.socials[i].immagine ? getFileNameFromPath(props.socials[i].immagine) : getFileNameFromPath(props.immagine)} changeImg={(e) => {
                                    if (e) {
                                        props.onChangeCurrentAttachment(e, (fileBin, fileMetadata) => {
                                            uploadImageSocial(fileBin, i, props.updateSocial)
                                        })
                                    }
                                }}
                                img sfoglia loadedAssets={props.loadedAssets} updateSocial={props.updateSocial} imgIndex={i}
                            />
                            {imgPreview(i)}
                            <br /> <br />
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}