import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Icon } from 'design-react-kit';
import Sprite from "../img/sprite.svg";
import './ModalFile.css';
import { getFileNameFromPath, getFileNameFromPathWithTimestamp } from "../Utils/Utils";
import { BUCKET_URL } from "../../config/config";
import Pager from "../UI/Table/Pager";

export default function ModalFile(props) {

    const [page, setPage] = useState(0)
    const [elementsInView, setElementsInView] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(null);

    //FILTRO
    const [filterValue, setFilterValue] = useState()
    const [filtered, setFiltered] = useState(null)

    const updateFilter = event => {
        let tmpFiltered
        if (filtered && event.target.value.startsWith(filterValue)) {
            tmpFiltered = filtered.filter(el => el.filename.toLowerCase().trim().indexOf(event.target.value.toLowerCase().trim()) !== -1)
        } else {
            tmpFiltered = props.array.filter(el => el.filename.toLowerCase().trim().indexOf(event.target.value.toLowerCase().trim()) !== -1)
        }

        setFilterValue(event.target.value)
        setFiltered(tmpFiltered)
        setElementsInView(tmpFiltered.slice(page * props.pagination, (page * props.pagination) + props.pagination))
        select(null)
        setPage(0)
    }

    const resetFilter = () => {
        updateFilter({ target: { value: "" } })
        setFiltered(null)
    }

    //on props change
    useEffect(() => {
        setElementsInView(props.pagination ? [...props.array].slice(0, props.pagination) : [...props.array])
        setPage(0)
    }, [props.array])


    //on page change
    useEffect(() => {
        if (props.pagination) {
            if (filtered)
                setElementsInView(filtered.slice(page * props.pagination, (page * props.pagination) + props.pagination))
            else setElementsInView([...props.array].slice(page * props.pagination, (page * props.pagination) + props.pagination))
        }
    }, [page])


    const select = (i) => {
        setSelectedIndex(i);
    }


    return (
        <Modal dialogClassName={"custom-modal modal-file"} show={props.show} onHide={props.onHide} backdrop={true}>
            <Modal.Header closeButton>
                <div className={'col-8'}>
                    <Modal.Title>{props.title}</Modal.Title>
                </div>
                <div className={'col-3'}>
                    <span className={"d-flex"}>
                        <input type="text" placeholder={"Filtra elenco"} style={{ "background": "transparent" }} className={`form-control`} value={filterValue} onChange={updateFilter} />
                        <svg className="icon" id={"clearFilter"} onClick={() => resetFilter()}>
                            <use href={Sprite + "#it-close"} />
                        </svg>
                    </span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className={'col-12 row'}>
                    <div className={'col-8'} id={"elements"}>
                        {elementsInView.map((el, i) =>
                            <ul key={el.filename + i}>
                                <li onClick={() => select(i)} className={'file'}>
                                    <Icon className="right" color="black" icon="it-file" id={"folder" + i} />
                                    <span className={selectedIndex === i ? "selected" : null}>{getFileNameFromPath(el.filename, true)}</span>
                                </li>
                            </ul>
                        )}
                        {elementsInView.length === 0 && <h5 className={'mt-2'}>Nessun elemento da visualizzare</h5>}
                    </div>
                    <div className={'col-4'}>
                        {selectedIndex !== null
                            ? <img id={"preview"}
                                src={
                                    filtered ?
                                        BUCKET_URL + filtered[selectedIndex + page * props.pagination]?.filename :
                                        BUCKET_URL + props.array[selectedIndex + page * props.pagination]?.filename
                                } alt={"Anteprima"} />
                            : <p>Seleziona un'immagine per visualizzarne un'anteprima</p>
                        }
                    </div>
                    <div className={'col-12'}>
                        {props.pagination && props.array && props.array.length > props.pagination &&
                            <Pager
                                page={page}
                                paginationSize={props.pagination}
                                nElements={filtered ? filtered.length : props.array.length}

                                //FUNZIONI
                                setPage={(page) => {
                                    select(null)
                                    setPage(page)
                                }}
                            />
                        }
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                {!props.body ?
                    <>
                        <Button variant="secondary" disabled={selectedIndex === null} onClick={() => props.onConfirm(elementsInView[selectedIndex].filename)}>
                            Conferma
                        </Button>
                        <Button variant="secondary" onClick={props.onHide}>
                            Annulla
                        </Button>
                    </> : null}
            </Modal.Footer>
        </Modal>
    )

}
