import React from 'react';
import { KO_TEMPLATE } from "../../Utils/Dataset";
import './Invalid_Template.css';

export default function Invalid_Template() {
    return (
        <div className="invalidTemplate">
            <div className="inputBoxWithShadow">
                {KO_TEMPLATE}
            </div>
        </div>
    )
}