import React from 'react';
import { Icon, Toggle } from 'design-react-kit';
import CustomInput from '../../UI/CustomInput/CustomInput';
import TextEditor from '../../UI/TextEditor/TextEditor';
import './Template_Applicazione_Custom.css';
import { Messages } from '../../Utils/Messages';
import Select from '../../UI/Select/Select';
import InfoModal from '../../UI/InfoModal/InfoModal';



export default function Template_Applicazione_Custom(props) {

    let arrayLinkAlternativo = props.content.arrayLink && props.content.arrayLink.length > 0 ? props.content.arrayLink.filter(el => el.id !== "PRGLA" && el.id !== "DISTURBI_ALIMENTARI").map(el => ({ ...el, value: el.id })) : []

    let showSecondoLink = () => {
        if (props.content.idLink === "DISTURBI_ALIMENTARI" || props.content.idLink === "PRGLA") return false
        else return true
    }

    let isDisableNomeColonnaSecondoLink = () => {
        if (props.content.idLinkAlternativo !== null) return false
        else return true
    }

    const linksDaCollegare = (
        <>
            <div className="row linksDaCollegare">
                <div className="col-6">
                    <Select idOption={"selectId"} title={showSecondoLink() ? "Primo link da collegare*" : "Link da collegare*"} placeholder="Seleziona il link da collegare..." array={props.content.arrayLink && props.content.arrayLink.length > 0 ? props.content.arrayLink.map(el => ({ ...el, value: el.id })) : []} value={props.content.idLink} change={props.content.idLinkChangedHandler} valid={true} fieldValue={"id"} fieldName={"nome"} />
                </div>
                {showSecondoLink() &&
                    <div className="col-5">
                        <CustomInput id="input1" labelName="Nome colonna*" change={props.content.titoloLinkChangedHandler} name={props.content.titoloLink} valid={true} />
                    </div>}
            </div>
            {showSecondoLink() &&
                <>
                    <div className="row linksDaCollegare">
                        <div className="col-6">
                            <Select idOption={"selectId"} title={"Secondo link da collegare".concat(props.content.linkInMutuaEsclusione ? "*" : "")} placeholder="Seleziona il link da collegare..." array={arrayLinkAlternativo} value={props.content.idLinkAlternativo} change={props.content.idLinkAlternativoChangedHandler} valid={true} fieldValue={"id"} fieldName={"nome"} />
                        </div>
                        <div className="col-5">
                            <CustomInput id="input1" labelName={"Nome colonna".concat(props.content.linkInMutuaEsclusione && props.content.idLinkAlternativo !== null ? "*" : "")} change={props.content.titoloLinkAlternativoChangedHandler} name={props.content.idLinkAlternativo === null ? null : props.content.titoloLinkAlternativo} valid={true} disabled={isDisableNomeColonnaSecondoLink()} />
                        </div>
                        <div className="col-auto" title={"Pulisci secondo link da collegare."} style={{ paddingTop: "2.8rem" }}>
                            <Icon icon="it-close-circle" onClick={props.content.resetLinkAlternativo} style={{ cursor: "pointer" }} />
                        </div>
                    </div>
                    <div className="row pt-3">
                        <div className="col-auto">
                            <Toggle onChange={props.content.setLinkInMutuaEsclusione} checked={props.content.linkInMutuaEsclusione} label={"Visualizza entrambi i link"} style={{ display: "inline-block !important" }} />
                        </div>
                        <div className="col-auto">
                            <InfoModal message={Messages.INFO_LINK_DA_COLLEGARE_APP_CUSTOM} />
                        </div>
                    </div>
                </>}
        </>
    )

    return (
        <div id="ApplicazioneStrutture">
            <div className="inputBoxWithShadow">
                <CustomInput id="input1" labelName="Titolo*" change={props.content.titleChangedHandler} name={props.content.title} valid={props.content.validateTitle} />
                <CustomInput id="input3" labelName="DESCRIZIONE (non visibile sul Portale)" change={props.content.descriptionChangedHandler} name={props.content.description} valid={props.content.validateDescription} textarea />
                <CustomInput id="input4" labelName="Url*" change={() => { }} name={props.content.path} valid={props.content.validatePath} tipologia={props.content.tipologia} setPath={props.content.setPath} invalidMsg={props.content.invalidMsg ?? props.content.invalidMsg} setInvalidMsg={props.content.setInvalidMsg} />
                {linksDaCollegare}
            </div>
            <label className="text-type">TESTO*</label>
            <TextEditor isErasable={false} array={props.content.testo} delete={props.content.removeTestoHandler} updateValue={props.content.updateTestoHandler} valid={props.content.validateTesto} indexOfPage={props.content.index} />
        </div>
    )
}