import { REPHEADERS, SERVICE_URL } from "../config/config";
import AuthenticationManager from "../sessionManager/AuthenticationManager";
import SessioneUtente from "../sessionManager/SessioneUtente";

export default function CestinoRepository() {

    const contenutiServiceUrl = SERVICE_URL + "/contenuti";

    const authManager = new AuthenticationManager();
    const session = SessioneUtente.getInstance();

    /* centralizza le logiche di inzializzazione degli Headers */
    async function getHeaders(requestUrl) {
        let defaultHeaders = { ...REPHEADERS };
        defaultHeaders["AZUser"] = defaultHeaders["AZUser"] + ";areaterritoriale=" + session.idAreaTerritoriale
        return await authManager.initRequestHeaders(requestUrl, defaultHeaders);
    }

    async function getEliminati(queryParams) {
        const requestUrl = contenutiServiceUrl + '/cestino?' + new URLSearchParams(queryParams);
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: 'GET'
        }).then(result => result.elementi)
    }

    async function ripristinaEliminati(ids) {
        const requestUrl = contenutiServiceUrl + '/cestino/ripristina';
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            body: JSON.stringify(ids),
            method: 'POST'
        }).catch(err => err)
    }

    async function eliminaContenutiDefinitivamente(queryParams) {
        const requestUrl = contenutiServiceUrl + '/cestino' + (queryParams ? "?" + new URLSearchParams(queryParams) : "");
        const headers = await getHeaders(requestUrl);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: 'DELETE'
        }).catch(err => err)
    }

    return Object.freeze({
        getEliminati,
        ripristinaEliminati,
        eliminaContenutiDefinitivamente,
    });

}