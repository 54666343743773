import React from 'react';
import "./CircleContainer.css";

export default function CircleContainer(props) {

    return (
        <section id={"circle-container"}>
            <span>{props.body && props.body}</span>
        </section>
    )
}