import React, { useState, useContext } from 'react';
import { Icon } from 'design-react-kit';
import './DatabaseRicercaItem.css';
import { Redirect, Link } from 'react-router-dom';
import { Context, } from "../../../../hook/globalState/Store";

export default function DatabaseRicercaItem(props) {

    const [redirect, setRedirect] = useState(null);

    const [state, dispatch] = useContext(Context);

    return (
        <>
            <div id={"databaseRicercaItem"}>
                <Link
                    to={{
                        pathname: props.pathname + props.href,
                        categoria: props.categoria,
                        sezionePadre: props.sezione,
                        queryParam: props.queryParam
                    }}
                >
                    <div className="row">
                        <div className="col-1">
                            <Icon
                                icon={"it-folder"}
                                color={"secondary"}
                                size={"lg"}
                            />
                        </div>
                        <div id="labelSezione" className="col-10">
                            <label>
                                {props.sezione}
                            </label>
                        </div>
                        <div id="rightArrow" className="col-1" >
                            <Icon
                                icon={"it-chevron-right"}
                                color={"secondary"}
                                onClick={() => {
                                    if (props.onClick)
                                        props.onClick();
                                }}
                            />
                        </div>
                    </div>
                </Link>
                <hr></hr>
            </div>
        </>
    )
}

function showRedirect(setRedirect, redirect) {
    setRedirect(redirect)
}
