const urlRegex = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#%[\]@!\$&'\(\)\*\+,;=.]+$/

export default function validate(value) {

    var res = true;

    if (!value || !value.trim().length || value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
        res = false;
    }

    return res;
}

export function validateEmail(email) {
    let res = true;
    let regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    if (!email || !email.trim().length || email.replace(/<(.|\n)*?>/g, '').trim().length === 0 || !email.match(regex)) {
        res = false;
    }

    return res;

}

export function validateCF(cf) {
    let res = true;

    if (!cf || !cf.trim().length || cf.replace(/<(.|\n)*?>/g, '').trim().length === 0 || cf.length !== 16) {
        res = false;
    }
    else res = true;

    return res;
}

export function validateCAP(cap) {
    let res = true;

    if (cap.length !== 5) {
        res = false;
    }

    return res;
}

export function validateUrl(url) {
    if (url && url.indexOf('/') === 0) {
        return true;
    }
    if (!url || !url.trim().length || url.replace(/<(.|\n)*?>/g, '').trim().length === 0 || !url.match(urlRegex)) {
        return false;
    }
    return true;
}

export function validateInternalUrl(url) {
    if (url && url.indexOf('/') === 0) {
        return true;
    }
    return false;
}

export function validateUrlNotRequired(url) {
    if (url && url.indexOf('/') === 0) {
        return true;
    }
    if (url?.trim()?.length > 0 && !url?.match(urlRegex)) {
        return false;
    }
    return true;
}

export function validateLinksGallery(links) {
    return links?.map((el, i) => {
        if (el.link?.trim()?.length > 0 && !el.link?.match(urlRegex)) {
            return false;
        }
        return true;
    })
}

export function validateLinksComeFarePer(links) {
    return links?.map((el, i) => {
        if (el.link?.trim()?.length > 0 && !el.link?.match(urlRegex)) {
            return false;
        }
        return true;
    })
}

export function validateSitiMotoreDiRicerca(siti) {
    return siti?.map((el, i) => {
        if (!el.sito || !el.sito?.trim().length || (el.sito?.trim()?.length > 0 && !el.sito?.match(urlRegex))) {
            return false;
        }
        return true;
    })
}

export function validatePassword(password) { //Minimo 8 caratteri, almeno una lettera minuscola, almeno una lettera maiuscola, almeno un numero e almeno un carattere speciale
    let res = true;
    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

    if (!password || !password.trim().length || password.replace(/<(.|\n)*?>/g, '').trim().length === 0 || !password.match(regex)) res = false;

    return res
}

export function validateQuill(values) {

    return values?.map((el, i) => {
        if (!el.titolo || !el.testo || !el.testo.trim().length || el.testo.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
            return false;
        }
        return true;
    })
}

export function validateQuillNoTitle(values) {
    return values?.map(el => !(!el.testo || !el.testo.trim().length || el.testo.replace(/<(.|\n)*?>/g, '').trim().length === 0))
}

export function validateTrue(values) {
    return values?.map((el, i) => {
        return true
    })
}

export function validateArray(values) {

    return values.map((el, i) => {
        if (!el.descrizione || !el.link) {
            return false;
        }
        return true;
    })
}

export function validateCustomApp(values) {

    return values?.map((el, i) => {
        if (!el.descrizione || !el.descrizione.trim().length) {
            return false;
        }
        return true;
    })
}

export function validateCustomCTA(values) {
    return values?.map((el, i) => {
        if (!el.descrizione || !el.descrizione.trim().length || !el.link || el.link?.replace(/<(.|\n)*?>/g, '')?.trim()?.length === 0 || !el.link?.trim()?.length || !el.link?.match(urlRegex)) {
            return false;
        }
        return true;
    })
}

export function validateQrCode(qrCode) {
    let res = true;
    let regex = /\d{6}/;

    if (!qrCode || !qrCode.match(regex)) {
        res = false;
    }

    return res
}

export function validateSitiCertificatiValues(values) {

    return values.map((el, i) => {
        if (!el.descrizione || !el.link) {
            return false;
        }
        if (!validateUrl(el.link)) {
            return false;
        }
        return true;
    })
}

export function validatePulsantiBluValues(values) {

    return values.map((el, i) => {
        if (!el.descrizione || !el.link) {
            return false;
        }
        if (!validateUrl(el.link)) {
            return false;
        }
        return true;
    })
}

export function validateContenutiCorrelatiValues(values) {

    return values.map((el, i) => {
        if (!el.descrizione || !el.link) {
            return false;
        }
        if (!validateInternalUrl(el.link)) {
            return false;
        }
        return true;
    })
}
