import React, { useState, useLayoutEffect } from 'react'
import { Icon } from 'design-react-kit'
import './MenuItem.css'
import { DragDropContext, Droppable, Draggable, resetServerContext } from 'react-beautiful-dnd'
import SubItem from './SubItem/SubItem';

export default function MenuItem(props) {

    const tooltipDelete = "Nascondi";
    const tooltipModifica = "Modifica";
    const tooltipMostra = "Mostra";
    const tooltipInterrompiModifica = "Interrompi modifica";

    const [updateDisabled, setUpdateDisabled] = useState(true);
    const [hideElement, setHideElement] = useState(false)
    const [customWidth, setCustomWidth] = useState();
    const [customIcon, setCustomIcon] = useState({ icon: "it-password-invisible", color: "black" });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = ((result) => {
        if (!result.destination) {
            return;
        }

        const newArrayItems = reorder(
            props.sottovoci,
            result.source.index,
            result.destination.index
        );

        props.changeVoce(props.index, "sottovoci", newArrayItems)

    });

    // Gestione custom width dell'input
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext("2d");

    useLayoutEffect(() => {
        if (props.etichetta) {
            setCustomWidth(ctx.measureText(props.etichetta).width * 2 + 25);
        }
        if (props.visibile !== undefined) {
            setHideElement(!props.visibile)
            if (props.visibile === true) {
                setCustomIcon({ icon: "it-password-invisible", color: "black" })
            }
            else {
                setCustomIcon({ icon: "it-password-visible", color: "black" })
            }
        }
    }, [props.etichetta, props.visibile])

    return (
        <>
            <div id="menuItem">
                <ul>
                    <li className={hideElement ? "content-disable" : "content"} key={props.id + props.index + "inner"}>
                        <span>
                            <Icon id={hideElement ? "primaryBurgerIcon-disable" : "primaryBurgerIcon"} className="left" icon="it-burger" />
                            <input
                                className={hideElement ? "input-disable" : !updateDisabled ? "input-update" : "input"}
                                style={{ width: customWidth + "px", maxWidth: "400px", backgroundColor: "transparent" }}
                                id={props.index}
                                value={props.contenitore ? (props.etichetta ? props.etichetta : "") : (props.nomeContenuto)}
                                onChange={(e) => props.changeVoce(props.index, "etichetta", e.target.value)}
                                disabled={updateDisabled}
                                readOnly={hideElement}
                            />
                            {updateDisabled ?
                                <>
                                    {props.motoreDiRicerca ? null : props.contenitore ?
                                        <a data-toggle="tooltip" data-selector="true" title={tooltipModifica}>
                                            <Icon
                                                id={hideElement ? "updateIcon-disable" : "updateIcon"}
                                                className="right"
                                                icon="it-pencil"
                                                onClick={() => {
                                                    change(setUpdateDisabled, updateDisabled)
                                                }}
                                            />
                                        </a> : null
                                    }
                                    <a data-toggle="tooltip" data-selector="true" title={!hideElement ? tooltipDelete : tooltipMostra}>
                                        <Icon
                                            id={"deleteIcon"}
                                            className="right"
                                            color={customIcon.color}
                                            icon={customIcon.icon}
                                            onClick={(e) => {
                                                change(setHideElement, hideElement)
                                                changeIcon(hideElement, setCustomIcon)
                                                props.changeVoce(props.index, "visibile", hideElement)
                                            }}
                                        />
                                    </a>
                                </> :
                                <a data-toggle="tooltip" data-selector="true" title={tooltipInterrompiModifica}>
                                    {props.motoreDiRicerca ?
                                        <input
                                            className={hideElement ? "input-disable" : !updateDisabled ? "input-update" : "input"}
                                            style={{ width: customWidth + "px", maxWidth: "400px" }}
                                            id={props.id}
                                            value={props.contenitore ? (props.etichetta ? props.etichetta : "") : (props.nomeContenuto)}
                                            onChange={(e) => props.changeVoce(props.index, "etichetta", e.target.value)}
                                            disabled={updateDisabled}
                                            readOnly={hideElement}
                                        /> :
                                        <Icon
                                            id={"confirmUpdate"}
                                            icon="it-check"
                                            color="success"
                                            onClick={(e) =>
                                                change(setUpdateDisabled, updateDisabled)
                                            }
                                        />
                                    }
                                </a>}
                        </span>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId={props.id + props.index + "droppable"}>
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        <ul>
                                            {props.sottovoci ? props.sottovoci.map((el, i) =>
                                                <Draggable key={el.idContenuto + i + "innDraggable"} draggableId={el.idContenuto?.toString()} index={i}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <li>
                                                                <SubItem
                                                                    index1={props.index}
                                                                    index2={i}
                                                                    visibile={el.visibile}
                                                                    etichetta={el.nomeContenuto ? el.nomeContenuto : el.etichetta}
                                                                    changeVoce={props.changeVoce}
                                                                    hideVoce={hideElement}
                                                                />
                                                            </li>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ) : null}
                                        </ul>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </li>
                </ul>
            </div >
        </>
    )
}

function change(setState, state) {
    setState(!state)
}

function changeIcon(hideElement, setCustomIcon) {
    if (hideElement) {
        setCustomIcon({ icon: "it-password-invisible", color: "black" })
    }
    else
        setCustomIcon({ icon: "it-password-visible", color: "black" })
}
