import React from 'react';
import { BUCKET_URL } from '../../config/config';
import { getFileNameFromPath } from '../Utils/Utils';
import './SimpleFileLoader.css';

export default function SimpleFileLoader(props) {

    return (
        <div id="simpleFileLoader">
            <div className="container">
                <form method="post" action="" encType="multipart/form-data">
                    {props.value && <a style={{ cursor: "pointer", textDecoration: "underline" }} href={BUCKET_URL + props.value} target="_blank"><label id="labelFile">{getFileNameFromPath(props.value)}</label></a>}
                    <input type="file" name={props.idLoad} id={props.idLoad} className="upload" multiple="multiple" onChange={props.change} />
                    {React.cloneElement(props.loaderLayout, { htmlFor: props.idLoad })}
                </form>
            </div>
        </div>
    )
}