import React, { useEffect, useState, useContext } from 'react'
import './Table.css'
import { Context } from "../../../hook/globalState/Store";
import Pager from "./Pager";

export default function Table(props) {

    const DEFAULT_PAGINATION_SIZE = 5
    const [PAGINATION_SIZE, SetPAGINATION_SIZE] = useState(props.pagination ? parseInt(props.pagination) : DEFAULT_PAGINATION_SIZE)

    let baseZeroPaginaCorrente = props.paginaCorrente - 1;

    function updatePage(page) {
        props.setPaginaCorrente(page + 1)
    }

    useEffect(() => {
        if (props.pagination)
            SetPAGINATION_SIZE(props.pagination)
    }, [props.pagination]
    )

    const [cols, setCols] = useState()
    const [elementsInView, setElementsInView] = useState(props.elements ? props.elements : [])

    //Default render functions
    const defaultRenderHeaders = (cols) => {

        return <tr>
            {cols && cols.map((el, index) =>
                <th key={`th${props.id}${index}`}>
                    <p>{el.label}</p>
                </th>)
            }
        </tr>
    }

    const defaultRenderRow = (row, rowIndex) => {
        return row && cols ? <tr key={`tr${props.id}${rowIndex}`}>
            {cols.map(el => renderCell(el, row, rowIndex))}
        </tr> : <></>
    }

    const defaultRenderCell = (el, row, rowIndex) => <td>
        {props.disableHtml
            ? <span key={`td${props.id}${rowIndex}`}>{row[el.key ? el.key : el]}</span>
            : <span key={`td${props.id}${rowIndex}`} dangerouslySetInnerHTML={{ __html: `<span>${row[el.key ? el.key : el]}</span>` }} />}
    </td>

    const defaultRenderPagination = () =>
        //props.totalElements > PAGINATION_SIZE &&
        <Pager
            page={baseZeroPaginaCorrente}
            paginationSize={PAGINATION_SIZE}
            nElements={props.totalElements}
            disablePagination={props.disablePagination}
            nBackendPages={props.nBackendPages}
            //FUNZIONI
            setPage={updatePage}
            addItemButton={addItemButton}
        />

    const defaultRenderFooter = () => <></>

    const defaultRenderNoElements = () => <h6 className={'mt-3 ml-3'}>Nessun elemento da visualizzare</h6>

    //Render functions
    const renderHeaders = props.renderHeaders ? props.renderHeaders : defaultRenderHeaders
    const renderRow = props.renderRow ? props.renderRow : defaultRenderRow
    const renderCell = props.renderCell ? props.renderCell : defaultRenderCell
    const renderFooter = props.renderFooter ? props.renderFooter : defaultRenderFooter
    const renderPagination = props.renderPagination ? props.renderPagination : defaultRenderPagination
    const addItemButton = props.addItemButton ? props.addItemButton : () => <></>
    const renderNoElements = props.renderNoElements ? props.renderNoElements : defaultRenderNoElements

    //Aggiorna paginazione
    useEffect(() => {
        setElementsInView(props.elements)
        setCols(props.cols ? props.cols : Object.keys(elementsInView[0]))
    }, [props.elements])

    const defaultRenderTable = () =>
        <>
            <table className={'customTable'}>
                <tbody id={props.id ? props.id : 'defaultTableId'}>
                    {renderHeaders(cols)}
                    {elementsInView.length
                        ? elementsInView.map((el, index) => renderRow(el, index, cols))
                        : renderNoElements()
                    }
                    {renderFooter()}
                </tbody>
            </table>
            {renderPagination()}
        </>

    return props.renderTable ? props.renderTable() : defaultRenderTable()
}