import React from 'react';
import TopHeader from "../FullHeader/TopHeader/TopHeader";
import CenterHeader from '../FullHeader/CenterHeader/CenterHeader';
import Flag from '../FullHeader/CenterHeader/Flag/Flag';
import UserFooter from '../UserFooter/UserFooter';
import { Headers } from 'design-react-kit';
import Main from '../Main/Main';
import './UserLayout.css'

function UserLayout(props) {
    return (
        <React.Fragment>
            <div className="pt-sticky-header">
                <div className="pt-header-content">
                    <div className="it-header-wrapper it-header-sticky">
                        <TopHeader />
                        <CenterHeader mostraScadenzaPassword={true} />
                        <Flag></Flag>
                    </div>
                </div>
            </div>
            <Main>
                {props.children}
            </Main>
            <UserFooter></UserFooter>
        </React.Fragment>
    )
}

export default UserLayout;