import React, { useState, useCallback, useContext, useEffect } from 'react';
import useDocumentTitle from '../../../hook/useDocumentTitle';
import { Button, Icon } from "design-react-kit";
import InPageAlert, { alertLevels } from "../../UI/InPageAlert/InPageAlert";
import './Cestino.css'
import TrashBinCard from "../../UI/Gallery/TrashBinCard";
import Gallery from "../../UI/Gallery/Gallery";
import useModal from "../../../hook/useModal";
import CustomModal from "../../CustomModal/CustomModal";
import { Messages } from "../../Utils/Messages";
import { useGetEliminati } from "./CestinoHooks";
import { Context, showError, showSuccess } from "../../../hook/globalState/Store";
import { getRemainingDays, isUtenteImpersonificato } from "../../Utils/Utils";
import { WAIT_FOR_OPERATION, operazioniContenuto, OK_OPERATION, maxElementi } from "../../Utils/Dataset";
import { Redirect } from "react-router-dom";
import CestinoRepository from '../../../Repo/CestinoRepository';
import SessioneUtente from '../../../sessionManager/SessioneUtente';

function Cestino(props) {

    useDocumentTitle(props.title);

    const [state, dispatch] = useContext(Context);
    const sessione = SessioneUtente.getInstance();

    const [redirect, setRedirect] = useState(false)
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [buttonSeleziona, setButtonSeleziona] = useState(true)
    const [action, setAction] = useState()

    const queryParams = { numeroElementi: maxElementi };
    const queryParamsAreaterritoriale = { areaTerritoriale: sessione.idAreaTerritoriale };

    const [deletedContents, deletedContentsFetched, setDeletedContents, setDeletedContentsFetched] = useGetEliminati({ ...queryParams, ...queryParamsAreaterritoriale });

    let disableButtons = deletedContents?.length === 0 ?? true
    const [disableRecupera, setDisabledRecupera] = useState(true)

    //Reset alert when entering page
    useEffect(() => { dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.NONE, alertMsg: null } }) }, [])


    const [isWaiting, setIsWaiting] = useState(false)
    const restoreHandler = useCallback(async (ids) => {
        // don't send again while we are sending
        if (isWaiting) return
        // update state
        setIsWaiting(true)

        dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.INFO, alertMsg: WAIT_FOR_OPERATION } });
        // send the actual request
        CestinoRepository().ripristinaEliminati(ids)
            .then(result => {
                showSuccess()
                setDeletedContentsFetched(false)
                setDisabledRecupera(true)
                dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
            })
            .catch(err => {
                showError(Messages.ERR_RIPRISTINO_CESTINO)
                setDisabledRecupera(true)
            })
        // once the request is sent, update state again
        setIsWaiting(false)
    }, [isWaiting])

    const permaDeleteHandler = useCallback(() => {
        // don't send again while we are sending
        if (isWaiting) return
        // update state
        setIsWaiting(true)
        dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.INFO, alertMsg: WAIT_FOR_OPERATION } });

        // send the actual request
        CestinoRepository().eliminaContenutiDefinitivamente(queryParamsAreaterritoriale)
            .then(result => {
                showSuccess()
                setDeletedContentsFetched(false)
                handleClose()
            })
            .catch(err => showError(Messages.ERR_SVUOTAMENTO_CESTINO))
        // once the request is sent, update state again
        setIsWaiting(false)
    }, [])

    useEffect(() => {
        if (state.reloadCestino) setDeletedContentsFetched(false)
        dispatch({ type: 'RELOAD_CESTINO', payload: { reloadCestino: false } });
    }, [state.reloadCestino])

    return (
        redirect ?
            <Redirect to="/" /> :
            <>
                <CustomModal
                    show={show}
                    onHide={handleClose}
                    title={"Attenzione"}
                    text={action === operazioniContenuto.INDIETRO ? "Ritorno alla pagina principale" : Messages.ERR_02_00_0E_0A}
                    onConfirm={() => callBackAndClose(handleClose, setRedirect, action, permaDeleteHandler)}
                />
                <div div id="trashBinContainer" >
                    <div className="row">
                        <div className={'col-6 p-0'}><h6 id={"title"}>CESTINO</h6></div>
                        <div id={"goBack"} className="col-6">
                            <a id={"buttonBack"} onClick={() => getModal(setAction, operazioniContenuto.INDIETRO, handleShow)}>
                                <Icon className="right" color="black" icon="it-arrow-left" id="arrow" />
                            Indietro
                        </a>
                        </div>
                    </div>
                    <div className="row col-9 p-0">
                        <i>Gli elementi indicano i giorni mancanti all'eliminazione. Dopo tale momento, gli elementi verranno eliminati in modo permanente.</i>
                    </div>
                    <div className={"row col-9 p-0"} id={"actions"}>
                        <Button color="secondary" outline="true" id={"svuotaTutto"} disabled={disableButtons} onClick={() => {
                            setAction("delete")
                            handleShow()
                        }}>
                            <span>
                                <Icon className="delete" color="black" icon="it-delete" id="delete" />
                                <span>Svuota tutto</span>
                            </span>
                        </Button>

                        {buttonSeleziona ?
                            <Button color="secondary" id={"recupera"} onClick={() => { setButtonSeleziona(false) }}>Seleziona</Button> :
                            <Button color="secondary" id={"recupera"} disabled={disableButtons || disableRecupera} onClick={() => {
                                restoreHandler(deletedContents.filter(el => el.checked).map(el => el.id))
                            }}>Recupera</Button>
                        }

                    </div>
                    <div className={"row col-9"} id={"actions"}>
                        {deletedContentsFetched ?
                            <Gallery id={"trashBinGallery"}>
                                {deletedContents.map((el, index) => <TrashBinCard
                                    seleziona={buttonSeleziona}
                                    id={"gallery" + index}
                                    content={{ ...el, "giorniRimanenti": 30 - getRemainingDays(el.dataCancellazione) + " giorni" }}
                                    index={index}
                                    change={(index) => {
                                        deletedContents[index].checked = !deletedContents[index].checked
                                        setDeletedContents(deletedContents)
                                        setDisabledRecupera(deletedContents.filter(el => el.checked).length === 0)
                                    }}
                                />)}
                            </Gallery> :
                            <span>Loading...</span>}
                    </div>
                </div >
            </>
    )
}


function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}

function callBackAndClose(handleClose, setRedirect, action, handler) {
    if (action === operazioniContenuto.INDIETRO) {
        handleClose();
        setRedirect(true);
    } else handler()
}


export default Cestino;
