import React from 'react';
import { Icon } from 'design-react-kit';
import CustomLink from './CustomLink/CustomLink';
import './ButtonLink.css';


export default function ButtonLink(props) {

    return (
        <div id="buttonLink">
            <div className="row">
                <label>{props.name}</label>
            </div>
            <div className="row">
                {!props.links || props.links.length === 0 ? <span>{props.initialize}</span> : null}
            </div>
            <div className="row">
                <button onClick={() => props.click()}>
                    <Icon className="left" color="black" icon="it-plus-circle" id="arrowRight" />
                    Aggiungi nuovo
                </button>
            </div>
            <div>
                {props.links ? props.links.map((el, i) =>
                    <CustomLink updateValue={props.updateValue} key={i} index={i} descrizione={el.descrizione} link={el.link} inNuovaFinestra={el.inNuovaFinestra} id={el.id} click={() => props.delete(i)} isValid={props.valid[i]} messaggioValidazione={props.messaggioValidazione} />
                ) : null}
            </div>
        </div>
    )
}