import React from "react";
import useDocumentTitle from "../../../hook/useDocumentTitle";
import './Error.css'
import { Headers } from 'design-react-kit';
import TopHeader from "../../layout/FullHeader/TopHeader/TopHeader";
import CenterHeader from "../../layout/FullHeader/CenterHeader/CenterHeader";
import Flag from "../../layout/FullHeader/CenterHeader/Flag/Flag";
import UserFooter from "../../layout/UserFooter/UserFooter";

export default function Error(props) {
    useDocumentTitle(props.title);


    return (
        <>
            <Headers sticky>
                <TopHeader />
                <CenterHeader mostraScadenzaPassword={false} />
                <Flag></Flag>
            </Headers>
            <div id={"errorPageDiv"} className={"text-center"}>
                <h3 className={"pt-4"}>Si è verificato un errore</h3>
            </div>
            <UserFooter />
        </>
    )
}
