import React, { useState, useCallback, useContext } from 'react';
import { Icon } from 'design-react-kit';
import './InserimentoContenuto.css';
import Select from '../../UI/Select/Select';
import useInput from '../../../hook/useInput';
import { Link, Redirect } from 'react-router-dom';
import CustomInput from '../../UI/CustomInput/CustomInput';
import useDocumentTitle from '../../../hook/useDocumentTitle';
import ContenutiRepository from '../../../Repo/ContenutiRepository';
import { Context, scrollToTop } from "../../../hook/globalState/Store";
import { templatesEnum, WAIT_FOR_OPERATION, linguaEnum } from "../../Utils/Dataset";
import { alertLevels } from "../../UI/InPageAlert/InPageAlert";
import SessioneUtente from '../../../sessionManager/SessioneUtente';


export default function InserimentoContenuto(props) {

    const [, dispatch] = useContext(Context);

    // SessioneUtente
    const session = SessioneUtente.getInstance()

    const [tipologia, , tipologiaChangeHandler] = useInput("");
    const [name, , nameChangedHandler] = useInput("");
    const [data, setData] = useState({});
    const [redirect, setRedirect] = useState(false);

    const [isWaiting, setIsWaiting] = useState(false)
    const nextHandler = useCallback(async (t, n, o) => {
        // don't send again while we are sending
        if (isWaiting) return
        // update state
        setIsWaiting(true)

        dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.INFO, alertMsg: WAIT_FOR_OPERATION } });
        scrollToTop()

        // send the actual request
        ContenutiRepository().creaContenuto(InizializzaContenuto(t, n, o, session))
            .then(result => {
                setData(result.contenuto);
                setRedirect(true);
                dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
            })
            .catch(err => {
            })
        // once the request is sent, update state again
        setIsWaiting(false)
    }, [isWaiting]) // update the callback if the state changes

    const options = templatesEnum.templatesEnum

    useDocumentTitle(props.title);

    return (
        <>
            {redirect && data?.id ? <Redirect to={{ pathname: '/edit/contenuto/' + data.id, customProps: { id: data.id, title: name } }} /> :
                <div id="insertContent">
                    <div className={'row pr-2'} id={"container"}>
                        <div className={'col-6'}>
                            <h4>Inserimento nuovo contenuto</h4>
                        </div>
                        <div className={'col-6 text-right '}>
                            <Link id="back" to="/">
                                <Icon className="left" color="black" icon="it-arrow-left" id="arrow" />Indietro
                                </Link>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-5">
                            <Select idOption={"selectId"} title={"TIPOLOGIA"} placeholder="Selezionare la tipologia di contenuto..." array={options} value={tipologia} change={tipologiaChangeHandler} fieldValue={"tipologiaEnum"} fieldName={"nome"} valid={true} />
                        </div>
                        <div className="col-7">
                            <CustomInput id="nameContent" labelName="Nome del contenuto" placeholder="Nome contenuto" change={nameChangedHandler} name={name} valid={true} />
                            {tipologia && name ? <button id="forward" disabled={isWaiting} onClick={() => nextHandler(tipologia, name, options)}>
                                <Icon className="left" color="black" icon="it-arrow-right-circle" id="arrowRight" />
                            </button> : null}
                        </div>
                    </div>
                </div >}
        </>
    )
}

function InizializzaContenuto(tipologia, nome, options, session) {

    var lingua = linguaEnum.ITALIANO;
    var template = "";

    let switchCase = options.filter(x => x.tipologiaEnum.toUpperCase() === tipologia.toUpperCase())[0];
    template = switchCase?.value;
    tipologia = switchCase?.tipologiaEnum.toUpperCase();
    let areaTerritoriale = session.idAreaTerritoriale

    return Object.freeze({
        template,
        tipologia,
        nome,
        lingua,
        areaTerritoriale
    });
}
