import React from "react";
import './UserBadge.css'
import { Icon } from "design-react-kit";

export default function UserBadge(props) {
    let nome = props.content?.nome ? props.content?.nome : "";
    let cognome = props.content?.cognome ? props.content?.cognome : "";
    let etichetta = (nome || cognome) ? nome + " " + cognome : props.content.codiceFiscale;
    return (
        <span className="user-badge-container">
            <span className="user-badge">
                {etichetta}
                {!props.readonly ? props.removeSelf && <Icon color="black" icon="it-close" id="deleteUser" onClick={() => props.removeSelf(props.index)} /> : null}
            </span>
        </span>
    )
}
