
import { Base64 } from 'js-base64';

export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    var res = Base64.atob(base64);
    var resArray = res.split('');
    var resMapped = resArray.map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    });
    var jsonPayload = decodeURIComponent(resMapped.join(''));

    return JSON.parse(jsonPayload);
}