import { useEffect, useState } from 'react';

export default function useFileHandler(initalValue, validate) {


    const [file, setFile] = useState(initalValue ? initalValue : null);
    const [fileMetadata, setFileMetadata] = useState(initalValue ? initalValue : {});
    const [callback, setCallback] = useState();

    const onChange = (e, callback) => {
        setCallback(() => callback) //salvo la funzione da eseguire a caricamento completato. useState non salva direttamente le funzioni => wrappare in una funzione anonima
        let readFile = e.target.files[0]

        setFileMetadata({
            lastModified: readFile.lastModified,
            lastModifiedDate: readFile.lastModifiedDate,
            name: readFile.name,
            size: readFile.size,
            type: readFile.type
        })


        setFile(readFile)
    }

    useEffect(() => {
        if (file !== null) { //viene eseguito quando il caricamento del file è effettivamente terminato
            if (callback) callback(file, fileMetadata)
        }
    }, [file])

    let validationResult = validate ? validate(file?.metadata?.name) : null;

    return [file, setFile, fileMetadata, setFileMetadata, onChange, validationResult]
}
