import React from 'react';
import { Badge, Button } from 'design-react-kit';
import './CustomForm.css';
import useModal from '../../../hook/useModal';
import ModalFile from '../../ModalFile/ModalFile';

export default function CustomForm(props) {

    const [show, , handleClose, handleShow] = useModal(false);

    //CONSTS
    const PAGINAZIONE = 5

    const buttonSfoglia = (
        <>
            {!props.readonly ?
                <div id={"buttonSfoglia"}>
                    <Button className={"btnSfoglia"} color={"secondary"} tag="button" onClick={handleShow} >sfoglia</Button>
                </div> :
                <div id={"buttonSfogliaDisabled"}>
                    <Button className={"btnSfogliaDisabled"} color={"secondary"} tag="button" onClick={handleShow} disabled={true}>sfoglia</Button>
                </div>}
            <ModalFile
                show={show}
                onHide={handleClose}
                title={"Immagini presenti nella collezione"}
                array={props.loadedAssets}
                pagination={PAGINAZIONE}
                onConfirm={(name) => {
                    props.updateSocial(props.imgIndex, "immagine", name)
                    handleClose()
                }}
            />
        </>
    )

    const buttonLoad = (
        <div id={"buttonLoad"}>
            {!props.readonly ? <><input type="file" id={props.id + "socialImg"} className="upload" multiple="multiple" onChange={props.changeImg} />
                <label id="btnLoad" htmlFor={props.id + "socialImg"}>
                    carica
            </label></> :
                <Button className={"btnCaricaDisabled"} color={"secondary"} tag="button" onClick={handleShow} disabled={true}>carica</Button>}
        </div>
    )

    return (
        <>
            {props.img ?
                <form method="post" action="" encType="multipart/form-data">
                    <div id="customForm">
                        <label className="label-text">{props.labelName}</label>
                        {props.tip ? <div className="label-tip"><i className="label-text">{props.tip}</i></div> : null}
                        <div className="form-group" key={props.key}>
                            <input style={{ maxWidth: "40%" }} className="mr-1" type="text" readOnly value={props.name || ""} />
                            {buttonLoad}
                            {props.sfoglia && buttonSfoglia}
                        </div>
                    </div>
                </form>
                :
                <div className="form-group" id="customForm" >
                    <label className="label-text">{props.labelName}</label>
                    {!props.removeSpacing && (<><br /><br /></>)}
                    <div id="inputBox">
                        <i>{props.customText ? props.customText : null} </i>
                        {!props.textarea ?
                            <input id={props.id} className={"d-block"} type="text" value={props.name || ""} disabled={props.disabled} onChange={props.change} /> :
                            <textarea rows="3" id={props.id} type="text" value={props.name || ""} onChange={props.change} key={props.key} readOnly={props.disabled} />
                        }
                    </div>
                </div>
            }
        </>
    )
}