import React from 'react';
import './CustomApp.css';
import Select from '../../Select/Select';
import { Toggle, Icon } from 'design-react-kit';


export default function CustomApp(props) {

    const tooltipDelete = "Rimuovi applicazione custom"

    return (
        <div id="CustomApp">
            <a data-toggle="tooltip" data-selector="true" title={tooltipDelete}>
                <button className={'float-right mt-2'} onClick={() => props.delete(props.id)} >
                    <Icon className="delete" color="black" icon="it-delete" id="delete" />
                </button>
            </a>

            <div className="row">
                <div className="col-8 p-0">
                    <label htmlFor={props.id}>DESCRIZIONE</label>
                    <div className="description">
                        <input id={props.id} type="text" className={`form-control`} placeholder={"Inserire una descrizione..."} value={props.descrizione} onChange={(e) => props.changeDescrizioneCustomApp(props.id, e)} />
                    </div>
                    {!props.isValid ? <p>E' necessario valorizzare la descrizione</p> : null}
                </div>
            </div>
            <Select idOption={"selectId"} title={"LINK DA COLLEGARE"} placeholder="Selezionare l'applicazione desiderata..." array={props.options && props.options.length > 0 ? props.options.map(el => ({ ...el, value: el.path })) : []} value={props.link} change={(e) => { props.change(e); props.changeLinkDaCollegare(props.id, e) }} fieldValue={"path"} fieldName={"nome"} />
            <div className="row">
                <div className="col-6">
                    <div className="switch">
                        <div>
                            <Toggle onChange={() => props.changeToggleCta(props.id)} checked={props.toggleValueCta} label={props.toggleNameCta} />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    {props.isToggleLinkVisibile ?
                        <div className="switch">
                            <div>
                                <Toggle onChange={() => props.changeToggleLink(props.id)} checked={props.toggleValueLink} label={props.toggleNameLink} />
                            </div>
                        </div> : null}
                </div>
            </div>
        </div>
    )
}
