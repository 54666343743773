import { LEA_URL, REPHEADERS, REPHEADERSLEA } from "../config/config";
import { leaEnum, tipologiaEnum } from "../components/Utils/Dataset";
import AuthenticationManager from '../sessionManager/AuthenticationManager';
import SessioneUtente from "../sessionManager/SessioneUtente";

export default function LeaRepository() {

    const leaServiceUrl = LEA_URL + "/lea"

    const authManager = new AuthenticationManager();
    const session = SessioneUtente.getInstance();

    /* centralizza le logiche di inzializzazione degli Headers */
    async function getHeaders(requestUrl, mockHeaders) {
        let defaultHeaders = { ...mockHeaders };
        defaultHeaders["AZUser"] = defaultHeaders["AZUser"] + ";areaterritoriale=" + session.idAreaTerritoriale
        return await authManager.initRequestHeaders(requestUrl, defaultHeaders);
    }

    // Ogni editExtra.. fa anche la creazione dell'extre LEA (nel caso in cui non fosse presente il LEA da modificare)

    // Branche
    async function getBranche() {
        const requestUrl = leaServiceUrl + "/branche";
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET"
        })
    }

    // Categorie - Visibilità può essere: { mostra, nascondi }
    async function getCategoria(idCategoria) {
        const requestUrl = leaServiceUrl + "/categorie/" + idCategoria;
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET"
        })
    }

    async function saveLea(content, tipoLea) {

        if (content.id) { //se il contenuto da salvare ha un id assegnato significa che si tratta una edit altrimenti è la post di un nuovo elemento
            return editLea(content, tipoLea);
        }
        else {
            return postLea(content, tipoLea);
        }
    }

    async function postLea(content, tipoLea) {

        let postLeaStrategy = getAusilio
        switch (tipoLea) {
            case leaEnum.AUSILI_MONOUSO:
                postLeaStrategy = postAusilioMonouso
                break
            case leaEnum.AUSILIO:
                postLeaStrategy = postAusilio
                break
            case leaEnum.PRESIDI:
                postLeaStrategy = postPresidio
                break
            case leaEnum.PRESTAZIONI:
                postLeaStrategy = postPrestazione
                break
        }

        return postLeaStrategy(content)

    }

    async function deleteLea(content, tipoLea) {

        let deleteLeaStrategy = getAusilio
        switch (tipoLea) {
            case leaEnum.AUSILI_MONOUSO:
                deleteLeaStrategy = deleteAusilioMonouso
                break
            case leaEnum.AUSILIO:
                deleteLeaStrategy = deleteAusilio
                break
            case leaEnum.PRESIDI:
                deleteLeaStrategy = deletePresidio
                break
            case leaEnum.PRESTAZIONI:
                deleteLeaStrategy = deletePrestazione
                break
        }

        return deleteLeaStrategy(content)

    }

    async function getLea(queryParams, tipoLea) {

        let getLeaStrategy = getAusilio
        switch (tipoLea) {
            case leaEnum.AUSILI_MONOUSO:
                getLeaStrategy = getAusiliMonouso
                break
            case leaEnum.AUSILIO:
                getLeaStrategy = getAusilio
                break
            case leaEnum.PRESIDI:
                getLeaStrategy = getPresidio
                break
            case leaEnum.PRESTAZIONI:
                getLeaStrategy = getPrestazione
                break
        }

        return getLeaStrategy(queryParams)
    }

    async function editLea(content, tipoLea) {

        let editLeaStrategy = getAusilio
        switch (tipoLea) {
            case leaEnum.AUSILI_MONOUSO:
                editLeaStrategy = editAusilioMonouso
                break
            case leaEnum.AUSILIO:
                editLeaStrategy = editAusilio
                break
            case leaEnum.PRESIDI:
                editLeaStrategy = editPresidio
                break
            case leaEnum.PRESTAZIONI:
                editLeaStrategy = editPrestazione
                break
        }

        return editLeaStrategy(content)
    }

    async function editExtraLea(lea, idAreaTerritoriale, tipoLea) {

        let editExtraLeaStrategy = editExtraAusilio
        switch (tipoLea) {
            case leaEnum.AUSILI_MONOUSO:
                editExtraLeaStrategy = editExtraAusilioMonouso
                break
            case leaEnum.AUSILIO:
                editExtraLeaStrategy = editExtraAusilio
                break
            case leaEnum.PRESIDI:
                editExtraLeaStrategy = editExtraPresidio
                break
            case leaEnum.PRESTAZIONI:
                editExtraLeaStrategy = editExtraPrestazione
                break
        }

        return editExtraLeaStrategy(lea, idAreaTerritoriale)
    }

    async function deleteExtraLea(content, idAreaTerritoriale, idExtraLea, tipoLea) {

        let deleteExtraLeaStrategy = deleteExtraAusilio
        switch (tipoLea) {
            case leaEnum.AUSILI_MONOUSO:
                deleteExtraLeaStrategy = deleteExtraAusilioMonouso
                break
            case leaEnum.AUSILIO:
                deleteExtraLeaStrategy = deleteExtraAusilio
                break
            case leaEnum.PRESIDI:
                deleteExtraLeaStrategy = deleteExtraPresidio
                break
            case leaEnum.PRESTAZIONI:
                deleteExtraLeaStrategy = deleteExtraPrestazione
                break
        }

        return deleteExtraLeaStrategy(content, idAreaTerritoriale, idExtraLea)
    }

    async function editVisibilitaCategoria(idCategoria, visibilita) {
        let action = visibilita ? 'mostra' : 'nascondi';
        const requestUrl = leaServiceUrl + "/categorie/" + idCategoria + "/" + action;
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "POST"
        })
    }

    // LEA - Ausili
    async function getAusilio(queryParams) {
        const requestUrl = leaServiceUrl + "/ausili?" + new URLSearchParams(queryParams);
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET"
        })
    }

    async function postAusilio(content) {
        const requestUrl = leaServiceUrl + "/ausili";
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(content)
        })
    }

    async function deleteAusilio(content) {
        const requestUrl = leaServiceUrl + "/ausili/" + content.id;
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "DELETE"
        })
    }

    async function editAusilio(content) {
        const requestUrl = leaServiceUrl + "/ausili/" + content.id;
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "PUT",
            body: JSON.stringify(content)
        })
    }
    async function editExtraAusilio(lea, idAreaTerritoriale) {
        const requestUrl = leaServiceUrl + "/ausili/" + lea.id + "/" + idAreaTerritoriale;
        const headers = await getHeaders(requestUrl, REPHEADERSLEA);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "PUT",
            body: JSON.stringify(lea.leaExtra)
        })
    }
    async function deleteExtraAusilio(content, idAreaTerritoriale, idExtraLea) {
        const requestUrl = leaServiceUrl + "/ausili/" + content.id + "/" + idAreaTerritoriale + "/" + idExtraLea;
        const headers = await getHeaders(requestUrl, REPHEADERSLEA);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "DELETE"
        })
    }
    async function getAusiliMonouso(queryParams) {
        const requestUrl = leaServiceUrl + "/ausili/monouso?" + new URLSearchParams(queryParams);
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET"
        })
    }

    async function postAusilioMonouso(content) {
        const requestUrl = leaServiceUrl + "/ausili/monouso";
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(content)
        })
    }

    async function deleteAusilioMonouso(content) {
        const requestUrl = leaServiceUrl + "/ausili/monouso/" + content.id;
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "DELETE"
        })
    }

    async function editAusilioMonouso(content) {
        const requestUrl = leaServiceUrl + "/ausili/monouso/" + content.id;
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "PUT",
            body: JSON.stringify(content)
        })
    }
    async function editExtraAusilioMonouso(lea, idAreaTerritoriale) {
        const requestUrl = leaServiceUrl + "/ausili/monouso/" + lea.id + "/" + idAreaTerritoriale;
        const headers = await getHeaders(requestUrl, REPHEADERSLEA);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "PUT",
            body: JSON.stringify(lea.leaExtra)
        })
    }
    async function deleteExtraAusilioMonouso(content, idAreaTerritoriale, idExtraLea) {
        const requestUrl = leaServiceUrl + "/ausili/monouso/" + content.id + "/" + idAreaTerritoriale + "/" + idExtraLea;
        const headers = await getHeaders(requestUrl, REPHEADERSLEA);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "DELETE"
        })
    }

    //LEA - Presidi
    async function getPresidio(queryParams) {
        const requestUrl = leaServiceUrl + "/presidi?" + new URLSearchParams(queryParams);
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET"
        })
    }

    async function postPresidio(content) {
        const requestUrl = leaServiceUrl + "/presidi";
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(content)
        })
    }

    async function deletePresidio(content) {
        const requestUrl = leaServiceUrl + "/presidi/" + content.id;
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "DELETE"
        })
    }

    async function editPresidio(content) {
        const requestUrl = leaServiceUrl + "/presidi/" + content.id;
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "PUT",
            body: JSON.stringify(content)
        })
    }
    async function editExtraPresidio(lea, idAreaTerritoriale) {
        const requestUrl = leaServiceUrl + "/presidi/" + lea.id + "/" + idAreaTerritoriale;
        const headers = await getHeaders(requestUrl, REPHEADERSLEA);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "PUT",
            body: JSON.stringify(lea.leaExtra)
        })
    }
    async function deleteExtraPresidio(content, idAreaTerritoriale, idExtraLea) {
        const requestUrl = leaServiceUrl + "/presidi/" + content.id + "/" + idAreaTerritoriale + "/" + idExtraLea;
        const headers = await getHeaders(requestUrl, REPHEADERSLEA);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "DELETE"
        })
    }

    //LEA - Prestazioni
    async function getPrestazione(queryParams) {
        const requestUrl = leaServiceUrl + "/prestazioni?" + new URLSearchParams(queryParams);
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET"
        })
    }

    async function postPrestazione(content) {
        const requestUrl = leaServiceUrl + "/prestazioni";
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(content)
        })
    }

    async function deletePrestazione(content) {
        const requestUrl = leaServiceUrl + "/prestazioni/" + content.id;
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "DELETE"
        })
    }

    async function editPrestazione(content) {
        const requestUrl = leaServiceUrl + "/prestazioni/" + content.id;
        const headers = await getHeaders(requestUrl, REPHEADERS);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "PUT",
            body: JSON.stringify(content)
        })
    }
    async function editExtraPrestazione(lea, idAreaTerritoriale) {
        const requestUrl = leaServiceUrl + "/prestazioni/" + lea.id + "/" + idAreaTerritoriale;
        const headers = await getHeaders(requestUrl, REPHEADERSLEA);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "PUT",
            body: JSON.stringify(lea.leaExtra)
        })
    }
    async function deleteExtraPrestazione(content, idAreaTerritoriale, idExtraLea) {
        const requestUrl = leaServiceUrl + "/prestazioni/" + content.id + "/" + idAreaTerritoriale + "/" + idExtraLea;
        const headers = await getHeaders(requestUrl, REPHEADERSLEA);
        headers["Content-Type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "DELETE"
        })
    }

    async function getAllNote() {
        const requestUrl = leaServiceUrl + "/note";
        const headers = await getHeaders(requestUrl, REPHEADERSLEA);
        headers["Content-type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET"
        })
    }

    async function getAllBranche() {
        const requestUrl = leaServiceUrl + "/branche";
        const headers = await getHeaders(requestUrl, REPHEADERSLEA);
        headers["Content-type"] = "application/json";
        return fetch(requestUrl, {
            headers: headers,
            method: "GET"
        })
    }

    async function getLeaExtra(idAreaTerritoriale) {

        const requestUrl = leaServiceUrl + "/extra/" + idAreaTerritoriale;
        const headers = await getHeaders(requestUrl, REPHEADERSLEA);
        headers["Content-type"] = "application/json";

        return fetch(requestUrl, {
            headers: headers,
            method: "GET"
        }).then(result => result)
    }

    async function editLeaExtra(payload, idAreaTerritoriale) {

        const requestUrl = leaServiceUrl + "/extra/" + idAreaTerritoriale;
        const headers = await getHeaders(requestUrl, REPHEADERSLEA);
        headers["Content-type"] = "application/json";

        return fetch(requestUrl, {
            headers: headers,
            method: "PUT",
            body: JSON.stringify(payload)
        }).then(result => result)
    }

    async function deleteLeaExtra(idAreaTerritoriale) {

        const requestUrl = leaServiceUrl + "/extra/" + idAreaTerritoriale;
        const headers = await getHeaders(requestUrl, REPHEADERSLEA);
        headers["Content-type"] = "application/json";

        return fetch(requestUrl, {
            headers: headers,
            method: "DELETE"
        }).then(result => result)
    }

    return Object.freeze({
        getBranche, getLea,
        getCategoria, editVisibilitaCategoria,
        editExtraLea, deleteExtraLea, getAllNote, getAllBranche,
        getLeaExtra, editLeaExtra, deleteLeaExtra,
        deleteLea, saveLea
    });

}
