import React, { useContext, useEffect, useState } from 'react';
import './HomePageUtenteRegionale.css';
import useDocumentTitle from '../../../../hook/useDocumentTitle';
import { Context, showLoading, hideLoading } from '../../../../hook/globalState/Store';
import NewsPublisher from '../../../UI/NewsPublisher/NewsPublisher';
import { BUCKET_URL } from '../../../../config/config';
import NewsRepository from '../../../../Repo/NewsRepository';
import moment from 'moment';
import { hasErrorOutcome } from '../../../Interceptor/Interceptor';
import SessioneUtente from '../../../../sessionManager/SessioneUtente';

export default function HomePageUtenteRegionale(props) {

    useDocumentTitle(props.title);
    var sessione = SessioneUtente.getInstance();
    const [state, dispatch] = useContext(Context)
    const [contentFetched, setContentFetched] = useState(false);
    const [notizieRecenti, setNotizieRecenti] = useState();

    useEffect(() => {
        if (!contentFetched) {
            showLoading()
            onLoadHandler(setNotizieRecenti, setContentFetched, sessione)
        }
    }, [contentFetched])

    useEffect(() => {
        setContentFetched(false);
    }, [sessione.idAreaTerritoriale])

    return (
        <div id="homePageUtenteRegionale">
            <div className="row">
                <h5>News aggiunte di recente</h5>
            </div>
            <div className={"recentNews"}>
                <div className="row">
                    {contentFetched && notizieRecenti?.map((el, i) =>
                        <div id={el.id} key={el.id + i} className="col-8">
                            <NewsPublisher
                                image={BUCKET_URL + el.immagineCopertina?.path} name={"group" + i}
                                id={"radio" + i} edit
                                editPath={"/edit/contenuto/" + el.id}
                                date={moment.utc(el.dataInserimento).local().format("DD-MM-YYYY")}
                                time={moment.utc(el.dataInserimento).local().format("HH:mm")}
                                cardTitle={"Data pubblicazione"} nomeContenuto={el.nome}
                            />
                        </div>
                    )}
                </div>
                {notizieRecenti?.length <= 0 &&
                    <i>Non sono presenti news aggiunte di recente.</i>
                }
            </div>
        </div>
    )
}

function onLoadHandler(setNotizieRecenti, setContentFetched, sessione) {

    let dateTo = moment().utc().subtract(30, 'days').format()

    // News aggiunte da massimo 30 giorni
    NewsRepository().getNewsPubblicateRecentemente({ date: dateTo, idAreaTerritoriale: sessione.idAreaTerritoriale })
        .then(data => {
            if (data && !hasErrorOutcome(data)) setNotizieRecenti(data)
        })
        .finally(() => {
            hideLoading()
            setContentFetched(true)
        })

}