import React, { useEffect, useState } from 'react';
import Sprite from "../../../img/sprite.svg";
import { useNotizieInScadenza } from '../../HomePage/HomePageHooks';
import { hideLoading, showLoading } from '../../../../hook/globalState/Store';
import NewsPublisher from '../../../UI/NewsPublisher/NewsPublisher';
import { BUCKET_URL } from '../../../../config/config';
import './NewsScadenza.css'
import moment from 'moment';
import useObjectsArray from '../../../../hook/useObjectsArray';
import NewsRepository from '../../../../Repo/NewsRepository';
import { operazioniContenuto, roleEnum } from '../../../Utils/Dataset';
import SessioneUtente from '../../../../sessionManager/SessioneUtente';


export default function NewsScadenza() {

    let sessione = SessioneUtente.getInstance()

    // Gestione news in scadenza senza limiti temporali
    const [contentFetched, setContentFetched] = useState(false);
    const [notizieInScadenza, setNotizieInScadenza] = useObjectsArray();

    // Gestione paginazione
    const [page, setPage] = useState(0)
    const [elementsInView, setElementsInView] = useState([])
    const prevPage = () => {
        if (page > 0)
            setPage(page - 1)
    }
    const nextPage = () => {
        if (notizieInScadenza.length / pagination > page + 1)
            setPage(page + 1)
    }
    const pagination = 2;

    // Gestione caricamento iniziale
    useEffect(() => {
        if (contentFetched) {
            hideLoading()
        }
        else {
            onLoadHandler(setNotizieInScadenza, setContentFetched, sessione)
            showLoading()
        }
    }, [contentFetched])

    // Gestione paginazione
    useEffect(() => {
        setElementsInView(pagination ? notizieInScadenza?.slice(0, pagination) : notizieInScadenza)
        setPage(0)
    }, [notizieInScadenza])

    useEffect(() => {
        if (pagination) {
            setElementsInView(notizieInScadenza?.slice(page * pagination, (page * pagination) + pagination))
        }
    }, [page])

    return (
        <div id="pageNewsScadenza">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {contentFetched && notizieInScadenza?.length !== 0 ?
                            <span>News in scadenza</span> :
                            null
                        }
                    </div>
                </div>
                <div id="mapNews">
                    {contentFetched && elementsInView?.map((el, i) =>
                        <NewsPublisher
                            image={BUCKET_URL + el.immagineCopertina.path}
                            cardTitle={operazioniContenuto.SCADENZA_PROGRAMMATA}
                            name={"group" + i}
                            id={"radio" + i}
                            date={moment.utc(el.dataScadenzaProgrammata).local().format("DD-MM-YYYY")}
                            time={moment.utc(el.dataScadenzaProgrammata).local().format("HH:mm")}
                            key={el.id} nomeContenuto={el.nome}
                        />
                    )}
                </div>
                {(contentFetched && notizieInScadenza?.length === 0) ?
                    <>
                        <div className={"newsType"}>
                            <span>Non sono presenti news in scadenza</span>
                        </div>
                    </> :
                    null
                }
                {pagination && notizieInScadenza?.length > pagination && (
                    <div id={"pagination"} className={"mb-4"}>
                        <span>Pagina {page + 1}</span>
                        <span onClick={prevPage}>
                            <svg className="icon">
                                <use xlinkHref={`${Sprite}#it-chevron-left`} />
                            </svg>
                        </span>
                        <span onClick={nextPage}>
                            <svg className="icon">
                                <use xlinkHref={`${Sprite}#it-chevron-right`} />
                            </svg>
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}

function onLoadHandler(setNotizieInScadenza, setContentFetched, sessione) {

    let dateTo = moment().utc().add(365, 'days').format()

    NewsRepository().getNewsInScadenza(dateTo, { areaTerritoriale: sessione.ruoliUtente === roleEnum.EDITORE && "" })
        .then(result => setNotizieInScadenza(result))
        .finally(setContentFetched(true))

}