import React, { useState, useEffect, useContext, useCallback } from 'react';
import NewsPublisher from '../../../UI/NewsPublisher/NewsPublisher';
import './GestisciNews.css';
import useDocumentTitle from '../../../../hook/useDocumentTitle';
import { useNotizieInPubblicazione, useNotizieInScadenza } from './../../HomePage/HomePageHooks';
import { Context, hideLoading, showLoading, showError } from "../../../../hook/globalState/Store";
import moment from 'moment';
import { BUCKET_URL } from "../../../../config/config";
import { Button } from "design-react-kit";
import CustomInput from "../../../UI/CustomInput/CustomInput";
import validate from "../../../UI/FieldValidation/FieldValidation";
import useInput from "../../../../hook/useInput";
import { alertLevels } from "../../../UI/InPageAlert/InPageAlert";
import ContenutiRepository from "../../../../Repo/ContenutiRepository";
import { templatesEnum, WAIT_FOR_OPERATION, operazioniContenuto, linguaEnum, tipologiaEnum } from "../../../Utils/Dataset";
import { Link, Redirect } from 'react-router-dom';
import NewsRepository from '../../../../Repo/NewsRepository';
import SessioneUtente from '../../../../sessionManager/SessioneUtente';


export default function GestisciNews(props) {

    useDocumentTitle(props.title);

    // SessioneUtente
    const session = SessioneUtente.getInstance()

    const [state, dispatch] = useContext(Context)
    const [contentsFetched, setContentsFetched] = useState(false);
    const [notizieInPubblicazione, setNotizieInPubblicazione] = useState();
    const [notizieInScadenza, setNotizieInScadenza] = useState();

    useEffect(() => {
        if (!contentsFetched) {
            showLoading()
            onLoadHandler(setNotizieInPubblicazione, setNotizieInScadenza, setContentsFetched, session)
        }
        else hideLoading()
    }, [contentsFetched])

    const headerMsg = 'Qui puoi gestire le news pubblicate e inserire nuovi contenuti regionali.'

    //Create news
    const [newsName, , onChangeNewsName, newsNameValid] = useInput("", validate)
    const [redirect, setRedirect] = useState()
    const [data, setData] = useState()
    const InizializzaContenuto = () => Object.freeze({
        'template': templatesEnum.templatesEnum[5].value,
        'tipologia': templatesEnum.templatesEnum[5].tipologiaEnum.toUpperCase(),
        'nome': newsName,
        'areaTerritoriale': session.idAreaTerritoriale,
        'lingua': linguaEnum.ITALIANO
    })


    const createNewsHandler = () => {
        dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.INFO, alertMsg: WAIT_FOR_OPERATION } });

        // send the actual request
        ContenutiRepository().creaContenuto(InizializzaContenuto())
            .then(result => {
                setData(result.contenuto);
                setRedirect(true);
                dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
            })
            .catch(err => err)
    }

    return (
        <div id="gestisciNewsPanel">
            {redirect && data?.id && <Redirect to={{ pathname: '/edit/contenuto/' + data.id, customProps: { id: data.id, title: newsName, tipologia: tipologiaEnum.NEWS } }} />}
            <div className="container">
                <h5>Gestisci le news</h5>
                <div id={"infoText"}>
                    <i>{headerMsg}</i>
                </div>
                <div className={'col-9 d-flex pl-0'}>
                    <div className={'col-5 pl-0'}>
                        <CustomInput name={newsName} hideLabel labelName="Aggiungi News" id={"inputTable"} valid change={onChangeNewsName} />
                    </div>
                    <div className={'col-7 btn-news'}>
                        <Button id="btnAggiungiNews" disabled={!newsNameValid} color={"secondary"} tag="button" onClick={createNewsHandler}>Aggiungi News</Button>
                        <Link to="/contenuti-news"><Button>Vedi tutte</Button></Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 newsType mt-3">
                        {contentsFetched && notizieInPubblicazione?.length > 0 &&
                            <>
                                <span>News in pubblicazione</span>
                                {notizieInPubblicazione?.map((el, i) =>
                                    <NewsPublisher
                                        edit editPath={'/edit/contenuto/' + el.id}
                                        image={BUCKET_URL + el.immagineCopertina.path}
                                        cardTitle={operazioniContenuto.PUBBLICAZIONE_PROGRAMMATA} name={"group" + i} id={"radio" + i}
                                        date={moment.utc(el.dataPubblicazioneProgrammata).local().format("DD-MM-YYYY")}
                                        time={moment.utc(el.dataPubblicazioneProgrammata).local().format("HH:mm")}
                                        key={el.id} nomeContenuto={el.nome}
                                    />
                                )}
                            </>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 newsType mt-3">
                        {contentsFetched && notizieInScadenza?.length > 0 &&
                            <>
                                <span>News in scadenza</span>
                                {notizieInScadenza?.map((el, i) =>
                                    <NewsPublisher
                                        edit editPath={'/edit/contenuto/' + el.id}
                                        image={BUCKET_URL + el.immagineCopertina.path} visible={true}
                                        cardTitle={operazioniContenuto.SCADENZA_PROGRAMMATA} name={"group" + i} id={"radio" + i}
                                        date={moment.utc(el.dataScadenzaProgrammata).local().format("DD-MM-YYYY")}
                                        time={moment.utc(el.dataScadenzaProgrammata).local().format("HH:mm")}
                                        key={el.id} nomeContenuto={el.nome}
                                    />
                                )}
                            </>
                        }
                    </div>
                </div>
                {(contentsFetched && !notizieInPubblicazione?.length) && (contentsFetched && !notizieInScadenza?.length) &&
                    <>
                        <div className={"newsType mt-3"}>
                            <span>Non sono presenti news in pubblicazione/scadenza</span>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

function onLoadHandler(setNotizieInPubblicazione, setNotizieInScadenza, setContentsFetched, session) {

    let dateTo = moment().utc().add(365, 'days').format() // getAll delle news in pubblicazione e in scadenza
    let filter = { areaTerritoriale: session.idAreaTerritoriale }

    Promise.all([NewsRepository().getNewsInPubblicazione(dateTo, filter), NewsRepository().getNewsInScadenza(dateTo, filter)])
        .then(([result1, result2]) => {
            setNotizieInPubblicazione(result1)
            setNotizieInScadenza(result2)
        })
        .catch(err => err)
        .finally(setContentsFetched(true))

}
