import Sprite from "../../img/sprite.svg";
import DeFlag from "../../img/logos/de-flag.png";
import FrFlag from "../../img/logos/fr-flag.png"
import React from "react";
import { linguaEnum } from '../../Utils/Dataset';

export default function LanguageFlag(props) {
    let flag;

    switch (props.lingua) {
        case linguaEnum.ITALIANO:
            flag = <svg className="icon d-none d-lg-block"><use href={Sprite + "#it-flag"} /></svg>;
            break;
        case linguaEnum.INGLESE:
            flag = <svg className="icon d-none d-lg-block"><use href={Sprite + "#uk-flag"} /></svg>;
            break;
        case linguaEnum.TEDESCO:
            flag = <img className="flag" src={DeFlag} />
            break;
        case linguaEnum.FRANCESE:
            flag = <img className="flag" src={FrFlag} />
            break;
        default:
            flag = <svg className="icon d-none d-lg-block"><use href={Sprite + "#it-flag"} /></svg>;
            break;
    }

    return flag;

}
