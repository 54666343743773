//global overwrite of fetch
import React, { useCallback } from "react";
import { getMessaggio504, getMessaggioOperazione, getMessaggioOperazioneAPMS, getMessaggioOperazioneMSRicerca, getSeveritaOperazione } from "../../Repo/Utils";
import { useContext } from "react";
import { Context, showError } from "../../hook/globalState/Store";
import { alertLevels } from "../UI/InPageAlert/InPageAlert";
import { ricercaServiceUrlCategoria, ricercaServiceUrlSito, RICERCA_URL, utentiServiceUrl } from "../../config/config";


//Se non ho res.esito (ad esempio nella get dei dati ho solo i dati, considero l'operazione OK)
export const hasErrorOutcome = res => (res.esito?.codRet ?? "001") !== "001" ?? false
export const hasErrorOutcomeAPMS = res => (res?.errore ? true : false)

export default function Interceptor(props) {

    const [state, dispatch] = useContext(Context);

    const manageError = (url, err, operationId) => {
        let errore = err.esito?.messaggi?.filter(el => el.codMsg !== "OK")[0]
        let erroreAPMS = err?.errore;
        let erroreMSRicerca = err?.message;

        if (err?.status === 504) {
            dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.WARN, alertMsg: getMessaggio504() + " (operationId: " + operationId + ")" } });
        }
        else if (errore) {
            dispatch({ type: 'FIELDS_ERROR', payload: { fieldsError: errore.parametri } })
            dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: getSeveritaOperazione(errore.severita), alertMsg: (getMessaggioOperazione(errore, err.esito) + " (operationId: " + operationId + ")") } });
        }
        else if (erroreAPMS) {
            showError(getMessaggioOperazioneAPMS(err) + " (operationId: " + operationId + ")")
        }
        else if (erroreMSRicerca) {
            showError(getMessaggioOperazioneMSRicerca(erroreMSRicerca))
        }
        //errori non da esito
        else {
            dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: getSeveritaOperazione(alertLevels.ERROR), alertMsg: getMessaggioOperazione(null) } });
        }

        throw err.message ? err.message : err
    }

    // const hasToIntercept = url => true //url.indexOf(SERVICE_URL) !== -1 //indirizzo backend da env
    const isResponseOk = res => res.status === 200 //il server risponde OK
    const manageRes = (res, url) => {
        if (isAPMS(url) ? !hasErrorOutcomeAPMS(res) : !hasErrorOutcome(res)) { //esito non bloccante
            return res
        } else throw res
    }

    const originalFetch = fetch
    fetch = useCallback((url, originalArguments) => {
        if (hasToIntercept(url, originalArguments)) {
            const operationId = getOriginalOperationId(originalArguments)
            return originalFetch(url, originalArguments)
                .then(res => {
                    if (isResponseOk(res)) {
                        return res.json()
                            .then(res => manageRes(res, url))  //la response è OK e contiene dati
                            .catch(res => manageRes(res, url)) //la response è OK ma NON contiene dati
                    } else throw res //il server risponde != OK
                }).catch(err => {
                    if (isMSRicerca(url)) err.json()
                        .then(err => manageError(url, err, operationId))
                    else manageError(url, err, operationId)
                }) //errore di connessione (oppure fa la catch degli errori generati nella gestione della response)
        } else if (hasJsonBody(url)) {
            return originalFetch(url, originalArguments)
                .then(data => data.json()).then(res => manageRes(res, url)) //la response è OK e contiene dati
                .catch(res => manageRes(res, url))
        }
        else {
            return originalFetch(url, originalArguments)
                .then(data => data).then(res => manageRes(res, url)) //la response è OK e contiene dati
                .catch(res => manageRes(res, url))
        }
    }, [])

    return <>{props.children}</>
}

function hasJsonBody(url) {
    if (url?.indexOf("/setMFAUtente") > -1 || url?.indexOf("verificaDispositivoMFA") > -1) {
        return false;
    }
    return true;
}

function hasToIntercept(url, originalArguments) {
    if (url?.indexOf("/setMFAUtente") > -1 || url?.indexOf("verificaDispositivoMFA") > -1) {
        return false;
    }
    if (originalArguments?.method === "GET" && ((url?.indexOf("/disturbialimentari") === -1) || (url?.indexOf("/tempiattesa") === -1))) {
        return false
    }
    else return true
}

function getOriginalOperationId(originalArguments) {
    let operationId = "Sconosciuto";
    const contextStringArray = originalArguments?.headers?.AZContext?.split(";");
    if (contextStringArray?.length) {
        const operationIdKeyValue = contextStringArray.find(x => x.toLowerCase().indexOf("operationid") === 0);

        if (operationIdKeyValue) {
            const operationIdKeyValueArray = operationIdKeyValue.split("=");

            if (operationIdKeyValueArray.length > 1) {
                operationId = operationIdKeyValueArray[1];
            }

        }
    }
    return operationId;
}

function isAPMS(url) {
    if (url?.indexOf(utentiServiceUrl) > -1) return true
    else return false
}

function isMSRicerca(url) {
    if (url?.indexOf(ricercaServiceUrlCategoria) > -1 || url?.indexOf(ricercaServiceUrlSito) > -1) return true
    else return false
}
