import React, { useContext, useEffect, useState } from 'react';
import TextEditor, { TextEditorItem } from '../../UI/TextEditor/TextEditor';
import './Template_05.css';
import CustomInput from '../../UI/CustomInput/CustomInput';
import SocialSharing from "../../UI/SocialSharing/SocialSharing";
import ToggleItem from "../../UI/ToggleItem/ToggleItem";
import Radio from "../../UI/Radio/Radio";
import FileLoader from "../../UI/FileLoader/FileLoader";
import Gallery from "../../UI/Gallery/Gallery";
import GalleryCard from "../../UI/Gallery/GalleryCard";
import AttachmentTable from "../../UI/AttachmentsTable/AttachmentsTable";
import SimpleFileLoader from "../../SimpleFileLoader/SimpleFileLoader";
import { Icon, Button } from "design-react-kit";
import UserBadge from "../../UI/UserBadge/UserBadge";
import ContenutiRepository from "../../../Repo/ContenutiRepository";
import Tag from '../../UI/Tag/Tag';
import { assetsEnum, extensionsFile, cataloghiEnum, provenienzaEnum, roleEnum, uploadLimits, KO_FONTE, linguaEnum } from "../../Utils/Dataset";
import { Context, showError, showSuccess } from "../../../hook/globalState/Store";
import { getFileExtension, getMessageUploadError, uploadImage, uploadImageGallery, uploadAllegato } from '../../Utils/Utils';
import { BUCKET_URL } from "../../../config/config";
import { Messages } from '../../Utils/Messages';
import { isUtenteRegionale } from '../../Utils/Utils';
import { ValidationValues } from '../../Utils/Validation';
import { hasErrorOutcome } from '../../Interceptor/Interceptor';
import SessioneUtente from '../../../sessionManager/SessioneUtente';
import InfoModal from '../../UI/InfoModal/InfoModal';
import { addNewAttachmentRow, attachmentLoader } from '../TemplateUtils';


export default function Template_05a(props) {

    //Gestione Fonti
    ////////////////////////////////////////////////////////////////////////////////////////
    const [state, dispatch] = useContext(Context)
    const [optionRadio, setOptionRadio] = useState([]);
    const [optionFetched, setOptionFetched] = useState(false);

    let session = SessioneUtente.getInstance();

    useEffect(() => {
        if (!optionFetched) {
            onLoadHandler(setOptionRadio, setOptionFetched)
        }
    }, [optionFetched]);

    function onLoadHandler(setOptionRadio, setOptionFetched) {
        ContenutiRepository().getCataloghi(cataloghiEnum.FONTE)
            .then(result => {
                setOptionRadio(result.map(el => ({ ...el, value: el.descrizione })));
                setOptionFetched(true);
            }).catch(err => {
                setOptionFetched(true);
            });
    }

    const addFonteHandler = (value) => {
        ContenutiRepository().creaCatalogo(inizializzaCatalogo(value, cataloghiEnum.FONTE))
            .then(data => setOptionFetched(false))
            .catch(err => err);
    }

    const removeCatalogoHandler = (id) => {
        ContenutiRepository().rimuoviCatalogo(id)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    showSuccess()
                } else {
                    showError(KO_FONTE)
                }
                setOptionFetched(false)
            })
            .catch(err => err);
    }

    const inizializzaCatalogo = (descrizione, tipologia) => {
        return Object.freeze({
            descrizione,
            tipologia
        })
    }
    ////////////////////////////////////////////////////////////////////////////////////////

    //Gestione Provenienza & Fonte interna
    ////////////////////////////////////////////////////////////////////////////////////////
    const optionFonteInterna = () => {
        if (optionRadio?.filter(el => el.descrizione?.toLowerCase().includes((props.content.role === roleEnum.AMMINISTRATORE || props.content.role === roleEnum.EDITORE) ? "portale" : "regioni")).length > 0)
            return optionRadio?.filter(el => el.descrizione?.toLowerCase().includes((props.content.role === roleEnum.AMMINISTRATORE || props.content.role === roleEnum.EDITORE) ? "portale" : "regioni"))
        else return optionRadio[0]
    }
    const optionProvenienza = [{ id: 1, descrizione: "Interna", value: "Interna" }, { id: 2, descrizione: "Esterna", value: "Esterna" }];
    ////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////

    //Gestione Provenienza & Fonte Esterna
    ////////////////////////////////////////////////////////////////////////////////////////
    const optionFonteEsterna = () => {
        return optionRadio?.filter(el => !el.descrizione.toLowerCase().includes("portale") && !el.descrizione.toLowerCase().includes("regioni"))
    }

    //Gestione Galleria
    ////////////////////////////////////////////////////////////////////////////////////////

    const uploadGalleryElement = (file) => uploadImageGallery(file, props.content.addGalleryPic)

    const picLoader =
        <SimpleFileLoader
            loaderLayout={(
                <label id={"addNew"} className="row">
                    <Icon className="left" color="black" icon="it-plus-circle" id="arrowRight" />
                    Aggiungi nuovo
                </label>
            )}
            change={(e) => {
                if (extensionsFile.IMMAGINI.includes(getFileExtension(e.target.files[0]))) {
                    uploadGalleryElement(e.target.files[0])
                    e.target.value = null;
                } else showError(getMessageUploadError("immagine", extensionsFile.IMMAGINI))
            }}
            idLoad="uploadGallery"
            name={props.content.currentGalleryPic}
        />

    ////////////////////////////////////////////////////////////////////////////////////////

    //Gestione Persone
    ////////////////////////////////////////////////////////////////////////////////////////
    const addNewUserBadgeInput = <>
        <CustomInput labelName="Nome" name={props.content?.manageUserInput?.nome} valid={props.content?.manageUserInput?.nome?.length > 0 ?? false} change={(e) => props.content.setManageUserInput({ ...props.content.manageUserInput, nome: e.target.value })} />
        <CustomInput labelName="Cognome" name={props.content?.manageUserInput?.cognome} valid={props.content?.manageUserInput?.cognome?.length > 0 ?? false} change={(e) => props.content.setManageUserInput({ ...props.content.manageUserInput, cognome: e.target.value })} />
        <div id={"addUserBadgeActions"}>
            <Icon className="left" color="black" icon="it-delete" id="deleteUserIcon" onClick={() => props.content.setManageUserInput({ nome: "", cognome: "", showUserBadgeInput: false })} />
            <Button id={"saveButton"} color={"secondary"} tag="button" disabled={props.content?.manageUserInput?.nome?.length > 0 && props.content?.manageUserInput?.cognome?.length > 0 ? false : true}
                onClick={(e) => {
                    if (props.content?.manageUserInput?.nome?.length > 0 && props.content?.manageUserInput?.cognome?.length > 0) {
                        props.content.setManageUserInput({ nome: "", cognome: "", showUserBadgeInput: false })
                        props.content.addPersona({ "nome": props.content.manageUserInput.nome, "cognome": props.content.manageUserInput.cognome, })
                    }
                }
                }> {"Aggiungi"}
            </Button>
        </div>
    </>

    const addNewUserBadge =
        <label className="row" id="addUserBadge" >
            {props.content.manageUserInput?.showUserBadgeInput ?? false ?
                <>{addNewUserBadgeInput}</> :
                <span onClick={() => props.content.setManageUserInput({ ...props.content.manageUserInput, showUserBadgeInput: true })}>
                    <Icon className="left" color="black" icon="it-plus-circle" id="addNewUserIcon" />
                Aggiungi nuovo
                </span>
            }
        </label>
    ////////////////////////////////////////////////////////////////////////////////////////

    // Gestione immagine copertina
    ////////////////////////////////////////////////////////////////////////////////////////
    const uploadFileCopertina = (file) => uploadImage(file, props.content.setFileCopertina, uploadLimits.IMG_COPERTINA)
    ////////////////////////////////////////////////////////////////////////////////////////

    return (
        <>
            <div id="Template5" className="notizia">
                {props.content.provenienza === provenienzaEnum.INTERNA ?
                    <>
                        <div className={"inputBoxWithShadow sourceRadio"}>
                            {props.content.lingua === linguaEnum.ITALIANO ?
                                <Radio title={"Provenienza"} inline id={"provenienzaRadio"} options={optionProvenienza} value={props.content.provenienza} change={props.content.provenienzaChangeHandler} notDelete /> :
                                <div className="pb-4"><label>Provenienza</label><div>{props.content.provenienza}</div></div>}
                            {props.content.lingua === linguaEnum.ITALIANO ?
                                <Radio title={"Fonte*"} inline id={"sourceRadio"} options={optionFonteInterna()} value={props.content.fonte} change={props.content.fonteChangeHandler} radioButtonValue={props.content.newRadioButton} changeRadioButton={props.content.changeNewRadioButtonHandler} notDelete ruoloUtente={props.content.role} /> :
                                <div><label>Fonte</label><div>{props.content.fonte}</div></div>}
                        </div>

                        <div className="inputBoxWithShadow">
                            <CustomInput id="input1" labelName="Titolo*" change={props.content.titleChangedHandler} name={props.content.title} valid={props.content.validateTitle} />
                            <CustomInput id="input2" labelName="Sottotitolo" change={props.content.subTitleChangedHandler} name={props.content.subTitle} valid={props.content.validateSubTitle} />
                            <CustomInput id="input3" labelName="DESCRIZIONE (non visibile sul Portale)" change={props.content.descriptionChangedHandler} name={props.content.description} valid={props.content.validateDescription} textarea />
                            <CustomInput id="input4" labelName="Url*" change={props.content.pathChangedHandler} name={props.content.path} valid={props.content.validatePath} tipologia={props.content.tipologia} setPath={props.content.setPath} invalidMsg={props.content.invalidMsg ?? props.content.invalidMsg} setInvalidMsg={props.content.setInvalidMsg} />
                        </div>
                        <FileLoader labelName="IMMAGINE DI COPERTINA*" tip={Messages.SIZE_CONSIGLIATA_IMMAGINE_COPERTINA} idLoad="upload1" change={(e) => props.content.onChangeCurrentAttachment(e, (fileBin, fileMetadata) => uploadFileCopertina(fileBin))} name={props.content.fileCopertina} valid={props.content.validateCopertina} alternativeText={props.content.alternativeTextImgCopertina} changeAlternativeText={props.content.alternativeTextImgCopertinaChangedHandler} setFile={props.content.setFileCopertina} type={assetsEnum.IMMAGINI}
                            loadedAssets={props.content.loadedAssets} preview previewUri={props.content.fileCopertina ? BUCKET_URL + props.content.fileCopertina : null}
                        />

                        <div className="row inputBoxWithShadow d-flex">
                            <ToggleItem inputType={"date"} toggleName={"Data"} toggleValue={props.content.toggleDate} formValue={props.content.inputDate} changeToggle={props.content.setToggleDate} changeValue={props.content.inputDateChangedHandler} id={"toggleDate"} valid={props.content.validateInputDate} />
                            <ToggleItem inputType={"text"} toggleName={"Tempo di lettura"} toggleValue={props.content.toggleReading} formValue={props.content.tempoLettura} changeToggle={props.content.setToggleReading} changeValue={props.content.tempoLetturaChangedHandler} id={"toggleText"} valid={props.content.validateTempoLettura} rejectNaN={props.content.rejectNaN} />
                        </div>

                        <div className={"inputBoxWithShadow"}>
                            <Tag dynamicOptions id={"sourceTag"} options={props.content.tags} onAdd={(chip) => props.content.addTag(chip)} onDelete={props.content.removeTag} value={props.content.newTag} change={props.content.tagChangedHandler} infoMessage={Messages.INFO_TAG} />
                        </div>

                        <label className="text-type">testo*</label>
                        <TextEditor isErasable={false} label={"testoSuperiore"} array={props.content.testo} click={props.content.addTestoHandler} delete={props.content.removeTestoHandler} updateValue={props.content.updateTestoHandler} valid={props.content.validateTesto} indexOfPage={props.content.index} />

                        <div className={"inputBoxWithShadow"}>
                            <Gallery id={"gallery"} addElementRender={picLoader} label={"Galleria"} infoMessage={Messages.INFO_GALLERIA}>
                                {props.content.galleryPics.map((el, index) => <GalleryCard id={"gallery" + index} key={"galleryKey" + index} content={{ ...el, path: BUCKET_URL + el.path }} index={index} removeSelf={props.content.removeGalleryPic} value={el.descrizione} change={props.content.updateGalleryPic} link={el.link} validateLink={props.content.validateGallery[index]} />)}
                            </Gallery>
                        </div>

                        <label className="text-type">testo inferiore</label>
                        <TextEditor isErasable={true} label={"testoInferiore"} array={props.content.testoInferiore} click={props.content.addTestoInferioreHandler} delete={props.content.removeTestoInferioreHandler} updateValue={props.content.updateTestoInferioreHandler} valid={props.content.validateTestoInferiore} indexOfPage={props.content.index} />

                        <div className={"inputBoxWithShadow"}>
                            <AttachmentTable label={"Allegati"}
                                attachments={props.content.attachments}
                                attachmentLoader={(index) => attachmentLoader(index, props.content.onChangeCurrentAttachment, props.content.updateAttachment)}
                                removeAttachment={props.content.removeAttachment}
                                addElementRender={addNewAttachmentRow(props.content.addAttachment)}
                                updateAttachment={props.content.updateAttachment}
                            />
                        </div>

                        <div className={'inputBoxWithShadow'}>
                            <label id={"ulterioriInformazioni"}>Ulteriori Informazioni</label>
                            <div id={"ulterioriInformazioniDiv"}>
                                <CustomInput id="inputDescrizione" labelName="Descrizione" change={props.content.moreDescriptionChangeHandler} name={props.content.moreDescription} valid={true} />
                                <CustomInput id="inputIndirizzo" labelName="Indirizzo" change={props.content.moreAddressChangeHandler} name={props.content.moreAddress} valid={true} />
                            </div>
                        </div>
                        <div className={"inputBoxWithShadow"}>
                            <Gallery id={"badgesGallery"} addElementRender={addNewUserBadge} label={"Persone"}>
                                {props.content.persone.map((el, index) => <UserBadge id={"userBadge" + index} key={"badgeKey" + index} content={el} index={index} removeSelf={props.content.removePersona} icon="it-user" />)}
                            </Gallery>
                        </div>

                        <div className={'inputBoxWithShadow'}>
                            <SocialSharing labelName={"CONDIVISIONE SUI SOCIAL"} valueUrl={props.content.path} socials={props.content.socials} updateSocial={props.content.updateSocial} titolo={props.content.title} descrizione={props.content.description} immagine={props.content.fileCopertina} onChangeCurrentAttachment={props.content.onChangeCurrentAttachment} loadedAssets={props.content.loadedAssets} />
                        </div>
                    </> :
                    <>
                        <div className={"inputBoxWithShadow sourceRadio"}>
                            {props.content.lingua === linguaEnum.ITALIANO ?
                                <Radio title={"Provenienza"} inline id={"provenienzaRadio"} options={optionProvenienza} value={props.content.provenienza} change={props.content.provenienzaChangeHandler} notDelete /> :
                                <div className="pb-4"><label>Provenienza</label><div>{props.content.provenienza}</div></div>}
                            {props.content.lingua === linguaEnum.ITALIANO ?
                                !isUtenteRegionale(session.ruoliUtente) ?
                                    <Radio title={"Fonte*"} inline dynamicOptions id={"sourceRadio"} options={optionFonteEsterna()} addFonteHandler={addFonteHandler} deleteFonteHandler={removeCatalogoHandler} value={props.content.fonte} error={!props.content.fonte} errorMsg={"E' necessario selezionare almeno una fonte"} change={props.content.fonteChangeHandler} radioButtonValue={props.content.newRadioButton} changeRadioButton={props.content.changeNewRadioButtonHandler} infoMessage={Messages.INFO_FONTE} ruoloUtente={props.content.role} /> :
                                    <Radio title={"Fonte*"} inline id={"sourceRadio"} options={optionFonteInterna()} value={props.content.fonte} change={props.content.fonteChangeHandler} radioButtonValue={props.content.newRadioButton} changeRadioButton={props.content.changeNewRadioButtonHandler} notDelete ruoloUtente={props.content.role} /> :
                                <div><label>Fonte</label><div>{props.content.fonte}</div></div>}
                        </div>

                        <div className="inputBoxWithShadow">
                            {/* // TODO - allineare il campo link al servizio */}
                            <CustomInput id="input5" labelName="Link*" change={props.content.linkChangedHandler} name={props.content.link} valid={props.content.validateLink} />
                            <CustomInput id="input6" labelName="Titolo*" change={props.content.titleChangedHandler} name={props.content.title} valid={props.content.validateTitle} />
                            <div className="fieldTesto">
                                <label className={"fieldLabel"}>{"Testo breve*"}</label><InfoModal message={"Limite massimo di caratteri: 250 (spazi inclusi)"} />
                                <TextEditorItem testo={props.content.testo[0].testo} index={0} updateValue={(updatedContent) => props.content.updateTestoHandler(0, "testo", updatedContent, null, null)} />
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}
