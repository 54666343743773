import React, { createContext, useReducer } from "react";
import Reducer from './Reducer'
import { alertLevels } from "../../components/UI/InPageAlert/InPageAlert";
import { extensionsFile, OK_OPERATION } from "../../components/Utils/Dataset";


const initialState = {
    reloadMenu: false,
    reloadEditContent: false,
    reloadEditMenu: false,
    reloadTable: false,
    reloadHomepage: false,
    reloadCestino: false,

    reloadPath: false,

    alertLevel: false,
    alertMsg: null,

    scrollTop: false,

    redirect: false,
    redirectUrl: null,

    isLoading: false,

    fieldsError: null,

    isMenuCollapsed: false,

    userAuthenticated: false,

    forceLogout: false
};

let exportDispatch;
const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    exportDispatch = dispatch
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};

export const Context = createContext(initialState);
export default Store;

//ACTIONS
export const showLoading = () => exportDispatch({ type: "LOADING", payload: { isLoading: true } });
export const hideLoading = () => exportDispatch({ type: "LOADING", payload: { isLoading: false } });
export const scrollToTop = () => exportDispatch({ type: 'SCROLL_TOP', payload: { scrollTop: true } });
export const showError = (errorMsg) => exportDispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.ERROR, alertMsg: errorMsg } });
export const showSuccess = (msg) => exportDispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.SUCCESS, alertMsg: msg ? msg : OK_OPERATION } });
export const showWarning = (msg) => exportDispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.WARN, alertMsg: msg } })
export const dismissAlert = () => exportDispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.NONE, alertMsg: null } });
export const collapseMenu = () => exportDispatch({ type: "MENU", payload: { isMenuCollapsed: true } });
export const expandMenu = () => exportDispatch({ type: "MENU", payload: { isMenuCollapsed: true } })
export const reloadMenu = () => exportDispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
export const reloadHomepage = () => exportDispatch({ type: 'RELOAD_HOMEPAGE', payload: { reloadHomepage: true } });
export const setUserAuthenticated = (value) => exportDispatch({ type: 'USER_AUTHENTICATED', payload: { userAuthenticated: value } });
export const setForceLogout = () => exportDispatch({ type: 'FORCE_LOGOUT', payload: { forceLogout: true } });
export const reloadCestino = () => exportDispatch({ type: 'RELOAD_CESTINO', payload: { reloadCestino: true } })
