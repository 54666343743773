import React, { useCallback } from 'react'
import MenuItem from '../../../UI/MenuItem/MenuItem'
import './MenuSection.css'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default function MenuSection(props) {

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = ((result) => {
        if (!result.destination) {
            return;
        }

        const newArrayItems = reorder(
            props.menuContents?.voci,
            result.source.index,
            result.destination.index
        );

        props.menuContents.setVoci(newArrayItems)

    });

    console.log("VOCI: ", props.menuContents)

    return (
        <>
            <div id="mainMenu" className={"ml-4"}>
                {props.menuContents?.voci ?
                    <>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {props.menuContents?.voci ? props.menuContents?.voci.map((el, i) => (
                                            el.ordinabile ?
                                                <Draggable key={el.idContenuto + i} draggableId={"draggable" + i} index={i} isDragDisabled={props.dragDisabled}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <MenuItem
                                                                contenitore={el.contenitore}
                                                                index={i}
                                                                id={el.idContenuto}
                                                                etichetta={el.etichetta}
                                                                nomeContenuto={el.nomeContenuto}
                                                                visibile={el.visibile}
                                                                sottovoci={el.sottovoci}
                                                                changeVoce={props.menuContents?.updateVoce}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable> : null)) :
                                            null
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        {props.menuContents?.voci.map((el, i) => (
                            !el.ordinabile &&
                            <MenuItem
                                key={el.idContenuto + "notOrdOut" + i}
                                index={i}
                                id={el.idContenuto + "notOrd"}
                                etichetta={el.etichetta}
                                nomeContenuto={el.nomeContenuto}
                                sottovoci={el.sottovoci}
                                visibile={el.visibile}
                                changeVoce={props.menuContents?.updateVoce}
                            />
                        ))}
                    </> :
                    null
                }
            </div>
        </>
    )
}
