import React, { useState, useContext, useEffect, Fragment } from 'react';
import useDocumentTitle from '../../../../hook/useDocumentTitle';
import './Redattori.css'
import UserBadge from "../../../UI/UserBadge/UserBadge";
import { Button, Icon } from "design-react-kit";
import CustomInput from "../../../UI/CustomInput/CustomInput";
import {
    statoContenuto,
    operazioniContenuto,
    areeTerritorialiEnum,
    KO_OPERATION,
    operazioniUtente,
    roleEnum,
    typeOrder,
    maxElementi
} from "../../../Utils/Dataset";
import useModal from "../../../../hook/useModal";
import useInput from "../../../../hook/useInput";
import { validateCF, validateEmail } from "../../../UI/FieldValidation/FieldValidation";
import Card from "../../../UI/Card/Card";
import Publisher from "../../../UI/Publisher/Publisher";
import { Messages } from '../../../Utils/Messages';
import CustomModal from '../../../CustomModal/CustomModal';
import SessioneUtente from '../../../../sessionManager/SessioneUtente';
import Select from '../../../UI/Select/Select';
import { hideLoading, showError, showLoading, showSuccess } from '../../../../hook/globalState/Store';
import UtentiRepository from '../../../../Repo/UtentiRepository';
import { hasErrorOutcomeAPMS } from '../../../Interceptor/Interceptor';
import TableRicerca from "../../../UI/TableRicerca/TableRicerca";
import { isUtenteRegionale } from "../../../Utils/Utils";

function Redattori(props) {

    useDocumentTitle(props.title);
    var session = SessioneUtente.getInstance()

    //Consts
    const headerMsg = "Qui puoi gestire i ruoli e i dati degli utenti."
    const ruoli = [
        { id: roleEnum.AUTORE, nome: "Autore - Referente regionale" },
        { id: roleEnum.COLLABORATORE, nome: "Collaboratore - Utente regionale" }
    ]

    //Gestione finestre modali
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [action, setAction] = useState("");

    //Gestione utenti
    const [editing, setEditing] = useState(false)
    const [editIndex, setEditIndex] = useState(null)
    const [contentFetched, setContentFetched] = useState(false)

    const [redattoriList, setRedattoriList] = useState([])
    const [headers] = useState([
        { key: "nome", name: "Nome", sortDirection: typeOrder.NONE },
        { key: "cognome", name: "Cognome", sortDirection: typeOrder.NONE },
        { key: "codicegruppo", name: "Ruolo", sortDirection: typeOrder.NONE },
        { key: "modifica", name: "Modifica" }]);

    const [nome, setNome] = useState('')
    const [cognome, setCognome] = useState('')
    const [codiceFiscale, setCodiceFiscale, , validCF] = useInput('', validateCF)
    const [email, setEmail, , validEmail] = useInput('', validateEmail)
    const [confermaEmail, setConfermaEmail] = useInput('')
    const [selectedRole, setSelectedRole, selectedRoleChangedHandler] = useInput('')
    const [index, setIndex] = useState(0)

    const resetInputs = () => {
        setNome('')
        setCognome('')
        setCodiceFiscale('')
        setSelectedRole('')
        setEmail('')
        setConfermaEmail('')
    }
    const undoEdit = () => {
        resetInputs()
        setEditing(false)
    }

    const removeRedattore = (i) => {
        showLoading()
        console.log("REDATTORI: ", redattoriList)
        UtentiRepository().deleteUser(InizializzaRedattore(redattoriList[i]?.codiceFiscale, redattoriList[i]?.nome, redattoriList[i]?.cognome, redattoriList[i]?.gruppi[0]?.codice_gruppo, redattoriList[i]?.mail, session))
            .then(result => {
                if (result && !hasErrorOutcomeAPMS(result)) {
                    setContentFetched(false)
                    showSuccess()
                }
            })
            .finally(() => hideLoading())
    }
    const editNew = () => {
        setEditIndex(null)
        setEditing(true)
    }
    const editExistent = (i) => {

        setNome(redattoriList[i]?.nome)
        setCognome(redattoriList[i]?.cognome)
        setCodiceFiscale(redattoriList[i]?.codiceFiscale)
        setSelectedRole(redattoriList[i]?.codicegruppo)
        setEmail(redattoriList[i]?.mail)
        setConfermaEmail(redattoriList[i]?.mail)

        setEditing(true)
        setEditIndex(i)
    }

    const hasError = () => {

        return cognome?.trim().length === 0 ||
            nome?.trim().length === 0 ||
            codiceFiscale?.trim().length === 0 ||
            !validCF ||
            email?.trim().length === 0 ||
            !validEmail ||
            (confermaEmail !== email) ||
            selectedRole?.trim().length === 0
    }

    const tableCell = (key, row, id) => {
        let cell;
        let index = redattoriList.indexOf(row);
        if (key === "modifica") {
            cell = (<td className={key}>
                <span title={"Elimina utente"}>
                    <Icon
                        color="black" icon="it-delete" id="deleteUser" size="lg"
                        onClick={() => {
                            setIndex(index)
                            getModal(setAction, operazioniContenuto.ELIMINAZIONE, handleShow);
                        }}
                        style={{ cursor: "pointer" }}
                    />
                </span>
                <span title={"Modifica utente"}>
                    <Icon color="black" icon="it-pencil" id="editUser" onClick={() => { editExistent(index) }} style={{ cursor: "pointer" }} />
                </span>
            </td>)
        } else if (key === "nome" || key === "cognome") {
            cell = (<td className={key}><span>{row[key]}</span></td>)
        } else if (key === "codicegruppo") {
            cell = (<td className={key}><span>{roleEnum.parse(row[key])}</span></td>)
        }

        return cell
    }

    useEffect(() => {
        if (!contentFetched) {
            showLoading()
            onLoadHandler(setContentFetched, setRedattoriList, session)
        }

    }, [contentFetched])

    return (
        <>
            <div id="redattoriContainer">
                <CustomModal
                    show={show}
                    onHide={handleClose}
                    onConfirm={() => callBackAndClose(handleClose, action, removeRedattore, index)}
                    title={action === operazioniContenuto.INDIETRO ? "Ritorno alla pagina principale" : action} in corso
                    text={getModalMessage(action)}
                    body={action === operazioniContenuto.ANTEPRIMA ? <iframe title={"Preview"} src={"https://preview.wafept.pdt.18-196-167-173.nip.io/"} width={"100%"} height={"100%"} /> : null}
                />
                <div className="row">
                    <div id={"headerText"} className="col-9">
                        <h5>Redattori</h5>
                        <div id={"infoText"}>
                            <i>{headerMsg}</i>
                        </div>
                        {/*tabella lista redattori*/}
                        {!editing && redattoriList ?
                            <div className={"pt-2"}>
                                <TableRicerca
                                    id={"listaRedattori"}
                                    elements={redattoriList}
                                    renderCell={tableCell}
                                    sorting={true}
                                    headers={headers}
                                    addItemButtonFunction={editNew}
                                    pagination={10}
                                />
                            </div> : <Fragment />}
                        {editing &&
                            <div className={'input-box col-9'}>
                                <div>
                                    <div className={'d-flex'}>
                                        <div className={'col-9'}>
                                            <CustomInput id="input1" labelName="Nome*" change={(e) => setNome(e.target.value)} name={nome} valid={true} />
                                        </div>
                                        <div className="col-2"></div>
                                        <div className={'col-1'}>
                                            <span onClick={() => undoEdit()}><Icon color="black" className={'d-inline'} icon={'it-close'} id="undoEdit" /></span>
                                        </div>
                                    </div>
                                    <div className={'col-9'}>
                                        <CustomInput id="input2" labelName="Cognome*" change={(e) => setCognome(e.target.value)} name={cognome} valid={true} />
                                        {editIndex === null ?
                                            <>
                                                <CustomInput id="input3" labelName="Codice Fiscale*" change={(e) => setCodiceFiscale(e.target.value)} name={codiceFiscale} valid={validCF} />
                                                <Select
                                                    idOption={"selectId"} title={"Ruolo*"} array={ruoli} value={selectedRole}
                                                    placeholder={"Seleziona il ruolo dell'utente..."} fieldName={"nome"} fieldValue={"id"}
                                                    change={selectedRoleChangedHandler}
                                                />
                                            </> :
                                            <>
                                                <CustomInput disabled id="input3" labelName="Codice Fiscale*" name={codiceFiscale} valid={true} />
                                                <CustomInput disabled id="input7" labelName="Ruolo*" name={ruoli?.filter(el => el.id === selectedRole)[0]?.nome} valid={true} />
                                            </>
                                        }
                                        <CustomInput id="input6" labelName="Regione" name={areeTerritorialiEnum[session.idAreaTerritoriale]?.nome} disabled valid={true} />
                                        <CustomInput id="input4" labelName="E-mail*" change={(e) => setEmail(e.target.value)} name={email} valid={validEmail} />
                                        <CustomInput id="input5" labelName="Conferma e-mail*" change={(e) => setConfermaEmail(e.target.value)} name={confermaEmail} valid={(confermaEmail === email) ? true : false} invalidMsg={((confermaEmail !== email && confermaEmail) || !confermaEmail) ? "Confermare l'email precedentemente inserita" : null} />
                                        <div id={'uploadUserImage'}>
                                            <div className="mb-3">
                                                <i>* Campi obbligatori</i>
                                            </div>
                                            <Button
                                                disabled={hasError()}
                                                color={'secondary'}
                                                outline={false}
                                                onClick={() => {
                                                    editIndex === null ?
                                                        operationUser(InizializzaRedattore(codiceFiscale, nome, cognome, selectedRole, email, session), setContentFetched, resetInputs, setEditing, operazioniUtente.CREA) :
                                                        operationUser(InizializzaRedattore(codiceFiscale, nome, cognome, selectedRole, email, session), setContentFetched, resetInputs, setEditing, operazioniUtente.MODIFICA)
                                                }}
                                            >
                                                {editIndex === null ? 'Conferma' : 'Salva'}
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}

function getModalMessage(action) {
    switch (action) {
        case operazioniContenuto.INDIETRO:
            return Messages.ERR_TMP_GESTIONE_RUOLI
        case operazioniContenuto.ELIMINAZIONE:
            return Messages.ERR_ELIMINAZIONE_UTENTE
        case operazioniContenuto.ANNULLA_MODIFICHE:
            return Messages.INFO_ANNULLA_MODIFICHE
        case operazioniContenuto.PUBBLICAZIONE:
            return Messages.INFO_PUBBLICAZIONE
        default:
            return Messages.INFO_DEFAULT
    }
}

function callBackAndClose(handleClose, action, removeRedattore, index) {
    if (action === operazioniContenuto.ELIMINAZIONE) {
        removeRedattore(index);
        handleClose();
    }
}

function onLoadHandler(setContentFetched, setRedattoriList, session) {
    Promise.all([UtentiRepository().getUsers({ applicazione: "PDT", listaAttributi: [{ codiceAttributo: "PT-ATERR", valore: session.idAreaTerritoriale }], codiceGruppo: "" })])
        .then(([result]) => {
            if (result && !hasErrorOutcomeAPMS(result)) {
                setRedattoriList(result)
            }
            else setRedattoriList([])
        })
        .finally(() => {
            setContentFetched(true)
            hideLoading()
        })

    // Nuova gestione della getUtenti
    // let profilo = "*;PT-ATERR;" + session.idAreaTerritoriale
    // Promise.all([UtentiRepository().getUtenti({ applicazione: "PDT", size: 0, profilo: profilo })])
    //     .then(([result]) => {
    //         if (result && !hasErrorOutcomeAPMS(result)) {
    //             setRedattoriList(result.elementi)
    //         }
    //     })
    //     .finally(() => {
    //         setContentFetched(true)
    //         hideLoading()
    //     })
}

function InizializzaRedattore(codiceFiscale, nome, cognome, selectedRole, email, session) {

    return Object.freeze({
        "applicazione": "PDT",
        "codiceFiscale": codiceFiscale?.toString(),
        "cognome": cognome?.toString(),
        "listaGruppi": [
            {
                "codicegruppo": selectedRole?.toString(),
                "listaAttributi": [
                    {
                        "codiceAttributo": "PT-ATERR",
                        "valore": session.idAreaTerritoriale
                    }
                ]
            }
        ],
        "mail": email?.toString(),
        "nome": nome?.toString(),
        "struttura": "local",
        "utentelocale": true
    });

}

function operationUser(user, setContentFetched, resetInputs, setEditing, action) {
    if (action === operazioniUtente.CREA) {
        showLoading()
        UtentiRepository().createUser(user)
            .then(result => {
                if (result && !hasErrorOutcomeAPMS(result)) {
                    setContentFetched(false)
                    showSuccess()
                }
            })
            .finally(() => {
                hideLoading()
                resetInputs()
                setEditing(false)
            })
    }
    else if (action === operazioniUtente.MODIFICA) {
        showLoading()
        UtentiRepository().editUser(user)
            .then(result => {
                if (result && !hasErrorOutcomeAPMS(result)) {
                    setContentFetched(false)
                    showSuccess()
                }
            })
            .finally(() => {
                hideLoading()
                resetInputs()
                setEditing(false)
            })
    }
}


export default Redattori;
