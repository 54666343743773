import React, { useContext, useState, useEffect } from 'react';
import { Context, showLoading, hideLoading, showSuccess, showError } from '../../../../hook/globalState/Store';
import useModal from '../../../../hook/useModal';
import { Icon } from 'design-react-kit';
import CustomModal from '../../../CustomModal/CustomModal';
import moment from 'moment';
import Card from '../../../UI/Card/Card';
import Publisher from '../../../UI/Publisher/Publisher';
import { Messages } from '../../../Utils/Messages';
import TitleTemplate from '../../../UI/TitleTemplate/TitleTemplate';
import useInput from '../../../../hook/useInput';
import { getNameFromPath } from '../../../Utils/Utils';
import './DatabaseRicercaCustomApp.css';
import DragDropItems from '../../../UI/DragDropItems/DragDropItems';
import useObjectsArray from '../../../../hook/useObjectsArray';
import DatabaseRicerca from '../../../UI/DatabaseRicerca/DatabaseRicerca';
import LeaRepository from '../../../../Repo/LeaRepository';
import { Redirect, useHistory } from 'react-router-dom';
import { operazioniContenuto, KO_RELEASE, roleEnum } from '../../../Utils/Dataset';
import ContenutiRepository from '../../../../Repo/ContenutiRepository';
import { hasErrorOutcome } from '../../../Interceptor/Interceptor';
import SessioneUtente from '../../../../sessionManager/SessioneUtente';

export default function DatabaseRicercaCustomApp(props) {

    // Sessione
    let session = SessioneUtente.getInstance();

    const [contentFetched, setContentFetched] = useState(false)
    const [nome, setNome, changeNome] = useInput("Prestazioni garantite dal SSN") // Per ora mock
    const [nomeNotEditable, setNomeNotEditable] = useState(true)
    const [sezione, setSezione] = useState(null)
    const [items, setItems, removeItem, addItem, updateItem] = useObjectsArray(null) // Per la lista del DB
    const [itemsDragDrop, setItemsDragDrop, , , updateItemsDragDrop] = useObjectsArray(null) // Per la lista del drag and drop
    const [paginaLea, setPaginaLea] = useState();
    const history = useHistory();

    // Consts
    const headerTitle = "Applicazioni custom"
    const headerMsg = "In questa sezione puoi gestire il contenuto dell'applicazione custom. Ricordati di selezionare 'Pubblica' per permettere la pubblicazione della modifica sul Portale."

    const [state, dispatch] = useContext(Context);

    // Gestione finestre modali
    const [action, setAction] = useState("");
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [redirect, setRedirect] = useState(false);

    // idCategoria
    const [idCategoria, setIdCategoria] = useState(props.id);

    // Gestione pulsanti
    const btnPrimary = [
        {
            id: "salva", color: "secondary", outline: true, title: "Salva modifiche", disabled: false,
            onClickHandler: () => {
                ContenutiRepository().aggiornaContenuto(inizializzaPaginaLea(paginaLea, itemsDragDrop, props.id, session))
                    .then(result => {
                        if (result) {
                            if (!hasErrorOutcome(result)) {
                                setContentFetched(false)
                                showSuccess()
                            } else showError(KO_RELEASE)
                        }
                    }).catch((err) => showError(KO_RELEASE))
            }
        },
    ]
    const btnSecondary = [
        {
            id: "3", color: "secondary", title: "Visualizza anteprima", disabled: false,
            onClickHandler: () => {
                getModal(setAction, operazioniContenuto.ANTEPRIMA, handleShow)
            }
        },
        {
            id: "pubblica", color: "secondary", outline: false, title: "Pubblica", disabled: false,
            onClickHandler: () => {
                getModal(setAction, operazioniContenuto.PUBBLICAZIONE, handleShow)
            }
        }
    ]

    useEffect(() => {
        if (!contentFetched) {
            showLoading()
            onLoadHandler(setContentFetched, setItems, props.id, setPaginaLea, setItemsDragDrop, setSezione)
        }
    }, [contentFetched])

    useEffect(() => {
        showLoading()
        onLoadHandler(setContentFetched, setItems, props.id, setPaginaLea, setItemsDragDrop, setSezione)
    }, [props.id])

    return (
        contentFetched && items?.sottoCategorie?.length === 0 ?
            <Redirect to={"/applicazioni-custom/prestazioni-garantite-ssn/" + props.match.params.lingua + "/" + props.tipoLea + '/' + props.id + "/table"} /> :
            <>
                <div id="databaseRicercaCustomApp">
                    <CustomModal
                        show={show}
                        onHide={handleClose}
                        onConfirm={() => callBackAndClose(handleClose, action, inizializzaPaginaLea(paginaLea, itemsDragDrop, props.id, session), history, setContentFetched)}
                        title={action === operazioniContenuto.INDIETRO ? "Indietro" : action} in corso
                        text={getModalMessage(action)}
                        body={action === operazioniContenuto.ANTEPRIMA ? <iframe title={"Preview"} src={"https://preview.wafept.pdt.18-196-167-173.nip.io/"} width={"100%"} height={"100%"} /> : null}
                    />
                    <div className="row">
                        <div className="col-6">
                            <h5>{headerTitle}</h5>
                        </div>
                        <div id={"goBack"} className="col-6">
                            <a id={"buttonBack"} onClick={() => getModal(setAction, operazioniContenuto.INDIETRO, handleShow)}> <Icon className="right" color="black" icon="it-arrow-left" id="arrow" />Indietro</a>
                        </div>
                    </div>
                    <div className="row">
                        <div id={"headerText"} className="col-9">
                            <div id={"infoText"}>
                                <TitleTemplate
                                    name={nome}
                                    onChangeName={changeNome}
                                    nameNotEditable={nomeNotEditable}
                                    setNameNotEditable={setNomeNotEditable}
                                />
                                <i>{headerMsg}</i>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div id={"headerText"} className={'col-9'}>
                            <h6>{sezione}</h6>
                            {(session.ruoliUtente !== roleEnum.AUTORE && session.ruoliUtente !== roleEnum.COLLABORATORE) &&
                                <DragDropItems
                                    items={itemsDragDrop ? itemsDragDrop : null}
                                    setItems={setItemsDragDrop}
                                    updateItem={updateItemsDragDrop}
                                    label={"MODIFICA"}
                                    notLoadOthers
                                    fieldKey={"idSottocategoria"}
                                />
                            }
                            <DatabaseRicerca
                                label={"DATABASE RICERCA"}
                                database={items ? items.sottoCategorie : ""}
                                onlyDb={true}
                                pathname={"/applicazioni-custom/prestazioni-garantite-ssn/" + props.match.params.lingua + "/" + props.tipoLea}
                                onClick={() => {
                                    setItemsDragDrop(null)
                                    setContentFetched(false)
                                }}
                                id={props.id}
                            />
                        </div>
                    </div>
                </div>
            </>
    )
}

function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}

function callBackAndClose(handleClose, action, inizializzaPaginaLea, history, setContentFetched) {
    if (action === operazioniContenuto.INDIETRO) {
        history.goBack()
        handleClose();
    }

    if (action === operazioniContenuto.PUBBLICAZIONE) {

        ContenutiRepository().pubblicaContenuto(inizializzaPaginaLea)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    showSuccess()
                    setContentFetched(false)
                } else showError(KO_RELEASE)
            }).catch((err) => showError(KO_RELEASE))
        handleClose();

    }
}

function getModalMessage(action) {
    switch (action) {
        case operazioniContenuto.INDIETRO:
            return Messages.ERR_INDIETRO_LEA
        default:
            return Messages.INFO_DEFAULT
    }
}

function onLoadHandler(setContentFetched, setItems, idCategoria, setPaginaLea, setItemsDragDrop, setSezione) {

    Promise.all([LeaRepository().getCategoria(idCategoria), ContenutiRepository().getSezione({ tipologia: "APPLICAZIONE_LEA", dontUseAreaTerritorialeFilter: true })])
        .then(([result, result2]) => {

            if (result) {
                setSezione(result.descrizione)

                setItems({
                    codice: result.codice,
                    etichetta: result.descrizione,
                    id: result.id,
                    sottoCategorie: convertSottoCategorie(result?.sottoCategorie),
                    visibile: result.visibile
                })
            }

            if (result2 && result2.length) {
                setPaginaLea(result2[0])
                result2 = convertSottoCategorieDb(result2, idCategoria)

                setItemsDragDrop(result2[0].sottoCategorie)
            }
        })
        .catch(err => console.log(err))
        .then(() => {
            hideLoading()
            setContentFetched(true)
        })
}

function convertSottoCategorieDb(array, idCategoria) {
    const newArr = array[0]?.categorie?.filter(el => el.idCategoria == idCategoria);
    return newArr;
}

function convertSottoCategorie(array) {
    return array?.map((el, i) => ({
        codice: el.codice,
        etichetta: el.descrizione,
        id: el.id,
        sottoCategorie: el.sottoCategorie?.length > 0 ? convertSottoCategorie(el.sottoCategorie) : el.sottoCategorie,
        visibile: el.visibile
    }))
}

function inizializzaPaginaLea(paginaLea, itemsDragDrop, idCategoria, session) {

    let index = paginaLea?.categorie?.indexOf(paginaLea.categorie?.filter(el => el.idCategoria === idCategoria)[0]);

    if (paginaLea.categorie[index])
        paginaLea.categorie[index].sottoCategorie = itemsDragDrop


    return Object.freeze({
        areaTerritoriale: session.idAreaTerritoriale,
        categorie: paginaLea.categorie,
        contenutiAltraLingua: paginaLea.contenutiAltraLingua,
        dataCancellazione: paginaLea.dataCancellazione,
        dataInserimento: paginaLea.dataInserimento,
        dataModifica: paginaLea.dataModifica,
        dataPubblicazione: paginaLea.dataPubblicazione,
        dataPubblicazioneProgrammata: paginaLea.dataPubblicazioneProgrammata,
        dataScadenzaProgrammata: paginaLea.dataScadenzaProgrammata,
        dataSospensione: paginaLea.dataSospensione,
        descrizione: paginaLea.descrizione,
        facebook: paginaLea.facebook,
        id: paginaLea.id,
        idAgnosticoLingua: paginaLea.idAgnosticoLingua,
        idPadre: paginaLea.idPadre,
        idUtenteInserimento: paginaLea.idUtenteInserimento,
        idVersioneLavoro: paginaLea.idVersioneLavoro,
        indice: paginaLea.indice,
        lingua: paginaLea.lingua,
        nome: paginaLea.nome,
        path: paginaLea.path,
        sottotitolo: paginaLea.sottotitolo,
        stato: paginaLea.stato,
        template: paginaLea.template,
        testo: paginaLea.testo,
        tipologia: paginaLea.tipologia,
        titolo: paginaLea.titolo,
        twitter: paginaLea.twitter,
        utenteCancellazione: paginaLea.utenteCancellazione,
        utenteInserimento: paginaLea.utenteInserimento,
        utenteModifica: paginaLea.utenteModifica,
        utentePubblicazione: paginaLea.utentePubblicazione,
        utenteSospensione: paginaLea.utenteSospensione,
        validazione: paginaLea.validazione,
        versione: paginaLea.versione
    })
}
