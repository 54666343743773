import authConfig from '../config/authConfig'
import { auth_debug_log_enable, globals } from '../config/config'
import AuthenticationManager from './AuthenticationManager';
import { parseJwt } from "./../components/Utils/JwtHelper"

export default class SessioneUtente {

    idSessione = null;
    dataScadenzaToken = null;
    idUtente = null;
    nomeUtente = null;
    cognomeUtente = null;
    ruoliUtente = null;
    //stateCode = null;
    //codiceStruttura = null;
    tokenExpirationTime = null;
    idApplicazione = null;
    idToken = null;
    sessionTimeOut = null;
    sessionExpirationTime = null;
    sessionCreatedTime = null;
    sessionLatestUpdate = null;
    idAreaTerritoriale = null;
    codiceFiscale = null;
    scadenzaPassword = null;
    accessToken = null;
    ruoloRealeUtente = null;

    static instance = null;

    constructor(authParams) {
        this.sessionInit(authParams)
    }

    static initEmptyInstance() {
        this.instance = new SessioneUtente();
        return this.instance;
    }

    static getInstance(authParams) {
        if (authParams) {
            auth_debug_log_enable && console.log("Sessione Utente: inizializzazione authParams");
            this.instance = new SessioneUtente(authParams);
        }
        return this.instance;
    }

    static editInstance(field, value) {
        if (this.instance != null) {
            this.instance[field] = value;
        }
    }

    static destroySession() {
        this.instance = null;
        auth_debug_log_enable && console.log('[DEBUG] Session successfully cleared.')
    }

    sessionInit = (authParams) => {
        //  Init session parameter
        this.SessionCreatedTime = Date.now()
        this.SessionLatestUpdate = this.SessionCreatedTime
        this.SessionTimeOut = authConfig['SessionTimeOut']
        this.SessionExpirationTime = authConfig['SessionExpirationTime']

        //  Init auth parameter
        this.idSessione = authParams?.sessionId
        this.idUtente = authParams?.userid
        this.idApplicazione = globals['app-id-applicazione']
        auth_debug_log_enable && console.log('[DEBUG] Sessione istanziata ');
    }

    static existsSession() {
        return SessioneUtente.getInstance() != null
    }

    static isSessionExpired() {
        auth_debug_log_enable && console.log('[DEBUG] Checking isSessionExpired. Session:')
        auth_debug_log_enable && console.log(SessioneUtente.getInstance())

        let sessionTimeOut = SessioneUtente.getInstance().SessionTimeOut
        let sessionLatestUpdate = SessioneUtente.getInstance().SessionLatestUpdate
        let sessionCreatedTime = SessioneUtente.getInstance().SessionCreatedTime
        let sessionExpirationTime = SessioneUtente.getInstance().SessionExpirationTime

        let timestamp = Date.now()

        // Minutes from last operation
        let minutesFromLastOp = (timestamp - sessionLatestUpdate) / 1000 / 60

        // Hours from creation
        let hoursFromCreation = (timestamp - sessionCreatedTime) / 1000 / 3600

        // Session expired => destroy session and return to login
        if ((minutesFromLastOp >= sessionTimeOut) || (hoursFromCreation >= sessionExpirationTime)) {
            auth_debug_log_enable && console.log("SESSIONE EXPIRED: ");
            auth_debug_log_enable && console.log("minutesFromLastOp: " + minutesFromLastOp);
            auth_debug_log_enable && console.log("sessionTimeOut: " + sessionTimeOut);
            auth_debug_log_enable && console.log("hoursFromCreation: " + hoursFromCreation);
            auth_debug_log_enable && console.log("sessionExpirationTime: " + sessionExpirationTime);
            this.destroySession();
            return true
        }
        else {
            auth_debug_log_enable && console.log("[DEBUG] Session NOT expired.")
        }
    }

}
