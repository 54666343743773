import React from 'react';
import './PostFooter.css';
import PostFooterLink from './PostFooterLink/PostFooterLink';

function PostFooter() {
    return (
        <div className="PostFooter">
            {/* <h5 className="sr-only">Sezione Link Utili</h5>
            <PostFooterLink name={'Privacy-Cookies'}> Privacy-Cookies |</PostFooterLink>
            <PostFooterLink name={'Note legali'}> Note legali |</PostFooterLink>
            <PostFooterLink name={'Contatti'}> Contatti |</PostFooterLink>
            <PostFooterLink name={'Accessibilità'}> Accessibilità |</PostFooterLink>
            <PostFooterLink name={'Mappa del sito'}> Mappa del sito |</PostFooterLink>
            <PostFooterLink name={'La Redazione'} id="lastOne"> La Redazione </PostFooterLink> */}
        </div>
    )
}

export default PostFooter;