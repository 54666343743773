import { Icon } from 'design-react-kit';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { hideLoading, showLoading } from '../../../../hook/globalState/Store';
import useModal from '../../../../hook/useModal';
import useObjectsArray from '../../../../hook/useObjectsArray';
import StruttureRepository from '../../../../Repo/StruttureRepository';
import SessioneUtente from '../../../../sessionManager/SessioneUtente';
import StrutturaCovidBox from '../../../UI/StrutturaCovidBox/StrutturaCovidBox';
import { operazioniAssociazioni, operazioniStruttureCovid } from '../../../Utils/Dataset';
import './StruttureCovid.css'

export default function StruttureCovid() {

    // Session
    const session = SessioneUtente.getInstance();

    // Consts
    const headerMsg = "Qui puoi visualizzare i siti covid-19. Se non inserisci alcun contenuto, questo non sarà visibile dal cittadino nelle sezioni specifiche."

    // States
    const [struttureCovid, setStruttureCovid, removeStrutturaCovid, addStrutturaCovid, updateStrutturaCovid] = useObjectsArray([])
    const [removeStrutturaCovidIndex, setRemoveStrutturaCovidIndex] = useState()
    const [contentFetched, setContentFetched] = useState(false);

    // Gestione finestre modali
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [action, setAction] = useState("");

    const handleRemoveStrutturaCovid = (index) => {
        setRemoveStrutturaCovidIndex(index)
        getModal(operazioniStruttureCovid.ELIMINA)
    }
    const getModal = (azione) => {
        setAction(azione);
        handleShow();
    }

    useEffect(() => {
        if (!contentFetched) {
            showLoading()
            onLoadHandler(setContentFetched, setStruttureCovid, session)
        }
    }, [contentFetched])

    return (
        <div className={"strutture-covid-container"}>
            <div className="row">
                <div className="col-9">
                    <h5>Siti covid-19</h5>
                    <i>{headerMsg}</i>
                </div>
                <div className={'col-3 text-right '}>
                    <Link id="back" to="/">
                        <Icon className="left" color="black" icon="it-arrow-left" id="arrow" />Indietro
                                </Link>
                </div>
            </div>
            <div className="row">
                <div className="col-9">
                    <div>
                        {struttureCovid && struttureCovid.length ? struttureCovid.map((el, index) => <div key={el.cap + "-" + index} className={"associazione-box"} ><StrutturaCovidBox el={el} removeSelf={() => handleRemoveStrutturaCovid(index)} /></div>)
                            : <div className="mt-4"><span>Non sono presenti siti covid-19.</span></div>}
                    </div>
                    <div className="mt-4"><Link className={"add-struttura-covid"} to={'/aggiungi-sito-covid'}><Icon className="left" color="black" icon="it-plus-circle" id="arrowRight" /><span className={'ml-2 add-new-associazione'}>Aggiungi nuovo</span></Link></div>
                </div>
            </div>
        </div>
    )
}

function onLoadHandler(setContentFetched, setStruttureCovid, session) {
    StruttureRepository().getAllStruttureCovid(session.idAreaTerritoriale)
        .then(result => setStruttureCovid(result?.elementi))
        .catch(err => err)
        .finally(() => {
            setContentFetched(true)
            hideLoading()
        })
}