import { Icon } from 'design-react-kit';
import React, { useContext, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Context, hideLoading, scrollToTop, showError, showLoading, showSuccess } from '../../../../hook/globalState/Store';
import useFileHandler from '../../../../hook/useFileHandler';
import useInput from '../../../../hook/useInput';
import useModal from '../../../../hook/useModal';
import StruttureRepository from '../../../../Repo/StruttureRepository';
import { getMessaggioOperazione } from '../../../../Repo/Utils';
import SessioneUtente from '../../../../sessionManager/SessioneUtente';
import CustomModal from '../../../CustomModal/CustomModal';
import { hasErrorOutcome } from '../../../Interceptor/Interceptor';
import SimpleFileLoader from '../../../SimpleFileLoader/SimpleFileLoader';
import Card from '../../../UI/Card/Card';
import validate from '../../../UI/FieldValidation/FieldValidation';
import Publisher from '../../../UI/Publisher/Publisher';
import { KO_OPERATION, operazioniAssociazioni, operazioniContenuto } from '../../../Utils/Dataset';
import { Messages } from '../../../Utils/Messages';
import { uploadPdf } from '../../../Utils/Utils';
import './TempiAttesa.css';

export default function TempiAttesa() {

    // Session
    const session = SessioneUtente.getInstance();

    // Consts
    const headerMsg = "Qui puoi caricare il file con informazioni sui tempi d'attesa."

    // States
    const [document, setDocument, documentChangedHandler] = useInput({ idAreaTerritoriale: "", descrizione: "", path: "" });
    const [currentDocument, setCurrentDocument, documentMetadata, setDocumentMetadata, onChangeCurrentDocument] = useFileHandler();
    const [path, setPath, pathChangedHandler, validatePath] = useInput("");
    const [descrizione, setDescrizione, descrizioneChangedHandler, validateDescrizione] = useInput("", validate);
    const [contentFetched, setContentFetched] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [state, dispatch] = useContext(Context);

    // Pulsanti
    const btnPrimary = [
        {
            id: operazioniContenuto.SALVA_MODIFICHE.toLowerCase(), color: "secondary", outline: true, title: "Salva modifiche", disabled: (path && validateDescrizione) ? false : true,
            onClickHandler: () => {
                StruttureRepository().editTempoAttesa({ idAreaTerritoriale: SessioneUtente.getInstance().idAreaTerritoriale, descrizione: descrizione, path: path })
                    .then((result) => {
                        if (result) {
                            if (!hasErrorOutcome(result)) {
                                scrollToTop()
                                showSuccess()
                                setContentFetched(false)
                            } else showError(getMessaggioOperazione(result?.esito?.messaggi[0], { idAreaTerritoriale: document.idAreaTerritoriale, descrizione: descrizione, path: path }))
                        }
                    })
            }
        }
    ]
    const btnSecondary = [
        {
            id: operazioniContenuto.ANNULLA_MODIFICHE.toLowerCase(), color: "secondary", outline: false, disabled: false, title: operazioniContenuto.ANNULLA_MODIFICHE,
            onClickHandler: () => { getModal(operazioniContenuto.ANNULLA_MODIFICHE) }
        },
        {
            id: "6", color: "outline-primary", title: "Elimina", disabled: false,
            onClickHandler: () => {
                getModal(operazioniContenuto.ELIMINAZIONE)
            }
        }
    ]

    //Gestione finestre modali
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [action, setAction] = useState("");

    const getModal = (azione) => {
        setAction(azione);
        handleShow();
    }

    const callBackAndClose = () => {
        if (action === operazioniContenuto.ANNULLA_MODIFICHE) {
            // history.go(0)
            setContentFetched(false)
            handleClose();
        }
        else if (action === operazioniContenuto.ELIMINAZIONE) {
            StruttureRepository().deleteTempoAttesa()
                .then(result => {
                    if (!hasErrorOutcome(result)) {
                        setRedirect(true)
                        dispatch({ type: 'RELOAD_MENU', payload: { reloadMenu: true } });
                        showSuccess()
                    }
                    else showError(getMessaggioOperazione(result?.esito?.messaggi[0], { idAreaTerritoriale: document.idAreaTerritoriale, descrizione: descrizione, path: path }))
                })
            handleClose();
        }
    }

    const getModalTitle = (action) => {
        switch (action) {
            case operazioniContenuto.ANNULLA_MODIFICHE:
                return operazioniContenuto.ANNULLA_MODIFICHE
            case operazioniContenuto.ELIMINAZIONE:
                return operazioniContenuto.ELIMINAZIONE
            default:
                return operazioniContenuto.ANNULLA_MODIFICHE
        }
    }

    const getModalMessage = () => {
        switch (action) {
            case operazioniContenuto.ANNULLA_MODIFICHE:
                return Messages.INFO_ANNULLA_MODIFICHE
            case operazioniContenuto.ELIMINAZIONE:
                return Messages.ERR_02_01_0A_0A
            default:
                return Messages.INFO_ANNULLA_MODIFICHE
        }
    }

    useEffect(() => {
        if (!contentFetched) {
            showLoading()
            onLoadHandler(setContentFetched, setDocument, session)
        }
        else {
            setDescrizione(document?.descrizione)
            setPath(document?.path)
        }

    }, [contentFetched])

    return (
        redirect ? <Redirect to="/" /> :
            <div className={"tempi-attesa-container"}>
                <CustomModal
                    show={show}
                    onHide={handleClose}
                    onConfirm={() => callBackAndClose()}
                    title={getModalTitle(action)}
                    text={getModalMessage()}
                />
                <div className="row">
                    <div className="col-6">
                        <h5>Tempi d'attesa - PRGLA</h5>
                    </div>
                    <div className={'col-6 text-right '}>
                        <Link id="back" to="/">
                            <Icon className="left" color="black" icon="it-arrow-left" id="arrow" />Indietro
                                </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">
                        <i>{headerMsg}</i>
                        <div className="section-container mt-4">
                            <div id={"buttonLoad"}>
                                <SimpleFileLoader
                                    idLoad={'uploadDocument'}
                                    loaderLayout={
                                        <label style={path ? { marginLeft: "2em" } : { marginLeft: "0em" }} id="btnLoad" htmlFor={"uploadDocument"}>
                                            {path === null ? "Carica" : "Cambia"}
                                        </label>
                                    }
                                    change={(e) => onChangeCurrentDocument(e, (file, fileMetadata) => uploadPdf(file, setPath))}
                                    value={path}
                                />
                            </div>
                            <div className="descrizione-attesa">
                                <div className="row">
                                    <div className="col-2">
                                        <label htmlFor={"text-1"}>Descrizione*</label>
                                    </div>
                                    <div className="col-9">
                                        <input id={"text-1"} type="text" value={descrizione} onChange={descrizioneChangedHandler} />
                                        {!validateDescrizione && <i className="danger">Il campo Descrizione è obbligatorio</i>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 cards">
                        <Card title={operazioniContenuto.MODIFICA}>
                            <Publisher
                                arrayPrimary={btnPrimary}
                                arraySecondary={btnSecondary}
                            />
                        </Card>
                    </div>
                </div>
            </div >
    )
}

function onLoadHandler(setContentFetched, setDocument, session) {
    StruttureRepository().getTempoAttesa()
        .then(result => {
            if (!(result.status === 404))
                setDocument(result)
        })
        .finally(() => {
            setContentFetched(true)
            hideLoading()
        })
}