import { Button, Icon } from 'design-react-kit';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Context, hideLoading, showError, showLoading, showSuccess } from '../../../hook/globalState/Store';
import useDocumentTitle from '../../../hook/useDocumentTitle';
import useInput from '../../../hook/useInput';
import useModal from '../../../hook/useModal';
import useObjectsArray from '../../../hook/useObjectsArray';
import StruttureRepository from '../../../Repo/StruttureRepository';
import UtentiRepository from '../../../Repo/UtentiRepository';
import { getMessaggioOperazione } from '../../../Repo/Utils';
import SessioneUtente from '../../../sessionManager/SessioneUtente';
import CustomModal from '../../CustomModal/CustomModal';
import { hasErrorOutcome, hasErrorOutcomeAPMS } from '../../Interceptor/Interceptor';
import CustomInput from '../../UI/CustomInput/CustomInput';
import { validateCF, validateEmail } from '../../UI/FieldValidation/FieldValidation';
import { alertLevels } from '../../UI/InPageAlert/InPageAlert';
import Select from '../../UI/Select/Select';
import UserBadge from '../../UI/UserBadge/UserBadge';
import { APPLICATION_NAME, areeTerritorialiEnum, KO_OPERATION, KO_RELEASE, OK_OPERATION, OK_RELEASE, operazioniContenuto, operazioniUtente, roleEnum, typeOrder } from '../../Utils/Dataset';
import TableRicerca from "../../UI/TableRicerca/TableRicerca";
import { Messages } from '../../Utils/Messages';
import './GestioneRuoli.css';

const resetMfaAction = "Reset MFA";
const resettaMfaTooltip = "Resetta la configurazione corrente della Multi Factor Authentication per l'utente selezionato";

export default function GestioneRuoli(props) {

    var session = SessioneUtente.getInstance()
    useDocumentTitle(props.title);

    //Consts
    const headerMsg = "Qui puoi gestire i ruoli e i dati degli utenti."
    const ruoli = [
        { id: roleEnum.AMMINISTRATORE, nome: "Amministratore" },
        { id: roleEnum.EDITORE, nome: "Editore - Redazione centrale" },
        { id: roleEnum.AUTORE, nome: "Autore - Referente regionale" },
        { id: roleEnum.COLLABORATORE, nome: "Collaboratore - Utente regionale" }
    ]
    const currentUserIsAdmin = SessioneUtente.getInstance().ruoliUtente?.toUpperCase() === roleEnum.AMMINISTRATORE;

    const [state, dispatch] = useContext(Context)

    //State
    const [contentFetched, setContentFetched] = useState(false)
    const [areeTerritoriali, setAreeTerritoriali] = useState()
    const [areaTerritoriale, setAreaTerritoriale, areaTerritorialeChangedHandler] = useInput('')
    const [selectedRole, setSelectedRole, selectedRoleChangedHandler] = useInput('')
    const [nome, setNome] = useState('')
    const [cognome, setCognome] = useState('')
    const [codiceFiscale, setCodiceFiscale, , validCF] = useInput('', validateCF)
    const [email, setEmail, , validEmail] = useInput('', validateEmail)
    const [confermaEmail, setConfermaEmail] = useInput('')
    const [redirect, setRedirect] = useState(false)
    const [index, setIndex] = useState(null)
    const [filterValue, setFilterValue] = useState("")
    const [utentiFiltratiPerRegione, setUtentiFiltratiPerRegione] = useState(null)
    const [headers] = useState([
        { key: "nome", name: "Nome", sortDirection: typeOrder.NONE },
        { key: "cognome", name: "Cognome", sortDirection: typeOrder.NONE },
        { key: "codicegruppo", name: "Ruolo", sortDirection: typeOrder.NONE },
        { key: "modifica", name: "Modifica" }]);

    //Gestione finestre modali
    const [show, setShow, handleClose, handleShow] = useModal(false);
    const [action, setAction] = useState("");

    //Gestione utenti
    const [editing, setEditing] = useState(false)
    const [editIndex, setEditIndex] = useState(null)

    const [utentiList, setUtentiList] = useState([])

    const resetInputs = () => {
        setNome('')
        setCognome('')
        setCodiceFiscale('')
        setSelectedRole('')
        setAreaTerritoriale('')
        setEmail('')
        setConfermaEmail('')
    }
    const undoEdit = () => {
        resetInputs()
        setEditing(false)
    }

    const removeUtente = (i) => {
        showLoading()
        UtentiRepository().deleteUser(InizializzaUtente(utentiList[i]?.codiceFiscale, utentiList[i]?.nome, utentiList[i]?.cognome, utentiList[i]?.codicegruppo, utentiList[i]?.listaAttributi[0]?.valore, utentiList[i]?.mail))
            .then(result => {
                if (result) {
                    setContentFetched(false)
                    showSuccess()
                }
            })
            .finally(() => {
                resetFilter()
                hideLoading()
            })
    }
    const editNew = () => {
        resetInputs()
        setEditIndex(null)
        setEditing(true)
    }
    const editExistent = (i) => {

        setNome(utentiList[i].nome)
        setCognome(utentiList[i].cognome)
        setCodiceFiscale(utentiList[i].codiceFiscale)
        setSelectedRole(utentiList[i]?.codicegruppo)
        setAreaTerritoriale(utentiList[i]?.listaAttributi[0]?.valore)
        setEmail(utentiList[i].mail)
        setConfermaEmail(utentiList[i].mail)

        setEditing(true)
        setEditIndex(i)
    }

    const hasError = () => {

        return cognome?.trim().length === 0 ||
            nome?.trim().length === 0 ||
            (codiceFiscale?.trim().length === 0) ||
            !validCF ||
            (email?.trim().length === 0) ||
            !validEmail ||
            (confermaEmail !== email) ||
            selectedRole?.trim().length === 0 ||
            ((selectedRole && selectedRole.toUpperCase() !== roleEnum.EDITORE && selectedRole.toUpperCase() !== roleEnum.AMMINISTRATORE) && areaTerritoriale?.trim().length === 0)
    }

    useEffect(() => {
        if (!contentFetched) {
            showLoading()
            onLoadHandler(setContentFetched, setAreeTerritoriali, setUtentiList, filterValue)
        }
        else setUtentiFiltratiPerRegione(utentiList)
    }, [contentFetched])

    // Per la nuova gestione della get utenti
    // useEffect(() => {
    //     setContentFetched(false)
    // }, [filterValue])

    const resetMfaUtenteSelezionato = () => {
        UtentiRepository().rimozioneMFAUtente(codiceFiscale, APPLICATION_NAME)
            .then(result => {
                if (!hasErrorOutcome(result)) {
                    setAction(null);
                    dispatch({ type: 'SHOW_ALERT', payload: { alertLevel: alertLevels.SUCCESS, alertMsg: OK_OPERATION } });
                } else showError(getMessaggioOperazione(result?.esito?.messaggi[0], result?.esito))
            }).catch((err) => showError(KO_OPERATION))
            .finally(hideLoading)
    }

    function callBackAndClose(handleClose, action, setRedirect, removeUtente, index) {
        if (action === operazioniContenuto.INDIETRO) {
            handleClose();
            setRedirect(true);
        }
        else if (action === operazioniContenuto.ELIMINAZIONE) {
            removeUtente(index);
            handleClose();
        }
        else if (action === resetMfaAction) {
            resetMfaUtenteSelezionato();
            handleClose();
        }
    }

    // Filtro
    const updateFilter = event => {
        setFilterValue(event.target.value)
        let tmpFiltered = utentiList?.filter(el => el?.listaAttributi?.filter(el => el.codiceAttributo === "PT-ATERR")[0]?.valore?.toLowerCase()?.trim() === event.target.value.toLowerCase().trim())
        setUtentiFiltratiPerRegione(tmpFiltered)
    }
    const resetFilter = () => {
        updateFilter({ target: { value: "" } })
        setUtentiFiltratiPerRegione(utentiList)
    }

    const filterUtentiPerRegione = (
        <div className={"filter-elements"}>
            <div className="row">
                <Select title={"Seleziona la regione per cui filtrare gli utenti"} placeholder={"Ricerca utente per regione..."} array={areeTerritoriali} fieldName={"nome"} fieldValue={"id"} value={filterValue} change={updateFilter} />
                <div className="reset-filter" title={"Resetta filtro"}>
                    <Icon icon={"it-close"} onClick={() => resetFilter()} />
                </div>
            </div>
        </div>
    )

    const tableCell = (key, row, id) => {
        let cell;
        let index = utentiList.indexOf(row);
        if (key === "modifica") {
            cell = (<td className={key}>
                <span title={"Elimina utente"}>
                    <Icon
                        color="black" icon="it-delete" id="deleteUser" size="lg"
                        onClick={() => {
                            setIndex(index)
                            getModal(setAction, operazioniContenuto.ELIMINAZIONE, handleShow);
                        }}
                    />
                </span>
                <span title={"Modifica utente"}>
                    <Icon color="black" icon="it-pencil" id="editUser" onClick={() => {
                        editExistent(index)
                    }} />
                </span>
            </td>)
        } else if (key === "nome" || key === "cognome") {
            cell = (<td className={key}><span>{row[key]}</span></td>)
        } else if (key === "codicegruppo") {
            cell = (<td className={key}><span>{roleEnum.parse(row[key])}</span></td>)
        }

        return cell
    }

    return (
        <>
            {redirect ? <Redirect to={"/home"} /> :
                <div id="gestioneRuoliContainer">
                    <div className="row">
                        <div id="headerText" className="col-6">
                            <h5>Redattori</h5>
                            <div id={"infoText"}>
                                <i>{headerMsg}</i>
                            </div>
                        </div>
                        <div id={"goBackMenu"} className="col-6">
                            <a id={"buttonBack"} onClick={() => getModal(setAction, operazioniContenuto.INDIETRO, handleShow)}> <Icon className="right" color="black" icon="it-arrow-left" id="arrow" />Indietro</a>
                        </div>
                    </div>
                    <CustomModal
                        show={show}
                        onHide={handleClose}
                        onConfirm={() => callBackAndClose(handleClose, action, setRedirect, removeUtente, index)}
                        title={action === operazioniContenuto.INDIETRO ? "Ritorno alla pagina principale" : action} in corso
                        text={getModalMessage(action)}
                        body={action === operazioniContenuto.ANTEPRIMA ? <iframe title={"Preview"} src={"https://preview.wafept.pdt.18-196-167-173.nip.io/"} width={"100%"} height={"100%"} /> : null}
                    />
                    <div className="row">
                        <div id={"headerText"} className="col-8">
                            <div className={"d-block"} id={"actions"}>
                                {filterUtentiPerRegione}
                                {!editing && utentiFiltratiPerRegione ?
                                    <div className={"pt-2"}>
                                        <TableRicerca
                                            id={"listaRedattori"}
                                            elements={utentiFiltratiPerRegione}
                                            renderCell={tableCell}
                                            sorting={true}
                                            headers={headers}
                                            addItemButtonFunction={editNew}
                                            pagination={10}
                                            filterValue={filterValue}
                                        />
                                    </div> : <></>}
                            </div>
                            {editing &&
                                <div className={'input-box col-9'}>
                                    <div>
                                        <div className={'d-flex'}>
                                            <div className={'col-9'}>
                                                <CustomInput id="input1" labelName="Nome*" change={(e) => setNome(e.target.value)} name={nome} valid={true} />
                                            </div>
                                            <div className={'col-2'}></div>
                                            <div className={'col-1'}>
                                                <span onClick={() => undoEdit()}><Icon color="black" className={'d-inline'} icon={'it-close'} id="undoEdit" /></span>
                                            </div>
                                        </div>
                                        <div className={'col-9'}>
                                            <CustomInput id="input2" labelName="Cognome*" change={(e) => setCognome(e.target.value)} name={cognome} valid={true} />
                                            {editIndex === null ?
                                                <>
                                                    <CustomInput id="input3" labelName="Codice Fiscale*" change={(e) => setCodiceFiscale(e.target.value)} name={codiceFiscale} valid={validCF} invalidMsg={!validCF ? "Inserire un codice fiscale valido" : null} />
                                                    <Select
                                                        idOption={"selectId"} title={"Ruolo*"} array={ruoli} value={selectedRole}
                                                        placeholder={"Seleziona il ruolo dell'utente..."} fieldName={"nome"} fieldValue={"id"}
                                                        change={selectedRoleChangedHandler}
                                                    />
                                                </> :
                                                <>
                                                    <CustomInput id="input3" disabled labelName="Codice Fiscale*" name={codiceFiscale} valid={true} />
                                                    <CustomInput id="input7" labelName="Ruolo*" name={ruoli?.filter(el => el.id === selectedRole)[0]?.nome} disabled valid={true} />
                                                </>
                                            }
                                            {(selectedRole && (selectedRole !== roleEnum.EDITORE) && (selectedRole !== roleEnum.AMMINISTRATORE)) &&
                                                <>
                                                    {editIndex === null ?
                                                        <Select
                                                            idOption={"selectId"} title={"Regione*"} array={areeTerritoriali} value={areaTerritoriale}
                                                            placeholder={"Seleziona la regione dell'utente..."} fieldName={"nome"} fieldValue={"id"}
                                                            change={areaTerritorialeChangedHandler}
                                                        /> :
                                                        <CustomInput id="input6" labelName="Regione*" name={areeTerritorialiEnum[areaTerritoriale]?.nome} disabled valid={true} />
                                                    }
                                                </>
                                            }
                                            <CustomInput id="input4" labelName="E-mail*" change={(e) => setEmail(e.target.value)} name={email} valid={validEmail} invalidMsg={!validEmail ? "Inserire un'email valida" : null} />
                                            <CustomInput id="input5" labelName="Conferma e-mail*" change={(e) => setConfermaEmail(e.target.value)} name={confermaEmail} valid={(confermaEmail === email) ? true : false} invalidMsg={((confermaEmail !== email && confermaEmail) || !confermaEmail) ? "Confermare l'email precedentemente inserita" : null} />
                                            <div className="campi-obbligatori">
                                                <span><i>* Campi obbligatori</i></span>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Button
                                                            disabled={hasError()}
                                                            color={'secondary'}
                                                            outline={false}
                                                            onClick={() => {
                                                                editIndex === null ?
                                                                    operationUser(InizializzaUtente(codiceFiscale, nome, cognome, selectedRole, areaTerritoriale, email, ruoli), setContentFetched, resetInputs, setEditing, operazioniUtente.CREA, resetFilter) :
                                                                    operationUser(InizializzaUtente(codiceFiscale, nome, cognome, selectedRole, areaTerritoriale, email, ruoli), setContentFetched, resetInputs, setEditing, operazioniUtente.MODIFICA, resetFilter)
                                                            }}
                                                        >
                                                            {editIndex === null ? 'Conferma' : 'Salva'}
                                                        </Button>
                                                    </div>
                                                    <div className="col-6">
                                                        {currentUserIsAdmin && editIndex ? <Button title={resettaMfaTooltip} onClick={() => { getModal(setAction, resetMfaAction, handleShow); }}>Resetta MFA</Button> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

function getModalMessage(action) {
    switch (action) {
        case resetMfaAction:
            return Messages.INFO_RESET_MFA
        case operazioniContenuto.INDIETRO:
            return Messages.ERR_TMP_GESTIONE_RUOLI
        case operazioniContenuto.ELIMINAZIONE:
            return Messages.ERR_ELIMINAZIONE_UTENTE
        case operazioniContenuto.ANNULLA_MODIFICHE:
            return Messages.INFO_ANNULLA_MODIFICHE
        case operazioniContenuto.PUBBLICAZIONE:
            return Messages.INFO_PUBBLICAZIONE
        default:
            return Messages.INFO_DEFAULT
    }
}

function getModal(setAction, azione, handleShow) {
    setAction(azione);
    handleShow();
}

function onLoadHandler(setContentFetched, setAreeTerritoriali, setUtentiList, regioneDaFiltrare) {

    Promise.all([StruttureRepository().getAreeTerritoriali(), UtentiRepository().getUsers({ applicazione: "PDT", listaAttributi: [], codiceGruppo: "" })])
        .then(([result1, result2]) => {
            if (result1 && (result2 && !hasErrorOutcomeAPMS(result2))) {
                setAreeTerritoriali(result1)
                setUtentiList(result2)
            }
            else setUtentiList([])
        })
        .finally(() => {
            setContentFetched(true)
            hideLoading()
        })

    // Nuova gestione get utenti
    // let profilo = "*;PT-ATERR;" + regioneDaFiltrare

    // Promise.all([StruttureRepository().getAreeTerritoriali(), UtentiRepository().getUtenti({ applicazione: "PDT", size: 0, profilo: regioneDaFiltrare && profilo })])
    //     .then(([result1, result2]) => {
    //         if (result1 && (result2 && !hasErrorOutcomeAPMS(result2))) {
    //             setAreeTerritoriali(result1)
    //             setUtentiList(result2.elementi)
    //         }
    //     })
    //     .finally(() => {
    //         setContentFetched(true)
    //         hideLoading()
    //     })
}

function InizializzaUtente(codiceFiscale, nome, cognome, selectedRole, areaTerritoriale, email) {

    return Object.freeze({
        "applicazione": "PDT",
        "codiceFiscale": codiceFiscale?.toString().toUpperCase(),
        "cognome": cognome?.toString(),
        "listaGruppi": [
            {
                "codicegruppo": selectedRole?.toString(),
                "listaAttributi": [
                    {
                        "codiceAttributo": "PT-ATERR",
                        "valore": areaTerritoriale ? areaTerritoriale.toString() : ""
                    }
                ]
            }
        ],
        "mail": email?.toString(),
        "nome": nome?.toString(),
        "struttura": "local",
        "utentelocale": true
    });

}

function operationUser(user, setContentFetched, resetInputs, setEditing, action, resetFilter) {
    if (action === operazioniUtente.CREA) {
        showLoading()
        UtentiRepository().createUser(user)
            .then(result => {
                if (result && !hasErrorOutcomeAPMS(result)) {
                    setContentFetched(false)
                    showSuccess()
                }
            })
            .finally(() => {
                hideLoading()
                resetInputs()
                setEditing(false)
            })
    }
    else if (action === operazioniUtente.MODIFICA) {
        showLoading()
        UtentiRepository().editUser(user)
            .then(result => {
                if (result && !hasErrorOutcomeAPMS(result)) {
                    setContentFetched(false)
                    showSuccess()
                }
            })
            .finally(() => {
                hideLoading()
                resetInputs()
                setEditing(false)
            })
    }
}